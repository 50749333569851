import React from 'react';
import {Link as RouterLink} from 'react-router-dom';

import {Refresh, Search} from '@mui/icons-material';
import {
    TableContainer, Table, TableBody, TableRow, TableCell, IconButton, Toolbar,
    OutlinedInput, InputAdornment, Box, Tooltip, Typography, Link
} from '@mui/material';
import {useSearchCommunes} from '../services/commune.gql';
import useCommuneService from '../services/commune.services';
import {PATH_ADMINISTRATIVE_DIVISION} from '../../administrative_division.path';
import {CommuneCreateDialogMenuItem, CommuneMoreMenu} from './index';
import {MyTablePagination, SortableTableHead, usePagination} from '../../../../shared/components/tables';
import {useDelete} from '../../../../shared/hooks/useDelete';
import {sxTableToolbar} from '../../../../shared/components/tables/components.table.utils';

const TABLE_HEAD = [
    {id: 'id', label: 'ID', alignRight: false},
    {id: 'name', label: 'Nom', alignRight: false},
    {id: 'statusCommuneObj', label: 'Statut de la commune', alignRight: false},
    {id: 'circle', label: 'Cercle', alignRight: false},
    {id: ''}
];

export default function CommuneTable({circle, currentCircle}) {
    const {
        page, order, filterName, rowsPerPage, orderBy, handleRequestSort,
        handleChangePage, handleChangeRowsPerPage, handleFilterByName
    } = usePagination();

    const {communes, refetch, communePage} = useSearchCommunes({
        query: filterName,
        size: rowsPerPage,
        page, circle
    });

    const {destroy} = useCommuneService();
    const {onDelete} = useDelete({
        destroyFn: destroy, onSuccess: refetch
    });

    return (
        <>
            <Toolbar sx={sxTableToolbar}>
                <OutlinedInput
                    sx={{width: 240}}
                    value={filterName}
                    onChange={handleFilterByName}
                    placeholder="Recherche commune"
                    startAdornment={
                        <InputAdornment position="start">
                            <Box component={Search} sx={{color: 'text.disabled'}}/>
                        </InputAdornment>
                    }
                />

                <Box>
                    {currentCircle && (
                        <CommuneCreateDialogMenuItem
                            btn
                            circle={currentCircle}
                            onSave={(createdCommune) => {
                                console.log(`##### createdCommune: `, createdCommune);
                                refetch();
                            }}
                        />
                    )}
                    <Tooltip title='Actualiser'>
                        <IconButton onClick={() => refetch()}>
                            <Refresh/>
                        </IconButton>
                    </Tooltip>
                </Box>
            </Toolbar>
            <TableContainer sx={{minWidth: 800}}>
                <Table size='small'>
                    <SortableTableHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {communes.map((row) => (<Row key={row.id} {...{row, onDelete}}/>))}
                    </TableBody>
                </Table>
            </TableContainer>

            <MyTablePagination
                page={communePage}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
};

function Row({row, onDelete}) {
    const {id, name,statusCommuneObj, circleObj} = row;

    return (
        <TableRow hover sx={{'&:last-child td, &:last-child th': {border: 0}}}>
            <TableCell component='th' scope='row'>
                <Link
                    to={PATH_ADMINISTRATIVE_DIVISION.communes + '/' + id}
                    component={RouterLink}
                >
                    <Typography variant='body2' noWrap>
                        {id}
                    </Typography>
                </Link>
            </TableCell>

            <TableCell component='th' scope='row'>
                <Link
                    to={PATH_ADMINISTRATIVE_DIVISION.communes + '/' + id}
                    component={RouterLink}
                >
                    <Typography variant='body2' noWrap>
                        {name}
                    </Typography>
                </Link>
            </TableCell>
            <TableCell style={{minWidth: 160}}>
                <Link
                    to={PATH_ADMINISTRATIVE_DIVISION.statusCommunes + '/' + statusCommuneObj?.id}
                    component={RouterLink}
                >
                    <Typography variant='body2' noWrap>
                        {statusCommuneObj?.name}
                    </Typography>
                </Link>
            </TableCell>

            <TableCell style={{minWidth: 160}}>
                <Link
                    to={PATH_ADMINISTRATIVE_DIVISION.circles + '/' + circleObj?.id}
                    component={RouterLink}
                >
                    <Typography variant='body2' noWrap>
                        {circleObj?.name}
                    </Typography>
                </Link>
            </TableCell>
            <TableCell align='right'>
                <CommuneMoreMenu id={id} onDelete={onDelete} currentCommune={row} isIconButton hiddenNew/>
            </TableCell>
        </TableRow>
    );
}
