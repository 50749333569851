import React from 'react';
// material
import FlagIcon from '@mui/icons-material/Flag';
// utils
import {PATH_ADMINISTRATIVE_DIVISION} from '../../administrative_division.path';
import {useCountPays} from '../services/pays.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function PaysEntryButton() {
    const {count} = useCountPays();
  return (
      <SubModuleEntryButton
          label='Pays'
          value={count}
          url={PATH_ADMINISTRATIVE_DIVISION.pays}
          icon={<FlagIcon />}
      />
  );
}
