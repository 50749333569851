import React from "react";
import SourceIcon from "@mui/icons-material/Source";
import { path } from "../../home/sidebar/paths";

//Features
const ROOTS_SIGMA4 = "/sigma";
const pathSigma4 = path(ROOTS_SIGMA4);
export const PATH_SIGMA4 = {
  root: ROOTS_SIGMA4,
  home: pathSigma4("/home"),
  infrastructures: pathSigma4("/infrastructures"),
  infrastructureTypes: pathSigma4("/infrastructure-types"),
  siteTypes: pathSigma4("/site-types"),
  saepTypes: pathSigma4("/saep-types"),
  saep: pathSigma4("/saeps"),
  exploitantTypes: pathSigma4("/exploitant-types"),
  energies: pathSigma4("/energies"),
  drillingResults: pathSigma4("/drilling-results"),
  coordinatedModes: pathSigma4("/coordinated-modes"),
  aquifers: pathSigma4("/aquifers"),
  lithologies: pathSigma4("/lithologies"),
  dewateringTypes: pathSigma4("/dewatering-types"),
  hydrologicalUnits: pathSigma4("/hydrological-units"),
  operateurs: pathSigma4("/operateurs"),
  wallTypes: pathSigma4("/wall-types"),
  wallNatures: pathSigma4("/wall-natures"),
  casingInfrastructures: pathSigma4("/casing-infrastructures"),
  lithologyInfrastructures: pathSigma4("/lithology-infrastructures"),
  pumpInfrastructures: pathSigma4("/pump-infrastructures"),
  analyzeInfrastructures: pathSigma4("/analyze-infrastructures"),
  annular_drillings: pathSigma4("/annular-drillings"),
  annular_natures: pathSigma4("/annular-natures"),
  drilling_water_coming: pathSigma4("/drilling-water-comings"),
  infrastructure_drilling: pathSigma4("/infrastructure-drillings"),
  infrastructure_well: pathSigma4("/infrastructure-wells"),
  tank_natures: pathSigma4("/tank-natures"),
  tanks: pathSigma4("/tanks"),
  distributions: pathSigma4("/distributions"),
};

export const sigma4Menu = [
  {
    title: "sigma4",
    path: PATH_SIGMA4.root,
    icon: <SourceIcon />,
    children: [
      {
        title: "Accueil",
        path: PATH_SIGMA4.home,
      },
      { title: "Ouvrages", path: PATH_SIGMA4.infrastructures },
      { title: "Types d'ouvrages", path: PATH_SIGMA4.infrastructureTypes },
      { title: "Types sites", path: PATH_SIGMA4.siteTypes },
      { title: "Types saep", path: PATH_SIGMA4.saepTypes },
      { title: "Types exploitant", path: PATH_SIGMA4.exploitantTypes },
      { title: "Energie", path: PATH_SIGMA4.energies },
      { title: "Saep", path: PATH_SIGMA4.saep },
      { title: "Resulats forages", path: PATH_SIGMA4.drillingResults },
      {title: "Modes de relevé des coordonnées", path: PATH_SIGMA4.coordinatedModes,},
      { title: "Aquifères", path: PATH_SIGMA4.aquifers },
      { title: "Lithologies", path: PATH_SIGMA4.lithologies },
      { title: "Types exhaures", path: PATH_SIGMA4.dewateringTypes },
      { title: "Unités hydrologiques", path: PATH_SIGMA4.hydrologicalUnits },
      { title: "Opérateurs", path: PATH_SIGMA4.operateurs },
      { title: "Types parois", path: PATH_SIGMA4.wallTypes },
      { title: "Natures parois", path: PATH_SIGMA4.wallNatures },
      { title: "Ouvrages tubages", path: PATH_SIGMA4.casingInfrastructures },
      {title: "Ouvrages lithologies", path: PATH_SIGMA4.lithologyInfrastructures,},
      { title: "Ouvrages pompes", path: PATH_SIGMA4.pumpInfrastructures },
      { title: "Ouvrages analyses", path: PATH_SIGMA4.analyzeInfrastructures },
      //    DILIKA
      { title: "Réservoirs", path: PATH_SIGMA4.tanks },
      { title: "Distributions", path: PATH_SIGMA4.distributions },
      { title: "Nature reservoire", path: PATH_SIGMA4.tank_natures },
      { title: "Annulaire nature", path: PATH_SIGMA4.annular_natures },
      { title: "Ouvrage puit", path: PATH_SIGMA4.infrastructure_well },
      { title: "Annulaire forage", path: PATH_SIGMA4.annular_drillings },
      { title: "Ouvrage Forage", path: PATH_SIGMA4.infrastructure_drilling },
      { title: "Forage venue eau", path: PATH_SIGMA4.drilling_water_coming },
    ],
  },
];
