import React, {lazy} from 'react';
import RiskManagementToolApplicationDegreeList from "./pages/RiskManagementToolApplicationDegreeList";
import RiskManagementToolApplicationDegreeDisplay from "./pages/RiskManagementToolApplicationDegreeDisplay";
import RiskManagementToolApplicationDegreeCreate from "./pages/RiskManagementToolApplicationDegreeCreate";
import RiskManagementToolApplicationDegreeEdit from "./pages/RiskManagementToolApplicationDegreeEdit";

const riskManagementToolApplicationDegreeRouter = [
    {path: 'risk-management-tool-application-degrees', element: <RiskManagementToolApplicationDegreeList/>},
    {path: 'risk-management-tool-application-degrees/:id', element: <RiskManagementToolApplicationDegreeDisplay/>},
    {path: 'risk-management-tool-application-degrees/new', element: <RiskManagementToolApplicationDegreeCreate/>},
    {path: 'risk-management-tool-application-degrees/:id/edit', element: <RiskManagementToolApplicationDegreeEdit/>},
];

export default riskManagementToolApplicationDegreeRouter;
