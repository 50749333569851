import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const TypeSphereList = Loadable(lazy(() => import('./pages/TypeSphereList')));
const TypeSphereCreate = Loadable(lazy(() => import('./pages/TypeSphereCreate')));
const TypeSphereEdit = Loadable(lazy(() => import('./pages/TypeSphereEdit')));
const TypeSphereDisplay = Loadable(lazy(() => import('./pages/TypeSphereDisplay')));

const typeSphereRouter = [
    {path: 'type-spheres', element: <TypeSphereList/>},
    {path: 'type-spheres/:id', element: <TypeSphereDisplay/>},
    {path: 'type-spheres/new', element: <TypeSphereCreate/>},
    {path: 'type-spheres/:id/edit', element: <TypeSphereEdit/>},
];

export default typeSphereRouter;
