import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const TypesObservationDeviceList = Loadable(lazy(() => import("./pages/TypesObservationDeviceList")));
const TypesObservationDeviceCreate = Loadable(lazy(() => import("./pages/TypesObservationDeviceCreate")));
const TypesObservationDeviceEdit = Loadable(lazy(() => import("./pages/TypesObservationDeviceEdit")));
const TypesObservationDeviceDisplay = Loadable(lazy(() => import("./pages/TypesObservationDeviceDisplay")));

const typesObservationDeviceRouter = [
  { path: "types-observation-device", element: <TypesObservationDeviceList /> },
  { path: "types-observation-device/:id", element: <TypesObservationDeviceDisplay /> },
  { path: "types-observation-device/new", element: <TypesObservationDeviceCreate /> },
  { path: "types-observation-device/:id/edit", element: <TypesObservationDeviceEdit /> },
];

export default typesObservationDeviceRouter;
