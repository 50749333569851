import {useApiHttp} from '../../../../../shared/hooks/useHttp';


export default function useReportingService() {
    const {crud, post, get} = useApiHttp();

    const {
        create,
        update,
        destroy,
        findById,
        findAll,
    } = crud('reporting');

    const execute = (reportingRequest) => post('reporting/execute', reportingRequest);
    const duplicate = (reporting) => post(`reporting/${reporting}/duplicate`);
    const exportById = (id) => get(`reporting/${id}/export`);
    const exportAll = () => get(`reporting/export-all`);
    const importOne = (data) => post(`reporting/import-one`, data);
    const importAll = (data) => post(`reporting/import-all`, data);

    return {
        create,
        update,
        destroy,
        findById,
        findAll,
        execute,
        duplicate,
        exportById,
        exportAll,
        importOne,
        importAll,
    }
}
