import React, {lazy} from 'react';
import InstrumentPolicyOccurrenceList from "./pages/InstrumentPolicyOccurrenceList";
import InstrumentPolicyOccurrenceDisplay from "./pages/InstrumentPolicyOccurrenceDisplay";
import InstrumentPolicyOccurrenceCreate from "./pages/InstrumentPolicyOccurrenceCreate";
import InstrumentPolicyOccurrenceEdit from "./pages/InstrumentPolicyOccurrenceEdit";

const instrumentPolicyOccurrenceRouter = [
    {path: 'instrument-policy-occurrences', element: <InstrumentPolicyOccurrenceList/>},
    {path: 'instrument-policy-occurrences/:id', element: <InstrumentPolicyOccurrenceDisplay/>},
    {path: 'instrument-policy-occurrences/new', element: <InstrumentPolicyOccurrenceCreate/>},
    {path: 'instrument-policy-occurrences/:id/edit', element: <InstrumentPolicyOccurrenceEdit/>},

];
export default instrumentPolicyOccurrenceRouter;
