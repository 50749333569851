import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const LocalityList = Loadable(lazy(() => import('./pages/LocalityList')));
const LocalityCreate = Loadable(lazy(() => import('./pages/LocalityCreate')));
const LocalityEdit = Loadable(lazy(() => import('./pages/LocalityEdit')));
const LocalityDisplay = Loadable(lazy(() => import('./pages/LocalityDisplay')));

const localityRouter = [
    {path: 'localities', element: <LocalityList/>},
    {path: 'localities/:id', element: <LocalityDisplay/>},
    {path: 'localities/new', element: <LocalityCreate/>},
    {path: 'localities/:id/edit', element: <LocalityEdit/>},
];

export default localityRouter;
