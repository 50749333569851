import { useEffect, useState } from "react";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { parseCount, parseEntity } from "../../../../utils/gql.utils";
import { useMessage } from "../../../../shared/hooks/useMessage";

export let needRefresh = false;
export const setRefresh = (value) => (needRefresh = value);

export const villageStatusFields = `
    id wording code description
`;

///////////////////// Find By ID /////////////////////
const GET_VILLAGE_STATUS_BY_ID = gql`
    query VillageStatusById($id: Int!) {
        villageStatusById(id: $id) {
            error message entity { ${villageStatusFields} }
        }
    }
`;

export const useVillageStatusById = (id) => {
  id = parseInt(id);
  return useQuery(GET_VILLAGE_STATUS_BY_ID, {
    variables: { id },
    fetchPolicy: "network-only",
  });
};

export const useVillageStatus = (id) => {
  const result = useVillageStatusById(id);
  return parseEntity(result, "villageStatusById");
};

///////////////////// Search /////////////////////
const SEARCH_VILLAGE_STATUS = gql`
    query SearchVillageStatus($query: String, $page: Int, $size: Int) {
        searchVillageStatus(query: $query, page: $page, size: $size) {
            error message page {
                content { ${villageStatusFields} }
                page hasNext hasPrevious totalPages totalElements numberOfElements
            }
        }
    }
`;

export const useSearchVillageStatus = ({ query = "", page = 0, size = 20 }) => {
  const [villageStatus, setVillageStatus] = useState([]);
  const [villageStatusPage, setVillageStatusPage] = useState(null);

  const { showError } = useMessage();

  const [search, { data: searchData, refetch, loading, error: _error }] =
    useLazyQuery(SEARCH_VILLAGE_STATUS, {
      variables: { query, page, size },
    });

  useEffect(() => {
    if (_error) {
      showError("Erreur lors de l'operation");
      setVillageStatusPage(null);
      setVillageStatus([]);
      return;
    }

    if (!searchData) return;

    const { error, message, page } = searchData.searchVillageStatus;

    if (error) {
      showError(message);
      setVillageStatusPage(null);
      setVillageStatus([]);
    } else {
      setVillageStatusPage(page);
      setVillageStatus(page.content);
    }

    if (needRefresh) {
      refetch();
      setRefresh(false);
    }
  }, [_error, searchData, needRefresh]);

  useEffect(() => {
    search({ variables: { query, page, size } });
  }, [query, page, size]);

  return {
    villageStatus,
    loading,
    search,
    refetch,
    villageStatusPage,
  };
};

///////////////////// Count /////////////////////
const COUNT_VILLAGE_STATUS = gql`
  query CountVillageStatus {
    countVillageStatus {
      error
      message
      count
    }
  }
`;

export const useCountVillageStatus = () => {
  const result = useQuery(COUNT_VILLAGE_STATUS, {
    fetchPolicy: "network-only",
  });
  return parseCount(result, "countVillageStatus");
};
