import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const BusinessUnitAreaList = Loadable(lazy(() => import('./pages/BusinessUnitAreaList')));
const BusinessUnitAreaCreate = Loadable(lazy(() => import('./pages/BusinessUnitAreaCreate')));
const BusinessUnitAreaEdit = Loadable(lazy(() => import('./pages/BusinessUnitAreaEdit')));
const BusinessUnitAreaDisplay = Loadable(lazy(() => import('./pages/BusinessUnitAreaDisplay')));

const businessUnitAreaRouter = [
    {path: 'business-unit-areas', element: <BusinessUnitAreaList/>},
    {path: 'business-unit-areas/:id', element: <BusinessUnitAreaDisplay/>},
    {path: 'business-unit-areas/new', element: <BusinessUnitAreaCreate/>},
    {path: 'business-unit-areas/:id/edit', element: <BusinessUnitAreaEdit/>},
];

export default businessUnitAreaRouter;
