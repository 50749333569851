import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {Form, FormikProvider, useFormik} from 'formik';
import {useMessage} from '../../../../shared/hooks/useMessage';
// material
import {LoadingButton} from '@mui/lab';
import {Card, Stack, Box, Button} from '@mui/material';
// routes
import {setRefresh} from '../services/legislative.text.occurrence.gql';
import useLegislativeTextOccurrenceService from '../services/legislative.text.occurrence.services';
import {formErrorMessageParser} from '../../../../utils/utils';
import {FormikAutocomplete, FormikTextField, FormRowStack} from '../../../../shared/components/froms/FormComponents';
import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {
    InitialLegislativeTextOccurrence,
    LegislativeTextOccurrenceSchema
} from '../services/legislative.text.occurrence.store';
import {useConnectFormState} from '../../../../shared/hooks/useFormikState';

import {useSearchRiskManagementToolApplicationDegrees} from "../../../risk_protection/risk_management_tool_application_degree/services/risk.management.tool.application.degree.gql";
import {RiskManagementToolApplicationDegreeCreateDialogMenuItem} from "../../../risk_protection/risk_management_tool_application_degree/components";

import {useSearchDataRefYears} from "../../../administrative_division/data_ref_year/services/data.ref.year.gql";
import {DataRefYearCreateDialogMenuItem} from "../../../administrative_division/data_ref_year/components";

import {useSearchLegislativeTexts} from "../../../gire_env_favorable/legislative_text/services/legislative.text.gql";
import {LegislativeTextCreateDialogMenuItem} from "../../../gire_env_favorable/legislative_text/components";

LegislativeTextOccurrenceForm.propTypes = {
    isEdit: PropTypes.bool,
    currentLegislativeTextOccurrence: PropTypes.object,
    fromDialog: PropTypes.object,
};

export default function LegislativeTextOccurrenceForm({
                                                           isEdit,
                                                           currentLegislativeTextOccurrence,
                                                           setCurrentLegislativeTextOccurrence,
                                                           fromDialog
                                                       }) {
    const navigate = useNavigate();
    const {create, update} = useLegislativeTextOccurrenceService();
    const {showSuccess, showError} = useMessage();

    const initRiskManagementToolApplicationDegreeQuery = currentLegislativeTextOccurrence?.riskManagementToolApplicationDegreeObj?.name ?? fromDialog?.riskManagementToolApplicationDegree?.name ?? '';
    const [riskManagementToolApplicationDegreeQuery, setRiskManagementToolApplicationDegreeQuery] = useState(initRiskManagementToolApplicationDegreeQuery);
    const {
        riskManagementToolApplicationDegrees,
        riskManagementToolApplicationDegreeLoading
    } = useSearchRiskManagementToolApplicationDegrees({riskManagementToolApplicationDegreeQuery});

    const initDataRefYearQuery = currentLegislativeTextOccurrence?.dataRefYearObj?.name ?? fromDialog?.dataRefYear?.name ?? '';
    const [dataRefYearQuery, setDataRefYearQuery] = useState(initDataRefYearQuery);
    const {dataRefYears, dataRefYearLoading} = useSearchDataRefYears({dataRefYearQuery});

    const initLegislativeTextQuery = currentLegislativeTextOccurrence?.legislativeTextObj?.name ?? fromDialog?.legislativeText?.name ?? '';
    const [legislativeTextQuery, setLegislativeTextQuery] = useState(initLegislativeTextQuery);
    const {legislativeTexts, legislativeTextLoading} = useSearchLegislativeTexts({legislativeTextQuery});

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: InitialLegislativeTextOccurrence(),
        validationSchema: LegislativeTextOccurrenceSchema,
        onSubmit: async (legislativeTextOccurrence, {setSubmitting}) => {
            try {
                legislativeTextOccurrence = LegislativeTextOccurrenceSchema.cast(legislativeTextOccurrence);

                let message;
                if (!isEdit) {
                    legislativeTextOccurrence = await create(legislativeTextOccurrence);
                    message = 'Creation avec succès';
                } else {
                    legislativeTextOccurrence = await update(currentLegislativeTextOccurrence.id, legislativeTextOccurrence);
                    message = 'Modification avec succès';
                }

                setRefresh(true);

                setCurrentLegislativeTextOccurrence();

                setSubmitting(false);
                showSuccess(message);

                if (fromDialog) {
                    fromDialog.onSave(legislativeTextOccurrence);
                } else {
                    navigate(`${PATH_GIRE_ENV_FAVORABLE.legislativeTextOccurrences}/${legislativeTextOccurrence.id}`);
                }
            } catch (error) {
                setSubmitting(false);
                showError(formErrorMessageParser({error, isEdit}));
            }
        }
    });

    const {values, setValues, handleSubmit, isSubmitting, setFieldValue} = formik;

    useConnectFormState({
        formValues: values, setFormValues: setValues,
        stateValues: currentLegislativeTextOccurrence, setStateValues: setCurrentLegislativeTextOccurrence,
    });

    const onCancel = (_) => {
        setCurrentLegislativeTextOccurrence();
        if (fromDialog) {
            fromDialog.onCancel();
        } else {
            navigate(PATH_GIRE_ENV_FAVORABLE.legislativeTextOccurrences);
        }
    }

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete='off' onSubmit={handleSubmit}>

                <Card sx={{p: 3}}>

                    <Stack spacing={3}>

                        <FormRowStack>
                            <FormikTextField label='Nom' name='name' formik={formik}/>
                        </FormRowStack>

                        <Box flexGrow={1} display='flex' flexDirection='row'>
                            <FormikAutocomplete name='riskManagementToolApplicationDegree'
                                                label="Dégree d'application"
                                                options={riskManagementToolApplicationDegrees}
                                                labelField='name'
                                                embeddedObjField='riskManagementToolApplicationDegreeObj'
                                                {...{
                                                    riskManagementToolApplicationDegreeQuery,
                                                    formik,
                                                    riskManagementToolApplicationDegreeLoading,
                                                    setRiskManagementToolApplicationDegreeQuery
                                                }}
                            />
                            <Box sx={{py: 1, ml: 1}}>
                                <RiskManagementToolApplicationDegreeCreateDialogMenuItem btn
                                                                                         onSave={(createdRiskManagementToolApplicationDegree) => {
                                                                                             currentLegislativeTextOccurrence.riskManagementToolApplicationDegreeObj = createdRiskManagementToolApplicationDegree;
                                                                                             setRiskManagementToolApplicationDegreeQuery(createdRiskManagementToolApplicationDegree?.name ?? '');
                                                                                             setFieldValue('riskManagementToolApplicationDegree', createdRiskManagementToolApplicationDegree?.id);
                                                                                         }}/>
                            </Box>
                        </Box>

                        <FormRowStack>
                            <Box flexGrow={1} display='flex' flexDirection='row'>
                                <FormikAutocomplete name='dataRefYear'
                                                    label="L'année de reference de donnée"
                                                    options={dataRefYears}
                                                    labelField='name'
                                                    embeddedObjField='dataRefYearObj'
                                                    {...{
                                                        dataRefYearQuery,
                                                        formik,
                                                        dataRefYearLoading,
                                                        setDataRefYearQuery
                                                    }}
                                />
                                <Box sx={{py: 1, ml: 1}}>
                                    <DataRefYearCreateDialogMenuItem btn onSave={(createdDataRefYear) => {
                                        currentLegislativeTextOccurrence.dataRefYearObj = createdDataRefYear;
                                        setDataRefYearQuery(createdDataRefYear?.name ?? '');
                                        setFieldValue('dataRefYear', createdDataRefYear?.id);
                                    }}/>
                                </Box>
                            </Box>
                            <Box flexGrow={1} display='flex' flexDirection='row'>
                                <FormikAutocomplete name='legislativeText'
                                                    label="Instruments de politique et stratégie eau"
                                                    options={legislativeTexts}
                                                    labelField='name'
                                                    embeddedObjField='legislativeTextObj'
                                                    {...{
                                                        legislativeTextQuery,
                                                        formik,
                                                        legislativeTextLoading,
                                                        setLegislativeTextQuery
                                                    }}
                                />
                                <Box sx={{py: 1, ml: 1}}>
                                    <DataRefYearCreateDialogMenuItem btn onSave={(createdLegislativeText) => {
                                        currentLegislativeTextOccurrence.legislativeTextObj = createdLegislativeText;
                                        setDataRefYearQuery(createdLegislativeText?.name ?? '');
                                        setFieldValue('legislativeText', createdLegislativeText?.id);
                                    }}/>
                                </Box>
                            </Box>
                        </FormRowStack>

                        <FormRowStack>
                            <FormikTextField label='Justification' name='justification' formik={formik}/>
                            <FormikTextField label='Source de données' name='dataSource' formik={formik}/>
                        </FormRowStack>

                        <Box sx={{mt: 3, display: 'flex', justifyContent: 'flex-end'}}>
                            <Button
                                sx={{mr: 2}}
                                size='medium'
                                onClick={onCancel}
                                disabled={isSubmitting}>
                                {'Annuler'}
                            </Button>
                            <LoadingButton type='submit' variant='contained' size='medium' loading={isSubmitting}>
                                {!isEdit ? 'Créer' : 'Modifier'}
                            </LoadingButton>
                        </Box>
                    </Stack>

                </Card>

            </Form>
        </FormikProvider>
    );
}
