import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const StrategicWaterManagementAreaList = Loadable(lazy(() => import("./pages/StrategicWaterManagementAreaList")));
const StrategicWaterManagementAreaCreate = Loadable(lazy(() => import("./pages/StrategicWaterManagementAreaCreate")));
const StrategicWaterManagementAreaEdit = Loadable(lazy(() => import("./pages/StrategicWaterManagementAreaEdit")));
const StrategicWaterManagementAreaDisplay = Loadable(lazy(() => import("./pages/StrategicWaterManagementAreaDisplay")));

const strategicWaterManagementAreaEntityRouter = [
  { path: "strategic-water-management-areas", element: <StrategicWaterManagementAreaList /> },
  { path: "strategic-water-management-areas/:id", element: <StrategicWaterManagementAreaDisplay /> },
  { path: "strategic-water-management-areas/new", element: <StrategicWaterManagementAreaCreate /> },
  { path: "strategic-water-management-areas/:id/edit", element: <StrategicWaterManagementAreaEdit /> },
];

export default strategicWaterManagementAreaEntityRouter;
