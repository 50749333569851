import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const WasteWaterDischargeList = Loadable(lazy(() => import('./pages/WasteWaterDischargeList')));
const WasteWaterDischargeCreate = Loadable(lazy(() => import('./pages/WasteWaterDischargeCreate')));
const WasteWaterDischargeEdit = Loadable(lazy(() => import('./pages/WasteWaterDischargeEdit')));
const WasteWaterDischargeDisplay = Loadable(lazy(() => import('./pages/WasteWaterDischargeDisplay')));

const wasteWaterDischargeRouter = [
    {path: 'waste-water-discharges', element: <WasteWaterDischargeList/>},
    {path: 'waste-water-discharges/:id', element: <WasteWaterDischargeDisplay/>},
    {path: 'waste-water-discharges/new', element: <WasteWaterDischargeCreate/>},
    {path: 'waste-water-discharges/:id/edit', element: <WasteWaterDischargeEdit/>},
];

export default wasteWaterDischargeRouter;
