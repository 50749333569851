import React from 'react';
import {PATH_GIRE_ENV_FAVORABLE, commonItems} from '../../gire.env.favorable.path';
import HeaderBreadcrumbs from "../../../../shared/components/HeaderBreadcrumbs";

export function InstrumentPolicyOccurrenceHeaderBreadcrumbs ({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[
            ...commonItems,
            {name: "Type d Occurrences d'état de mise en œuvre des instruments de politique et stratégie eau", href: PATH_GIRE_ENV_FAVORABLE.instrumentPolicyOccurrences},
            {name: lastName},
        ]}
    />);
}

export function InstrumentPolicyOccurrenceListHeaderBreadcrumbs ({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[...commonItems, {name: lastName}]}
    />);
}
