import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const WorkList = Loadable(lazy(() => import("./pages/WorkList")));
const WorkCreate = Loadable(lazy(() => import("./pages/WorkCreate")));
const WorkEdit = Loadable(lazy(() => import("./pages/WorkEdit")));
const WorkDisplay = Loadable(lazy(() => import("./pages/WorkDisplay")));

const workRouter = [
  { path: "works", element: <WorkList /> },
  { path: "works/:id", element: <WorkDisplay /> },
  { path: "works/new", element: <WorkCreate /> },
  { path: "works/:id/edit", element: <WorkEdit /> },
];

export default workRouter;
