import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Box, Tab, Card, Container, Stack, TextField, CircularProgress} from '@mui/material';
import Page from '../../../../shared/components/Page';
import {AmenagementTypeHeaderBreadcrumbs} from '../components';
import {FormRowStack} from '../../../../shared/components/froms/FormComponents';
import {PATH_RISK_PROTECTION} from '../../risk.protection.path';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useAmenagementType} from '../services/amenagement.type.gql';
import AmenagementTypeMoreMenu from '../components/AmenagementTypeMoreMenu';
import useAmenagementTypeService from "../services/amenagement.type.services";
import {useDelete} from "../../../../shared/hooks/useDelete";
// ----------------------------------------------------------------------

export default function AmenagementTypeDisplay() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {showError} = useMessage();
    const [tapIndex, setTapIndex] = useState('info');
    const {loading, entity: currentAmenagementType, error, errorMessage} = useAmenagementType(id);

    const {destroy} = useAmenagementTypeService();
    const {onDelete} = useDelete({
        destroyFn: destroy,
        onSuccess: () => navigate(PATH_RISK_PROTECTION.amenagementTypes),
    });

    useEffect(() => {
        if (loading) return;
        if (error) {
            showError(errorMessage);
            navigate(PATH_RISK_PROTECTION.amenagementTypes);
        }
    }, [error, loading, errorMessage]);

    const handleChangeTab = (event, newValue) => setTapIndex(newValue);

    return (
        <Page title={'Types d\'aménagement/Protection contre les risques liés aux RE: Type d\'aménagement'}>
            <Container maxWidth={'xl'}>
                <AmenagementTypeHeaderBreadcrumbs
                    heading="Type d'aménagement"
                    lastName={`${currentAmenagementType?.name ?? 'Type d\'aménagement'}`}

                    action={<AmenagementTypeMoreMenu id={Number(id)} onDelete={onDelete} hiddenDisplay/>}
                />

                {loading && <CircularProgress/>}
                {!loading && currentAmenagementType && (
                    <TabContext value={tapIndex}>
                        <Box sx={{mb: 4, direction: 'row', display: 'flex', justifyContent: 'space-between'}}>
                            <TabList onChange={handleChangeTab}>
                                <Tab disableRipple value='info' label='Information'/>
                                {/*<Tab disableRipple value='regions' label='Regions'/>*/}
                            </TabList>
                        </Box>


                        <Card>
                            <TabPanel value='info'>
                                <Box sx={{p: 0, minHeight: '80%'}}>

                                    <Stack spacing={3}>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label='Nom'
                                                value={currentAmenagementType?.name}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label="Source de donnée"
                                                value={currentAmenagementType?.dataSource}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label="Description"
                                                value={currentAmenagementType?.description}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>

                                    </Stack>

                                </Box>

                            </TabPanel>

                            {/*<TabPanel value='regions' sx={{p: 0}}>
                                <StatusCommuneTable amenagementType={currentAmenagementType.id} currentAmenagementType={currentAmenagementType}/>
                            </TabPanel>*/}
                        </Card>

                    </TabContext>
                )}
            </Container>
        </Page>
    );
}
