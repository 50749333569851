import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';

import MainLayout from '../../home/AppLayout';
import Loadable from '../../shared/components/Loadable';

import {PATH_ENERGY} from './energy.path';
import productionTypeRouter from "./production_type/production.type.router";
import energyProductionRouter from "./energy_production/energy.production.router";
import variationOccurrenceRouter from "./variation_occurrence/variation.occurrence.router";
import productionOccurrenceRouter from "./production_occurrence/production.occurrence.router";
import occurrenceUseRouter from "./occurrence_use/occurrence.use.router";




const EnergyHome = Loadable(lazy(() => import('./EnergyHome')));

const energyRouter = [{
    path: PATH_ENERGY.root,
    element: <MainLayout/>,
    children: [
        {path: PATH_ENERGY.root, element: <Navigate to={PATH_ENERGY.home} replace/>},
        {path: 'home', element: <EnergyHome/>},

         ...productionTypeRouter,
         ...energyProductionRouter,
         ...variationOccurrenceRouter,
         ...productionOccurrenceRouter,
         ...occurrenceUseRouter,
    ]
},];

export default energyRouter;
