import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { parseCount, parseEntity } from "../../../../utils/gql.utils";
import { useEffect, useState } from "react";
import { useMessage } from "../../../../shared/hooks/useMessage";

export let needRefresh = false;
export const setRefresh = (value) => (needRefresh = value);

export const riskManagementToolsImplementationOccurrenceFields = `
id 
name
waterMass waterMassObj{id wording}
riskManagementTool riskManagementToolObj{id name}
riskManagementToolApplicationDegree riskManagementToolApplicationDegreeObj{id name}
dataRefYear dataRefYearObj{id name}
justification
dataSource
`;

///////////////////// Find By ID /////////////////////
const GET_EVENT_LOSS_OCCURRENCE_BY_ID = gql`
    query RiskManagementToolsImplementationOccurrenceById($id: Int!) {
        riskManagementToolsImplementationOccurrenceById(id: $id) {
            error message entity { ${riskManagementToolsImplementationOccurrenceFields} }
        }
    } 
`;

export const useRiskManagementToolsImplementationOccurrenceById = (id) => {
  id = parseInt(id);
  return useQuery(GET_EVENT_LOSS_OCCURRENCE_BY_ID, {
    variables: { id },
    fetchPolicy: "network-only",
  });
};

export const useRiskManagementToolsImplementationOccurrence = (id) => {
  const result = useRiskManagementToolsImplementationOccurrenceById(id);
  return parseEntity(result, "riskManagementToolsImplementationOccurrenceById");
};
///////////////////// Search /////////////////////
const SEARCH_EVENT_LOSS_OCCURRENCE = gql`
    query SearchRiskManagementToolsImplementationOccurrences($query: String,$riskManagementToolApplicationDegree: Int,$waterMass: Int,$riskManagementTool: Int, $dataRefYear: Int, $page: Int, $size: Int) {
        searchRiskManagementToolsImplementationOccurrences(query: $query, riskManagementToolApplicationDegree:$riskManagementToolApplicationDegree, waterMass:$waterMass, riskManagementTool:$riskManagementTool, dataRefYear:$dataRefYear, page: $page, size: $size) {
            error message page {
                content { ${riskManagementToolsImplementationOccurrenceFields} }
                page hasNext hasPrevious totalPages totalElements numberOfElements
            }
        }
    }
`;

export const useSearchRiskManagementToolsImplementationOccurrences = ({ query = "", riskManagementToolApplicationDegree, waterMass,riskManagementTool, dataRefYear, page = 0, size = 20 }) => {
  const [riskManagementToolsImplementationOccurrences, setRiskManagementToolsImplementationOccurrences] = useState([]);
  const [riskManagementToolsImplementationOccurrencePage, setRiskManagementToolsImplementationOccurrencePage] = useState(null);

  const { showError } = useMessage();

  const [search, { data: searchData, refetch, loading, error: _error }] =
    useLazyQuery(SEARCH_EVENT_LOSS_OCCURRENCE, {
      variables: { query, riskManagementToolApplicationDegree, waterMass,dataRefYear, riskManagementTool, page, size },
    });

  useEffect(() => {
    if (_error) {
      showError("Erreur lors de l'operation");
      setRiskManagementToolsImplementationOccurrencePage(null);
      setRiskManagementToolsImplementationOccurrences([]);
      return;
    }

    if (!searchData) return;

    const { error, message, page } = searchData.searchRiskManagementToolsImplementationOccurrences;

    if (error) {
      showError(message);
      setRiskManagementToolsImplementationOccurrencePage(null);
      setRiskManagementToolsImplementationOccurrences([]);
    } else {
      setRiskManagementToolsImplementationOccurrencePage(page);
      setRiskManagementToolsImplementationOccurrences(page.content);
    }

    if (needRefresh) {
      refetch();
      setRefresh(false);
    }
  }, [_error, searchData, needRefresh]);

  useEffect(() => {
    search({ variables: { query, riskManagementToolApplicationDegree, waterMass, riskManagementTool, dataRefYear, page, size } });
  }, [query ,riskManagementToolApplicationDegree, waterMass,riskManagementTool, dataRefYear,  page, size]);

  return {
    riskManagementToolsImplementationOccurrences,
    loading,
    search,
    refetch,
    riskManagementToolsImplementationOccurrencePage,
  };
};

///////////////////// Count /////////////////////
const COUNT_EVENT_LOSS_OCCURRENCES = gql`
  query CountRiskManagementToolsImplementationOccurrences {
    countRiskManagementToolsImplementationOccurrences {
      error
      message
      count
    }
  }
`;

export const useCountRiskManagementToolsImplementationOccurrences = () => {
  const result = useQuery(COUNT_EVENT_LOSS_OCCURRENCES, {
    fetchPolicy: "network-only",
  });
  return parseCount(result, "countRiskManagementToolsImplementationOccurrences");
};
