import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Add} from '@mui/icons-material';
import { Card, Button, Container } from '@mui/material';
import {PATH_RISK_PROTECTION} from '../../risk.protection.path';
import {Page} from '../../../../shared/components';
import {RiskManagementToolApplicationDegreeTable, RiskManagementToolApplicationDegreeListHeaderBreadcrumbs} from '../components';

export default function RiskManagementToolApplicationDegreeList() {
    return (
        <Page title="Risque et protection en RE: Liste des degrés d'application des outils de gestion de risques liés à l'eau">
            <Container maxWidth={'xl'}>
                <RiskManagementToolApplicationDegreeListHeaderBreadcrumbs
                    heading="Degrés d'application des outils de gestion de risques liés à l'eau"
                    lastName="Liste des degrés d'application des outils de gestion de risques liés à l'eau"
                    action={
                        <Button
                            variant="contained"
                            component={RouterLink}
                            to={PATH_RISK_PROTECTION.riskManagementToolApplicationDegrees + '/new'}
                            startIcon={<Add/>}>
                            Nouveau
                        </Button>
                    }
                />

                <Card>
                    <RiskManagementToolApplicationDegreeTable/>
                </Card>
            </Container>
        </Page>
    );
}
