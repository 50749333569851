import React from "react";
// material
import { Typography } from "@mui/material";
// components
import { RiskManagementToolsImplementationOccurrenceForm } from "../components";
import CreateDialogMenuItem from "../../../../shared/components/CreateDialogMenuItem";
import {useRiskManagementToolsImplementationOccurrenceStore} from "../services/risk.management.tools.implementation.occurrence.store";

// ----------------------------------------------------------------------

export function RiskManagementToolsImplementationOccurrenceCreateDialogMenuItem({riskManagementTool,
                                                            onClose,
  onSave,
  onClick,
  btn,
}) {

    const setCurrentRiskManagementToolsImplementationOccurrence = () => {
    };
    const currentRiskManagementToolsImplementationOccurrence = {
        riskManagementTool: riskManagementTool?.id,
        riskManagementToolObj: riskManagementTool,
    };

    return (
    <CreateDialogMenuItem
      btn={btn}
      onClose={onClose}
      onClick={onClick}
      menuText="Ajouter une occurrences de mise en œuvre des outils de gestion de risques liés à l'eau"
      dialogTitle="Création d'une occurrences de mise en œuvre des outils de gestion de risques liés à l'eau"
      dialogContextText={<>Création de nouvel occurrences d'évenements et de pertes</>}
      formRender={({ onClose }) => {
        return (
          <RiskManagementToolsImplementationOccurrenceForm
            {...{ currentRiskManagementToolsImplementationOccurrence, setCurrentRiskManagementToolsImplementationOccurrence }}
            fromDialog={{
              onCancel: onClose,
              onSave: (createdRiskManagementToolsImplementationOccurrence) => {
                onSave && onSave(createdRiskManagementToolsImplementationOccurrence);
                onClose && onClose();
              },
            }}
          />
        );
      }}
    />
  );
}
