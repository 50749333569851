import {gql, useLazyQuery} from '@apollo/client';
import {useEffect, useState} from 'react';
import {useMessage} from '../../../../../shared/hooks/useMessage';

export let needRefresh = false;
export const setRefresh = (value) => needRefresh = value;

export const dashboardWidgetFields = `
    id position dashboardTitle widgetTitle
    autoRefresh refreshDelay
    dashboard dashboardObj {id title description}
    widget widgetObj {id title description}
    gridHxs gridHmd gridHlg
    gridVxs gridVmd gridVlg
`;

///////////////////// Search /////////////////////
const SEARCH_REPORTING_GROUPINGS_BY_REPORTING = gql`
    query SearchDashboardWidgetsByDashboard($dashboard: Int) {
        searchDashboardWidgetsByDashboard(dashboard: $dashboard) {
            error message list { ${dashboardWidgetFields} }
        }
    }`;

export const useSearchDashboardWidgetsByDashboard = ({dashboard}) => {
    dashboard = dashboard && parseInt(dashboard);
    const [dashboardWidgets, setDashboardWidgets] = useState([]);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_REPORTING_GROUPINGS_BY_REPORTING, {
        variables: {dashboard},
    });

    useEffect(() => {
        if (_error) {
            showError('Erreur lors de l\'operation');
            setDashboardWidgets([]);
            return;
        }

        if (!searchData) return;

        const {error, message, list} = searchData.searchDashboardWidgetsByDashboard;

        if (error) {
            showError(message);
            setDashboardWidgets([]);
        } else {
            setDashboardWidgets(list);
        }

        if (needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({variables: {dashboard}});
    }, [dashboard]);

    return {
        dashboardWidgets, loading, search, refetch
    }
}
