import React from 'react';
import {PATH_RISK_PROTECTION, commonItems} from '../../risk.protection.path';
import HeaderBreadcrumbs from "../../../../shared/components/HeaderBreadcrumbs";


export function AmenagementTypeHeaderBreadcrumbs ({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[
            ...commonItems,
            {name: 'Types d\'aménagement', href: PATH_RISK_PROTECTION.amenagementTypes},
            {name: lastName},
        ]}
    />);
}

export function AmenagementTypeListHeaderBreadcrumbs ({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[...commonItems, {name: lastName}]}
    />);
}
