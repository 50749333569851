import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const TypeAggressionFactorList = Loadable(lazy(() => import('./pages/TypeAggressionFactorList')));
const TypeAggressionFactorCreate = Loadable(lazy(() => import('./pages/TypeAggressionFactorCreate')));
const TypeAggressionFactorEdit = Loadable(lazy(() => import('./pages/TypeAggressionFactorEdit')));
const TypeAggressionFactorDisplay = Loadable(lazy(() => import('./pages/TypeAggressionFactorDisplay')));

const typeAggressionFactorRouter = [
    {path: 'type-aggression-factors', element: <TypeAggressionFactorList/>},
    {path: 'type-aggression-factors/:id', element: <TypeAggressionFactorDisplay/>},
    {path: 'type-aggression-factors/new', element: <TypeAggressionFactorCreate/>},
    {path: 'type-aggression-factors/:id/edit', element: <TypeAggressionFactorEdit/>},
];

export default typeAggressionFactorRouter;
