import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const InfrastructureTypeList = Loadable(
  lazy(() => import("./pages/InfrastructureTypeList"))
);
const InfrastructureTypeCreate = Loadable(
  lazy(() => import("./pages/InfrastructureTypeCreate"))
);
const InfrastructureTypeEdit = Loadable(
  lazy(() => import("./pages/InfrastructureTypeEdit"))
);
const InfrastructureTypeDisplay = Loadable(
  lazy(() => import("./pages/InfrastructureTypeDisplay"))
);

const infrastructureTypeRouter = [
  { path: "infrastructure-types", element: <InfrastructureTypeList /> },
  { path: "infrastructure-types/:id", element: <InfrastructureTypeDisplay /> },
  { path: "infrastructure-types/new", element: <InfrastructureTypeCreate /> },
  {
    path: "infrastructure-types/:id/edit",
    element: <InfrastructureTypeEdit />,
  },
];

export default infrastructureTypeRouter;
