import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const InfrastructureWellList = Loadable(
  lazy(() => import("./pages/InfrastructureWellList"))
);
const InfrastructureWellCreate = Loadable(
  lazy(() => import("./pages/InfrastructureWellCreate"))
);
const InfrastructureWellEdit = Loadable(
  lazy(() => import("./pages/InfrastructureWellEdit"))
);
const InfrastructureWellDisplay = Loadable(
  lazy(() => import("./pages/InfrastructureWellDisplay"))
);

const infrastructureWellRouter = [
  { path: "infrastructure-wells", element: <InfrastructureWellList /> },
  { path: "infrastructure-wells/:id", element: <InfrastructureWellDisplay /> },
  { path: "infrastructure-wells/new", element: <InfrastructureWellCreate /> },
  {
    path: "infrastructure-wells/:id/edit",
    element: <InfrastructureWellEdit />,
  },
];

export default infrastructureWellRouter;
