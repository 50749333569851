import React from "react";
// material
import { Typography } from "@mui/material";
// components
import CreateDialogMenuItem from "../../../../shared/components/CreateDialogMenuItem";
import { IrrigatedDevelopmentEntityUsagesForm } from "./index";
// ----------------------------------------------------------------------

export function IrrigatedDevelopmentEntityUsagesCreateDialogMenuItem({
  IrrigatedDevelopmentEntityUsagesType,
  onClose,
  onSave,
  onClick,
  btn,
}) {
  const setCurrentIrrigatedDevelopmentEntityUsages = () => {};
  const currentIrrigatedDevelopmentEntityUsages = {};

  return (
    <CreateDialogMenuItem
      btn={btn}
      onClose={onClose}
      onClick={onClick}
      menuText="Ajouter une entité d'aménagement irrigué"
      dialogTitle="Création de l'entité d'aménagement irrigué"
      dialogContextText={
        <>
          Création de nouvelle entité d'aménagement irrigué
          <Typography variant="h6" component={"span"}>
            {" "}
            {IrrigatedDevelopmentEntityUsagesType?.name ?? ""}
          </Typography>
        </>
      }
      formRender={({ onClose }) => {
        return (
          <IrrigatedDevelopmentEntityUsagesForm
            {...{
              currentIrrigatedDevelopmentEntityUsages,
              setCurrentIrrigatedDevelopmentEntityUsages,
            }}
            fromDialog={{
              onCancel: onClose,
              onSave: (createdIrrigatedDevelopmentEntityUsages) => {
                onSave && onSave(createdIrrigatedDevelopmentEntityUsages);
                onClose && onClose();
              },
            }}
          />
        );
      }}
    />
  );
}
