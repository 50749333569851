import React from 'react';

// material
import { styled } from '@mui/material/styles';
import { Box, Container, Typography } from '@mui/material';
// import { Facebook, Instagram, LinkedIn, Twitter } from '@mui/icons-material';

// hooks
import useCountdown from '../hooks/useCountdown';

// components
import Page from '../components/Page';
import ComingSoonIllustration from '../../assets/illustration_coming_soon';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  minHeight: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

const CountdownStyle = styled('div')({
  display: 'flex',
  justifyContent: 'center'
});

const SeparatorStyle = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 1),
  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(0, 2.5)
  }
}));

// ----------------------------------------------------------------------

export default function ComingSoon({date = '2022-01-20 07:30'}) {

  const countdown = useCountdown(new Date(date));

  return (
    <RootStyle title="En cours de construction">
      <Container>
        <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
          <Typography variant="h3" paragraph>
            Bientôt disponible!
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>Nous travaillons actuellement dur sur cette page!</Typography>

          <ComingSoonIllustration sx={{ my: 10, height: 240 }} />

          <CountdownStyle>
            <div>
              <Typography variant="h2">{countdown.days}</Typography>
              <Typography sx={{ color: 'text.secondary' }}>Jours</Typography>
            </div>

            <SeparatorStyle variant="h2">:</SeparatorStyle>

            <div>
              <Typography variant="h2">{countdown.hours}</Typography>
              <Typography sx={{ color: 'text.secondary' }}>Heures</Typography>
            </div>

            <SeparatorStyle variant="h2">:</SeparatorStyle>

            <div>
              <Typography variant="h2">{countdown.minutes}</Typography>
              <Typography sx={{ color: 'text.secondary' }}>Minutes</Typography>
            </div>

            <SeparatorStyle variant="h2">:</SeparatorStyle>

            <div>
              <Typography variant="h2">{countdown.seconds}</Typography>
              <Typography sx={{ color: 'text.secondary' }}>Secondes</Typography>
            </div>
          </CountdownStyle>

        </Box>
      </Container>
    </RootStyle>
  );
}
