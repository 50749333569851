import * as Yup from 'yup';
import create from 'zustand';


export const TypeSphereSchema = Yup.object().shape({
    name: Yup.string().default('').required('Le nom est obligatoire'),
    dataSource: Yup.string().default(''),
});

const initialTypeSphere = TypeSphereSchema.getDefaultFromShape();
export const InitialTypeSphere = () => ({...initialTypeSphere});

const setCurrentNewTypeSphere = set => typeSphere => {
    set(state => ({...state, currentNewTypeSphere: typeSphere ?? InitialTypeSphere()}));
}

const setCurrentUpdateTypeSphere = set => typeSphere => {
    set(state => ({...state, currentUpdateTypeSphere: typeSphere ?? InitialTypeSphere()}));
}

export const useTypeSphereStore = create(set => ({
    //State
    currentNewTypeSphere: InitialTypeSphere(),
    currentUpdateTypeSphere: InitialTypeSphere(),
    //Reducers
    setCurrentNewTypeSphere: setCurrentNewTypeSphere(set),
    setCurrentUpdateTypeSphere: setCurrentUpdateTypeSphere(set),
}))
