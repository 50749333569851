import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const ProductionOccurrenceList = Loadable(lazy(() => import('./pages/ProductionOccurrenceList')));
const ProductionOccurrenceCreate = Loadable(lazy(() => import('./pages/ProductionOccurrenceCreate')));
const ProductionOccurrenceEdit = Loadable(lazy(() => import('./pages/ProductionOccurrenceEdit')));
const ProductionOccurrenceDisplay = Loadable(lazy(() => import('./pages/ProductionOccurrenceDisplay')));

const productionOccurrenceRouter = [
    {path: 'production-occurrences', element: <ProductionOccurrenceList/>},
    {path: 'production-occurrences/:id', element: <ProductionOccurrenceDisplay/>},
    {path: 'production-occurrences/new', element: <ProductionOccurrenceCreate/>},
    {path: 'production-occurrences/:id/edit', element: <ProductionOccurrenceEdit/>},
];

export default productionOccurrenceRouter;
