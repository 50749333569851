import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const EntityAreaList = Loadable(lazy(() => import('./pages/EntityAreaList')));
const EntityAreaCreate = Loadable(lazy(() => import('./pages/EntityAreaCreate')));
const EntityAreaEdit = Loadable(lazy(() => import('./pages/EntityAreaEdit')));
const EntityAreaDisplay = Loadable(lazy(() => import('./pages/EntityAreaDisplay')));

const entityAreaRouter = [
    {path: 'entity-areas', element: <EntityAreaList/>},
    {path: 'entity-areas/:id', element: <EntityAreaDisplay/>},
    {path: 'entity-areas/new', element: <EntityAreaCreate/>},
    {path: 'entity-areas/:id/edit', element: <EntityAreaEdit/>},
];

export default entityAreaRouter;
