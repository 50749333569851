import React, {lazy} from 'react';
import SicknessTypeList from "./pages/SicknessTypeList";
import SicknessTypeDisplay from "./pages/SicknessTypeDisplay";
import SicknessTypeCreate from "./pages/SicknessTypeCreate";
import SicknessTypeEdit from "./pages/SicknessTypeEdit";

const sicknessTypeRouter = [
    {path: 'sickness-types', element: <SicknessTypeList/>},
    {path: 'sickness-types/:id', element: <SicknessTypeDisplay/>},
    {path: 'sickness-types/new', element: <SicknessTypeCreate/>},
    {path: 'sickness-types/:id/edit', element: <SicknessTypeEdit/>},
];

export default sicknessTypeRouter;
