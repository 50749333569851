import * as Yup from 'yup';
import create from 'zustand';


export const AmenagementTypeSchema = Yup.object().shape({
    name: Yup.string().default('').required('Le nom est obligatoire'),
    description: Yup.string().default(''),
    dataSource: Yup.string().default(''),
});

const initialAmenagementType = AmenagementTypeSchema.getDefaultFromShape();
export const InitialAmenagementType = () => ({...initialAmenagementType});

const setCurrentNewAmenagementType = set => amenagementType => {
    set(state => ({...state, currentNewAmenagementType: amenagementType ?? InitialAmenagementType()}));
}

const setCurrentUpdateAmenagementType = set => amenagementType => {
    set(state => ({...state, currentUpdateAmenagementType: amenagementType ?? InitialAmenagementType()}));
}

export const useAmenagementTypeStore = create(set => ({
    //State
    currentNewAmenagementType: InitialAmenagementType(),
    currentUpdateAmenagementType: InitialAmenagementType(),
    //Reducers
    setCurrentNewAmenagementType: setCurrentNewAmenagementType(set),
    setCurrentUpdateAmenagementType: setCurrentUpdateAmenagementType(set),
}))
