import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const WallTypeList = Loadable(lazy(() => import("./pages/WallTypeList")));
const WallTypeCreate = Loadable(lazy(() => import("./pages/WallTypeCreate")));
const WallTypeEdit = Loadable(lazy(() => import("./pages/WallTypeEdit")));
const WallTypeDisplay = Loadable(lazy(() => import("./pages/WallTypeDisplay")));

const wallTypeRouter = [
  { path: "wall-types", element: <WallTypeList /> },
  { path: "wall-types/:id", element: <WallTypeDisplay /> },
  { path: "wall-types/new", element: <WallTypeCreate /> },
  { path: "wall-types/:id/edit", element: <WallTypeEdit /> },
];

export default wallTypeRouter;
