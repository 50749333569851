import {gql, useQuery, useLazyQuery} from '@apollo/client';
import {parseCount, parseEntity} from "../../../../utils/gql.utils";
import {useEffect, useState} from "react";
import {useMessage} from "../../../../shared/hooks/useMessage";

export let needRefresh = false;
export const setRefresh = (value) => needRefresh = value;

export const dataRefYearFields = `
    id name begin end
`;

///////////////////// Find By ID /////////////////////
const GET_DATA_REF_YEAR_BY_ID = gql`
    query DataRefYearById($id: Int!) {
        dataRefYearById(id: $id) {
            error message entity { ${dataRefYearFields} }
        }
    }
`;

export const useDataRefYearById = (id) => {
    id = parseInt(id);
    return useQuery(GET_DATA_REF_YEAR_BY_ID, {
        variables: {id},
    });
}

export const useDataRefYear = (id) => {
    const result = useDataRefYearById(id);
    return parseEntity(result, 'dataRefYearById');
}

///////////////////// Search /////////////////////
const SEARCH_DATA_REF_YEARS = gql`
    query SearchDataRefYears($query: String, $page: Int, $size: Int) {
        searchDataRefYears(query: $query, page: $page, size: $size) {
            error message page {
                content { ${dataRefYearFields} }
                page hasNext hasPrevious totalPages, totalElements, numberOfElements,
            }
        }
    }
`;

export const useSearchDataRefYears = ({query = '', page = 0, size = 20}) => {
    const [dataRefYears, setDataRefYears] = useState([]);
    const [dataRefYearPage, setDataRefYearPage] = useState(null);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_DATA_REF_YEARS, {
        variables: {query, page, size},
    });

    useEffect(() => {
        if(_error) {
            showError('Erreur lors de l\'operation');
            setDataRefYearPage(null);
            setDataRefYears([]);
            return;
        }

        if (!searchData) return;

        const {error, message, page} = searchData.searchDataRefYears;


        if (error) {
            showError(message);
            setDataRefYearPage(null);
            setDataRefYears([]);
        } else {
            setDataRefYearPage(page);
            setDataRefYears(page.content);
        }


        if (needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({variables: {query, page, size}});
    }, [query, page, size]);

    return {
        dataRefYears, loading, search, refetch, dataRefYearPage
    }
}

///////////////////// Count /////////////////////
const COUNT_DATA_REF_YEARS = gql`
    query CountDataRefYears {
        countDataRefYears {
            error message count
        }
    }
`;

export const useCountDataRefYears = () => {
    const result = useQuery(COUNT_DATA_REF_YEARS, {
        fetchPolicy: 'network-only'
    });
    return parseCount(result, 'countDataRefYears');
}
