import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Refresh, Search} from '@mui/icons-material';
import {
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    Toolbar,
    OutlinedInput,
    InputAdornment,
    Box, Tooltip, Typography, Link
} from '@mui/material';

import {useSearchDataRefYears} from '../services/data.ref.year.gql';
import useDataRefYearService from '../services/data.ref.year.services';
import {PATH_ADMINISTRATIVE_DIVISION} from '../../administrative_division.path';
import { DataRefYearMoreMenu} from './index';
import {MyTablePagination, SortableTableHead, usePagination} from '../../../../shared/components/tables';
import {useDelete} from '../../../../shared/hooks/useDelete';
import {
    RefreshIconButton,
    sxTableRow,
    sxTableToolbar,
    TableCellLink,
    TableSearchField
} from "../../../../shared/components/tables/components.table.utils";

const TABLE_HEAD = [
    {id: 'id', label: 'ID', alignRight: false},
    {id: 'name', label: 'Nom', alignRight: false},
    {id: 'begin', label: 'Début', alignRight: false},
    {id: 'end', label: 'Fin', alignRight: false},
    {id: ''}
];

export default function DataRefYearTable() {
    const {
        page, order, filterName, rowsPerPage, orderBy, handleRequestSort,
        handleChangePage, handleChangeRowsPerPage, handleFilterByName
    } = usePagination();

    const {dataRefYears, refetch, dataRefYearPage} = useSearchDataRefYears({
        query: filterName,
        size: rowsPerPage,
        page
    });

    const {destroy} = useDataRefYearService();
    const {onDelete} = useDelete({
        destroyFn: destroy, onSuccess: refetch
    });

    return (
        <>
            <Toolbar sx={sxTableToolbar}>
                <TableSearchField
                    value={filterName}
                    onChange={handleFilterByName}
                    placeholder='Recherche année de reférence des données'/>

                <RefreshIconButton onRefresh={refetch}/>
            </Toolbar>
            <TableContainer sx={{minWidth: 800}}>
                <Table size='small'>
                    <SortableTableHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={dataRefYears.length}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {dataRefYears.map((row) => (<Row key={row.id} {...{row, onDelete}}/>))}
                    </TableBody>
                </Table>
            </TableContainer>

            <MyTablePagination
                page={dataRefYearPage}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
};

function Row({row, onDelete}) {
    const {id, name, begin, end} = row;

    return (
        <TableRow hover sx={sxTableRow}>
            <TableCell component='th' scope='row'>
                <TableCellLink to={PATH_ADMINISTRATIVE_DIVISION.dataRefYears + '/' + id} value={id}/>
            </TableCell>

            <TableCell component='th' scope='row'>
                <TableCellLink to={PATH_ADMINISTRATIVE_DIVISION.dataRefYears + '/' + id} value={name}/>
            </TableCell>
            <TableCell style={{minWidth: 160}}>
                <Typography variant='body2' noWrap> {begin} </Typography>
            </TableCell>
            <TableCell style={{minWidth: 160}}>
                <Typography variant='body2' noWrap> {end} </Typography>
            </TableCell>
            <TableCell align='right'>
                <DataRefYearMoreMenu id={id} onDelete={onDelete} currentDataRefYear={row} isIconButton hiddenNew/>
            </TableCell>
        </TableRow>
    );
}
