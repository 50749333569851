import React from 'react';
// material
import {Dashboard} from '@mui/icons-material';
// utils
import {PATH_REPORTING} from '../../../reporting.path';
import HomeEntryButton from "../../../../../home/components/HomeEntryButton";
import {useCountMyDashboards} from "../services/dashboard.profile.gql";

// ----------------------------------------------------------------------

export default function MyDashboardEntryButton() {

    const {count} = useCountMyDashboards();

    return (
        <HomeEntryButton
            value={count}
            icon={<Dashboard/>}
            label={`Mes tableaux de bord`}
            url={`${PATH_REPORTING.dashboard}/my-dashboards`}
        />
    );
}
