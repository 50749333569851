import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const AnalyzeInfrastructureList = Loadable(
  lazy(() => import("./pages/AnalyzeInfrastructureList"))
);
const AnalyzeInfrastructureCreate = Loadable(
  lazy(() => import("./pages/AnalyzeInfrastructureCreate"))
);
const AnalyzeInfrastructureEdit = Loadable(
  lazy(() => import("./pages/AnalyzeInfrastructureEdit"))
);
const AnalyzeInfrastructureDisplay = Loadable(
  lazy(() => import("./pages/AnalyzeInfrastructureDisplay"))
);

const analyzeInfrastructureRouter = [
  { path: "analyze-infrastructures", element: <AnalyzeInfrastructureList /> },
  {
    path: "analyze-infrastructures/:id",
    element: <AnalyzeInfrastructureDisplay />,
  },
  {
    path: "analyze-infrastructures/new",
    element: <AnalyzeInfrastructureCreate />,
  },
  {
    path: "analyze-infrastructures/:id/edit",
    element: <AnalyzeInfrastructureEdit />,
  },
];

export default analyzeInfrastructureRouter;
