import { useApiHttp } from "../../../../shared/hooks/useHttp";

const useInstrumentPolicyService = () => {
  const { crud } = useApiHttp();
  const { create, update, destroy, findById, findAll } = crud("instrument-policies");

  return {
    create,
    update,
    destroy,
    findById,
    findAll,
  };
};
export default useInstrumentPolicyService;
