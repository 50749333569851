import React from 'react';
// material
import {Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {AhpTypeForm} from '../components';

import {AhpTypeHeaderBreadcrumbs} from '../components';
import {useAhpTypeStore} from "../services/ahp.type.store";

// ----------------------------------------------------------------------

export default function AhpTypeCreate() {
    const {currentNewAhpType: currentAhpType, setCurrentNewAhpType: setCurrentAhpType} = useAhpTypeStore();
    return (
        <Page title="Pêche: Creation type AHP">
            <Container  maxWidth={'xl'}>
                <AhpTypeHeaderBreadcrumbs
                    heading='Creation de nouveau type AHP'
                    lastName='Nouveau type AHP'
                />
                <AhpTypeForm {...{currentAhpType, setCurrentAhpType}}/>
            </Container>
        </Page>
    );
}
