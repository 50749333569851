import React, {useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
// material
import {CircularProgress, Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {QuantitativeObservationTypeForm} from '../components';

import {QuantitativeObservationTypeHeaderBreadcrumbs} from '../components';

import {PATH_POTENTIAL_WATER_AVAILABILITY} from '../../potential.water.availability.path';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useQuantitativeObservationType} from '../services/quantitative.observation.type.gql';
import {useQuantitativeObservationTypeStore} from "../services/quantitative.observation.type.store";

export default function QuantitativeObservationTypeEdit() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {showError} = useMessage();

    const {setCurrentUpdateQuantitativeObservationType: setCurrentQuantitativeObservationType} = useQuantitativeObservationTypeStore();

    const {entity: currentQuantitativeObservationType, error, loading, errorMessage} = useQuantitativeObservationType(id);

    useEffect(() => {
        if (loading) return;
        if (error) {
            showError(errorMessage);
            navigate(PATH_POTENTIAL_WATER_AVAILABILITY.quantitativeObservationTypes);
        }
    }, [loading, error, errorMessage]);

    useEffect(() => {
        setCurrentQuantitativeObservationType(currentQuantitativeObservationType);
    }, [currentQuantitativeObservationType]);

    return (
        <Page title={"Disponibilite potentielle en eau: Modification type d'observation quantitative"}>
            <Container maxWidth={'xl'}>
                <QuantitativeObservationTypeHeaderBreadcrumbs
                    heading="Modification du type d'observation quantitative"
                    lastName={`Modification type d'observation quantitative - ${id}`}

                />

                {loading && <CircularProgress/>}
                {!loading && currentQuantitativeObservationType && <QuantitativeObservationTypeForm isEdit {...{currentQuantitativeObservationType, setCurrentQuantitativeObservationType}}/>}
            </Container>
        </Page>
    );
}
