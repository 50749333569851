import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const WallNatureList = Loadable(lazy(() => import("./pages/WallNatureList")));
const WallNatureCreate = Loadable(
  lazy(() => import("./pages/WallNatureCreate"))
);
const WallNatureEdit = Loadable(lazy(() => import("./pages/WallNatureEdit")));
const WallNatureDisplay = Loadable(
  lazy(() => import("./pages/WallNatureDisplay"))
);

const wallNatureRouter = [
  { path: "wall-natures", element: <WallNatureList /> },
  { path: "wall-natures/:id", element: <WallNatureDisplay /> },
  { path: "wall-natures/new", element: <WallNatureCreate /> },
  { path: "wall-natures/:id/edit", element: <WallNatureEdit /> },
];

export default wallNatureRouter;
