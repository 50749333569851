import React from 'react';
import ReactDOM from 'react-dom';
import {HelmetProvider} from 'react-helmet-async';
import {BrowserRouter} from 'react-router-dom';

import App from './App';

import {LocalizationProvider} from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';

import {ApolloProvider} from './shared/contexts/ApolloContext';
import {HttpProvider} from './shared/contexts/HttpContext';
import {AuthProvider} from './shared/contexts/AuthContext';
import {ReactQueryClientProvider} from './shared/contexts/ReactQueryContext';

ReactDOM.render(
    <HelmetProvider>
        <LocalizationProvider dateAdapter={DateAdapter}>
            <ReactQueryClientProvider>
                <BrowserRouter>
                    <AuthProvider>
                        <HttpProvider>
                            <ApolloProvider>
                                <App/>
                            </ApolloProvider>
                        </HttpProvider>
                    </AuthProvider>
                </BrowserRouter>
            </ReactQueryClientProvider>
        </LocalizationProvider>
    </HelmetProvider>,
    document.getElementById('root')
);
