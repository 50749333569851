import * as Yup from 'yup';
import create from 'zustand';

export const OccurrenceAgriculturalProductionSchema = Yup.object().shape({
    name: Yup.string().default('').required('Le nom est obligatoire'),
    saisonCulturale: Yup.string().default('').required('La saison culturelle est obligatoire'),
    superfTotIrriguee: Yup.string().default('').required('La superficie totale irriguée est obligatoire'),
    productionAnnee: Yup.string().default('').required('La production annuelle est obligatoire'),
    rendement: Yup.string().default('').required('Le rendelent est obligatoire'),
    valeurMarchande: Yup.string().default('').required('La valeur marchande est obligatoire'),
    irrigatedDevelopmentEntityUsages: Yup.number().required("entités d'Aménagement irrigués est obligatoire"),
    culturalSpeculationEntityUsages: Yup.number().required("Entités de spéculations culturales est obligatoire"),
    localWaterDepartment: Yup.number().required("Le service locale eau est obligatoire"),
    dataRefYear: Yup.number().required("L'année de reference est obligatoire est obligatoire"),
    dataSource: Yup.string().default(''),
});

const initialOccurrenceAgriculturalProduction = OccurrenceAgriculturalProductionSchema.getDefaultFromShape();
export const InitialOccurrenceAgriculturalProduction = () => ({...initialOccurrenceAgriculturalProduction});

const setCurrentNewOccurrenceAgriculturalProduction = set => occurrenceAgriculturalProduction => {
    set(state => ({...state, currentNewOccurrenceAgriculturalProduction: occurrenceAgriculturalProduction ?? InitialOccurrenceAgriculturalProduction()}));
}

const setCurrentUpdateOccurrenceAgriculturalProduction = set => occurrenceAgriculturalProduction => {
    set(state => ({...state, currentUpdateOccurrenceAgriculturalProduction: occurrenceAgriculturalProduction ?? InitialOccurrenceAgriculturalProduction()}));
}

export const useOccurrenceAgriculturalProductionStore = create(set => ({
    //State
    currentNewOccurrenceAgriculturalProduction: InitialOccurrenceAgriculturalProduction(),
    currentUpdateOccurrenceAgriculturalProduction: InitialOccurrenceAgriculturalProduction(),
    //Reducers
    setCurrentNewOccurrenceAgriculturalProduction: setCurrentNewOccurrenceAgriculturalProduction(set),
    setCurrentUpdateOccurrenceAgriculturalProduction: setCurrentUpdateOccurrenceAgriculturalProduction(set),
}))
