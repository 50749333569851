import React from 'react';
// components
import {WaterMassForm} from '../components';
import CreateDialogMenuItem from '../../../../shared/components/CreateDialogMenuItem';
import {useWaterMassStore} from "../services/water.mass.store";

export function WaterMassCreateDialogMenuItem({onClose, onSave, btn}) {
    const {
        currentNewWaterMass: currentWaterMass,
        setCurrentNewWaterMass: setCurrentWaterMass
    } = useWaterMassStore();
    return (
        <CreateDialogMenuItem
            btn={btn}
            onClose={onClose}
            menuText="Ajouter une masse d'eau"
            dialogTitle="Création d'une masse d'eau"
            dialogContextText={<> {`Création de nouvelle masse d'eau`} </>}
            formRender={({onClose}) => {
                return (
                    <WaterMassForm
                        {...{currentWaterMass, setCurrentWaterMass}}
                        fromDialog={{
                            onCancel: onClose,
                            onSave: (createdWaterMass) => {
                                onSave && onSave(createdWaterMass);
                                onClose && onClose();
                            }
                        }}/>
                );
            }}
        />
    );

}
