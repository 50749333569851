import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';

import MainLayout from '../../home/AppLayout';
import Loadable from '../../shared/components/Loadable';

import {PATH_CONSTRUCTION} from './construction.path';
import activityTypeConstructionRouter from "./activity_type_construction/activity.type.construction.router";
import massiveConstructionRouter from "./massive_construction/massive.construction.router";
import occurrenceAchievementRouter from "./occurrence_achievement/occurrence.achievement.router";
import samplingOccurrenceRouter from "./sampling_occurrence/sampling.occurrence.router";




const ConstructionHome = Loadable(lazy(() => import('./ConstructionHome')));

const constructionRouter = [{
    path: PATH_CONSTRUCTION.root,
    element: <MainLayout/>,
    children: [
        {path: PATH_CONSTRUCTION.root, element: <Navigate to={PATH_CONSTRUCTION.home} replace/>},
        {path: 'home', element: <ConstructionHome/>},

         ...activityTypeConstructionRouter,
         ...massiveConstructionRouter,
         ...occurrenceAchievementRouter,
         ...samplingOccurrenceRouter,
    ]
},];

export default constructionRouter;
