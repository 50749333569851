import React, {lazy} from 'react';
import LegislativeTextOccurrenceList from "./pages/LegislativeTextOccurrenceList";
import LegislativeTextOccurrenceDisplay from "./pages/LegislativeTextOccurrenceDisplay";
import LegislativeTextOccurrenceCreate from "./pages/LegislativeTextOccurrenceCreate";
import LegislativeTextOccurrenceEdit from "./pages/LegislativeTextOccurrenceEdit";

const legislativeTextOccurrenceRouter = [
    {path: 'legislative-text-occurrences', element: <LegislativeTextOccurrenceList/>},
    {path: 'legislative-text-occurrences/:id', element: <LegislativeTextOccurrenceDisplay/>},
    {path: 'legislative-text-occurrences/new', element: <LegislativeTextOccurrenceCreate/>},
    {path: 'legislative-text-occurrences/:id/edit', element: <LegislativeTextOccurrenceEdit/>},

];
export default legislativeTextOccurrenceRouter;
