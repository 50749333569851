import React, {lazy} from 'react';
import RiskManagementToolTypeList from "./pages/RiskManagementToolTypeList";
import RiskManagementToolTypeDisplay from "./pages/RiskManagementToolTypeDisplay";
import RiskManagementToolTypeCreate from "./pages/RiskManagementToolTypeCreate";
import RiskManagementToolTypeEdit from "./pages/RiskManagementToolTypeEdit";

const riskManagementToolTypeRouter = [
    {path: 'risk-management-tool-types', element: <RiskManagementToolTypeList/>},
    {path: 'risk-management-tool-types/:id', element: <RiskManagementToolTypeDisplay/>},
    {path: 'risk-management-tool-types/new', element: <RiskManagementToolTypeCreate/>},
    {path: 'risk-management-tool-types/:id/edit', element: <RiskManagementToolTypeEdit/>},
];

export default riskManagementToolTypeRouter;
