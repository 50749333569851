import React from 'react';
import {Navigate} from 'react-router-dom';

import ComingSoon from '../../shared/pages/ComingSoon';
import MainLayout from '../../home/AppLayout';
import FakeModuleHome from './FakeModuleHome';
import fakeModules from './fake.modules';

export const fakeRouters = () => fakeModules.map(fakeModuleRouter);

const fakeModuleRouter = ({title, Icon, moduleName, moduleDescription, path, features}) => {
    return {
        path,
        element: <MainLayout/>,
        children: [
            {path, element: <Navigate to={`${path}/home`} replace/>},
            {
                path: 'home',
                element: <FakeModuleHome {...{title, Icon, moduleName, moduleDescription, path, features }}/>
            },
            ...features.flatMap(({path, date}) => fakeFeatureRouter(path, date)),
        ]
    }
}

export const fakeFeatureRouter = (path, date) => {
    if (path === '/home') return [];

    path = path.substring(1);

    return [
        {path: `${path}`, element: <ComingSoon date={date}/>},
        {path: `${path}/:id`, element: <ComingSoon date={date}/>},
        {path: `${path}/new`, element: <ComingSoon date={date}/>},
        {path: `${path}/:id/edit`, element: <ComingSoon date={date}/>},
    ];
}
