import {useApiHttp} from '../../../../shared/hooks/useHttp';

const useRiskManagementToolsImplementationOccurrenceService = () => {
    const {crud} = useApiHttp();

    const {
        create,
        update,
        destroy,
        findById,
        findAll,
    } = crud('risk-management-tools-implementation-occurrences');

    return {
        create,
        update,
        destroy,
        findById,
        findAll,
    }
}
export default useRiskManagementToolsImplementationOccurrenceService;
