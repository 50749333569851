import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Add} from '@mui/icons-material';
import { Card, Button, Container } from '@mui/material';
import {PATH_IRRIGATION_NEED_WATER} from '../../irrigation.need.water.path';
import {Page} from '../../../../shared/components';
import {AhaTypeTable, AhaTypeListHeaderBreadcrumbs} from '../components';

export default function AhaTypeList() {
    return (
        <Page title="Type aménagement hydro-agricole: Liste des types aménagements hydro-agricoles">
            <Container maxWidth={'xl'}>
                <AhaTypeListHeaderBreadcrumbs
                    heading='Types aménagements hydro-agricoles'
                    lastName='Liste des types aménagements hydro-agricoles'
                    action={
                        <Button
                            variant="contained"
                            component={RouterLink}
                            to={PATH_IRRIGATION_NEED_WATER.aha_types + '/new'}
                            startIcon={<Add/>}>
                            Nouveau
                        </Button>
                    }
                />

                <Card>
                    <AhaTypeTable/>
                </Card>
            </Container>
        </Page>
    );
}
