import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const TypeWasteWaterList = Loadable(lazy(() => import('./pages/TypeWasteWaterList')));
const TypeWasteWaterCreate = Loadable(lazy(() => import('./pages/TypeWasteWaterCreate')));
const TypeWasteWaterEdit = Loadable(lazy(() => import('./pages/TypeWasteWaterEdit')));
const TypeWasteWaterDisplay = Loadable(lazy(() => import('./pages/TypeWasteWaterDisplay')));

const typeWasteWaterRouter = [
    {path: 'type-waste-waters', element: <TypeWasteWaterList/>},
    {path: 'type-waste-waters/:id', element: <TypeWasteWaterDisplay/>},
    {path: 'type-waste-waters/new', element: <TypeWasteWaterCreate/>},
    {path: 'type-waste-waters/:id/edit', element: <TypeWasteWaterEdit/>},
];

export default typeWasteWaterRouter;
