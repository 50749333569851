export const showEntry = (keys = [''], query) => {
    const queries = query.toLowerCase()
        .split(' ')
        .filter(token => token.trim().length > 0);

    if(queries.length === 0) return true;

    for (const key of keys) {
        for (const _query of queries) {
            if (key.toLowerCase().indexOf(_query) > -1) return true;
        }
    }
    return false;
}
