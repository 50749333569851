import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const SourceDrinkingWaterList = Loadable(lazy(() => import('./pages/SourceDrinkingWaterList')));
const SourceDrinkingWaterCreate = Loadable(lazy(() => import('./pages/SourceDrinkingWaterCreate')));
const SourceDrinkingWaterEdit = Loadable(lazy(() => import('./pages/SourceDrinkingWaterEdit')));
const SourceDrinkingWaterDisplay = Loadable(lazy(() => import('./pages/SourceDrinkingWaterDisplay')));

const sourceDrinkingWaterRouter = [
    {path: 'source-drinking-waters', element: <SourceDrinkingWaterList/>},
    {path: 'source-drinking-waters/:id', element: <SourceDrinkingWaterDisplay/>},
    {path: 'source-drinking-waters/new', element: <SourceDrinkingWaterCreate/>},
    {path: 'source-drinking-waters/:id/edit', element: <SourceDrinkingWaterEdit/>},
];

export default sourceDrinkingWaterRouter;
