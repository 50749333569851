import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const OccurrenceUseList = Loadable(lazy(() => import('./pages/OccurrenceUseList')));
const OccurrenceUseCreate = Loadable(lazy(() => import('./pages/OccurrenceUseCreate')));
const OccurrenceUseEdit = Loadable(lazy(() => import('./pages/OccurrenceUseEdit')));
const OccurrenceUseDisplay = Loadable(lazy(() => import('./pages/OccurrenceUseDisplay')));

const occurrenceUseRouter = [
    {path: 'occurrence-uses', element: <OccurrenceUseList/>},
    {path: 'occurrence-uses/:id', element: <OccurrenceUseDisplay/>},
    {path: 'occurrence-uses/new', element: <OccurrenceUseCreate/>},
    {path: 'occurrence-uses/:id/edit', element: <OccurrenceUseEdit/>},
];

export default occurrenceUseRouter;
