import React from 'react';
// components
import {OccurrenceAgriculturalProductionForm} from '../components';
import CreateDialogMenuItem from '../../../../shared/components/CreateDialogMenuItem';

export function OccurrenceAgriculturalProductionCreateDialogMenuItem({
                                                              irrigatedDevelopmentEntityUsages,
                                                              dataRefYear,
                                                              onClose,
                                                              onSave,
                                                              btn
                                                          }) {

    const setCurrentOccurrenceAgriculturalProduction = () => {
    };
    const currentOccurrenceAgriculturalProduction = {
        irrigatedDevelopmentEntityUsages: irrigatedDevelopmentEntityUsages?.id,
        irrigatedDevelopmentEntityUsagesObj: irrigatedDevelopmentEntityUsages,
        dataRefYear: dataRefYear?.id,
        dataRefYearObj: dataRefYear
    };

    return (
        <CreateDialogMenuItem
            btn={btn}
            onClose={onClose}
            menuText="Ajouter une occurrences de productions agricoles"
            dialogTitle="Création de occurrences de productions agricoles"
            dialogContextText={<> {`Création de nouvelle occurrences de productions agricoles`} </>}
            formRender={({onClose}) => {
                return (
                    <OccurrenceAgriculturalProductionForm
                        {...{currentOccurrenceAgriculturalProduction, setCurrentOccurrenceAgriculturalProduction}}
                        fromDialog={{
                            onCancel: onClose,
                            onSave: (createdOccurrenceAgriculturalProduction) => {
                                onSave && onSave(createdOccurrenceAgriculturalProduction);
                                onClose && onClose();
                            }
                        }}/>
                );
            }}
        />
    );

}
