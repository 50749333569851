import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useNavigate} from 'react-router-dom';
import {Form, FormikProvider, useFormik} from 'formik';
import {Box, Card, Stack} from '@mui/material';
import {PATH_RISK_PROTECTION} from '../../risk.protection.path';
import {
    FormikAutocomplete,
    FormikTextField,
    FormRowStack,
    SubmitCancelGroupButton
} from '../../../../shared/components/froms/FormComponents';
import {setRefresh} from '../services/risk.management.tools.implementation.occurrence.gql';
import useRiskManagementToolsImplementationOccurrenceService
    from '../services/risk.management.tools.implementation.occurrence.services';
import {useConnectFormState} from "../../../../shared/hooks/useFormikState";
import {
    RiskManagementToolsImplementationOccurrenceSchema,
    InitialRiskManagementToolsImplementationOccurrence
} from "../services/risk.management.tools.implementation.occurrence.store";
import {formErrorMessageParser} from "../../../../utils/utils";

import {useSearchRiskManagementToolApplicationDegrees} from "../../risk_management_tool_application_degree/services/risk.management.tool.application.degree.gql";
import {useSearchWaterMasses} from "../../../water_use/water_mass/services/water.mass.gql";
import {useSearchRiskManagementTools} from "../../risk_management_tool/services/risk.management.tool.gql";
import {RiskManagementToolApplicationDegreeCreateDialogMenuItem} from "../../risk_management_tool_application_degree/components";
import {useSearchDataRefYears} from "../../../administrative_division/data_ref_year/services/data.ref.year.gql";
import {DataRefYearCreateDialogMenuItem} from "../../../administrative_division/data_ref_year/components";
import {WaterMassCreateDialogMenuItem} from "../../../water_use/water_mass/components/WaterMassDialog";
import {RiskManagementToolCreateDialogMenuItem} from "../../risk_management_tool/components";

RiskManagementToolsImplementationOccurrenceForm.propTypes = {
    isEdit: PropTypes.bool,
    currentRiskManagementToolsImplementationOccurrence: PropTypes.object,
    fromDialog: PropTypes.object,
};

export default function RiskManagementToolsImplementationOccurrenceForm({
                                                                            isEdit,
                                                                            currentRiskManagementToolsImplementationOccurrence,
                                                                            setCurrentRiskManagementToolsImplementationOccurrence,
                                                                            fromDialog
                                                                        }) {
    const navigate = useNavigate();
    const {showSuccess, showError} = useMessage();
    const {create, update} = useRiskManagementToolsImplementationOccurrenceService();

    const initRiskManagementToolApplicationDegreeQuery = currentRiskManagementToolsImplementationOccurrence?.riskManagementToolApplicationDegreeObj?.name ?? fromDialog?.riskManagementToolApplicationDegree?.name ?? '';
    const [riskManagementToolApplicationDegreeQuery, setRiskManagementToolApplicationDegreeQuery] = useState(initRiskManagementToolApplicationDegreeQuery);
    const {
        riskManagementToolApplicationDegrees,
        riskManagementToolApplicationDegreeLoading
    } = useSearchRiskManagementToolApplicationDegrees({riskManagementToolApplicationDegreeQuery});

    const initDataRefYearQuery = currentRiskManagementToolsImplementationOccurrence?.dataRefYearObj?.name ?? fromDialog?.dataRefYear?.name ?? '';
    const [dataRefYearQuery, setDataRefYearQuery] = useState(initDataRefYearQuery);
    const {dataRefYears, dataRefYearLoading} = useSearchDataRefYears({dataRefYearQuery});

    const initQueryWaterMass = currentRiskManagementToolsImplementationOccurrence?.waterMassObj?.wording ?? fromDialog?.waterMass?.wording ?? '';
    const [waterMassQuery, setWaterMassQuery] = useState(initQueryWaterMass);
    const {waterMasses, waterMassLoading} = useSearchWaterMasses({waterMassQuery});

    const initRiskManagementToolQuery = currentRiskManagementToolsImplementationOccurrence?.riskManagementToolObj?.name ?? fromDialog?.riskManagementTool?.name ?? '';
    const [riskManagementToolQuery, setRiskManagementToolQuery] = useState(initRiskManagementToolQuery);
    const {riskManagementTools, riskManagementToolLoading} = useSearchRiskManagementTools({riskManagementToolQuery});


    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: RiskManagementToolsImplementationOccurrenceSchema,
        initialValues: InitialRiskManagementToolsImplementationOccurrence(),
        onSubmit: async (riskManagementToolsImplementationOccurrence, {setSubmitting}) => {
            try {
                riskManagementToolsImplementationOccurrence = RiskManagementToolsImplementationOccurrenceSchema.cast(riskManagementToolsImplementationOccurrence);

                let message;
                if (!isEdit) {
                    riskManagementToolsImplementationOccurrence = await create(riskManagementToolsImplementationOccurrence);
                    message = 'Création avec succès';
                } else {
                    riskManagementToolsImplementationOccurrence = await update(currentRiskManagementToolsImplementationOccurrence.id, riskManagementToolsImplementationOccurrence);
                    message = 'Modification avec succès';
                }

                setRefresh(true);
                setCurrentRiskManagementToolsImplementationOccurrence();

                setSubmitting(false);
                showSuccess(message);

                if (fromDialog) {
                    fromDialog.onSave(riskManagementToolsImplementationOccurrence);
                } else {
                    navigate(`${PATH_RISK_PROTECTION.riskManagementToolsImplementationOccurrences}/${riskManagementToolsImplementationOccurrence.id}`);
                }
            } catch (error) {
                setSubmitting(false);
                showError(formErrorMessageParser({error, isEdit}));
            }
        }
    });

    const {values, setValues, handleSubmit, isSubmitting, setFieldValue} = formik;

    useConnectFormState({
        formValues: values,
        setFormValues: setValues,
        stateValues: currentRiskManagementToolsImplementationOccurrence,
        setStateValues: setCurrentRiskManagementToolsImplementationOccurrence,
    });

    const onCancel = (_) => {
        setCurrentRiskManagementToolsImplementationOccurrence();
        if (fromDialog) {
            fromDialog.onCancel();
        } else {
            navigate(PATH_RISK_PROTECTION.riskManagementToolsImplementationOccurrences);
        }
    };

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>

                <Card sx={{p: 3}}>

                    <Stack spacing={3}>

                        <FormRowStack>
                            <FormikTextField label='Nom' name='name' formik={formik}/>
                        </FormRowStack>

                        <FormRowStack>
                            <Box flexGrow={1} display='flex' flexDirection='row'>
                                <FormikAutocomplete
                                    name='riskManagementToolApplicationDegree'
                                    label="Degre d'application des outils de gestion de risques liés à l'eau"
                                    options={riskManagementToolApplicationDegrees}
                                    labelField='name'
                                    embeddedObjField='riskManagementToolApplicationDegreeObj'
                                    {...{
                                        riskManagementToolApplicationDegreeQuery,
                                        formik,
                                        riskManagementToolApplicationDegreeLoading,
                                        setRiskManagementToolApplicationDegreeQuery
                                    }}
                                />
                                <Box sx={{py: 1, ml: 1}}>
                                    <RiskManagementToolApplicationDegreeCreateDialogMenuItem btn
                                                                                             onSave={(createdRiskManagementToolApplicationDegree) => {
                                                                                                 currentRiskManagementToolsImplementationOccurrence.riskManagementToolApplicationDegreeObj = createdRiskManagementToolApplicationDegree;
                                                                                                 setRiskManagementToolApplicationDegreeQuery(createdRiskManagementToolApplicationDegree?.name ?? '');
                                                                                                 setFieldValue('riskManagementToolApplicationDegree', createdRiskManagementToolApplicationDegree?.id);
                                                                                             }}/>
                                </Box>
                            </Box>

                            <Box flexGrow={1} display='flex' flexDirection='row'>
                                <FormikAutocomplete
                                    name='dataRefYear'
                                    label="Année de reférence des données"
                                    options={dataRefYears}
                                    labelField='name'
                                    embeddedObjField='dataRefYearObj'
                                    {...{dataRefYearQuery, formik, dataRefYearLoading, setDataRefYearQuery}}
                                />
                                <Box sx={{py: 1, ml: 1}}>
                                    <DataRefYearCreateDialogMenuItem btn onSave={(createdDataRefYear) => {
                                        currentRiskManagementToolsImplementationOccurrence.dataRefYearObj = createdDataRefYear;
                                        setDataRefYearQuery(createdDataRefYear?.name ?? '');
                                        setFieldValue('dataRefYear', createdDataRefYear?.id);
                                    }}/>
                                </Box>
                            </Box>
                        </FormRowStack>

                        <FormRowStack>
                            <Box flexGrow={1} display='flex' flexDirection='row'>
                                <FormikAutocomplete
                                    name='waterMass'
                                    label="Mass d'eau"
                                    options={waterMasses}
                                    labelField='wording'
                                    embeddedObjField='waterMassObj'
                                    {...{waterMassQuery, formik, waterMassLoading, setWaterMassQuery}}
                                />

                                <Box sx={{py: 1, ml: 1}}>
                                    <WaterMassCreateDialogMenuItem btn onSave={(createdWaterMass) => {
                                        currentRiskManagementToolsImplementationOccurrence.waterMassObj = createdWaterMass;
                                        setWaterMassQuery(createdWaterMass?.wording ?? '');
                                        setFieldValue('waterMass', createdWaterMass?.id);
                                    }}/>
                                </Box>
                            </Box>

                            <Box flexGrow={1} display='flex' flexDirection='row'>
                                <FormikAutocomplete
                                    name='riskManagementTool'
                                    label="Outils de gestion de risques liés à l'eau"
                                    options={riskManagementTools}
                                    labelField='name'
                                    embeddedObjField='riskManagementToolObj'
                                    {...{
                                        riskManagementToolQuery,
                                        formik,
                                        riskManagementToolLoading,
                                        setRiskManagementToolQuery
                                    }}
                                />
                                <Box sx={{py: 1, ml: 1}}>
                                    <RiskManagementToolCreateDialogMenuItem btn onSave={(createdRiskManagementTool) => {
                                        currentRiskManagementToolsImplementationOccurrence.riskManagementToolObj = createdRiskManagementTool;
                                        setRiskManagementToolQuery(createdRiskManagementTool?.name ?? '');
                                        setFieldValue('riskManagementTool', createdRiskManagementTool?.id);
                                    }}/>
                                </Box>
                            </Box>
                        </FormRowStack>


                        <FormRowStack>
                            <FormikTextField label='Justification' name='justification' formik={formik}/>
                            <FormikTextField label='Source de données' name='dataSource' formik={formik}/>
                        </FormRowStack>

                        <SubmitCancelGroupButton {...{isEdit, isSubmitting, onCancel}}/>
                    </Stack>

                </Card>

            </Form>
        </FormikProvider>
    );
}
