import {useApiHttp} from '../../../../shared/hooks/useHttp';

const useLegislativeTextOccurrenceService = () => {
    const {crud} = useApiHttp();

    const {
        create,
        update,
        destroy,
        findById,
        findAll,
    } = crud('legislative-text-occurrences');

    return {
        create,
        update,
        destroy,
        findById,
        findAll
    }
}

export default useLegislativeTextOccurrenceService;
