import React from "react";
// material
import { Typography } from "@mui/material";
// components
import CreateDialogMenuItem from "../../../../shared/components/CreateDialogMenuItem";
import { CulturalSpeculationEntityUsagesForm } from "./index";
// ----------------------------------------------------------------------

export function CulturalSpeculationEntityUsagesCreateDialogMenuItem({
  CulturalSpeculationEntityUsagesType,
  onClose,
  onSave,
  onClick,
  btn,
}) {
  const setCurrentCulturalSpeculationEntityUsages = () => {};
  const currentCulturalSpeculationEntityUsages = {};

  return (
    <CreateDialogMenuItem
      btn={btn}
      onClose={onClose}
      onClick={onClick}
      menuText="Ajouter une entité de spéculations culturales"
      dialogTitle="Création de la entité de spéculations culturales"
      dialogContextText={
        <>
          Création de nouvelle spéculation culturale
          <Typography variant="h6" component={"span"}>
            {" "}
            {CulturalSpeculationEntityUsagesType?.name ?? ""}
          </Typography>
        </>
      }
      formRender={({ onClose }) => {
        return (
          <CulturalSpeculationEntityUsagesForm
            {...{
              currentCulturalSpeculationEntityUsages,
              setCurrentCulturalSpeculationEntityUsages,
            }}
            fromDialog={{
              onCancel: onClose,
              onSave: (createdCulturalSpeculationEntityUsages) => {
                onSave && onSave(createdCulturalSpeculationEntityUsages);
                onClose && onClose();
              },
            }}
          />
        );
      }}
    />
  );
}
