import {gql, useQuery, useLazyQuery} from '@apollo/client';
import {parseCount, parseEntity} from '../../../../utils/gql.utils';
import {useEffect, useState} from 'react';
import {useMessage} from '../../../../shared/hooks/useMessage';

export let needRefresh = false;
export const setRefresh = (value) => needRefresh = value;

export const typeSphereFields = `
    id name dataSource
`;


///////////////////// Find By ID /////////////////////
const GET_TYPE_SPHERE_BY_ID = gql`
    query TypeSphereById($id: Int!) {
        typeSphereById(id: $id) {
            error message entity { ${typeSphereFields} }
        }
    }
`;

export const useTypeSphereById = (id) => {
    id = parseInt(id);
    return useQuery(GET_TYPE_SPHERE_BY_ID, {
        variables: {id},
        fetchPolicy: 'network-only'
    });
}

export const useTypeSphere = (id) => {
    const result = useTypeSphereById(id);
    return parseEntity(result, 'typeSphereById');
}

///////////////////// Search /////////////////////
const SEARCH_TYPE_SPHERE = gql`
    query SearchTypeSpheres($query: String, $page: Int, $size: Int) {
        searchTypeSpheres(query: $query, page: $page, size: $size) {
            error message page {
                content { ${typeSphereFields} }
                page hasNext hasPrevious totalPages totalElements numberOfElements
            }
        }
    }
`;

export const useSearchTypeSpheres = ({query = '', page = 0, size = 20}) => {
    const [typeSpheres, setTypeSpheres] = useState([]);
    const [typeSpherePage, setTypeSpherePage] = useState(null);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_TYPE_SPHERE, {
        variables: {query, page, size},
    });

    useEffect(() => {
        if(_error) {
            showError('Erreur lors de l\'operation');
            setTypeSpherePage(null);
            setTypeSpheres([]);
            return;
        }

        if(!searchData) return;

        const {error, message, page} = searchData.searchTypeSpheres;

        if (error) {
            showError(message);
            setTypeSpherePage(null);
            setTypeSpheres([]);
        } else {
            setTypeSpherePage(page);
            setTypeSpheres(page.content);
        }

        if(needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({ variables: { query, page, size } });
    }, [query, page, size]);

    return {
        typeSpheres: typeSpheres, loading, search, refetch, typeSpherePage
    }
}

///////////////////// Count /////////////////////
const COUNT_TYPE_SPHERE = gql`
    query CountTypeSpheres {
        countTypeSpheres {
            error message count
        }
    }
`;

export const useCountTypeSpheres = () => {
    const result = useQuery(COUNT_TYPE_SPHERE, {
        fetchPolicy: 'network-only'
    });
    return parseCount(result, 'countTypeSpheres');
}
