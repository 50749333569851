import React from 'react';
import {MultilineChartOutlined} from '@mui/icons-material';
import {path} from '../../home/sidebar/paths';

//Features
const ROOTS_REPORTING = '/reporting';
const pathReporting = path(ROOTS_REPORTING);
export const PATH_REPORTING = {
    root: ROOTS_REPORTING,
    home: pathReporting('/home'),
    reporting: pathReporting('/reporting'),
    dashboard: pathReporting('/dashboards'),
    widget: pathReporting('/widgets'),
    tests: pathReporting('/tests'),
};

export const reportingMenu = [
    {
        title: 'Reporting',
        path: PATH_REPORTING.root,
        icon: <MultilineChartOutlined/>,
        children: [
            { title: 'Accueil', path: PATH_REPORTING.home },
            { title: `Reporting`, path: PATH_REPORTING.reporting },
            { title: 'Tableau de bord', path: PATH_REPORTING.dashboard },
            { title: 'Widget', path: PATH_REPORTING.widget },
            // { title: 'Tests', path: `${PATH_REPORTING.tests}/form` },
        ]
    }
];
