import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { parseCount, parseEntity } from "../../../../utils/gql.utils";
import { useEffect, useState } from "react";
import { useMessage } from "../../../../shared/hooks/useMessage";

export let needRefresh = false;
export const setRefresh = (value) => (needRefresh = value);

export const waterTypeFields = `
    id wording description
`;

///////////////////// Find By ID /////////////////////
const GET_BASIN_BY_ID = gql`
    query WaterTypeById($id: Int!) {
        waterTypeById(id: $id) {
            error message entity { ${waterTypeFields} }
        }
    }
`;

export const useWaterTypeById = (id) => {
  id = parseInt(id);
  return useQuery(GET_BASIN_BY_ID, {
    variables: { id },
    fetchPolicy: "network-only",
  });
};

export const useWaterType = (id) => {
  const result = useWaterTypeById(id);
  return parseEntity(result, "waterTypeById");
};

///////////////////// Search /////////////////////
const SEARCH_BASINS = gql`
    query SearchWaterTypes($query: String, $page: Int, $size: Int) {
        searchWaterTypes(query: $query, page: $page, size: $size) {
            error message page {
                content { ${waterTypeFields} }
                page hasNext hasPrevious totalPages totalElements numberOfElements
            }
        }
    }
`;

export const useSearchWaterTypes = ({ query = "", page = 0, size = 20 }) => {
  const [waterTypes, setWaterTypes] = useState([]);
  const [waterTypePage, setWaterTypePage] = useState(null);

  const { showError } = useMessage();

  const [search, { data: searchData, refetch, loading, error: _error }] =
    useLazyQuery(SEARCH_BASINS, {
      variables: { query, page, size },
    });

  useEffect(() => {
    if (_error) {
      showError("Erreur lors de l'operation");
      setWaterTypePage(null);
      setWaterTypes([]);
      return;
    }

    if (!searchData) return;

    const { error, message, page } = searchData.searchWaterTypes;

    if (error) {
      showError(message);
      setWaterTypePage(null);
      setWaterTypes([]);
    } else {
      setWaterTypePage(page);
      setWaterTypes(page.content);
    }

    if (needRefresh) {
      refetch();
      setRefresh(false);
    }
  }, [_error, searchData, needRefresh]);

  useEffect(() => {
    search({ variables: { query, page, size } });
  }, [query, page, size]);

  return {
    waterTypes,
    loading,
    search,
    refetch,
    waterTypePage,
  };
};

///////////////////// Count /////////////////////
const COUNT_BASINS = gql`
  query CountWaterTypes {
    countWaterTypes {
      error
      message
      count
    }
  }
`;

export const useCountWaterTypes = () => {
  const result = useQuery(COUNT_BASINS, {
    fetchPolicy: "network-only",
  });
  return parseCount(result, "countWaterTypes");
};
