import React from 'react';
// material
import {Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {InstitutionalOrganizationalFrameworkStructureOccurrenceForm} from '../components';

import {InstitutionalOrganizationalFrameworkStructureOccurrenceHeaderBreadcrumbs} from '../components';
import {useInstitutionalOrganizationalFrameworkStructureOccurrenceStore} from "../services/institutional.organizational.framework.structure.occurrence.store";

// ----------------------------------------------------------------------

export default function InstitutionalOrganizationalFrameworkStructureOccurrenceCreate() {
    const {currentNewInstitutionalOrganizationalFrameworkStructureOccurrence: currentInstitutionalOrganizationalFrameworkStructureOccurrence, setCurrentNewInstitutionalOrganizationalFrameworkStructureOccurrence: setCurrentInstitutionalOrganizationalFrameworkStructureOccurrence} = useInstitutionalOrganizationalFrameworkStructureOccurrenceStore();
    return (
        <Page title="Gire environment favorable: Creation occurrences d'état de dynamisme du cadre institutionnel et organisationnel">
            <Container  maxWidth={'xl'}>
                <InstitutionalOrganizationalFrameworkStructureOccurrenceHeaderBreadcrumbs
                    heading="Creation de nouvelle occurrences d'état de dynamisme du cadre institutionnel et organisationnel"
                    lastName="Nouvelle occurrence d'état de dynamisme du cadre institutionnel et organisationnel"
                />
                <InstitutionalOrganizationalFrameworkStructureOccurrenceForm {...{currentInstitutionalOrganizationalFrameworkStructureOccurrence, setCurrentInstitutionalOrganizationalFrameworkStructureOccurrence}}/>
            </Container>
        </Page>
    );
}
