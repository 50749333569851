import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const TypeInstrumentList = Loadable(lazy(() => import('./pages/TypeInstrumentList')));
const TypeInstrumentCreate = Loadable(lazy(() => import('./pages/TypeInstrumentCreate')));
const TypeInstrumentEdit = Loadable(lazy(() => import('./pages/TypeInstrumentEdit')));
const TypeInstrumentDisplay = Loadable(lazy(() => import('./pages/TypeInstrumentDisplay')));

const typeInstrumentRouter = [
    {path: 'type-instruments', element: <TypeInstrumentList/>},
    {path: 'type-instruments/:id', element: <TypeInstrumentDisplay/>},
    {path: 'type-instruments/new', element: <TypeInstrumentCreate/>},
    {path: 'type-instruments/:id/edit', element: <TypeInstrumentEdit/>},
];

export default typeInstrumentRouter;
