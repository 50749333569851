import React, {lazy} from 'react';
import QuantitativeObservationTypeList from "./pages/QuantitativeObservationTypeList";
import QuantitativeObservationTypeDisplay from "./pages/QuantitativeObservationTypeDisplay";
import QuantitativeObservationTypeCreate from "./pages/QuantitativeObservationTypeCreate";
import QuantitativeObservationTypeEdit from "./pages/QuantitativeObservationTypeEdit";

const quantitativeObservationTypeRouter = [
    {path: 'quantitative-observation-types', element: <QuantitativeObservationTypeList/>},
    {path: 'quantitative-observation-types/:id', element: <QuantitativeObservationTypeDisplay/>},
    {path: 'quantitative-observation-types/new', element: <QuantitativeObservationTypeCreate/>},
    {path: 'quantitative-observation-types/:id/edit', element: <QuantitativeObservationTypeEdit/>},
];
export default quantitativeObservationTypeRouter;
