import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const OccurrenceRateList = Loadable(lazy(() => import('./pages/OccurrenceRateList')));
const OccurrenceRateCreate = Loadable(lazy(() => import('./pages/OccurrenceRateCreate')));
const OccurrenceRateEdit = Loadable(lazy(() => import('./pages/OccurrenceRateEdit')));
const OccurrenceRateDisplay = Loadable(lazy(() => import('./pages/OccurrenceRateDisplay')));

const occurrenceRateRouter = [
    {path: 'occurrence-rates', element: <OccurrenceRateList/>},
    {path: 'occurrence-rates/:id', element: <OccurrenceRateDisplay/>},
    {path: 'occurrence-rates/new', element: <OccurrenceRateCreate/>},
    {path: 'occurrence-rates/:id/edit', element: <OccurrenceRateEdit/>},
];

export default occurrenceRateRouter;
