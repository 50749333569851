import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const OccurrenceDevelopmentActionList = Loadable(lazy(() => import('./pages/OccurrenceDevelopmentActionList')));
const OccurrenceDevelopmentActionCreate = Loadable(lazy(() => import('./pages/OccurrenceDevelopmentActionCreate')));
const OccurrenceDevelopmentActionEdit = Loadable(lazy(() => import('./pages/OccurrenceDevelopmentActionEdit')));
const OccurrenceDevelopmentActionDisplay = Loadable(lazy(() => import('./pages/OccurrenceDevelopmentActionDisplay')));

const occurrenceDevelopmentActionRouter = [
    {path: 'occurrence-development-actions', element: <OccurrenceDevelopmentActionList/>},
    {path: 'occurrence-development-actions/:id', element: <OccurrenceDevelopmentActionDisplay/>},
    {path: 'occurrence-development-actions/new', element: <OccurrenceDevelopmentActionCreate/>},
    {path: 'occurrence-development-actions/:id/edit', element: <OccurrenceDevelopmentActionEdit/>},
];

export default occurrenceDevelopmentActionRouter;
