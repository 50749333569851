import React from 'react';
import {Domain} from '@mui/icons-material';
import {path} from '../../home/sidebar/paths';

//Features
const DOMESTIC = '/domestics';
const _path = path(DOMESTIC);
export const PATH_DOMESTIC = {
    root: DOMESTIC,
    home: _path('/home'),
    waterRights: _path("/water-rights"),
    operationalUnities: _path("/operational-unities"),
    operationalEntities: _path("/operational-entities"),
    businessUnitAreas: _path("/business-unit-areas"),
    specificWaterRequirements: _path("/specific-water-requirements"),
    solicitedWaterMass: _path("/solicited-water-mass"),
    occurrenceRates: _path("/occurrence-rates"),
    occurrenceResults: _path("/occurrence-results"),
    occurrenceCollections: _path("/occurrence-collections"),
    occurrenceNeeds: _path("/occurrence-needs"),
};




export const  domesticMenu = [
    {
        title: 'AEP-Domestique',
        path: PATH_DOMESTIC.root,
        icon: <Domain/>,
        children: [
            { title: 'Accueil', path: PATH_DOMESTIC.home },
            { title: 'Droits d\'usages de l\'eau', path: PATH_DOMESTIC.waterRights },
            // { title: 'Unités opérationnelles', path: PATH_DOMESTIC.operationalUnities },
            { title: 'Entités opérationnelles utilisatrice', path: PATH_DOMESTIC.operationalEntities },
            { title: 'Domaines des entités opérationnelles', path: PATH_DOMESTIC.businessUnitAreas },
            { title: 'Besoins spécifique', path: PATH_DOMESTIC.specificWaterRequirements },
            { title: 'Masses d\'eaux sollicitée', path: PATH_DOMESTIC.solicitedWaterMass },
        ]
    }
];

export const commonItems = [
    { name: "Accueil", href: "/" },
    { name: "AEP-Domestique", href: PATH_DOMESTIC.home },
];