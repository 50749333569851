import React from "react";
import {Link as RouterLink} from "react-router-dom";

import {useTheme} from "@mui/material/styles";
import {Refresh, Search} from "@mui/icons-material";
import {
    Box,
    IconButton,
    InputAdornment,
    Link,
    OutlinedInput,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Toolbar,
    Tooltip,
    Typography,
} from "@mui/material";

import {useSearchRiskManagementToolsImplementationOccurrences} from "../services/risk.management.tools.implementation.occurrence.gql";
import useRiskManagementToolsImplementationOccurrenceService from "../services/risk.management.tools.implementation.occurrence.services";
import {useMessage} from "../../../../shared/hooks/useMessage";
import {usePagination} from "../../../../shared/components/tables";
import SortableTableHead from "../../../../shared/components/tables/SortableTableHead";
import MyTablePagination from "../../../../shared/components/tables/MyTablePagination";
import {RiskManagementToolsImplementationOccurrenceMoreMenu} from "./index";
import AlertDialog, {
    useDeleteDialog,
} from "../../../../shared/components/AlertDialog";
import {PATH_RISK_PROTECTION} from "../../risk.protection.path";
import {RiskManagementToolsImplementationOccurrenceCreateDialogMenuItem} from "./RiskManagementToolsImplementationOccurrenceDialog";

const TABLE_HEAD = [
    {id: "id", label: "ID", alignRight: false},
    {id: "name", label: "Nom", alignRight: false},
    {id: "riskManagementToolApplicationDegreeObj", label: "Degré d'application", alignRight: false},
    {id: "dataRefYearObj", label: "Année de reférence", alignRight: false},
    {id: "waterMassObj", label: "Masse d'eau", alignRight: false},
    {id: "justification", label: "Justification", alignRight: false},
    {id: "dataSource", label: "Source de données", alignRight: false},
    {id: ""},
];

export default function RiskManagementToolsImplementationOccurrenceTable({riskManagementTool, currentRiskManagementTool}) {
    const theme = useTheme();
    const {showSuccess, showError} = useMessage();
    const {destroy} = useRiskManagementToolsImplementationOccurrenceService();

    const {
        page,
        order,
        filterName,
        rowsPerPage,
        orderBy,
        handleRequestSort,
        handleChangePage,
        handleChangeRowsPerPage,
        handleFilterByName,
    } = usePagination();

    const {deleteConfirmOpen, setDeleteConfirmOpen, idToDelete, handleDelete} = useDeleteDialog();

    const {riskManagementToolsImplementationOccurrences, refetch, riskManagementToolsImplementationOccurrencePage} = useSearchRiskManagementToolsImplementationOccurrences({
        query: filterName,
        size: rowsPerPage,
        page, riskManagementTool
    });

    const handleDeleteConfirm = () => {
        console.log("####### handleDelete");
        destroy(idToDelete)
            .then((_) => {
                showSuccess(`Occurrences de mise en œuvre des outils de gestion de risques liés à l'eau avec succès`);
                refetch();
            })
            .catch((error) => {
                showError(`Erreur lors de la suppression d'occurrences de mise en œuvre des outils de gestion de risques liés à l'eau ${error}`);
            });
    };

    return (
        <>
            <Toolbar
                sx={{
                    height: 96,
                    display: "flex",
                    justifyContent: "space-between",
                    padding: theme.spacing(0, 1, 0, 3),
                }}
            >
                <OutlinedInput
                    sx={{width: 240}}
                    value={filterName}
                    onChange={handleFilterByName}
                    placeholder="Recherche occurrences de mise en œuvre des outils de gestion de risques liés à l'eau"
                    startAdornment={
                        <InputAdornment position="start">
                            <Box component={Search} sx={{color: "text.disabled"}}/>
                        </InputAdornment>
                    }
                />

                <Box>
                    {riskManagementTool && (
                        <RiskManagementToolsImplementationOccurrenceCreateDialogMenuItem
                            btn
                            riskManagementTool={currentRiskManagementTool}
                            onSave={(createdRiskManagementTool) => {
                                console.log(`##### createdRiskManagementTool: `, createdRiskManagementTool);
                                refetch();
                            }}
                        />
                    )}
                    <Tooltip title='Actualiser'>
                        <IconButton onClick={() => refetch()}>
                            <Refresh/>
                        </IconButton>
                    </Tooltip>
                </Box>
            </Toolbar>

            <TableContainer sx={{minWidth: 800}}>
                <Table size="small">
                    <SortableTableHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={riskManagementToolsImplementationOccurrences.length}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {riskManagementToolsImplementationOccurrences.map((row) => (
                            <Row key={row.id} row={row} handleDelete={handleDelete}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <MyTablePagination
                page={riskManagementToolsImplementationOccurrencePage}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <AlertDialog
                title={"Suppression"}
                message={"Voulez-vous vraiment supprimer cette occurrences de mise en œuvre des outils de gestion de risques liés à l'eau"}
                open={deleteConfirmOpen}
                onYes={() => {
                    setDeleteConfirmOpen(false);
                    handleDeleteConfirm();
                }}
                onClose={() => setDeleteConfirmOpen(false)}
                onNo={() => setDeleteConfirmOpen(false)}
            />
        </>
    );
}

function Row({row, handleDelete}) {
    const {id, name, riskManagementToolApplicationDegreeObj, waterMassObj, dataRefYearObj, justification, dataSource} = row;

    return (
        <TableRow hover sx={{"&:last-child td, &:last-child th": {border: 0}}}>
            <TableCell component="th" scope="row">
                <Link
                    to={PATH_RISK_PROTECTION.riskManagementToolsImplementationOccurrences + "/" + id}
                    component={RouterLink}
                >
                    <Typography variant="body2" noWrap>
                        {id}
                    </Typography>
                </Link>
            </TableCell>

            <TableCell component="th" scope="row">
                <Link
                    to={PATH_RISK_PROTECTION.riskManagementToolsImplementationOccurrences + "/" + id}
                    component={RouterLink}>
                    <Typography variant="body2" noWrap>
                        {name}
                    </Typography>
                </Link>
            </TableCell>

            <TableCell style={{minWidth: 160}}>
                <Typography variant="body2" noWrap>
                    {riskManagementToolApplicationDegreeObj?.name}
                </Typography>
            </TableCell>

            <TableCell style={{minWidth: 160}}>
                <Typography variant="body2" noWrap>
                    {dataRefYearObj?.name}
                </Typography>
            </TableCell>

            <TableCell style={{minWidth: 160}}>
                <Typography variant="body2" noWrap>
                    {waterMassObj?.wording}
                </Typography>
            </TableCell>

            <TableCell style={{minWidth: 160}}>
                <Typography variant="body2" noWrap>
                    {justification}
                </Typography>
            </TableCell>

            <TableCell style={{minWidth: 160}}>
                <Typography variant="body2" noWrap>
                    {dataSource}
                </Typography>
            </TableCell>

            <TableCell align="right">
                <RiskManagementToolsImplementationOccurrenceMoreMenu id={id} onDelete={() => handleDelete(id)}/>
            </TableCell>
        </TableRow>
    );
}
