import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const BudgetProgramsList = Loadable(
  lazy(() => import("./pages/BudgetProgramsList"))
);
const BudgetProgramsCreate = Loadable(
  lazy(() => import("./pages/BudgetProgramsCreate"))
);
const BudgetProgramsEdit = Loadable(
  lazy(() => import("./pages/BudgetProgramsEdit"))
);
const BudgetProgramsDisplay = Loadable(
  lazy(() => import("./pages/BudgetProgramsDisplay"))
);

const budgetProgramsRouter = [
  {
    path: "budget-programs",
    element: <BudgetProgramsList />,
  },
  {
    path: "budget-programs/:id",
    element: <BudgetProgramsDisplay />,
  },
  {
    path: "budget-programs/new",
    element: <BudgetProgramsCreate />,
  },
  {
    path: "budget-programs/:id/edit",
    element: <BudgetProgramsEdit />,
  },
];

export default budgetProgramsRouter;
