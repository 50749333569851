import {gql, useLazyQuery} from '@apollo/client';
import {useEffect, useState} from 'react';
import {useMessage} from '../../../../../shared/hooks/useMessage';

export let needRefresh = false;
export const setRefresh = (value) => needRefresh = value;

export const reportingInputFields = `
    id label description position valueType reporting 
    dateFormat pattern prefix suffix inputValueRequest 
    requestWithCondition realValueType constantsList
    inputAuto inputAutoValue
`;

///////////////////// Search /////////////////////
const SEARCH_REPORTING_INPUTS_BY_REPORTING = gql`
    query SearchReportingInputsByReporting($reporting: Int) {
        searchReportingInputsByReporting(reporting: $reporting) {
            error message list { ${reportingInputFields} }
        }
    }`;

export const useSearchReportingInputsByReporting = ({reporting}) => {
    reporting = reporting && parseInt(reporting);
    const [reportingInputs, setReportingInputs] = useState([]);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_REPORTING_INPUTS_BY_REPORTING, {
        variables: {reporting},
    });

    useEffect(() => {
        if (_error) {
            showError('Erreur lors de l\'operation');
            setReportingInputs([]);
            return;
        }

        if (!searchData) return;

        const {error, message, list} = searchData.searchReportingInputsByReporting;

        if (error) {
            showError(message);
            setReportingInputs([]);
        } else {
            setReportingInputs(list);
        }

        if (needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({variables: {reporting}});
    }, [reporting]);

    return {
        reportingInputs, loading, search, refetch
    }
}
