import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const BudgetExpenditureAreaList = Loadable(
  lazy(() => import("./pages/BudgetExpenditureAreaList"))
);
const BudgetExpenditureAreaCreate = Loadable(
  lazy(() => import("./pages/BudgetExpenditureAreaCreate"))
);
const BudgetExpenditureAreaEdit = Loadable(
  lazy(() => import("./pages/BudgetExpenditureAreaEdit"))
);
const BudgetExpenditureAreaDisplay = Loadable(
  lazy(() => import("./pages/BudgetExpenditureAreaDisplay"))
);

const budgetExpenditureAreaRouter = [
  {
    path: "budget-expenditure-areas",
    element: <BudgetExpenditureAreaList />,
  },
  {
    path: "budget-expenditure-areas/:id",
    element: <BudgetExpenditureAreaDisplay />,
  },
  {
    path: "budget-expenditure-areas/new",
    element: <BudgetExpenditureAreaCreate />,
  },
  {
    path: "budget-expenditure-areas/:id/edit",
    element: <BudgetExpenditureAreaEdit />,
  },
];

export default budgetExpenditureAreaRouter;
