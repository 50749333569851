import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
// material
import {Box, Drawer, styled} from '@mui/material';
// components
import {MHidden} from '../../shared/components';
//
import SideBarContent from "./SideBarContent";


// ----------------------------------------------------------------------
const RootStyle = styled(Box)(({theme}) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH,
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.complex
        })
    }
}))

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

// ----------------------------------------------------------------------

HomeSidebar.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onCloseSidebar: PropTypes.func
};

export default function HomeSidebar({isOpenSidebar, onCloseSidebar}) {
    const {pathname} = useLocation();

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
    }, [pathname]);

    return (
        <RootStyle>
            <MHidden width='lgUp'>
                <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{sx: {width: DRAWER_WIDTH}}}>
                    <SideBarContent/>
                </Drawer>
            </MHidden>

            <MHidden width='lgDown'>
                <Drawer open variant='persistent'
                        PaperProps={{sx: {width: DRAWER_WIDTH, bgcolor: 'background.default'}}}>
                    <SideBarContent/>
                </Drawer>
            </MHidden>
        </RootStyle>
    );
}
