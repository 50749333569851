import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet-async';
// material
import {Box} from '@mui/material';

// ----------------------------------------------------------------------

const Page = forwardRef(({children, title = '', ...other}, ref) => {
    return (
        <Box ref={ref} {...other}>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            {children}
        </Box>
    );
});

Page.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default Page;
