import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {Form, FormikProvider, useFormik} from 'formik';
import {useMessage} from '../../../../shared/hooks/useMessage';
// material
import {Card, Stack, Box} from '@mui/material';
// routes
import {PATH_ADMINISTRATIVE_DIVISION} from '../../administrative_division.path';
import {
    FormikAutocomplete,
    FormikTextField,
    SubmitCancelGroupButton
} from '../../../../shared/components/froms/FormComponents';
import {setRefresh} from '../services/circle.gql';
import useCircleService from '../services/circle.services';
import {useSearchRegions} from '../../region/services/region.gql';
import {RegionCreateDialogMenuItem} from '../../region/components';
import {InitialCircle, CircleSchema} from '../services/circle.store';
import {formErrorMessageParser} from '../../../../utils/utils';
import {useConnectFormState} from '../../../../shared/hooks/useFormikState';
// ----------------------------------------------------------------------

CircleForm.propTypes = {
    isEdit: PropTypes.bool,
    currentCircle: PropTypes.object,
    fromDialog: PropTypes.object,
};

export default function CircleForm({isEdit, currentCircle, setCurrentCircle, fromDialog}) {
    const navigate = useNavigate();
    const {create, update} = useCircleService();
    const {showSuccess, showError} = useMessage();

    const initQuery = currentCircle?.regionObj?.name ?? fromDialog?.region?.name ?? '';
    const [query, setQuery] = useState(initQuery);
    const {regions, loading} = useSearchRegions({query});

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: InitialCircle(),
        validationSchema: CircleSchema,
        onSubmit: async (circle, {setSubmitting}) => {
            try {
                circle = CircleSchema.cast(circle);

                let message;
                if (!isEdit) {
                    circle = await create(circle);
                    message = 'Création avec succès';
                } else {
                    circle = await update(currentCircle.id, circle);
                    message = 'Modification avec succès';
                }

                setRefresh(true);

                setCurrentCircle();

                setSubmitting(false);
                showSuccess(message);

                if (fromDialog) {
                    fromDialog.onSave(circle);
                } else {
                    navigate(`${PATH_ADMINISTRATIVE_DIVISION.circles}/${circle.id}`);
                }
            } catch (error) {
                setSubmitting(false);
                showError(formErrorMessageParser({error, isEdit}));
            }
        }
    });

    const {values, setValues, handleSubmit, isSubmitting, setFieldValue} = formik;

    useConnectFormState({
        formValues: values, setFormValues: setValues,
        stateValues: currentCircle, setStateValues: setCurrentCircle,
    });

    const onCancel = (_) => {
        setCurrentCircle();
        if (fromDialog) {
            fromDialog.onCancel();
        } else {
            navigate(PATH_ADMINISTRATIVE_DIVISION.circles);
        }
    }

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete='off' onSubmit={handleSubmit}>

                <Card sx={{p: 3}}>

                    <Stack spacing={3}>

                        <FormikTextField label='Nom' name='name' formik={formik}/>

                        <Box flexGrow={1} display='flex' flexDirection='row'>
                            <FormikAutocomplete
                                name='region'
                                label='Region'
                                options={regions}
                                embeddedObjField='regionObj'
                                {...{query, formik, loading, setQuery}}
                            />
                            <Box sx={{py: 1, ml: 1}}>
                                <RegionCreateDialogMenuItem btn onSave={(createdRegion) => {
                                    currentCircle.regionObj = createdRegion;
                                    setQuery(createdRegion?.name ?? '');
                                    setFieldValue('region', createdRegion?.id);
                                }}/>
                            </Box>
                        </Box>

                        <SubmitCancelGroupButton {...{isEdit, isSubmitting, onCancel}}/>

                    </Stack>

                </Card>

            </Form>
        </FormikProvider>
    );
}
