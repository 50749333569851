import {gql, useQuery, useLazyQuery} from '@apollo/client';
import {parseCount, parseEntity} from '../../../../utils/gql.utils';
import {useEffect, useState} from 'react';
import {useMessage} from '../../../../shared/hooks/useMessage';

export let needRefresh = false;
export const setRefresh = (value) => needRefresh = value;

export const ahpTypeFields = `
    id name description dataSource
`;

///////////////////// Find By ID /////////////////////
const GET_AHP_TYPE_BY_ID = gql`
    query AhpTypeById($id: Int!) {
        ahpTypeById(id: $id) {
            error message entity { ${ahpTypeFields} }
        }
    }
`;

export const useAhpTypeById = (id) => {
    id = parseInt(id);
    return useQuery(GET_AHP_TYPE_BY_ID, {
        variables: {id},
        fetchPolicy: 'network-only'
    });
}

export const useAhpType = (id) => {
    const result = useAhpTypeById(id);
    return parseEntity(result, 'ahpTypeById');
}

///////////////////// Search /////////////////////
const SEARCH_AHP_TYPE = gql`
    query SearchAhpTypes($query: String, $page: Int, $size: Int) {
        searchAhpTypes(query: $query, page: $page, size: $size) {
            error message page {
                content { ${ahpTypeFields} }
                page hasNext hasPrevious totalPages totalElements numberOfElements
            }
        }
    }
`;

export const useSearchAhpTypes = ({query = '', page = 0, size = 20}) => {
    const [ahpTypes, setAhpTypes] = useState([]);
    const [ahpTypePage, setAhpTypePage] = useState(null);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_AHP_TYPE, {
        variables: {query, page, size},
    });

    useEffect(() => {
        if(_error) {
            showError('Erreur lors de l\'operation');
            setAhpTypePage(null);
            setAhpTypes([]);
            return;
        }

        if(!searchData) return;

        const {error, message, page} = searchData.searchAhpTypes;

        if (error) {
            showError(message);
            setAhpTypePage(null);
            setAhpTypes([]);
        } else {
            setAhpTypePage(page);
            setAhpTypes(page.content);
        }

        if(needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({ variables: { query, page, size } });
    }, [query, page, size]);

    return {
        ahpTypes: ahpTypes, loading, search, refetch, ahpTypePage
    }
}

///////////////////// Count /////////////////////
const COUNT_AHP_TYPE = gql`
    query CountAhpTypes {
        countAhpTypes {
            error message count
        }
    }
`;

export const useCountAhpTypes = () => {
    const result = useQuery(COUNT_AHP_TYPE, {
        fetchPolicy: 'network-only'
    });
    return parseCount(result, 'countAhpTypes');
}
