import {gql, useQuery, useLazyQuery} from '@apollo/client';
import {parseCount, parseEntity} from '../../../../utils/gql.utils';
import {useEffect, useState} from 'react';
import {useMessage} from '../../../../shared/hooks/useMessage';

export let needRefresh = false;
export const setRefresh = (value) => needRefresh = value;

export const instrumentPolicyOccurrenceFields = `id name justification dataSource 
riskManagementToolApplicationDegree riskManagementToolApplicationDegreeObj{id name}
 dataRefYear dataRefYearObj{id name}
 instrumentPolicy instrumentPolicyObj{id name}
 `;

///////////////////// Find By ID /////////////////////
const GET_PAYS_BY_ID = gql`
    query InstrumentPolicyOccurrenceById($id: Int!) {
        instrumentPolicyOccurrenceById(id: $id) {
            error message entity { ${instrumentPolicyOccurrenceFields} }
        }
    }
`;
export const useInstrumentPolicyOccurrenceById = (id) => {
    id = parseInt(id);
    return useQuery(GET_PAYS_BY_ID, {
        variables: {id},
        fetchPolicy: 'network-only'
    });
}

export const useInstrumentPolicyOccurrence = (id) => {
    const result = useInstrumentPolicyOccurrenceById(id);
    return parseEntity(result, 'instrumentPolicyOccurrenceById');
}

///////////////////// Search /////////////////////
const SEARCH_PAYS = gql`
    query SearchInstrumentPolicyOccurrences($query: String, $riskManagementToolApplicationDegree: Int,  $dataRefYear: Int, $instrumentPolicy: Int, $page: Int, $size: Int) {
        searchInstrumentPolicyOccurrences(query: $query, riskManagementToolApplicationDegree:$riskManagementToolApplicationDegree, dataRefYear:$dataRefYear, instrumentPolicy:$instrumentPolicy, page: $page, size: $size) {
            error message page {
                content { ${instrumentPolicyOccurrenceFields} }
                page hasNext hasPrevious totalPages totalElements numberOfElements
            }
        }
    }`;

export const useSearchInstrumentPolicyOccurrences = ({query = '', riskManagementToolApplicationDegree,dataRefYear, instrumentPolicy, page = 0, size = 20}) => {
    const [instrumentPolicyOccurrences, setInstrumentPolicyOccurrences] = useState([]);
    const [instrumentPolicyOccurrencePage, setInstrumentPolicyOccurrencePage] = useState(null);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_PAYS, {
        variables: {query, riskManagementToolApplicationDegree,dataRefYear,instrumentPolicy, page, size},
    });

    useEffect(() => {
        if(_error) {
            showError('Erreur lors de l\'operation');
            setInstrumentPolicyOccurrencePage(null);
            setInstrumentPolicyOccurrences([]);
            return;
        }

        if(!searchData) return;

        const {error, message, page} = searchData.searchInstrumentPolicyOccurrences;

        if (error) {
            showError(message);
            setInstrumentPolicyOccurrencePage(null);
            setInstrumentPolicyOccurrences([]);
        } else {
            setInstrumentPolicyOccurrencePage(page);
            setInstrumentPolicyOccurrences(page.content);
        }

        if(needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);
    
    useEffect(() => {
        search({ variables: { query, riskManagementToolApplicationDegree,dataRefYear,instrumentPolicy, page, size } });
    }, [query,riskManagementToolApplicationDegree, dataRefYear,instrumentPolicy, page, size]);

    return {
        instrumentPolicyOccurrences: instrumentPolicyOccurrences, loading, search, refetch, instrumentPolicyOccurrencePage
    }
}

///////////////////// Count /////////////////////
const COUNT_PAYS = gql`
    query CountInstrumentPolicyOccurrences {
        countInstrumentPolicyOccurrences {
            error message count
        }
    }
`;

export const useCountInstrumentPolicyOccurrences = () => {
    const result = useQuery(COUNT_PAYS, {
        fetchPolicy: 'network-only'
    });
    return parseCount(result, 'countInstrumentPolicyOccurrences');
}
