import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const ActorList = Loadable(lazy(() => import("./pages/ActorList")));
const ActorCreate = Loadable(lazy(() => import("./pages/ActorCreate")));
const ActorEdit = Loadable(lazy(() => import("./pages/ActorEdit")));
const ActorDisplay = Loadable(lazy(() => import("./pages/ActorDisplay")));

const actorRouter = [
  { path: "actors", element: <ActorList /> },
  { path: "actors/:id", element: <ActorDisplay /> },
  { path: "actors/new", element: <ActorCreate /> },
  { path: "actors/:id/edit", element: <ActorEdit /> },
];

export default actorRouter;
