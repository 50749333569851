import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const OccurrenceRangeList = Loadable(lazy(() => import('./pages/OccurrenceRangeList')));
const OccurrenceRangeCreate = Loadable(lazy(() => import('./pages/OccurrenceRangeCreate')));
const OccurrenceRangeEdit = Loadable(lazy(() => import('./pages/OccurrenceRangeEdit')));
const OccurrenceRangeDisplay = Loadable(lazy(() => import('./pages/OccurrenceRangeDisplay')));

const occurrenceRangeRouter = [
    {path: 'occurrence-ranges', element: <OccurrenceRangeList/>},
    {path: 'occurrence-ranges/:id', element: <OccurrenceRangeDisplay/>},
    {path: 'occurrence-ranges/new', element: <OccurrenceRangeCreate/>},
    {path: 'occurrence-ranges/:id/edit', element: <OccurrenceRangeEdit/>},
];

export default occurrenceRangeRouter;
