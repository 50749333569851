import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const ReliefActionList = Loadable(lazy(() => import("./pages/ReliefActionList")));
const ReliefActionCreate = Loadable(lazy(() => import("./pages/ReliefActionCreate")));
const ReliefActionEdit = Loadable(lazy(() => import("./pages/ReliefActionEdit")));
const ReliefActionDisplay = Loadable(lazy(() => import("./pages/ReliefActionDisplay")));

const reliefActionRouter = [
  { path: "relief-actions", element: <ReliefActionList /> },
  { path: "relief-actions/:id", element: <ReliefActionDisplay /> },
  { path: "relief-actions/new", element: <ReliefActionCreate /> },
  { path: "relief-actions/:id/edit", element: <ReliefActionEdit /> },
];

export default reliefActionRouter;
