import * as Yup from 'yup';
import create from 'zustand';


export const QuantitativeObservationTypeSchema = Yup.object().shape({
    name: Yup.string().default('').required('Le nom est obligatoire'),
    description: Yup.string().default(''),
    dataSource: Yup.string().default(''),
});

const initialQuantitativeObservationType = QuantitativeObservationTypeSchema.getDefaultFromShape();
export const InitialQuantitativeObservationType = () => ({...initialQuantitativeObservationType});

const setCurrentNewQuantitativeObservationType = set => quantitativeObservationType => {
    set(state => ({...state, currentNewQuantitativeObservationType: quantitativeObservationType ?? InitialQuantitativeObservationType()}));
}

const setCurrentUpdateQuantitativeObservationType = set => quantitativeObservationType => {
    set(state => ({...state, currentUpdateQuantitativeObservationType: quantitativeObservationType ?? InitialQuantitativeObservationType()}));
}

export const useQuantitativeObservationTypeStore = create(set => ({
    //State
    currentNewQuantitativeObservationType: InitialQuantitativeObservationType(),
    currentUpdateQuantitativeObservationType: InitialQuantitativeObservationType(),
    //Reducers
    setCurrentNewQuantitativeObservationType: setCurrentNewQuantitativeObservationType(set),
    setCurrentUpdateQuantitativeObservationType: setCurrentUpdateQuantitativeObservationType(set),
}))
