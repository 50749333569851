import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Box, Tab, Card, Container, Stack, TextField, CircularProgress} from '@mui/material';
import Page from '../../../../shared/components/Page';
import {InstrumentPolicyTypeHeaderBreadcrumbs} from '../components';
import {FormRowStack} from '../../../../shared/components/froms/FormComponents';
import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useInstrumentPolicyType} from '../services/instrument.policy.type.gql';
import InstrumentPolicyTypeMoreMenu from '../components/InstrumentPolicyTypeMoreMenu';
import useInstrumentPolicyTypeService from "../services/instrument.policy.type.services";
import {useDelete} from "../../../../shared/hooks/useDelete";

export default function InstrumentPolicyTypeDisplay() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {showError} = useMessage();
    const [tapIndex, setTapIndex] = useState('info');
    const {loading, entity: currentInstrumentPolicyType, error, errorMessage} = useInstrumentPolicyType(id);

    const {destroy} = useInstrumentPolicyTypeService();
    const {onDelete} = useDelete({
        destroyFn: destroy,
        onSuccess: () => navigate(PATH_GIRE_ENV_FAVORABLE.instrumentPolicyTypes),
    });

    useEffect(() => {
        if (loading) return;
        if (error) {
            showError(errorMessage);
            navigate(PATH_GIRE_ENV_FAVORABLE.instrumentPolicyTypes);
        }
    }, [error, loading, errorMessage]);

    const handleChangeTab = (event, newValue) => setTapIndex(newValue);

    return (
        <Page title={"Gire environment favorable: Type d'instruments de politique et stratégie"}>
            <Container maxWidth={'xl'}>
                <InstrumentPolicyTypeHeaderBreadcrumbs
                    heading="Type d'instruments de politique et stratégie"
                    lastName={`${currentInstrumentPolicyType?.name ?? "Type d'instruments de politique et stratégie"}`}

                    action={<InstrumentPolicyTypeMoreMenu id={Number(id)} onDelete={onDelete} hiddenDisplay/>}
                />

                {loading && <CircularProgress/>}
                {!loading && currentInstrumentPolicyType && (
                    <TabContext value={tapIndex}>
                        <Box sx={{mb: 4, direction: 'row', display: 'flex', justifyContent: 'space-between'}}>
                            <TabList onChange={handleChangeTab}>
                                <Tab disableRipple value='info' label='Information'/>
                                {/*<Tab disableRipple value='regions' label='Regions'/>*/}
                            </TabList>
                        </Box>


                        <Card>
                            <TabPanel value='info'>
                                <Box sx={{p: 0, minHeight: '80%'}}>

                                    <Stack spacing={3}>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label='Nom'
                                                value={currentInstrumentPolicyType?.name}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label="Source de donnée"
                                                value={currentInstrumentPolicyType?.dataSource}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                 multiline minRows={2} maxRows={6}  label="Description"
                                                value={currentInstrumentPolicyType?.description}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>

                                    </Stack>
                                </Box>
                            </TabPanel>
                        </Card>

                    </TabContext>
                )}
            </Container>
        </Page>
    );
}
