import {Enum} from '../../../utils/enum.utils';

export const WidgetSource = Enum({
    REQUEST: {description: 'Requete', request: true},
    REPORTING: {description: 'Reporting generique', reporting: true},
});

export const WidgetType = Enum({
    VALUE: {
        description: 'Valeur',
        sources: [WidgetSource.REQUEST],
        needUnit: true,
        value: true,
    },
    CHART: {
        description: 'Graphique',
        sources: [WidgetSource.REPORTING, WidgetSource.REQUEST],
        chart: true,
    },
    TABLE: {
        description: 'Tableau',
        sources: [WidgetSource.REPORTING],
        table: true,
    },
}, {
    bySource: function (source) {
        return this.values().filter(({sources}) => sources.indexOf(source) !== -1);
    }
});

export const WidgetChartType = Enum({
    BAR: {
        description: 'Bar',
        types: [WidgetType.CHART],
        bar: true,
    },
    PIE: {
        description: 'Pie',
        types: [WidgetType.CHART],
        pie: true,
    },
    LINE: {
        description: 'Line',
        types: [WidgetType.CHART],
        line: true,
    },
    POLAR_AREA: {
        description: 'PolarArea',
        types: [WidgetType.CHART],
        polarArea: true,
    },
    RADAR: {
        description: 'Radar',
        types: [WidgetType.CHART],
        radar: true,
    },
    PERCENTAGE: {
        description: 'Pourcetage',
        types: [WidgetType.CHART, WidgetType.CHART],
        percentage: true,
    },
}, {
    byType: function (type) {
        return this.values().filter(({types}) => types.indexOf(type) !== -1);
    }
});
