import React from 'react';
// components
import {RiskManagementToolTypeForm} from '../components';
import CreateDialogMenuItem from '../../../../shared/components/CreateDialogMenuItem';
import {useRiskManagementToolTypeStore} from "../services/risk.management.tool.type.store";

// ----------------------------------------------------------------------

export function RiskManagementToolTypeCreateDialogMenuItem({onClose, onSave, btn}) {
    const {currentNewRiskManagementToolType: currentRiskManagementToolType, setCurrentNewRiskManagementToolType: setCurrentRiskManagementToolType} = useRiskManagementToolTypeStore();
    return (
        <CreateDialogMenuItem
            btn={btn}
            onClose={onClose}
            menuText="Ajouter un type outils de gestion de risques liées à l'eau"
            dialogTitle="Création de types outils de gestion de risques liées à l'eau"
            dialogContextText={<> {`Création de nouveau type outils de gestion de risques liées à l'eau`} </>}
            formRender={({onClose}) => {
                return (
                    <RiskManagementToolTypeForm
                        {...{currentRiskManagementToolType, setCurrentRiskManagementToolType}}
                        fromDialog={{
                            onCancel: onClose,
                            onSave: (createdRiskManagementToolType) => {
                                onSave && onSave(createdRiskManagementToolType);
                                onClose && onClose();
                            }
                        }}/>
                );
            }}
        />
    );

}
