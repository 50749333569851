import React from 'react';
// material
import MergeTypeIcon from '@mui/icons-material/MergeType';
// utils
import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {useCountInstrumentMechanismParticipationTypes} from '../services/instrument.mechanism.participation.type.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';

export default function InstrumentMechanismParticipationTypeEntryButton() {
    const {count} = useCountInstrumentMechanismParticipationTypes();
  return (
      <SubModuleEntryButton
          label="Types d'nstruments et mécanismes de participation à la GIRE"
          value={count}
          url={PATH_GIRE_ENV_FAVORABLE.instrumentMechanismParticipationTypes}
          icon={<MergeTypeIcon />}
      />
  );
}
