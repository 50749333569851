import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const StationReferenceStatusList = Loadable(lazy(() => import("./pages/StationReferenceStatusList")));
const StationReferenceStatusCreate = Loadable(lazy(() => import("./pages/StationReferenceStatusCreate")));
const StationReferenceStatusEdit = Loadable(lazy(() => import("./pages/StationReferenceStatusEdit")));
const StationReferenceStatusDisplay = Loadable(lazy(() => import("./pages/StationReferenceStatusDisplay")));

const stationReferenceStatusRouter = [
  { path: "station-reference-status", element: <StationReferenceStatusList /> },
  { path: "station-reference-status/:id", element: <StationReferenceStatusDisplay /> },
  { path: "station-reference-status/new", element: <StationReferenceStatusCreate /> },
  { path: "station-reference-status/:id/edit", element: <StationReferenceStatusEdit /> },
];

export default stationReferenceStatusRouter;
