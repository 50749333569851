import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const QuantitativeObservationAndQualityControlStationsList = Loadable(lazy(() => import("./pages/QuantitativeObservationAndQualityControlStationsList")));
const QuantitativeObservationAndQualityControlStationsCreate = Loadable(lazy(() => import("./pages/QuantitativeObservationAndQualityControlStationsCreate")));
const QuantitativeObservationAndQualityControlStationsEdit = Loadable(lazy(() => import("./pages/QuantitativeObservationAndQualityControlStationsEdit")));
const QuantitativeObservationAndQualityControlStationsDisplay = Loadable(lazy(() => import("./pages/QuantitativeObservationAndQualityControlStationsDisplay")));

const quantitativeObservationAndQualityControlStationsRouter = [
  { path: "quantitative-observation-and-quality-control-stations", element: <QuantitativeObservationAndQualityControlStationsList /> },
  { path: "quantitative-observation-and-quality-control-stations/:id", element: <QuantitativeObservationAndQualityControlStationsDisplay /> },
  { path: "quantitative-observation-and-quality-control-stations/new", element: <QuantitativeObservationAndQualityControlStationsCreate /> },
  { path: "quantitative-observation-and-quality-control-stations/:id/edit", element: <QuantitativeObservationAndQualityControlStationsEdit /> },
];

export default quantitativeObservationAndQualityControlStationsRouter;
