import React from "react";
import HeaderBreadcrumbs from "../../../../shared/components/HeaderBreadcrumbs";
import { PATH_GIRE_ENV_FAVORABLE } from "../../gire.env.favorable.path";

const commonItems = [
  { name: "Accueil", href: "/" },
  { name: "Instruments de politique et stratégie eau", href: PATH_GIRE_ENV_FAVORABLE.home },
];

export function InstrumentPolicyHeaderBreadcrumbs({
  heading,
  lastName,
  ...others
}) {
  return (
    <HeaderBreadcrumbs
      {...others}
      heading={heading}
      links={[
        ...commonItems,
        {
          name: "Instruments de politique et stratégie eau",
          href: PATH_GIRE_ENV_FAVORABLE.instrumentPolicies,
        },
        { name: lastName },
      ]}
    />
  );
}

export function InstrumentPolicyListHeaderBreadcrumbs({
  heading,
  lastName,
  ...others
}) {
  return (
    <HeaderBreadcrumbs
      {...others}
      heading={heading}
      links={[...commonItems, { name: lastName }]}
    />
  );
}
