import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const AhpList = Loadable(
  lazy(() => import("./pages/AhpList"))
);
const AhpCreate = Loadable(
  lazy(() => import("./pages/AhpCreate"))
);
const AhpEdit = Loadable(
  lazy(() => import("./pages/AhpEdit"))
);
const AhpDisplay = Loadable(
  lazy(() => import("./pages/AhpDisplay"))
);

const ahpRouter = [
  {
    path: "ahps",
    element: <AhpList />,
  },
  {
    path: "ahps/:id",
    element: <AhpDisplay />,
  },
  {
    path: "ahps/new",
    element: <AhpCreate />,
  },
  {
    path: "ahps/:id/edit",
    element: <AhpEdit />,
  },
];

export default ahpRouter;
