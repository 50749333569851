import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const WaterMassSetList = Loadable(lazy(() => import("./pages/WaterMassSetList")));
const WaterMassSetCreate = Loadable(lazy(() => import("./pages/WaterMassSetCreate")));
const WaterMassSetEdit = Loadable(lazy(() => import("./pages/WaterMassSetEdit")));
const WaterMassSetDisplay = Loadable(lazy(() => import("./pages/WaterMassSetDisplay")));

const waterMassSetRouter = [
  { path: "water-mass-sets", element: <WaterMassSetList /> },
  { path: "water-mass-sets/:id", element: <WaterMassSetDisplay /> },
  { path: "water-mass-sets/new", element: <WaterMassSetCreate /> },
  { path: "water-mass-sets/:id/edit", element: <WaterMassSetEdit /> },
];

export default waterMassSetRouter;
