import React from 'react';
import SetMealIcon from '@mui/icons-material/SetMeal';
import {path} from '../../home/sidebar/paths';

const FISHING = '/fishing';
const _path = path(FISHING);
export const PATH_FISHING = {
    root: FISHING,
    home: _path('/home'),
    ahp: _path("/ahps"),
    ahpType: _path("/ahp-types"),
    amenagementEntity: _path("/amenagement-entities"),
    occurrenceFishProductions: _path("/occurrence-fish-productions"),
    occurrenceNeedWithdrawal: _path("/occurrence-need-withdrawals"),
};

export const fishingMenu = [
    {
        title: 'Pêche',
        path: PATH_FISHING.root,
        icon: <SetMealIcon/>,
        children: [
            {title: 'Accueil', path: PATH_FISHING.home},
            {title: 'Type AHP', path: PATH_FISHING.ahpType},
            {title: 'Entités d\'aménagement Hydro-Piscicoles', path: PATH_FISHING.ahp},
            {title: 'Entités d\'aménagements HP', path: PATH_FISHING.amenagementEntity},

            // {title: 'Types de pêche et pisciculture', path: PATH_FISHING.fishingFarmingType},
            // {title: 'Entités de fermes et mares piscicoles', path: PATH_FISHING.farmFishPond},
            // {title: 'Entités zones de pêche', path: PATH_FISHING.fishingArea},
            // {title: 'fish Production Occurrence', path: PATH_FISHING.fishProductionOccurrence},
            // {title: 'need Withdrawal Occurrence', path: PATH_FISHING.needWithdrawalOccurrence},
        ]
    }
];

export const commonItems = [
    {name: "Accueil", href: "/"},
    {name: "Pêche", href: PATH_FISHING.home},
];
