import {useApiHttp} from '../../../../shared/hooks/useHttp';

const useRiskManagementToolApplicationDegreeService = () => {
    const {crud} = useApiHttp();

    const {
        create,
        update,
        destroy,
        findById,
        findAll,
    } = crud('risk-management-tool-application-degrees');

    return {
        create,
        update,
        destroy,
        findById,
        findAll,
    }
}

export default useRiskManagementToolApplicationDegreeService;
