import {useApiHttp} from '../../../../../shared/hooks/useHttp';


export default function useDashboardWidgetService() {
    const {crud, post} = useApiHttp();

    const {
        create,
        update,
        destroy,
        findById,
        findAll,
    } = crud('dashboard-widgets');

    const execute = (dashboardWidget) => post(`dashboard-widgets/${dashboardWidget}/execute`);

    return {
        create,
        update,
        destroy,
        findById,
        findAll,
        execute,
    }
}
