import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';

const OccurrenceFishProductionList = Loadable(lazy(() => import('./pages/OccurrenceFishProductionList')));
const OccurrenceFishProductionCreate = Loadable(lazy(() => import('./pages/OccurrenceFishProductionCreate')));
const OccurrenceFishProductionEdit = Loadable(lazy(() => import('./pages/OccurrenceFishProductionEdit')));
const OccurrenceFishProductionDisplay = Loadable(lazy(() => import('./pages/OccurrenceFishProductionDisplay')));

const occurrenceFishProductionRouter = [
    {path: 'occurrence-fish-productions', element: <OccurrenceFishProductionList/>},
    {path: 'occurrence-fish-productions/:id', element: <OccurrenceFishProductionDisplay/>},
    {path: 'occurrence-fish-productions/new', element: <OccurrenceFishProductionCreate/>},
    {path: 'occurrence-fish-productions/:id/edit', element: <OccurrenceFishProductionEdit/>},
];
export default occurrenceFishProductionRouter;
