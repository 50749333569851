import React from 'react';
import {path} from '../../home/sidebar/paths';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';

//Features
const DEGRADATION_PROTECTION = '/degradation_protection';
const _path = path(DEGRADATION_PROTECTION);
export const PATH_DEGRADATION_PROTECTION = {
    root: DEGRADATION_PROTECTION,
    home: _path('/home'),
    typeDegradationFactors: _path("/type-degradation-factors"),
    typeAggressionFactors: _path("/type-aggression-factors"),
    typeDevelopmentActions: _path("/type-development-actions"),
    occurrenceDegradationFactors: _path("/occurrence-degradation-factors"),
    occurrenceAggressions: _path("/occurrence-aggressions"),
    typeWasteWaters: _path("/type-waste-waters"),
    wasteWaterDischarges: _path("/waste-water-discharges"),
    occurrenceDevelopmentActions: _path("/occurrence-development-actions"),
    occurrenceEnvironmentals: _path("/occurrence-environmentals"),
    productionWasteWaters: _path("/production-waste-waters"),

};




export const  degradationProtectionMenu = [
    {
        title: 'Dégradation & protection',
        path: PATH_DEGRADATION_PROTECTION.root,
        icon: <AlignHorizontalLeftIcon/>,
        children: [
            { title: 'Accueil', path: PATH_DEGRADATION_PROTECTION.home },
            { title: 'Déversements eaux usées', path: PATH_DEGRADATION_PROTECTION.wasteWaterDischarges },
            { title: 'Productions eaux usées', path: PATH_DEGRADATION_PROTECTION.productionWasteWaters},
            { title: 'Types de facteurs', path: PATH_DEGRADATION_PROTECTION.typeDegradationFactors },
            { title: 'Types d\'agressions', path: PATH_DEGRADATION_PROTECTION.typeAggressionFactors },
            { title: 'Types d\'actions', path: PATH_DEGRADATION_PROTECTION.typeDevelopmentActions },
            { title: 'Types d\'eau usées', path: PATH_DEGRADATION_PROTECTION.typeWasteWaters },
        ]
    }
];

export const commonItems = [
    { name: "Accueil", href: "/" },
    { name: "Dégradation et protection en RE", href: PATH_DEGRADATION_PROTECTION.home },
];