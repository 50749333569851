import React from 'react';
// material
import {Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {InstrumentPolicyTypeForm} from '../components';

import {InstrumentPolicyTypeHeaderBreadcrumbs} from '../components';
import {useInstrumentPolicyTypeStore} from "../services/instrument.policy.type.store";

// ----------------------------------------------------------------------

export default function InstrumentPolicyTypeCreate() {
    const {currentNewInstrumentPolicyType: currentInstrumentPolicyType, setCurrentNewInstrumentPolicyType: setCurrentInstrumentPolicyType} = useInstrumentPolicyTypeStore();
    return (
        <Page title="Gire environment favorable: Création type d'instruments de politique et stratégie">
            <Container  maxWidth={'xl'}>
                <InstrumentPolicyTypeHeaderBreadcrumbs
                    heading="Création de nouveau type d'instruments de politique et stratégie"
                    lastName="Nouveau type d'instruments de politique et stratégie"
                />
                <InstrumentPolicyTypeForm {...{currentInstrumentPolicyType, setCurrentInstrumentPolicyType}}/>
            </Container>
        </Page>
    );
}
