import * as Yup from 'yup';
import create from 'zustand';


export const InstitutionalOrganizationalFrameworkStructureTypeSchema = Yup.object().shape({
    name: Yup.string().default('').required('Le nom est obligatoire'),
    description: Yup.string().default(''),
    dataSource: Yup.string().default(''),
});

const initialInstitutionalOrganizationalFrameworkStructureType = InstitutionalOrganizationalFrameworkStructureTypeSchema.getDefaultFromShape();
export const InitialInstitutionalOrganizationalFrameworkStructureType = () => ({...initialInstitutionalOrganizationalFrameworkStructureType});

const setCurrentNewInstitutionalOrganizationalFrameworkStructureType = set => institutionalOrganizationalFrameworkStructureType => {
    set(state => ({...state, currentNewInstitutionalOrganizationalFrameworkStructureType: institutionalOrganizationalFrameworkStructureType ?? InitialInstitutionalOrganizationalFrameworkStructureType()}));
}

const setCurrentUpdateInstitutionalOrganizationalFrameworkStructureType = set => institutionalOrganizationalFrameworkStructureType => {
    set(state => ({...state, currentUpdateInstitutionalOrganizationalFrameworkStructureType: institutionalOrganizationalFrameworkStructureType ?? InitialInstitutionalOrganizationalFrameworkStructureType()}));
}

export const useInstitutionalOrganizationalFrameworkStructureTypeStore = create(set => ({
    //State
    currentNewInstitutionalOrganizationalFrameworkStructureType: InitialInstitutionalOrganizationalFrameworkStructureType(),
    currentUpdateInstitutionalOrganizationalFrameworkStructureType: InitialInstitutionalOrganizationalFrameworkStructureType(),
    //Reducers
    setCurrentNewInstitutionalOrganizationalFrameworkStructureType: setCurrentNewInstitutionalOrganizationalFrameworkStructureType(set),
    setCurrentUpdateInstitutionalOrganizationalFrameworkStructureType: setCurrentUpdateInstitutionalOrganizationalFrameworkStructureType(set),
}))
