import * as Yup from 'yup';
import create from 'zustand';


export const LocalWaterDepartmentSchema = Yup.object().shape({
    name: Yup.string().default('').required('Le nom est obligatoire'),
    description: Yup.string().default(' '),
    locality: Yup.string().required('La localité est obligatoire'),
});

const initialLocalWaterDepartment = LocalWaterDepartmentSchema.getDefaultFromShape();
export const InitialLocalWaterDepartment = () => ({...initialLocalWaterDepartment});

const setCurrentNewLocalWaterDepartment = set => localWaterDepartment => {
    set(state => ({...state, currentNewLocalWaterDepartment: localWaterDepartment ?? InitialLocalWaterDepartment()}));
}

const setCurrentUpdateLocalWaterDepartment = set => localWaterDepartment => {
    set(state => ({...state, currentUpdateLocalWaterDepartment: localWaterDepartment ?? InitialLocalWaterDepartment()}));
}

export const useLocalWaterDepartmentStore = create(set => ({
    //State
    currentNewLocalWaterDepartment: InitialLocalWaterDepartment(),
    currentUpdateLocalWaterDepartment: InitialLocalWaterDepartment(),
    //Reducers
    setCurrentNewLocalWaterDepartment: setCurrentNewLocalWaterDepartment(set),
    setCurrentUpdateLocalWaterDepartment: setCurrentUpdateLocalWaterDepartment(set),
}))
