import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const EnvironmentList = Loadable(lazy(() => import("./pages/EnvironmentList")));
const EnvironmentCreate = Loadable(
  lazy(() => import("./pages/EnvironmentCreate"))
);
const EnvironmentEdit = Loadable(lazy(() => import("./pages/EnvironmentEdit")));
const EnvironmentDisplay = Loadable(
  lazy(() => import("./pages/EnvironmentDisplay"))
);

const environmentRouter = [
  { path: "environments", element: <EnvironmentList /> },
  { path: "environments/:id", element: <EnvironmentDisplay /> },
  { path: "environments/new", element: <EnvironmentCreate /> },
  { path: "environments/:id/edit", element: <EnvironmentEdit /> },
];

export default environmentRouter;
