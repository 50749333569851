import * as Yup from "yup";
import create from "zustand";

export const CulturalSpeculationEntityUsagesSchema = Yup.object().shape({
    name: Yup.string().default("").required("Le libellé est obligatoire"),
    code: Yup.string().required("Le code est obligatoire"),
    dataRefYear: Yup.number().required('La reférence des données est obligatoire'),
    dataSource: Yup.string().required("La source des données est obligatoire"),
    averageAnnualCost: Yup.number().required("Le prix moyen annuel est obligatoire"),
});

const initialCulturalSpeculationEntityUsages =
    CulturalSpeculationEntityUsagesSchema.getDefaultFromShape();
export const InitialCulturalSpeculationEntityUsages = () => ({
    ...initialCulturalSpeculationEntityUsages,
});

const setCurrentNewCulturalSpeculationEntityUsages = (set) => (CulturalSpeculationEntityUsages) => {
    set((state) => ({
        ...state,
        currentNewCulturalSpeculationEntityUsages:
            CulturalSpeculationEntityUsages ?? InitialCulturalSpeculationEntityUsages(),
    }));
};

const setCurrentUpdateCulturalSpeculationEntityUsages =
    (set) => (CulturalSpeculationEntityUsages) => {
        set((state) => ({
            ...state,
            currentUpdateCulturalSpeculationEntityUsages:
                CulturalSpeculationEntityUsages ?? InitialCulturalSpeculationEntityUsages(),
        }));
    };

export const useCulturalSpeculationEntityUsagesStore = create((set) => ({
    //State
    currentNewCulturalSpeculationEntityUsages: InitialCulturalSpeculationEntityUsages(),
    currentUpdateCulturalSpeculationEntityUsages:
        InitialCulturalSpeculationEntityUsages(), //Reducers
    setCurrentNewCulturalSpeculationEntityUsages:
        setCurrentNewCulturalSpeculationEntityUsages(set),
    setCurrentUpdateCulturalSpeculationEntityUsages:
        setCurrentUpdateCulturalSpeculationEntityUsages(set),
}));
