import * as Yup from 'yup';
import create from 'zustand';

export const RiskManagementToolsImplementationOccurrenceSchema = Yup.object().shape({
  name: Yup.string().default('').required('Le nom est obligatoire'),
  riskManagementToolApplicationDegree: Yup.string().required('Le degré d\'application des outils de gestion de risques liés à l\'eau'),
  dataRefYear: Yup.number().required("L'année de reférence des données est obligatoire"),
  waterMass: Yup.number().required("La masse d'eau est obligatoire"),
  dataSource: Yup.string().default(''),
  justification: Yup.string().required("La justification est obligatoire"),
 });

const initialRiskManagementToolsImplementationOccurrence = RiskManagementToolsImplementationOccurrenceSchema.getDefaultFromShape();
export const InitialRiskManagementToolsImplementationOccurrence = () => ({...initialRiskManagementToolsImplementationOccurrence});

const setCurrentNewRiskManagementToolsImplementationOccurrence = set => riskManagementToolsImplementationOccurrence => {
  set(state => ({...state, currentNewRiskManagementToolsImplementationOccurrence: riskManagementToolsImplementationOccurrence ?? InitialRiskManagementToolsImplementationOccurrence()}));
}

const setCurrentUpdateRiskManagementToolsImplementationOccurrence = set => riskManagementToolsImplementationOccurrence => {
  set(state => ({...state, currentUpdateRiskManagementToolsImplementationOccurrence: riskManagementToolsImplementationOccurrence ?? InitialRiskManagementToolsImplementationOccurrence()}));
}

export const useRiskManagementToolsImplementationOccurrenceStore = create(set => ({
  //State
  currentNewRiskManagementToolsImplementationOccurrence: InitialRiskManagementToolsImplementationOccurrence(),
  currentUpdateRiskManagementToolsImplementationOccurrence: InitialRiskManagementToolsImplementationOccurrence(),
  //Reducers
  setCurrentNewRiskManagementToolsImplementationOccurrence: setCurrentNewRiskManagementToolsImplementationOccurrence(set),
  setCurrentUpdateRiskManagementToolsImplementationOccurrence: setCurrentUpdateRiskManagementToolsImplementationOccurrence(set),
}))
