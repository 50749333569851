import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const OccurrencesOfCompositionAndFunctionalityOfGireOrganOfficesList = Loadable(
  lazy(() => import("./pages/OccurrencesOfCompositionAndFunctionalityOfGireOrganOfficesList"))
);
const OccurrencesOfCompositionAndFunctionalityOfGireOrganOfficesCreate = Loadable(
  lazy(() => import("./pages/OccurrencesOfCompositionAndFunctionalityOfGireOrganOfficesCreate"))
);
const OccurrencesOfCompositionAndFunctionalityOfGireOrganOfficesEdit = Loadable(
  lazy(() => import("./pages/OccurrencesOfCompositionAndFunctionalityOfGireOrganOfficesEdit"))
);
const OccurrencesOfCompositionAndFunctionalityOfGireOrganOfficesDisplay = Loadable(
  lazy(() => import("./pages/OccurrencesOfCompositionAndFunctionalityOfGireOrganOfficesDisplay"))
);

const occurrencesOfCompositionAndFunctionalityOfGireOrganOfficesRouter = [
  {
    path: "occurrences-of-composition-and-functionality-of-gire-organ-offices",
    element: <OccurrencesOfCompositionAndFunctionalityOfGireOrganOfficesList />,
  },
  {
    path: "occurrences-of-composition-and-functionality-of-gire-organ-offices/:id",
    element: <OccurrencesOfCompositionAndFunctionalityOfGireOrganOfficesDisplay />,
  },
  {
    path: "occurrences-of-composition-and-functionality-of-gire-organ-offices/new",
    element: <OccurrencesOfCompositionAndFunctionalityOfGireOrganOfficesCreate />,
  },
  {
    path: "occurrences-of-composition-and-functionality-of-gire-organ-offices/:id/edit",
    element: <OccurrencesOfCompositionAndFunctionalityOfGireOrganOfficesEdit />,
  },
];

export default occurrencesOfCompositionAndFunctionalityOfGireOrganOfficesRouter;
