import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const DistributionList = Loadable(
  lazy(() => import("./pages/DistributionList"))
);
const DistributionCreate = Loadable(
  lazy(() => import("./pages/DistributionCreate"))
);
const DistributionEdit = Loadable(
  lazy(() => import("./pages/DistributionEdit"))
);
const DistributionDisplay = Loadable(
  lazy(() => import("./pages/DistributionDisplay"))
);

const distributionRouter = [
  { path: "distributions", element: <DistributionList /> },
  { path: "distributions/:id", element: <DistributionDisplay /> },
  { path: "distributions/new", element: <DistributionCreate /> },
  { path: "distributions/:id/edit", element: <DistributionEdit /> },
];

export default distributionRouter;
