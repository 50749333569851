import React from 'react';
// components
import {AhaTypeForm} from '../components';
import CreateDialogMenuItem from '../../../../shared/components/CreateDialogMenuItem';
import {useAhaTypeStore} from "../services/aha.type.store";

// ----------------------------------------------------------------------


export function AhaTypeCreateDialogMenuItem({onClose, onSave, btn}) {
    const {currentNewAhaType: currentAhaType, setCurrentNewAhaType: setCurrentAhaType} = useAhaTypeStore();
    return (
        <CreateDialogMenuItem
            btn={btn}
            onClose={onClose}
            menuText='Ajouter un type aménagement hydro-agricole'
            dialogTitle='Création de type aménagement hydro-agricole'
            dialogContextText={<> {`Création de nouveau type aménagement hydro-agricole`} </>}
            formRender={({onClose}) => {
                return (
                    <AhaTypeForm
                        {...{currentAhaType, setCurrentAhaType}}
                        fromDialog={{
                            onCancel: onClose,
                            onSave: (createdAhaType) => {
                                onSave && onSave(createdAhaType);
                                onClose && onClose();
                            }
                        }}/>
                );
            }}
        />
    );

}
