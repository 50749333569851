import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const StatusCommuneList = Loadable(lazy(() => import('./pages/StatusCommuneList')));
const StatusCommuneCreate = Loadable(lazy(() => import('./pages/StatusCommuneCreate')));
const StatusCommuneEdit = Loadable(lazy(() => import('./pages/StatusCommuneEdit')));
const StatusCommuneDisplay = Loadable(lazy(() => import('./pages/StatusCommuneDisplay')));

const statusCommuneRouter = [
    {path: 'status-communes', element: <StatusCommuneList/>},
    {path: 'status-communes/:id', element: <StatusCommuneDisplay/>},
    {path: 'status-communes/new', element: <StatusCommuneCreate/>},
    {path: 'status-communes/:id/edit', element: <StatusCommuneEdit/>},
];

export default statusCommuneRouter;
