import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const TankList = Loadable(lazy(() => import("./pages/TankList")));
const TankCreate = Loadable(lazy(() => import("./pages/TankCreate")));
const TankEdit = Loadable(lazy(() => import("./pages/TankEdit")));
const TankDisplay = Loadable(lazy(() => import("./pages/TankDisplay")));

const tankRouter = [
  { path: "tanks", element: <TankList /> },
  { path: "tanks/:id", element: <TankDisplay /> },
  { path: "tanks/new", element: <TankCreate /> },
  { path: "tanks/:id/edit", element: <TankEdit /> },
];

export default tankRouter;
