import React, {lazy} from 'react';
import AmenagementTypeList from "./pages/AmenagementTypeList";
import AmenagementTypeDisplay from "./pages/AmenagementTypeDisplay";
import AmenagementTypeCreate from "./pages/AmenagementTypeCreate";
import AmenagementTypeEdit from "./pages/AmenagementTypeEdit";

const amenagementTypeRouter = [
    {path: 'amenagement-types', element: <AmenagementTypeList/>},
    {path: 'amenagement-types/:id', element: <AmenagementTypeDisplay/>},
    {path: 'amenagement-types/new', element: <AmenagementTypeCreate/>},
    {path: 'amenagement-types/:id/edit', element: <AmenagementTypeEdit/>},
];

export default amenagementTypeRouter;
