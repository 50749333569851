import React, {useState} from "react";
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Tooltip,
} from "@mui/material";
import {Add, Edit, Info} from "@mui/icons-material";

export default function CreateDialogMenuItem({
                                                 onClose,
                                                 dialogTitle,
                                                 dialogContextText,
                                                 menuText,
                                                 formRender,
                                                 btn,
                                                 isDisplay,
                                                 onClick,
                                             }) {
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        console.log("On Close CreateDialogMenuItem");
        setOpen(false);
        onClose && onClose(false);
    };

    return (
        <>
            {/*Overloaded menu to display according to the edit or Show info menu*/}
            {!btn && (
                <MenuItem
                    onClick={() => {
                        onClick && onClick();
                        setOpen(true);
                    }}
                    sx={{color: "text.secondary"}}
                >
                    <ListItemIcon>
                        {!isDisplay ? <Edit/> : <Info/>}
                    </ListItemIcon>
                    <ListItemText
                        primary={!isDisplay ? "Modifier" : "Afficher"}
                        primaryTypographyProps={{variant: "body2"}}
                    />
                </MenuItem>
            )}
            {btn && (
                <IconButton onClick={() => setOpen(true)}>
                    <Tooltip title={menuText}>
                        <Add/>
                    </Tooltip>
                </IconButton>
            )}

            <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogContextText}</DialogContentText>
                </DialogContent>

                {formRender && formRender({onClose: handleClose})}
            </Dialog>
        </>
    );
}
