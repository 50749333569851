import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Box, Tab, Card, Container, Stack, TextField, CircularProgress} from '@mui/material';
import Page from '../../../../shared/components/Page';
import {RiskTypeHeaderBreadcrumbs} from '../components';
import {FormRowStack} from '../../../../shared/components/froms/FormComponents';
import {PATH_RISK_PROTECTION} from '../../risk.protection.path';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useRiskType} from '../services/risk.type.gql';
import RiskTypeMoreMenu from '../components/RiskTypeMoreMenu';
import useRiskTypeService from "../services/risk.type.services";
import {useDelete} from "../../../../shared/hooks/useDelete";

export default function RiskTypeDisplay() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {showError} = useMessage();
    const [tapIndex, setTapIndex] = useState('info');
    const {loading, entity: currentRiskType, error, errorMessage} = useRiskType(id);

    const {destroy} = useRiskTypeService();
    const {onDelete} = useDelete({
        destroyFn: destroy,
        onSuccess: () => navigate(PATH_RISK_PROTECTION.riskTypes),
    });

    useEffect(() => {
        if (loading) return;
        if (error) {
            showError(errorMessage);
            navigate(PATH_RISK_PROTECTION.riskTypes);
        }
    }, [error, loading, errorMessage]);

    const handleChangeTab = (event, newValue) => setTapIndex(newValue);

    return (
        <Page title={'Risque et protection en RE: Type de risque'}>
            <Container maxWidth={'xl'}>
                <RiskTypeHeaderBreadcrumbs
                    heading='Type de risque'
                    lastName={`${currentRiskType?.name ?? 'Type de risque'}`}

                    action={<RiskTypeMoreMenu id={Number(id)} onDelete={onDelete} hiddenDisplay/>}
                />

                {loading && <CircularProgress/>}
                {!loading && currentRiskType && (
                    <TabContext value={tapIndex}>
                        <Box sx={{mb: 4, direction: 'row', display: 'flex', justifyContent: 'space-between'}}>
                            <TabList onChange={handleChangeTab}>
                                <Tab disableRipple value='info' label='Information'/>
                                {/*<Tab disableRipple value='regions' label='Regions'/>*/}
                            </TabList>
                        </Box>


                        <Card>
                            <TabPanel value='info'>
                                <Box sx={{p: 0, minHeight: '80%'}}>

                                    <Stack spacing={3}>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label='Nom'
                                                value={currentRiskType?.name}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label="Source de donnée"
                                                value={currentRiskType?.dataSource}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                 multiline minRows={2} maxRows={6}  label="Description"
                                                value={currentRiskType?.description}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>
                                    </Stack>
                                </Box>
                            </TabPanel>

                        </Card>

                    </TabContext>
                )}
            </Container>
        </Page>
    );
}
