import {useApiHttp} from '../../../../shared/hooks/useHttp';

const useInstrumentPolicyOccurrenceService = () => {
    const {crud} = useApiHttp();

    const {
        create,
        update,
        destroy,
        findById,
        findAll,
    } = crud('instrument-policy-occurrences');

    return {
        create,
        update,
        destroy,
        findById,
        findAll
    }
}

export default useInstrumentPolicyOccurrenceService;
