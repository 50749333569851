import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const PointsForMonitoringTheQualityOfTheWaterDistributedAndPollutionControlList = Loadable(lazy(() => import("./pages/PointsForMonitoringTheQualityOfTheWaterDistributedAndPollutionControlList")));
const PointsForMonitoringTheQualityOfTheWaterDistributedAndPollutionControlCreate = Loadable(
  lazy(() => import("./pages/PointsForMonitoringTheQualityOfTheWaterDistributedAndPollutionControlCreate"))
);
const PointsForMonitoringTheQualityOfTheWaterDistributedAndPollutionControlEdit = Loadable(lazy(() => import("./pages/PointsForMonitoringTheQualityOfTheWaterDistributedAndPollutionControlEdit")));
const PointsForMonitoringTheQualityOfTheWaterDistributedAndPollutionControlDisplay = Loadable(
  lazy(() => import("./pages/PointsForMonitoringTheQualityOfTheWaterDistributedAndPollutionControlDisplay"))
);

const pointsForMonitoringTheQualityOfTheWaterDistributedAndPollutionControlRouter = [
  { path: "points-for-monitoring-the-quality-of-the-water-distributed-and-pollution-control", element: <PointsForMonitoringTheQualityOfTheWaterDistributedAndPollutionControlList /> },
  { path: "points-for-monitoring-the-quality-of-the-water-distributed-and-pollution-control/:id", element: <PointsForMonitoringTheQualityOfTheWaterDistributedAndPollutionControlDisplay /> },
  { path: "points-for-monitoring-the-quality-of-the-water-distributed-and-pollution-control/new", element: <PointsForMonitoringTheQualityOfTheWaterDistributedAndPollutionControlCreate /> },
  { path: "points-for-monitoring-the-quality-of-the-water-distributed-and-pollution-control/:id/edit", element: <PointsForMonitoringTheQualityOfTheWaterDistributedAndPollutionControlEdit /> },
];

export default pointsForMonitoringTheQualityOfTheWaterDistributedAndPollutionControlRouter;
