import React from 'react';
// material
import MergeTypeIcon from "@mui/icons-material/MergeType";
// utils
import {PATH_RISK_PROTECTION} from '../../risk.protection.path';
import {useCountSicknessTypes} from '../services/sickness.type.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';

export default function SicknessTypeEntryButton() {
    const {count} = useCountSicknessTypes();
  return (
      <SubModuleEntryButton
          label='Types de maladies liés aux RE'
          value={count}
          url={PATH_RISK_PROTECTION.sicknessTypes}
          icon={<MergeTypeIcon />}
      />
  );
}
