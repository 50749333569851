import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const FeatureOccurrencesOfObservationDevicesList = Loadable(
  lazy(() => import("./pages/FeatureOccurrencesOfObservationDevicesList"))
);
const FeatureOccurrencesOfObservationDevicesCreate = Loadable(
  lazy(() => import("./pages/FeatureOccurrencesOfObservationDevicesCreate"))
);
const FeatureOccurrencesOfObservationDevicesEdit = Loadable(
  lazy(() => import("./pages/FeatureOccurrencesOfObservationDevicesEdit"))
);
const FeatureOccurrencesOfObservationDevicesDisplay = Loadable(
  lazy(() => import("./pages/FeatureOccurrencesOfObservationDevicesDisplay"))
);

const featureOccurrencesOfObservationDevicesRouter = [
  {
    path: "feature-occurrences-of-observation-devices",
    element: <FeatureOccurrencesOfObservationDevicesList />,
  },
  {
    path: "feature-occurrences-of-observation-devices/:id",
    element: <FeatureOccurrencesOfObservationDevicesDisplay />,
  },
  {
    path: "feature-occurrences-of-observation-devices/new",
    element: <FeatureOccurrencesOfObservationDevicesCreate />,
  },
  {
    path: "feature-occurrences-of-observation-devices/:id/edit",
    element: <FeatureOccurrencesOfObservationDevicesEdit />,
  },
];

export default featureOccurrencesOfObservationDevicesRouter;
