import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const WaterUseItemList = Loadable(lazy(() => import("./pages/WaterUseItemList")));
const WaterUseItemCreate = Loadable(lazy(() => import("./pages/WaterUseItemCreate")));
const WaterUseItemEdit = Loadable(lazy(() => import("./pages/WaterUseItemEdit")));
const WaterUseItemDisplay = Loadable(lazy(() => import("./pages/WaterUseItemDisplay")));

const waterUseItemRouter = [
  { path: "water-use-items", element: <WaterUseItemList /> },
  { path: "water-use-items/:id", element: <WaterUseItemDisplay /> },
  { path: "water-use-items/new", element: <WaterUseItemCreate /> },
  { path: "water-use-items/:id/edit", element: <WaterUseItemEdit /> },
];

export default waterUseItemRouter;
