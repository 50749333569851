import React from "react";
import {Navigate, useRoutes} from "react-router-dom";
import MainLayout from "./home/MainLayout";

import {PATH_APP} from "./home/sidebar/paths";

//Modules
import adminRouter from "./features/admin/admin.router";
import administrative_divisionRouter from "./features/administrative_division/administrative_division.router";
import reportingModuleRouter from "./features/reporting/reportingModuleRouter";
import testGraphqlRouter from "./features/test_graphql/testGraphql.router";
import othersPagesRouter from "./features/others_pages/othersPages.router";
import actorsRouter from "./features/actors/actors.router";
import {fakeRouters} from "./features/_fakes/fake.router";
import sigma4Router from "./features/sigma4/sigma4.router";
import waterUseRouter from "./features/water_use/water.use.router";
import waterManagementRouter from "./features/water_management/water.management.router";
import degradationProtectionRouter from "./features/degradation_protection/degradation.protection.router";
import waterQualityRouter from "./features/water_quality/water.quality.router";
import riskProtectionRouter from "./features/risk_protection/risk.protection.router";
import constructionRouter from "./features/construction/construction.router";
import irrigationNeedWaterRouter from "./features/irrigation_need_water/irrigation.need.water.router";
import energyRouter from "./features/energy/energy.router";
import environmentRouter from "./features/environment/environment.router";
import gireFinancingRouter from "./features/gire_financing/gire.financing.router";
import pastoralRouter from "./features/pastoral/pastoral.router";
import domesticRouter from "./features/domestic/domestic.router";
import waterUsageIrrigationRouter from "./features/water_usage_urrigation/water.usage.irrigation.router";
import moduleFishingRouter from "./features/module_fishing/module.fishing.router";
import potentialWaterAvailabilityRouter
    from "./features/potential_water_availability/potential.water.availability.router"
import managementInstrumentsRouter from "./features/management_instruments/management.instruments.router";
import organInstrumentRouter from "./features/organ_instrument/organ.instrument.router";
import gireEnvFavorableRouter from "./features/gire_env_favorable/gire.env.favorable.router";
import sanitationAccessRouter from "./features/sanitation_access/sanitation.access.router";

const AppRouter = () => {
    return useRoutes([
        {
            path: "/",
            element: <MainLayout/>,
            children: [
                {
                    path: "/",
                    element: <Navigate to={PATH_APP.home} replace/>,
                },
            ],
        },

        ...testGraphqlRouter,

        ...adminRouter,
        ...reportingModuleRouter,
        ...administrative_divisionRouter,
        ...actorsRouter,
        ...sigma4Router,

        ...waterUseRouter,
        ...waterManagementRouter,
        ...degradationProtectionRouter,
        ...waterQualityRouter,
        ...riskProtectionRouter,
        ...constructionRouter,
        ...energyRouter,
        ...environmentRouter,
        ...pastoralRouter,
        ...irrigationNeedWaterRouter,
        ...waterUsageIrrigationRouter,
        ...moduleFishingRouter,
        ...domesticRouter,
        ...gireFinancingRouter,
        ...potentialWaterAvailabilityRouter,
        ...managementInstrumentsRouter,
        ...organInstrumentRouter,
        ...gireEnvFavorableRouter,
        ...sanitationAccessRouter,

        ...fakeRouters(),

        ...othersPagesRouter,
    ]);
};

export default AppRouter;
