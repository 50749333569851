import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Box, Tab, Card, Container, Stack, TextField, CircularProgress} from '@mui/material';
import Page from '../../../../shared/components/Page';
import {InstrumentMechanismParticipationOccurrenceHeaderBreadcrumbs} from '../components';
import {FormRowStack} from '../../../../shared/components/froms/FormComponents';
import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useInstrumentMechanismParticipationOccurrence} from '../services/instrument.mechanism.participation.occurrence.gql';
import InstrumentMechanismParticipationOccurrenceMoreMenu from '../components/InstrumentMechanismParticipationOccurrenceMoreMenu';
import useInstrumentMechanismParticipationOccurrenceService from "../services/instrument.mechanism.participation.occurrence.services";
import {useDelete} from "../../../../shared/hooks/useDelete";

export default function InstrumentMechanismParticipationOccurrenceDisplay() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {showError} = useMessage();
    const [tapIndex, setTapIndex] = useState('info');
    const {loading, entity: currentInstrumentMechanismParticipationOccurrence, error, errorMessage} = useInstrumentMechanismParticipationOccurrence(id);

    const {destroy} = useInstrumentMechanismParticipationOccurrenceService();
    const {onDelete} = useDelete({
        destroyFn: destroy,
        onSuccess: () => navigate(PATH_GIRE_ENV_FAVORABLE.instrumentMechanismParticipationOccurrences),
    });

    useEffect(() => {
        if (loading) return;
        if (error) {
            showError(errorMessage);
            navigate(PATH_GIRE_ENV_FAVORABLE.instrumentMechanismParticipationOccurrences);
        }
    }, [error, loading, errorMessage]);

    const handleChangeTab = (event, newValue) => setTapIndex(newValue);

    return (
        <Page
            title={"Gire environment favorable: occurrences d'état de mise en œuvre des instruments de politique et stratégie eau"}>
            <Container maxWidth={'xl'}>
                <InstrumentMechanismParticipationOccurrenceHeaderBreadcrumbs
                    heading="occurrences d'état de mise en œuvre des instruments de politique et stratégie eau"
                    lastName={`${currentInstrumentMechanismParticipationOccurrence?.name ?? "occurrences d'état de mise en œuvre des instruments de politique et stratégie eau"}`}

                    action={<InstrumentMechanismParticipationOccurrenceMoreMenu id={Number(id)} onDelete={onDelete} hiddenDisplay/>}
                />

                {loading && <CircularProgress/>}
                {!loading && currentInstrumentMechanismParticipationOccurrence && (
                    <TabContext value={tapIndex}>
                        <Box sx={{mb: 4, direction: 'row', display: 'flex', justifyContent: 'space-between'}}>
                            <TabList onChange={handleChangeTab}>
                                <Tab disableRipple value='info' label='Information'/>
                                {/*<Tab disableRipple value='regions' label='Regions'/>*/}
                            </TabList>
                        </Box>


                        <Card>
                            <TabPanel value='info'>
                                <Box sx={{p: 0, minHeight: '80%'}}>

                                    <Stack spacing={3}>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label='Nom'
                                                value={currentInstrumentMechanismParticipationOccurrence?.name}
                                                InputProps={{readOnly: true}}
                                            />
                                            <TextField
                                                fullWidth
                                                label="Dégrée d'application"
                                                value={currentInstrumentMechanismParticipationOccurrence?.riskManagementToolApplicationDegreeObj?.name}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label="Année de reference de donnée"
                                                value={currentInstrumentMechanismParticipationOccurrence?.dataRefYearObj?.name}
                                                InputProps={{readOnly: true}}
                                            />
                                            <TextField
                                                fullWidth
                                                label="Instruments et mécanismes de participation à la GIRE"
                                                value={currentInstrumentMechanismParticipationOccurrence?.instrumentMechanismParticipationObj?.name}
                                                InputProps={{readOnly: true}}
                                            />

                                        </FormRowStack>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label="Justification"
                                                value={currentInstrumentMechanismParticipationOccurrence?.justification}
                                                InputProps={{readOnly: true}}
                                            />

                                            <TextField
                                                fullWidth
                                                label="Source de donnée"
                                                value={currentInstrumentMechanismParticipationOccurrence?.dataSource}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>

                                    </Stack>

                                </Box>

                            </TabPanel>
                            
                        </Card>

                    </TabContext>
                )}
            </Container>
        </Page>
    );
}
