import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import {useMessage} from '../../../../shared/hooks/useMessage';
// routes
import {PATH_ADMINISTRATIVE_DIVISION} from '../../administrative_division.path';
import {
    FormikDatePicker, FormikTextField, SimpleFormikForm,
} from '../../../../shared/components/froms/FormComponents';
import {setRefresh} from '../services/data.ref.year.gql';
import useDataRefYearService from '../services/data.ref.year.services';
import {DataRefYearSchema, InitialDataRefYear} from '../services/data.ref.year.store';
import {formErrorMessageParser} from '../../../../utils/utils';
import {useConnectFormikState} from '../../../../shared/hooks/useFormikState';
// ----------------------------------------------------------------------

DataRefYearForm.propTypes = {
    isEdit: PropTypes.bool,
    currentDataRefYear: PropTypes.object,
    fromDialog: PropTypes.object,
};

export default function DataRefYearForm({isEdit, currentDataRefYear, setCurrentDataRefYear, fromDialog}) {
    const navigate = useNavigate();
    const {create, update} = useDataRefYearService();
    const {showSuccess, showError} = useMessage();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: InitialDataRefYear(),
        validationSchema: DataRefYearSchema,
        onSubmit: async (dataRefYear, {setSubmitting}) => {
            try {
                dataRefYear = DataRefYearSchema.cast(dataRefYear);

                let message;
                if (!isEdit) {
                    dataRefYear = await create(dataRefYear);
                    message = 'Création avec succès';
                } else {
                    dataRefYear = await update(currentDataRefYear.id, dataRefYear);
                    message = 'Modification avec succès';
                }

                setRefresh(true);

                setCurrentDataRefYear();

                setSubmitting(false);
                showSuccess(message);

                if (fromDialog) {
                    fromDialog.onSave(dataRefYear);
                } else {
                    navigate(`${PATH_ADMINISTRATIVE_DIVISION.dataRefYears}/${dataRefYear.id}`);
                }
            } catch (error) {
                setSubmitting(false);
                showError(formErrorMessageParser({error, isEdit}));
            }
        }
    });

    useConnectFormikState({
        formik, stateValues: currentDataRefYear, setStateValues: setCurrentDataRefYear,
    });

    const onCancel = (_) => {
        setCurrentDataRefYear();
        if (fromDialog) {
            fromDialog.onCancel();
        } else {
            navigate(PATH_ADMINISTRATIVE_DIVISION.dataRefYears);
        }
    }

    return (
        <SimpleFormikForm formik={formik} isEdit={isEdit} onCancel={onCancel}>
            <FormikTextField label='Nom' name='name'/>

            <FormikDatePicker label='Debut' name='begin'/>

            <FormikDatePicker label='Fin' name='end'/>
        </SimpleFormikForm>
    );
}
