import React from 'react';
import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';
import bciLogo from '../../assets/images/logo20.png';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  let {width, height} = sx ?? {};
  return <Box component="img" src={bciLogo} sx={{ ...sx, width: width ?? 20, height: height ?? 20 }} />;
}
