export default class GeoPoint {

    static CHAR_DEG = "\u00B0";
    static CHAR_MIN = "\u0027";
    static CHAR_SEC = "\u0022";
    static CHAR_SEP = "\u0020";

    static MAX_LNG = 180;
    static MAX_LAT = 90;

    // decimal
    lng = NaN;
    lat = NaN;

    // degrees
    lngDeg = NaN;
    latDeg = NaN;

    constructor({lng, lat}) {
        switch (typeof (lng)) {
            case 'number':
                this.lngDeg = this.dec2deg(lng, GeoPoint.MAX_LNG);
                this.lng = lng;
                break;
            case 'string':
                if (this.decode(lng)) this.lngDeg = lng;
                this.lng = this.deg2dec(lng, GeoPoint.MAX_LNG);
                break;
            default:
                this.lng = 0;
        }

        switch (typeof (lat)) {
            case 'number':
                this.latDeg = this.dec2deg(lat, GeoPoint.MAX_LAT);
                this.lat = lat;
                break;
            case 'string':
                if (this.decode(lat)) this.latDeg = lat;
                this.lat = this.deg2dec(lat, GeoPoint.MAX_LAT);
                break;
            default:
                this.lat = 0;
        }
    }

    dec2deg(value, max) {
        const sign = value < 0 ? -1 : 1;
        const abs = Math.abs(Math.round(value * 1000000));

        if (abs > (max * 1000000)) return NaN;

        const dec = abs % 1000000 / 1000000;
        const deg = Math.floor(abs / 1000000) * sign;
        const min = Math.floor(dec * 60);
        const sec = (dec - min / 60) * 3600;

        let result = "";

        result += deg;
        result += GeoPoint.CHAR_DEG;
        result += GeoPoint.CHAR_SEP;
        result += min;
        result += GeoPoint.CHAR_MIN;
        result += GeoPoint.CHAR_SEP;
        result += sec.toFixed(2);
        result += GeoPoint.CHAR_SEC;

        return result;
    }

    deg2dec(value) {
        const matches = this.decode(value);

        if (!matches) return NaN;

        const deg = parseFloat(matches[1]);
        const min = parseFloat(matches[2]);
        const sec = parseFloat(matches[3]);

        if (isNaN(deg) || isNaN(min) || isNaN(sec)) return NaN;

        return deg + (min / 60.0) + (sec / 3600);
    }

    decode(value) {
        let pattern = "";

        // deg
        pattern += "(-?\\d+)";
        pattern += GeoPoint.CHAR_DEG;
        pattern += "\\s*";

        // min
        pattern += "(\\d+)";
        pattern += GeoPoint.CHAR_MIN;
        pattern += "\\s*";

        // sec
        pattern += "(\\d+(?:\\.\\d+)?)";
        pattern += GeoPoint.CHAR_SEC;

        return value.match(new RegExp(pattern));
    }

    toLatLng() {
        return {lat: this.lat, lng: this.lng}
    }

    toString() {
        return `${this.toStringDec()} (${this.toStringDeg()})`;
    }

    toStringDec() {
        return `${this.lat}, ${this.lng}`;
    }

    toStringDeg() {
        return `${this.latDeg}, ${this.lngDeg}`;
    }

};
