import React from 'react';
import {Link as RouterLink} from "react-router-dom";

import {useTheme} from "@mui/material/styles";
import {Refresh, Search} from "@mui/icons-material";
import {
    Box,
    IconButton,
    InputAdornment,
    Link,
    OutlinedInput,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";

import {useSearchOccurrenceAgriculturalProductions} from '../services/occurrence.agricultural.production.gql';
import useOccurrenceAgriculturalProductionService from '../services/occurrence.agricultural.production.services';
import {usePagination} from '../../../../shared/components/tables';
import SortableTableHead from "../../../../shared/components/tables/SortableTableHead";
import MyTablePagination from "../../../../shared/components/tables/MyTablePagination";
import {OccurrenceAgriculturalProductionCreateDialogMenuItem, OccurrenceAgriculturalProductionMoreMenu} from "./index";
import {useDelete} from "../../../../shared/hooks/useDelete";
import {PATH_WATER_USAGE_URIGATION} from "../../water.usage.irrigation.path";

const TABLE_HEAD = [
    {id: 'id', label: 'ID', alignRight: false},
    {id: 'name', label: 'Nom', alignRight: false},
    {id: 'irrigatedDevelopmentEntityUsagesObj', label: "Entités d'Aménagement irrigués", alignRight: false},
    {
        id: 'culturalSpeculationEntityUsagesObj',
        label: 'Entités de spéculations',
        alignRight: false
    },
    {
        id: 'dataRefYearObj',
        label: 'Année de reference',
        alignRight: false
    },
    {id: 'dataSource', label: 'Source de données', alignRight: false},
    {id: ''}
];

export default function OccurrenceAgriculturalProductionTable({irrigatedDevelopmentEntityUsages, currentIrrigatedDevelopmentEntityUsages, culturalSpeculationEntityUsages, currentCulturalSpeculationEntityUsages}) {
    const theme = useTheme();
    const {
        page, order, filterName, rowsPerPage, orderBy, handleRequestSort,
        handleChangePage, handleChangeRowsPerPage, handleFilterByName
    } = usePagination();

    const {occurrenceAgriculturalProductions,
        refetch,
        occurrenceAgriculturalProductionPage} = useSearchOccurrenceAgriculturalProductions({
        query: filterName,
        size: rowsPerPage,
        page, irrigatedDevelopmentEntityUsages
    });

    const {destroy} = useOccurrenceAgriculturalProductionService();
    const {onDelete} = useDelete({
        destroyFn: destroy, onSuccess: refetch
    });

    return (
        <>
            <Toolbar
                sx={{
                    height: 96,
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: theme.spacing(0, 1, 0, 3),
                }}
            >

                <OutlinedInput
                    sx={{width: 240}}
                    value={filterName}
                    onChange={handleFilterByName}
                    placeholder="Recherche occurrences de productions agricoles"
                    startAdornment={
                        <InputAdornment position="start">
                            <Box component={Search} sx={{color: 'text.disabled'}}/>
                        </InputAdornment>
                    }
                />

                <Box>
                    {currentIrrigatedDevelopmentEntityUsages && (
                        <OccurrenceAgriculturalProductionCreateDialogMenuItem
                            btn
                            irrigatedDevelopmentEntityUsages={currentIrrigatedDevelopmentEntityUsages}
                            onSave={(createdIrrigatedDevelopmentEntityUsages) => {
                                console.log(`##### createdIrrigatedDevelopmentEntityUsages: `, createdIrrigatedDevelopmentEntityUsages);
                                refetch();
                            }}
                        />
                    )}
                    {culturalSpeculationEntityUsages && (
                        <OccurrenceAgriculturalProductionCreateDialogMenuItem
                            btn
                            culturalSpeculationEntityUsages={currentCulturalSpeculationEntityUsages}
                            onSave={(createdCulturalSpeculationEntityUsages) => {
                                console.log(`##### createdCulturalSpeculationEntityUsages: `, createdCulturalSpeculationEntityUsages);
                                refetch();
                            }}
                        />
                    )}
                    <Tooltip title='Actualiser'>
                        <IconButton onClick={() => refetch()}>
                            <Refresh/>
                        </IconButton>
                    </Tooltip>
                </Box>
            </Toolbar>
            <TableContainer sx={{minWidth: 800}}>
                <Table size="small">
                    <SortableTableHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {occurrenceAgriculturalProductions.map((row) => (<Row key={row.id} {...{row, onDelete}} />))}
                    </TableBody>
                </Table>
            </TableContainer>

            <MyTablePagination
                page={occurrenceAgriculturalProductionPage}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}

function Row({row, onDelete}) {
    const {id, name, irrigatedDevelopmentEntityUsagesObj, dataRefYearObj, culturalSpeculationEntityUsagesObj, dataSource} = row;

    return (
        <TableRow hover sx={{'&:last-child td, &:last-child th': {border: 0}}}>
            <TableCell component='th' scope='row'>
                <Link
                    to={PATH_WATER_USAGE_URIGATION.occurrenceAgriculturalProductions + '/' + id}
                    component={RouterLink}
                >
                    <Typography variant='body2' noWrap>
                        {id}
                    </Typography>
                </Link>
            </TableCell>
            <TableCell component='th' scope='row'>
                <Link
                    to={PATH_WATER_USAGE_URIGATION.occurrenceAgriculturalProductions + '/' + id}
                    component={RouterLink}
                >
                    <Typography
                        variant='body2' noWrap>
                        {name}
                    </Typography>
                </Link>
            </TableCell>

            <TableCell component='th' scope='row'>
                <Link
                    to={PATH_WATER_USAGE_URIGATION.irrigatedDevelopmentEntityUsages + '/' + irrigatedDevelopmentEntityUsagesObj?.id}
                    component={RouterLink}
                >
                    <Typography
                        variant='body2' noWrap>
                        {irrigatedDevelopmentEntityUsagesObj?.name}
                    </Typography>
                </Link>
            </TableCell>

            <TableCell component='th' scope='row'>
                    <Typography
                        variant='body2' noWrap>
                        {dataRefYearObj?.name}
                    </Typography>
            </TableCell>

            <TableCell style={{minWidth: 160}}>
                <Typography variant='body2' noWrap> {culturalSpeculationEntityUsagesObj?.name} </Typography>
            </TableCell>

            <TableCell style={{minWidth: 160}}>
                <Typography variant='body2' noWrap> {dataSource} </Typography>
            </TableCell>

            <TableCell align='right'>
                <OccurrenceAgriculturalProductionMoreMenu {...{id, onDelete}} isIconButton hiddenNew/>
            </TableCell>
        </TableRow>
    );
}


