import * as Yup from 'yup';
import create from 'zustand';


export const AhpTypeSchema = Yup.object().shape({
    name: Yup.string().default('').required('Le nom est obligatoire'),
    description: Yup.string().default(''),
    dataSource: Yup.string().default(''),
});

const initialAhpType = AhpTypeSchema.getDefaultFromShape();
export const InitialAhpType = () => ({...initialAhpType});

const setCurrentNewAhpType = set => ahpType => {
    set(state => ({...state, currentNewAhpType: ahpType ?? InitialAhpType()}));
}

const setCurrentUpdateAhpType = set => ahpType => {
    set(state => ({...state, currentUpdateAhpType: ahpType ?? InitialAhpType()}));
}

export const useAhpTypeStore = create(set => ({
    //State
    currentNewAhpType: InitialAhpType(),
    currentUpdateAhpType: InitialAhpType(),
    //Reducers
    setCurrentNewAhpType: setCurrentNewAhpType(set),
    setCurrentUpdateAhpType: setCurrentUpdateAhpType(set),
}))
