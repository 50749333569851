import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import MainLayout from "../../home/AppLayout";
import Loadable from "../../shared/components/Loadable";
import { PATH_SIGMA4 } from "./sigma4.path";
//Features
import infrastructureTypeRouter from "./infrastructure_type/infrastructure.type.router";
import siteTypeRouter from "./site_type/site.type.router";
import saepTypeRouter from "./saep_type/saep.type.router";
import saepRouter from "./saep/saep.router";
import energyTypeRouter from "./energy/energy.router";
import exploitantTypeRouter from "./exploitant_type/exploitant.type.router";
import drillingResultRouter from "./drilling_result/drilling.result.router";
import coordinatedModeRouter from "./coordinated_mode/coordinated.mode.router";
import aquiferRouter from "./aquifer/aquifer.router";
import lithologyRouter from "./lithology/lithology.router";
import dewateringTypeRouter from "./dewatering_type/dewatering.type.router";
import hydrologicalUnitRouter from "./hydrological_unit/hydrological.unit.router";
import infrastructureRouter from "./infrastructure/infrastructure.router";
import annularDrillingRouter from "./annular_drilling/annular.drilling.router";
import annularNatureRouter from "./annular_nature/annular.nature.router";
import drillingWaterComingRouter from "./drilling_water_coming/drilling.water.coming.router";
import infrastructureDrillingRouter from "./infrastructure_drilling/infrastructure.drilling.router";
import operateurRouter from "./operateur/operateur.router";
import wallTypeRouter from "./wall_type/wall.type.router";
import wallNatureRouter from "./wall_nature/wall.nature.router";
import casingInfrastructureRouter from "./casing_infrastructure/casing.infrastructure.router";
import lithologyInfrastructureRouter from "./lithology_infrastructure/lithology.infrastructure.router";
import pumpInfrastructureRouter from "./pump_infrastructure/pump.infrastructure.router";
import analyzeInfrastructureRouter from "./analyze_infrastructure/analyze.infrastructure.router";
import tankRouter from "./tank/tank.router";
import tankNatureRouter from "./tank_nature/tank.nature.router";
import distributionRouter from "./distribution/distribution.router";
import infrastructureWellRouter from "./infrastructure_well/infrastructure.well.router";

const Sigma4Home = Loadable(lazy(() => import("./sigma4.home")));

const sigma4Router = [
  {
    path: PATH_SIGMA4.root,
    element: <MainLayout />,
    children: [
      {
        path: PATH_SIGMA4.root,
        element: <Navigate to={PATH_SIGMA4.home} replace />,
      },
      { path: "home", element: <Sigma4Home /> },
      ...infrastructureRouter,
      ...infrastructureTypeRouter,
      ...siteTypeRouter,
      ...saepTypeRouter,
      ...saepRouter,
      ...exploitantTypeRouter,
      ...energyTypeRouter,
      ...drillingResultRouter,
      ...coordinatedModeRouter,
      ...aquiferRouter,
      ...lithologyRouter,
      ...dewateringTypeRouter,
      ...hydrologicalUnitRouter,
      ...operateurRouter,
      ...wallTypeRouter,
      ...wallNatureRouter,
      ...casingInfrastructureRouter,
      ...lithologyInfrastructureRouter,
      ...pumpInfrastructureRouter,
      ...analyzeInfrastructureRouter,
      ...annularDrillingRouter,
      ...annularNatureRouter,
      ...drillingWaterComingRouter,
      ...infrastructureDrillingRouter,
      ...infrastructureWellRouter,
      ...tankNatureRouter,
      ...tankRouter,
      ...distributionRouter,
    ],
  },
];

export default sigma4Router;
