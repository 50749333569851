import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const ActivityTypeList = Loadable(
  lazy(() => import("./pages/ActivityTypeList"))
);
const ActivityTypeCreate = Loadable(
  lazy(() => import("./pages/ActivityTypeCreate"))
);
const ActivityTypeEdit = Loadable(
  lazy(() => import("./pages/ActivityTypeEdit"))
);
const ActivityTypeDisplay = Loadable(
  lazy(() => import("./pages/ActivityTypeDisplay"))
);

const activityTypeRouter = [
  { path: "activity-types", element: <ActivityTypeList /> },
  { path: "activity-types/:id", element: <ActivityTypeDisplay /> },
  { path: "activity-types/new", element: <ActivityTypeCreate /> },
  { path: "activity-types/:id/edit", element: <ActivityTypeEdit /> },
];

export default activityTypeRouter;
