import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const RiskManagementToolsImplementationOccurrenceList = Loadable(lazy(() => import("./pages/RiskManagementToolsImplementationOccurrenceList")));
const RiskManagementToolsImplementationOccurrenceCreate = Loadable(lazy(() => import("./pages/RiskManagementToolsImplementationOccurrenceCreate")));
const RiskManagementToolsImplementationOccurrenceEdit = Loadable(lazy(() => import("./pages/RiskManagementToolsImplementationOccurrenceEdit")));
const RiskManagementToolsImplementationOccurrenceDisplay = Loadable(lazy(() => import("./pages/RiskManagementToolsImplementationOccurrenceDisplay")));

const riskManagementToolsImplementationOccurrenceRouter = [
  { path: "risk-management-tools-implementation-occurrences", element: <RiskManagementToolsImplementationOccurrenceList /> },
  { path: "risk-management-tools-implementation-occurrences/:id", element: <RiskManagementToolsImplementationOccurrenceDisplay /> },
  { path: "risk-management-tools-implementation-occurrences/new", element: <RiskManagementToolsImplementationOccurrenceCreate /> },
  { path: "risk-management-tools-implementation-occurrences/:id/edit", element: <RiskManagementToolsImplementationOccurrenceEdit /> },
];

export default riskManagementToolsImplementationOccurrenceRouter;
