import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const BloliqueWaterRequirementList = Loadable(
  lazy(() => import("./pages/BloliqueWaterRequirementList"))
);
const BloliqueWaterRequirementCreate = Loadable(
  lazy(() => import("./pages/BloliqueWaterRequirementCreate"))
);
const BloliqueWaterRequirementEdit = Loadable(
  lazy(() => import("./pages/BloliqueWaterRequirementEdit"))
);
const BloliqueWaterRequirementDisplay = Loadable(
  lazy(() => import("./pages/BloliqueWaterRequirementDisplay"))
);

const bloliqueWaterRequirementRouter = [
  {
    path: "blolique-water-requirement",
    element: <BloliqueWaterRequirementList />,
  },
  {
    path: "blolique-water-requirement/:id",
    element: <BloliqueWaterRequirementDisplay />,
  },
  {
    path: "blolique-water-requirement/new",
    element: <BloliqueWaterRequirementCreate />,
  },
  {
    path: "blolique-water-requirement/:id/edit",
    element: <BloliqueWaterRequirementEdit />,
  },
];

export default bloliqueWaterRequirementRouter;
