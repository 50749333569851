import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const AquiferList = Loadable(lazy(() => import("./pages/AquiferList")));
const AquiferCreate = Loadable(lazy(() => import("./pages/AquiferCreate")));
const AquiferEdit = Loadable(lazy(() => import("./pages/AquiferEdit")));
const AquiferDisplay = Loadable(lazy(() => import("./pages/AquiferDisplay")));

const aquiferRouter = [
  { path: "aquifers", element: <AquiferList /> },
  { path: "aquifers/:id", element: <AquiferDisplay /> },
  { path: "aquifers/new", element: <AquiferCreate /> },
  { path: "aquifers/:id/edit", element: <AquiferEdit /> },
];

export default aquiferRouter;
