import React, {useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
// material
import {CircularProgress, Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {AhpTypeForm} from '../components';

import {AhpTypeHeaderBreadcrumbs} from '../components';

import {PATH_FISHING} from '../../module.fishing.path';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useAhpType} from '../services/ahp.type.gql';
import {useAhpTypeStore} from "../services/ahp.type.store";

export default function AhpTypeEdit() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {showError} = useMessage();

    const {setCurrentUpdateAhpType: setCurrentAhpType} = useAhpTypeStore();

    const {entity: currentAhpType, error, loading, errorMessage} = useAhpType(id);

    useEffect(() => {
        if (loading) return;
        if (error) {
            showError(errorMessage);
            navigate(PATH_FISHING.ahpType);
        }
    }, [loading, error, errorMessage]);

    useEffect(() => {
        setCurrentAhpType(currentAhpType);
    }, [currentAhpType]);

    return (
        <Page title={'Pêche: Modification type AHP'}>
            <Container maxWidth={'xl'}>
                <AhpTypeHeaderBreadcrumbs
                    heading='Modification du type AHP'
                    lastName={`Modification du type AHP - ${id}`}

                />

                {loading && <CircularProgress/>}
                {!loading && currentAhpType && <AhpTypeForm isEdit {...{currentAhpType, setCurrentAhpType}}/>}
            </Container>
        </Page>
    );
}
