import React from "react";
import { path } from "../../home/sidebar/paths";
import { WaterRounded } from "@mui/icons-material";

//Features
export const IRRIGATION_NEED_WATER = "/irrigation-need-water";
const _path = path(IRRIGATION_NEED_WATER);
export const PATH_IRRIGATION_NEED_WATER = {
  root: IRRIGATION_NEED_WATER,
  home: _path("/home"),
  blolique_water_requirements: _path("/blolique-water-requirement"),
  cultural_speculation_entities: _path("/cultural-speculation-entities"),
  hydro_agricultural_development_entities: _path(
    "/hydro-agricultural-development-entities"
  ),
  irrigated_development_entities: _path("/irrigated-development-entities"),
  occurrences_of_water_need: _path("/occurrences-of-water-need"),
  aha_types: _path("/aha-types"),
};

export const irrigationNeedWaterMenu = [
  {
    title: "Besoin en eau d'irrigation",
    path: PATH_IRRIGATION_NEED_WATER.root,
    icon: <WaterRounded />,
    children: [
      { title: "Accueil", path: PATH_IRRIGATION_NEED_WATER.home },
      {
        title: "Besoin blolique en eau",
        path: PATH_IRRIGATION_NEED_WATER.blolique_water_requirements,
      },
      {
        title: "Spéculations culturales",
        path: PATH_IRRIGATION_NEED_WATER.cultural_speculation_entities,
      },
      {
        title: "Aménagements Hydro-agricoles",
        path: PATH_IRRIGATION_NEED_WATER.hydro_agricultural_development_entities,
      },
      {
        title: "Aménagements irrigués",
        path: PATH_IRRIGATION_NEED_WATER.irrigated_development_entities,
      },
      // {
      //   title: "Occurrences besoin en eau",
      //   path: PATH_IRRIGATION_NEED_WATER.occurrences_of_water_need,
      // },
      {
        title: "Type aménagements HA",
        path: PATH_IRRIGATION_NEED_WATER.aha_types,
      },
    ],
  },
];

export const commonItems = [
  { name: "Accueil", href: "/" },
  { name: "Qualité Eau", href: PATH_IRRIGATION_NEED_WATER.home },
];
