import * as Yup from 'yup';
import create from 'zustand';


export const LegislativeTextOccurrenceSchema = Yup.object().shape({
    name: Yup.string().default('').required('Le nom est obligatoire'),
    justification: Yup.string().required("La justification est obligatoire"),
    riskManagementToolApplicationDegree: Yup.string().required("Le degrée d'application est obligatoire"),
    dataRefYear: Yup.string().required("L'année de reference de donnée"),
    dataSource: Yup.string().default(''),
});

const initialLegislativeTextOccurrence = LegislativeTextOccurrenceSchema.getDefaultFromShape();
export const InitialLegislativeTextOccurrence = () => ({...initialLegislativeTextOccurrence});

const setCurrentNewLegislativeTextOccurrence = set => legislativeTextOccurrence => {
    set(state => ({...state, currentNewLegislativeTextOccurrence: legislativeTextOccurrence ?? InitialLegislativeTextOccurrence()}));
}

const setCurrentUpdateLegislativeTextOccurrence = set => legislativeTextOccurrence => {
    set(state => ({...state, currentUpdateLegislativeTextOccurrence: legislativeTextOccurrence ?? InitialLegislativeTextOccurrence()}));
}

export const useLegislativeTextOccurrenceStore = create(set => ({
    //State
    currentNewLegislativeTextOccurrence: InitialLegislativeTextOccurrence(),
    currentUpdateLegislativeTextOccurrence: InitialLegislativeTextOccurrence(),
    //Reducers
    setCurrentNewLegislativeTextOccurrence: setCurrentNewLegislativeTextOccurrence(set),
    setCurrentUpdateLegislativeTextOccurrence: setCurrentUpdateLegislativeTextOccurrence(set),
}))
