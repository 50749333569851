import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { parseCount, parseEntity } from "../../../../utils/gql.utils";
import { useEffect, useState } from "react";
import { useMessage } from "../../../../shared/hooks/useMessage";

export let needRefresh = false;
export const setRefresh = (value) => (needRefresh = value);
const CulturalSpeculationEntityUsagesFields = `
    id name code dataSource averageAnnualCost 
    dataRefYear dataRefYearObj{id name} 
    description`;

///////////////////// Find By ID /////////////////////
const GET_CULTURAL_SPECULATION_ENTITIES_BY_ID = gql`
    query CulturalSpeculationEntityUsagesById($id: Int!) {
        culturalSpeculationEntityUsagesById(id: $id) {
            error message entity { ${CulturalSpeculationEntityUsagesFields} }
        }
    }
`;

export const useCulturalSpeculationEntityUsagesById = (id) => {
  id = parseInt(id);
  return useQuery(GET_CULTURAL_SPECULATION_ENTITIES_BY_ID, {
    variables: { id },
    fetchPolicy: "network-only",
  });
};

export const useCulturalSpeculationEntityUsages = (id) => {
  const result = useCulturalSpeculationEntityUsagesById(id);
  return parseEntity(result, "culturalSpeculationEntityUsagesById");
};

///////////////////// Search /////////////////////
const SEARCH_CULTURAL_SPECULATION_ENTITIES_BY_ID = gql`
    query SearchCulturalSpeculationEntityUsages($query: String,$dataRefYear: Int, $page: Int, $size: Int) {
        searchCulturalSpeculationEntityUsages(query: $query, dataRefYear:$dataRefYear, page: $page, size: $size) {
            error message page {
                content { ${CulturalSpeculationEntityUsagesFields} }
                page hasNext hasPrevious totalPages totalElements numberOfElements
            }
        }
    }
`;

export const useSearchCulturalSpeculationEntityUsages = ({
                                                       query= "", dataRefYear,
                                                       page = 0,
                                                       size = 20,
                                                     }) => {
  const [culturalSpeculationEntityUsages, setCulturalSpeculationEntityUsages] =
      useState([]);
  const [culturalSpeculationEntityUsagesPage, setCulturalSpeculationEntityUsagesPage] =
      useState(null);

  const { showError } = useMessage();

  const [search, { data: searchData, refetch, loading, error: _error }] =
      useLazyQuery(SEARCH_CULTURAL_SPECULATION_ENTITIES_BY_ID, {
        variables: { query, dataRefYear, page, size },
      });

  useEffect(() => {
    if (_error) {
      showError("Erreur lors de l'operation");
      setCulturalSpeculationEntityUsagesPage(null);
      setCulturalSpeculationEntityUsages([]);
      return;
    }

    if (!searchData) return;

    const { error, message, page } =
        searchData.searchCulturalSpeculationEntityUsages;

    if (error) {
      showError(message);
      setCulturalSpeculationEntityUsagesPage(null);
      setCulturalSpeculationEntityUsages([]);
    } else {
      setCulturalSpeculationEntityUsages(page.content);
      setCulturalSpeculationEntityUsagesPage(page);
    }

    if (needRefresh) {
      refetch();
      setRefresh(false);
    }
  }, [_error, searchData, needRefresh, refetch, showError]);

  useEffect(() => {
    search({ variables: { query, dataRefYear, page, size } });
  }, [query, dataRefYear, page, size]);

  return {
    culturalSpeculationEntityUsages,
    loading,
    search,
    refetch,
    culturalSpeculationEntityUsagesPage,
  };
};

///////////////////// Count /////////////////////
const COUNT_CULTURAL_SPECULATION_ENTITIES = gql`
  query CountCulturalSpeculationEntityUsages {
    countCulturalSpeculationEntityUsages {
      error
      message
      count
    }
  }
`;

export const useCountCulturalSpeculationEntityUsages = () => {
  const result = useQuery(COUNT_CULTURAL_SPECULATION_ENTITIES, {
    fetchPolicy: "network-only",
  });
  return parseCount(result, "countCulturalSpeculationEntityUsages");
};
