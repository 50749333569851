import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const InterventionTypeList = Loadable(
  lazy(() => import("./pages/InterventionTypeList"))
);
const InterventionTypeCreate = Loadable(
  lazy(() => import("./pages/InterventionTypeCreate"))
);
const InterventionTypeEdit = Loadable(
  lazy(() => import("./pages/InterventionTypeEdit"))
);
const InterventionTypeDisplay = Loadable(
  lazy(() => import("./pages/InterventionTypeDisplay"))
);

const interventionTypeRouter = [
  { path: "intervention-types", element: <InterventionTypeList /> },
  { path: "intervention-types/:id", element: <InterventionTypeDisplay /> },
  { path: "intervention-types/new", element: <InterventionTypeCreate /> },
  { path: "intervention-types/:id/edit", element: <InterventionTypeEdit /> },
];

export default interventionTypeRouter;
