import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const PlantOccurrenceList = Loadable(lazy(() => import('./pages/PlantOccurrenceList')));
const PlantOccurrenceCreate = Loadable(lazy(() => import('./pages/PlantOccurrenceCreate')));
const PlantOccurrenceEdit = Loadable(lazy(() => import('./pages/PlantOccurrenceEdit')));
const PlantOccurrenceDisplay = Loadable(lazy(() => import('./pages/PlantOccurrenceDisplay')));

const plantOccurrenceRouter = [
    {path: 'plant-occurrences', element: <PlantOccurrenceList/>},
    {path: 'plant-occurrences/:id', element: <PlantOccurrenceDisplay/>},
    {path: 'plant-occurrences/new', element: <PlantOccurrenceCreate/>},
    {path: 'plant-occurrences/:id/edit', element: <PlantOccurrenceEdit/>},
];

export default plantOccurrenceRouter;
