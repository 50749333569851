import { useEffect, useState } from "react";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { parseCount, parseEntity } from "../../../../utils/gql.utils";
import { useMessage } from "../../../../shared/hooks/useMessage";

export let needRefresh = false;
export const setRefresh = (value) => (needRefresh = value);

export const originPopulationFields = `
    id wording code description
`;

///////////////////// Find By ID /////////////////////
const GET_ORIGIN_POPULATION_BY_ID = gql`
    query OriginPopulationById($id: Int!) {
        originPopulationById(id: $id) {
            error message entity { ${originPopulationFields} }
        }
    }
`;

export const useOriginPopulationById = (id) => {
  id = parseInt(id);
  return useQuery(GET_ORIGIN_POPULATION_BY_ID, {
    variables: { id },
    fetchPolicy: "network-only",
  });
};

export const useOriginPopulation = (id) => {
  const result = useOriginPopulationById(id);
  return parseEntity(result, "originPopulationById");
};

///////////////////// Search /////////////////////
const SEARCH_ORIGIN_POPULATIONS = gql`
    query SearchOriginPopulations($query: String, $page: Int, $size: Int) {
        searchOriginPopulations(query: $query, page: $page, size: $size) {
            error message page {
                content { ${originPopulationFields} }
                page hasNext hasPrevious totalPages totalElements numberOfElements
            }
        }
    }
`;

export const useSearchOriginPopulations = ({
  query = "",
  page = 0,
  size = 20,
}) => {
  const [originPopulations, setOriginPopulations] = useState([]);
  const [originPopulationPage, setOriginPopulationPage] = useState(null);

  const { showError } = useMessage();

  const [search, { data: searchData, refetch, loading, error: _error }] =
    useLazyQuery(SEARCH_ORIGIN_POPULATIONS, {
      variables: { query, page, size },
    });

  useEffect(() => {
    if (_error) {
      showError("Erreur lors de l'operation");
      setOriginPopulationPage(null);
      setOriginPopulations([]);
      return;
    }

    if (!searchData) return;

    const { error, message, page } = searchData.searchOriginPopulations;

    if (error) {
      showError(message);
      setOriginPopulationPage(null);
      setOriginPopulations([]);
    } else {
      setOriginPopulationPage(page);
      setOriginPopulations(page.content);
    }

    if (needRefresh) {
      refetch();
      setRefresh(false);
    }
  }, [_error, searchData, needRefresh]);

  useEffect(() => {
    search({ variables: { query, page, size } });
  }, [query, page, size]);

  return {
    originPopulations,
    loading,
    search,
    refetch,
    originPopulationPage,
  };
};

///////////////////// Count /////////////////////
const COUNT_ORIGIN_POPULATION = gql`
  query CountOriginPopulation {
    countOriginPopulation {
      error
      message
      count
    }
  }
`;

export const useCountOriginPopulation = () => {
  const result = useQuery(COUNT_ORIGIN_POPULATION, {
    fetchPolicy: "network-only",
  });
  return parseCount(result, "countOriginPopulation");
};
