import React from 'react';
import {AmenagementTypeForm} from '../components';
import CreateDialogMenuItem from '../../../../shared/components/CreateDialogMenuItem';
import {useAmenagementTypeStore} from "../services/amenagement.type.store";

export function AmenagementTypeCreateDialogMenuItem({onClose, onSave, btn}) {
    const {currentNewAmenagementType: currentAmenagementType, setCurrentNewAmenagementType: setCurrentAmenagementType} = useAmenagementTypeStore();
    return (
        <CreateDialogMenuItem
            btn={btn}
            onClose={onClose}
            menuText="Ajouter un type d'aménagement"
            dialogTitle="Type d'aménagement"
            dialogContextText={<> {`Création de nouveau type d'aménagement`} </>}
            formRender={({onClose}) => {
                return (
                    <AmenagementTypeForm
                        {...{currentAmenagementType, setCurrentAmenagementType}}
                        fromDialog={{
                            onCancel: onClose,
                            onSave: (createdAmenagementType) => {
                                onSave && onSave(createdAmenagementType);
                                onClose && onClose();
                            }
                        }}/>
                );
            }}
        />
    );

}
