import React from 'react';
import {AdminPanelSettings} from '@mui/icons-material';
import {path} from '../../home/sidebar/paths';

//Features
const ROOTS_ADMIN = '/admin';
const pathAdmin = path(ROOTS_ADMIN);
export const PATH_ADMIN = {
    root: ROOTS_ADMIN,
    home: pathAdmin('/home'),
    groups: pathAdmin('/groups'),
    users: pathAdmin('/users'),
};

export const adminMenu = [
    {
        title: 'Administration',
        path: PATH_ADMIN.root,
        icon: <AdminPanelSettings/>,
        children: [
            { title: 'Accueil', path: PATH_ADMIN.home },
            { title: `Groupe d'utilisateur`, path: PATH_ADMIN.groups },
            { title: 'Utilisateurs', path: PATH_ADMIN.users },
        ]
    }
];

export const commonItems = [
    {name: 'Accueil', href: '/'},
    {name: 'Administration', href: PATH_ADMIN.home},
];
