import {gql, useQuery, useLazyQuery} from '@apollo/client';
import {parseCount, parseEntity} from "../../../../utils/gql.utils";
import {useEffect, useState} from "react";
import {useMessage} from "../../../../shared/hooks/useMessage";

export let needRefresh = false;
export const setRefresh = (value) => needRefresh = value;

export const circleFields = `
    id name region regionObj {id name}
`;

///////////////////// Find By ID /////////////////////
const GET_CIRCLE_BY_ID = gql`
    query CircleById($id: Int!) {
        circleById(id: $id) {
            error message entity { ${circleFields} }
        }
    }
`;

export const useCircleById = (id) => {
    id = parseInt(id);
    return useQuery(GET_CIRCLE_BY_ID, {
        variables: {id},
    });
}

export const useCircle = (id) => {
    const result = useCircleById(id);
    return parseEntity(result, 'circleById');
}

///////////////////// Search /////////////////////
const SEARCH_CIRCLES = gql`
    query SearchCircles($query: String, $region: Int, $page: Int, $size: Int) {
        searchCircles(query: $query, region: $region, page: $page, size: $size) {
            error message page {
                content { ${circleFields} }
                page hasNext hasPrevious totalPages, totalElements, numberOfElements,
            }
        }
    }
`;

export const useSearchCircles = ({query = '', region, page = 0, size = 20}) => {
    const [circles, setCircles] = useState([]);
    const [circlePage, setCirclePage] = useState(null);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_CIRCLES, {
        variables: {query, region, page, size},
    });

    useEffect(() => {
        if(_error) {
            showError('Erreur lors de l\'operation');
            setCirclePage(null);
            setCircles([]);
            return;
        }

        if (!searchData) return;

        const {error, message, page} = searchData.searchCircles;


        if (error) {
            showError(message);
            setCirclePage(null);
            setCircles([]);
        } else {
            setCirclePage(page);
            setCircles(page.content);
        }


        if (needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({variables: {query, region, page, size}});
    }, [query, region, page, size]);

    return {
        circles, loading, search, refetch, circlePage
    }
}

///////////////////// Count /////////////////////
const COUNT_CIRCLES = gql`
    query CountCircles {
        countCircles {
            error message count
        }
    }
`;

export const useCountCircles = () => {
    const result = useQuery(COUNT_CIRCLES, {
        fetchPolicy: 'network-only'
    });
    return parseCount(result, 'countCircles');
}
