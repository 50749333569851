import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const OriginPopulationList = Loadable(
  lazy(() => import("./pages/OriginPopulationList"))
);
const OriginPopulationCreate = Loadable(
  lazy(() => import("./pages/OriginPopulationCreate"))
);
const OriginPopulationEdit = Loadable(
  lazy(() => import("./pages/OriginPopulationEdit"))
);
const OriginPopulationDisplay = Loadable(
  lazy(() => import("./pages/OriginPopulationDisplay"))
);

const originPopulationRouter = [
  { path: "origin-populations", element: <OriginPopulationList /> },
  { path: "origin-populations/:id", element: <OriginPopulationDisplay /> },
  { path: "origin-populations/new", element: <OriginPopulationCreate /> },
  { path: "origin-populations/:id/edit", element: <OriginPopulationEdit /> },
];

export default originPopulationRouter;
