import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const CommunicationDirectoryList = Loadable(
  lazy(() => import("./pages/CommunicationDirectoryList"))
);
const CommunicationDirectoryCreate = Loadable(
  lazy(() => import("./pages/CommunicationDirectoryCreate"))
);
const CommunicationDirectoryEdit = Loadable(
  lazy(() => import("./pages/CommunicationDirectoryEdit"))
);
const CommunicationDirectoryDisplay = Loadable(
  lazy(() => import("./pages/CommunicationDirectoryDisplay"))
);

const communicationDirectoryRouter = [
  {
    path: "communication-directories",
    element: <CommunicationDirectoryList />,
  },
  {
    path: "communication-directories/:id",
    element: <CommunicationDirectoryDisplay />,
  },
  {
    path: "communication-directories/new",
    element: <CommunicationDirectoryCreate />,
  },
  {
    path: "communication-directories/:id/edit",
    element: <CommunicationDirectoryEdit />,
  },
];

export default communicationDirectoryRouter;
