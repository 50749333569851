import React from 'react';
// material
import {Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {LegislativeTextOccurrenceForm} from '../components';

import {LegislativeTextOccurrenceHeaderBreadcrumbs} from '../components';
import {useLegislativeTextOccurrenceStore} from "../services/legislative.text.occurrence.store";

// ----------------------------------------------------------------------

export default function LegislativeTextOccurrenceCreate() {
    const {currentNewLegislativeTextOccurrence: currentLegislativeTextOccurrence, setCurrentNewLegislativeTextOccurrence: setCurrentLegislativeTextOccurrence} = useLegislativeTextOccurrenceStore();
    return (
        <Page title="Gire environment favorable: Creation occurrences d'état d\'application des textes du cadre cadre législatif et reglementaire eau">
            <Container  maxWidth={'xl'}>
                <LegislativeTextOccurrenceHeaderBreadcrumbs
                    heading="Creation de nouvelle occurrences d'état d\'application des textes du cadre cadre législatif et reglementaire eau"
                    lastName="Nouvelle occurrence d'état d\'application des textes du cadre cadre législatif et reglementaire eau"
                />
                <LegislativeTextOccurrenceForm {...{currentLegislativeTextOccurrence, setCurrentLegislativeTextOccurrence}}/>
            </Container>
        </Page>
    );
}
