import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const ImpactList = Loadable(lazy(() => import("./pages/ImpactList")));
const ImpactCreate = Loadable(lazy(() => import("./pages/ImpactCreate")));
const ImpactEdit = Loadable(lazy(() => import("./pages/ImpactEdit")));
const ImpactDisplay = Loadable(lazy(() => import("./pages/ImpactDisplay")));

const impactRouter = [
  { path: "impacts", element: <ImpactList /> },
  { path: "impacts/:id", element: <ImpactDisplay /> },
  { path: "impacts/new", element: <ImpactCreate /> },
  { path: "impacts/:id/edit", element: <ImpactEdit /> },
];

export default impactRouter;
