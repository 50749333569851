import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const WaterQualityObservationStationsList = Loadable(
  lazy(() => import("./pages/WaterQualityObservationStationsList"))
);
const WaterQualityObservationStationsCreate = Loadable(
  lazy(() => import("./pages/WaterQualityObservationStationsCreate"))
);
const WaterQualityObservationStationsEdit = Loadable(
  lazy(() => import("./pages/WaterQualityObservationStationsEdit"))
);
const WaterQualityObservationStationsDisplay = Loadable(
  lazy(() => import("./pages/WaterQualityObservationStationsDisplay"))
);

const waterQualityObservationStationsRouter = [
  {
    path: "water-quality-observation-stations",
    element: <WaterQualityObservationStationsList />,
  },
  {
    path: "water-quality-observation-stations/:id",
    element: <WaterQualityObservationStationsDisplay />,
  },
  {
    path: "water-quality-observation-stations/new",
    element: <WaterQualityObservationStationsCreate />,
  },
  {
    path: "water-quality-observation-stations/:id/edit",
    element: <WaterQualityObservationStationsEdit />,
  },
];

export default waterQualityObservationStationsRouter;
