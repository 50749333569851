import React from 'react';
import {Box, Typography, styled} from '@mui/material';

import SearchModule from './SearchModule';

const RootStyle = styled(Box)(({theme}) => ({
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(3),
}))

function SearchSection({section, onSelectItem}) {
    const {subheader, items: modules} = section;
    return (
        <RootStyle>
            <Typography fontWeight={600} sx={{textTransform: 'capitalize', mb: .5}}>
                {subheader}
            </Typography>

            {modules.map(module => (
                <SearchModule key={module.path} module={module} onSelectItem={onSelectItem}/>
            ))}

        </RootStyle>
    );
}

export default SearchSection;