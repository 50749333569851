import React, {useEffect, useMemo, useState} from "react";
import {Link as RouterLink, useNavigate, useParams} from "react-router-dom";
import {CSVLink} from "react-csv";
// material
import {Download, Info, PlayCircle} from "@mui/icons-material";
import {
    Box,
    Checkbox,
    CircularProgress,
    Container,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,

    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import {DesktopDatePicker} from "@mui/lab";
// components
import Page from "../../../../../shared/components/Page";
import {ReportingHeaderBreadcrumbs} from "../components";
import {PATH_REPORTING} from "../../../reporting.path";
import useReportingService from "../services/reporting.services";
import {useMessage} from "../../../../../shared/hooks/useMessage";
import {useReporting} from "../services/reporting.gql";
import {useSearchReportingInputsByReporting} from "../../reporting_input/services/reporting.input.gql";
import {ReportingValueType} from "../../reporting.enum";
import {useSearchReportingColumnsByReporting} from "../../reporting_column/services/reporting.column.gql";
import {useSearchReportingGroupingsByReporting} from "../../reporting_grouping/services/reporting.grouping.gql";
import {ActionsButton} from "../../../../../shared/components/DefaultActionsButton";

// ---------------------------------------------------------------------------------------------------------------------

export default function ReportingExecutionResult() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {showError} = useMessage();
    const {execute} = useReportingService();

    const [requestInput, setRequestInput] = useState({});
    const [reportingResult, setReportingResult] = useState(null);
    const [reportingCsvHeaders, setReportingCsvHeaders] = useState(null);
    const [reportingCsvData, setReportingCsvData] = useState(null);

    const {loading, entity: currentReporting, error, errorMessage} = useReporting(id);

    const {reportingInputs, loading: loadingInputs} =
        useSearchReportingInputsByReporting({reporting: id});
    const {reportingColumns, loading: loadingColumns} =
        useSearchReportingColumnsByReporting({reporting: id});
    const {reportingGroupings, loading: loadingGroupings} =
        useSearchReportingGroupingsByReporting({reporting: id});

    const reportingInputsToShow = useMemo(
        () => (loadingInputs || !reportingInputs) ? [] : reportingInputs.filter(({inputAuto}) => !inputAuto),
        [loadingInputs, reportingInputs]
    );

    useEffect(() => {
        if (loading) return;
        if (error) {
            showError(errorMessage);
            navigate(PATH_REPORTING.reporting);
        }
    }, [error, loading, errorMessage]);

    useEffect(() => {
        if (!currentReporting || !reportingInputs) return;

        const _requestInput = {};
        for (const reportingInput of reportingInputs) {
            let {id, valueType, inputAuto, inputAutoValue} = reportingInput;

            if (inputAuto) {
                _requestInput[id] = {input: id, inputAuto, inputAutoValue};
                continue;
            }
            const enumValueType = ReportingValueType.valueOf(valueType);
            if (!(enumValueType && enumValueType.date)) continue;

            const value = new Date();
            _requestInput[id] = {value, input: id, [enumValueType.field]: value};
        }

        setRequestInput((requestInputPrev) => ({
            ...requestInputPrev,
            ..._requestInput,
        }));
    }, [reportingInputs, reportingInputsToShow, currentReporting]);

    useEffect(() => {
        if (!currentReporting || loadingInputs) return;

        console.log({reportingInputs, reportingInputsToShow, requestInput, requestInputKeys: Object.keys(requestInput).length});

        if ((reportingInputs.length === 0) || (reportingInputsToShow.length === 0 && Object.keys(requestInput).length > 0)) {
            handleExecute().then(console.log);
        }

    }, [currentReporting, loadingInputs, reportingInputs, reportingInputsToShow, requestInput]);

    useEffect(() => {
        if (!reportingColumns) return;

        const reportingCsvHeaders = reportingColumns.map(
            ({columnName: key, label}) => ({label, key})
        );
        setReportingCsvHeaders(reportingCsvHeaders);

    }, [reportingColumns]);

    useEffect(() => {
        if (!reportingResult) return;

        setReportingCsvData(reportingResult.lines);
    }, [reportingResult]);

    const handleExecute = async () => {
        try {
            const inputs = reportingInputs.map((reportingInput) => {
                const {value, valueDate, inputAuto, inputAutoValue, ...ri} =
                requestInput[reportingInput.id] ?? {};

                if (inputAuto) return {inputAuto, ...ri};

                if (!value) throw `Le champ ${reportingInput.label} est nul`;

                if (valueDate) return {...ri, valueDate: valueDate.getTime()};
                return {...ri};
            });

            const {data} = await execute({
                reporting: currentReporting.id,
                inputs,
            });

            setReportingResult(data);
        } catch (e) {
            console.log(e);
            if (typeof e === "string") {
                showError(`${e}`);
            } else {
                showError(`Erreur lors de l'operation`);
            }
        }
    };

    // const handleExport = async () => {
    //     console.log('Export');
    // }

    return (
        <Page title={`Reporting: resultat execution`}>
            <Container maxWidth={"xl"}>
                <ReportingHeaderBreadcrumbs
                    heading={`Reporting`}
                    lastName={`${currentReporting && `${currentReporting.label}`}`}
                    action={<ActionsButton
                        items={[
                            {
                                icon: <Info/>,
                                label: "Afficher",
                                component: RouterLink,
                                to: `${PATH_REPORTING.reporting}/${id}`,
                            },
                            {divider: true},
                            {
                                label: "Executer",
                                icon: <PlayCircle/>,
                                onClick: handleExecute
                            },
                        ]}
                    />}
                />

                {loading && <CircularProgress/>}
                {!loading && currentReporting && (
                    <>
                        <Paper sx={{flexGrow: 1, mb: 3}} variant="outlined">
                            <Box
                                sx={{
                                    display: "flex",
                                    px: 2,
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Typography>
                                    {reportingInputsToShow?.length > 0
                                        ? `Les champs Input`
                                        : `${currentReporting.label}`}
                                </Typography>

                                <Box>
                                    <IconButton
                                        onClick={() => {
                                            navigate(`${PATH_REPORTING.reporting}/${id}`);
                                        }}
                                    >
                                        <Tooltip title="Affciher">
                                            <Info/>
                                        </Tooltip>
                                    </IconButton>
                                    <IconButton onClick={handleExecute}>
                                        <Tooltip title="Executer">
                                            <PlayCircle/>
                                        </Tooltip>
                                    </IconButton>
                                    {/*<IconButton onClick={handleExport}>*/}
                                    {/*    <Tooltip title='Exporter'>*/}
                                    {/*        <Download/>*/}
                                    {/*    </Tooltip>*/}
                                    {/*</IconButton>*/}
                                    {reportingCsvHeaders && reportingCsvData && (
                                        <CSVLink
                                            separator={";"}
                                            filename={`${
                                                currentReporting.label
                                                    ?.toLowerCase()
                                                    .replaceAll(" ", "_") ?? "file"
                                            }.csv`}
                                            className={`MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-admp4s-MuiButtonBase-root-MuiIconButton-root`}
                                            headers={reportingCsvHeaders}
                                            data={reportingCsvData}
                                        >
                                            <Tooltip title="Exporter">
                                                <Download/>
                                            </Tooltip>
                                        </CSVLink>
                                    )}
                                    {/*{reportingCsvHeaders && reportingCsvData && <CSVDownloader*/}
                                    {/*    bom={true}*/}
                                    {/*    filename={`${currentReporting.label?.toLowerCase().replaceAll(' ', '_') ?? 'file'}`}*/}
                                    {/*    className={`MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-admp4s-MuiButtonBase-root-MuiIconButton-root`}*/}
                                    {/*    headers={reportingCsvHeaders}*/}
                                    {/*    data={reportingCsvData}*/}
                                    {/*>*/}
                                    {/*    <Tooltip title='Exporter 2'>*/}
                                    {/*        <Download/>*/}
                                    {/*    </Tooltip>*/}
                                    {/*</CSVDownloader>}*/}
                                </Box>
                            </Box>

                            {reportingInputsToShow.length > 0 && (
                                <>
                                    <Divider/>

                                    <Grid container spacing={2} sx={{p: 2}}>
                                        {reportingInputsToShow.map((reportingInput) => {
                                            return (
                                                <ReportingInputField
                                                    key={reportingInput.id}
                                                    requestInput={requestInput}
                                                    reportingInput={reportingInput}
                                                    setRequestInput={setRequestInput}
                                                />
                                            );
                                        })}
                                    </Grid>
                                </>
                            )}
                        </Paper>

                        <Paper sx={{flexGrow: 1, mb: 3}} variant="outlined">
                            <TableContainer sx={{minWidth: 800}}>
                                <ReportingResultTable
                                    columns={reportingColumns}
                                    lines={reportingResult?.lines}
                                />
                            </TableContainer>
                        </Paper>

                        {reportingResult && reportingGroupings?.length > 0 && (
                            <>
                                {reportingGroupings.map(({id}, index) => {
                                    const {label, valueType, ...value} =
                                        reportingResult.resultGroupings[id];

                                    const enumValueType = ReportingValueType.valueOf(valueType);
                                    if (!enumValueType) return <></>;

                                    return (
                                        <Typography key={index} variant="h6" align="right">
                                            {label}
                                            <Box component="span" sx={{color: "text.disabled"}}>
                                                {` : ${value[enumValueType.field]}`}
                                            </Box>
                                        </Typography>
                                    );
                                })}
                            </>
                        )}
                    </>
                )}
            </Container>
        </Page>
    );
}

function ReportingInputField({reportingInput, requestInput, setRequestInput}) {
    let {label, id, valueType, realValueType} = reportingInput;
    let {value} = requestInput[id] ?? {value: ''};

    let enumValueType = ReportingValueType.valueOf(valueType);
    if (!enumValueType) return <></>;

    valueType = enumValueType.list ? realValueType : valueType;

    // valueType = 'VALUE_INTEGER';

    enumValueType = ReportingValueType.valueOf(valueType);
    if (!enumValueType) return <></>;

    let inputField;

    if (
        enumValueType === ReportingValueType.VALUE_STRING ||
        enumValueType === ReportingValueType.VALUE_INTEGER ||
        enumValueType === ReportingValueType.VALUE_REAL
    ) {
        inputField = (
            <TextField
                fullWidth
                label={label}
                value={value}
                onChange={(e) => {
                    // const rawValue = e.target.value;
                    // let parsedValue = rawValue;
                    // if (enumValueType === ReportingValueType.VALUE_INTEGER) {
                    //     parsedValue = rawValue.trim().length === 0 ? '' : parseInt(rawValue);
                    // } else if(enumValueType === ReportingValueType.VALUE_REAL) {
                    //     parsedValue = rawValue.trim().length === 0 ? '' : parseFloat(rawValue);
                    // }
                    // setRequestInput({...requestInput, [pattern]: parsedValue});
                    setRequestInput({
                        ...requestInput,
                        [id]: {
                            input: id,
                            value: e.target.value,
                            [enumValueType.field]: e.target.value,
                        },
                    });
                }}
            />
        );
    } else if (enumValueType === ReportingValueType.VALUE_DATE) {
        inputField = (
            <DesktopDatePicker
                fullWidth
                label={label}
                value={value}
                onChange={(newValue) => {
                    setRequestInput({
                        ...requestInput,
                        [id]: {
                            input: id,
                            value: newValue,
                            [enumValueType.field]: newValue,
                        },
                    });
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
            />
        );
    } else if (enumValueType === ReportingValueType.VALUE_BOOLEAN) {
        inputField = (
            <FormControlLabel
                fullWidth
                sx={{mb: 2}}
                label={label}
                control={
                    <Checkbox
                        fullWidth
                        checked={value}
                        onChange={(e) => {

                            setRequestInput({
                                ...requestInput,
                                [id]: {
                                    input: id,
                                    value: e.target.checked,
                                    [enumValueType.field]: e.target.checked,
                                },
                            });
                        }}
                    />
                }
            />
        );
    } else {
        return <></>;
    }

    return (
        <Grid item xs={12} md={6} lg={4}>
            {inputField}
        </Grid>
    );
}

export function ReportingResultTable({columns, lines}) {
    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {columns?.map((column) => {
                        const {id, label} = column;
                        return (
                            <TableCell key={id} align="left">
                                {label}
                            </TableCell>
                        );
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
                {lines?.map((line, index) => {
                    return <Row key={index} line={line} columns={columns}/>;
                })}
            </TableBody>
        </Table>
    );
}

function Row({line, columns}) {
    return (
        <TableRow hover sx={{"&:last-child td, &:last-child th": {border: 0}}}>
            {columns?.map(({columnName}) => {
                return (
                    <TableCell key={columnName} component="th" scope="row">
                        <Typography variant="body2" noWrap>
                            {line[columnName]}
                        </Typography>
                    </TableCell>
                );
            })}
        </TableRow>
    );
}
