import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {Form, FormikProvider, useFormik} from 'formik';
import {useMessage} from '../../../../shared/hooks/useMessage';
// material
import {LoadingButton} from '@mui/lab';
import {Card, Stack, Box, Button} from '@mui/material';
// routes
import {setRefresh} from '../services/instrument.policy.occurrence.gql';
import useInstrumentPolicyOccurrenceService from '../services/instrument.policy.occurrence.services';
import {formErrorMessageParser} from '../../../../utils/utils';
import {FormikAutocomplete, FormikTextField, FormRowStack} from '../../../../shared/components/froms/FormComponents';
import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {
    InitialInstrumentPolicyOccurrence,
    InstrumentPolicyOccurrenceSchema
} from '../services/instrument.policy.occurrence.store';
import {useConnectFormState} from '../../../../shared/hooks/useFormikState';

import {useSearchRiskManagementToolApplicationDegrees} from "../../../risk_protection/risk_management_tool_application_degree/services/risk.management.tool.application.degree.gql";
import {RiskManagementToolApplicationDegreeCreateDialogMenuItem} from "../../../risk_protection/risk_management_tool_application_degree/components";

import {useSearchDataRefYears} from "../../../administrative_division/data_ref_year/services/data.ref.year.gql";
import {DataRefYearCreateDialogMenuItem} from "../../../administrative_division/data_ref_year/components";

import {useSearchInstrumentPolicies} from "../../../gire_env_favorable/instrument_policy/services/instrument.policy.gql";
import {InstrumentPolicyCreateDialogMenuItem} from "../../../gire_env_favorable/instrument_policy/components";

InstrumentPolicyOccurrenceForm.propTypes = {
    isEdit: PropTypes.bool,
    currentInstrumentPolicyOccurrence: PropTypes.object,
    fromDialog: PropTypes.object,
};

export default function InstrumentPolicyOccurrenceForm({
                                                           isEdit,
                                                           currentInstrumentPolicyOccurrence,
                                                           setCurrentInstrumentPolicyOccurrence,
                                                           fromDialog
                                                       }) {
    const navigate = useNavigate();
    const {create, update} = useInstrumentPolicyOccurrenceService();
    const {showSuccess, showError} = useMessage();

    const initRiskManagementToolApplicationDegreeQuery = currentInstrumentPolicyOccurrence?.riskManagementToolApplicationDegreeObj?.name ?? fromDialog?.riskManagementToolApplicationDegree?.name ?? '';
    const [riskManagementToolApplicationDegreeQuery, setRiskManagementToolApplicationDegreeQuery] = useState(initRiskManagementToolApplicationDegreeQuery);
    const {
        riskManagementToolApplicationDegrees,
        riskManagementToolApplicationDegreeLoading
    } = useSearchRiskManagementToolApplicationDegrees({riskManagementToolApplicationDegreeQuery});

    const initDataRefYearQuery = currentInstrumentPolicyOccurrence?.dataRefYearObj?.name ?? fromDialog?.dataRefYear?.name ?? '';
    const [dataRefYearQuery, setDataRefYearQuery] = useState(initDataRefYearQuery);
    const {dataRefYears, dataRefYearLoading} = useSearchDataRefYears({dataRefYearQuery});

    const initInstrumentPolicyQuery = currentInstrumentPolicyOccurrence?.instrumentPolicyObj?.name ?? fromDialog?.instrumentPolicy?.name ?? '';
    const [instrumentPolicyQuery, setInstrumentPolicyQuery] = useState(initInstrumentPolicyQuery);
    const {instrumentPolicies, instrumentPolicyLoading} = useSearchInstrumentPolicies({instrumentPolicyQuery});

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: InitialInstrumentPolicyOccurrence(),
        validationSchema: InstrumentPolicyOccurrenceSchema,
        onSubmit: async (instrumentPolicyOccurrence, {setSubmitting}) => {
            try {
                instrumentPolicyOccurrence = InstrumentPolicyOccurrenceSchema.cast(instrumentPolicyOccurrence);

                let message;
                if (!isEdit) {
                    instrumentPolicyOccurrence = await create(instrumentPolicyOccurrence);
                    message = 'Creation avec succès';
                } else {
                    instrumentPolicyOccurrence = await update(currentInstrumentPolicyOccurrence.id, instrumentPolicyOccurrence);
                    message = 'Modification avec succès';
                }

                setRefresh(true);

                setCurrentInstrumentPolicyOccurrence();

                setSubmitting(false);
                showSuccess(message);

                if (fromDialog) {
                    fromDialog.onSave(instrumentPolicyOccurrence);
                } else {
                    navigate(`${PATH_GIRE_ENV_FAVORABLE.instrumentPolicyOccurrences}/${instrumentPolicyOccurrence.id}`);
                }
            } catch (error) {
                setSubmitting(false);
                showError(formErrorMessageParser({error, isEdit}));
            }
        }
    });

    const {values, setValues, handleSubmit, isSubmitting, setFieldValue} = formik;

    useConnectFormState({
        formValues: values, setFormValues: setValues,
        stateValues: currentInstrumentPolicyOccurrence, setStateValues: setCurrentInstrumentPolicyOccurrence,
    });

    const onCancel = (_) => {
        setCurrentInstrumentPolicyOccurrence();
        if (fromDialog) {
            fromDialog.onCancel();
        } else {
            navigate(PATH_GIRE_ENV_FAVORABLE.instrumentPolicyOccurrences);
        }
    }

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete='off' onSubmit={handleSubmit}>

                <Card sx={{p: 3}}>

                    <Stack spacing={3}>

                        <FormRowStack>
                            <FormikTextField label='Nom' name='name' formik={formik}/>
                        </FormRowStack>

                        <Box flexGrow={1} display='flex' flexDirection='row'>
                            <FormikAutocomplete name='riskManagementToolApplicationDegree'
                                                label="Dégree d'application"
                                                options={riskManagementToolApplicationDegrees}
                                                labelField='name'
                                                embeddedObjField='riskManagementToolApplicationDegreeObj'
                                                {...{
                                                    riskManagementToolApplicationDegreeQuery,
                                                    formik,
                                                    riskManagementToolApplicationDegreeLoading,
                                                    setRiskManagementToolApplicationDegreeQuery
                                                }}
                            />
                            <Box sx={{py: 1, ml: 1}}>
                                <RiskManagementToolApplicationDegreeCreateDialogMenuItem btn
                                                                                         onSave={(createdRiskManagementToolApplicationDegree) => {
                                                                                             currentInstrumentPolicyOccurrence.riskManagementToolApplicationDegreeObj = createdRiskManagementToolApplicationDegree;
                                                                                             setRiskManagementToolApplicationDegreeQuery(createdRiskManagementToolApplicationDegree?.name ?? '');
                                                                                             setFieldValue('riskManagementToolApplicationDegree', createdRiskManagementToolApplicationDegree?.id);
                                                                                         }}/>
                            </Box>
                        </Box>

                        <FormRowStack>
                            <Box flexGrow={1} display='flex' flexDirection='row'>
                                <FormikAutocomplete name='dataRefYear'
                                                    label="L'année de reference de donnée"
                                                    options={dataRefYears}
                                                    labelField='name'
                                                    embeddedObjField='dataRefYearObj'
                                                    {...{
                                                        dataRefYearQuery,
                                                        formik,
                                                        dataRefYearLoading,
                                                        setDataRefYearQuery
                                                    }}
                                />
                                <Box sx={{py: 1, ml: 1}}>
                                    <DataRefYearCreateDialogMenuItem btn onSave={(createdDataRefYear) => {
                                        currentInstrumentPolicyOccurrence.dataRefYearObj = createdDataRefYear;
                                        setDataRefYearQuery(createdDataRefYear?.name ?? '');
                                        setFieldValue('dataRefYear', createdDataRefYear?.id);
                                    }}/>
                                </Box>
                            </Box>
                            <Box flexGrow={1} display='flex' flexDirection='row'>
                                <FormikAutocomplete name='instrumentPolicy'
                                                    label="Instruments de politique et stratégie eau"
                                                    options={instrumentPolicies}
                                                    labelField='name'
                                                    embeddedObjField='instrumentPolicyObj'
                                                    {...{
                                                        instrumentPolicyQuery,
                                                        formik,
                                                        instrumentPolicyLoading,
                                                        setInstrumentPolicyQuery
                                                    }}
                                />
                                <Box sx={{py: 1, ml: 1}}>
                                    <DataRefYearCreateDialogMenuItem btn onSave={(createdInstrumentPolicy) => {
                                        currentInstrumentPolicyOccurrence.instrumentPolicyObj = createdInstrumentPolicy;
                                        setDataRefYearQuery(createdInstrumentPolicy?.name ?? '');
                                        setFieldValue('instrumentPolicy', createdInstrumentPolicy?.id);
                                    }}/>
                                </Box>
                            </Box>
                        </FormRowStack>

                        <FormRowStack>
                            <FormikTextField label='Justification' name='justification' formik={formik}/>
                            <FormikTextField label='Source de données' name='dataSource' formik={formik}/>
                        </FormRowStack>

                        <Box sx={{mt: 3, display: 'flex', justifyContent: 'flex-end'}}>
                            <Button
                                sx={{mr: 2}}
                                size='medium'
                                onClick={onCancel}
                                disabled={isSubmitting}>
                                {'Annuler'}
                            </Button>
                            <LoadingButton type='submit' variant='contained' size='medium' loading={isSubmitting}>
                                {!isEdit ? 'Créer' : 'Modifier'}
                            </LoadingButton>
                        </Box>
                    </Stack>

                </Card>

            </Form>
        </FormikProvider>
    );
}
