import React from 'react';
// material
import {Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {RiskTypeForm} from '../components';

import {RiskTypeHeaderBreadcrumbs} from '../components';
import {useRiskTypeStore} from "../services/risk.type.store";

// ----------------------------------------------------------------------

export default function RiskTypeCreate() {
    const {currentNewRiskType: currentRiskType, setCurrentNewRiskType: setCurrentRiskType} = useRiskTypeStore();
    return (
        <Page title="Risque et protection en RE: Création type de risque">
            <Container  maxWidth={'xl'}>
                <RiskTypeHeaderBreadcrumbs
                    heading='Création de nouveau type de risque'
                    lastName='Nouveau type de risques liés aux RE'
                />
                <RiskTypeForm {...{currentRiskType, setCurrentRiskType}}/>
            </Container>
        </Page>
    );
}
