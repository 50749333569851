import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Add} from '@mui/icons-material';
import { Card, Button, Container } from '@mui/material';
import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {Page} from '../../../../shared/components';
import {InstrumentPolicyTypeTable, InstrumentPolicyTypeListHeaderBreadcrumbs} from '../components';

export default function InstrumentPolicyTypeList() {
    return (
        <Page title="Type d'instruments de politique et stratégie: Liste des types AHP">
            <Container maxWidth={'xl'}>
                <InstrumentPolicyTypeListHeaderBreadcrumbs
                    heading="Type d'instruments de politique et stratégie"
                    lastName="Liste des d'instruments de politique et stratégie"
                    action={
                        <Button
                            variant="contained"
                            component={RouterLink}
                            to={PATH_GIRE_ENV_FAVORABLE.instrumentPolicyTypes + '/new'}
                            startIcon={<Add/>}>
                            Nouveau
                        </Button>
                    }
                />

                <Card>
                    <InstrumentPolicyTypeTable/>
                </Card>
            </Container>
        </Page>
    );
}
