import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {Form, FormikProvider, useFormik} from 'formik';
import {useMessage} from '../../../../shared/hooks/useMessage';
// material
import {Box, Card, Stack} from '@mui/material';
// routes
import {PATH_ADMINISTRATIVE_DIVISION} from '../../administrative_division.path';
import {
    FormikAutocomplete,
    FormikSwitch,
    FormikTextField,
    FormRowStack,
    SubmitCancelGroupButton,
    SubSectionTitle
} from '../../../../shared/components/froms/FormComponents';
import {setRefresh} from '../services/locality.gql';
import useLocalityService from '../services/locality.services';
import {useSearchCommunes} from '../../commune/services/commune.gql';
import {useSearchLocalityTypes} from "../../locality_type/services/locality_type.gql";
import {LocalityTypeCreateDialogMenuItem} from "../../locality_type/components";
import {CommuneCreateDialogMenuItem} from "../../commune/components";
import {useSearchVillageStatus} from "../../village_status/services/village_status.gql";
import {useSearchEnvironment} from "../../environment/services/environment.gql";
import {useSearchOriginPopulations} from "../../origin_population/services/origin_population.gql";
import GeoLocationPicker from "../../../../shared/components/maps/GeoLocationPicker";
import GeoPoint from "../../../../utils/geopoint";
import {InitialLocality, LocalitySchema} from '../services/locality.store';
import {formErrorMessageParser} from '../../../../utils/utils';
import {useConnectFormState} from '../../../../shared/hooks/useFormikState';
// ----------------------------------------------------------------------

LocalityForm.propTypes = {
    isEdit: PropTypes.bool,
    currentLocality: PropTypes.object,
    fromDialog: PropTypes.object,
};

export default function LocalityForm({isEdit, currentLocality, setCurrentLocality, fromDialog}) {
    const navigate = useNavigate();
    const {create, update} = useLocalityService();
    const {showSuccess, showError} = useMessage();

    const initLocalityLocalityQuery = currentLocality?.localityTypeObj?.name ?? fromDialog?.localityType?.name ?? '';
    const [queryLocalityType, setQueryLocalityType] = useState(initLocalityLocalityQuery);
    const {localityTypes, loadingLocalityType} =
        useSearchLocalityTypes({query: queryLocalityType});

    const initOriginPopulationOriginPopulationQuery =
        currentLocality?.originPopulationObj?.name ?? fromDialog?.originPopulationObj?.name ?? '';
    const [queryOriginPopulation, setQueryOriginPopulation] = useState(
        initOriginPopulationOriginPopulationQuery
    );
    const {originPopulations, loadingOriginPopulation} =
        useSearchOriginPopulations({
            query: queryOriginPopulation,
        });

    const initVillageStatusVillageStatusQuery =
        currentLocality?.villageStatusObj?.wording ?? fromDialog?.villageStatusObj?.wording ?? '';
    const [queryVillageStatus, setQueryVillageStatus] = useState(
        initVillageStatusVillageStatusQuery
    );
    const {villageStatus, loadingVillageStatus} =
        useSearchVillageStatus({
            query: queryVillageStatus,
        });

    const initEnvironmentEnvironmentQuery =
        currentLocality?.environmentObj?.wording ?? fromDialog?.environmentObj?.wording ?? '';
    const [queryEnvironment, setQueryEnvironment] = useState(
        initEnvironmentEnvironmentQuery
    );
    const {environment, loadingEnvironment} = useSearchEnvironment({
        query: queryEnvironment,
    });

    //
    // const [querySubBasin, setQuerySubBasin] = useState(initSubBasinSubBasinQuery);
    // const {subBasins, loadingSubBasin} = useSearchSubBasins({
    //     query: querySubBasin,
    // });

    const initCommuneQuery =
        currentLocality?.communeObj?.name ?? fromDialog?.commune?.name ?? "";
    const [queryCommune, setQueryCommune] = useState(initCommuneQuery);
    const {communes, loadingCommune} = useSearchCommunes({
        query: queryCommune,
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: InitialLocality(),
        validationSchema: LocalitySchema,
        onSubmit: async (locality, {setSubmitting}) => {
            try {
                locality = LocalitySchema.cast(locality);

                let message;
                if (!isEdit) {
                    locality = await create(locality);
                    message = 'Création avec succès';
                } else {
                    locality = await update(currentLocality.id, locality);
                    message = 'Modification avec succès';
                }

                setRefresh(true);

                setCurrentLocality();

                setSubmitting(false);
                showSuccess(message);

                if (fromDialog) {
                    fromDialog.onSave(locality);
                } else {
                    navigate(`${PATH_ADMINISTRATIVE_DIVISION.localities}/${locality.id}`);
                }
            } catch (error) {
                setSubmitting(false);
                showError(formErrorMessageParser({error, isEdit}));
            }
        },
    });

    const {values, setValues, handleSubmit, isSubmitting, setFieldValue} = formik;

    useConnectFormState({
        formValues: values, setFormValues: setValues,
        stateValues: currentLocality, setStateValues: setCurrentLocality,
    });

    const onCancel = (_) => {
        setCurrentLocality();
        if (fromDialog) {
            fromDialog.onCancel();
        } else {
            navigate(PATH_ADMINISTRATIVE_DIVISION.localities);
        }
    };

    const handleGeolocation = (geoPoint) => {
        setFieldValue('latitudeDescription', geoPoint.lat);
        setFieldValue('longitudeDescription', geoPoint.lng);
        setFieldValue('latitudeText', geoPoint.latDeg);
        setFieldValue('longitudeText', geoPoint.lngDeg);
    };

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete='off' onSubmit={handleSubmit}>
                <Card sx={{p: 3}}>
                    <Stack spacing={3}>
                        <FormRowStack>
                            <FormikTextField label='Nom' name='name' formik={formik}/>
                            <FormikTextField label='Nom 2' name='name2' formik={formik}/>
                        </FormRowStack>

                        <FormRowStack>
                            <Box flexGrow={1} display='flex' flexDirection='row'>
                                <FormikAutocomplete
                                    name="localityType"
                                    label="Type localité"
                                    options={localityTypes}
                                    // embeddedObjField="localityTypeObj"
                                    {...{queryLocalityType, formik, loadingLocalityType, setQueryLocalityType}}
                                />

                                <Box sx={{py: 1, ml: 1}}>
                                    <LocalityTypeCreateDialogMenuItem btn onSave={(createdLocalityType) => {
                                        currentLocality.localityTypeObj = createdLocalityType;
                                        setQueryLocalityType(createdLocalityType?.name ?? '');
                                        setFieldValue('localityType', createdLocalityType?.id);
                                    }}/>
                                </Box>
                            </Box>

                            <Box flexGrow={1} display='flex' flexDirection='row'>
                                <FormikAutocomplete
                                    name="commune"
                                    label="Commune"
                                    options={communes}
                                    // embeddedObjField="communeObj"
                                    {...{queryCommune, formik, loadingCommune, setQueryCommune}}
                                />
                                <Box sx={{py: 1, ml: 1}}>
                                    <CommuneCreateDialogMenuItem btn onSave={(createdCommune) => {
                                        currentLocality.communeObj = createdCommune;
                                        setQueryCommune(createdCommune?.name ?? '');
                                        setFieldValue('commune', createdCommune?.id);
                                    }}/>
                                </Box>
                            </Box>
                        </FormRowStack>

                        <FormRowStack>
                            <FormikTextField label='Code Agis' name='codagis' formik={formik}/>
                            <FormikTextField label="Numero d'Ordre" name='numOrder' formik={formik}/>
                        </FormRowStack>

                        <FormRowStack>
                            <FormikTextField label="Code Carte" name='codeCarte' formik={formik}/>
                            <FormikTextField label="Code C" name='codeC' formik={formik}/>
                        </FormRowStack>

                        <FormRowStack>
                            <FormikTextField label="Coordonne x" name='xCoordinate' formik={formik}/>
                            <FormikTextField label="Coordonne y" name='yCoordinate' formik={formik}/>
                        </FormRowStack>

                        <FormRowStack>
                            <FormikTextField label="Population" name='population' formik={formik}/>
                            <FormikTextField label="Cheptel année" name='livestockYear' formik={formik}/>
                        </FormRowStack>

                        <FormRowStack>
                            <FormikTextField label="Cheptel" name='livestock' formik={formik}/>
                            <FormikTextField label="Cheptel actuel" name='currentLivestock' formik={formik}/>
                        </FormRowStack>

                        <FormRowStack>
                            <FormikTextField label="Population année" name='populationYear' formik={formik}/>
                            <FormikTextField label="Population actuelle" name='currentPopulation' formik={formik}/>
                            <FormikTextField label="Numéro du ménage" name='householdNumber' formik={formik}/>
                        </FormRowStack>

                        <FormRowStack>
                            <FormikTextField label="Nombre concession" name='concessionNumber' formik={formik}/>
                            <FormikTextField label="Remarque" name='remark' formik={formik}/>
                            <FormikTextField label="Village frontalier" name='borderVillage' formik={formik}/>
                        </FormRowStack>

                        <FormRowStack>
                            <FormikTextField label="Numero plan" name='planNum' formik={formik}/>
                        </FormRowStack>

                        <FormRowStack>
                            <FormikSwitch label='Il y a un marché' name='existMarche' formik={formik}/>
                        </FormRowStack>

                        <SubSectionTitle title='Details'/>

                        <FormRowStack>
                            <FormikAutocomplete
                                name="villageStatus"
                                label="Status Village"
                                labelField='wording'
                                options={villageStatus}
                                // embeddedObjField="villageStatusObj"
                                {...{
                                    queryVillageStatus,
                                    formik,
                                    loadingVillageStatus,
                                    setQueryVillageStatus
                                }}
                            />
                            <FormikAutocomplete
                                name="environment"
                                label="Milieu"
                                labelField='wording'
                                options={environment}
                                // embeddedObjField="environmentObj"
                                {...{
                                    queryEnvironment,
                                    formik,
                                    loadingEnvironment,
                                    setQueryEnvironment
                                }}
                            />
                        </FormRowStack>
                        <FormRowStack>
                            <FormikAutocomplete
                                name="originPopulation"
                                label="Origine population"
                                labelField='wording'
                                options={originPopulations}
                                // embeddedObjField="originPopulationObj"
                                {...{
                                    queryOriginPopulation,
                                    formik,
                                    loadingOriginPopulation,
                                    setQueryOriginPopulation
                                }}
                            />
                        </FormRowStack>

                        <SubSectionTitle title='Geolocalisation' action={(
                            <GeoLocationPicker
                                isIconButton
                                onSelected={handleGeolocation}
                                location={
                                    currentLocality && new GeoPoint({
                                        lat: !currentLocality.latitudeDescription ? 12.634435 : currentLocality.latitudeDescription,
                                        lng: !currentLocality.longitudeDescription ? -8.002168 : currentLocality.longitudeDescription,
                                    })
                                }
                            />
                        )}/>

                        <FormRowStack>
                            <FormikTextField label='Latitude decimal' name='latitudeDescription' formik={formik}/>
                            <FormikTextField label='Latitude degre' name='latitudeText' formik={formik}/>
                        </FormRowStack>

                        <FormRowStack>
                            <FormikTextField label='Latitude decimal' name='longitudeDescription' formik={formik}/>
                            <FormikTextField label='Longitude degre' name='longitudeText' formik={formik}/>
                        </FormRowStack>

                        <FormRowStack>
                            <FormikTextField label='Altitude' name='altitude' formik={formik}/>
                        </FormRowStack>

                        <SubmitCancelGroupButton {...{isEdit, isSubmitting, onCancel}}/>

                    </Stack>
                </Card>
            </Form>
        </FormikProvider>
    );
}
