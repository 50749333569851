import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const AnnularNatureList = Loadable(
  lazy(() => import("./pages/AnnularNatureList"))
);
const AnnularNatureCreate = Loadable(
  lazy(() => import("./pages/AnnularNatureCreate"))
);
const AnnularNatureEdit = Loadable(
  lazy(() => import("./pages/AnnularNatureEdit"))
);
const AnnularNatureDisplay = Loadable(
  lazy(() => import("./pages/AnnularNatureDisplay"))
);

const annularNatureRouter = [
  { path: "annular-natures", element: <AnnularNatureList /> },
  { path: "annular-natures/:id", element: <AnnularNatureDisplay /> },
  { path: "annular-natures/new", element: <AnnularNatureCreate /> },
  { path: "annular-natures/:id/edit", element: <AnnularNatureEdit /> },
];

export default annularNatureRouter;
