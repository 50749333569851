import React from 'react';
import {Box, Link, styled} from "@mui/material";
import {NavLink as RouterLink} from "react-router-dom";

const RootStyle = styled(Box)(({theme}) => ({
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(.25),
}))

const StyledLink = styled(props => (
    <Link underline={'hover'} component={RouterLink} {...props}/>
))(({theme}) => ({
    fontWeight: 300,
    textTransform: 'capitalize',
    marginLeft: theme.spacing(1),
}))

function SearchModuleItem({item, onSelectItem}) {
    const {title, path} = item;

    return (
        <RootStyle>
            <StyledLink to={path} onClick={() => onSelectItem(title)}>
                {title}
            </StyledLink>
        </RootStyle>
    );
}

export default SearchModuleItem;