import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const PumpInfrastructureList = Loadable(
  lazy(() => import("./pages/PumpInfrastructureList"))
);
const PumpInfrastructureCreate = Loadable(
  lazy(() => import("./pages/PumpInfrastructureCreate"))
);
const PumpInfrastructureEdit = Loadable(
  lazy(() => import("./pages/PumpInfrastructureEdit"))
);
const PumpInfrastructureDisplay = Loadable(
  lazy(() => import("./pages/PumpInfrastructureDisplay"))
);

const pumpInfrastructureRouter = [
  { path: "pump-infrastructures", element: <PumpInfrastructureList /> },
  { path: "pump-infrastructures/:id", element: <PumpInfrastructureDisplay /> },
  { path: "pump-infrastructures/new", element: <PumpInfrastructureCreate /> },
  {
    path: "pump-infrastructures/:id/edit",
    element: <PumpInfrastructureEdit />,
  },
];

export default pumpInfrastructureRouter;
