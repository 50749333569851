import React from 'react';
// material
import {Typography} from '@mui/material';
// components
import {CommuneForm} from '../components';
import CreateDialogMenuItem from '../../../../shared/components/CreateDialogMenuItem';

// ----------------------------------------------------------------------

export function CommuneCreateDialogMenuItem({circle, onClose, onSave, onClick, btn}) {
    const setCurrentCommune = () => {};
    const currentCommune = {circle: circle?.id, circleObj: circle};
    return (
        <CreateDialogMenuItem
            btn={btn}
            onClose={onClose}
            onClick={onClick}
            menuText='Ajouter une commune'
            dialogTitle='Création de commune'
            dialogContextText={<>
                Création de nouvelle commune
                {circle && <>
                    {` pour`}<Typography variant='h6' component={'span'}> {circle.name}</Typography>
                </>}
            </>}
            formRender={({onClose}) => {
                return (
                    <CommuneForm
                        {...{currentCommune, setCurrentCommune}}
                        fromDialog={{
                            circle,
                            onCancel: onClose,
                            onSave: (createdCommune) => {
                                onSave && onSave(createdCommune);
                                onClose && onClose();
                            },
                        }}
                    />
                );
            }}
        />
    );

}
