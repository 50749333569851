import React, {useState} from "react";
import PropTypes from "prop-types";
import {useMessage} from "../../../../shared/hooks/useMessage";
import {useNavigate} from "react-router-dom";
import {Form, FormikProvider, useFormik} from "formik";
// material
import {Box, Card, Stack} from "@mui/material";
import {
    FormikAutocomplete,
    FormikTextField,
    FormRowStack,
    SubmitCancelGroupButton
} from "../../../../shared/components/froms/FormComponents";
import {setRefresh} from "../services/cultural.speculation.entity.usages.gql";

import useCulturalSpeculationEntityUsagesService from "../services/cultural.speculation.entity.usages.services";
import {PATH_WATER_USAGE_URIGATION} from "../../water.usage.irrigation.path";
import {
    CulturalSpeculationEntityUsagesSchema,
    InitialCulturalSpeculationEntityUsages,
} from "../services/cultural.speculation.entity.usages.store";
import {useConnectFormState} from "../../../../shared/hooks/useFormikState";
import {useSearchDataRefYears} from "../../../administrative_division/data_ref_year/services/data.ref.year.gql";
import {DataRefYearCreateDialogMenuItem} from "../../../administrative_division/data_ref_year/components";

CulturalSpeculationEntityUsagesForm.propTypes = {
    isEdit: PropTypes.bool,
    currentProduct: PropTypes.object,
    fromDialog: PropTypes.object,
};

export default function CulturalSpeculationEntityUsagesForm({isEdit, currentCulturalSpeculationEntityUsages, setCurrentCulturalSpeculationEntityUsages, fromDialog,}) {
    const navigate = useNavigate();
    const {create, update} = useCulturalSpeculationEntityUsagesService();
    const {showSuccess, showError} = useMessage();


    const initDataRefYearQuery = currentCulturalSpeculationEntityUsages?.dataRefYearObj?.name ?? fromDialog?.dataRefYear?.name ?? '';
    const [dataRefYearQuery, setDataRefYearQuery] = useState(initDataRefYearQuery);
    const {dataRefYears, dataRefYearLoading} = useSearchDataRefYears({dataRefYearQuery});

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: InitialCulturalSpeculationEntityUsages(),
        validationSchema: CulturalSpeculationEntityUsagesSchema,
        onSubmit: async (
            CulturalSpeculationEntityUsages,
            {setSubmitting, resetForm, setErrors}
        ) => {
            try {
                CulturalSpeculationEntityUsages = CulturalSpeculationEntityUsagesSchema.cast(
                    CulturalSpeculationEntityUsages
                );

                let message;
                if (!isEdit) {
                    await create(CulturalSpeculationEntityUsages);
                    message = "Création avec succes";
                } else {
                    await update(
                        currentCulturalSpeculationEntityUsages.id,
                        CulturalSpeculationEntityUsages
                    );
                    message = "Modification avec succes";
                }

                setRefresh(true);

                resetForm();

                setSubmitting(false);
                showSuccess(message);
                if (fromDialog) {
                    fromDialog.onSave(CulturalSpeculationEntityUsages);
                } else {
                    navigate(PATH_WATER_USAGE_URIGATION.culturalSpeculationEntityUsages);
                }
            } catch (error) {
                console.error(error);
                setSubmitting(false);
                showError(
                    !isEdit
                        ? "Erreur lors de la creation"
                        : "Erreur lors de la modification"
                );
            }
        },
    });

    const {
        errors,
        values,
        setValues,
        touched,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        getFieldProps,
    } = formik;

    useConnectFormState({
        formValues: values,
        setFormValues: setValues,
        stateValues: currentCulturalSpeculationEntityUsages,
        setStateValues: setCurrentCulturalSpeculationEntityUsages,
    });

    const onCancel = (_) => {
        setCurrentCulturalSpeculationEntityUsages();
        if (fromDialog) {
            fromDialog.onCancel();
        } else {
            navigate(PATH_WATER_USAGE_URIGATION.culturalSpeculationEntityUsages);
        }
    };

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Card sx={{p: 3}}>
                    <Stack spacing={3}>
                        <FormikTextField label="Nom" name="name" formik={formik}/>

                        <Box flexGrow={1} display='flex' flexDirection='row'>
                            <FormikAutocomplete
                                name='dataRefYear'
                                label="Année de reférence des données"
                                options={dataRefYears}
                                labelField='name'
                                embeddedObjField='dataRefYearObj'
                                {...{dataRefYearQuery, formik, dataRefYearLoading, setDataRefYearQuery}}
                            />
                            <Box sx={{py: 1, ml: 1}}>
                                <DataRefYearCreateDialogMenuItem btn onSave={(createdDataRefYear) => {
                                    currentCulturalSpeculationEntityUsages.dataRefYearObj = createdDataRefYear;
                                    setDataRefYearQuery(createdDataRefYear?.name ?? '');
                                    setFieldValue('dataRefYear', createdDataRefYear?.id);
                                }}/>
                            </Box>
                        </Box>

                        <FormRowStack>
                            <FormikTextField label="Code" name="code" formik={formik}/>
                            <FormikTextField label="Prix moyen annuel" name="averageAnnualCost" formik={formik}/>
                        </FormRowStack>

                        <FormRowStack flexGrow={1} display="flex" flexDirection="row">
                            <FormikTextField
                                label="Source de donnée"
                                name="dataSource"
                                formik={formik}
                            />
                            <FormikTextField
                                 multiline minRows={2} maxRows={6}  label="Description"
                                name="description"
                                formik={formik}
                            />
                        </FormRowStack>
                        <SubmitCancelGroupButton {...{isEdit, isSubmitting, onCancel}} />
                    </Stack>
                </Card>
            </Form>
        </FormikProvider>
    );
}
