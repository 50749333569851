import React from 'react';
// material
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
// utils
import {PATH_RISK_PROTECTION} from '../../risk.protection.path';
import {useCountRiskManagementToolApplicationDegrees} from '../services/risk.management.tool.application.degree.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';

// ----------------------------------------------------------------------

export default function RiskManagementToolApplicationDegreeEntryButton() {
    const {count} = useCountRiskManagementToolApplicationDegrees();
  return (
      <SubModuleEntryButton
          label="Degré d'application des outils de gestion de risques liés à l'eau"
          value={count}
          url={PATH_RISK_PROTECTION.riskManagementToolApplicationDegrees}
          icon={<Rotate90DegreesCwIcon />}
      />
  );
}
