import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const MassiveConstructionList = Loadable(lazy(() => import('./pages/MassiveConstructionList')));
const MassiveConstructionCreate = Loadable(lazy(() => import('./pages/MassiveConstructionCreate')));
const MassiveConstructionEdit = Loadable(lazy(() => import('./pages/MassiveConstructionEdit')));
const MassiveConstructionDisplay = Loadable(lazy(() => import('./pages/MassiveConstructionDisplay')));

const massiveConstructionRouter = [
    {path: 'massive-constructions', element: <MassiveConstructionList/>},
    {path: 'massive-constructions/:id', element: <MassiveConstructionDisplay/>},
    {path: 'massive-constructions/new', element: <MassiveConstructionCreate/>},
    {path: 'massive-constructions/:id/edit', element: <MassiveConstructionEdit/>},
];

export default massiveConstructionRouter;
