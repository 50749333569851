import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const TypeSpaceList = Loadable(lazy(() => import('./pages/TypeSpaceList')));
const TypeSpaceCreate = Loadable(lazy(() => import('./pages/TypeSpaceCreate')));
const TypeSpaceEdit = Loadable(lazy(() => import('./pages/TypeSpaceEdit')));
const TypeSpaceDisplay = Loadable(lazy(() => import('./pages/TypeSpaceDisplay')));

const typeSpaceRouter = [
    {path: 'type-spaces', element: <TypeSpaceList/>},
    {path: 'type-spaces/:id', element: <TypeSpaceDisplay/>},
    {path: 'type-spaces/new', element: <TypeSpaceCreate/>},
    {path: 'type-spaces/:id/edit', element: <TypeSpaceEdit/>},
];

export default typeSpaceRouter;
