import {useApiHttp} from '../../../../shared/hooks/useHttp';

const useInstrumentMechanismParticipationTypeService = () => {
    const {crud} = useApiHttp();

    const {
        create,
        update,
        destroy,
        findById,
        findAll,
    } = crud('instrument-mechanism-participation-types');

    return {
        create,
        update,
        destroy,
        findById,
        findAll,
    }
}

export default useInstrumentMechanismParticipationTypeService;
