import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Add} from '@mui/icons-material';
import { Card, Button, Container } from '@mui/material';
import {PATH_FISHING} from '../../module.fishing.path';
import {Page} from '../../../../shared/components';
import {AhpTypeTable, AhpTypeListHeaderBreadcrumbs} from '../components';

export default function AhpTypeList() {
    return (
        <Page title="Type AHP: Liste des types AHP">
            <Container maxWidth={'xl'}>
                <AhpTypeListHeaderBreadcrumbs
                    heading='Types AHP'
                    lastName='Liste des types AHP'
                    action={
                        <Button
                            variant="contained"
                            component={RouterLink}
                            to={PATH_FISHING.ahpType + '/new'}
                            startIcon={<Add/>}>
                            Nouveau
                        </Button>
                    }
                />

                <Card>
                    <AhpTypeTable/>
                </Card>
            </Container>
        </Page>
    );
}
