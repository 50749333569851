import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';

import MainLayout from '../../home/AppLayout';
import Loadable from '../../shared/components/Loadable';

import {PATH_GIRE_FINANCING} from './gire.financing.path';
import budgetExpenditureAreaRouter from "./budget_expenditure_area/budget.expenditure.area.router";
import budgetNatureRouter from "./budget_nature/budget.nature.router";
import budgetProgramsRouter from "./budget_programs/budget.programs.router";
import budgetTypeRouter from "./budget_type/budget.type.router";
import sphereGireRouter
    from "./sphere_gire/sphere.gire.router";
import gireOrganEntitiesRouter from "./gire_organ_entities/gire.organ.entities.router";
import gireManagementToolRouter from "./gire_management_tool/gire.management.tool.router";
import occurrencesOfCompositionAndFunctionalityOfGireOrganOfficesRouter
    from "./occurrences_of_composition_and_functionality_of_gire_organ_offices/occurrences.of.composition.and.functionality.of.gire.organ.offices.router";
//Features

const GireFinancingHome = Loadable(lazy(() => import('./GireFinancingHome')));

const gireFinancingRouter = [{
    path: PATH_GIRE_FINANCING.root,
    element: <MainLayout/>,
    children: [
        {path: PATH_GIRE_FINANCING.root, element: <Navigate to={PATH_GIRE_FINANCING.home} replace/>},
        {path: 'home', element: <GireFinancingHome/>},
        ...budgetExpenditureAreaRouter,
        ...budgetNatureRouter,
        ...budgetProgramsRouter,
        ...budgetTypeRouter,
        ...sphereGireRouter,
        ...gireOrganEntitiesRouter,
        ...gireManagementToolRouter,
        ...occurrencesOfCompositionAndFunctionalityOfGireOrganOfficesRouter,
    ]
},];

export default gireFinancingRouter;
