import React from 'react';
// material
import {TableChart} from '@mui/icons-material';
// utils
import {PATH_REPORTING} from '../../../reporting.path';
import HomeEntryButton from "../../../../../home/components/HomeEntryButton";
import {useCountMyReporting} from "../services/reporting.profile.gql";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function MyReportingEntryButton() {

    const {count} = useCountMyReporting();

    return (
        <HomeEntryButton
            value={count}
            icon={<TableChart/>}
            label={`Mes Reporting`}
            url={`${PATH_REPORTING.reporting}/my-reporting`}
        />
    );
}
