import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {Form, FormikProvider, useFormik} from 'formik';
import {useMessage} from '../../../../shared/hooks/useMessage';
// material
import {LoadingButton} from '@mui/lab';
import {Box, Button, Card, Stack} from '@mui/material';
// routes
import {setRefresh} from '../services/risk.management.tool.application.degree.gql';
import useRiskManagementToolApplicationDegreeService from '../services/risk.management.tool.application.degree.services';
import {formErrorMessageParser} from '../../../../utils/utils';
import {FormikTextField, FormRowStack} from '../../../../shared/components/froms/FormComponents';
import {PATH_RISK_PROTECTION} from '../../risk.protection.path';
import {
    InitialRiskManagementToolApplicationDegree,
    RiskManagementToolApplicationDegreeSchema
} from '../services/risk.management.tool.application.degree.store';
import {useConnectFormState} from '../../../../shared/hooks/useFormikState';

RiskManagementToolApplicationDegreeForm.propTypes = {
    isEdit: PropTypes.bool,
    currentRiskManagementToolApplicationDegree: PropTypes.object,
    fromDialog: PropTypes.object,
};

export default function RiskManagementToolApplicationDegreeForm({
                                                                    isEdit,
                                                                    currentRiskManagementToolApplicationDegree,
                                                                    setCurrentRiskManagementToolApplicationDegree,
                                                                    fromDialog
                                                                }) {
    const navigate = useNavigate();
    const {create, update} = useRiskManagementToolApplicationDegreeService();
    const {showSuccess, showError} = useMessage();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: InitialRiskManagementToolApplicationDegree(),
        validationSchema: RiskManagementToolApplicationDegreeSchema,
        onSubmit: async (riskManagementToolApplicationDegree, {setSubmitting}) => {
            try {
                riskManagementToolApplicationDegree = RiskManagementToolApplicationDegreeSchema.cast(riskManagementToolApplicationDegree);

                let message;
                if (!isEdit) {
                    riskManagementToolApplicationDegree = await create(riskManagementToolApplicationDegree);
                    message = 'Création avec succès';
                } else {
                    riskManagementToolApplicationDegree = await update(currentRiskManagementToolApplicationDegree.id, riskManagementToolApplicationDegree);
                    message = 'Modification avec succès';
                }

                setRefresh(true);

                setCurrentRiskManagementToolApplicationDegree();

                setSubmitting(false);
                showSuccess(message);

                if (fromDialog) {
                    fromDialog.onSave(riskManagementToolApplicationDegree);
                } else {
                    navigate(`${PATH_RISK_PROTECTION.riskManagementToolApplicationDegrees}/${riskManagementToolApplicationDegree.id}`);
                }
            } catch (error) {
                setSubmitting(false);
                showError(formErrorMessageParser({error, isEdit}));
            }
        }
    });

    const {values, setValues, handleSubmit, isSubmitting} = formik;

    useConnectFormState({
        formValues: values,
        setFormValues: setValues,
        stateValues: currentRiskManagementToolApplicationDegree,
        setStateValues: setCurrentRiskManagementToolApplicationDegree,
    });

    const onCancel = (_) => {
        setCurrentRiskManagementToolApplicationDegree();
        if (fromDialog) {
            fromDialog.onCancel();
        } else {
            navigate(PATH_RISK_PROTECTION.riskManagementToolApplicationDegrees);
        }
    }

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete='off' onSubmit={handleSubmit}>

                <Card sx={{p: 3}}>

                    <Stack spacing={3}>

                        <FormikTextField label='Nom' name='name' formik={formik}/>
                        <FormRowStack>
                            <FormikTextField label='Critère de notation' name='notationCriteria' formik={formik}/>
                            <FormikTextField label='Notation' name='notation' formik={formik}/>
                        </FormRowStack>

                        <FormRowStack>
                            <FormikTextField label='Source de données' name='dataSource' formik={formik}/>
                            <FormikTextField multiline minRows={2} maxRows={6}  label='Description' name='description' formik={formik}/>
                        </FormRowStack>

                        <Box sx={{mt: 3, display: 'flex', justifyContent: 'flex-end'}}>
                            <Button
                                sx={{mr: 2}}
                                size='medium'
                                onClick={onCancel}
                                disabled={isSubmitting}>
                                {'Annuler'}
                            </Button>
                            <LoadingButton type='submit' variant='contained' size='medium' loading={isSubmitting}>
                                {!isEdit ? 'Créer' : 'Modifier'}
                            </LoadingButton>
                        </Box>
                    </Stack>

                </Card>

            </Form>
        </FormikProvider>
    );
}
