import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import { Refresh, Search } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

import { useSearchInstitutionalOrganizationalFrameworkStructures } from "../services/institutional.organizational.framework.structure.gql";
import useInstitutionalOrganizationalFrameworkStructureService from "../services/institutional.organizational.framework.structure.services";
import { useMessage } from "../../../../shared/hooks/useMessage";
import { usePagination } from "../../../../shared/components/tables";
import SortableTableHead from "../../../../shared/components/tables/SortableTableHead";
import MyTablePagination from "../../../../shared/components/tables/MyTablePagination";
import { InstitutionalOrganizationalFrameworkStructureMoreMenu } from "./index";
import AlertDialog, {
  useDeleteDialog,
} from "../../../../shared/components/AlertDialog";
import {PATH_GIRE_ENV_FAVORABLE} from "../../gire.env.favorable.path";

const TABLE_HEAD = [
  { id: "id", label: "ID", alignRight: false },
  { id: "name", label: "Nom", alignRight: false },
  { id: "institutionalOrganizationalFrameworkStructureTypeObj", label: "Type", alignRight: false },
  { id: "itypeSphereObj", label: "Types de sphère d'actions de GIRE", alignRight: false },
  { id: "dataSource", label: "Source de données", alignRight: false },
  { id: "" },
];

export default function InstitutionalOrganizationalFrameworkStructureTable() {
  const theme = useTheme();
  const { showSuccess, showError } = useMessage();
  const { destroy } = useInstitutionalOrganizationalFrameworkStructureService();

  const {
    page,
    order,
    setSelected,
    filterName,
    rowsPerPage,
    orderBy,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    handleFilterByName,
  } = usePagination();

  const { deleteConfirmOpen, setDeleteConfirmOpen, idToDelete, handleDelete } =
    useDeleteDialog();

  const { institutionalOrganizationalFrameworkStructures, refetch, institutionalOrganizationalFrameworkStructurePage } = useSearchInstitutionalOrganizationalFrameworkStructures({
    query: filterName,
    size: rowsPerPage,
    page,
  });

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = institutionalOrganizationalFrameworkStructures.map(({ id }) => id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleDeleteConfirm = () => {
    console.log("####### handleDelete");
    destroy(idToDelete)
      .then((_) => {
        showSuccess(`Structures de cadre institutionnel et organisationnel supprimée avec succès`);
        refetch();
      })
      .catch((error) => {
        showError(`Erreur lors de la suppression de la structures de cadre institutionnel et organisationnel ${error}`);
      });
  };

  return (
    <>
      <Toolbar
        sx={{
          height: 96,
          display: "flex",
          justifyContent: "space-between",
          padding: theme.spacing(0, 1, 0, 3),
        }}
      >
        <OutlinedInput
          sx={{ width: 240 }}
          value={filterName}
          onChange={handleFilterByName}
          placeholder="Recherche structures de cadre institutionnel et organisationnel"
          startAdornment={
            <InputAdornment position="start">
              <Box component={Search} sx={{ color: "text.disabled" }} />
            </InputAdornment>
          }
        />

        <Tooltip title="Actualiser">
          <IconButton onClick={() => refetch()}>
            <Refresh />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table size="small">
          <SortableTableHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={institutionalOrganizationalFrameworkStructures.length}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
          />
          <TableBody>
            {institutionalOrganizationalFrameworkStructures.map((row) => (
              <Row key={row.id} row={row} handleDelete={handleDelete} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <MyTablePagination
        page={institutionalOrganizationalFrameworkStructurePage}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <AlertDialog
        title={"Suppression"}
        message={"Voulez-vous vraiment supprimer ce structure de cadre institutionnel et organisationnel"}
        open={deleteConfirmOpen}
        onYes={() => {
          setDeleteConfirmOpen(false);
          handleDeleteConfirm();
        }}
        onClose={() => setDeleteConfirmOpen(false)}
        onNo={() => setDeleteConfirmOpen(false)}
      />
    </>
  );
}

function Row({ row, handleDelete }) {
  const { id, name,institutionalOrganizationalFrameworkStructureTypeObj, dataSource, typeSphereObj } = row;

  return (
    <TableRow hover sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell component="th" scope="row">
        <Link
          to={PATH_GIRE_ENV_FAVORABLE.institutionalOrganizationalFrameworkStructures + "/" + id}
          component={RouterLink}
        >
          <Typography variant="body2" noWrap>
            {id}
          </Typography>
        </Link>
      </TableCell>

      <TableCell component="th" scope="row">
        <Link
          to={PATH_GIRE_ENV_FAVORABLE.institutionalOrganizationalFrameworkStructures + "/" + id}
          component={RouterLink}
        >
          <Typography variant="body2" noWrap>
            {name}
          </Typography>
        </Link>
      </TableCell>
      <TableCell style={{ minWidth: 160 }}>
        <Typography variant="body2" noWrap>
          {institutionalOrganizationalFrameworkStructureTypeObj?.name}
        </Typography>
      </TableCell>

      <TableCell style={{ minWidth: 160 }}>
        <Typography variant="body2" noWrap>
          {typeSphereObj?.name}
        </Typography>
      </TableCell>

        <TableCell style={{ minWidth: 160 }}>
        <Typography variant="body2" noWrap>
          {dataSource}
        </Typography>
      </TableCell>

      <TableCell align="right">
        <InstitutionalOrganizationalFrameworkStructureMoreMenu id={id} onDelete={() => handleDelete(id)} />
      </TableCell>
    </TableRow>
  );
}
