import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const InstrumentMechanismParticipationList = Loadable(lazy(() => import("./pages/InstrumentMechanismParticipationList")));
const InstrumentMechanismParticipationCreate = Loadable(lazy(() => import("./pages/InstrumentMechanismParticipationCreate")));
const InstrumentMechanismParticipationEdit = Loadable(lazy(() => import("./pages/InstrumentMechanismParticipationEdit")));
const InstrumentMechanismParticipationDisplay = Loadable(lazy(() => import("./pages/InstrumentMechanismParticipationDisplay")));

const instrumentMechanismParticipationRouter = [
  { path: "instrument-mechanism-participations", element: <InstrumentMechanismParticipationList /> },
  { path: "instrument-mechanism-participations/:id", element: <InstrumentMechanismParticipationDisplay /> },
  { path: "instrument-mechanism-participations/new", element: <InstrumentMechanismParticipationCreate /> },
  { path: "instrument-mechanism-participations/:id/edit", element: <InstrumentMechanismParticipationEdit /> },
];

export default instrumentMechanismParticipationRouter;


