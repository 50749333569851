import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const StaticReserveWaterBodyList = Loadable(
  lazy(() => import("./pages/StaticReserveWaterBodyList"))
);
const StaticReserveWaterBodyCreate = Loadable(
  lazy(() => import("./pages/StaticReserveWaterBodyCreate"))
);
const StaticReserveWaterBodyEdit = Loadable(
  lazy(() => import("./pages/StaticReserveWaterBodyEdit"))
);
const StaticReserveWaterBodyDisplay = Loadable(
  lazy(() => import("./pages/StaticReserveWaterBodyDisplay"))
);

const staticReserveWaterBodyRouter = [
  {
    path: "static-reserve-water-bodies",
    element: <StaticReserveWaterBodyList />,
  },
  {
    path: "static-reserve-water-bodies/:id",
    element: <StaticReserveWaterBodyDisplay />,
  },
  {
    path: "static-reserve-water-bodies/new",
    element: <StaticReserveWaterBodyCreate />,
  },
  {
    path: "static-reserve-water-bodies/:id/edit",
    element: <StaticReserveWaterBodyEdit />,
  },
];

export default staticReserveWaterBodyRouter;
