import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const CompetencyList = Loadable(lazy(() => import("./pages/CompetencyList")));
const CompetencyCreate = Loadable(
  lazy(() => import("./pages/CompetencyCreate"))
);
const CompetencyEdit = Loadable(lazy(() => import("./pages/CompetencyEdit")));
const CompetencyDisplay = Loadable(
  lazy(() => import("./pages/CompetencyDisplay"))
);

const competencyRouter = [
  { path: "competencies", element: <CompetencyList /> },
  { path: "competencies/:id", element: <CompetencyDisplay /> },
  { path: "competencies/new", element: <CompetencyCreate /> },
  { path: "competencies/:id/edit", element: <CompetencyEdit /> },
];

export default competencyRouter;
