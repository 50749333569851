import {useApiHttp} from '../../../../shared/hooks/useHttp';


const useLegislativeTextService = () => {
    const {crud} = useApiHttp();

    const {
        create,
        update,
        destroy,
        findById,
        findAll,
    } = crud('legislative-texts');


    return {
        create,
        update,
        destroy,
        findById,
        findAll,
    }
}

export default useLegislativeTextService;
