import React from 'react';
import {PATH_GIRE_ENV_FAVORABLE, commonItems} from '../../gire.env.favorable.path';
import HeaderBreadcrumbs from "../../../../shared/components/HeaderBreadcrumbs";

export function InstitutionalOrganizationalFrameworkStructureOccurrenceHeaderBreadcrumbs ({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[
            ...commonItems,
            {name: "Type d Occurrences d'état de dynamisme du cadre institutionnel et organisationnel", href: PATH_GIRE_ENV_FAVORABLE.institutionalOrganizationalFrameworkStructureOccurrences},
            {name: lastName},
        ]}
    />);
}

export function InstitutionalOrganizationalFrameworkStructureOccurrenceListHeaderBreadcrumbs ({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[...commonItems, {name: lastName}]}
    />);
}
