import React, {useState} from "react";
import PropTypes from "prop-types";
import {useMessage} from "../../../../shared/hooks/useMessage";
import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {Autocomplete, Box, CircularProgress, TextField,} from "@mui/material";
// routes
import {PATH_WATER_USE} from "../../water.use.path";
import {InitialWaterMass, WaterMassSchema} from "../services/water.mass.store";
import {setRefresh} from "../services/water.mass.gql";
import {formErrorMessageParser} from '../../../../utils/utils';
import {useConnectFormState} from '../../../../shared/hooks/useFormikState';
import useWaterMassService from "../services/water.mass.services";
import {useSearchWaterMassSets} from "../../water_mass_set/services/water.mass.set.gql";
import {useSearchWaterTypes} from "../../water_type/services/water.type.gql";
import {FormikTextField, SimpleFormikForm} from "../../../../shared/components/froms/FormComponents";

WaterMassForm.propTypes = {
    isEdit: PropTypes.bool,
    currentWaterMass: PropTypes.object,
    fromDialog: PropTypes.object,
};

export default function WaterMassForm({isEdit, currentWaterMass, setCurrentWaterMass, fromDialog}) {
    const navigate = useNavigate();
    const {create, update} = useWaterMassService();
    const {showSuccess, showError} = useMessage();

    const initQueryWaterMassSet = currentWaterMass?.waterMassSetObj?.wording ?? fromDialog?.waterMassSet?.wording ?? '';
    const [waterMassSetQuery, setWaterMassSetQuery] = useState(initQueryWaterMassSet);
    const {waterMassSets, waterMassSetLoading} = useSearchWaterMassSets({waterMassSetQuery});

    const initQueryWaterType = currentWaterMass?.waterTypeObj?.wording ?? fromDialog?.waterType?.wording ?? '';
    const [waterTypeQuery, setWaterTypeQuery] = useState(initQueryWaterType);
    const {waterTypes, waterTypeLoading} = useSearchWaterTypes({waterTypeQuery});

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: InitialWaterMass(),
        validationSchema: WaterMassSchema,
        onSubmit: async (waterMass, {setSubmitting}) => {
            try {
                waterMass = WaterMassSchema.cast(waterMass);

                let message;
                if (!isEdit) {
                    waterMass = await create(waterMass);
                    message = 'Création avec succès';
                } else {
                    waterMass = await update(currentWaterMass.id, waterMass);
                    message = 'Modification avec succès';
                }

                setRefresh(true);

                setCurrentWaterMass();

                setSubmitting(false);
                showSuccess(message);

                if (fromDialog) {
                    fromDialog.onSave(waterMass);
                } else {
                    navigate(`${PATH_WATER_USE.water_mass}/${waterMass.id}`);
                }
            } catch (error) {
                setSubmitting(false);
                showError(formErrorMessageParser({error, isEdit}));
            }
        }
    });

    const {values, touched, errors, setValues, setFieldValue} = formik;

    useConnectFormState({
        formValues: values, setFormValues: setValues,
        stateValues: currentWaterMass, setStateValues: setCurrentWaterMass,
    });

    const onCancel = (_) => {
        setCurrentWaterMass();
        if (fromDialog) {
            fromDialog.onCancel();
        } else {
            navigate(PATH_WATER_USE.waterMasss);
        }
    }

    return (
        <SimpleFormikForm formik={formik} isEdit={isEdit} onCancel={onCancel}>
            <FormikTextField label='Nom' name='wording'/>

            <Box flexGrow={1} display='flex' flexDirection='row'>
                <Autocomplete
                    fullWidth
                    name="waterMassSet"
                    autoHighlight
                    options={waterMassSets}
                    loading={waterMassSetLoading}
                    inputValue={waterMassSetQuery}
                    value={{id: values.waterMassSet}}
                    loadingText={<CircularProgress/>}
                    isOptionEqualToValue={(option, value) =>
                        option.id === value?.id
                    }
                    onChange={(event, selectedWaterMassSet) => {
                        setFieldValue("waterMassSet", selectedWaterMassSet?.id);
                        setWaterMassSetQuery(selectedWaterMassSet?.wording ?? "");
                    }}
                    getOptionLabel={(option) => {
                        const _option = waterMassSets.find((op) => op.id === option.id);
                        return _option?.wording ?? "";
                    }}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                label="Ensemble masse eau"
                                onChange={(e) => {
                                    setWaterMassSetQuery(e.target.value);
                                }}
                                error={Boolean(touched.waterMassSet && errors.waterMassSet)}
                                helperText={touched.waterMassSet && errors.waterMassSet}
                            />
                        );
                    }}
                />

            </Box>

            <Box flexGrow={1} display='flex' flexDirection='row'>
                <Autocomplete
                    fullWidth
                    name="waterType"
                    autoHighlight
                    options={waterTypes}
                    loading={waterTypeLoading}
                    inputValue={waterTypeQuery}
                    value={{id: values.waterType}}
                    loadingText={<CircularProgress/>}
                    isOptionEqualToValue={(option, value) =>
                        option.id === value?.id
                    }
                    onChange={(event, selectedWaterType) => {
                        setFieldValue("waterType", selectedWaterType?.id);
                        setWaterTypeQuery(selectedWaterType?.wording ?? "");
                    }}
                    getOptionLabel={(option) => {
                        const _option = waterTypes.find((op) => op.id === option.id);
                        return _option?.wording ?? "";
                    }}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                label="Type d'eau"
                                onChange={(e) => {
                                    setWaterTypeQuery(e.target.value);
                                }}
                                error={Boolean(touched.waterType && errors.waterType)}
                                helperText={touched.waterType && errors.waterType}
                            />
                        );
                    }}
                />

            </Box>

            <FormikTextField multiline minRows={2} maxRows={6}  label='Description' name='description'/>
        </SimpleFormikForm>
    );
}
