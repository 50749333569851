import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { parseCount, parseEntity } from "../../../../utils/gql.utils";
import { useEffect, useState } from "react";
import { useMessage } from "../../../../shared/hooks/useMessage";

export let needRefresh = false;
export const setRefresh = (value) => (needRefresh = value);

export const instrumentPolicyFields = `
    id name 
    instrumentPolicyType instrumentPolicyTypeObj{id name} typeSphere typeSphereObj{id name}
    dateMizPlace horizonProjection descriptionObjectifs structureResp dataSource`;

///////////////////// Find By ID /////////////////////
const GET_BASIN_BY_ID = gql`
    query InstrumentPolicyById($id: Int!) {
        instrumentPolicyById(id: $id) {
            error message entity { ${instrumentPolicyFields} }
        }
    }
`;

export const useInstrumentPolicyById = (id) => {
    id = parseInt(id);
    return useQuery(GET_BASIN_BY_ID, {
        variables: { id },
        fetchPolicy: "network-only",
    });
};

export const useInstrumentPolicy = (id) => {
    const result = useInstrumentPolicyById(id);
    return parseEntity(result, "instrumentPolicyById");
};
///////////////////// Search /////////////////////
const SEARCH_HEALTH_AREA = gql`
    query SearchInstrumentPolicies($query: String, $instrumentPolicyType: Int, $typeSphere: Int, $page: Int, $size: Int) {
        searchInstrumentPolicies(query: $query, instrumentPolicyType:$instrumentPolicyType, typeSphere:$typeSphere, page: $page, size: $size) {
            error message page {
                content { ${instrumentPolicyFields} }
                page hasNext hasPrevious totalPages totalElements numberOfElements
            }
        }
    }
`;

export const useSearchInstrumentPolicies = ({ query = "", instrumentPolicyType, typeSphere, page = 0, size = 20 }) => {
    const [instrumentPolicies, setInstrumentPolicies] = useState([]);
    const [instrumentPolicyPage, setInstrumentPolicyPage] = useState(null);

    const { showError } = useMessage();

    const [search, { data: searchData, refetch, loading, error: _error }] =
        useLazyQuery(SEARCH_HEALTH_AREA, {
            variables: { query, instrumentPolicyType, typeSphere, page, size },
        });

    useEffect(() => {
        if (_error) {
            showError("Erreur lors de l'operation");
            setInstrumentPolicyPage(null);
            setInstrumentPolicies([]);
            return;
        }

        if (!searchData) return;

        const { error, message, page } = searchData.searchInstrumentPolicies;

        if (error) {
            showError(message);
            setInstrumentPolicyPage(null);
            setInstrumentPolicies([]);
        } else {
            setInstrumentPolicyPage(page);
            setInstrumentPolicies(page.content);
        }

        if (needRefresh) {
            refetch();
            setRefresh(false);
        }
    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({ variables: { query, instrumentPolicyType, typeSphere, page, size } });
    }, [query,instrumentPolicyType,typeSphere, page, size]);

    return {
        instrumentPolicies,
        loading,
        search,
        refetch,
        instrumentPolicyPage,
    };
};

///////////////////// Count /////////////////////
const COUNT_HEALTH_AREAS = gql`
  query CountInstrumentPolicies {
    countInstrumentPolicies {
      error
      message
      count
    }
  }
`;

export const useCountInstrumentPolicies = () => {
    const result = useQuery(COUNT_HEALTH_AREAS, {
        fetchPolicy: "network-only",
    });
    return parseCount(result, "countInstrumentPolicies");
};
