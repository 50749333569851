import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const InformationSupportProductionOccurrencesList = Loadable(
  lazy(() => import("./pages/InformationSupportProductionOccurrencesList"))
);
const InformationSupportProductionOccurrencesCreate = Loadable(
  lazy(() => import("./pages/InformationSupportProductionOccurrencesCreate"))
);
const InformationSupportProductionOccurrencesEdit = Loadable(
  lazy(() => import("./pages/InformationSupportProductionOccurrencesEdit"))
);
const InformationSupportProductionOccurrencesDisplay = Loadable(
  lazy(() => import("./pages/InformationSupportProductionOccurrencesDisplay"))
);


const informationSupportProductionOccurrencesRouter = [
  {
    path: "information-support-production-occurrences",
    element: <InformationSupportProductionOccurrencesList />,
  },
  {
    path: "information-support-production-occurrences/:id",
    element: <InformationSupportProductionOccurrencesDisplay />,
  },
  {
    path: "information-support-production-occurrences/new",
    element: <InformationSupportProductionOccurrencesCreate />,
  },
  {
    path: "information-support-production-occurrences/:id/edit",
    element: <InformationSupportProductionOccurrencesEdit />,
  },
];

export default informationSupportProductionOccurrencesRouter;
