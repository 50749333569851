import React, {useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
// material
import {CircularProgress, Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {InstrumentPolicyTypeForm} from '../components';
import {InstrumentPolicyTypeHeaderBreadcrumbs} from '../components';
import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useInstrumentPolicyType} from '../services/instrument.policy.type.gql';
import {useInstrumentPolicyTypeStore} from "../services/instrument.policy.type.store";

export default function InstrumentPolicyTypeEdit() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {showError} = useMessage();

    const {setCurrentUpdateInstrumentPolicyType: setCurrentInstrumentPolicyType} = useInstrumentPolicyTypeStore();

    const {entity: currentInstrumentPolicyType, error, loading, errorMessage} = useInstrumentPolicyType(id);

    useEffect(() => {
        if (loading) return;
        if (error) {
            showError(errorMessage);
            navigate(PATH_GIRE_ENV_FAVORABLE.instrumentPolicyTypes);
        }
    }, [loading, error, errorMessage]);

    useEffect(() => {
        setCurrentInstrumentPolicyType(currentInstrumentPolicyType);
    }, [currentInstrumentPolicyType]);

    return (
        <Page title={"Gire environment favorable: Modification type d'instruments de politique et stratégie"}>
            <Container maxWidth={'xl'}>
                <InstrumentPolicyTypeHeaderBreadcrumbs
                    heading="Modification du type d'instruments de politique et stratégie"
                    lastName={`Modification type d'instruments de politique et stratégie - ${id}`}
                />

                {loading && <CircularProgress/>}
                {!loading && currentInstrumentPolicyType && <InstrumentPolicyTypeForm isEdit {...{currentInstrumentPolicyType, setCurrentInstrumentPolicyType}}/>}
            </Container>
        </Page>
    );
}
