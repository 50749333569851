import {useEffect, useState} from 'react';
import {useMessage} from '../../../../../shared/hooks/useMessage';
import {parseCount, parseEntity} from '../../../../../utils/gql.utils';
import {gql, useQuery, useLazyQuery} from '@apollo/client';

export let needRefresh = false;
export const setRefresh = (value) => needRefresh = value;

export const dashboardFields = `
    id title description
`;

///////////////////// Find By ID /////////////////////
const GET_DASHBOARD_BY_ID = gql`
    query DashboardById($id: Int!) {
        dashboardById(id: $id) {
            error message entity { ${dashboardFields} }
        }
    }
`;

export const useDashboardById = (id) => {
    id = parseInt(id);
    return useQuery(GET_DASHBOARD_BY_ID, {
        variables: {id},
        fetchPolicy: 'network-only'
    });
}

export const useDashboard = (id) => {
    const result = useDashboardById(id);

    return parseEntity(result, 'dashboardById');
}

///////////////////// Search /////////////////////
const SEARCH_DASHBOARDS = gql`
    query SearchDashboards($query: String, $page: Int, $size: Int) {
        searchDashboards(query: $query, page: $page, size: $size) {
            error message page {
                content { ${dashboardFields} }
                page hasNext hasPrevious totalPages totalElements numberOfElements
            }
        }
    }
`;

export const useSearchDashboards = ({query = '', page = 0, size = 20}) => {
    const [dashboards, setDashboards] = useState([]);
    const [dashboardPage, setDashboardPage] = useState(null);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_DASHBOARDS, {
        variables: {query, page, size},
    });

    useEffect(() => {
        if (_error) {
            showError('Erreur lors de l\'operation');
            setDashboardPage(null);
            setDashboards([]);
            return;
        }

        if (!searchData) return;

        const {error, message, page} = searchData.searchDashboards;

        if (error) {
            showError(message);
            setDashboardPage(null);
            setDashboards([]);
        } else {
            setDashboardPage(page);
            setDashboards(page.content);
        }

        if (needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({variables: {query, page, size}});
    }, [query, page, size]);

    return {
        dashboards, loading, search, refetch, dashboardPage
    }
}

///////////////////// Count /////////////////////
const COUNT_DASHBOARD = gql`
    query CountDashboards {
        countDashboards {
            error message count
        }
    }
`;

export const useCountDashboards = () => {
    const result = useQuery(COUNT_DASHBOARD, {
        fetchPolicy: 'network-only'
    });
    return parseCount(result, 'countDashboards');
}
