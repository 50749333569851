import {gql, useLazyQuery, useQuery} from '@apollo/client';
import {parseCount, parseEntity} from "../../../../utils/gql.utils";
import {useEffect, useState} from "react";
import {useMessage} from "../../../../shared/hooks/useMessage";

export let needRefresh = false;
export const setRefresh = (value) => needRefresh = value;

export const regionFields = `
    id name pays paysObj {id name}
`;

///////////////////// Find By ID /////////////////////
const GET_REGION_BY_ID = gql`
    query RegionById($id: Int!) {
        regionById(id: $id) {
            error message entity { ${regionFields} }
        }
    }
`;

export const useRegionById = (id) => {
    id = parseInt(id);
    return useQuery(GET_REGION_BY_ID, {
        variables: {id},
        fetchPolicy: 'network-only'
    });
}

export const useRegion = (id) => {
    const result = useRegionById(id);
    return parseEntity(result, 'regionById');
}

///////////////////// Search /////////////////////
const SEARCH_REGIONS = gql`
    query SearchRegions($query: String, $pays: Int, $page: Int, $size: Int) {
        searchRegions(query: $query, pays: $pays, page: $page, size: $size) {
            error message page {
                content { ${regionFields} }
                page hasNext hasPrevious totalPages, totalElements, numberOfElements,
            }
        }
    }
`;

export const useSearchRegions = ({query = '', pays, page = 0, size = 20}) => {
    const [regions, setRegions] = useState([]);
    const [regionsPage, setRegionsPage] = useState(null);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_REGIONS, {
        variables: {query, pays, page, size},
    });

    useEffect(() => {
        if (_error) {
            showError('Erreur lors de l\'operation');
            setRegionsPage(null);
            setRegions([]);
            return;
        }

        if (!searchData) return;

        const {error, message, page} = searchData.searchRegions;


        if (error) {
            showError(message);
            setRegionsPage(null);
            setRegions([]);
        } else {
            setRegionsPage(page);
            setRegions(page.content);
        }


        if (needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({variables: {query, pays, page, size}});
    }, [query, pays, page, size]);

    return {
        regions, loading, search, refetch, regionsPage
    }
}

///////////////////// Count /////////////////////
const COUNT_REGIONS = gql`
    query CountRegions {
        countRegions {
            error message count
        }
    }
`;

export const useCountRegions = () => {
    const result = useQuery(COUNT_REGIONS, {
        fetchPolicy: 'network-only'
    });
    return parseCount(result, 'countRegions');
}
