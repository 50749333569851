import React from 'react';
import {PATH_RISK_PROTECTION} from '../../risk.protection.path';
import HeaderBreadcrumbs from "../../../../shared/components/HeaderBreadcrumbs";

const commonItems = [
    {name: 'Accueil', href: '/'},
    {name: 'Risque et protection en RE', href: PATH_RISK_PROTECTION.home},
];

export function RiskManagementToolsImplementationOccurrenceHeaderBreadcrumbs ({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[
            ...commonItems,
            {name: 'Occurrences de mise en œuvre des outils de gestion de risques liés à l\'eau', href: PATH_RISK_PROTECTION.riskManagementToolsImplementationOccurrences},
            {name: lastName},
        ]}
    />);
}

export function RiskManagementToolsImplementationOccurrenceListHeaderBreadcrumbs ({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[...commonItems, {name: lastName}]}
    />);
}
