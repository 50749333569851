import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const ManagementInstrumentList = Loadable(lazy(() => import('./pages/ManagementInstrumentList')));
const ManagementInstrumentCreate = Loadable(lazy(() => import('./pages/ManagementInstrumentCreate')));
const ManagementInstrumentEdit = Loadable(lazy(() => import('./pages/ManagementInstrumentEdit')));
const ManagementInstrumentDisplay = Loadable(lazy(() => import('./pages/ManagementInstrumentDisplay')));

const managementInstrumentRouter = [
    {path: 'management-instruments', element: <ManagementInstrumentList/>},
    {path: 'management-instruments/:id', element: <ManagementInstrumentDisplay/>},
    {path: 'management-instruments/new', element: <ManagementInstrumentCreate/>},
    {path: 'management-instruments/:id/edit', element: <ManagementInstrumentEdit/>},
];

export default managementInstrumentRouter;
