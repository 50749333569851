import React, {lazy} from 'react';
import Loadable from '../../../../shared/components/Loadable';


const DashboardList = Loadable(lazy(() => import('./pages/DashboardList')));
const DashboardEdit = Loadable(lazy(() => import('./pages/DashboardEdit')));
const DashboardCreate = Loadable(lazy(() => import('./pages/DashboardCreate')));
const DashboardDisplay = Loadable(lazy(() => import('./pages/DashboardDisplay')));
const DashboardExecutionResult = Loadable(lazy(() => import('./pages/DashboardExecutionResult')));
const MyDashboardList = Loadable(lazy(() => import('./pages/MyDashboardList')));

const dashboardRouter = [
    {path: 'dashboards', element: <DashboardList/>},
    {path: 'dashboards/new', element: <DashboardCreate/>},
    {path: 'dashboards/:id', element: <DashboardDisplay/>},
    {path: 'dashboards/:id/edit', element: <DashboardEdit/>},
    {path: 'dashboards/:id/execute', element: <DashboardExecutionResult/>},
    {path: 'dashboards/my-dashboards', element: <MyDashboardList/>},
];

export default dashboardRouter;
