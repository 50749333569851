import {gql, useQuery, useLazyQuery} from '@apollo/client';
import {parseCount, parseEntity} from '../../../../utils/gql.utils';
import {useEffect, useState} from 'react';
import {useMessage} from '../../../../shared/hooks/useMessage';

export let needRefresh = false;
export const setRefresh = (value) => needRefresh = value;

export const participationAreaAndTypeFields = `
    id name description dataSource
`;

///////////////////// Find By ID /////////////////////
const GET_PAYS_BY_ID = gql`
    query ParticipationAreaAndTypeById($id: Int!) {
        participationAreaAndTypeById(id: $id) {
            error message entity { ${participationAreaAndTypeFields} }
        }
    }
`;

export const useParticipationAreaAndTypeById = (id) => {
    id = parseInt(id);
    return useQuery(GET_PAYS_BY_ID, {
        variables: {id},
        fetchPolicy: 'network-only'
    });
}

export const useParticipationAreaAndType = (id) => {
    const result = useParticipationAreaAndTypeById(id);
    return parseEntity(result, 'participationAreaAndTypeById');
}

///////////////////// Search /////////////////////
const SEARCH_PAYS = gql`
    query SearchParticipationAreaAndTypes($query: String, $page: Int, $size: Int) {
        searchParticipationAreaAndTypes(query: $query, page: $page, size: $size) {
            error message page {
                content { ${participationAreaAndTypeFields} }
                page hasNext hasPrevious totalPages totalElements numberOfElements
            }
        }
    }
`;

export const useSearchParticipationAreaAndTypes = ({query = '', page = 0, size = 20}) => {
    const [participationAreaAndTypes, setParticipationAreaAndTypes] = useState([]);
    const [participationAreaAndTypePage, setParticipationAreaAndTypePage] = useState(null);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_PAYS, {
        variables: {query, page, size},
    });

    useEffect(() => {
        if(_error) {
            showError('Erreur lors de l\'operation');
            setParticipationAreaAndTypePage(null);
            setParticipationAreaAndTypes([]);
            return;
        }

        if(!searchData) return;

        const {error, message, page} = searchData.searchParticipationAreaAndTypes;

        if (error) {
            showError(message);
            setParticipationAreaAndTypePage(null);
            setParticipationAreaAndTypes([]);
        } else {
            setParticipationAreaAndTypePage(page);
            setParticipationAreaAndTypes(page.content);
        }

        if(needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({ variables: { query, page, size } });
    }, [query, page, size]);

    return {
        participationAreaAndTypes: participationAreaAndTypes, loading, search, refetch, participationAreaAndTypePage
    }
}

///////////////////// Count /////////////////////
const COUNT_PAYS = gql`
    query CountParticipationAreaAndTypes {
        countParticipationAreaAndTypes {
            error message count
        }
    }
`;

export const useCountParticipationAreaAndTypes = () => {
    const result = useQuery(COUNT_PAYS, {
        fetchPolicy: 'network-only'
    });
    return parseCount(result, 'countParticipationAreaAndTypes');
}
