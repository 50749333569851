import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const OccurrenceDegradationFactorList = Loadable(lazy(() => import('./pages/OccurrenceDegradationFactorList')));
const OccurrenceDegradationFactorCreate = Loadable(lazy(() => import('./pages/OccurrenceDegradationFactorCreate')));
const OccurrenceDegradationFactorEdit = Loadable(lazy(() => import('./pages/OccurrenceDegradationFactorEdit')));
const OccurrenceDegradationFactorDisplay = Loadable(lazy(() => import('./pages/OccurrenceDegradationFactorDisplay')));

const occurrenceDegradationFactorRouter = [
    {path: 'occurrence-degradation-factors', element: <OccurrenceDegradationFactorList/>},
    {path: 'occurrence-degradation-factors/:id', element: <OccurrenceDegradationFactorDisplay/>},
    {path: 'occurrence-degradation-factors/new', element: <OccurrenceDegradationFactorCreate/>},
    {path: 'occurrence-degradation-factors/:id/edit', element: <OccurrenceDegradationFactorEdit/>},
];

export default occurrenceDegradationFactorRouter;
