import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const TypeDegradationFactorList = Loadable(lazy(() => import('./pages/TypeDegradationFactorList')));
const TypeDegradationFactorCreate = Loadable(lazy(() => import('./pages/TypeDegradationFactorCreate')));
const TypeDegradationFactorEdit = Loadable(lazy(() => import('./pages/TypeDegradationFactorEdit')));
const TypeDegradationFactorDisplay = Loadable(lazy(() => import('./pages/TypeDegradationFactorDisplay')));

const typeDegradationFactorRouter = [
    {path: 'type-degradation-factors', element: <TypeDegradationFactorList/>},
    {path: 'type-degradation-factors/:id', element: <TypeDegradationFactorDisplay/>},
    {path: 'type-degradation-factors/new', element: <TypeDegradationFactorCreate/>},
    {path: 'type-degradation-factors/:id/edit', element: <TypeDegradationFactorEdit/>},
];

export default typeDegradationFactorRouter;
