import React from 'react';
import {PATH_ADMINISTRATIVE_DIVISION, commonItems} from '../../administrative_division.path';
import HeaderBreadcrumbs from "../../../../shared/components/HeaderBreadcrumbs";


export function CommuneHeaderBreadcrumbs ({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[
            ...commonItems,
            {name: 'Commune', href: PATH_ADMINISTRATIVE_DIVISION.communes},
            {name: lastName},
        ]}
    />);
}

export function CommuneListHeaderBreadcrumbs ({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[...commonItems, {name: lastName}]}
    />);
}
