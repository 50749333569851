import {useApiHttp} from '../../../../shared/hooks/useHttp';


const useLocalWaterDepartmentService = () => {
    const {crud} = useApiHttp();

    const {
        create,
        update,
        destroy,
        findById,
        findAll,
    } = crud('local-water-departments');



    return {
        create,
        update,
        destroy,
        findById,
        findAll,
    }
}

export default useLocalWaterDepartmentService;
