import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const InformationSupportsTypesList = Loadable(lazy(() => import("./pages/InformationSupportsTypesList")));
const InformationSupportsTypesCreate = Loadable(lazy(() => import("./pages/InformationSupportsTypesCreate")));
const InformationSupportsTypesEdit = Loadable(lazy(() => import("./pages/InformationSupportsTypesEdit")));
const InformationSupportsTypesDisplay = Loadable(lazy(() => import("./pages/InformationSupportsTypesDisplay")));

const informationSupportsTypesRouter = [
  { path: "information-supports-types", element: <InformationSupportsTypesList /> },
  { path: "information-supports-types/:id", element: <InformationSupportsTypesDisplay /> },
  { path: "information-supports-types/new", element: <InformationSupportsTypesCreate /> },
  { path: "information-supports-types/:id/edit", element: <InformationSupportsTypesEdit /> },
];

export default informationSupportsTypesRouter;
