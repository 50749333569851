import React from "react";
// material
import { Typography } from "@mui/material";
// components
import CreateDialogMenuItem from "../../../../shared/components/CreateDialogMenuItem";
import {InstrumentMechanismParticipationForm, InstrumentMechanismParticipationOccurrenceForm} from "./index";
// ----------------------------------------------------------------------

export function InstrumentMechanismParticipationOccurrenceCreateDialogMenuItem({
                                                                   instrumentMechanismParticipation,
                                                                   riskManagementToolApplicationDegree,
                                                                   dataRefYear,
                                                                   onClose,
                                                                   onSave,
                                                                   onClick,
                                                                   btn,
                                                               }) {
    const setCurrentInstrumentMechanismParticipationOccurrence = () => {};
    const currentInstrumentMechanismParticipationOccurrence = {
        riskManagementToolApplicationDegree: riskManagementToolApplicationDegree?.id,
        riskManagementToolApplicationDegreeObj: riskManagementToolApplicationDegree,

        dataRefYear: dataRefYear?.id,
        dataRefYearObj: dataRefYear,

        instrumentMechanismParticipation: instrumentMechanismParticipation?.id,
        instrumentMechanismParticipationObj: instrumentMechanismParticipation,
    };

    return (
        <CreateDialogMenuItem
            btn={btn}
            onClose={onClose}
            onClick={onClick}
            menuText="Ajouter une occurrence d'état de mise en œuvre des instruments et mécanismes de participation à la GIRE"
            dialogTitle="Création de l'occurrence d'état de mise en œuvre des instruments et mécanismes de participation à la GIRE"
            dialogContextText={<>{`Creation de nouvelle occurrence d'état de mise en œuvre des instruments et mécanismes de participation à la GIRE`}</>}
            formRender={({ onClose }) => {
                return (
                    <InstrumentMechanismParticipationOccurrenceForm
                        {...{currentInstrumentMechanismParticipationOccurrence, setCurrentInstrumentMechanismParticipationOccurrence,
                        }}
                        fromDialog={{
                            onCancel: onClose,
                            onSave: (createdInstrumentMechanismParticipationOccurrence) => {
                                onSave && onSave(createdInstrumentMechanismParticipationOccurrence);
                                onClose && onClose();
                            },
                        }}
                    />
                );
            }}
        />
    );
}
