import {gql, useQuery, useLazyQuery} from '@apollo/client';
import {parseCount, parseEntity} from '../../../../utils/gql.utils';
import {useEffect, useState} from 'react';
import {useMessage} from '../../../../shared/hooks/useMessage';

export let needRefresh = false;
export const setRefresh = (value) => needRefresh = value;

export const paysFields = `
    id name nationality
`;

///////////////////// Find By ID /////////////////////
const GET_PAYS_BY_ID = gql`
    query PaysById($id: Int!) {
        paysById(id: $id) {
            error message entity { ${paysFields} }
        }
    }
`;

export const usePaysById = (id) => {
    id = parseInt(id);
    return useQuery(GET_PAYS_BY_ID, {
        variables: {id},
        fetchPolicy: 'network-only'
    });
}

export const usePays = (id) => {
    const result = usePaysById(id);
    return parseEntity(result, 'paysById');
}

///////////////////// Search /////////////////////
const SEARCH_PAYS = gql`
    query SearchPays($query: String, $page: Int, $size: Int) {
        searchPays(query: $query, page: $page, size: $size) {
            error message page {
                content { ${paysFields} }
                page hasNext hasPrevious totalPages totalElements numberOfElements
            }
        }
    }
`;

export const useSearchPays = ({query = '', page = 0, size = 20}) => {
    const [pays, setPays] = useState([]);
    const [paysPage, setPaysPage] = useState(null);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_PAYS, {
        variables: {query, page, size},
    });

    useEffect(() => {
        if(_error) {
            showError('Erreur lors de l\'operation');
            setPaysPage(null);
            setPays([]);
            return;
        }

        if(!searchData) return;

        const {error, message, page} = searchData.searchPays;

        if (error) {
            showError(message);
            setPaysPage(null);
            setPays([]);
        } else {
            setPaysPage(page);
            setPays(page.content);
        }

        if(needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({ variables: { query, page, size } });
    }, [query, page, size]);

    return {
        pays, loading, search, refetch, paysPage
    }
}

///////////////////// Count /////////////////////
const COUNT_PAYS = gql`
    query CountPays {
        countPays {
            error message count
        }
    }
`;

export const useCountPays = () => {
    const result = useQuery(COUNT_PAYS, {
        fetchPolicy: 'network-only'
    });
    return parseCount(result, 'countPays');
}
