import { useApiHttp } from "../../../../shared/hooks/useHttp";

const useCulturalSpeculationEntityUsagesService = () => {
  const { crud } = useApiHttp();
  const { create, update, destroy, findById, findAll } = crud("cultural-speculation-entity-usages");

  return {
    create,
    update,
    destroy,
    findById,
    findAll,
  };
};

export default useCulturalSpeculationEntityUsagesService;
