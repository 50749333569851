import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";
const RenewableWaterResourceList = Loadable(lazy(() => import("./pages/RenewableWaterResourceList")));
const RenewableWaterResourceCreate = Loadable(lazy(() => import("./pages/RenewableWaterResourceCreate")));
const RenewableWaterResourceEdit = Loadable(lazy(() => import("./pages/RenewableWaterResourceEdit")));
const RenewableWaterResourceDisplay = Loadable(lazy(() => import("./pages/RenewableWaterResourceDisplay")));

const renewableWaterResourceRouter = [
  { path: "renewable-water-resources", element: <RenewableWaterResourceList /> },
  { path: "renewable-water-resources/:id", element: <RenewableWaterResourceDisplay /> },
  { path: "renewable-water-resources/new", element: <RenewableWaterResourceCreate /> },
  { path: "renewable-water-resources/:id/edit", element: <RenewableWaterResourceEdit /> },
];

export default renewableWaterResourceRouter;
