import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const SamplingOccurrenceList = Loadable(lazy(() => import('./pages/SamplingOccurrenceList')));
const SamplingOccurrenceCreate = Loadable(lazy(() => import('./pages/SamplingOccurrenceCreate')));
const SamplingOccurrenceEdit = Loadable(lazy(() => import('./pages/SamplingOccurrenceEdit')));
const SamplingOccurrenceDisplay = Loadable(lazy(() => import('./pages/SamplingOccurrenceDisplay')));

const samplingOccurrenceRouter = [
    {path: 'sampling-occurrences', element: <SamplingOccurrenceList/>},
    {path: 'sampling-occurrences/:id', element: <SamplingOccurrenceDisplay/>},
    {path: 'sampling-occurrences/new', element: <SamplingOccurrenceCreate/>},
    {path: 'sampling-occurrences/:id/edit', element: <SamplingOccurrenceEdit/>},
];

export default samplingOccurrenceRouter;
