import {useMessage} from "./useMessage";
import fileDownload from 'js-file-download';
import {readFile} from '../../utils/file.utils';


export const useImportExport = () => {
    const {showSuccess, showError} = useMessage();

    const exportToJson = async (data, filename) => {
        try {
            console.log(filename, data);

            const output = JSON.stringify(data, null, 4);

            fileDownload(output, filename);

            showSuccess(`Export realise avec succès`);
        } catch (e) {
            console.error(e);
            showError(`Erreur lors de l'export`);
        }
    }

    const importFromJson = async (file) => {
        const fileData = await readFile(file);
        const data = JSON.parse(`${fileData}`);
        console.log(`#####@@@@ data:`, data)
        return data;
    }

    return {
        exportToJson,
        importFromJson,
    }
}
