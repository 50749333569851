import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const BudgetNatureList = Loadable(
  lazy(() => import("./pages/BudgetNatureList"))
);
const BudgetNatureCreate = Loadable(
  lazy(() => import("./pages/BudgetNatureCreate"))
);
const BudgetNatureEdit = Loadable(
  lazy(() => import("./pages/BudgetNatureEdit"))
);
const BudgetNatureDisplay = Loadable(
  lazy(() => import("./pages/BudgetNatureDisplay"))
);

const budgetNatureRouter = [
  {
    path: "budget-natures",
    element: <BudgetNatureList />,
  },
  {
    path: "budget-natures/:id",
    element: <BudgetNatureDisplay />,
  },
  {
    path: "budget-natures/new",
    element: <BudgetNatureCreate />,
  },
  {
    path: "budget-natures/:id/edit",
    element: <BudgetNatureEdit />,
  },
];

export default budgetNatureRouter;
