import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const CoordinatedModeList = Loadable(
  lazy(() => import("./pages/CoordinatedModeList"))
);
const CoordinatedModeCreate = Loadable(
  lazy(() => import("./pages/CoordinatedModeCreate"))
);
const CoordinatedModeEdit = Loadable(
  lazy(() => import("./pages/CoordinatedModeEdit"))
);
const CoordinatedModeDisplay = Loadable(
  lazy(() => import("./pages/CoordinatedModeDisplay"))
);

const coordinatedModeRouter = [
  { path: "coordinated-modes", element: <CoordinatedModeList /> },
  { path: "coordinated-modes/:id", element: <CoordinatedModeDisplay /> },
  { path: "coordinated-modes/new", element: <CoordinatedModeCreate /> },
  { path: "coordinated-modes/:id/edit", element: <CoordinatedModeEdit /> },
];

export default coordinatedModeRouter;
