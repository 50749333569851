import React from 'react';
// components
import {TypeSphereForm} from '../components';
import CreateDialogMenuItem from '../../../../shared/components/CreateDialogMenuItem';
import {useTypeSphereStore} from "../services/type.sphere.store";

// ----------------------------------------------------------------------

export function TypeSphereCreateDialogMenuItem({onClose, onSave, btn}) {
    const {
        currentNewTypeSphere: currentTypeSphere,
        setCurrentNewTypeSphere: setCurrentTypeSphere
    } = useTypeSphereStore();
    return (
        <CreateDialogMenuItem
            btn={btn}
            onClose={onClose}
            menuText="Ajouter un type de sphère d'action"
            dialogTitle="Création de type de sphère d'action"
            dialogContextText={<> {`Création de nouveau de sphère d'action`} </>}
            formRender={({onClose}) => {
                return (
                    <TypeSphereForm
                        {...{currentTypeSphere, setCurrentTypeSphere}}
                        fromDialog={{
                            onCancel: onClose,
                            onSave: (createdTypeSphere) => {
                                onSave && onSave(createdTypeSphere);
                                onClose && onClose();
                            }
                        }}/>
                );
            }}
        />
    );

}
