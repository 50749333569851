import React from "react";
import { path } from "../../home/sidebar/paths";
import WaterDamageIcon from '@mui/icons-material/WaterDamage';
//Features
const WATER_USE = "/water-use";
const _path = path(WATER_USE);
export const PATH_WATER_USE = {
    root: WATER_USE,
    home: _path("/home"),
    localWaterDepartments: _path("/local-water-departments"),
    areaUseWaters: _path("/area-use-waters"),
    waterUseItems: _path("/water-use-items"),
    operationalStructureWaterUses: _path("/operational-structure-water-uses"),
    water_source: _path("/waters-sources"),
    water_type: _path("/water-types"),
    water_mass_set: _path("/water-mass-sets"),
    water_mass_subset: _path("/water-mass-subsets"),
    water_mass: _path("/water-masses"),
    impact: _path("/impacts"),
    reject: _path("/rejects"),
    water_use_mode: _path("/waters-uses-modes"),
    work: _path("/works"),
};

export const waterUseMenu = [
    {
        title: "Usage eau",
        path: PATH_WATER_USE.root,
        icon: <WaterDamageIcon />,
        children: [
            { title: "Accueil", path: PATH_WATER_USE.home },
            {
                title: "Services local eau",
                path: PATH_WATER_USE.localWaterDepartments,
            },
            { title: "Domaines usage eau", path: PATH_WATER_USE.areaUseWaters },
            { title: "Poste usage eau", path: PATH_WATER_USE.waterUseItems },
            {
                title: "Structure opérationnelle usage eau",
                path: PATH_WATER_USE.operationalStructureWaterUses,
            },
            { title: "Source d'eau", path: PATH_WATER_USE.water_source },
            { title: "Type d'eau", path: PATH_WATER_USE.water_type },
            { title: "Masse d'eau", path: PATH_WATER_USE.water_mass },
            { title: "Ensemble masse eau", path: PATH_WATER_USE.water_mass_set },
            {
                title: "Sous ensemble masse eau",
                path: PATH_WATER_USE.water_mass_subset,
            },
            { title: "Impacts", path: PATH_WATER_USE.impact },
            { title: "Rejets", path: PATH_WATER_USE.reject },
            { title: "Modes usages eaux", path: PATH_WATER_USE.water_use_mode },
            { title: "Ouvrages", path: PATH_WATER_USE.work },
        ],
    },
];

export const commonItems = [
    { name: "Accueil", href: "/" },
    { name: "Usage Eau", href: PATH_WATER_USE.home },
];