import * as Yup from 'yup';
import create from 'zustand';


export const AhaTypeSchema = Yup.object().shape({
    name: Yup.string().default('').required('Le nom est obligatoire'),
    description: Yup.string().default(''),
    dataSource: Yup.string().default(''),
});

const initialAhaType = AhaTypeSchema.getDefaultFromShape();
export const InitialAhaType = () => ({...initialAhaType});

const setCurrentNewAhaType = set => ahaType => {
    set(state => ({...state, currentNewAhaType: ahaType ?? InitialAhaType()}));
}

const setCurrentUpdateAhaType = set => ahaType => {
    set(state => ({...state, currentUpdateAhaType: ahaType ?? InitialAhaType()}));
}

export const useAhaTypeStore = create(set => ({
    //State
    currentNewAhaType: InitialAhaType(),
    currentUpdateAhaType: InitialAhaType(),
    //Reducers
    setCurrentNewAhaType: setCurrentNewAhaType(set),
    setCurrentUpdateAhaType: setCurrentUpdateAhaType(set),
}))
