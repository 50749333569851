import {useApiHttp} from '../../../../shared/hooks/useHttp';


const useInstitutionalOrganizationalFrameworkStructureService = () => {
    const {crud} = useApiHttp();

    const {
        create,
        update,
        destroy,
        findById,
        findAll,
    } = crud('institutional-organizational-framework-structures');


    return {
        create,
        update,
        destroy,
        findById,
        findAll,
    }
}

export default useInstitutionalOrganizationalFrameworkStructureService;
