import * as Yup from 'yup';
import create from 'zustand';


export const StatusCommuneSchema = Yup.object().shape({
    name: Yup.string().default('').required('Le nom est obligatoire'),
    // description: Yup.string(),
});

const initialStatusCommune = StatusCommuneSchema.getDefaultFromShape();
export const InitialStatusCommune = () => ({...initialStatusCommune});

const setCurrentNewStatusCommune = set => statusCommune => {
    set(state => ({...state, currentNewStatusCommune: statusCommune ?? InitialStatusCommune()}));
}

const setCurrentUpdateStatusCommune = set => statusCommune => {
    set(state => ({...state, currentUpdateStatusCommune: statusCommune ?? InitialStatusCommune()}));
}

export const useStatusCommuneStore = create(set => ({
    //State
    currentNewStatusCommune: InitialStatusCommune(),
    currentUpdateStatusCommune: InitialStatusCommune(),
    //Reducers
    setCurrentNewStatusCommune: setCurrentNewStatusCommune(set),
    setCurrentUpdateStatusCommune: setCurrentUpdateStatusCommune(set),
}))
