
// ----------------------------------------------------------------------

export const path = root => sublink => `${root}${sublink}`;

// ----------------------------------------------------------------------
const ROOTS_APP = '/app';
const pathApp = path(ROOTS_APP);

export const PATH_APP = {
    root: ROOTS_APP,
    home: pathApp('/home'),
};

// ----------------------------------------------------------------------

export const PATH_PAGE = {
    page404: '/404',
    page500: '/500',
};
