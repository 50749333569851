import * as Yup from 'yup';
import create from 'zustand';


export const PaysSchema = Yup.object().shape({
    name: Yup.string().default('').required('Le nom est obligatoire'),
    nationality: Yup.string().default('').required('La nationalite est obligatoire'),
});

const initialPays = PaysSchema.getDefaultFromShape();
export const InitialPays = () => ({...initialPays});

const setCurrentNewPays = set => pays => {
    set(state => ({...state, currentNewPays: pays ?? InitialPays()}));
}

const setCurrentUpdatePays = set => pays => {
    set(state => ({...state, currentUpdatePays: pays ?? InitialPays()}));
}

export const usePaysStore = create(set => ({
    //State
    currentNewPays: InitialPays(),
    currentUpdatePays: InitialPays(),
    //Reducers
    setCurrentNewPays: setCurrentNewPays(set),
    setCurrentUpdatePays: setCurrentUpdatePays(set),
}))
