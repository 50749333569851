import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const WaterUseModeList = Loadable(
  lazy(() => import("./pages/WaterUseModeList"))
);
const WaterUseModeCreate = Loadable(
  lazy(() => import("./pages/WaterUseModeCreate"))
);
const WaterUseModeEdit = Loadable(
  lazy(() => import("./pages/WaterUseModeEdit"))
);
const WaterUseModeDisplay = Loadable(
  lazy(() => import("./pages/WaterUseModeDisplay"))
);

const waterUseModeRouter = [
  { path: "waters-uses-modes", element: <WaterUseModeList /> },
  { path: "waters-uses-modes/:id", element: <WaterUseModeDisplay /> },
  { path: "waters-uses-modes/new", element: <WaterUseModeCreate /> },
  { path: "waters-uses-modes/:id/edit", element: <WaterUseModeEdit /> },
];

export default waterUseModeRouter;
