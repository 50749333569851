import React from 'react';
import {PATH_IRRIGATION_NEED_WATER, commonItems} from '../../irrigation.need.water.path';
import HeaderBreadcrumbs from "../../../../shared/components/HeaderBreadcrumbs";

export function AhaTypeHeaderBreadcrumbs ({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[
            ...commonItems,
            {name: 'Types aménagements Hydro-agricole', href: PATH_IRRIGATION_NEED_WATER.aha_types},
            {name: lastName},
        ]}
    />);
}

export function AhaTypeListHeaderBreadcrumbs ({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[...commonItems, {name: lastName}]}
    />);
}
