import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const HealthAreaList = Loadable(lazy(() => import("./pages/HealthAreaList")));
const HealthAreaCreate = Loadable(lazy(() => import("./pages/HealthAreaCreate")));
const HealthAreaEdit = Loadable(lazy(() => import("./pages/HealthAreaEdit")));
const HealthAreaDisplay = Loadable(lazy(() => import("./pages/HealthAreaDisplay")));

const healthAreaRouter = [
  { path: "health-areas", element: <HealthAreaList /> },
  { path: "health-areas/:id", element: <HealthAreaDisplay /> },
  { path: "health-areas/new", element: <HealthAreaCreate /> },
  { path: "health-areas/:id/edit", element: <HealthAreaEdit /> },
];

export default healthAreaRouter;
