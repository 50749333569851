import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useNavigate} from 'react-router-dom';
import {Form, FormikProvider, useFormik} from 'formik';
import {Box, Card, Stack} from '@mui/material';
import {PATH_RISK_PROTECTION} from '../../risk.protection.path';
import {
    FormikAutocomplete,
    FormikTextField,
    FormRowStack,
    SubmitCancelGroupButton
} from '../../../../shared/components/froms/FormComponents';
import {setRefresh} from '../services/health.area.gql';
import useHealthAreaService from '../services/health.area.services';
import {useConnectFormState} from "../../../../shared/hooks/useFormikState";
import {HealthAreaSchema, InitialHealthArea} from "../services/health.area.store";
import {formErrorMessageParser} from "../../../../utils/utils";
import {useSearchWaterMasses} from "../../../water_use/water_mass/services/water.mass.gql";
import {LocalWaterDepartmentCreateDialogMenuItem} from "../../../water_use/local_water_department/components";
import {WaterMassCreateDialogMenuItem} from "../../../water_use/water_mass/components/WaterMassDialog";

HealthAreaForm.propTypes = {
    isEdit: PropTypes.bool,
    currentHealthArea: PropTypes.object,
    fromDialog: PropTypes.object,
};

export default function HealthAreaForm({isEdit, currentHealthArea, setCurrentHealthArea, fromDialog}) {
    const navigate = useNavigate();
    const {showSuccess, showError} = useMessage();
    const {create, update} = useHealthAreaService();

    const initQuery = currentHealthArea?.waterMassObj?.wording ?? fromDialog?.waterMass?.wording ?? '';
    const [query, setQuery] = useState(initQuery);
    const {waterMasses, loading} = useSearchWaterMasses({query});

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: HealthAreaSchema,
        initialValues: InitialHealthArea(),
        onSubmit: async (healthArea, {setSubmitting}) => {
            try {
                healthArea = HealthAreaSchema.cast(healthArea);

                let message;
                if (!isEdit) {
                    healthArea = await create(healthArea);
                    message = 'Création avec succès';
                } else {
                    healthArea = await update(currentHealthArea.id, healthArea);
                    message = 'Modification avec succès';
                }

                setRefresh(true);
                setCurrentHealthArea();

                setSubmitting(false);
                showSuccess(message);

                if (fromDialog) {
                    fromDialog.onSave(healthArea);
                } else {
                    navigate(`${PATH_RISK_PROTECTION.healthAreas}/${healthArea.id}`);
                }
            } catch (error) {
                setSubmitting(false);
                showError(formErrorMessageParser({error, isEdit}));
            }
        }
    });

    const {values, setValues, handleSubmit, isSubmitting, setFieldValue} = formik;

    useConnectFormState({
        formValues: values, setFormValues: setValues,
        stateValues: currentHealthArea, setStateValues: setCurrentHealthArea,
    });

    const onCancel = (_) => {
        setCurrentHealthArea();
        if (fromDialog) {
            fromDialog.onCancel();
        } else {
            navigate(PATH_RISK_PROTECTION.healthAreas);
        }
    };

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>

                <Card sx={{p: 3}}>

                    <Stack spacing={3}>

                        <FormikTextField label='Nom' name='name' formik={formik}/>

                        <Box flexGrow={1} display='flex' flexDirection='row'>
                            <FormikAutocomplete
                                name='waterMass'
                                label="Masse d'eau"
                                options={waterMasses}
                                labelField='wording'
                                embeddedObjField='waterMassObj'
                                {...{query, formik, loading, setQuery}}
                            />
                            <Box sx={{py: 1, ml: 1}}>
                                <WaterMassCreateDialogMenuItem btn onSave={(createdWaterMass) => {
                                    currentHealthArea.waterMassObj = createdWaterMass;
                                    setQuery(createdWaterMass?.wording ?? '');
                                    setFieldValue('waterMass', createdWaterMass?.id);
                                }}/>
                            </Box>
                        </Box>

                        <FormRowStack>
                            <FormikTextField label='DataSource' name='dataSource' formik={formik}/>
                        </FormRowStack>

                        <SubmitCancelGroupButton {...{isEdit, isSubmitting, onCancel}}/>
                    </Stack>

                </Card>

            </Form>
        </FormikProvider>
    );
}
