import {gql, useQuery, useLazyQuery} from '@apollo/client';
import {parseCount, parseEntity} from '../../../../utils/gql.utils';
import {useEffect, useState} from 'react';
import {useMessage} from '../../../../shared/hooks/useMessage';

export let needRefresh = false;
export const setRefresh = (value) => needRefresh = value;

export const riskTypeFields = `
    id name description dataSource
`;

///////////////////// Find By ID /////////////////////
const GET_PAYS_BY_ID = gql`
    query RiskTypeById($id: Int!) {
        riskTypeById(id: $id) {
            error message entity { ${riskTypeFields} }
        }
    }
`;

export const useRiskTypeById = (id) => {
    id = parseInt(id);
    return useQuery(GET_PAYS_BY_ID, {
        variables: {id},
        fetchPolicy: 'network-only'
    });
}

export const useRiskType = (id) => {
    const result = useRiskTypeById(id);
    return parseEntity(result, 'riskTypeById');
}

///////////////////// Search /////////////////////
const SEARCH_PAYS = gql`
    query SearchRiskTypes($query: String, $page: Int, $size: Int) {
        searchRiskTypes(query: $query, page: $page, size: $size) {
            error message page {
                content { ${riskTypeFields} }
                page hasNext hasPrevious totalPages totalElements numberOfElements
            }
        }
    }
`;

export const useSearchRiskTypes = ({query = '', page = 0, size = 20}) => {
    const [riskTypes, setRiskTypes] = useState([]);
    const [riskTypePage, setRiskTypePage] = useState(null);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_PAYS, {
        variables: {query, page, size},
    });

    useEffect(() => {
        if(_error) {
            showError('Erreur lors de l\'operation');
            setRiskTypePage(null);
            setRiskTypes([]);
            return;
        }

        if(!searchData) return;

        const {error, message, page} = searchData.searchRiskTypes;

        if (error) {
            showError(message);
            setRiskTypePage(null);
            setRiskTypes([]);
        } else {
            setRiskTypePage(page);
            setRiskTypes(page.content);
        }

        if(needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({ variables: { query, page, size } });
    }, [query, page, size]);

    return {
        riskTypes: riskTypes, loading, search, refetch, riskTypePage
    }
}

///////////////////// Count /////////////////////
const COUNT_PAYS = gql`
    query CountRiskTypes {
        countRiskTypes {
            error message count
        }
    }
`;

export const useCountRiskTypes = () => {
    const result = useQuery(COUNT_PAYS, {
        fetchPolicy: 'network-only'
    });
    return parseCount(result, 'countRiskTypes');
}
