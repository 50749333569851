import {Link as RouterLink} from 'react-router-dom';
// material
import {styled} from '@mui/material/styles';
import {Box, Button, Container, Typography} from '@mui/material';
import Page from '../components/Page';
import PageNotFoundIllustration from "../../assets/illustration_404";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({theme}) => ({
    display: 'flex',
    minHeight: '100%',
    alignItems: 'center',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

export default function Page404() {
    return (
        <RootStyle title="404 Page non trouvee">
            <Container>
                <Box sx={{maxWidth: 480, margin: 'auto', textAlign: 'center'}}>
                    <Typography variant="h3" paragraph>
                        {"Désolé, cette page n'existe pas!"}
                    </Typography>
                    <Typography sx={{color: 'text.secondary'}}>
                        Désolé, la page que vous cherchez n'existe pas. Peut etre que vous avez fait une erreur sur URL?
                        Assurez-vous que c'est le bon URL.
                    </Typography>

                    <PageNotFoundIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />

                    <Button to="/" size="large" variant="contained" component={RouterLink}>
                        Accueil
                    </Button>
                </Box>
            </Container>
        </RootStyle>
    );
}
