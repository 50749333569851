import {useEffect, useState} from 'react';
import {useMessage} from '../../../../../shared/hooks/useMessage';
import {parseCount, parseEntity} from '../../../../../utils/gql.utils';
import {gql, useQuery, useLazyQuery} from '@apollo/client';

export let needRefresh = false;
export const setRefresh = (value) => needRefresh = value;

export const reportingFields = `
    id label description request
`;

///////////////////// Find By ID /////////////////////
const GET_REPORTING_BY_ID = gql`
    query ReportingById($id: Int!) {
        reportingById(id: $id) {
            error message entity { ${reportingFields} }
        }
    }
`;

export const useReportingById = (id) => {
    id = parseInt(id);
    return useQuery(GET_REPORTING_BY_ID, {
        variables: {id},
        fetchPolicy: 'network-only'
    });
}

export const useReporting = (id) => {
    const result = useReportingById(id);

    return parseEntity(result, 'reportingById');
}

///////////////////// Search /////////////////////
const SEARCH_REPORTING = gql`
    query SearchReporting($query: String, $page: Int, $size: Int) {
        searchReporting(query: $query, page: $page, size: $size) {
            error message page {
                content { ${reportingFields} }
                page hasNext hasPrevious totalPages totalElements numberOfElements
            }
        }
    }
`;

export const useSearchReporting = ({query = '', page = 0, size = 20}) => {
    const [reporting, setReporting] = useState([]);
    const [reportingPage, setReportingPage] = useState(null);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_REPORTING, {
        variables: {query, page, size},
    });

    useEffect(() => {
        if (_error) {
            showError('Erreur lors de l\'operation');
            setReportingPage(null);
            setReporting([]);
            return;
        }

        if (!searchData) return;

        const {error, message, page} = searchData.searchReporting;

        if (error) {
            showError(message);
            setReportingPage(null);
            setReporting([]);
        } else {
            setReportingPage(page);
            setReporting(page.content);
        }

        if (needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({variables: {query, page, size}});
    }, [query, page, size]);

    return {
        reporting, loading, search, refetch, reportingPage
    }
}

///////////////////// Search for Widget /////////////////////
const SEARCH_REPORTING_FOR_WIDGET = gql`
    query SearchReportingForWidget($query: String, $page: Int, $size: Int) {
        searchReportingForWidget(query: $query, page: $page, size: $size) {
            error message list {${reportingFields} }
        }
    }
`;

export const useSearchReportingForWidget = ({query = '', page = 0, size = 20}) => {
    const [reporting, setReporting] = useState([]);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_REPORTING_FOR_WIDGET, {
        variables: {query, page, size},
    });

    useEffect(() => {
        if (_error) {
            showError('Erreur lors de l\'operation');
            setReporting([]);
            return;
        }

        if (!searchData) return;

        const {error, message, list} = searchData.searchReportingForWidget;

        if (error) {
            showError(message);
            setReporting([]);
        } else {
            setReporting(list);
        }

        if (needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({variables: {query, page, size}});
    }, [query, page, size]);

    return {
        reporting, loading, search, refetch
    }
}

///////////////////// Count /////////////////////
const COUNT_REPORTING = gql`
    query CountReporting {
        countReporting {
            error message count
        }
    }
`;

export const useCountReporting = () => {
    const result = useQuery(COUNT_REPORTING, {
        fetchPolicy: 'network-only'
    });
    return parseCount(result, 'countReporting');
}
