import React, {useState} from "react";
import PropTypes from "prop-types";
import {useMessage} from "../../../../shared/hooks/useMessage";
import {useNavigate} from "react-router-dom";
import {Form, FormikProvider, useFormik} from "formik";
// material
import {Box, Card, Stack} from "@mui/material";
// routes
import {
    FormikAutocomplete,
    FormikTextField, FormRowStack,
    SubmitCancelGroupButton
} from "../../../../shared/components/froms/FormComponents";
import {setRefresh} from "../services/irrigated.development.entity.usages.gql";
import useIrrigatedDevelopmentEntityUsagesService from "../services/irrigated.development.entity.usages.services";
import {PATH_WATER_USAGE_URIGATION} from "../../water.usage.irrigation.path";
import {
    InitialIrrigatedDevelopmentEntityUsages,
    IrrigatedDevelopmentEntityUsagesSchema
} from "../services/irrigated.development.entity.usages.store";
import {useConnectFormState} from "../../../../shared/hooks/useFormikState";
import {useSearchAhaTypes} from "../../../irrigation_need_water/aha_type/services/aha.type.gql";
import {useSearchDataRefYears} from "../../../administrative_division/data_ref_year/services/data.ref.year.gql";
import {AhaTypeCreateDialogMenuItem} from "../../../irrigation_need_water/aha_type/components";
import {DataRefYearCreateDialogMenuItem} from "../../../administrative_division/data_ref_year/components";

IrrigatedDevelopmentEntityUsagesForm.propTypes = {
    isEdit: PropTypes.bool,
    currentProduct: PropTypes.object,
    fromDialog: PropTypes.object,
};

export default function IrrigatedDevelopmentEntityUsagesForm({
                                                                 isEdit,
                                                                 currentIrrigatedDevelopmentEntityUsages,
                                                                 setCurrentIrrigatedDevelopmentEntityUsages,
                                                                 fromDialog,
                                                             }) {
    const navigate = useNavigate();
    const {create, update} = useIrrigatedDevelopmentEntityUsagesService();
    const {showSuccess, showError} = useMessage();

    const initAhaTypeQuery = currentIrrigatedDevelopmentEntityUsages?.ahaTypeObj?.name ?? fromDialog?.ahaType?.name ?? '';
    const [ahaTypeQuery, setAhaTypeQuery] = useState(initAhaTypeQuery);
    const {ahaTypes, ahaTypeLoading} = useSearchAhaTypes({ahaTypeQuery});


    const initDataRefYearQuery = currentIrrigatedDevelopmentEntityUsages?.dataRefYearObj?.name ?? fromDialog?.dataRefYear?.name ?? '';
    const [dataRefYearQuery, setDataRefYearQuery] = useState(initDataRefYearQuery);
    const {dataRefYears, dataRefYearLoading} = useSearchDataRefYears({dataRefYearQuery});


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: InitialIrrigatedDevelopmentEntityUsages(),
        validationSchema: IrrigatedDevelopmentEntityUsagesSchema,
        onSubmit: async (
            irrigatedDevelopmentEntityUsages,
            {setSubmitting, resetForm, setErrors}
        ) => {
            try {
                irrigatedDevelopmentEntityUsages = IrrigatedDevelopmentEntityUsagesSchema.cast(irrigatedDevelopmentEntityUsages);

                let message;
                if (!isEdit) {
                    await create(irrigatedDevelopmentEntityUsages);
                    message = "Création avec succès";
                } else {
                    await update(
                        currentIrrigatedDevelopmentEntityUsages.id,
                        irrigatedDevelopmentEntityUsages
                    );
                    message = "Modification avec succès";
                }

                setRefresh(true);

                resetForm();

                setSubmitting(false);
                showSuccess(message);
                if (fromDialog) {
                    fromDialog.onSave(irrigatedDevelopmentEntityUsages);
                } else {
                    navigate(PATH_WATER_USAGE_URIGATION.irrigatedDevelopmentEntityUsages);
                }
            } catch (error) {
                console.error(error);
                setSubmitting(false);
                showError(
                    !isEdit
                        ? "Erreur lors de la creation"
                        : "Erreur lors de la modification"
                );
            }
        },
    });

    const {
        errors,
        values,
        setValues,
        touched,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        getFieldProps,
    } = formik;

    useConnectFormState({
        formValues: values,
        setFormValues: setValues,
        stateValues: currentIrrigatedDevelopmentEntityUsages,
        setStateValues: setCurrentIrrigatedDevelopmentEntityUsages,
    });

    const onCancel = (_) => {
        setCurrentIrrigatedDevelopmentEntityUsages();
        if (fromDialog) {
            fromDialog.onCancel();
        } else {
            navigate(PATH_WATER_USAGE_URIGATION.irrigatedDevelopmentEntityUsages);
        }
    };

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Card sx={{p: 3}}>
                    <Stack spacing={3}>
                    <FormikTextField label="Nom" name="name" formik={formik}/>

                        <FormRowStack>
                            <FormikTextField label="Code" name="code" formik={formik}/>

                            <FormikAutocomplete
                                name='ahaType'
                                label="Type aménagement hydro-agricole"
                                options={ahaTypes}
                                labelField='name'
                                embeddedObjField='ahaTypeObj'
                                {...{ahaTypeQuery, formik, ahaTypeLoading, setAhaTypeQuery}}
                            />
                            <Box sx={{py: 1, ml: 1}}>
                                <AhaTypeCreateDialogMenuItem btn onSave={(createdAhaType) => {
                                    currentIrrigatedDevelopmentEntityUsages.ahaTypeObj = createdAhaType;
                                    setAhaTypeQuery(createdAhaType?.name ?? '');
                                    setFieldValue('ahaType', createdAhaType?.id);
                                }}/>
                            </Box>
                        </FormRowStack>

                        <FormRowStack>
                            <FormikTextField label="Type de culture" name="cultureType" formik={formik}/>

                                <FormikAutocomplete
                                    name='dataRefYear'
                                    label="Année de reférence des données"
                                    options={dataRefYears}
                                    labelField='name'
                                    embeddedObjField='dataRefYearObj'
                                    {...{dataRefYearQuery, formik, dataRefYearLoading, setDataRefYearQuery}}
                                />
                                <Box sx={{py: 1, ml: 1}}>
                                    <DataRefYearCreateDialogMenuItem btn onSave={(createdDataRefYear) => {
                                        currentIrrigatedDevelopmentEntityUsages.dataRefYearObj = createdDataRefYear;
                                        setDataRefYearQuery(createdDataRefYear?.name ?? '');
                                        setFieldValue('dataRefYear', createdDataRefYear?.id);
                                    }}/>
                                </Box>

                        </FormRowStack>

                        <FormRowStack>
                            <FormikTextField label="Saison culturelle" name="culturalSeason" formik={formik}/>
                            <FormikTextField label="Cout moyen de la production" name="averageProductionCost" formik={formik}/>
                        </FormRowStack>

                        <FormRowStack>
                            <FormikTextField label="Source de donnée" name="dataSource" formik={formik}/>
                        </FormRowStack>

                        <SubmitCancelGroupButton {...{isEdit, isSubmitting, onCancel}} />
                    </Stack>
                </Card>
            </Form>
        </FormikProvider>
    );
}
