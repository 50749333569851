import * as Yup from 'yup';
import create from 'zustand';


export const InstrumentPolicyTypeSchema = Yup.object().shape({
    name: Yup.string().default('').required('Le nom est obligatoire'),
    description: Yup.string().default(''),
    dataSource: Yup.string().default(''),
});

const initialInstrumentPolicyType = InstrumentPolicyTypeSchema.getDefaultFromShape();
export const InitialInstrumentPolicyType = () => ({...initialInstrumentPolicyType});

const setCurrentNewInstrumentPolicyType = set => instrumentPolicyType => {
    set(state => ({...state, currentNewInstrumentPolicyType: instrumentPolicyType ?? InitialInstrumentPolicyType()}));
}

const setCurrentUpdateInstrumentPolicyType = set => instrumentPolicyType => {
    set(state => ({...state, currentUpdateInstrumentPolicyType: instrumentPolicyType ?? InitialInstrumentPolicyType()}));
}

export const useInstrumentPolicyTypeStore = create(set => ({
    //State
    currentNewInstrumentPolicyType: InitialInstrumentPolicyType(),
    currentUpdateInstrumentPolicyType: InitialInstrumentPolicyType(),
    //Reducers
    setCurrentNewInstrumentPolicyType: setCurrentNewInstrumentPolicyType(set),
    setCurrentUpdateInstrumentPolicyType: setCurrentUpdateInstrumentPolicyType(set),
}))
