import React from 'react';
import {Build, House, ViewCompact} from '@mui/icons-material';
import {path} from '../../home/sidebar/paths';

//Features
const CONSTRUCTION = '/constructions';
const _path = path(CONSTRUCTION);
export const PATH_CONSTRUCTION = {
    root: CONSTRUCTION,
    home: _path('/home'),
    activityTypeConstructions: _path("/activity-type-constructions"),
    massiveConstructions: _path("/massive-constructions"),
    occurrenceAchievements: _path("/occurrence-achievements"),
    samplingOccurrences: _path("/sampling-occurrences"),

};




export const  constructionMenu = [
    {
        title: 'Construction',
        path: PATH_CONSTRUCTION.root,
        icon: <House/>,
        children: [
            { title: 'Accueil', path: PATH_CONSTRUCTION.home },
            { title: 'Massives constructions', path: PATH_CONSTRUCTION.massiveConstructions },
            { title: 'Types d\'activités', path: PATH_CONSTRUCTION.activityTypeConstructions },
        ]
    }
];

export const commonItems = [
    { name: "Accueil", href: "/" },
    { name: "Construction", href: PATH_CONSTRUCTION.home },
];