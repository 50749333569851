import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const QuantitativeObservationalDataList = Loadable(lazy(() => import("./pages/QuantitativeObservationalDataList")));
const QuantitativeObservationalDataCreate = Loadable(lazy(() => import("./pages/QuantitativeObservationalDataCreate")));
const QuantitativeObservationalDataEdit = Loadable(lazy(() => import("./pages/QuantitativeObservationalDataEdit")));
const QuantitativeObservationalDataDisplay = Loadable(lazy(() => import("./pages/QuantitativeObservationalDataDisplay")));

const quantitativeObservationalDataRouter = [
  { path: "quantitative-observational-data", element: <QuantitativeObservationalDataList /> },
  { path: "quantitative-observational-data/:id", element: <QuantitativeObservationalDataDisplay /> },
  { path: "quantitative-observational-data/new", element: <QuantitativeObservationalDataCreate /> },
  { path: "quantitative-observational-data/:id/edit", element: <QuantitativeObservationalDataEdit /> },
];

export default quantitativeObservationalDataRouter;
