import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Box, Tab, Card, Container, Stack, TextField, CircularProgress} from '@mui/material';
import Page from '../../../../shared/components/Page';
import {LegislativeTextTypeHeaderBreadcrumbs} from '../components';
import {FormRowStack} from '../../../../shared/components/froms/FormComponents';
import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useLegislativeTextType} from '../services/legislative.text.type.gql';
import LegislativeTextTypeMoreMenu from '../components/LegislativeTextTypeMoreMenu';
import useLegislativeTextTypeService from "../services/legislative.text.type.services";
import {useDelete} from "../../../../shared/hooks/useDelete";

export default function LegislativeTextTypeDisplay() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {showError} = useMessage();
    const [tapIndex, setTapIndex] = useState('info');
    const {loading, entity: currentLegislativeTextType, error, errorMessage} = useLegislativeTextType(id);

    const {destroy} = useLegislativeTextTypeService();
    const {onDelete} = useDelete({
        destroyFn: destroy,
        onSuccess: () => navigate(PATH_GIRE_ENV_FAVORABLE.legislativeTextTypes),
    });

    useEffect(() => {
        if (loading) return;
        if (error) {
            showError(errorMessage);
            navigate(PATH_GIRE_ENV_FAVORABLE.legislativeTextTypes);
        }
    }, [error, loading, errorMessage]);

    const handleChangeTab = (event, newValue) => setTapIndex(newValue);

    return (
        <Page title={"Gire environnement favorable: Types textes de cadre législatif et reglementaire eau"}>
            <Container maxWidth={'xl'}>
                <LegislativeTextTypeHeaderBreadcrumbs
                    heading="Types textes de cadre législatif et reglementaire eau"
                    lastName={`${currentLegislativeTextType?.name ?? "Types textes de cadre législatif et reglementaire eau"}`}

                    action={<LegislativeTextTypeMoreMenu id={Number(id)} onDelete={onDelete} hiddenDisplay/>}
                />

                {loading && <CircularProgress/>}
                {!loading && currentLegislativeTextType && (
                    <TabContext value={tapIndex}>
                        <Box sx={{mb: 4, direction: 'row', display: 'flex', justifyContent: 'space-between'}}>
                            <TabList onChange={handleChangeTab}>
                                <Tab disableRipple value='info' label='Information'/>
                                {/*<Tab disableRipple value='regions' label='Regions'/>*/}
                            </TabList>
                        </Box>


                        <Card>
                            <TabPanel value='info'>
                                <Box sx={{p: 0, minHeight: '80%'}}>

                                    <Stack spacing={3}>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label='Nom'
                                                value={currentLegislativeTextType?.name}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label="Source de donnée"
                                                value={currentLegislativeTextType?.dataSource}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                 multiline minRows={2} maxRows={6}  label="Description"
                                                value={currentLegislativeTextType?.description}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>

                                    </Stack>

                                </Box>

                            </TabPanel>

                            {/*<TabPanel value='regions' sx={{p: 0}}>
                                <RegionTable legislativeTextType={currentLegislativeTextType.id} currentLegislativeTextType={currentLegislativeTextType}/>
                            </TabPanel>*/}
                        </Card>

                    </TabContext>
                )}
            </Container>
        </Page>
    );
}
