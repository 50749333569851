import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Box, Tab, Card, Container, Stack, TextField, CircularProgress} from '@mui/material';
import Page from '../../../../shared/components/Page';
import {InstitutionalOrganizationalFrameworkStructureOccurrenceHeaderBreadcrumbs} from '../components';
import {FormRowStack} from '../../../../shared/components/froms/FormComponents';
import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useInstitutionalOrganizationalFrameworkStructureOccurrence} from '../services/institutional.organizational.framework.structure.occurrence.gql';
import InstitutionalOrganizationalFrameworkStructureOccurrenceMoreMenu from '../components/InstitutionalOrganizationalFrameworkStructureOccurrenceMoreMenu';
import useInstitutionalOrganizationalFrameworkStructureOccurrenceService from "../services/institutional.organizational.framework.structure.occurrence.services";
import {useDelete} from "../../../../shared/hooks/useDelete";

export default function InstitutionalOrganizationalFrameworkStructureOccurrenceDisplay() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {showError} = useMessage();
    const [tapIndex, setTapIndex] = useState('info');
    const {loading, entity: currentInstitutionalOrganizationalFrameworkStructureOccurrence, error, errorMessage} = useInstitutionalOrganizationalFrameworkStructureOccurrence(id);

    const {destroy} = useInstitutionalOrganizationalFrameworkStructureOccurrenceService();
    const {onDelete} = useDelete({
        destroyFn: destroy,
        onSuccess: () => navigate(PATH_GIRE_ENV_FAVORABLE.institutionalOrganizationalFrameworkStructureOccurrences),
    });

    useEffect(() => {
        if (loading) return;
        if (error) {
            showError(errorMessage);
            navigate(PATH_GIRE_ENV_FAVORABLE.institutionalOrganizationalFrameworkStructureOccurrences);
        }
    }, [error, loading, errorMessage]);

    const handleChangeTab = (event, newValue) => setTapIndex(newValue);

    return (
        <Page
            title={"Gire environment favorable: occurrences d'état de dynamisme du cadre institutionnel et organisationnel"}>
            <Container maxWidth={'xl'}>
                <InstitutionalOrganizationalFrameworkStructureOccurrenceHeaderBreadcrumbs
                    heading="occurrences d'état de dynamisme du cadre institutionnel et organisationnel"
                    lastName={`${currentInstitutionalOrganizationalFrameworkStructureOccurrence?.name ?? "occurrences d'état de dynamisme du cadre institutionnel et organisationnel"}`}

                    action={<InstitutionalOrganizationalFrameworkStructureOccurrenceMoreMenu id={Number(id)} onDelete={onDelete} hiddenDisplay/>}
                />

                {loading && <CircularProgress/>}
                {!loading && currentInstitutionalOrganizationalFrameworkStructureOccurrence && (
                    <TabContext value={tapIndex}>
                        <Box sx={{mb: 4, direction: 'row', display: 'flex', justifyContent: 'space-between'}}>
                            <TabList onChange={handleChangeTab}>
                                <Tab disableRipple value='info' label='Information'/>
                                {/*<Tab disableRipple value='regions' label='Regions'/>*/}
                            </TabList>
                        </Box>


                        <Card>
                            <TabPanel value='info'>
                                <Box sx={{p: 0, minHeight: '80%'}}>

                                    <Stack spacing={3}>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label='Nom'
                                                value={currentInstitutionalOrganizationalFrameworkStructureOccurrence?.name}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>

                                        <TextField
                                            fullWidth
                                            label="Structures de cadre institutionnel et organisationnel"
                                            value={currentInstitutionalOrganizationalFrameworkStructureOccurrence?.institutionalOrganizationalFrameworkStructureObj?.name}
                                            InputProps={{readOnly: true}}
                                        />

                                        <FormRowStack>
                                                <TextField
                                                    fullWidth
                                                    label="Dégrée d'application"
                                                    value={currentInstitutionalOrganizationalFrameworkStructureOccurrence?.riskManagementToolApplicationDegreeObj?.name}
                                                    InputProps={{readOnly: true}}
                                                />

                                            <TextField
                                                fullWidth
                                                label="Année de reference de donnée"
                                                value={currentInstitutionalOrganizationalFrameworkStructureOccurrence?.dataRefYearObj?.name}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label="Justification"
                                                value={currentInstitutionalOrganizationalFrameworkStructureOccurrence?.justification}
                                                InputProps={{readOnly: true}}
                                            />
                                            <TextField
                                            fullWidth
                                            label="Source de donnée"
                                            value={currentInstitutionalOrganizationalFrameworkStructureOccurrence?.dataSource}
                                            InputProps={{readOnly: true}}
                                        />
                                        </FormRowStack>

                                    </Stack>
                                </Box>

                            </TabPanel>
                        </Card>

                    </TabContext>
                )}
            </Container>
        </Page>
    );
}
