import * as Yup from "yup";
import create from "zustand";

export const WaterMassSchema = Yup.object().shape({
  wording: Yup.string().default("").required("Le nom est obligatoire"),
  waterMassSet: Yup.number().default(null).required("L' ensemble masse eau est obligatoire"),
  waterType: Yup.number().default(null).required("Le type d'eau est obligatoire"),
  description: Yup.string().default(""),
});

const initialWaterMass = WaterMassSchema.getDefaultFromShape();
export const InitialWaterMass = () => ({ ...initialWaterMass });

const setCurrentNewWaterMass = (set) => (waterMass) => {
  set((state) => ({
    ...state,
    currentNewWaterMass: waterMass ?? InitialWaterMass(),
  }));
};

const setCurrentUpdateWaterMass = (set) => (waterMass) => {
  set((state) => ({
    ...state,
    currentUpdateWaterMass: waterMass ?? InitialWaterMass(),
  }));
};

export const useWaterMassStore = create((set) => ({
  //State
  currentNewWaterMass: InitialWaterMass(),
  currentUpdateWaterMass: InitialWaterMass(),
  //Reducers
  setCurrentNewWaterMass: setCurrentNewWaterMass(set),
  setCurrentUpdateWaterMass: setCurrentUpdateWaterMass(set),
}));
