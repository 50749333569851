import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const EvaluationToolsTypeList = Loadable(lazy(() => import("./pages/EvaluationToolsTypeList")));
const EvaluationToolsTypeCreate = Loadable(lazy(() => import("./pages/EvaluationToolsTypeCreate")));
const EvaluationToolsTypeEdit = Loadable(lazy(() => import("./pages/EvaluationToolsTypeEdit")));
const EvaluationToolsTypeDisplay = Loadable(lazy(() => import("./pages/EvaluationToolsTypeDisplay")));

const evaluationToolsTypeRouter = [
  { path: "evaluation-tools-type", element: <EvaluationToolsTypeList /> },
  { path: "evaluation-tools-type/:id", element: <EvaluationToolsTypeDisplay /> },
  { path: "evaluation-tools-type/new", element: <EvaluationToolsTypeCreate /> },
  { path: "evaluation-tools-type/:id/edit", element: <EvaluationToolsTypeEdit /> },
];

export default evaluationToolsTypeRouter;
