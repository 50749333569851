import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const ProductionTypeList = Loadable(lazy(() => import('./pages/ProductionTypeList')));
const ProductionTypeCreate = Loadable(lazy(() => import('./pages/ProductionTypeCreate')));
const ProductionTypeEdit = Loadable(lazy(() => import('./pages/ProductionTypeEdit')));
const ProductionTypeDisplay = Loadable(lazy(() => import('./pages/ProductionTypeDisplay')));

const productionTypeRouter = [
    {path: 'production-types', element: <ProductionTypeList/>},
    {path: 'production-types/:id', element: <ProductionTypeDisplay/>},
    {path: 'production-types/new', element: <ProductionTypeCreate/>},
    {path: 'production-types/:id/edit', element: <ProductionTypeEdit/>},
];

export default productionTypeRouter;
