import React from "react";
// material
import { Typography } from "@mui/material";
// components
import CreateDialogMenuItem from "../../../../shared/components/CreateDialogMenuItem";
import {InstitutionalOrganizationalFrameworkStructureForm, InstitutionalOrganizationalFrameworkStructureOccurrenceForm} from "./index";
// ----------------------------------------------------------------------

export function InstitutionalOrganizationalFrameworkStructureOccurrenceCreateDialogMenuItem({
                                                                   institutionalOrganizationalFrameworkStructure,
                                                                   riskManagementToolApplicationDegree,
                                                                   dataRefYear,
                                                                   onClose,
                                                                   onSave,
                                                                   onClick,
                                                                   btn,
                                                               }) {
    const setCurrentInstitutionalOrganizationalFrameworkStructureOccurrence = () => {};
    const currentInstitutionalOrganizationalFrameworkStructureOccurrence = {
        riskManagementToolApplicationDegree: riskManagementToolApplicationDegree?.id,
        riskManagementToolApplicationDegreeObj: riskManagementToolApplicationDegree,

        dataRefYear: dataRefYear?.id,
        dataRefYearObj: dataRefYear,

        institutionalOrganizationalFrameworkStructure: institutionalOrganizationalFrameworkStructure?.id,
        institutionalOrganizationalFrameworkStructureObj: institutionalOrganizationalFrameworkStructure,
    };

    return (
        <CreateDialogMenuItem
            btn={btn}
            onClose={onClose}
            onClick={onClick}
            menuText="Ajouter une occurrence d'état de dynamisme du cadre institutionnel et organisationnel"
            dialogTitle="Création de l'occurrences d'état de dynamisme du cadre institutionnel et organisationnel"
            dialogContextText={<>{`Creation de nouvelle occurrence d'état de dynamisme du cadre institutionnel et organisationnel`}</>}
            formRender={({ onClose }) => {
                return (
                    <InstitutionalOrganizationalFrameworkStructureOccurrenceForm
                        {...{currentInstitutionalOrganizationalFrameworkStructureOccurrence, setCurrentInstitutionalOrganizationalFrameworkStructureOccurrence,
                        }}
                        fromDialog={{
                            onCancel: onClose,
                            onSave: (createdInstitutionalOrganizationalFrameworkStructureOccurrence) => {
                                onSave && onSave(createdInstitutionalOrganizationalFrameworkStructureOccurrence);
                                onClose && onClose();
                            },
                        }}
                    />
                );
            }}
        />
    );
}
