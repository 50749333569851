import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const HydroAgriculturalDevelopmentEntitiesList = Loadable(
  lazy(() => import("./pages/HydroAgriculturalDevelopmentEntitiesList"))
);
const HydroAgriculturalDevelopmentEntitiesCreate = Loadable(
  lazy(() => import("./pages/HydroAgriculturalDevelopmentEntitiesCreate"))
);
const HydroAgriculturalDevelopmentEntitiesEdit = Loadable(
  lazy(() => import("./pages/HydroAgriculturalDevelopmentEntitiesEdit"))
);
const HydroAgriculturalDevelopmentEntitiesDisplay = Loadable(
  lazy(() => import("./pages/HydroAgriculturalDevelopmentEntitiesDisplay"))
);

const hydroAgriculturalDevelopmentEntitiesRouter = [
  {
    path: "hydro-agricultural-development-entities",
    element: <HydroAgriculturalDevelopmentEntitiesList />,
  },
  {
    path: "hydro-agricultural-development-entities/:id",
    element: <HydroAgriculturalDevelopmentEntitiesDisplay />,
  },
  {
    path: "hydro-agricultural-development-entities/new",
    element: <HydroAgriculturalDevelopmentEntitiesCreate />,
  },
  {
    path: "hydro-agricultural-development-entities/:id/edit",
    element: <HydroAgriculturalDevelopmentEntitiesEdit />,
  },
];

export default hydroAgriculturalDevelopmentEntitiesRouter;
