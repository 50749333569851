import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const InstitutionalOrganizationalFrameworkStructureList = Loadable(lazy(() => import("./pages/InstitutionalOrganizationalFrameworkStructureList")));
const InstitutionalOrganizationalFrameworkStructureCreate = Loadable(lazy(() => import("./pages/InstitutionalOrganizationalFrameworkStructureCreate")));
const InstitutionalOrganizationalFrameworkStructureEdit = Loadable(lazy(() => import("./pages/InstitutionalOrganizationalFrameworkStructureEdit")));
const InstitutionalOrganizationalFrameworkStructureDisplay = Loadable(lazy(() => import("./pages/InstitutionalOrganizationalFrameworkStructureDisplay")));

const institutionalOrganizationalFrameworkStructureRouter = [
  { path: "institutional-organizational-framework-structures", element: <InstitutionalOrganizationalFrameworkStructureList /> },
  { path: "institutional-organizational-framework-structures/:id", element: <InstitutionalOrganizationalFrameworkStructureDisplay /> },
  { path: "institutional-organizational-framework-structures/new", element: <InstitutionalOrganizationalFrameworkStructureCreate /> },
  { path: "institutional-organizational-framework-structures/:id/edit", element: <InstitutionalOrganizationalFrameworkStructureEdit /> },
];

export default institutionalOrganizationalFrameworkStructureRouter;
