import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';

const OccurrenceNeedWithdrawalList = Loadable(lazy(() => import('./pages/OccurrenceNeedWithdrawalList')));
const OccurrenceNeedWithdrawalCreate = Loadable(lazy(() => import('./pages/OccurrenceNeedWithdrawalCreate')));
const OccurrenceNeedWithdrawalEdit = Loadable(lazy(() => import('./pages/OccurrenceNeedWithdrawalEdit')));
const OccurrenceNeedWithdrawalDisplay = Loadable(lazy(() => import('./pages/OccurrenceNeedWithdrawalDisplay')));

const occurrenceNeedWithdrawalRouter = [
    {path: 'occurrence-need-withdrawals', element: <OccurrenceNeedWithdrawalList/>},
    {path: 'occurrence-need-withdrawals/:id', element: <OccurrenceNeedWithdrawalDisplay/>},
    {path: 'occurrence-need-withdrawals/new', element: <OccurrenceNeedWithdrawalCreate/>},
    {path: 'occurrence-need-withdrawals/:id/edit', element: <OccurrenceNeedWithdrawalEdit/>},
];
export default occurrenceNeedWithdrawalRouter;
