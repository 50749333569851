import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const OccurrenceCompositionList = Loadable(lazy(() => import('./pages/OccurrenceCompositionList')));
const OccurrenceCompositionCreate = Loadable(lazy(() => import('./pages/OccurrenceCompositionCreate')));
const OccurrenceCompositionEdit = Loadable(lazy(() => import('./pages/OccurrenceCompositionEdit')));
const OccurrenceCompositionDisplay = Loadable(lazy(() => import('./pages/OccurrenceCompositionDisplay')));

const occurrenceCompositionRouter = [
    {path: 'occurrence-compositions', element: <OccurrenceCompositionList/>},
    {path: 'occurrence-compositions/:id', element: <OccurrenceCompositionDisplay/>},
    {path: 'occurrence-compositions/new', element: <OccurrenceCompositionCreate/>},
    {path: 'occurrence-compositions/:id/edit', element: <OccurrenceCompositionEdit/>},
];

export default occurrenceCompositionRouter;
