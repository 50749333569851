import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import MainLayout from "../../home/AppLayout";
import Loadable from "../../shared/components/Loadable";

import { PATH_ACTORS } from "./actors.path";
import actorRouter from "./actor/actor.router";
import typeActorRouter from "./type_actor/type.acteur.router";
import hierarchyRouter from "./hierarchy/hierarchy.router";
import interventionTypeRouter from "./intervention_type/intervention.type.router";
import activityTypeRouter from "./activity_type/activity.type.router";
import typeCommunicationMethodRouter from "./type_communication_method/type.communication.method.router";
import communicationDirectoryRouter from "./communication_directory/communication.directory.router";
import communicationMethodCategoryRouter from "./communication_method_category/communication.method.category.router";
import competencyRouter from "./competency/competency.router";
import responsibilityRouter from "./responsibility/responsibility.router";
import structureRouter from "./structure/structure.router";

//Features

const ActorsHome = Loadable(lazy(() => import("./ActorsHome")));

const actorsRouter = [
  {
    path: PATH_ACTORS.root,
    element: <MainLayout />,
    children: [
      {
        path: PATH_ACTORS.root,
        element: <Navigate to={PATH_ACTORS.home} replace />,
      },
      { path: "home", element: <ActorsHome /> },
      ...actorRouter,
      ...typeActorRouter,
      ...hierarchyRouter,
      ...competencyRouter,
      ...interventionTypeRouter,
      ...activityTypeRouter,
      ...typeCommunicationMethodRouter,
      ...communicationMethodCategoryRouter,
      ...communicationDirectoryRouter,
      ...responsibilityRouter,
      ...structureRouter,
    ],
  },
];

export default actorsRouter;
