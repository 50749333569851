import {useEffect, useState} from 'react';
import {gql, useQuery, useLazyQuery} from '@apollo/client';
import {parseCount, parseEntity} from '../../../../utils/gql.utils';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {usePublicApollo} from "../../../../shared/hooks/usePublicApollo";

export let needRefresh = false;
export const setRefresh = (value) => needRefresh = value;

const waterMassFields = `
    id wording description waterMassSet waterMassSetObj {id wording} waterType waterTypeObj {id wording}
`;

///////////////////// Find By ID /////////////////////
const GET_WATER_MASS_BY_ID = gql`
    query WaterMassById($id: Int!) {
        waterMassById(id: $id) {
            error message entity { ${waterMassFields} }
        }
    }
`;

export const useWaterMassById = (id) => {
    id = parseInt(id);
    return useQuery(GET_WATER_MASS_BY_ID, {
        variables: {id},
        fetchPolicy: 'network-only',
    });
}

export const useWaterMass = (id) => {
    const result = useWaterMassById(id);
    return parseEntity(result, 'waterMassById');
}

///////////////////// Search /////////////////////
const SEARCH_WATER_MASSES = gql`
    query SearchWaterMasses($query: String, $waterMassSet: Int, $waterType: Int, $page: Int, $size: Int) {
        searchWaterMasses(query: $query, waterMassSet: $waterMassSet, waterType: $waterType, page: $page, size: $size) {
            error message page {
                content { ${waterMassFields} }
                page hasNext hasPrevious totalPages totalElements numberOfElements
            }
        }
    }
`;

export const useSearchWaterMasses = ({query, waterMassSet, waterType, page = 0, size = 20}) => {
    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_WATER_MASSES, {
        variables: {query, waterMassSet, waterType, page, size},
    });

    const [waterMasses, setWaterMasses] = useState([]);
    const [waterMassesPage, setWaterMassesPage] = useState(null);

    const {showError} = useMessage();

    useEffect(() => {
        if(_error) {
            showError('Erreur lors de l\'operation');
            setWaterMassesPage(null);
            setWaterMasses([]);
            return;
        }

        if(!searchData) return;

        const {error, message, page} = searchData.searchWaterMasses;

        if (error) {
            showError(message);
            setWaterMassesPage(null);
            setWaterMasses([]);
        } else {
            setWaterMasses(page.content);
            setWaterMassesPage(page);
        }

        if(needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh, refetch, showError]);

    useEffect(() => {
        search({ variables: { query, waterMassSet, waterType, page, size } });
    }, [query, waterMassSet, waterType, page, size]);

    return {
        waterMasses, loading, search, refetch, waterMassesPage
    }
}

export const usePublicSearchWaterMasses = ({query, waterMassSet, waterType, page = 0, size = 20}) => {
    const pClient = usePublicApollo();

    console.log('###### pClient: ', pClient);

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_WATER_MASSES, {
        variables: {query, waterMassSet, waterType, page, size},
        client: pClient
    });

    const [waterMasses, setWaterMasses] = useState([]);
    const [waterMassesPage, setWaterMassesPage] = useState(null);

    const {showError} = useMessage();

    useEffect(() => {
        if(_error) {
            showError('Erreur lors de l\'operation');
            setWaterMassesPage(null);
            setWaterMasses([]);
            return;
        }

        if(!searchData) return;

        const {error, message, page} = searchData.searchWaterMasses;

        if (error) {
            showError(message);
            setWaterMassesPage(null);
            setWaterMasses([]);
        } else {
            setWaterMasses(page.content);
            setWaterMassesPage(page);
        }

        if(needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh, refetch, showError]);

    useEffect(() => {
        search({ variables: { query, waterMassSet, waterType, page, size } });
    }, [query, waterMassSet, waterType, page, size]);

    return {
        waterMasses, loading, search, refetch, waterMassesPage
    }
}

///////////////////// Count /////////////////////
const COUNT_WATER_MASSES = gql`
    query CountWaterMasses {
        countWaterMasses {
            error message count
        }
    }
`;

export const useCountWaterMasses = () => {
    const result = useQuery(COUNT_WATER_MASSES, {
        fetchPolicy: 'network-only'
    });
    return parseCount(result, 'countWaterMasses');
}
