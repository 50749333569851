import React from 'react';
// material
import MergeTypeIcon from "@mui/icons-material/MergeType";
// utils
import {PATH_RISK_PROTECTION} from '../../risk.protection.path';
import {useCountAmenagementTypes} from '../services/amenagement.type.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AmenagementTypeEntryButton() {
    const {count} = useCountAmenagementTypes();
  return (
      <SubModuleEntryButton
          label="Type d'aménagement"
          value={count}
          url={PATH_RISK_PROTECTION.amenagementTypes}
          icon={<MergeTypeIcon />}
      />
  );
}
