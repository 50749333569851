import React, {useContext} from 'react';
import {Link as RouterLink} from "react-router-dom";
import {DesktopDatePicker, LoadingButton} from '@mui/lab';
import {
    Autocomplete, Box, Button, Card, CircularProgress, FormControl, FormControlLabel,
    IconButton, InputAdornment, InputLabel, Link, Select, Stack, Switch, TextField, Tooltip, Typography,
} from "@mui/material";
import {ArrowForward, Close} from "@mui/icons-material";
import {Form, FormikContext, FormikProvider} from "formik";

export function FormikForm({formik, children}) {
    const {handleSubmit} = formik;
    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                {children}
            </Form>
        </FormikProvider>
    )
}

export function SimpleFormikForm({formik, onCancel, isEdit, children}) {
    return (
        <FormikForm formik={formik}>
            <Card sx={{p: 3}}>
                <Stack spacing={3}>
                    {children}
                    <SubmitCancelGroupButton {...{onCancel, isEdit}}/>
                </Stack>
            </Card>
        </FormikForm>
    );
}

export function FormTitleAndCloseButton(
    {
        title, subTitle, onCLose,
        TitleProps = {},
        SubTitleProps = {},
        ...others
    }
) {
    const titleProps = {...{variant: "h4"}, ...TitleProps}
    const subTitleProps = {...{variant: "h4"}, ...SubTitleProps}
    return (
        <Box display={"flex"} alignItems={"start"} mb={3} {...others}>
            <Stack flexGrow={1} spacing={1}>
                <Typography {...titleProps}>{title}</Typography>
                <Typography {...subTitleProps}>{subTitle}</Typography>
            </Stack>

            <Tooltip title={"Fermer"}><IconButton onClick={onCLose}><Close/></IconButton></Tooltip>
        </Box>
    );
}

export function SubmitCancelGroupButton({isEdit, createLabel, editLabel, cancelLabel, onCancel}) {
    const {isSubmitting} = useContext(FormikContext);
    return (
        <Box sx={{mt: 10, display: "flex", justifyContent: "flex-end"}}>
            <Button
                sx={{mr: 2}}
                size="medium"
                onClick={onCancel}
                disabled={isSubmitting}>
                {cancelLabel ?? "Annuler"}
            </Button>
            <LoadingButton
                type="submit"
                variant="contained"
                size="medium"
                loading={isSubmitting}
            >
                {!isEdit ? createLabel ?? "Créer" : editLabel ?? "Modifier"}
            </LoadingButton>
        </Box>
    );
}

export function SubSectionTitle({title, action, paddingTop, TypographyProps = {}, children, ...others}) {
    return (
        <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            sx={{pt: paddingTop ?? 4}}
            {...others}
        >
            <Typography variant="h4" {...TypographyProps}>
                {title}
            </Typography>

            {action}
        </Box>
    );
}

export function FormikSwitch({name, ...others}) {
    const {getFieldProps, values} = useContext(FormikContext);

    return (
        <FormControlLabel
            sx={{mb: 2}}
            control={<Switch {...getFieldProps(name)} checked={values[name]}/>}
            {...others}
        />
    );
}

export function FormikTextField({name, ...others}) {
    const {getFieldProps, touched, errors} = useContext(FormikContext);
    const errorMessage = touched[name] && errors[name];

    return (
        <TextField
            fullWidth
            {...getFieldProps(name)}
            helperText={errorMessage}
            error={Boolean(errorMessage)}
            {...others}
        />
    );
}

export function FormikDatePicker({name, ...others}) {
    const {getFieldProps, setFieldValue, touched, errors} = useContext(FormikContext);
    const errorMessage = touched[name] && errors[name];

    return (
        <DesktopDatePicker
            {...getFieldProps(name)}
            inputFormat='dd/MM/yyyy'
            onChange={(newValue) => setFieldValue(name, newValue)}
            renderInput={(params) => (
                <TextField fullWidth {...params} helperText={errorMessage} error={Boolean(errorMessage)}/>
            )}
            {...others}
        />
    )
}

export function FormikSelectField({name, label, children, ...others}) {
    const {getFieldProps, touched, errors} = useContext(FormikContext);
    const errorMessage = touched[name] && errors[name];

    return (
        <FormControl fullWidth>
            <InputLabel id={name}>{label}</InputLabel>
            <Select
                label={label}
                labelId={name}
                placeholder={label}
                {...getFieldProps(name)}
                error={Boolean(errorMessage)}
                helperText={errorMessage}
                {...others}
            >
                {children}
            </Select>
        </FormControl>
    );
}

export function FormikAutocomplete(
    {
        name,
        label,
        idField = "id",
        labelField = "name",
        embeddedObjField,
        options,
        query,
        setQuery,
        loading,
        InputProps,
        ...others
    }
) {
    const {values, setFieldValue, touched, errors} = useContext(FormikContext);
    const errorMessage = touched[name] && errors[name];
    options = [
        {[idField]: null, [labelField]: `Selectionner - ${label}`},
        ...options,
    ];

    const optionsIds = options.map((option) => option[idField]);

    const id = values[name];
    const includesId = optionsIds.includes(id);

    return (
        <Autocomplete
            fullWidth
            name={name}
            autoHighlight
            options={options}
            inputValue={query}
            value={{[idField]: values[name] ?? null}}
            loadingText={<CircularProgress/>}
            isOptionEqualToValue={(option, value) => {
                const optionId = option && option[idField];
                const valueId = value && value[idField];

                if (!optionId && !valueId) return true;

                if (loading && !optionId) return true;

                if (!includesId && !optionId) return true;

                return optionId === valueId;
            }}
            onChange={(event, selected) => {
                const selectedId = selected && selected[idField];
                if (id && !includesId) {
                    console.log(`####@@@@ Not set`);
                } else {
                    console.log(`####@@@@ Set`);
                }

                embeddedObjField && (values[embeddedObjField] = selected);
                setFieldValue(name, selectedId);
                setQuery && setQuery((selected && selected[labelField]) ?? "");
            }}
            getOptionLabel={(option) => {
                const _option = options?.find((op) => {
                    const optionId = option && option[idField];
                    const opId = op && op[idField];

                    // console.log(`optionId:${optionId}, opId:${opId}`);

                    if (!optionId && !opId) return true;

                    return optionId === opId;
                });
                // console.log(`_option: `, _option);
                const label = (_option && _option[labelField]) ?? "";
                // console.log(`label: `, label);

                return label;
            }}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        label={label}
                        helperText={errorMessage}
                        error={Boolean(errorMessage)}
                        onChange={({target: {value}}) => setQuery(value)}
                        {...InputProps}
                    />
                );
            }}
            {...others}
        />
    );
}

export function FormDisplayField({label = '', value = '', ...others}) {
    return (
        <TextField
            fullWidth
            label={label}
            value={value}
            {...others}
            InputProps={{readOnly: true}}
        />
    );
}

export function FormDisplayLinkField({label = '', value = '', to = '#', ...others}) {
    return (
        <TextField
            fullWidth
            label={label}
            value={value}
            {...others}
            InputProps={{
                readOnly: true,
                endAdornment: (
                    <InputAdornment position='end'>
                        <Link to={to} component={RouterLink}>
                            <Box component={ArrowForward} sx={{width: 28, height: 28}}/>
                        </Link>
                    </InputAdornment>
                )
            }}
        />
    );
}

export function FormRowStack({children, ...others}) {
    return (
        <Stack
            direction={{xs: "column", sm: "row"}}
            spacing={{xs: 3, sm: 2}}
            {...others}
        >
            {children}
        </Stack>
    );
}
