import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
// material
import {alpha} from '@mui/material/styles';
import {
    Box, Card, Typography, Stack, Button, Grid, OutlinedInput, InputAdornment, useTheme,
} from '@mui/material';
// utils
import {showEntry} from '../hooks/useModule';
import {fNumber} from '../../utils/formatNumber';
import {entryButtonHidden, entryButtonHover, entryButtonShow} from '../../utils/animation.utils';

import {motion} from 'framer-motion';
import {Search, Settings} from "@mui/icons-material";

// ----------------------------------------------------------------------

export const IconWrapperStyle = ({color, icon}) => {
    const theme = useTheme();
    color = color ?? theme.palette.success.main;

    return (
        <Box sx={{
            width: 45,
            height: 45,
            display: 'flex',
            borderRadius: '50%',
            alignItems: 'center',
            justifyContent: 'center',
            color: color,
            backgroundColor: alpha(color, 0.16)
        }}>
            {icon ?? <Settings />}
        </Box>
    )
}

// ----------------------------------------------------------------------

export const entryButtonVariants = {
    hidden: entryButtonHidden,
    animate: entryButtonShow,
    hover: entryButtonHover,
}

export default function SubModuleEntryButton({label, icon, value, url, iconColor}) {

    return (
        <Card sx={{display: 'flex', alignItems: 'center', p: 3, height: "100%", m: 0}}>
            <Box sx={{flexGrow: 1}}>
                <Typography variant='subtitle2'>{label ?? 'Entite'}</Typography>
                <Stack direction='row' alignItems="center" spacing={1} sx={{mt: 2, mb: 1}}>
                    <IconWrapperStyle icon={icon} color={iconColor}/>
                    <Typography variant="h3">
                        {value === undefined ? '...' : fNumber(value)}
                    </Typography>
                </Stack>

                <Box sx={{mt: 3, display: 'flex', justifyContent: 'flex-end'}}>
                    <Button component={RouterLink} to={`${url ?? ''}/new`}>
                        Nouveau
                    </Button>
                    <Button component={RouterLink} to={url ?? '/'}>
                        Liste
                    </Button>
                </Box>
            </Box>

        </Card>
    );
}

export const SubModuleEntryButtonFilter = ({query, setQuery}) => {
    return (
        <Grid item xs={12}>
            <Stack alignItems='start'>
                <OutlinedInput
                    sx={{width: 340}}
                    value={query}
                    onChange={({target: {value}}) => setQuery(value.toLowerCase())}
                    placeholder='Recherche'
                    startAdornment={
                        <InputAdornment position="start">
                            <Search sx={{color: 'text.disabled'}}/>
                        </InputAdornment>
                    }
                />
            </Stack>
        </Grid>
    );
}

export const SubModuleEntryButtonWrapper = ({keys, query, xs, md, children}) => {
    const show = showEntry(keys, query);

    if (!show) return (<></>);

    return (
        <Grid item xs={xs ?? 12} md={md ?? 4}>
            <motion.div variants={entryButtonVariants} initial={'hidden'} animate={'animate'} whileHover={'hover'}>
                {children}
            </motion.div>
        </Grid>
    );
}
