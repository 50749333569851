import React from 'react';
// material
import WavesIcon from '@mui/icons-material/Waves';
// utils
import {PATH_WATER_USE} from '../../water.use.path';
import {useCountWaterMasses} from '../services/water.mass.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';

export default function WaterMassEntryButton() {

    const {count} = useCountWaterMasses();

    return (
        <SubModuleEntryButton
            label="Mass d'eau"
            value={count}
            url={PATH_WATER_USE.water_mass}
            icon={<WavesIcon/>}
        />
    );
}
