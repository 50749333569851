import React from 'react';
import PropTypes from 'prop-types';
// material
import {styled} from '@mui/material/styles';
import {Typography, Box, Card, CardContent, useTheme} from '@mui/material';

import {motion} from 'framer-motion';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({theme}) => ({
    boxShadow: 'none',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
        height: '100%',
        display: 'flex',
        textAlign: 'left',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}));

// ----------------------------------------------------------------------

ModuleWelcome.propTypes = {
    displayName: PropTypes.string,
    description: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.node,
};

export default function ModuleWelcome({displayName, description, color, icon}) {
    const theme = useTheme();
    const backgroundColor = color ?? theme.palette.primary.lighter;

    return (
        <RootStyle sx={{backgroundColor}}>
            <CardContent sx={{p: {md: 5, xs: 2}}}>
                <Typography gutterBottom variant="h4">
                    {!displayName ? '...' : displayName}!
                </Typography>

                <Typography variant="body2" sx={{pb: {xs: 3, xl: 5}, maxWidth: 480, mx: 'auto'}}>
                    {description}
                </Typography>

            </CardContent>

            <Box
                sx={{
                    p: 3,
                    width: 200,
                    margin: {xs: 'auto', md: 'inherit'}
                }}
            >
                <motion.div
                    initial={{scale: .2}}
                    animate={{scale: 1}}
                    transition={{type: 'spring', stiffness: 220}}
                    whileHover={{scale: 1.2}}
                >
                    {icon}
                </motion.div>


            </Box>
        </RootStyle>
    );
}
