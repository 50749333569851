import React, {lazy} from 'react';
import InstitutionalOrganizationalFrameworkStructureTypeList from "./pages/InstitutionalOrganizationalFrameworkStructureTypeList";
import InstitutionalOrganizationalFrameworkStructureTypeDisplay from "./pages/InstitutionalOrganizationalFrameworkStructureTypeDisplay";
import InstitutionalOrganizationalFrameworkStructureTypeCreate from "./pages/InstitutionalOrganizationalFrameworkStructureTypeCreate";
import InstitutionalOrganizationalFrameworkStructureTypeEdit from "./pages/InstitutionalOrganizationalFrameworkStructureTypeEdit";

const institutionalOrganizationalFrameworkStructureTypeRouter = [
    {path: 'institutional-organizational-framework-structure-types', element: <InstitutionalOrganizationalFrameworkStructureTypeList/>},
    {path: 'institutional-organizational-framework-structure-types/:id', element: <InstitutionalOrganizationalFrameworkStructureTypeDisplay/>},
    {path: 'institutional-organizational-framework-structure-types/new', element: <InstitutionalOrganizationalFrameworkStructureTypeCreate/>},
    {path: 'institutional-organizational-framework-structure-types/:id/edit', element: <InstitutionalOrganizationalFrameworkStructureTypeEdit/>},

];
export default institutionalOrganizationalFrameworkStructureTypeRouter;
