import React from 'react';
// material
import MergeTypeIcon from '@mui/icons-material/MergeType';
// utils
import {PATH_ORGAN_INSTRUMENT} from "../../organ.instrument.path";
import {useCountTypeSpheres} from '../services/type.sphere.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function TypeSphereEntryButton() {
    const {count} = useCountTypeSpheres();
  return (
      <SubModuleEntryButton
          label="Types des sphères d'action"
          value={count}
          url={PATH_ORGAN_INSTRUMENT.typeSpheres}
          icon={<MergeTypeIcon />}
      />
  );
}
