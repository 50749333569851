import React from 'react';
// components
import {InstrumentMechanismParticipationTypeForm} from '../components';
import CreateDialogMenuItem from '../../../../shared/components/CreateDialogMenuItem';
import {useInstrumentMechanismParticipationTypeStore} from "../services/instrument.mechanism.participation.type.store";

export function InstrumentMechanismParticipationTypeCreateDialogMenuItem({onClose, onSave, btn}) {
    const {currentNewInstrumentMechanismParticipationType: currentInstrumentMechanismParticipationType, setCurrentNewInstrumentMechanismParticipationType: setCurrentInstrumentMechanismParticipationType} = useInstrumentMechanismParticipationTypeStore();
    return (
        <CreateDialogMenuItem
            btn={btn}
            onClose={onClose}
            menuText="Ajouter un type d'instruments et mécanismes de participation à la GIRE"
            dialogTitle="Création de type d'instruments et mécanismes de participation à la GIRE"
            dialogContextText={<> {`Création de nouveau type d'instruments de politique et stratégie`} </>}
            formRender={({onClose}) => {
                return (
                    <InstrumentMechanismParticipationTypeForm
                        {...{currentInstrumentMechanismParticipationType, setCurrentInstrumentMechanismParticipationType}}
                        fromDialog={{
                            onCancel: onClose,
                            onSave: (createdInstrumentMechanismParticipationType) => {
                                onSave && onSave(createdInstrumentMechanismParticipationType);
                                onClose && onClose();
                            }
                        }}/>
                );
            }}
        />
    );

}
