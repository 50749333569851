import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { parseCount, parseEntity } from "../../../../utils/gql.utils";
import { useEffect, useState } from "react";
import { useMessage } from "../../../../shared/hooks/useMessage";

export let needRefresh = false;
export const setRefresh = (value) => (needRefresh = value);

export const healthAreaFields = `
    id name  dataSource description waterMass waterMassObj{id wording}`;

///////////////////// Find By ID /////////////////////
const GET_BASIN_BY_ID = gql`
    query HealthAreaById($id: Int!) {
        healthAreaById(id: $id) {
            error message entity { ${healthAreaFields} }
        }
    }
`;

export const useHealthAreaById = (id) => {
  id = parseInt(id);
  return useQuery(GET_BASIN_BY_ID, {
    variables: { id },
    fetchPolicy: "network-only",
  });
};

export const useHealthArea = (id) => {
  const result = useHealthAreaById(id);
  return parseEntity(result, "healthAreaById");
};
///////////////////// Search /////////////////////
const SEARCH_HEALTH_AREA = gql`
    query SearchHealthAreas($query: String,$waterMass: Int, $page: Int, $size: Int) {
        searchHealthAreas(query: $query,waterMass:$waterMass, page: $page, size: $size) {
            error message page {
                content { ${healthAreaFields} }
                page hasNext hasPrevious totalPages totalElements numberOfElements
            }
        }
    }
`;

export const useSearchHealthAreas = ({ query = "", waterMass, page = 0, size = 20 }) => {
  const [healthAreas, setHealthAreas] = useState([]);
  const [healthAreaPage, setHealthAreaPage] = useState(null);

  const { showError } = useMessage();

  const [search, { data: searchData, refetch, loading, error: _error }] =
    useLazyQuery(SEARCH_HEALTH_AREA, {
      variables: { query,waterMass, page, size },
    });

  useEffect(() => {
    if (_error) {
      showError("Erreur lors de l'operation");
      setHealthAreaPage(null);
      setHealthAreas([]);
      return;
    }

    if (!searchData) return;

    const { error, message, page } = searchData.searchHealthAreas;

    if (error) {
      showError(message);
      setHealthAreaPage(null);
      setHealthAreas([]);
    } else {
      setHealthAreaPage(page);
      setHealthAreas(page.content);
    }

    if (needRefresh) {
      refetch();
      setRefresh(false);
    }
  }, [_error, searchData, needRefresh]);

  useEffect(() => {
    search({ variables: { query,waterMass, page, size } });
  }, [query,waterMass, page, size]);

  return {
    healthAreas,
    loading,
    search,
    refetch,
    healthAreaPage,
  };
};

///////////////////// Count /////////////////////
const COUNT_HEALTH_AREAS = gql`
  query CountHealthAreas {
    countHealthAreas {
      error
      message
      count
    }
  }
`;

export const useCountHealthAreas = () => {
  const result = useQuery(COUNT_HEALTH_AREAS, {
    fetchPolicy: "network-only",
  });
  return parseCount(result, "countHealthAreas");
};
