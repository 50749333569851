import React from 'react';
import {
    Avatar, Box, CircularProgress, Divider, List, ListItem,
    ListItemAvatar, ListItemText, Paper, Typography
} from '@mui/material';
import {AddDashboardWidgetDialog, DashboardWidgetMoreMenu} from './index';

import {useSearchDashboardWidgetsByDashboard} from '../services/dashboard.widget.gql';
import AlertDialog, {useDeleteDialog} from '../../../../../shared/components/AlertDialog';
import useDashboardWidgetService from '../services/dashboard.widget.services';
import {useMessage} from '../../../../../shared/hooks/useMessage';


export default function DashboardWidgetList({dashboard}) {
    dashboard = parseInt(dashboard);

    const {destroy} = useDashboardWidgetService();
    const {showSuccess, showError} = useMessage();
    const {dashboardWidgets, refetch, loading} = useSearchDashboardWidgetsByDashboard({dashboard});

    const {deleteConfirmOpen, setDeleteConfirmOpen, idToDelete, handleDelete} = useDeleteDialog();

    const handleDeleteConfirm = () => {
        destroy(idToDelete)
            .then(_ => {
                showSuccess(`Widget supprimé avec succès`);
                refetch();
            })
            .catch(_ => {
                showError(`Erreur lors de la suppression du widget`);
            });
    }

    return (
        <Paper sx={{flexGrow: 1}} variant='outlined'>
            <Box
                sx={{
                    display: 'flex', px: 2, pt: 1,
                    justifyContent: 'space-between', alignItems: 'center'
                }}
            >
                <Typography>
                    Les widgets
                </Typography>

                <AddDashboardWidgetDialog dashboard={dashboard} onAdd={refetch} onCancel={()=>{}}/>
            </Box>
            <Divider/>

            {loading && <CircularProgress/>}
            {
                !loading && dashboardWidgets &&
                <List>
                    {dashboardWidgets.map((dashboardWidget) => (
                        <ListItem key={dashboardWidget.id} secondaryAction={
                            <DashboardWidgetMoreMenu
                                dashboard={dashboard}
                                dashboardWidget={dashboardWidget}
                                onDelete={() => handleDelete(dashboardWidget.id)}
                                onUpdate={() => refetch()}
                            />
                        }>
                            <ListItemAvatar>
                                <Avatar>
                                    {`${dashboardWidget.position ?? 'ND'}`}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={dashboardWidget.widgetTitle} secondary={`${dashboardWidget.dashboardObj?.title ?? ''}`}/>
                        </ListItem>
                    ))}
                </List>
            }

            <AlertDialog
                message={'Voulez-vous vraiment supprimer ce widget ?'}
                open={deleteConfirmOpen}
                onYes={() => {
                    setDeleteConfirmOpen(false);
                    handleDeleteConfirm();
                }}
                onClose={() => setDeleteConfirmOpen(false)}
                onNo={() => setDeleteConfirmOpen(false)}
            />

        </Paper>
    );
}

