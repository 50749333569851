import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const HydrologicalUnitList = Loadable(
  lazy(() => import("./pages/HydrologicalUnitList"))
);
const HydrologicalUnitCreate = Loadable(
  lazy(() => import("./pages/HydrologicalUnitCreate"))
);
const HydrologicalUnitEdit = Loadable(
  lazy(() => import("./pages/HydrologicalUnitEdit"))
);
const HydrologicalUnitDisplay = Loadable(
  lazy(() => import("./pages/HydrologicalUnitDisplay"))
);

const hydrologicalUnitRouter = [
  { path: "hydrological-units", element: <HydrologicalUnitList /> },
  { path: "hydrological-units/:id", element: <HydrologicalUnitDisplay /> },
  { path: "hydrological-units/new", element: <HydrologicalUnitCreate /> },
  { path: "hydrological-units/:id/edit", element: <HydrologicalUnitEdit /> },
];

export default hydrologicalUnitRouter;
