import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const ObservationStationFunctionalityOccurrencesList = Loadable(
  lazy(() => import("./pages/ObservationStationFunctionalityOccurrencesList"))
);
const ObservationStationFunctionalityOccurrencesCreate = Loadable(
  lazy(() => import("./pages/ObservationStationFunctionalityOccurrencesCreate"))
);
const ObservationStationFunctionalityOccurrencesEdit = Loadable(
  lazy(() => import("./pages/ObservationStationFunctionalityOccurrencesEdit"))
);
const ObservationStationFunctionalityOccurrencesDisplay = Loadable(
  lazy(() => import("./pages/ObservationStationFunctionalityOccurrencesDisplay"))
);

const observationStationFunctionalityOccurrencesRouter = [
  {
    path: "observation-station-functionality-occurrences",
    element: <ObservationStationFunctionalityOccurrencesList />,
  },
  {
    path: "observation-station-functionality-occurrences/:id",
    element: <ObservationStationFunctionalityOccurrencesDisplay />,
  },
  {
    path: "observation-station-functionality-occurrences/new",
    element: <ObservationStationFunctionalityOccurrencesCreate />,
  },
  {
    path: "observation-station-functionality-occurrences/:id/edit",
    element: <ObservationStationFunctionalityOccurrencesEdit />,
  },
];

export default observationStationFunctionalityOccurrencesRouter;
