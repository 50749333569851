import React, {useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
// material
import {CircularProgress, Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {LegislativeTextTypeForm} from '../components';

import {LegislativeTextTypeHeaderBreadcrumbs} from '../components';

import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useLegislativeTextType} from '../services/legislative.text.type.gql';
import {useLegislativeTextTypeStore} from "../services/legislative.text.type.store";

export default function LegislativeTextTypeEdit() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {showError} = useMessage();

    const {setCurrentUpdateLegislativeTextType: setCurrentLegislativeTextType} = useLegislativeTextTypeStore();

    const {entity: currentLegislativeTextType, error, loading, errorMessage} = useLegislativeTextType(id);

    useEffect(() => {
        if (loading) return;
        if (error) {
            showError(errorMessage);
            navigate(PATH_GIRE_ENV_FAVORABLE.legislativeTextTypes);
        }
    }, [loading, error, errorMessage]);

    useEffect(() => {
        setCurrentLegislativeTextType(currentLegislativeTextType);
    }, [currentLegislativeTextType]);

    return (
        <Page title={"Gire environnement favorable: Modification types textes de cadre législatif et reglementaire eau"}>
            <Container maxWidth={'xl'}>
                <LegislativeTextTypeHeaderBreadcrumbs
                    heading="Modification du typetextes de cadre législatif et reglementaire eau"
                    lastName={`Modification types textes de cadre législatif et reglementaire eau - ${id}`}

                />

                {loading && <CircularProgress/>}
                {!loading && currentLegislativeTextType && <LegislativeTextTypeForm isEdit {...{currentLegislativeTextType, setCurrentLegislativeTextType}}/>}
            </Container>
        </Page>
    );
}
