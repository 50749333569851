import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';

const OccurrenceWaterRequestList = Loadable(lazy(() => import('./pages/OccurrenceWaterRequestList')));
const OccurrenceWaterRequestCreate = Loadable(lazy(() => import('./pages/OccurrenceWaterRequestCreate')));
const OccurrenceWaterRequestEdit = Loadable(lazy(() => import('./pages/OccurrenceWaterRequestEdit')));
const OccurrenceWaterRequestDisplay = Loadable(lazy(() => import('./pages/OccurrenceWaterRequestDisplay')));

const occurrenceWaterRequestRouter = [
    {path: 'occurrence-water-requests', element: <OccurrenceWaterRequestList/>},
    {path: 'occurrence-water-requests/:id', element: <OccurrenceWaterRequestDisplay/>},
    {path: 'occurrence-water-requests/new', element: <OccurrenceWaterRequestCreate/>},
    {path: 'occurrence-water-requests/:id/edit', element: <OccurrenceWaterRequestEdit/>},
];
export default occurrenceWaterRequestRouter;
