import React from 'react';
// material
import {Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {AmenagementTypeForm} from '../components';

import {AmenagementTypeHeaderBreadcrumbs} from '../components';
import {useAmenagementTypeStore} from "../services/amenagement.type.store";

// ----------------------------------------------------------------------

export default function AmenagementTypeCreate() {
    const {currentNewAmenagementType: currentAmenagementType, setCurrentNewAmenagementType: setCurrentAmenagementType} = useAmenagementTypeStore();
    return (
        <Page title="Aménagement et protection en RE: Création type d'aménagement">
            <Container  maxWidth={'xl'}>
                <AmenagementTypeHeaderBreadcrumbs
                    heading="Création de nouveau ype d'aménagement"
                    lastName="Nouveau type d'aménagement"
                />
                <AmenagementTypeForm {...{currentAmenagementType, setCurrentAmenagementType}}/>
            </Container>
        </Page>
    );
}
