import React, {useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
// material
import {CircularProgress, Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {InstitutionalOrganizationalFrameworkStructureOccurrenceForm} from '../components';

import {InstitutionalOrganizationalFrameworkStructureOccurrenceHeaderBreadcrumbs} from '../components';

import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useInstitutionalOrganizationalFrameworkStructureOccurrence} from '../services/institutional.organizational.framework.structure.occurrence.gql';
import {useInstitutionalOrganizationalFrameworkStructureOccurrenceStore} from "../services/institutional.organizational.framework.structure.occurrence.store";

export default function InstitutionalOrganizationalFrameworkStructureOccurrenceEdit() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {showError} = useMessage();

    const {setCurrentUpdateInstitutionalOrganizationalFrameworkStructureOccurrence: setCurrentInstitutionalOrganizationalFrameworkStructureOccurrence} = useInstitutionalOrganizationalFrameworkStructureOccurrenceStore();

    const {entity: currentInstitutionalOrganizationalFrameworkStructureOccurrence, error, loading, errorMessage} = useInstitutionalOrganizationalFrameworkStructureOccurrence(id);

    useEffect(() => {
        if (loading) return;
        if (error) {
            showError(errorMessage);
            navigate(PATH_GIRE_ENV_FAVORABLE.institutionalOrganizationalFrameworkStructureOccurrences);
        }
    }, [loading, error, errorMessage]);

    useEffect(() => {
        setCurrentInstitutionalOrganizationalFrameworkStructureOccurrence(currentInstitutionalOrganizationalFrameworkStructureOccurrence);
    }, [currentInstitutionalOrganizationalFrameworkStructureOccurrence]);

    return (
        <Page title={"Gire environment favorable: Modification occurrences d'état de dynamisme du cadre institutionnel et organisationnel"}>
            <Container maxWidth={'xl'}>
                <InstitutionalOrganizationalFrameworkStructureOccurrenceHeaderBreadcrumbs
                    heading="Modification du occurrences d'état de dynamisme du cadre institutionnel et organisationnel"
                    lastName={`Modification occurrences d'état de dynamisme du cadre institutionnel et organisationnel - ${id}`}
                />
                {loading && <CircularProgress/>}
                {!loading && currentInstitutionalOrganizationalFrameworkStructureOccurrence && <InstitutionalOrganizationalFrameworkStructureOccurrenceForm isEdit {...{currentInstitutionalOrganizationalFrameworkStructureOccurrence, setCurrentInstitutionalOrganizationalFrameworkStructureOccurrence}}/>}
            </Container>
        </Page>
    );
}
