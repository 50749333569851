import {useLocation, Outlet} from 'react-router-dom';
// material

// ----------------------------------------------------------------------

export default function MainLayout() {
    const {pathname} = useLocation();
    const isHome = pathname === '/';

    return (
        <>
            <Outlet/>
        </>
    );
}
