export function Enum(EnumEntry, othersFn) {
  const keys = Object.keys(EnumEntry);
  keys.forEach((key) => (EnumEntry[key].name = key));

  othersFn = !othersFn ? {} : { ...othersFn };
  return {
    ...EnumEntry,
    ...othersFn,
    names,
    values,
    valueOf,
  };
}

function names() {
  return this.values().map((_enum) => _enum?.name);
}

function valueOf(name) {
  return this.values().find((_enum) => _enum?.name === name);
}

function values() {
  return Object.keys(this)
    .map((key) => this[key])
    .filter((_enum) => typeof _enum !== "function");
}
