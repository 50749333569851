import React from 'react';
// components
import {PaysForm} from '../components';
import CreateDialogMenuItem from '../../../../shared/components/CreateDialogMenuItem';
import {usePaysStore} from "../services/pays.store";

// ----------------------------------------------------------------------

export function PaysCreateDialogMenuItem({onClose, onSave, btn}) {
    const {currentNewPays: currentPays, setCurrentNewPays: setCurrentPays} = usePaysStore();
    return (
        <CreateDialogMenuItem
            btn={btn}
            onClose={onClose}
            menuText='Ajouter un pays'
            dialogTitle='Création de region'
            dialogContextText={<> {`Création de nouveau pays`} </>}
            formRender={({onClose}) => {
                return (
                    <PaysForm
                        {...{currentPays, setCurrentPays}}
                        fromDialog={{
                            onCancel: onClose,
                            onSave: (createdPays) => {
                                onSave && onSave(createdPays);
                                onClose && onClose();
                            }
                        }}/>
                );
            }}
        />
    );

}
