import React from 'react';
// material
import {MultilineChartOutlined} from '@mui/icons-material';
// utils
import {PATH_REPORTING} from '../../../reporting.path';
import {useCountReporting} from '../services/reporting.gql';
import SubModuleEntryButton from '../../../../../shared/components/SubModuleEntryButton';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function ReportingEntryButton() {
    const {count} = useCountReporting();
  return (
      <SubModuleEntryButton
          label={`Reporting`}
          value={count}
          url={PATH_REPORTING.reporting}
          icon={<MultilineChartOutlined />}
      />
  );
}
