import React from "react";
// material
import {} from "@mui/icons-material";
// utils
import SubModuleEntryButton from "../../../../shared/components/SubModuleEntryButton";
import { PATH_WATER_USAGE_URIGATION } from "../../water.usage.irrigation.path";
import RamenDiningIcon from '@mui/icons-material/RamenDining';
import { useCountIrrigatedDevelopmentEntityUsages } from "../services/irrigated.development.entity.usages.gql";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function IrrigatedDevelopmentEntityUsagesEntryButton() {
  const { count } = useCountIrrigatedDevelopmentEntityUsages();
  return (
    <SubModuleEntryButton
      label={
        count > 1
          ? "Entités d'aménagements irrigués"
          : "Entité d'aménagement irrigué"
      }
      value={count}
      url={PATH_WATER_USAGE_URIGATION.irrigatedDevelopmentEntityUsages}
      icon={<RamenDiningIcon />}
    />
  );
}
