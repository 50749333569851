import * as Yup from 'yup';
import create from 'zustand';


export const CommuneSchema = Yup.object().shape({
    name: Yup.string().default('').required('Le nom est obligatoire'),
    statusCommune: Yup.number(),
    // statusCommune: Yup.number().required('Le statut de la commune est obligatoire'),
    circle: Yup.number().default(null).required('Le cercle est obligatoire'),
});

const initialCommune = CommuneSchema.getDefaultFromShape();
export const InitialCommune = () => ({...initialCommune});

const setCurrentNewCommune = set => commune => {
    set(state => ({...state, currentNewCommune: commune ?? InitialCommune()}));
}

const setCurrentUpdateCommune = set => commune => {
    set(state => ({...state, currentUpdateCommune: commune ?? InitialCommune()}));
}

export const useCommuneStore = create(set => ({
    //State
    currentNewCommune: InitialCommune(),
    currentUpdateCommune: InitialCommune(),
    //Reducers
    setCurrentNewCommune: setCurrentNewCommune(set),
    setCurrentUpdateCommune: setCurrentUpdateCommune(set),
}))
