import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {Form, FormikProvider, useFormik} from 'formik';
import {useMessage} from '../../../../shared/hooks/useMessage';
// material
import {LoadingButton} from '@mui/lab';
import {Card, Stack, Box, Button} from '@mui/material';
// routes
import {setRefresh} from '../services/sickness.type.gql';
import useSicknessTypeService from '../services/sickness.type.services';
import {formErrorMessageParser} from '../../../../utils/utils';
import {FormikTextField} from '../../../../shared/components/froms/FormComponents';
import {PATH_RISK_PROTECTION} from '../../risk.protection.path';
import {InitialSicknessType, SicknessTypeSchema} from '../services/sickness.type.store';
import {useConnectFormState} from '../../../../shared/hooks/useFormikState';
// ----------------------------------------------------------------------

SicknessTypeForm.propTypes = {
    isEdit: PropTypes.bool,
    currentSicknessType: PropTypes.object,
    fromDialog: PropTypes.object,
};

export default function SicknessTypeForm({isEdit, currentSicknessType, setCurrentSicknessType, fromDialog}) {
    const navigate = useNavigate();
    const {create, update} = useSicknessTypeService();
    const {showSuccess, showError} = useMessage();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: InitialSicknessType(),
        validationSchema: SicknessTypeSchema,
        onSubmit: async (sicknessType, {setSubmitting}) => {
            try {
                sicknessType = SicknessTypeSchema.cast(sicknessType);

                let message;
                if (!isEdit) {
                    sicknessType = await create(sicknessType);
                    message = 'Création avec succès';
                } else {
                    sicknessType = await update(currentSicknessType.id, sicknessType);
                    message = 'Modification avec succès';
                }

                setRefresh(true);

                setCurrentSicknessType();

                setSubmitting(false);
                showSuccess(message);

                if (fromDialog) {
                    fromDialog.onSave(sicknessType);
                } else {
                    navigate(`${PATH_RISK_PROTECTION.sicknessTypes}/${sicknessType.id}`);
                }
            } catch (error) {
                setSubmitting(false);
                showError(formErrorMessageParser({error, isEdit}));
            }
        }
    });

    const {values, setValues, handleSubmit, isSubmitting} = formik;

    useConnectFormState({
        formValues: values, setFormValues: setValues,
        stateValues: currentSicknessType, setStateValues: setCurrentSicknessType,
    });

    const onCancel = (_) => {
        setCurrentSicknessType();
        if (fromDialog) {
            fromDialog.onCancel();
        } else {
            navigate(PATH_RISK_PROTECTION.sicknessTypes);
        }
    }

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete='off' onSubmit={handleSubmit}>

                <Card sx={{p: 3}}>

                    <Stack spacing={3}>

                        <FormikTextField label='Nom' name='name' formik={formik}/>
                        <FormikTextField label='Source de données' name='dataSource' formik={formik}/>
                        <FormikTextField multiline minRows={2} maxRows={6}  label='Description' name='description' formik={formik}/>

                        <Box sx={{mt: 3, display: 'flex', justifyContent: 'flex-end'}}>
                            <Button
                                sx={{mr: 2}}
                                size='medium'
                                onClick={onCancel}
                                disabled={isSubmitting}
                            >
                                {'Annuler'}
                            </Button>
                            <LoadingButton type='submit' variant='contained' size='medium' loading={isSubmitting}>
                                {!isEdit ? 'Créer' : 'Modifier'}
                            </LoadingButton>
                        </Box>
                    </Stack>

                </Card>

            </Form>
        </FormikProvider>
    );
}
