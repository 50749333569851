import React from 'react';
// material
import MergeTypeIcon from '@mui/icons-material/MergeType';
// utils
import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {useCountInstrumentPolicyTypes} from '../services/instrument.policy.type.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';

export default function InstrumentPolicyTypeEntryButton() {
    const {count} = useCountInstrumentPolicyTypes();
  return (
      <SubModuleEntryButton
          label="Types d'instruments de politique et stratégie eau"
          value={count}
          url={PATH_GIRE_ENV_FAVORABLE.instrumentPolicyTypes}
          icon={<MergeTypeIcon />}
      />
  );
}
