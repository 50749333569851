import React from 'react';
import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import HeaderBreadcrumbs from "../../../../shared/components/HeaderBreadcrumbs";

const commonItems = [
    {name: 'Accueil', href: '/'},
    {name: 'Textes du cadre législatif et reglementaire eau', href: PATH_GIRE_ENV_FAVORABLE.home},
];

export function LegislativeTextHeaderBreadcrumbs ({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[
            ...commonItems,
            {name: 'Textes du cadre législatif et reglementaire eau', href: PATH_GIRE_ENV_FAVORABLE.legislativeTexts},
            {name: lastName},
        ]}
    />);
}

export function LegislativeTextListHeaderBreadcrumbs ({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[...commonItems, {name: lastName}]}
    />);
}
