import {useApiHttp} from '../../../../shared/hooks/useHttp';

const useRiskTypeService = () => {
    const {crud} = useApiHttp();

    const {
        create,
        update,
        destroy,
        findById,
        findAll,
    } = crud('risk-types');

    return {
        create,
        update,
        destroy,
        findById,
        findAll,
    }
}

export default useRiskTypeService;
