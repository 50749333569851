import React from 'react';
// material
import CropIcon from '@mui/icons-material/Crop';
// utils
import {PATH_ADMINISTRATIVE_DIVISION} from '../../administrative_division.path';
import {useCountRegions} from '../services/region.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';

// ----------------------------------------------------------------------

export default function RegionEntryButton() {
    const {count} = useCountRegions();
  return (
      <SubModuleEntryButton
          label='Regions'
          value={count}
          url={PATH_ADMINISTRATIVE_DIVISION.regions}
          icon={<CropIcon />}
      />
  );
}
