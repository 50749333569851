import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const InstrumentPolicyList = Loadable(
  lazy(() => import("./pages/InstrumentPolicyList"))
);
const InstrumentPolicyCreate = Loadable(
  lazy(() => import("./pages/InstrumentPolicyCreate"))
);
const InstrumentPolicyEdit = Loadable(
  lazy(() => import("./pages/InstrumentPolicyEdit"))
);
const InstrumentPolicyDisplay = Loadable(
  lazy(() => import("./pages/InstrumentPolicyDisplay"))
);

const instrumentPolicyRouter = [
  {
    path: "instrument-policies",
    element: <InstrumentPolicyList />,
  },
  {
    path: "instrument-policies/:id",
    element: <InstrumentPolicyDisplay />,
  },
  {
    path: "instrument-policies/new",
    element: <InstrumentPolicyCreate />,
  },
  {
    path: "instrument-policies/:id/edit",
    element: <InstrumentPolicyEdit />,
  },
];

export default instrumentPolicyRouter;
