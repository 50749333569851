import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const WaterTypeList = Loadable(lazy(() => import("./pages/WaterTypeList")));
const WaterTypeCreate = Loadable(lazy(() => import("./pages/WaterTypeCreate")));
const WaterTypeEdit = Loadable(lazy(() => import("./pages/WaterTypeEdit")));
const WaterTypeDisplay = Loadable(lazy(() => import("./pages/WaterTypeDisplay")));

const waterTypeRouter = [
  { path: "water-types", element: <WaterTypeList /> },
  { path: "water-types/:id", element: <WaterTypeDisplay /> },
  { path: "water-types/new", element: <WaterTypeCreate /> },
  { path: "water-types/:id/edit", element: <WaterTypeEdit /> },
];

export default waterTypeRouter;
