import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const CasingInfrastructureList = Loadable(
  lazy(() => import("./pages/CasingInfrastructureList"))
);
const CasingInfrastructureCreate = Loadable(
  lazy(() => import("./pages/CasingInfrastructureCreate"))
);
const CasingInfrastructureEdit = Loadable(
  lazy(() => import("./pages/CasingInfrastructureEdit"))
);
const CasingInfrastructureDisplay = Loadable(
  lazy(() => import("./pages/CasingInfrastructureDisplay"))
);

const casingInfrastructureRouter = [
  { path: "casing-infrastructures", element: <CasingInfrastructureList /> },
  {
    path: "casing-infrastructures/:id",
    element: <CasingInfrastructureDisplay />,
  },
  {
    path: "casing-infrastructures/new",
    element: <CasingInfrastructureCreate />,
  },
  {
    path: "casing-infrastructures/:id/edit",
    element: <CasingInfrastructureEdit />,
  },
];

export default casingInfrastructureRouter;
