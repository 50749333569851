import {useApiHttp} from '../../../../shared/hooks/useHttp';


const useDataRefYearService = () => {
    const {crud} = useApiHttp();

    const {
        create,
        update,
        destroy,
        findById,
        findAll,
    } = crud('data-ref-years');



    return {
        create,
        update,
        destroy,
        findById,
        findAll,
    }
}

export default useDataRefYearService;
