import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';
import MainLayout from '../../home/AppLayout';
import Loadable from '../../shared/components/Loadable';
import {PATH_FISHING} from './module.fishing.path';
import amenagementEntitiesRouter from "./amenagement_entity/amenagement.entity.router";
import ahpTypeRouter from "./ahp_type/ahp.type.router";
import ahpRouter from "./ahp/ahp.router";
import occurrenceFishProductionRouter from "./occurrence_fish_production/occurrence.fish.production.router";
import occurrenceNeedWithdrawalRouter from "./occurrence_need_withdrawal/occurrence.need.withdrawal.router";

const FishingHome = Loadable(lazy(() => import('./ModuleFishingHome')));

const moduleFishingRouter = [{
    path: PATH_FISHING.root,
    element: <MainLayout/>,
    children: [
        {path: PATH_FISHING.root, element: <Navigate to={PATH_FISHING.home} replace/>},
        {path: 'home', element: <FishingHome/>},
        ...ahpRouter,
        ...ahpTypeRouter,
        ...amenagementEntitiesRouter,
        ...occurrenceFishProductionRouter,
        ...occurrenceNeedWithdrawalRouter
    ]
}];

export default moduleFishingRouter;
