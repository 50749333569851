import {useApiHttp} from '../../../../shared/hooks/useHttp';

const useSicknessTypeService = () => {
    const {crud} = useApiHttp();

    const {
        create,
        update,
        destroy,
        findById,
        findAll,
    } = crud('sickness-types');

    return {
        create,
        update,
        destroy,
        findById,
        findAll,
    }
}

export default useSicknessTypeService;
