import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const SiteTypeList = Loadable(lazy(() => import("./pages/SiteTypeList")));
const SiteTypeCreate = Loadable(lazy(() => import("./pages/SiteTypeCreate")));
const SiteTypeEdit = Loadable(lazy(() => import("./pages/SiteTypeEdit")));
const SiteTypeDisplay = Loadable(lazy(() => import("./pages/SiteTypeDisplay")));

const siteTypeRouter = [
  { path: "site-types", element: <SiteTypeList /> },
  { path: "site-types/:id", element: <SiteTypeDisplay /> },
  { path: "site-types/new", element: <SiteTypeCreate /> },
  { path: "site-types/:id/edit", element: <SiteTypeEdit /> },
];

export default siteTypeRouter;
