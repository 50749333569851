import React, {useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
// material
import {CircularProgress, Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {InstitutionalOrganizationalFrameworkStructureTypeForm} from '../components';

import {InstitutionalOrganizationalFrameworkStructureTypeHeaderBreadcrumbs} from '../components';

import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useInstitutionalOrganizationalFrameworkStructureType} from '../services/institutional.organizational.framework.structure.type.gql';
import {useInstitutionalOrganizationalFrameworkStructureTypeStore} from "../services/institutional.organizational.framework.structure.type.store";

export default function InstitutionalOrganizationalFrameworkStructureTypeEdit() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {showError} = useMessage();

    const {setCurrentUpdateInstitutionalOrganizationalFrameworkStructureType: setCurrentInstitutionalOrganizationalFrameworkStructureType} = useInstitutionalOrganizationalFrameworkStructureTypeStore();

    const {entity: currentInstitutionalOrganizationalFrameworkStructureType, error, loading, errorMessage} = useInstitutionalOrganizationalFrameworkStructureType(id);

    useEffect(() => {
        if (loading) return;
        if (error) {
            showError(errorMessage);
            navigate(PATH_GIRE_ENV_FAVORABLE.institutionalOrganizationalFrameworkStructureTypes);
        }
    }, [loading, error, errorMessage]);

    useEffect(() => {
        setCurrentInstitutionalOrganizationalFrameworkStructureType(currentInstitutionalOrganizationalFrameworkStructureType);
    }, [currentInstitutionalOrganizationalFrameworkStructureType]);

    return (
        <Page title={"Gire environment favorable: Modification types de structures de cadre institutionnel et organisationnel eau"}>
            <Container maxWidth={'xl'}>
                <InstitutionalOrganizationalFrameworkStructureTypeHeaderBreadcrumbs
                    heading="Modification du typede structures de cadre institutionnel et organisationnel eau"
                    lastName={`Modification types de structures de cadre institutionnel et organisationnel eau - ${id}`}

                />

                {loading && <CircularProgress/>}
                {!loading && currentInstitutionalOrganizationalFrameworkStructureType && <InstitutionalOrganizationalFrameworkStructureTypeForm isEdit {...{currentInstitutionalOrganizationalFrameworkStructureType, setCurrentInstitutionalOrganizationalFrameworkStructureType}}/>}
            </Container>
        </Page>
    );
}
