import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Refresh, Search} from '@mui/icons-material';
import {
    TableContainer, Table, TableBody, TableRow, TableCell, IconButton,
    Toolbar, OutlinedInput, InputAdornment, Box, Tooltip, Typography, Link,
} from '@mui/material';
import useLocalityService from '../services/locality.services';
import {useSearchLocalities} from '../services/locality.gql';
import {SortableTableHead, MyTablePagination, usePagination} from '../../../../shared/components/tables';
import {LocalityCreateDialogMenuItem, LocalityMoreMenu} from './index';
import {PATH_ADMINISTRATIVE_DIVISION} from '../../administrative_division.path';
import {useDelete} from '../../../../shared/hooks/useDelete';
import {RefreshIconButton, sxTableToolbar} from '../../../../shared/components/tables/components.table.utils';

const TABLE_HEAD = [
    {id: "id", label: "ID", alignRight: false},
    {id: "name", label: "Nom", alignRight: false},
    {id: "villageStatus", label: "Status village", alignRight: false},
    {id: "localityType", label: "Type", alignRight: false},
    {id: "environment", label: "Milieu", alignRight: false},
    // { id: "geographicalArea", label: "Zone geograplique", alignRight: false },
    {id: "commune", label: "Commune", alignRight: false},
    // { id: "subBasin", label: "Sous bassin", alignRight: false },
    // { id: "originPopulation", label: "Origine population", alignRight: false },
    // { id: "topoMap", label: "Carte topo", alignRight: false },
    {id: ""},
];

export default function LocalityTable({commune, currentCommune, localityType, currentLocalityType}) {
    const {
        page, order, filterName, rowsPerPage, orderBy, handleRequestSort,
        handleChangePage, handleChangeRowsPerPage, handleFilterByName,
    } = usePagination();

    const {localities, refetch, localityPage} = useSearchLocalities({
        query: filterName,
        size: rowsPerPage,
        page, commune, localityType,
    });

    const {destroy} = useLocalityService();
    const {onDelete} = useDelete({
        destroyFn: destroy, onSuccess: refetch
    });

    return (
        <>
            <Toolbar sx={sxTableToolbar}>
                <OutlinedInput
                    sx={{width: 240}}
                    value={filterName}
                    onChange={handleFilterByName}
                    placeholder='Recherche localite'
                    startAdornment={
                        <InputAdornment position="start">
                            <Box component={Search} sx={{color: 'text.disabled'}}/>
                        </InputAdornment>
                    }
                />

                <Box>
                    {currentCommune && (
                        <LocalityCreateDialogMenuItem
                            btn
                            commune={currentCommune}
                            onSave={(createdLocality) => {
                                console.log(`##### createdLocality: `, createdLocality);
                                refetch();
                            }}
                        />
                    )}
                    {currentLocalityType && (
                        <LocalityCreateDialogMenuItem
                            btn
                            commune={currentLocalityType}
                            onSave={(createdLocality) => {
                                console.log(`##### createdLocality: `, createdLocality);
                                refetch();
                            }}
                        />
                    )}
                    <RefreshIconButton onRefresh={refetch}/>
                </Box>

            </Toolbar>
            <TableContainer sx={{minWidth: 800}}>
                <Table size='small'>
                    <SortableTableHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {localities.map((row) => (<Row key={row.id} {...{row, onDelete}}/>))}
                    </TableBody>
                </Table>
            </TableContainer>

            <MyTablePagination
                page={localityPage}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
}

function Row({row, onDelete}) {
    const {
        id,
        name,
        communeObj,
        localityTypeObj,
        villageStatusObj,
        environmentObj,
    } = row;

    return (
        <TableRow hover sx={{"&:last-child td, &:last-child th": {border: 0}}}>
            <TableCell component="th" scope="row">
                <Link
                    to={PATH_ADMINISTRATIVE_DIVISION.localities + "/" + id}
                    component={RouterLink}
                >
                    <Typography variant="body2" noWrap>
                        {id}
                    </Typography>
                </Link>
            </TableCell>

            <TableCell component="th" scope="row">
                <Link
                    to={PATH_ADMINISTRATIVE_DIVISION.localities + "/" + id}
                    component={RouterLink}
                >
                    <Typography variant="body2" noWrap>
                        {name}
                    </Typography>
                </Link>
            </TableCell>
            <TableCell style={{minWidth: 160}}>
                <Link
                    to={
                        PATH_ADMINISTRATIVE_DIVISION.village_status +
                        "/" +
                        villageStatusObj?.id
                    }
                    component={RouterLink}
                >
                    <Typography variant="body2" noWrap>
                        {villageStatusObj?.wording}
                    </Typography>
                </Link>
            </TableCell>
            <TableCell style={{minWidth: 160}}>
                <Link
                    to={
                        PATH_ADMINISTRATIVE_DIVISION.localityTypes +
                        "/" +
                        localityTypeObj?.id
                    }
                    component={RouterLink}
                >
                    <Typography variant="body2" noWrap>
                        {localityTypeObj?.name}
                    </Typography>
                </Link>
            </TableCell>
            <TableCell style={{minWidth: 160}}>
                <Link
                    to={
                        PATH_ADMINISTRATIVE_DIVISION.environment + "/" + environmentObj?.id
                    }
                    component={RouterLink}
                >
                    <Typography variant="body2" noWrap>
                        {environmentObj?.wording}
                    </Typography>
                </Link>
            </TableCell>
            <TableCell style={{minWidth: 160}}>
                <Link
                    to={PATH_ADMINISTRATIVE_DIVISION.communes + "/" + communeObj?.id}
                    component={RouterLink}
                >
                    <Typography variant="body2" noWrap>
                        {communeObj?.name}
                    </Typography>
                </Link>
            </TableCell>

            <TableCell align="right">
                <LocalityMoreMenu id={id} onDelete={onDelete} isIconButton hiddenNew/>
            </TableCell>
        </TableRow>
    );
}
