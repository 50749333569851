import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const WaterQualityObservationTypeList = Loadable(
  lazy(() => import("./pages/WaterQualityObservationTypeList"))
);
const WaterQualityObservationTypeCreate = Loadable(
  lazy(() => import("./pages/WaterQualityObservationTypeCreate"))
);
const WaterQualityObservationTypeEdit = Loadable(
  lazy(() => import("./pages/WaterQualityObservationTypeEdit"))
);
const WaterQualityObservationTypeDisplay = Loadable(
  lazy(() => import("./pages/WaterQualityObservationTypeDisplay"))
);

const waterQualityObservationTypeRouter = [
  {
    path: "water-quality-observation-types",
    element: <WaterQualityObservationTypeList />,
  },
  {
    path: "water-quality-observation-types/:id",
    element: <WaterQualityObservationTypeDisplay />,
  },
  {
    path: "water-quality-observation-types/new",
    element: <WaterQualityObservationTypeCreate />,
  },
  {
    path: "water-quality-observation-types/:id/edit",
    element: <WaterQualityObservationTypeEdit />,
  },
];

export default waterQualityObservationTypeRouter;
