import React from 'react';

import ThemeConfig from './theme';
import AppRouter from './AppRouter';
import useAuth from './shared/hooks/useAuth';
import GlobalStyles from './theme/globalStyles';
import LoadingScreen, {ProgressBarStyle} from './shared/components/LoadingScreen';
import {ScrollToTop, BaseOptionChartStyle, NotistackProvider} from './shared/components';

export default function App() {

    const {isInitialized} = useAuth();

    return (
        <ThemeConfig>
            <NotistackProvider>
                <GlobalStyles/>
                <ProgressBarStyle/>
                <BaseOptionChartStyle/>
                <ScrollToTop/>
                {isInitialized ? <AppRouter/> : <LoadingScreen/>}
            </NotistackProvider>
        </ThemeConfig>
    );
}
