import {gql, useQuery, useLazyQuery} from '@apollo/client';
import {parseCount, parseEntity} from '../../../../utils/gql.utils';
import {useEffect, useState} from 'react';
import {useMessage} from '../../../../shared/hooks/useMessage';

export let needRefresh = false;
export const setRefresh = (value) => needRefresh = value;

export const riskManagementToolFields = `
    id name dataRefYear dataRefYearObj {id name} riskManagementToolType riskManagementToolTypeObj {id name} dataSource
`;

///////////////////// Find By ID /////////////////////
const GET_PAYS_BY_ID = gql`
    query RiskManagementToolById($id: Int!) {
        riskManagementToolById(id: $id) {
            error message entity { ${riskManagementToolFields} }
        }
    }
`;

export const useRiskManagementToolById = (id) => {
    id = parseInt(id);
    return useQuery(GET_PAYS_BY_ID, {
        variables: {id},
        fetchPolicy: 'network-only'
    });
}

export const useRiskManagementTool = (id) => {
    const result = useRiskManagementToolById(id);
    return parseEntity(result, 'riskManagementToolById');
}

///////////////////// Search /////////////////////
const SEARCH_PAYS = gql`
    query SearchRiskManagementTools($query: String, $page: Int, $size: Int) {
        searchRiskManagementTools(query: $query, page: $page, size: $size) {
            error message page {
                content { ${riskManagementToolFields} }
                page hasNext hasPrevious totalPages totalElements numberOfElements
            }
        }
    }
`;

export const useSearchRiskManagementTools = ({query = '', page = 0, size = 20}) => {
    const [riskManagementTools, setRiskManagementTools] = useState([]);
    const [riskManagementToolPage, setRiskManagementToolPage] = useState(null);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_PAYS, {
        variables: {query, page, size},
    });

    useEffect(() => {
        if(_error) {
            showError('Erreur lors de l\'operation');
            setRiskManagementToolPage(null);
            setRiskManagementTools([]);
            return;
        }

        if(!searchData) return;

        const {error, message, page} = searchData.searchRiskManagementTools;

        if (error) {
            showError(message);
            setRiskManagementToolPage(null);
            setRiskManagementTools([]);
        } else {
            setRiskManagementToolPage(page);
            setRiskManagementTools(page.content);
        }

        if(needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({ variables: { query, page, size } });
    }, [query, page, size]);

    return {
        riskManagementTools: riskManagementTools, loading, search, refetch, riskManagementToolPage
    }
}

///////////////////// Count /////////////////////
const COUNT_PAYS = gql`
    query CountRiskManagementTools {
        countRiskManagementTools {
            error message count
        }
    }
`;

export const useCountRiskManagementTools = () => {
    const result = useQuery(COUNT_PAYS, {
        fetchPolicy: 'network-only'
    });
    return parseCount(result, 'countRiskManagementTools');
}
