import React from 'react';
// material
import MergeTypeIcon from "@mui/icons-material/MergeType";
// utils
import {PATH_RISK_PROTECTION} from '../../risk.protection.path';
import {useCountRiskTypes} from '../services/risk.type.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function RiskTypeEntryButton() {
    const {count} = useCountRiskTypes();
  return (
      <SubModuleEntryButton
          label='Type de risques liés aux RE'
          value={count}
          url={PATH_RISK_PROTECTION.riskTypes}
          icon={<MergeTypeIcon />}
      />
  );
}
