import * as Yup from 'yup';
import create from 'zustand';


export const InstrumentMechanismParticipationOccurrenceSchema = Yup.object().shape({
    name: Yup.string().default('').required('Le nom est obligatoire'),
    justification: Yup.string().required("La justification est obligatoire"),
    riskManagementToolApplicationDegree: Yup.string().required("Le degrée d'application est obligatoire"),
    dataRefYear: Yup.string().required("L'année de reference de donnée"),
    dataSource: Yup.string().default(''),
});

const initialInstrumentMechanismParticipationOccurrence = InstrumentMechanismParticipationOccurrenceSchema.getDefaultFromShape();
export const InitialInstrumentMechanismParticipationOccurrence = () => ({...initialInstrumentMechanismParticipationOccurrence});

const setCurrentNewInstrumentMechanismParticipationOccurrence = set => instrumentMechanismParticipationOccurrence => {
    set(state => ({...state, currentNewInstrumentMechanismParticipationOccurrence: instrumentMechanismParticipationOccurrence ?? InitialInstrumentMechanismParticipationOccurrence()}));
}

const setCurrentUpdateInstrumentMechanismParticipationOccurrence = set => instrumentMechanismParticipationOccurrence => {
    set(state => ({...state, currentUpdateInstrumentMechanismParticipationOccurrence: instrumentMechanismParticipationOccurrence ?? InitialInstrumentMechanismParticipationOccurrence()}));
}

export const useInstrumentMechanismParticipationOccurrenceStore = create(set => ({
    //State
    currentNewInstrumentMechanismParticipationOccurrence: InitialInstrumentMechanismParticipationOccurrence(),
    currentUpdateInstrumentMechanismParticipationOccurrence: InitialInstrumentMechanismParticipationOccurrence(),
    //Reducers
    setCurrentNewInstrumentMechanismParticipationOccurrence: setCurrentNewInstrumentMechanismParticipationOccurrence(set),
    setCurrentUpdateInstrumentMechanismParticipationOccurrence: setCurrentUpdateInstrumentMechanismParticipationOccurrence(set),
}))
