import * as Yup from 'yup';
import create from 'zustand';


export const LegislativeTextTypeSchema = Yup.object().shape({
    name: Yup.string().default('').required('Le nom est obligatoire'),
    description: Yup.string().default(''),
    dataSource: Yup.string().default(''),
});

const initialLegislativeTextType = LegislativeTextTypeSchema.getDefaultFromShape();
export const InitialLegislativeTextType = () => ({...initialLegislativeTextType});

const setCurrentNewLegislativeTextType = set => legislativeTextType => {
    set(state => ({...state, currentNewLegislativeTextType: legislativeTextType ?? InitialLegislativeTextType()}));
}

const setCurrentUpdateLegislativeTextType = set => legislativeTextType => {
    set(state => ({...state, currentUpdateLegislativeTextType: legislativeTextType ?? InitialLegislativeTextType()}));
}

export const useLegislativeTextTypeStore = create(set => ({
    //State
    currentNewLegislativeTextType: InitialLegislativeTextType(),
    currentUpdateLegislativeTextType: InitialLegislativeTextType(),
    //Reducers
    setCurrentNewLegislativeTextType: setCurrentNewLegislativeTextType(set),
    setCurrentUpdateLegislativeTextType: setCurrentUpdateLegislativeTextType(set),
}))
