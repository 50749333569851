import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {Form, FormikProvider, useFormik} from 'formik';
import {useMessage} from '../../../../shared/hooks/useMessage';
// material
import {LoadingButton} from '@mui/lab';
import {Card, Stack, Box, Button} from '@mui/material';
// routes
import {setRefresh} from '../services/legislative.text.type.gql';
import useLegislativeTextTypeService from '../services/legislative.text.type.services';
import {formErrorMessageParser} from '../../../../utils/utils';
import {FormikTextField} from '../../../../shared/components/froms/FormComponents';
import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {InitialLegislativeTextType, LegislativeTextTypeSchema} from '../services/legislative.text.type.store';
import {useConnectFormState} from '../../../../shared/hooks/useFormikState';


LegislativeTextTypeForm.propTypes = {
    isEdit: PropTypes.bool,
    currentLegislativeTextType: PropTypes.object,
    fromDialog: PropTypes.object,
};

export default function LegislativeTextTypeForm({isEdit, currentLegislativeTextType, setCurrentLegislativeTextType, fromDialog}) {
    const navigate = useNavigate();
    const {create, update} = useLegislativeTextTypeService();
    const {showSuccess, showError} = useMessage();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: InitialLegislativeTextType(),
        validationSchema: LegislativeTextTypeSchema,
        onSubmit: async (legislativeTextType, {setSubmitting}) => {
            try {
                legislativeTextType = LegislativeTextTypeSchema.cast(legislativeTextType);

                let message;
                if (!isEdit) {
                    legislativeTextType = await create(legislativeTextType);
                    message = 'Création avec succès';
                } else {
                    legislativeTextType = await update(currentLegislativeTextType.id, legislativeTextType);
                    message = 'Modification avec succès';
                }

                setRefresh(true);

                setCurrentLegislativeTextType();

                setSubmitting(false);
                showSuccess(message);

                if (fromDialog) {
                    fromDialog.onSave(legislativeTextType);
                } else {
                    navigate(`${PATH_GIRE_ENV_FAVORABLE.legislativeTextTypes}/${legislativeTextType.id}`);
                }
            } catch (error) {
                setSubmitting(false);
                showError(formErrorMessageParser({error, isEdit}));
            }
        }
    });

    const {values, setValues, handleSubmit, isSubmitting} = formik;

    useConnectFormState({
        formValues: values, setFormValues: setValues,
        stateValues: currentLegislativeTextType, setStateValues: setCurrentLegislativeTextType,
    });

    const onCancel = (_) => {
        setCurrentLegislativeTextType();
        if (fromDialog) {
            fromDialog.onCancel();
        } else {
            navigate(PATH_GIRE_ENV_FAVORABLE.legislativeTextTypes);
        }
    }

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete='off' onSubmit={handleSubmit}>

                <Card sx={{p: 3}}>

                    <Stack spacing={3}>

                        <FormikTextField label='Nom' name='name' formik={formik}/>
                        <FormikTextField label='Source de données' name='dataSource' formik={formik}/>
                        <FormikTextField multiline minRows={2} maxRows={6} label='Description' name='description' formik={formik}/>

                        <Box sx={{mt: 3, display: 'flex', justifyContent: 'flex-end'}}>
                            <Button
                                sx={{mr: 2}}
                                size='medium'
                                onClick={onCancel}
                                disabled={isSubmitting}
                            >
                                {'Annuler'}
                            </Button>
                            <LoadingButton type='submit' variant='contained' size='medium' loading={isSubmitting}>
                                {!isEdit ? 'Créer' : 'Modifier'}
                            </LoadingButton>
                        </Box>
                    </Stack>

                </Card>

            </Form>
        </FormikProvider>
    );
}
