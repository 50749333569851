import React from 'react';
import {Link as RouterLink} from "react-router-dom";

import {useTheme} from "@mui/material/styles";
import {Refresh, Search} from "@mui/icons-material";
import {
    Box, IconButton, InputAdornment, Link, OutlinedInput, Table,
    TableBody, TableCell, TableContainer, TableRow, Toolbar, Tooltip, Typography
} from "@mui/material";

import {useSearchInstitutionalOrganizationalFrameworkStructureOccurrences} from '../services/institutional.organizational.framework.structure.occurrence.gql';
import useInstitutionalOrganizationalFrameworkStructureOccurrenceService from '../services/institutional.organizational.framework.structure.occurrence.services';
import {usePagination} from '../../../../shared/components/tables';
import SortableTableHead from "../../../../shared/components/tables/SortableTableHead";
import MyTablePagination from "../../../../shared/components/tables/MyTablePagination";
import {InstitutionalOrganizationalFrameworkStructureOccurrenceCreateDialogMenuItem, InstitutionalOrganizationalFrameworkStructureOccurrenceMoreMenu} from "./index";
import {useDelete} from "../../../../shared/hooks/useDelete";
import {PATH_GIRE_ENV_FAVORABLE} from "../../gire.env.favorable.path";

const TABLE_HEAD = [
    {id: 'id', label: 'ID', alignRight: false},
    {id: 'name', label: 'Nom', alignRight: false},
    {id: "institutionalOrganizationalFrameworkStructureObj", label: "Structures de cadre institutionnel", alignRight: false},
    {id: "dataRefYearObj", label: "Année de reference", alignRight: false},
    {id: "riskManagementToolApplicationDegreeObj", label: "Dégrée d'application", alignRight: false},
    {id: 'justification', label: 'Justification', alignRight: false},
    {id: 'dataSource', label: 'Source de données', alignRight: false},
    {id: ''}
];

export default function InstitutionalOrganizationalFrameworkStructureOccurrenceTable({institutionalOrganizationalFrameworkStructure, currentInstitutionalOrganizationalFrameworkStructure, dataRefYear, currentDataRefYear, riskManagementToolApplicationDegree, currentRiskManagementToolApplicationDegree}) {
    const theme = useTheme();
    const {
        page, order, filterName, rowsPerPage, orderBy, handleRequestSort,
        handleChangePage, handleChangeRowsPerPage, handleFilterByName
    } = usePagination();

    const {
        institutionalOrganizationalFrameworkStructureOccurrences,
        refetch,
        institutionalOrganizationalFrameworkStructureOccurrencePage
    } = useSearchInstitutionalOrganizationalFrameworkStructureOccurrences({
        query: filterName,
        size: rowsPerPage,
        page, institutionalOrganizationalFrameworkStructure, dataRefYear, riskManagementToolApplicationDegree
    });

    const {destroy} = useInstitutionalOrganizationalFrameworkStructureOccurrenceService();
    const {onDelete} = useDelete({
        destroyFn: destroy, onSuccess: refetch
    });

    return (
        <>
            <Toolbar
                sx={{
                    height: 96,
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: theme.spacing(0, 1, 0, 3),
                }}
            >

                <OutlinedInput
                    sx={{width: 240}}
                    value={filterName}
                    onChange={handleFilterByName}
                    placeholder="Recherche occurrences d'état de dynamisme du cadre institutionnel et organisationnel"
                    startAdornment={
                        <InputAdornment position="start">
                            <Box component={Search} sx={{color: 'text.disabled'}}/>
                        </InputAdornment>
                    }
                />


                <Box>
                    {institutionalOrganizationalFrameworkStructure && (

                        <InstitutionalOrganizationalFrameworkStructureOccurrenceCreateDialogMenuItem
                            btn
                            institutionalOrganizationalFrameworkStructure={currentInstitutionalOrganizationalFrameworkStructure}
                            onSave={(createdInstitutionalOrganizationalFrameworkStructure) => {
                                console.log(`##### createdInstitutionalOrganizationalFrameworkStructure: `, createdInstitutionalOrganizationalFrameworkStructure);
                                refetch();
                            }}
                        />
                    )}
                    {dataRefYear && (
                        <InstitutionalOrganizationalFrameworkStructureOccurrenceCreateDialogMenuItem
                            btn
                            dataRefYear={currentDataRefYear}
                            onSave={(createdDataRefYear) => {
                                console.log(`##### createdDataRefYear: `, createdDataRefYear);
                                refetch();
                            }}
                        />
                    )}
                    {riskManagementToolApplicationDegree && (
                        <InstitutionalOrganizationalFrameworkStructureOccurrenceCreateDialogMenuItem
                            btn
                            riskManagementToolApplicationDegree={currentRiskManagementToolApplicationDegree}
                            onSave={(createdRiskManagementToolApplicationDegree) => {
                                console.log(`##### createdRiskManagementToolApplicationDegree: `, createdRiskManagementToolApplicationDegree);
                                refetch();
                            }}
                        />
                    )}

                    <Tooltip title='Actualiser'>
                        <IconButton onClick={() => refetch()}>
                            <Refresh/>
                        </IconButton>
                    </Tooltip>
                </Box>
            </Toolbar>

            <TableContainer sx={{minWidth: 800}}>
                <Table size="small">
                    <SortableTableHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {institutionalOrganizationalFrameworkStructureOccurrences.map((row) => (<Row key={row.id} {...{row, onDelete}} />))}
                    </TableBody>
                </Table>
            </TableContainer>

            <MyTablePagination
                page={institutionalOrganizationalFrameworkStructureOccurrencePage}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}

function Row({row, onDelete}) {
    const {id, name, riskManagementToolApplicationDegreeObj, dataRefYearObj, institutionalOrganizationalFrameworkStructureObj, justification, dataSource} = row;

    return (
        <TableRow hover sx={{'&:last-child td, &:last-child th': {border: 0}}}>
            <TableCell component='th' scope='row'>
                <Link
                    to={PATH_GIRE_ENV_FAVORABLE.institutionalOrganizationalFrameworkStructureOccurrences + '/' + id}
                    component={RouterLink}
                >
                    <Typography variant='body2' noWrap>
                        {id}
                    </Typography>
                </Link>
            </TableCell>
            <TableCell component='th' scope='row'>
                <Link
                    to={PATH_GIRE_ENV_FAVORABLE.institutionalOrganizationalFrameworkStructureOccurrences + '/' + id}
                    component={RouterLink}
                >
                    <Typography
                        variant='body2' noWrap>
                        {name}
                    </Typography>
                </Link>
            </TableCell>

            <TableCell component='th' scope='row'>
                <Typography
                    variant='body2' noWrap>
                    {institutionalOrganizationalFrameworkStructureObj?.name}
                </Typography>
            </TableCell>

            <TableCell component='th' scope='row'>
                <Typography
                    variant='body2' noWrap>
                    {dataRefYearObj?.name}
                </Typography>
            </TableCell>

            <TableCell style={{minWidth: 160}}>
                <Typography variant="body2" noWrap>
                    {riskManagementToolApplicationDegreeObj?.name}
                </Typography>
            </TableCell>

            <TableCell style={{minWidth: 160}}>
                <Typography variant="body2" noWrap>
                    {justification}
                </Typography>
            </TableCell>

            <TableCell style={{minWidth: 160}}>
                <Typography variant='body2' noWrap>
                    {dataSource}
                </Typography>
            </TableCell>

            <TableCell align='right'>
                <InstitutionalOrganizationalFrameworkStructureOccurrenceMoreMenu {...{id, onDelete}} isIconButton hiddenNew/>
            </TableCell>
        </TableRow>
    );
}


