import React from 'react';
import {PATH_POTENTIAL_WATER_AVAILABILITY, commonItems} from '../../potential.water.availability.path';
import HeaderBreadcrumbs from "../../../../shared/components/HeaderBreadcrumbs";

export function QuantitativeObservationTypeHeaderBreadcrumbs ({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[
            ...commonItems,
            {name: "Type d'observation quantitative", href: PATH_POTENTIAL_WATER_AVAILABILITY.quantitativeObservationTypes},
            {name: lastName},
        ]}
    />);
}

export function QuantitativeObservationTypeListHeaderBreadcrumbs ({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[...commonItems, {name: lastName}]}
    />);
}
