import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const SaepList = Loadable(lazy(() => import("./pages/SaepList")));
const SaepCreate = Loadable(lazy(() => import("./pages/SaepCreate")));
const SaepEdit = Loadable(lazy(() => import("./pages/SaepEdit")));
const SaepDisplay = Loadable(lazy(() => import("./pages/SaepDisplay")));

const saepRouter = [
  { path: "saeps", element: <SaepList /> },
  { path: "saeps/:id", element: <SaepDisplay /> },
  { path: "saeps/new", element: <SaepCreate /> },
  { path: "saeps/:id/edit", element: <SaepEdit /> },
];

export default saepRouter;
