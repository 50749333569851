import React from 'react';
// material
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
// utils
import {PATH_RISK_PROTECTION} from '../../risk.protection.path';
import {useCountHealthAreas} from '../services/health.area.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function HealthAreaEntryButton() {
    const {count} = useCountHealthAreas();
  return (
      <SubModuleEntryButton
          label="Entités aires de santé"
          value={count}
          url={PATH_RISK_PROTECTION.healthAreas}
          icon={<HealthAndSafetyIcon />}
      />
  );
}
