import {Enum} from '../../../utils/enum.utils';

export const ReportingValueType = Enum({
    VALUE_STRING: {description: 'Chaine', field: 'valueString'},
    VALUE_INTEGER: {description: 'Entier', field: 'valueLong'},
    VALUE_REAL: {description: 'Reel', field: 'valueDouble'},
    VALUE_DATE: {description: 'Date', date: true, field: 'valueDate'},
    VALUE_BOOLEAN: {description: 'Boolean', field: 'valueBoolean'},
    VALUE_CONSTANT_LIST: {description: 'Liste Constante', list: true, constant: true},
    VALUE_REQUEST_LIST: {description: 'Liste Requete', list: true, request: true},
}, {
    simpleValues: function () {
        return this.values().filter(_enum => !_enum.list);
    }
});

export const ReportingAutoValue = Enum({
    CURRENT_USERNAME: {description: "Login de l'utilisateur courant", valueType: ReportingValueType.VALUE_STRING, currentUsername: true},
    CURRENT_USERID: {description: "Identifiant de l'utilisateur courant", valueType: ReportingValueType.VALUE_STRING, currentUserId: true},
});

export const ReportingGroupingOperation = Enum({
    SUM: {
        description: 'Somme',
        types: [ReportingValueType.VALUE_REAL, ReportingValueType.VALUE_INTEGER],
    },
    AVG: {
        description: 'Moyenne',
        types: [ReportingValueType.VALUE_REAL, ReportingValueType.VALUE_INTEGER, ReportingValueType.VALUE_STRING],
    },
    MIN: {
        description: 'Min',
        types: [
            ReportingValueType.VALUE_REAL, ReportingValueType.VALUE_INTEGER,
            ReportingValueType.VALUE_STRING, ReportingValueType.VALUE_DATE
        ],
    },
    MAX: {
        description: 'Max',
        types: [
            ReportingValueType.VALUE_REAL, ReportingValueType.VALUE_INTEGER,
            ReportingValueType.VALUE_STRING, ReportingValueType.VALUE_DATE
        ],
    },
    COUNT: {
        description: 'Nombre',
        types: ReportingValueType.values(),
    },
}, {
    valuesFor: function (type) {
        return this.values().filter(_enum => _enum.types.indexOf(type) !== -1);
    }
});

