import React from 'react';
import {commonItems, PATH_ORGAN_INSTRUMENT} from '../../organ.instrument.path';
import HeaderBreadcrumbs from "../../../../shared/components/HeaderBreadcrumbs";


export function TypeSphereHeaderBreadcrumbs({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[
            ...commonItems,
            {name: 'Types des sphères d\'action', href: PATH_ORGAN_INSTRUMENT.typeSpheres},
            {name: lastName},
        ]}
    />);
}

export function TypeSphereListHeaderBreadcrumbs({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[...commonItems, {name: lastName}]}
    />);
}
