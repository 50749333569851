import * as Yup from 'yup';
import create from 'zustand';


export const RegionSchema = Yup.object().shape({
    name: Yup.string().default('').required('Le nom est obligatoire'),
    pays: Yup.number().default(null).required('Le pays est obligatoire'),
});

const initialRegion = RegionSchema.getDefaultFromShape();
export const InitialRegion = () => ({...initialRegion});

const setCurrentNewRegion = set => region => {
    set(state => ({...state, currentNewRegion: region ?? InitialRegion()}));
}

const setCurrentUpdateRegion = set => region => {
    set(state => ({...state, currentUpdateRegion: region ?? InitialRegion()}));
}

export const useRegionStore = create(set => ({
    //State
    currentNewRegion: InitialRegion(),
    currentUpdateRegion: InitialRegion(),
    //Reducers
    setCurrentNewRegion: setCurrentNewRegion(set),
    setCurrentUpdateRegion: setCurrentUpdateRegion(set),
}))
