import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Add} from '@mui/icons-material';
import { Card, Button, Container } from '@mui/material';
import {PATH_RISK_PROTECTION} from '../../risk.protection.path';
import {Page} from '../../../../shared/components';
import {RiskManagementToolTypeTable, RiskManagementToolTypeListHeaderBreadcrumbs} from '../components';

export default function RiskManagementToolTypeList() {
    return (
        <Page title="Risque et protection en RE: Liste des types outils de gestion de risque">
            <Container maxWidth={'xl'}>
                <RiskManagementToolTypeListHeaderBreadcrumbs
                    heading="Types outils de gestion de risques liées à l'eau"
                    lastName='Liste des types outils de gestion de risque'
                    action={
                        <Button
                            variant="contained"
                            component={RouterLink}
                            to={PATH_RISK_PROTECTION.riskManagementToolTypes + '/new'}
                            startIcon={<Add/>}>
                            Nouveau
                        </Button>
                    }
                />

                <Card>
                    <RiskManagementToolTypeTable/>
                </Card>
            </Container>
        </Page>
    );
}
