import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { useMessage } from "../../../../../shared/hooks/useMessage";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
// material
import { LoadingButton } from "@mui/lab";
import {
  Card,
  Stack,
  TextField,
  Box,
  Button,
  Autocomplete,
  CircularProgress,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
// routes
import { PATH_REPORTING } from "../../../reporting.path";
import { FormRowStack } from "../../../../../shared/components/froms/FormComponents";
import { setRefresh } from "../services/dashboard.widget.gql";

import useDashboardWidgetService from "../services/dashboard.widget.services";
import { useSearchWidgets } from "../../widget/services/widget.gql";
import { ReportingValueType } from "../../../reporting/reporting.enum";

// ----------------------------------------------------------------------

const gridHValues = [3, 6, 9, 12];

DashboardWidgetForm.propTypes = {
  isEdit: PropTypes.bool,
  dashboard: PropTypes.number,
  currentDashboardWidget: PropTypes.object,
  fromDialog: PropTypes.object,
};

export default function DashboardWidgetForm({
  isEdit,
  isDisplay,
  dashboard,
  currentDashboardWidget,
  fromDialog,
}) {
  const navigate = useNavigate();
  const { showSuccess, showError } = useMessage();
  const { create, update } = useDashboardWidgetService();

  const initQuery = currentDashboardWidget?.widgetObj?.title ?? "";
  const [query, setQuery] = useState(initQuery);
  const {
    widgets,
    refetch: refetchWidget,
    loading: loadingWidget,
  } = useSearchWidgets({
    dashboard,
    query,
  });

  const [selectedWidget, setSelectedWidget] = useState(null);
  const [schemaOthers, setSchemaOthers] = useState({});
  const [autoRefresh, setAutoRefresh] = useState(
    currentDashboardWidget?.autoRefresh ?? false
  );

  useEffect(() => {
    let _schemaOthers = {};

    if (autoRefresh) {
      _schemaOthers = {
        ..._schemaOthers,
        refreshDelay: Yup.number().required(
          `Le delais d'actualisation est obligatoire`
        ),
      };
    }

    setSchemaOthers(_schemaOthers);
  }, [autoRefresh]);

  const DashboardWidgetSchema = Yup.object().shape({
    widget: Yup.number().required("Le widget est obligatoire"),
    widgetTitle: Yup.string().required("Le titre est obligatoire"),
    dashboard: Yup.number().required(
      "Le tableau de bord parent est obligatoire"
    ),
    position: Yup.number().required("La position du Widget est obligatoire"),
    gridHxs: Yup.number(),
    gridHmd: Yup.number(),
    gridHlg: Yup.number(),
    gridVxs: Yup.number(),
    gridVmd: Yup.number(),
    gridVlg: Yup.number(),
    autoRefresh: Yup.bool(),

    ...schemaOthers,
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: DashboardWidgetSchema,
    initialValues: {
      widget: currentDashboardWidget?.widget,
      widgetTitle: currentDashboardWidget?.widgetTitle || "",
      dashboard: currentDashboardWidget?.dashboard || dashboard,
      position: currentDashboardWidget?.position || 0,
      gridHxs: currentDashboardWidget?.gridHxs || 12,
      gridHmd: currentDashboardWidget?.gridHmd || 6,
      gridHlg: currentDashboardWidget?.gridHlg || 6,
      gridVxs: currentDashboardWidget?.gridVxs || 350,
      gridVmd: currentDashboardWidget?.gridVmd || 350,
      gridVlg: currentDashboardWidget?.gridVlg || 350,

      autoRefresh: currentDashboardWidget?.autoRefresh ?? false,
      refreshDelay: currentDashboardWidget?.refreshDelay ?? 10 * 60,
    },
    onSubmit: async (
      dashboardWidget,
      { setSubmitting, resetForm, setErrors }
    ) => {
      try {
        if (isDisplay) {
          if (fromDialog) {
            fromDialog.onSave();
          } else {
            navigate(PATH_REPORTING.dashboard);
          }
          return;
        }
        dashboardWidget = DashboardWidgetSchema.cast(dashboardWidget);

        let message;
        if (!isEdit) {
          await create(dashboardWidget);
          message = "Création avec succès";
        } else {
          await update(currentDashboardWidget.id, dashboardWidget);
          message = "Modification avec succès";
        }

        setRefresh(true);

        resetForm();

        setSubmitting(false);
        showSuccess(message);

        if (fromDialog) {
          fromDialog.onSave();
        } else {
          navigate(PATH_REPORTING.dashboard);
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        showError(
          !isEdit
            ? "Erreur lors de la creation"
            : "Erreur lors de la modification"
        );
      }
    },
  });

  const onCancel = (_) => {
    if (fromDialog) {
      fromDialog.onCancel();
    } else {
      navigate(PATH_REPORTING.dashboard);
    }
  };

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (!selectedWidget) return;

    const { title, autoRefresh, refreshDelay } = selectedWidget;

    setFieldValue("widgetTitle", title);
    setFieldValue("autoRefresh", autoRefresh);

    autoRefresh && setFieldValue("refreshDelay", refreshDelay);
  }, [selectedWidget]);

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Card sx={{ p: 3 }}>
          <Stack spacing={3}>
            <FormRowStack>
              <Autocomplete
                fullWidth
                name="widget"
                autoHighlight
                options={widgets}
                loading={loadingWidget}
                inputValue={query}
                value={{ id: values.widget }}
                loadingText={<CircularProgress />}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, selectedWidget) => {
                  setFieldValue("widget", selectedWidget?.id);
                  setQuery(selectedWidget?.title ?? "");
                  setSelectedWidget(selectedWidget);
                }}
                getOptionLabel={(option) => {
                  const _option = widgets.find((op) => op.id === option.id);
                  return _option?.title ?? "";
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="Widget"
                      onChange={(e) => setQuery(e.target.value)}
                    />
                  );
                }}
              />
            </FormRowStack>

            <FormRowStack>
              <TextField
                fullWidth
                label="Titre"
                {...getFieldProps("widgetTitle")}
                error={Boolean(touched.widgetTitle && errors.widgetTitle)}
                helperText={touched.widgetTitle && errors.widgetTitle}
                InputProps={{ readOnly: isDisplay }}
              />
            </FormRowStack>

            <FormRowStack>
              <TextField
                fullWidth
                label="Position"
                inputProps={{ type: "number" }}
                {...getFieldProps("position")}
                error={Boolean(touched.position && errors.position)}
                helperText={touched.position && errors.position}
                InputProps={{ readOnly: isDisplay }}
              />
            </FormRowStack>

            <Box sx={{ pt: 4 }}>
              <Typography variant="h4">Taille du widget</Typography>
            </Box>

            <FormRowStack>
              <TextField
                select
                fullWidth
                label="Largeur Mobile"
                placeholder="Largeur Mobile"
                SelectProps={{ native: true }}
                {...getFieldProps("gridHxs")}
                error={Boolean(touched.gridHxs && errors.gridHxs)}
                helperText={touched.gridHxs && errors.gridHxs}
                InputProps={{ readOnly: isDisplay }}
              >
                {isDisplay && (
                  <option value={currentDashboardWidget?.gridHxs}>
                    {percentage(currentDashboardWidget?.gridHxs)}
                  </option>
                )}
                {!isDisplay && (
                  <>
                    <option value="" />
                    {gridHValues.map((option) => (
                      <option key={option} value={option}>
                        {percentage(option)}
                      </option>
                    ))}
                  </>
                )}
              </TextField>

              <TextField
                select
                fullWidth
                label="Largeur Tablet"
                placeholder="Largeur Tablet"
                SelectProps={{ native: true }}
                {...getFieldProps("gridHmd")}
                error={Boolean(touched.gridHmd && errors.gridHmd)}
                helperText={touched.gridHmd && errors.gridHmd}
                InputProps={{ readOnly: isDisplay }}
              >
                {isDisplay && (
                  <option value={currentDashboardWidget?.gridHmd}>
                    {percentage(currentDashboardWidget?.gridHmd)}
                  </option>
                )}
                {!isDisplay && (
                  <>
                    <option value="" />
                    {gridHValues.map((option) => (
                      <option key={option} value={option}>
                        {percentage(option)}
                      </option>
                    ))}
                  </>
                )}
              </TextField>

              <TextField
                select
                fullWidth
                label="Largeur PC"
                placeholder="Largeur PC"
                SelectProps={{ native: true }}
                {...getFieldProps("gridHlg")}
                error={Boolean(touched.gridHlg && errors.gridHlg)}
                helperText={touched.gridHlg && errors.gridHlg}
                InputProps={{ readOnly: isDisplay }}
              >
                {isDisplay && (
                  <option value={currentDashboardWidget?.gridHlg}>
                    {percentage(currentDashboardWidget?.gridHlg)}
                  </option>
                )}
                {!isDisplay && (
                  <>
                    <option value="" />
                    {gridHValues.map((option) => (
                      <option key={option} value={option}>
                        {percentage(option)}
                      </option>
                    ))}
                  </>
                )}
              </TextField>
            </FormRowStack>

            <FormRowStack>
              <TextField
                fullWidth
                label="Hauteur Mobile"
                placeholder="Hauteur Mobile"
                {...getFieldProps("gridVxs")}
                error={Boolean(touched.gridVxs && errors.gridVxs)}
                helperText={touched.gridVxs && errors.gridVxs}
                InputProps={{ readOnly: isDisplay }}
              />

              <TextField
                fullWidth
                label="Hauteur Tablet"
                placeholder="Hauteur Tablet"
                {...getFieldProps("gridVmd")}
                error={Boolean(touched.gridVmd && errors.gridVmd)}
                helperText={touched.gridVmd && errors.gridVmd}
                InputProps={{ readOnly: isDisplay }}
              />

              <TextField
                fullWidth
                label="Hauteur PC"
                placeholder="Hauteur PC"
                {...getFieldProps("gridVlg")}
                error={Boolean(touched.gridVlg && errors.gridVlg)}
                helperText={touched.gridVlg && errors.gridVlg}
                InputProps={{ readOnly: isDisplay }}
              />
            </FormRowStack>

            <FormRowStack>
              <FormControlLabel
                fullWidth
                label="Actualisation Automatique"
                control={
                  <Switch
                    checked={values.autoRefresh}
                    onChange={(e) => {
                      const { checked: autoRefresh } = e.target;
                      setFieldValue("autoRefresh", autoRefresh);
                      setAutoRefresh(autoRefresh);
                    }}
                  />
                }
              />
            </FormRowStack>

            {values.autoRefresh && (
              <FormRowStack>
                <TextField
                  fullWidth
                  label={`Delais d'actualisation (en seconde)`}
                  {...getFieldProps("refreshDelay")}
                  onChange={({ target: { value } }) => {
                    if (isNaN(Number(value))) return;
                    setFieldValue("refreshDelay", value);
                  }}
                  error={Boolean(touched.refreshDelay && errors.refreshDelay)}
                  helperText={touched.refreshDelay && errors.refreshDelay}
                />
              </FormRowStack>
            )}

            <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
              {!isDisplay && (
                <Button
                  sx={{ mr: 2 }}
                  size="medium"
                  onClick={onCancel}
                  disabled={isSubmitting}
                >
                  {"Annuler"}
                </Button>
              )}
              <LoadingButton
                type="submit"
                variant="contained"
                size="medium"
                loading={isSubmitting}
              >
                {isDisplay ? "Fermer" : !isEdit ? "Créer" : "Modifier"}
              </LoadingButton>
            </Box>
          </Stack>
        </Card>
      </Form>
    </FormikProvider>
  );
}

function percentage(value) {
  return `${((value ?? 12) / 12) * 100}%`;
}
