import * as Yup from 'yup';
import create from 'zustand';


export const CircleSchema = Yup.object().shape({
    name: Yup.string().default('').required('Le nom est obligatoire'),
    region: Yup.number().default(null).required('La region est obligatoire'),
});

const initialCircle = CircleSchema.getDefaultFromShape();
export const InitialCircle = () => ({...initialCircle});

const setCurrentNewCircle = set => circle => {
    set(state => ({...state, currentNewCircle: circle ?? InitialCircle()}));
}

const setCurrentUpdateCircle = set => circle => {
    set(state => ({...state, currentUpdateCircle: circle ?? InitialCircle()}));
}

export const useCircleStore = create(set => ({
    //State
    currentNewCircle: InitialCircle(),
    currentUpdateCircle: InitialCircle(),
    //Reducers
    setCurrentNewCircle: setCurrentNewCircle(set),
    setCurrentUpdateCircle: setCurrentUpdateCircle(set),
}))
