import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';
import MainLayout from '../../home/AppLayout';
import Loadable from '../../shared/components/Loadable';
import {PATH_POTENTIAL_WATER_AVAILABILITY} from './potential.water.availability.path';
import quantitativeObservationTypeRouter from "./quantitative_observation_type/quantitative.observation.type.router";
import staticReserveWaterBodyRouter from "./static_reserve_water_body/static.reserve.water.body.router";
import quantitativeObservationStationRouter from "./quantitative_observation_station/quantitativeObservationStationRouter";
import quantitativeObservationalDataRouter from "./quantitative_observational_data/quantitativeObservationalDataRouter";
import renewableWaterResourceRouter from "./renewable_water_resource/renewableWaterResourceRouter";

const PotentialWaterAvailabilityHome = Loadable(lazy(() => import('./PotentialWaterAvailabilityHome')));

const potentialWaterAvailabilityRouter = [{
    path: PATH_POTENTIAL_WATER_AVAILABILITY.root,
    element: <MainLayout/>,
    children: [
        {
            path: PATH_POTENTIAL_WATER_AVAILABILITY.root,
            element: <Navigate to={PATH_POTENTIAL_WATER_AVAILABILITY.home} replace/>
        },
        {path: 'home', element: <PotentialWaterAvailabilityHome/>},
        ...quantitativeObservationTypeRouter,
        ...staticReserveWaterBodyRouter,
        ...quantitativeObservationStationRouter,
        ...quantitativeObservationalDataRouter,
        ...renewableWaterResourceRouter
    ]
},
];
export default potentialWaterAvailabilityRouter;
