import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Add} from '@mui/icons-material';
import { Card, Button, Container } from '@mui/material';
import {PATH_RISK_PROTECTION} from '../../risk.protection.path';
import {Page} from '../../../../shared/components';
import {AmenagementTypeTable, AmenagementTypeListHeaderBreadcrumbs} from '../components';

export default function AmenagementTypeList() {
    return (
        <Page title="Types d'aménagement/Protection contre les risques liés aux RE: Liste des types d'aménagement/Protection contre les risques liés aux RE">
            <Container maxWidth={'xl'}>
                <AmenagementTypeListHeaderBreadcrumbs
                    heading="Types d'aménagement/Protection contre les risques liés aux RE"
                    lastName="Liste des types d'aménagement/Protection contre les risques liés aux RE"
                    action={
                        <Button
                            variant="contained"
                            component={RouterLink}
                            to={PATH_RISK_PROTECTION.amenagementTypes + '/new'}
                            startIcon={<Add/>}>
                            Nouveau
                        </Button>
                    }
                />

                <Card>
                    <AmenagementTypeTable/>
                </Card>
            </Container>
        </Page>
    );
}
