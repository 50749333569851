import React from 'react';
// material
import PlaceIcon from '@mui/icons-material/Place';
// utils
import {PATH_ADMINISTRATIVE_DIVISION} from '../../administrative_division.path';
import {useCountLocalities} from '../services/locality.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function LocalityEntryButton() {
    const {count} = useCountLocalities();
    return (
        <SubModuleEntryButton
            label='Localites'
            value={count}
            url={PATH_ADMINISTRATIVE_DIVISION.localities}
            icon={<PlaceIcon/>}
        />
    );
}
