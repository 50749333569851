import {useEffect} from "react";


export const useAutoRefresh = ({source, handler}) => {

    useEffect(() => {
        if(!source) return;

        const {autoRefresh, refreshDelay} = source;

        if(!autoRefresh) return;

        if(!refreshDelay || refreshDelay <= 0) return;

        const id = setInterval(() => {
            handler();
        }, refreshDelay * 1000);

        return () => clearInterval(id);
    }, [source]);

}