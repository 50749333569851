import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const WaterManagementList = Loadable(lazy(() => import("./pages/WaterManagementList")));
const WaterManagementCreate = Loadable(lazy(() => import("./pages/WaterManagementCreate")));
const WaterManagementEdit = Loadable(lazy(() => import("./pages/WaterManagementEdit")));
const WaterManagementDisplay = Loadable(lazy(() => import("./pages/WaterManagementDisplay")));

const waterManagementEntityRouter = [
  { path: "water-managements", element: <WaterManagementList /> },
  { path: "water-managements/:id", element: <WaterManagementDisplay /> },
  { path: "water-managements/new", element: <WaterManagementCreate /> },
  { path: "water-managements/:id/edit", element: <WaterManagementEdit /> },
];

export default waterManagementEntityRouter;
