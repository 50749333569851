import {gql, useQuery, useLazyQuery} from "@apollo/client";
import {parseCount, parseEntity} from "../../../../utils/gql.utils";
import {useEffect, useState} from "react";
import {useMessage} from "../../../../shared/hooks/useMessage";

export let needRefresh = false;
export const setRefresh = (value) => (needRefresh = value);
const IrrigatedDevelopmentEntityUsagesFields = `id name code description 
cultureType ahaType 
ahaTypeObj {id name} 
dataRefYear dataRefYearObj {id name}
culturalSeason averageProductionCost
dataSource`;

///////////////////// Find By ID /////////////////////
const GET_IRRIGATED_DEVELOPMENT_ENTITY_USAGES_BY_ID = gql`
    query IrrigatedDevelopmentEntityUsagesById($id: Int!) {
        irrigatedDevelopmentEntityUsagesById(id: $id) {
            error message entity { ${IrrigatedDevelopmentEntityUsagesFields} }
        }
    }
`;

export const useIrrigatedDevelopmentEntityUsagesById = (id) => {
    id = parseInt(id);
    return useQuery(GET_IRRIGATED_DEVELOPMENT_ENTITY_USAGES_BY_ID, {
        variables: {id},
        fetchPolicy: "network-only",
    });
};

export const useIrrigatedDevelopmentEntityUsages = (id) => {
    const result = useIrrigatedDevelopmentEntityUsagesById(id);
    return parseEntity(result, "irrigatedDevelopmentEntityUsagesById");
};

///////////////////// Search /////////////////////
const SEARCH_IRRIGATED_DEVELOPMENT_ENTITY_USAGES_BY_ID = gql`
    query SearchIrrigatedDevelopmentEntityUsages($query: String, $ahaType: Int, $dataRefYear: Int, $page: Int, $size: Int) {
        searchIrrigatedDevelopmentEntityUsages(query: $query,ahaType:$ahaType, dataRefYear:$dataRefYear, page: $page, size: $size) {
            error message page {
                content { ${IrrigatedDevelopmentEntityUsagesFields} }
                page hasNext hasPrevious totalPages totalElements numberOfElements
            }
        }
    }
`;

export const useSearchIrrigatedDevelopmentEntityUsages = ({
                                                              query,
                                                              ahaType,
                                                              dataRefYear,
                                                              page = 0,
                                                              size = 20,
                                                          }) => {
    const [irrigatedDevelopmentEntityUsages, setIrrigatedDevelopmentEntityUsages] =
        useState([]);
    const [
        irrigatedDevelopmentEntityUsagesPage,
        setIrrigatedDevelopmentEntityUsagesPage,
    ] = useState(null);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] =
        useLazyQuery(SEARCH_IRRIGATED_DEVELOPMENT_ENTITY_USAGES_BY_ID, {
            variables: {query, ahaType, dataRefYear, page, size},
        });

    useEffect(() => {
        if (_error) {
            showError("Erreur lors de l'operation");
            setIrrigatedDevelopmentEntityUsagesPage(null);
            setIrrigatedDevelopmentEntityUsages([]);
            return;
        }

        if (!searchData) return;

        const {error, message, page} =
            searchData.searchIrrigatedDevelopmentEntityUsages;

        if (error) {
            showError(message);
            setIrrigatedDevelopmentEntityUsagesPage(null);
            setIrrigatedDevelopmentEntityUsages([]);
        } else {
            setIrrigatedDevelopmentEntityUsages(page.content);
            setIrrigatedDevelopmentEntityUsagesPage(page);
        }

        if (needRefresh) {
            refetch();
            setRefresh(false);
        }
    }, [_error, searchData, needRefresh, refetch, showError]);

    useEffect(() => {
        search({variables: {query, ahaType, dataRefYear, page, size}});
    }, [query, ahaType, dataRefYear, page, size]);

    return {
        irrigatedDevelopmentEntityUsages,
        loading,
        search,
        refetch,
        irrigatedDevelopmentEntityUsagesPage,
    };
};

///////////////////// Count /////////////////////
const COUNT_IRRIGATED_DEVELOPMENT_ENTITY_USAGES = gql`
  query CountIrrigatedDevelopmentEntityUsages {
    countIrrigatedDevelopmentEntityUsages {
      error
      message
      count
    }
  }
`;

export const useCountIrrigatedDevelopmentEntityUsages = () => {
    const result = useQuery(COUNT_IRRIGATED_DEVELOPMENT_ENTITY_USAGES, {
        fetchPolicy: "network-only",
    });
    return parseCount(result, "countIrrigatedDevelopmentEntityUsages");
};
