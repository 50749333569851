// material
import React, {useState} from 'react';
import {Delete} from '@mui/icons-material';
import {
    Button, Dialog, DialogTitle, DialogActions, DialogContent,
    DialogContentText, MenuItem, ListItemIcon, ListItemText
} from '@mui/material';

export default function AlertDialog(
    {title, message, open, onClose, yesMessage, noMessage, onYes, onNo, yesSx, noSx}
) {

    console.log(title);

    noSx = noSx ?? {color: 'red'};

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title ?? 'Suppression'}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message ?? 'Voulez-vous vraiment supprimer cette ligne'}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button sx={yesSx} onClick={onNo}>{noMessage ?? 'Non'}</Button>
                <Button sx={noSx} onClick={onYes} autoFocus> {yesMessage ?? 'Oui'} </Button>
            </DialogActions>
        </Dialog>
    );
}

export const ConfirmationMenuItem = ({label, icon, onConfirm, onCancel, DialogProps}) => {
    const [open, setOpen] = useState(false);

    DialogProps = DialogProps ?? {};

    return (
      <>
          <MenuItem onClick={() => setOpen(true)} sx={{ color: 'text.secondary' }}>
              <ListItemIcon>
                  {icon ?? <Delete sx={{color: 'red'}} />}
              </ListItemIcon>
              <ListItemText primary={label ?? 'Supprimer'} primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>

          <AlertDialog
              {...{...DialogProps}}
              open={open}

              onYes={() => {
                  setOpen(false);
                  onConfirm && onConfirm();
              }}
              onNo={() => {
                  setOpen(false);
                  onCancel && onCancel();
              }}
              onClose={() => {
                  setOpen(false);
                  onCancel && onCancel();
              }}
          />

      </>
    );
}

export const useDeleteDialog = () => {
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState();

    const handleDelete = (id) => {
        setIdToDelete(id);
        setDeleteConfirmOpen(true);
    }

    return {
        deleteConfirmOpen, setDeleteConfirmOpen, idToDelete, handleDelete
    }
}
