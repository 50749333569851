import React from 'react';
import SecurityIcon from '@mui/icons-material/Security';
import {path} from '../../home/sidebar/paths';

//Features
const RISK_PROTECTION = '/risk-protection';
const _path = path(RISK_PROTECTION);
export const PATH_RISK_PROTECTION = {
    root: RISK_PROTECTION,
    home: _path('/home'),
    riskTypes: _path("/risk-types"),
    sicknessTypes: _path("/sickness-types"),
    amenagementTypes: _path("/amenagement-types"),
    riskManagementToolTypes: _path("/risk-management-tool-types"),
    riskManagementTools: _path("/risk-management-tools"),
    healthAreas: _path("/health-areas"),
    riskManagementToolApplicationDegrees: _path("/risk-management-tool-application-degrees"),
    reliefActions: _path("/relief-actions"),
    amenagementProtections: _path("/amenagement-protections"),
    eventLossOccurrences: _path("/event-loss-occurrences"),
    riskManagementToolsImplementationOccurrences: _path("/risk-management-tools-implementation-occurrences"),
};

export const  riskProtectionMenu = [
    {
        title: 'Risques et protections',
        path: PATH_RISK_PROTECTION.root,
        icon: <SecurityIcon/>,
        children: [
            { title: 'Accueil', path: PATH_RISK_PROTECTION.home },
            { title: 'Type de risque', path: PATH_RISK_PROTECTION.riskTypes },
            { title: 'Types de maladies', path: PATH_RISK_PROTECTION.sicknessTypes },
            { title: 'Types d\'aménagement/Protection', path: PATH_RISK_PROTECTION.amenagementTypes },
            { title: 'Types outils de gestion de risque', path: PATH_RISK_PROTECTION.riskManagementToolTypes },
            { title: 'Outils de gestion de risques', path: PATH_RISK_PROTECTION.riskManagementTools },
            { title: 'Entités aires de santé', path: PATH_RISK_PROTECTION.healthAreas },
            { title: 'Degré d\'application des outils de gestion', path: PATH_RISK_PROTECTION.riskManagementToolApplicationDegrees },
            { title: 'Actions de sécours(DGPC/DGSHP)', path: PATH_RISK_PROTECTION.reliefActions },
            { title: 'Aménagement et protection', path: PATH_RISK_PROTECTION.amenagementProtections },
            // { title: 'Occurrences d\'évenements et de pertes', path: PATH_RISK_PROTECTION.eventLossOccurrences },
            // { title: 'Occurrences gestion de risques liés à l\'eau', path: PATH_RISK_PROTECTION.riskManagementToolsImplementationOccurrences },
        ]
    }
];

export const commonItems = [
    { name: "Accueil", href: "/" },
    { name: "Risque et protection en RE", href: PATH_RISK_PROTECTION.home },
];
