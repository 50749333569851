import React from 'react';
import {Power} from '@mui/icons-material';
import {path} from '../../home/sidebar/paths';

//Features
const ENERGY = '/energies';
const _path = path(ENERGY);
export const PATH_ENERGY = {
    root: ENERGY,
    home: _path('/home'),
    productionTypes: _path("/production-types"),
    energyProductions: _path("/energy-productions"),
    variationOccurrences: _path("/variation-occurrences"),
    productionOccurrences: _path("/production-occurrences"),
    occurrenceUses: _path("/occurrence-uses"),

};




export const  energyMenu = [
    {
        title: 'Energie',
        path: PATH_ENERGY.root,
        icon: <Power/>,
        children: [
            { title: 'Accueil', path: PATH_ENERGY.home },
            { title: 'Productions d\'énergies', path: PATH_ENERGY.energyProductions },
            { title: 'Types productions', path: PATH_ENERGY.productionTypes },
        ]
    }
];

export const commonItems = [
    { name: "Accueil", href: "/" },
    { name: "Energie", href: PATH_ENERGY.home },
];