import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const GroupList = Loadable(lazy(() => import('./pages/GroupList')));
const GroupCreate = Loadable(lazy(() => import('./pages/GroupCreate')));
const GroupEdit = Loadable(lazy(() => import('./pages/GroupEdit')));
const GroupDisplay = Loadable(lazy(() => import('./pages/GroupDisplay')));

const groupRouter = [
    {path: 'groups', element: <GroupList/>},
    {path: 'groups/:id', element: <GroupDisplay/>},
    {path: 'groups/new', element: <GroupCreate/>},
    {path: 'groups/:id/edit', element: <GroupEdit/>},
];

export default groupRouter;
