import * as Yup from 'yup';
import create from 'zustand';


export const RiskManagementToolSchema = Yup.object().shape({
    name: Yup.string().default('').required('Le nom est obligatoire'),
    dataRefYear: Yup.string().required("L'année de reférence des données est obligatoire"),
    riskManagementToolType: Yup.string().required("Types outils de gestion de risques liées à l'eau"),
    dataSource: Yup.string().default(' '),
});

const initialRiskManagementTool = RiskManagementToolSchema.getDefaultFromShape();
export const InitialRiskManagementTool = () => ({...initialRiskManagementTool});

const setCurrentNewRiskManagementTool = set => riskManagementTool => {
    set(state => ({...state, currentNewRiskManagementTool: riskManagementTool ?? InitialRiskManagementTool()}));
}

const setCurrentUpdateRiskManagementTool = set => riskManagementTool => {
    set(state => ({...state, currentUpdateRiskManagementTool: riskManagementTool ?? InitialRiskManagementTool()}));
}

export const useRiskManagementToolStore = create(set => ({
    //State
    currentNewRiskManagementTool: InitialRiskManagementTool(),
    currentUpdateRiskManagementTool: InitialRiskManagementTool(),
    //Reducers
    setCurrentNewRiskManagementTool: setCurrentNewRiskManagementTool(set),
    setCurrentUpdateRiskManagementTool: setCurrentUpdateRiskManagementTool(set),
}))
