import React from 'react';
// material
import MergeTypeIcon from '@mui/icons-material/MergeType';
// utils
import {PATH_IRRIGATION_NEED_WATER} from '../../irrigation.need.water.path';
import {useCountAhaTypes} from '../services/aha.type.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AhaTypeEntryButton() {
    const {count} = useCountAhaTypes();
  return (
      <SubModuleEntryButton
          label='Type aménagements Hydro-agricoles'
          value={count}
          url={PATH_IRRIGATION_NEED_WATER.aha_types}
          icon={<MergeTypeIcon />}
      />
  );
}
