import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {useMessage} from '../../../../../shared/hooks/useMessage';
import {useNavigate} from 'react-router-dom';
import {Form, FormikProvider, useFormik} from 'formik';
// material
import {LoadingButton} from '@mui/lab';
import {
    Card, Stack, TextField, Box, Button
} from '@mui/material';
// routes
import {PATH_REPORTING} from '../../../reporting.path';
import {FormRowStack} from '../../../../../shared/components/froms/FormComponents';
import {setRefresh} from '../services/reporting.gql';

import useReportingService from '../services/reporting.services';
// ----------------------------------------------------------------------

ReportingForm.propTypes = {
    isEdit: PropTypes.bool,
    currentReporting: PropTypes.object,
    fromDialog: PropTypes.object,
};

export default function ReportingForm({isEdit, currentReporting, fromDialog}) {
    const navigate = useNavigate();
    const {showSuccess, showError} = useMessage();
    const {create, update} = useReportingService();

    const ReportingSchema = Yup.object().shape({
        label: Yup.string().required('Le libelle est obligatoire'),
        description: Yup.string(),
        request: Yup.string().required('La reequete est obligatoire'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: ReportingSchema,
        initialValues: {
            label: currentReporting?.label || '',
            description: currentReporting?.description || '',
            request: currentReporting?.request || '',
        },
        onSubmit: async (reporting, {setSubmitting, resetForm, setErrors}) => {
            try {
                reporting = ReportingSchema.cast(reporting);

                let message;
                if (!isEdit) {
                    await create(reporting);
                    message = 'Création avec succès';
                } else {
                    await update(currentReporting.id, reporting);
                    message = 'Modification avec succès';
                }

                setRefresh(true);

                resetForm();

                setSubmitting(false);
                showSuccess(message);

                if (fromDialog) {
                    fromDialog.onSave();
                } else {
                    navigate(PATH_REPORTING.reporting);
                }
            } catch (error) {
                console.error(error);
                setSubmitting(false);
                showError(!isEdit ? 'Erreur lors de la creation' : 'Erreur lors de la modification');
            }
        }
    });

    const onCancel = (_) => {
        if (fromDialog) {
            fromDialog.onCancel();
        } else {
            navigate(PATH_REPORTING.reporting);
        }
    }

    const {errors, touched, handleSubmit, isSubmitting, getFieldProps} = formik;

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>

                <Card sx={{p: 3}}>

                    <Stack spacing={3}>

                        <FormRowStack>
                            <TextField
                                fullWidth
                                label='Libelle'
                                {...getFieldProps('label')}
                                error={Boolean(touched.label && errors.label)}
                                helperText={touched.label && errors.label}
                            />
                        </FormRowStack>

                        <FormRowStack>
                            <TextField
                                fullWidth
                                multiline
                                minRows={2}
                                maxRows={6}
                                label='Description'
                                {...getFieldProps('description')}
                                error={Boolean(touched.description && errors.description)}
                                helperText={touched.description && errors.description}
                            />
                        </FormRowStack>

                        <FormRowStack>
                            <TextField
                                fullWidth
                                multiline
                                minRows={4}
                                maxRows={8}
                                label='Requete'
                                {...getFieldProps('request')}
                                error={Boolean(touched.request && errors.request)}
                                helperText={touched.request && errors.request}
                            />
                        </FormRowStack>

                        <Box sx={{mt: 3, display: 'flex', justifyContent: 'flex-end'}}>
                            <Button
                                sx={{mr: 2}}
                                size='medium'
                                onClick={onCancel}
                                disabled={isSubmitting}
                            >
                                {'Annuler'}
                            </Button>
                            <LoadingButton type='submit' variant='contained' size='medium' loading={isSubmitting}>
                                {!isEdit ? 'Créer' : 'Modifier'}
                            </LoadingButton>
                        </Box>
                    </Stack>

                </Card>

            </Form>
        </FormikProvider>
    );
}
