import React from 'react';
// material
import {Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {InstrumentPolicyOccurrenceForm} from '../components';

import {InstrumentPolicyOccurrenceHeaderBreadcrumbs} from '../components';
import {useInstrumentPolicyOccurrenceStore} from "../services/instrument.policy.occurrence.store";

// ----------------------------------------------------------------------

export default function InstrumentPolicyOccurrenceCreate() {
    const {currentNewInstrumentPolicyOccurrence: currentInstrumentPolicyOccurrence, setCurrentNewInstrumentPolicyOccurrence: setCurrentInstrumentPolicyOccurrence} = useInstrumentPolicyOccurrenceStore();
    return (
        <Page title="Gire environment favorable: Creation occurrences d'état de mise en œuvre des instruments de politique et stratégie eau">
            <Container  maxWidth={'xl'}>
                <InstrumentPolicyOccurrenceHeaderBreadcrumbs
                    heading="Creation de nouvelle occurrences d'état de mise en œuvre des instruments de politique et stratégie eau"
                    lastName="Nouvelle occurrence d'état de mise en œuvre des instruments de politique et stratégie eau"
                />
                <InstrumentPolicyOccurrenceForm {...{currentInstrumentPolicyOccurrence, setCurrentInstrumentPolicyOccurrence}}/>
            </Container>
        </Page>
    );
}
