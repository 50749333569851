import {gql, useLazyQuery, useQuery} from "@apollo/client";
import {parseCount, parseEntity} from "../../../../utils/gql.utils";
import {useEffect, useState} from "react";
import {useMessage} from "../../../../shared/hooks/useMessage";

export let needRefresh = false;
export const setRefresh = (value) => (needRefresh = value);

export const localityFields = `
    id name commune localityType name2 codagis 
    numOrder codeC latitudeDescription longitudeDescription
    latitudeText longitudeText codeCarte altitude existMarche
    xCoordinate yCoordinate population  
    livestock livestockYear currentLivestock 
    populationYear currentPopulation householdNumber concessionNumber 
    borderVillage remark planNum
    villageStatus villageStatusObj {id wording} 
    environment environmentObj {id wording}
    originPopulation originPopulationObj {id wording}
    communeObj {id name}  localityTypeObj {id name}
`;

///////////////////// Find By ID /////////////////////
const GET_LOCALITY_BY_ID = gql`
    query LocalityById($id: Int!) {
        localityById(id: $id) {
            error message entity { ${localityFields} }
        }
    }
`;

export const useLocalityById = (id) => {
    id = parseInt(id);
    return useQuery(GET_LOCALITY_BY_ID, {
        variables: {id},
    });
};

export const useLocality = (id) => {
    const result = useLocalityById(id);
    return parseEntity(result, "localityById");
};

///////////////////// Search /////////////////////
const SEARCH_LOCALITIES = gql`
    query SearchLocalities($query: String, $commune: Int, $localityType: Int,
       $villageStatus: Int, $environment: Int,
      $originPopulation: Int,$page: Int, $size: Int) {
        searchLocalities(query: $query, commune: $commune, localityType: $localityType,
         villageStatus: $villageStatus, environment: $environment,
      originPopulation: $originPopulation, page: $page, size: $size) {
            error message page {
                content { ${localityFields} }
                page hasNext hasPrevious totalPages, totalElements, numberOfElements,
            }
        }
    }
`;

export const useSearchLocalities = ({
                                        query = "",
                                        commune,
                                        localityType,
                                        villageStatus,
                                        environment,
                                        originPopulation,
                                        page = 0,
                                        size = 20,
                                    }) => {
    const [localities, setLocalities] = useState([]);
    const [localityPage, setLocalityPage] = useState(null);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] =
        useLazyQuery(SEARCH_LOCALITIES, {
            variables: {
                query,
                commune,
                localityType,
                villageStatus,
                environment,
                originPopulation,
                page,
                size,
            },
        });

    useEffect(() => {
        if (_error) {
            console.log("##### _error: ", _error);
            showError("Erreur lors de l'operation");
            setLocalityPage(null);
            setLocalities([]);
            return;
        }

        if (!searchData) return;

        const {error, message, page} = searchData.searchLocalities;

        if (error) {
            console.log("##### error: ", error);
            showError(message);
            setLocalityPage(null);
            setLocalities([]);
        } else {
            setLocalityPage(page);
            setLocalities(page.content);
        }

        if (needRefresh) {
            refetch();
            setRefresh(false);
        }
    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({
            variables: {
                query,
                commune,
                localityType,
                villageStatus,
                environment,
                originPopulation,
                page,
                size,
            },
        });
    }, [
        query,
        commune,
        localityType,
        villageStatus,
        environment,
        originPopulation,
        page,
        size,
    ]);

    return {
        localities,
        loading,
        search,
        refetch,
        localityPage,
    };
};

///////////////////// Count /////////////////////
const COUNT_LOCALITIES = gql`
  query CountLocalities {
    countLocalities {
      error
      message
      count
    }
  }
`;

export const useCountLocalities = () => {
    const result = useQuery(COUNT_LOCALITIES, {
        fetchPolicy: "network-only",
    });
    return parseCount(result, "countLocalities");
};
