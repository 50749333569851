import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const InfrastructureList = Loadable(
  lazy(() => import("./pages/InfrastructureList"))
);
const InfrastructureCreate = Loadable(
  lazy(() => import("./pages/InfrastructureCreate"))
);
const InfrastructureEdit = Loadable(
  lazy(() => import("./pages/InfrastructureEdit"))
);
const InfrastructureDisplay = Loadable(
  lazy(() => import("./pages/InfrastructureDisplay"))
);

const infrastructureRouter = [
  { path: "infrastructures", element: <InfrastructureList /> },
  { path: "infrastructures/:id", element: <InfrastructureDisplay /> },
  { path: "infrastructures/new", element: <InfrastructureCreate /> },
  { path: "infrastructures/:id/edit", element: <InfrastructureEdit /> },
];

export default infrastructureRouter;
