import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const OccurrencesOfWaterNeedList = Loadable(
  lazy(() => import("./pages/OccurrencesOfWaterNeedList"))
);
const OccurrencesOfWaterNeedCreate = Loadable(
  lazy(() => import("./pages/OccurrencesOfWaterNeedCreate"))
);
const OccurrencesOfWaterNeedEdit = Loadable(
  lazy(() => import("./pages/OccurrencesOfWaterNeedEdit"))
);
const OccurrencesOfWaterNeedDisplay = Loadable(
  lazy(() => import("./pages/OccurrencesOfWaterNeedDisplay"))
);

const occurrencesOfWaterNeedRouter = [
  {
    path: "occurrences-of-water-need",
    element: <OccurrencesOfWaterNeedList />,
  },
  {
    path: "occurrences-of-water-need/:id",
    element: <OccurrencesOfWaterNeedDisplay />,
  },
  {
    path: "occurrences-of-water-need/new",
    element: <OccurrencesOfWaterNeedCreate />,
  },
  {
    path: "occurrences-of-water-need/:id/edit",
    element: <OccurrencesOfWaterNeedEdit />,
  },
];

export default occurrencesOfWaterNeedRouter;
