import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableRow, Typography} from '@mui/material';

import {useSearchStatusCommunes} from '../services/status.commune.gql';
import useStatusCommuneService from '../services/status.commune.services';
import {PATH_ADMINISTRATIVE_DIVISION} from '../../administrative_division.path';
import {StatusCommuneMoreMenu} from './index';
import {MyTablePagination, SortableTableHead, usePagination} from "../../../../shared/components/tables";
import {useDelete} from "../../../../shared/hooks/useDelete";
import {
    SimpleTableToolbar, TableCellLink, sxTableRow
} from "../../../../shared/components/tables/components.table.utils";

const TABLE_HEAD = [
    {id: 'id', label: 'ID', alignRight: false},
    {id: 'name', label: 'Nom', alignRight: false},
    {id: 'description', label: 'Description', alignRight: false},
    {id: ''}
];

export default function StatusCommuneTable() {
    const {
        page, order, filterName, rowsPerPage, orderBy, handleRequestSort,
        handleChangePage, handleChangeRowsPerPage, handleFilterByName
    } = usePagination();

    const {statusCommunes, refetch, statusCommunesPage} = useSearchStatusCommunes({
        query: filterName, size: rowsPerPage, page
    });

    const {destroy} = useStatusCommuneService();
    const {onDelete} = useDelete({
        destroyFn: destroy, onSuccess: refetch
    });

    return (
        <>
            <SimpleTableToolbar
                value={filterName}
                onRefresh={refetch}
                onChange={handleFilterByName}
                placeholder='Recherche status'
            />

            <TableContainer sx={{minWidth: 800}}>
                <Table size='small'>
                    <SortableTableHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {statusCommunes.map((row) => (<Row key={row.id} {...{row, onDelete}}/>))}
                    </TableBody>
                </Table>
            </TableContainer>

            <MyTablePagination
                page={statusCommunesPage}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}

function Row({row, onDelete}) {
    const {id, name, description} = row;

    return (
        <TableRow hover sx={sxTableRow}>
            <TableCell component='th' scope='row'>
                <TableCellLink to={PATH_ADMINISTRATIVE_DIVISION.statusCommunes + '/' + id} value={id}/>
            </TableCell>
            <TableCell component='th' scope='row'>
                <TableCellLink to={PATH_ADMINISTRATIVE_DIVISION.statusCommunes + '/' + id} value={name}/>
            </TableCell>

            <TableCell component='th' scope='row'>
                <Typography variant='body2' noWrap>
                    {description}
                </Typography>
            </TableCell>
            <TableCell align="right">
                <StatusCommuneMoreMenu id={id} onDelete={onDelete} currentStatusCommune={row} isIconButton hiddenNew/>
            </TableCell>
        </TableRow>
    );
}
