import React, {useRef, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
// material
import {alpha} from '@mui/material/styles';
import {Button, Box, Divider, MenuItem, Typography, Avatar, IconButton} from '@mui/material';
import {AccountCircle, Home, Settings} from '@mui/icons-material';
// components
import {MenuPopover} from '../../shared/components';
// services
import useAuth from '../../shared/hooks/useAuth';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: 'Accueil',
        icon: Home,
        linkTo: '/'
    },
    {
        label: 'Profile',
        icon: AccountCircle,
        linkTo: '#'
    },
    {
        label: 'Settings',
        icon: Settings,
        linkTo: '#'
    }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const {login, logout, isAuthenticated, user} = useAuth();

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {!isAuthenticated && <Box sx={{pt: 1.5}}>
                <Button variant='outlined' onClick={login}>Se connecter</Button>
            </Box>}
            {isAuthenticated && <>
                <IconButton
                    ref={anchorRef}
                    onClick={handleOpen}
                    sx={{
                        padding: 0,
                        width: 44,
                        height: 44,
                        ...(open && {
                            '&:before': {
                                zIndex: 1,
                                content: "''",
                                width: '100%',
                                height: '100%',
                                borderRadius: '50%',
                                position: 'absolute',
                                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
                            }
                        })
                    }}
                >
                    {/*<Avatar src={account.photoURL} alt="photoURL"/>*/}
                    <Avatar>
                        <AccountCircle/>
                    </Avatar>
                </IconButton>

                <MenuPopover
                    open={open}
                    onClose={handleClose}
                    anchorEl={anchorRef.current}
                    sx={{width: 220}}
                >
                    <Box sx={{my: 1.5, px: 2.5}}>
                        <Typography variant="subtitle1" noWrap>
                            {user.getFullName() ?? 'Utilisateur'}
                        </Typography>
                        <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
                            {user.getEmail()}
                        </Typography>
                    </Box>

                    <Divider sx={{my: 1}}/>

                    {MENU_OPTIONS.map((option) => (
                        <MenuItem
                            key={option.label}
                            to={option.linkTo}
                            component={RouterLink}
                            onClick={handleClose}
                            sx={{typography: 'body2', py: 1, px: 2.5}}
                        >
                            <Box
                                component={option.icon}
                                sx={{
                                    mr: 2,
                                    width: 24,
                                    height: 24
                                }}
                            />

                            {option.label}
                        </MenuItem>
                    ))}

                    <Box sx={{p: 2, pt: 1.5}}>
                        <Button fullWidth color="inherit" variant="outlined" onClick={logout}>
                            Se deconnecter
                        </Button>
                    </Box>
                </MenuPopover>
            </>}
        </>
    );
}

