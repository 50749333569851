import React, { lazy } from "react";
import { Navigate } from "react-router-dom";

import MainLayout from "../../home/AppLayout";
import Loadable from "../../shared/components/Loadable";

import { PATH_WATER_QUALITY } from "./water.quality.path";
import waterQualityObservationTypeRouter from "./water_quality_observation_type/water.quality.observation.type.router";
import waterQualityObservationStationsRouter from "./water_quality_observation_stations/water.quality.observation.stations.router";
import waterQualityObservationOccurrencesRouter from "./water_quality_observation_occurrences/water.quality.observation.occurrences.router";
import waterQualityControlParametersRouter from "./water_quality_control_parameters/water.quality.control.parameters.router";
import pointsForMonitoringTheQualityOfTheWaterDistributedAndPollutionControlRouter
  from "./points_for_monitoring_the_quality_of_the_water_distributed_and_pollution_control/points.for.monitoring.the.quality.of.the.water.distributed.and.pollution.control.router";
import meaningfulAreasOfDrinkingWaterQualityControlPointsRouter
  from "./meaningful_areas_of_drinking_water_quality_control_points/meaningful.areas.of.drinking.water.quality.control.points.router";

const WaterQualityHome = Loadable(lazy(() => import("./WaterQualityHome")));

const waterQualityRouter = [
  {
    path: PATH_WATER_QUALITY.root,
    element: <MainLayout />,
    children: [
      {
        path: PATH_WATER_QUALITY.root,
        element: <Navigate to={PATH_WATER_QUALITY.home} replace />,
      },
      { path: "home", element: <WaterQualityHome /> },
      ...waterQualityObservationTypeRouter,
      ...waterQualityControlParametersRouter,
      ...waterQualityObservationStationsRouter,
      ...waterQualityObservationOccurrencesRouter,
      ...pointsForMonitoringTheQualityOfTheWaterDistributedAndPollutionControlRouter,
      ...meaningfulAreasOfDrinkingWaterQualityControlPointsRouter,
    ],
  },
];

export default waterQualityRouter;
