import React from 'react';
// material
import {Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {InstitutionalOrganizationalFrameworkStructureTypeForm} from '../components';

import {InstitutionalOrganizationalFrameworkStructureTypeHeaderBreadcrumbs} from '../components';
import {useInstitutionalOrganizationalFrameworkStructureTypeStore} from "../services/institutional.organizational.framework.structure.type.store";

// ----------------------------------------------------------------------

export default function InstitutionalOrganizationalFrameworkStructureTypeCreate() {
    const {currentNewInstitutionalOrganizationalFrameworkStructureType: currentInstitutionalOrganizationalFrameworkStructureType, setCurrentNewInstitutionalOrganizationalFrameworkStructureType: setCurrentInstitutionalOrganizationalFrameworkStructureType} = useInstitutionalOrganizationalFrameworkStructureTypeStore();
    return (
        <Page title="Gire environment favorable: Création type de structures de cadre institutionnel et organisationnel eau">
            <Container  maxWidth={'xl'}>
                <InstitutionalOrganizationalFrameworkStructureTypeHeaderBreadcrumbs
                    heading="Création de nouveau type de structures de cadre institutionnel et organisationnel eau"
                    lastName="Nouveau type de structures de cadre institutionnel et organisationnel eau"
                />
                <InstitutionalOrganizationalFrameworkStructureTypeForm {...{currentInstitutionalOrganizationalFrameworkStructureType, setCurrentInstitutionalOrganizationalFrameworkStructureType}}/>
            </Container>
        </Page>
    );
}
