import React from 'react';
import {Link as RouterLink} from 'react-router-dom';

import {Refresh, Search} from '@mui/icons-material';
import {
    TableContainer, Table, TableBody, TableRow, TableCell, IconButton,
    Toolbar, OutlinedInput, InputAdornment, Box, Tooltip, Typography, Link
} from '@mui/material';

import {useSearchCircles} from '../services/circle.gql';
import useCircleService from '../services/circle.services';
import {PATH_ADMINISTRATIVE_DIVISION} from '../../administrative_division.path';
import {CircleCreateDialogMenuItem, CircleMoreMenu} from './index';
import {MyTablePagination, SortableTableHead, usePagination} from '../../../../shared/components/tables';
import {useDelete} from '../../../../shared/hooks/useDelete';
import {sxTableRow, sxTableToolbar} from "../../../../shared/components/tables/components.table.utils";

const TABLE_HEAD = [
    {id: 'id', label: 'ID', alignRight: false},
    {id: 'name', label: 'Nom', alignRight: false},
    {id: 'region', label: 'Region', alignRight: false},
    {id: ''}
];

export default function CircleTable({region, currentRegion}) {
    const {
        page, order, filterName, rowsPerPage, orderBy, handleRequestSort,
        handleChangePage, handleChangeRowsPerPage, handleFilterByName
    } = usePagination();

    const {circles, refetch, circlePage} = useSearchCircles({
        query: filterName,
        size: rowsPerPage,
        page, region
    });

    const {destroy} = useCircleService();
    const {onDelete} = useDelete({
        destroyFn: destroy, onSuccess: refetch
    });

    return (
        <>
            <Toolbar sx={sxTableToolbar}>
                <OutlinedInput
                    sx={{width: 240}}
                    value={filterName}
                    onChange={handleFilterByName}
                    placeholder='Recherche cercle'
                    startAdornment={
                        <InputAdornment position='start'>
                            <Box component={Search} sx={{color: 'text.disabled'}}/>
                        </InputAdornment>
                    }
                />

                <Box>
                    {currentRegion && (
                        <CircleCreateDialogMenuItem
                            btn
                            region={currentRegion}
                            onSave={(createdCircle) => {
                                console.log(`##### createdCircle: `, createdCircle);
                                refetch();
                            }}
                        />
                    )}
                    <Tooltip title='Actualiser'>
                        <IconButton onClick={() => refetch()}>
                            <Refresh/>
                        </IconButton>
                    </Tooltip>
                </Box>
            </Toolbar>
            <TableContainer sx={{minWidth: 800}}>
                <Table size='small'>
                    <SortableTableHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={circles.length}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {circles.map((row) => (<Row key={row.id} {...{row, onDelete}}/>))}
                    </TableBody>
                </Table>
            </TableContainer>

            <MyTablePagination
                page={circlePage}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
};

function Row({row, onDelete}) {
    const {id, name, regionObj} = row;

    return (
        <TableRow hover sx={sxTableRow}>
            <TableCell component='th' scope='row'>
                <Link
                    to={PATH_ADMINISTRATIVE_DIVISION.circles + '/' + id}
                    component={RouterLink}
                >
                    <Typography variant='body2' noWrap>
                        {id}
                    </Typography>
                </Link>
            </TableCell>

            <TableCell component='th' scope='row'>
                <Link
                    to={PATH_ADMINISTRATIVE_DIVISION.circles + '/' + id}
                    component={RouterLink}
                >
                    <Typography variant='body2' noWrap>
                        {name}
                    </Typography>
                </Link>
            </TableCell>
            <TableCell style={{minWidth: 160}}>
                <Link
                    to={PATH_ADMINISTRATIVE_DIVISION.regions + '/' + regionObj?.id}
                    component={RouterLink}
                >
                    <Typography variant='body2' noWrap>
                        {regionObj?.name}
                    </Typography>
                </Link>
            </TableCell>
            <TableCell align='right'>
                <CircleMoreMenu id={id} onDelete={onDelete} currentCircle={row} isIconButton hiddenNew/>
            </TableCell>
        </TableRow>
    );
}
