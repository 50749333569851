import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const LegislativeTextList = Loadable(lazy(() => import("./pages/LegislativeTextList")));
const LegislativeTextCreate = Loadable(lazy(() => import("./pages/LegislativeTextCreate")));
const LegislativeTextEdit = Loadable(lazy(() => import("./pages/LegislativeTextEdit")));
const LegislativeTextDisplay = Loadable(lazy(() => import("./pages/LegislativeTextDisplay")));

const legislativeTextRouter = [
  { path: "legislative-texts", element: <LegislativeTextList /> },
  { path: "legislative-texts/:id", element: <LegislativeTextDisplay /> },
  { path: "legislative-texts/new", element: <LegislativeTextCreate /> },
  { path: "legislative-texts/:id/edit", element: <LegislativeTextEdit /> },
];

export default legislativeTextRouter;
