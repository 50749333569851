import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const OperationalManagementDeviceList = Loadable(lazy(() => import("./pages/OperationalManagementDeviceList")));
const OperationalManagementDeviceCreate = Loadable(lazy(() => import("./pages/OperationalManagementDeviceCreate")));
const OperationalManagementDeviceEdit = Loadable(lazy(() => import("./pages/OperationalManagementDeviceEdit")));
const OperationalManagementDeviceDisplay = Loadable(lazy(() => import("./pages/OperationalManagementDeviceDisplay")));

const operationalManagementDeviceRouter = [
  { path: "operational-management-devices", element: <OperationalManagementDeviceList /> },
  { path: "operational-management-devices/:id", element: <OperationalManagementDeviceDisplay /> },
  { path: "operational-management-devices/new", element: <OperationalManagementDeviceCreate /> },
  { path: "operational-management-devices/:id/edit", element: <OperationalManagementDeviceEdit /> },
];

export default operationalManagementDeviceRouter;
