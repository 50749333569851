import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const BudgetTypeList = Loadable(
  lazy(() => import("./pages/BudgetTypeList"))
);
const BudgetTypeCreate = Loadable(
  lazy(() => import("./pages/BudgetTypeCreate"))
);
const BudgetTypeEdit = Loadable(
  lazy(() => import("./pages/BudgetTypeEdit"))
);
const BudgetTypeDisplay = Loadable(
  lazy(() => import("./pages/BudgetTypeDisplay"))
);

const budgetTypeRouter = [
  {
    path: "budget-types",
    element: <BudgetTypeList />,
  },
  {
    path: "budget-types/:id",
    element: <BudgetTypeDisplay />,
  },
  {
    path: "budget-types/new",
    element: <BudgetTypeCreate />,
  },
  {
    path: "budget-types/:id/edit",
    element: <BudgetTypeEdit />,
  },
];

export default budgetTypeRouter;
