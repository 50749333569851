import React, {useRef, useState} from 'react';
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material';
import {Delete, MoreVert} from '@mui/icons-material';
import {DisplayDashboardWidgetDialog, EditDashboardWidgetDialog} from './index';


export default function DashboardWidgetMoreMenu({onDelete, onUpdate, dashboard, dashboardWidget}) {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                <MoreVert width={20} height={20}/>
            </IconButton>

            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: {width: 200, maxWidth: '100%'}
                }}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
            >

                <DisplayDashboardWidgetDialog
                    dashboard={dashboard}
                    dashboardWidget={dashboardWidget}
                    onClose={() => {
                        setIsOpen(false);
                    }}
                />

                <EditDashboardWidgetDialog
                    dashboard={dashboard}
                    dashboardWidget={dashboardWidget}
                    onUpdate={() => {
                        onUpdate();
                        setIsOpen(false);
                    }}
                    onCancel={() => {
                        setIsOpen(false);
                    }}
                />

                <MenuItem onClick={() => {
                    onDelete();
                    setIsOpen(false);
                }} sx={{color: 'text.secondary'}}>
                    <ListItemIcon>
                        <Delete/>
                    </ListItemIcon>
                    <ListItemText primary='Supprimer' primaryTypographyProps={{variant: 'body2'}}/>
                </MenuItem>

            </Menu>
        </>
    );
}
