import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';

import MainLayout from '../../home/AppLayout';
import Loadable from '../../shared/components/Loadable';

import {PATH_ORGAN_INSTRUMENT} from './organ.instrument.path';
import typeOrganRouter from "./type_organ/type.organ.router";
import typeInstrumentRouter from "./type_instrument/type.instrument.router";
import typeSphereRouter from "./type_sphere/type.sphere.router";
import entityOrganRouter from "./entity_organ/entity.organ.router";
import managementInstrumentRouter from "./management_instrument/management.instrument.router";
import areaCompetenceOrganRouter from "./area_competence_organ/area.competence.organ.router";
import zoneConcernedRouter from "./zone_concerned/zone.concerned.router";
import stateOccurrenceRouter from "./state_occurrence/state.occurrence.router";
import occurrenceCompositionRouter from "./occurrence_composition/occurrence.composition.router";




const PASTORAL_HOME = Loadable(lazy(() => import('./OrganInstrumentHome')));

const organInstrumentRouter = [{
    path: PATH_ORGAN_INSTRUMENT.root,
    element: <MainLayout/>,
    children: [
        {path: PATH_ORGAN_INSTRUMENT.root, element: <Navigate to={PATH_ORGAN_INSTRUMENT.home} replace/>},
        {path: 'home', element: <PASTORAL_HOME/>},
        ...typeOrganRouter,
        ...typeInstrumentRouter,
        ...typeSphereRouter,
        ...entityOrganRouter,
        ...managementInstrumentRouter,
        ...areaCompetenceOrganRouter,
        ...zoneConcernedRouter,
        ...stateOccurrenceRouter,
        ...occurrenceCompositionRouter,

    ]
},];

export default organInstrumentRouter;
