import React, {lazy} from 'react';
import AhaTypeList from "./pages/AhaTypeList";
import AhaTypeDisplay from "./pages/AhaTypeDisplay";
import AhaTypeCreate from "./pages/AhaTypeCreate";
import AhaTypeEdit from "./pages/AhaTypeEdit";

const ahaTypeRouter = [
    {path: 'aha-types', element: <AhaTypeList/>},
    {path: 'aha-types/:id', element: <AhaTypeDisplay/>},
    {path: 'aha-types/new', element: <AhaTypeCreate/>},
    {path: 'aha-types/:id/edit', element: <AhaTypeEdit/>},
];

export default ahaTypeRouter;
