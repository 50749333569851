import React, {lazy} from 'react';
import LegislativeTextTypeList from "./pages/LegislativeTextTypeList";
import LegislativeTextTypeDisplay from "./pages/LegislativeTextTypeDisplay";
import LegislativeTextTypeCreate from "./pages/LegislativeTextTypeCreate";
import LegislativeTextTypeEdit from "./pages/LegislativeTextTypeEdit";

const legislativeTextTypeRouter = [
    {path: 'legislative-text-types', element: <LegislativeTextTypeList/>},
    {path: 'legislative-text-types/:id', element: <LegislativeTextTypeDisplay/>},
    {path: 'legislative-text-types/new', element: <LegislativeTextTypeCreate/>},
    {path: 'legislative-text-types/:id/edit', element: <LegislativeTextTypeEdit/>},

];
export default legislativeTextTypeRouter;
