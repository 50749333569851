
const index = {
    httpBaseUrl: '/sineau',
    graphQlBaseUrl: '/sineau/graphql',
    keycloakConfig: {
        url: '/auth',
        realm: 'sineau',
        // clientId: 'synergie-web',
        clientId: 'sineau-web',
    }
}

export default index;
