import React from 'react';
import {Box, Link, styled} from "@mui/material";
import {NavLink as RouterLink} from "react-router-dom";

import SearchModuleItem from "./SearchModuleItem";


const RootStyle = styled(Box)(({theme}) => ({
    marginBottom: theme.spacing(1),
}))

const StyledTitleWrapper = styled(Box)(({theme}) => ({
    display: 'flex',
    alignItems: 'center'
}))

const StyledLink = styled(props => (
    <Link underline={'hover'} component={RouterLink} {...props}/>
))(({theme}) => ({
    fontWeight: 500,
    textTransform: 'capitalize',
    marginLeft: theme.spacing(1),
}))

export default function SearchModule({module, onSelectItem}) {
    const {title, path, icon, children} = module;
    return (
        <RootStyle>
            <StyledTitleWrapper>
                {icon}
                <StyledLink to={path} onClick={() => onSelectItem(title)}>
                    {title}
                </StyledLink>
            </StyledTitleWrapper>

            {children?.map(item => (
                <SearchModuleItem key={item.path} item={item} onSelectItem={onSelectItem}/>
            ))}
        </RootStyle>
    );
}
