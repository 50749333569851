import React from "react";
// material
import {} from "@mui/icons-material";
// utils
import SubModuleEntryButton from "../../../../shared/components/SubModuleEntryButton";
import { PATH_GIRE_ENV_FAVORABLE } from "../../gire.env.favorable.path";
import MergeTypeIcon from '@mui/icons-material/MergeType';
import { useCountInstrumentPolicies } from "../services/instrument.policy.gql";

export default function InstrumentPolicyEntryButton() {
  const { count } = useCountInstrumentPolicies();
  return (
    <SubModuleEntryButton
      label={
        count > 1
          ? "Instruments de politique et stratégie eau"
          : "instrument de politique et stratégie eau"
      }
      value={count}
      url={PATH_GIRE_ENV_FAVORABLE.instrumentPolicies}
      icon={<MergeTypeIcon />}
    />
  );
}
