import React from 'react';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import {path} from '../../home/sidebar/paths';

const POTENTIAL_WATER_AVAILABILITY = '/potential-water-availability';
const _path = path(POTENTIAL_WATER_AVAILABILITY);
export const PATH_POTENTIAL_WATER_AVAILABILITY = {
    root: POTENTIAL_WATER_AVAILABILITY,
    home: _path('/home'),
    quantitativeObservationTypes: _path("/quantitative-observation-types"),
    staticReserveWaterBodies: _path("/static-reserve-water-bodies"),
    quantitativeObservationStations: _path("/quantitative-observation-stations"),
    quantitativeObservationalData: _path("/quantitative-observational-data"),
    renewableWaterResources: _path("/renewable-water-resources"),
};

export const potentialWaterAvailabilityMenu = [
    {
        title: 'Disponibilité potentielle en eau',
        path: PATH_POTENTIAL_WATER_AVAILABILITY.root,
        icon: <EventBusyIcon/>,
        children: [
            {title: 'Accueil', path: PATH_POTENTIAL_WATER_AVAILABILITY.home},
            {
                title: 'Type d\'observation quantitative',
                path: PATH_POTENTIAL_WATER_AVAILABILITY.quantitativeObservationTypes
            },
            {
                title: 'Reserve statique des masses d\'eau',
                path: PATH_POTENTIAL_WATER_AVAILABILITY.staticReserveWaterBodies
            },
            {
                title: 'Stations d\'observation quantitative',
                path: PATH_POTENTIAL_WATER_AVAILABILITY.quantitativeObservationStations
            },
            {
                title: 'Données d\'observations quantitatives',
                path: PATH_POTENTIAL_WATER_AVAILABILITY.quantitativeObservationalData
            },
            {
                title: 'Ressources en eau renouvelables',
                path: PATH_POTENTIAL_WATER_AVAILABILITY.renewableWaterResources
            },
        ]
    }
];

export const commonItems = [
    {name: "Accueil", href: "/"},
    {name: "Disponibilité potentielle en eau", href: PATH_POTENTIAL_WATER_AVAILABILITY.home},
];
