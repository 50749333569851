import React, { lazy } from "react";
import { Navigate } from "react-router-dom";

import MainLayout from "../../home/AppLayout";
import Loadable from "../../shared/components/Loadable";

import { PATH_ADMINISTRATIVE_DIVISION } from "./administrative_division.path";

//Features
import paysRouter from "./pays/pays.router";
import regionRouter from "./region/region.router";
import circleRouter from "./circle/circle.router";
import communeRouter from "./commune/commune.router";
import localityRouter from "./locality/locality.router";
import localityTypeRouter from "./locality_type/locality_type.router";
import villageStatusRouter from "./village_status/village_status.router";
import environmentRouter from "./environment/environment.router";
import originPopulationRouter from "./origin_population/village_status.router";
import dataRefYearRouter from "./data_ref_year/data.ref.year.router";
import statusCommuneRouter from "./status_commune/status.commune.router";
import occurrenceAccessRateRouter from "../sanitation_access/occurrence_access_rate/occurrence.access.rate.router";
import occurenceEmopResultRouter from "../sanitation_access/occurence_emop_result/occurence.emop.result.router";

const AdministrativeDivisionHome = Loadable(
  lazy(() => import("./AdministrativeDivisionHome"))
);

const administrative_divisionRouter = [
  {
    path: PATH_ADMINISTRATIVE_DIVISION.root,
    element: <MainLayout />,
    children: [
      {
        path: PATH_ADMINISTRATIVE_DIVISION.root,
        element: <Navigate to={PATH_ADMINISTRATIVE_DIVISION.home} replace />,
      },
      { path: "home", element: <AdministrativeDivisionHome /> },

      ...paysRouter,

      ...regionRouter,

      ...circleRouter,

      ...communeRouter,

      ...localityRouter,

      ...localityTypeRouter,

      ...villageStatusRouter,

      ...environmentRouter,

      ...originPopulationRouter,

      ...dataRefYearRouter,

      ...statusCommuneRouter,
    ],
  },
];

export default administrative_divisionRouter;
