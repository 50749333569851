import React from 'react';
import {PATH_WATER_USE} from '../../water.use.path';
import HeaderBreadcrumbs from "../../../../shared/components/HeaderBreadcrumbs";

const commonItems = [
    { name: "Accueil", href: "/" },
    { name: "Usage Eau", href: PATH_WATER_USE.home },
];

export function WaterMassHeaderBreadcrumbs({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[
            ...commonItems,
            {name: 'Masse eau', href: PATH_WATER_USE.water_mass},
            {name: lastName},
        ]}
    />);
}

export function WaterMassListHeaderBreadcrumbs({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[...commonItems, {name: lastName}]}
    />);
}
