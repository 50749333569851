import {filter} from 'lodash';
import React, {useState} from 'react';
import {Refresh, Search} from '@mui/icons-material';
import {Box, IconButton, InputAdornment, Link, OutlinedInput, Toolbar, Tooltip, Typography} from '@mui/material';
import {Link as RouterLink} from "react-router-dom";

export function RefreshIconButton({tooltip, onRefresh}) {
    return (
        <Tooltip title={tooltip ?? 'Actualiser'}>
            <IconButton onClick={() => onRefresh && onRefresh()}>
                <Refresh/>
            </IconButton>
        </Tooltip>
    );
}

export function TableCellLink({to = '#', value}) {
    return (
        <Link to={to} component={RouterLink}>
            <Typography variant="body2" noWrap>
                {value}
            </Typography>
        </Link>
    );
}

export function TableSearchField({value, onChange, placeholder}) {
    return (
        <OutlinedInput
            sx={{width: 240}}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            startAdornment={
                <InputAdornment position="start">
                    <Box component={Search} sx={{color: "text.disabled"}}/>
                </InputAdornment>
            }
        />
    );
}

export function SimpleTableToolbar({value, onChange, placeholder, onRefresh}) {
    return (
        <Toolbar sx={sxTableToolbar}>
            <TableSearchField
                value={value}
                onChange={onChange}
                placeholder={placeholder}
            />

            <RefreshIconButton onRefresh={onRefresh}/>

        </Toolbar>
    )
}

export const sxTableToolbar = {
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: (theme) => theme.spacing(0, 1, 0, 3),
};

export const sxTableRow = {'&:last-child td, &:last-child th': {border: 0}};

export const usePagination = (initialState) => {
    const [page, setPage] = useState(initialState?.page ?? 0);
    const [order, setOrder] = useState(initialState?.order ?? 'asc');
    const [selected, setSelected] = useState(initialState?.selected ?? []);
    const [filterName, setFilterName] = useState(initialState?.filterName ?? '');
    const [rowsPerPage, setRowsPerPage] = useState(initialState?.rowsPerPage ?? 10);
    const [orderBy, setOrderBy] = useState(initialState?.orderBy ?? 'createdDate');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleRowClick = (event, field) => {
        setSelected(selectRow(selected, field));
    };

    const handleChangePage = (event, newPage) => {
        console.log('### hooks handleChangePage: ', newPage);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    return {
        page, setPage, order, setOrder, selected, setSelected, filterName, setFilterName,
        rowsPerPage, setRowsPerPage, orderBy, setOrderBy, handleRequestSort, handleRowClick,
        handleChangePage, handleChangeRowsPerPage, handleFilterByName
    }
}

export function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


export function applySortFilter(array, comparator, query, predicate) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    if (query) {
        return filter(array, predicate);
    }

    return stabilizedThis.map((el) => el[0]);
}


export const selectRow = (selected, field) => {
    const selectedIndex = selected.indexOf(field);
    let newSelected = [];
    if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, field);
    } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    return newSelected;
};

export const emptyRows = (page, rowsPerPage, list) => page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;
