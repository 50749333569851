import * as Yup from 'yup';
import create from 'zustand';


export const InstrumentPolicyOccurrenceSchema = Yup.object().shape({
    name: Yup.string().default('').required('Le nom est obligatoire'),
    justification: Yup.string().required("La justification est obligatoire"),
    riskManagementToolApplicationDegree: Yup.string().required("Le degrée d'application est obligatoire"),
    dataRefYear: Yup.string().required("L'année de reference de donnée"),
    instrumentPolicy: Yup.string().required("Instruments de politique et stratégie eau"),
    dataSource: Yup.string().default(''),
});

const initialInstrumentPolicyOccurrence = InstrumentPolicyOccurrenceSchema.getDefaultFromShape();
export const InitialInstrumentPolicyOccurrence = () => ({...initialInstrumentPolicyOccurrence});

const setCurrentNewInstrumentPolicyOccurrence = set => instrumentPolicyOccurrence => {
    set(state => ({...state, currentNewInstrumentPolicyOccurrence: instrumentPolicyOccurrence ?? InitialInstrumentPolicyOccurrence()}));
}

const setCurrentUpdateInstrumentPolicyOccurrence = set => instrumentPolicyOccurrence => {
    set(state => ({...state, currentUpdateInstrumentPolicyOccurrence: instrumentPolicyOccurrence ?? InitialInstrumentPolicyOccurrence()}));
}

export const useInstrumentPolicyOccurrenceStore = create(set => ({
    //State
    currentNewInstrumentPolicyOccurrence: InitialInstrumentPolicyOccurrence(),
    currentUpdateInstrumentPolicyOccurrence: InitialInstrumentPolicyOccurrence(),
    //Reducers
    setCurrentNewInstrumentPolicyOccurrence: setCurrentNewInstrumentPolicyOccurrence(set),
    setCurrentUpdateInstrumentPolicyOccurrence: setCurrentUpdateInstrumentPolicyOccurrence(set),
}))
