import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useNavigate} from 'react-router-dom';
import {Form, FormikProvider, useFormik} from 'formik';
import {Box, Card, Stack} from '@mui/material';
import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {
    FormikAutocomplete, FormikDatePicker,
    FormikTextField,
    FormRowStack,
    SubmitCancelGroupButton
} from '../../../../shared/components/froms/FormComponents';
import {setRefresh} from '../services/instrument.policy.gql';
import useInstrumentPolicyService from '../services/instrument.policy.services';
import {useConnectFormState} from "../../../../shared/hooks/useFormikState";
import {InstrumentPolicySchema, InitialInstrumentPolicy} from "../services/instrument.policy.store";
import {formErrorMessageParser} from "../../../../utils/utils";
import {useSearchInstrumentPolicyTypes} from "../../instrument_policy_type/services/instrument.policy.type.gql";
import {InstrumentPolicyTypeCreateDialogMenuItem} from "../../instrument_policy_type/components";
import {useSearchTypeSpheres} from "../../../organ_instrument/type_sphere/services/type.sphere.gql";
import {TypeSphereCreateDialogMenuItem} from "../../../organ_instrument/type_sphere/components";

InstrumentPolicyForm.propTypes = {
    isEdit: PropTypes.bool,
    currentInstrumentPolicy: PropTypes.object,
    fromDialog: PropTypes.object,
};
export default function InstrumentPolicyForm({isEdit,
                                                 currentInstrumentPolicy,
                                                 setCurrentInstrumentPolicy,
                                                 fromDialog
                                             }) {
    const navigate = useNavigate();
    const {create, update} = useInstrumentPolicyService();
    const {showSuccess, showError} = useMessage();

    const initTypeSphereQuery = currentInstrumentPolicy?.typeSphereObj?.name ?? fromDialog?.typeSphere?.name ?? '';
    const [typeSphereQuery, setTypeSphereQuery] = useState(initTypeSphereQuery);
    const {typeSpheres, typeSphereLoading} = useSearchTypeSpheres({typeSphereQuery});


    const initInstrumentPolicyTypeQuery = currentInstrumentPolicy?.instrumentPolicyTypeObj?.name ?? fromDialog?.instrumentPolicyType?.name ?? '';
    const [instrumentPolicyTypeQuery, setInstrumentPolicyTypeQuery] = useState(initInstrumentPolicyTypeQuery);
    const {
        instrumentPolicyTypes,
        instrumentPolicyLoading
    } = useSearchInstrumentPolicyTypes({instrumentPolicyTypeQuery});

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: InstrumentPolicySchema,
        initialValues: InitialInstrumentPolicy(),
        onSubmit: async (instrumentPolicy, {setSubmitting}) => {
            try {
                instrumentPolicy = InstrumentPolicySchema.cast(instrumentPolicy);

                let message;
                if (!isEdit) {
                    instrumentPolicy = await create(instrumentPolicy);
                    message = 'Création avec succès';
                } else {
                    instrumentPolicy = await update(currentInstrumentPolicy.id, instrumentPolicy);
                    message = 'Modification avec succès';
                }

                setRefresh(true);
                setCurrentInstrumentPolicy();

                setSubmitting(false);
                showSuccess(message);

                if (fromDialog) {
                    fromDialog.onSave(instrumentPolicy);
                } else {
                    navigate(`${PATH_GIRE_ENV_FAVORABLE.instrumentPolicies}/${instrumentPolicy.id}`);
                }
            } catch (error) {
                setSubmitting(false);
                showError(formErrorMessageParser({error, isEdit}));
            }
        }
    });

    const {values, setValues, handleSubmit, isSubmitting, setFieldValue} = formik;

    useConnectFormState({
        formValues: values, setFormValues: setValues,
        stateValues: currentInstrumentPolicy, setStateValues: setCurrentInstrumentPolicy,
    });

    const onCancel = (_) => {
        setCurrentInstrumentPolicy();
        if (fromDialog) {
            fromDialog.onCancel();
        } else {
            navigate(PATH_GIRE_ENV_FAVORABLE.instrumentPolicies);
        }
    };

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>

                <Card sx={{p: 3}}>

                    <Stack spacing={3}>

                        <FormRowStack>
                            <FormikTextField label='Nom' name='name' formik={formik}/>
                            <FormikTextField label='Description objectifs' name='descriptionObjectifs' formik={formik}/>
                        </FormRowStack>

                        <FormRowStack>
                            <Box flexGrow={1} display='flex' flexDirection='row'>
                                <FormikAutocomplete name='typeSphere'
                                                    label="Types de sphère d'actions de GIRE"
                                                    options={typeSpheres}
                                                    labelField='name'
                                                    embeddedObjField='typeSphereObj'
                                                    {...{
                                                        typeSphereQuery,
                                                        formik,
                                                        typeSphereLoading,
                                                        setTypeSphereQuery
                                                    }}
                                />
                                <Box sx={{py: 1, ml: 1}}>
                                    <TypeSphereCreateDialogMenuItem btn onSave={(createdTypeSphere) => {
                                        currentInstrumentPolicy.typeSphereObj = createdTypeSphere;
                                        setTypeSphereQuery(createdTypeSphere?.name ?? '');
                                        setFieldValue('typeSphere', createdTypeSphere?.id);
                                    }}/>
                                </Box>
                            </Box>
                            <Box flexGrow={1} display='flex' flexDirection='row'>
                                <FormikAutocomplete name='instrumentPolicyType'
                                                    label="Types d'instruments de politique et stratégie eau"
                                                    options={instrumentPolicyTypes}
                                                    labelField='name'
                                                    embeddedObjField='instrumentPolicyTypeObj'
                                                    {...{
                                                        instrumentPolicyTypeQuery,
                                                        formik,
                                                        instrumentPolicyLoading,
                                                        setInstrumentPolicyTypeQuery
                                                    }}
                                />
                                <Box sx={{py: 1, ml: 1}}>
                                    <InstrumentPolicyTypeCreateDialogMenuItem btn
                                                                              onSave={(createdInstrumentPolicyType) => {
                                                                                  currentInstrumentPolicy.instrumentPolicyTypeObj = createdInstrumentPolicyType;
                                                                                  setInstrumentPolicyTypeQuery(createdInstrumentPolicyType?.name ?? '');
                                                                                  setFieldValue('instrumentPolicyType', createdInstrumentPolicyType?.id);
                                                                              }}/>
                                </Box>
                            </Box>
                        </FormRowStack>

                        <FormRowStack>
                            <FormikDatePicker label='Date de mise en place' name='dateMizPlace'/>
                            <FormikTextField label='horizon projection' name='horizonProjection' formik={formik}/>
                        </FormRowStack>

                        <FormRowStack>
                            <FormikTextField label='Structure Resp' name='structureResp' formik={formik}/>
                            <FormikTextField label='Source des données' name='dataSource' formik={formik}/>
                        </FormRowStack>

                        <SubmitCancelGroupButton {...{isEdit, isSubmitting, onCancel}}/>
                    </Stack>

                </Card>

            </Form>
        </FormikProvider>
    );
}
