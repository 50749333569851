import React from 'react';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import {path} from '../../home/sidebar/paths';

//Features
const WATER_MANAGEMENT = '/water-management';
const _path = path(WATER_MANAGEMENT);
export const PATH_WATER_MANAGEMENT = {
    root: WATER_MANAGEMENT,
    home: _path('/home'),
    waterManagementTypes: _path('/water-management-types'),
    waterManagements: _path('/water-managements'),
    operationalManagementDevices: _path('/operational-management-devices'),
    strategicWaterManagementAreas: _path('/strategic-water-management-areas'),
};


export const  waterManagementMenu = [
    {
        title: 'Gestion de l\'eau',
        path: PATH_WATER_MANAGEMENT.root,
        icon: <ArchitectureIcon/>,
        children: [
            { title: 'Accueil', path: PATH_WATER_MANAGEMENT.home },
            { title: 'Types gestion de l\'eau', path: PATH_WATER_MANAGEMENT.waterManagementTypes },
            { title: 'Gestion de l\'eau', path: PATH_WATER_MANAGEMENT.waterManagements },
            { title: 'Dispositif Operationnel de gestion', path: PATH_WATER_MANAGEMENT.operationalManagementDevices },
            { title: 'Domaine stratégique de gestion de l\'eau', path: PATH_WATER_MANAGEMENT.strategicWaterManagementAreas },
        ]
    }
];

export const commonItems = [
    { name: "Accueil", href: "/" },
    { name: "Gestion de l\'eau", href: PATH_WATER_MANAGEMENT.home },
];
