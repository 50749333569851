import React from 'react';
// material
import {Settings} from '@mui/icons-material';
// utils
import {PATH_POTENTIAL_WATER_AVAILABILITY} from '../../potential.water.availability.path';
import {useCountQuantitativeObservationTypes} from '../services/quantitative.observation.type.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';

export default function QuantitativeObservationTypeEntryButton() {
    const {count} = useCountQuantitativeObservationTypes();
  return (
      <SubModuleEntryButton
          label="Type d'observation quantitative des RE"
          value={count}
          url={PATH_POTENTIAL_WATER_AVAILABILITY.quantitativeObservationTypes}
          icon={<Settings />}
      />
  );
}
