import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const OperationalEntityList = Loadable(lazy(() => import('./pages/OperationalEntityList')));
const OperationalEntityCreate = Loadable(lazy(() => import('./pages/OperationalEntityCreate')));
const OperationalEntityEdit = Loadable(lazy(() => import('./pages/OperationalEntityEdit')));
const OperationalEntityDisplay = Loadable(lazy(() => import('./pages/OperationalEntityDisplay')));

const operationalEntityRouter = [
    {path: 'operational-entities', element: <OperationalEntityList/>},
    {path: 'operational-entities/:id', element: <OperationalEntityDisplay/>},
    {path: 'operational-entities/new', element: <OperationalEntityCreate/>},
    {path: 'operational-entities/:id/edit', element: <OperationalEntityEdit/>},
];

export default operationalEntityRouter;
