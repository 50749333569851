import React, {Fragment} from 'react';
// material
import {Menu} from '@mui/icons-material';
import {alpha} from '@mui/material/styles';
import {AppBar, Toolbar, IconButton, Typography, styled, Box, TextField} from '@mui/material';
// components
import {MHidden} from '../../shared/components';
//
import AccountPopover from './AccountPopover';
import SearchField from "./search/SearchField";

// ----------------------------------------------------------------------

export const DRAWER_WIDTH = 280;
export const APPBAR_MOBILE = 64;
export const APPBAR_DESKTOP = 92;

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar)(({theme}) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.grey["100"], 0.72),
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
    }
}))

const StyledToolbar = styled(Toolbar)(({theme}) => ({
    display: 'flex',
    position: 'relative',
    minHeight: APPBAR_MOBILE,
    justifyContent: 'space-between',
    [theme.breakpoints.up('lg')]: {
        minHeight: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5)
    }
}))

export default function HomeNavbar({onOpenSidebar}) {

    return (
        <RootStyle>
            <StyledToolbar>
                <Fragment>
                    <MHidden width='lgUp'>
                        <Box display={'flex'} alignItems={'center'}>
                            <IconButton onClick={onOpenSidebar} sx={{mr: 1, color: 'text.primary'}}>
                                <Menu/>
                            </IconButton>
                            <AppTitle/>
                        </Box>
                    </MHidden>

                    <MHidden width='lgDown'>
                        <AppTitle/>
                    </MHidden>
                </Fragment>

                <SearchField/>

                <AccountPopover/>
            </StyledToolbar>
        </RootStyle>
    );
}

function AppTitle() {
    return <Typography variant="h4" color='black'>SINEAU</Typography>
}