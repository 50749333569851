import {gql, useQuery, useLazyQuery} from "@apollo/client";
import {parseCount, parseEntity} from "../../../../utils/gql.utils";
import {useEffect, useState} from "react";
import {useMessage} from "../../../../shared/hooks/useMessage";

export let needRefresh = false;
export const setRefresh = (value) => (needRefresh = value);

export const legislativeTextFields = `
   id name 
    legislativeTextType legislativeTextTypeObj{id name} typeSphere typeSphereObj{id name}
    dateMizPlace descriptionObjectifs structureResp dataSource`;

///////////////////// Find By ID /////////////////////
const GET_BASIN_BY_ID = gql`
    query LegislativeTextById($id: Int!) {
        legislativeTextById(id: $id) {
            error message entity { ${legislativeTextFields} }
        }
    }`;

export const useLegislativeTextById = (id) => {
    id = parseInt(id);
    return useQuery(GET_BASIN_BY_ID, {
        variables: {id},
        fetchPolicy: "network-only",
    });
};

export const useLegislativeText = (id) => {
    const result = useLegislativeTextById(id);
    return parseEntity(result, "legislativeTextById");
};
///////////////////// Search /////////////////////
const SEARCH_INSTITUTIONAL_ORGANIZATIONAL_FRAMEWORK_STRUCTURE = gql`
    query SearchLegislativeTexts($query: String,$legislativeTextType: Int, $page: Int, $size: Int) {
        searchLegislativeTexts(query: $query,legislativeTextType:$legislativeTextType, page: $page, size: $size) {
            error message page {
                content { ${legislativeTextFields} }
                page hasNext hasPrevious totalPages totalElements numberOfElements
            }
        }
    }
`;

export const useSearchLegislativeTexts = ({
                                                                            query = "",
                                                                            legislativeTextType,
                                                                            page = 0,
                                                                            size = 20
                                                                        }) => {
    const [legislativeTexts, setLegislativeTexts] = useState([]);
    const [legislativeTextPage, setLegislativeTextPage] = useState(null);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] =
        useLazyQuery(SEARCH_INSTITUTIONAL_ORGANIZATIONAL_FRAMEWORK_STRUCTURE, {
            variables: {query, legislativeTextType, page, size},
        });

    useEffect(() => {
        if (_error) {
            showError("Erreur lors de l'operation");
            setLegislativeTextPage(null);
            setLegislativeTexts([]);
            return;
        }

        if (!searchData) return;

        const {error, message, page} = searchData.searchLegislativeTexts;

        if (error) {
            showError(message);
            setLegislativeTextPage(null);
            setLegislativeTexts([]);
        } else {
            setLegislativeTextPage(page);
            setLegislativeTexts(page.content);
        }

        if (needRefresh) {
            refetch();
            setRefresh(false);
        }
    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({variables: {query, legislativeTextType, page, size}});
    }, [query, legislativeTextType, page, size]);

    return {
        legislativeTexts,
        loading,
        search,
        refetch,
        legislativeTextPage,
    };
};

///////////////////// Count /////////////////////
const COUNT_INSTITUTIONAL_ORGANIZATIONAL_FRAMEWORK_STRUCTURES = gql`
  query CountLegislativeTexts {
    countLegislativeTexts {
      error
      message
      count
    }
  }
`;

export const useCountLegislativeTexts = () => {
    const result = useQuery(COUNT_INSTITUTIONAL_ORGANIZATIONAL_FRAMEWORK_STRUCTURES, {
        fetchPolicy: "network-only",
    });
    return parseCount(result, "countLegislativeTexts");
};
