import React from 'react';
import {commonItems, PATH_WATER_USAGE_URIGATION} from '../../water.usage.irrigation.path';
import HeaderBreadcrumbs from "../../../../shared/components/HeaderBreadcrumbs";


export function OccurrenceAgriculturalProductionHeaderBreadcrumbs({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[
            ...commonItems,
            {name: 'Occurrences de productions agricoles', href: PATH_WATER_USAGE_URIGATION.occurrenceAgriculturalProductions},
            {name: lastName},
        ]}
    />);
}

export function OccurrenceAgriculturalProductionListHeaderBreadcrumbs({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[...commonItems, {name: lastName}]}
    />);
}
