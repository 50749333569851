import React from 'react';
// material
import {Container, Grid, useTheme} from '@mui/material';
// components
import Page from '../../shared/components/Page';
import ModuleWelcome from '../../shared/components/ModuleWelcome';
import {entryButtonHidden, entryButtonHover, entryButtonShow} from '../../utils/animation.utils';
import SubModuleEntryButton from "../../shared/components/SubModuleEntryButton";

import {motion} from 'framer-motion';


// ----------------------------------------------------------------------

const entryButtonVariants = {
    hidden: entryButtonHidden,
    entryButton: entryButtonShow,
    hover: entryButtonHover,
}

export default function FakeModuleHome(
    {
        title: moduleTitle,
        moduleName,
        moduleDescription,
        Icon,
        features,
        path: modulePath
    }
) {

    const theme = useTheme();

    return (
        <Page title={moduleTitle}>
            <Container maxWidth={'xl'}>
                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <ModuleWelcome
                            displayName={moduleName}
                            description={moduleDescription}
                            icon={<Icon sx={{fontSize: 150, color: theme.palette.primary.main}}/>}
                        />
                    </Grid>

                    {features.map(({title, Icon, path}) => {
                        if (title === 'home') return (<></>);
                        return (
                            <Grid item xs={12} md={6} lg={4}>
                                <motion.div
                                    variants={entryButtonVariants}
                                    initial='hidden'
                                    animate='entryButton'
                                    whileHover='hover'
                                >
                                    <SubModuleEntryButton
                                        label={title}
                                        value={0}
                                        url={`${modulePath}${path}`}
                                        icon={<Icon/>}
                                    />
                                </motion.div>
                            </Grid>
                        );
                    })}
                </Grid>
            </Container>
        </Page>
    );
}
