import React from 'react';
// components
import {LegislativeTextTypeForm} from '../components';
import CreateDialogMenuItem from '../../../../shared/components/CreateDialogMenuItem';
import {useLegislativeTextTypeStore} from "../services/legislative.text.type.store";

export function LegislativeTextTypeCreateDialogMenuItem({onClose, onSave, btn}) {
    const {currentNewLegislativeTextType: currentLegislativeTextType, setCurrentNewLegislativeTextType: setCurrentLegislativeTextType} = useLegislativeTextTypeStore();
    return (
        <CreateDialogMenuItem
            btn={btn}
            onClose={onClose}
            menuText="Ajouter un type textes de cadre législatif et reglementaire eau"
            dialogTitle="Création de type texte de cadre législatif"
            dialogContextText={<> {`Création de nouveau type texte de cadre législatif`} </>}
            formRender={({onClose}) => {
                return (
                    <LegislativeTextTypeForm
                        {...{currentLegislativeTextType, setCurrentLegislativeTextType}}
                        fromDialog={{
                            onCancel: onClose,
                            onSave: (createdLegislativeTextType) => {
                                onSave && onSave(createdLegislativeTextType);
                                onClose && onClose();
                            }
                        }}/>
                );
            }}
        />
    );
}
