import React, {lazy} from 'react';
import RiskManagementToolList from "./pages/RiskManagementToolList";
import RiskManagementToolDisplay from "./pages/RiskManagementToolDisplay";
import RiskManagementToolCreate from "./pages/RiskManagementToolCreate";
import RiskManagementToolEdit from "./pages/RiskManagementToolEdit";

const riskManagementToolRouter = [
    {path: 'risk-management-tools', element: <RiskManagementToolList/>},
    {path: 'risk-management-tools/:id', element: <RiskManagementToolDisplay/>},
    {path: 'risk-management-tools/new', element: <RiskManagementToolCreate/>},
    {path: 'risk-management-tools/:id/edit', element: <RiskManagementToolEdit/>},
];

export default riskManagementToolRouter;
