import * as Yup from 'yup';
import create from 'zustand';

export const LocalitySchema = Yup.object().shape({
    name: Yup.string().default('').required("Le nom est obligatoire"),
    commune: Yup.number().required("La commune est obligatoire"),
    localityType: Yup.number().required("Le type de localite est obligatoire"),
    villageStatus: Yup.number().required(
        "Le status du village est obligatoire"
    ),
    environment: Yup.number().required("Le status du village est obligatoire"),
    populationYear: Yup.number().required(
        "L'estimation de la population du village est obligatoire"
    ),
    currentPopulation: Yup.number().required(
        "La population actuelle du village est obligatoire"
    ),
    householdNumber: Yup.number().required(
        "Le nombre de menage du village est obligatoire"
    ),
    concessionNumber: Yup.number().required(
        "Le nombre de concession du village est obligatoire"
    ),

    latitudeDescription: Yup.number().default(0).required("La latitude est obligatoire"),
    latitudeText: Yup.string().default('0').required("La latitude en degre est obligatoire"),
    longitudeDescription: Yup.number().default(0).required("La longitude est obligatoire"),
    longitudeText: Yup.string().default('0').required("La longitude en degre est obligatoire"),

    xCoordinate: Yup.number().default(0),
    yCoordinate: Yup.number().default(0),
    population: Yup.number().default(0),
    currentLivestock: Yup.number().default(0),
    existMarche: Yup.bool().default(false),
});

const initialLocality = LocalitySchema.getDefaultFromShape();
export const InitialLocality = () => ({...initialLocality});

const setCurrentNewLocality = set => locality => {
    set(state => ({...state, currentNewLocality: locality ?? InitialLocality()}));
}

const setCurrentUpdateLocality = set => locality => {
    set(state => ({...state, currentUpdateLocality: locality ?? InitialLocality()}));
}

export const useLocalityStore = create(set => ({
    //State
    currentNewLocality: InitialLocality(),
    currentUpdateLocality: InitialLocality(),
    //Reducers
    setCurrentNewLocality: setCurrentNewLocality(set),
    setCurrentUpdateLocality: setCurrentUpdateLocality(set),
}))
