import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import {useMessage} from '../../../../shared/hooks/useMessage';
// material
// routes
import {setRefresh} from '../services/pays.gql';
import usePaysService from '../services/pays.services';
import {formErrorMessageParser} from '../../../../utils/utils';
import {FormikTextField, SimpleFormikForm} from '../../../../shared/components/froms/FormComponents';
import {PATH_ADMINISTRATIVE_DIVISION} from '../../administrative_division.path';
import {InitialPays, PaysSchema} from '../services/pays.store';
import {useConnectFormState} from '../../../../shared/hooks/useFormikState';
// ----------------------------------------------------------------------

PaysForm.propTypes = {
    isEdit: PropTypes.bool,
    currentPays: PropTypes.object,
    fromDialog: PropTypes.object,
};

export default function PaysForm({isEdit, currentPays, setCurrentPays, fromDialog}) {
    const navigate = useNavigate();
    const {create, update} = usePaysService();
    const {showSuccess, showError} = useMessage();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: InitialPays(),
        validationSchema: PaysSchema,
        onSubmit: async (pays, {setSubmitting}) => {
            try {
                pays = PaysSchema.cast(pays);

                let message;
                if (!isEdit) {
                    pays = await create(pays);
                    message = 'Création avec succès';
                } else {
                    pays = await update(currentPays.id, pays);
                    message = 'Modification avec succès';
                }

                setRefresh(true);
                setCurrentPays();
                setSubmitting(false);
                showSuccess(message);

                if (fromDialog) {
                    fromDialog.onSave(pays);
                } else {
                    navigate(`${PATH_ADMINISTRATIVE_DIVISION.pays}/${pays.id}`);
                }
            } catch (error) {
                setSubmitting(false);
                showError(formErrorMessageParser({error, isEdit}));
            }
        }
    });

    const {values, setValues} = formik;

    useConnectFormState({
        formValues: values, setFormValues: setValues,
        stateValues: currentPays, setStateValues: setCurrentPays,
    });

    const onCancel = (_) => {
        setCurrentPays();
        if (fromDialog) {
            fromDialog.onCancel();
        } else {
            navigate(PATH_ADMINISTRATIVE_DIVISION.pays);
        }
    }

    return (
        <SimpleFormikForm {...{formik, onCancel, isEdit}}>
            <FormikTextField label='Nom' name='name' formik={formik}/>

            <FormikTextField label='Nationalite' name='nationality' formik={formik}/>
        </SimpleFormikForm>
    );
}
