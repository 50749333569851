import React from 'react';
// components
import {RiskManagementToolForm} from '../components';
import CreateDialogMenuItem from '../../../../shared/components/CreateDialogMenuItem';
import {useRiskManagementToolStore} from "../services/risk.management.tool.store";

export function RiskManagementToolCreateDialogMenuItem({onClose, onSave, btn}) {
    const {currentNewRiskManagementTool: currentRiskManagementTool, setCurrentNewRiskManagementTool: setCurrentRiskManagementTool} = useRiskManagementToolStore();
    return (
        <CreateDialogMenuItem
            btn={btn}
            onClose={onClose}
            menuText="Ajouter un outils de gestion de risques liés à l'eau"
            dialogTitle="Création d'outils de gestion de risques liés à l'eau"
            dialogContextText={<> {`Création de nouvel outils de gestion de risques liés à l'eau`} </>}
            formRender={({onClose}) => {
                return (
                    <RiskManagementToolForm
                        {...{currentRiskManagementTool, setCurrentRiskManagementTool}}
                        fromDialog={{
                            onCancel: onClose,
                            onSave: (createdRiskManagementTool) => {
                                onSave && onSave(createdRiskManagementTool);
                                onClose && onClose();
                            }
                        }}/>
                );
            }}
        />
    );

}
