import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Grid, Box, Card, Stack, Typography} from '@mui/material';
import {entryButtonVariants, IconWrapperStyle} from '../../shared/components/SubModuleEntryButton';
import {fNumber} from '../../utils/formatNumber';
import {motion} from 'framer-motion';


export default function HomeEntryButton({label, url, icon, value, iconColor}) {

    const navigate = useNavigate();

    return (
        <Grid item xs={12} md={6}>
            <motion.div
                variants={entryButtonVariants}
                initial='hidden'
                animate='animate'
                whileHover='hover'
            >
                <Card
                    onClick={() => navigate(url)}
                    sx={{display: 'flex', alignItems: 'center', p: 3}}
                >
                    <Box sx={{flexGrow: 1}}>
                        <Typography variant='h6'>{label}</Typography>
                        <Stack direction='row' alignItems="center" spacing={1} sx={{mt: 2, mb: 1}}>
                            <IconWrapperStyle icon={icon} color={iconColor}/>
                            <Typography variant='h3'>
                                {value === undefined ? '...' : fNumber(value)}
                            </Typography>
                        </Stack>
                    </Box>
                </Card>
            </motion.div>
        </Grid>
    );
}
