import React from 'react';
// material
import {DonutLarge, DuoRounded, East, Forest} from '@mui/icons-material';
// utils
import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {useCountLegislativeTexts} from '../services/legislative.text.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function LegislativeTextEntryButton() {
    const {count} = useCountLegislativeTexts();
  return (
      <SubModuleEntryButton
          label="Textes du cadre législatif et reglementaire eau"
          value={count}
          url={PATH_GIRE_ENV_FAVORABLE.legislativeTexts}
          icon={<Forest />}
      />
  );
}
