import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const CulturalSpeculationEntitiesList = Loadable(
  lazy(() => import("./pages/CulturalSpeculationEntitiesList"))
);
const CulturalSpeculationEntitiesCreate = Loadable(
  lazy(() => import("./pages/CulturalSpeculationEntitiesCreate"))
);
const CulturalSpeculationEntitiesEdit = Loadable(
  lazy(() => import("./pages/CulturalSpeculationEntitiesEdit"))
);
const CulturalSpeculationEntitiesDisplay = Loadable(
  lazy(() => import("./pages/CulturalSpeculationEntitiesDisplay"))
);

const culturalSpeculationEntitiesRouter = [
  {
    path: "cultural-speculation-entities",
    element: <CulturalSpeculationEntitiesList />,
  },
  {
    path: "cultural-speculation-entities/:id",
    element: <CulturalSpeculationEntitiesDisplay />,
  },
  {
    path: "cultural-speculation-entities/new",
    element: <CulturalSpeculationEntitiesCreate />,
  },
  {
    path: "cultural-speculation-entities/:id/edit",
    element: <CulturalSpeculationEntitiesEdit />,
  },
];

export default culturalSpeculationEntitiesRouter;
