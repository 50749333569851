import {useEffect, useState} from 'react';
import {useMessage} from '../../../../../shared/hooks/useMessage';
import {parseCount, parseEntity} from '../../../../../utils/gql.utils';
import {gql, useQuery, useLazyQuery} from '@apollo/client';

export let needRefresh = false;
export const setRefresh = (value) => needRefresh = value;

export const widgetFields = `
    id title description type source chartType valueUnit autoRefresh refreshDelay
    request reporting reportingObj {id label description}
`;

///////////////////// Find By ID /////////////////////
const GET_WIDGET_BY_ID = gql`
    query WidgetById($id: Int!) {
        widgetById(id: $id) {
            error message entity { ${widgetFields} }
        }
    }
`;

export const useWidgetById = (id) => {
    id = parseInt(id);
    return useQuery(GET_WIDGET_BY_ID, {
        variables: {id},
        fetchPolicy: 'network-only'
    });
}

export const useWidget = (id) => {
    const result = useWidgetById(id);

    return parseEntity(result, 'widgetById');
}

///////////////////// Search /////////////////////
const SEARCH_WIDGET = gql`
    query SearchWidgets($query: String, $page: Int, $size: Int) {
        searchWidgets(query: $query, page: $page, size: $size) {
            error message page {
                content { ${widgetFields} }
                page hasNext hasPrevious totalPages totalElements numberOfElements
            }
        }
    }
`;

export const useSearchWidgets = ({query = '', page = 0, size = 20}) => {
    const [widgets, setWidgets] = useState([]);
    const [widgetPage, setWidgetPage] = useState(null);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_WIDGET, {
        variables: {query, page, size},
    });

    useEffect(() => {
        if (_error) {
            showError('Erreur lors de l\'operation');
            setWidgetPage(null);
            setWidgets([]);
            return;
        }

        if (!searchData) return;

        const {error, message, page} = searchData.searchWidgets;

        if (error) {
            showError(message);
            setWidgetPage(null);
            setWidgets([]);
        } else {
            setWidgetPage(page);
            setWidgets(page.content);
        }

        if (needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({variables: {query, page, size}});
    }, [query, page, size]);

    return {
        widgets, loading, search, refetch, widgetPage
    }
}

///////////////////// Count /////////////////////
const COUNT_WIDGET = gql`
    query CountWidgets {
        countWidgets {
            error message count
        }
    }
`;

export const useCountWidgets = () => {
    const result = useQuery(COUNT_WIDGET, {
        fetchPolicy: 'network-only'
    });
    return parseCount(result, 'countWidgets');
}
