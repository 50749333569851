import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';
import MainLayout from '../../home/AppLayout';
import Loadable from '../../shared/components/Loadable';
import {PATH_GIRE_ENV_FAVORABLE} from './gire.env.favorable.path';
import instrumentPolicyTypeRouter from "./instrument_policy_type/instrument.policy.type.router";
import instrumentPolicyRouter from "./instrument_policy/instrument.policy.router";
import institutionalOrganizationalFrameworkStructureTypeRouter
    from "./institutional_organizational_framework_structure_type/institutional.organizational.framework.structure.type.router";
import institutionalOrganizationalFrameworkStructureRouter
    from "./institutional_organizational_framework_structure/institutional.organizational.framework.structure.router";
import participationAreaAndTypeRouter from "./participation_area_and_types/participation.area.and.type.router";
import instrumentMechanismParticipationOccurrenceRouter from "./instrument_mechanism_participation_occurrence/instrument.mechanism.participation.occurrence.router";
import legislativeTextRouter from "./legislative_text/legislative.text.router";
import legislativeTextTypeRouter from "./legislative_text_type/legislative.text.type.router";
import instrumentPolicyOccurrenceRouter from "./instrument_policy_occurrence/instrument.policy.occurrence.router";
import legislativeTextOccurrenceRouter from "./legislative_text_occurrence/legislative.text.occurrence.router";
import institutionalOrganizationalFrameworkStructureOccurrenceRouter
    from "./institutional_organizational_framework_structure_occurrence/institutional.organizational.framework.structure.occurrence.router";
import instrumentMechanismParticipationRouter
    from "./instrument_mechanism_participation/instrument.mechanism.participation.router";
import instrumentMechanismParticipationTypeRouter
    from "./instrument_mechanism_participation_type/instrument.mechanism.participation.type.router";
const GireEnvFavorableHome = Loadable(lazy(() => import('./gireEnvFavorableHome')));

const gireEnvFavorableRouter = [{
    path: PATH_GIRE_ENV_FAVORABLE.root,
    element: <MainLayout/>,
    children: [
        {
            path: PATH_GIRE_ENV_FAVORABLE.root,
            element: <Navigate to={PATH_GIRE_ENV_FAVORABLE.home} replace/>
        },
        {path: 'home', element: <GireEnvFavorableHome/>},
        ...instrumentPolicyTypeRouter,
        ...instrumentPolicyRouter,
        ...institutionalOrganizationalFrameworkStructureTypeRouter,
        ...institutionalOrganizationalFrameworkStructureRouter,
        ...participationAreaAndTypeRouter,
        ...legislativeTextTypeRouter,
        ...legislativeTextRouter,
        ...legislativeTextOccurrenceRouter,
        ...instrumentPolicyOccurrenceRouter,
        ...institutionalOrganizationalFrameworkStructureOccurrenceRouter,
        ...instrumentMechanismParticipationTypeRouter,
        ...instrumentMechanismParticipationOccurrenceRouter,
        ...instrumentMechanismParticipationRouter,
    ]
},
];
export default gireEnvFavorableRouter;
