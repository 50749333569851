import React, {useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
// material
import {CircularProgress, Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {ParticipationAreaAndTypeForm} from '../components';

import {ParticipationAreaAndTypeHeaderBreadcrumbs} from '../components';

import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useParticipationAreaAndType} from '../services/participation.area.and.type.gql';
import {useParticipationAreaAndTypeStore} from "../services/participation.area.and.type.store";

export default function ParticipationAreaAndTypeEdit() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {showError} = useMessage();

    const {setCurrentUpdateParticipationAreaAndType: setCurrentParticipationAreaAndType} = useParticipationAreaAndTypeStore();

    const {entity: currentParticipationAreaAndType, error, loading, errorMessage} = useParticipationAreaAndType(id);

    useEffect(() => {
        if (loading) return;
        if (error) {
            showError(errorMessage);
            navigate(PATH_GIRE_ENV_FAVORABLE.participationAreaAndTypes);
        }
    }, [loading, error, errorMessage]);

    useEffect(() => {
        setCurrentParticipationAreaAndType(currentParticipationAreaAndType);
    }, [currentParticipationAreaAndType]);

    return (
        <Page title={"Gire environment favorable: Modification type et domaine de participation à la GIRE"}>
            <Container maxWidth={'xl'}>
                <ParticipationAreaAndTypeHeaderBreadcrumbs
                    heading="Modification du type et domaine de participation à la GIRE"
                    lastName={`Modification type et domaine de participation à la GIRE - ${id}`}/>

                {loading && <CircularProgress/>}
                {!loading && currentParticipationAreaAndType && <ParticipationAreaAndTypeForm isEdit {...{currentParticipationAreaAndType, setCurrentParticipationAreaAndType}}/>}
            </Container>
        </Page>
    );
}
