import React from 'react';
// material
import MergeTypeIcon from '@mui/icons-material/MergeType';
// utils
import {PATH_ADMINISTRATIVE_DIVISION} from '../../administrative_division.path';
import {useCountLocalityTypes} from '../services/locality_type.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';
// ----------------------------------------------------------------------
export default function LocalityTypeEntryButton() {
    const {count} = useCountLocalityTypes();
  return (
      <SubModuleEntryButton
          label='Type de localite'
          value={count}
          url={PATH_ADMINISTRATIVE_DIVISION.localityTypes}
          icon={<MergeTypeIcon />}
      />
  );
}
