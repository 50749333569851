import {useApiHttp} from '../../../../../shared/hooks/useHttp';


export default function useDashboardService() {
    const {crud, post, get} = useApiHttp();

    const {
        create,
        update,
        destroy,
        findById,
        findAll,
    } = crud('dashboards');

    const duplicate = (dashboard) => post(`dashboards/${dashboard}/duplicate`);
    const exportById = (id) => get(`dashboards/${id}/export`);
    const exportAll = () => get(`dashboards/export-all`);
    const exportAllWithDependencies = () => get(`dashboards/export-all-with-deps`);
    const importOne = (data) => post(`dashboards/import-one`, data);
    const importAll = (data) => post(`dashboards/import-all`, data);
    const importAllWithDependencies = (data) => post(`dashboards/import-all-with-deps`, data);

    return {
        create,
        update,
        destroy,
        findById,
        findAll,
        duplicate,
        exportById,
        exportAll,
        exportAllWithDependencies,
        importOne,
        importAll,
        importAllWithDependencies,
    }
}

