import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const EntityOrganList = Loadable(lazy(() => import('./pages/EntityOrganList')));
const EntityOrganCreate = Loadable(lazy(() => import('./pages/EntityOrganCreate')));
const EntityOrganEdit = Loadable(lazy(() => import('./pages/EntityOrganEdit')));
const EntityOrganDisplay = Loadable(lazy(() => import('./pages/EntityOrganDisplay')));

const entityOrganRouter = [
    {path: 'entity-organs', element: <EntityOrganList/>},
    {path: 'entity-organs/:id', element: <EntityOrganDisplay/>},
    {path: 'entity-organs/new', element: <EntityOrganCreate/>},
    {path: 'entity-organs/:id/edit', element: <EntityOrganEdit/>},
];

export default entityOrganRouter;
