import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const AnnularDrillingList = Loadable(
  lazy(() => import("./pages/AnnularDrillingList"))
);
const AnnularDrillingCreate = Loadable(
  lazy(() => import("./pages/AnnularDrillingCreate"))
);
const AnnularDrillingEdit = Loadable(
  lazy(() => import("./pages/AnnularDrillingEdit"))
);
const AnnularDrillingDisplay = Loadable(
  lazy(() => import("./pages/AnnularDrillingDisplay"))
);

const annularDrillingRouter = [
  { path: "annular-drillings", element: <AnnularDrillingList /> },
  { path: "annular-drillings/:id", element: <AnnularDrillingDisplay /> },
  { path: "annular-drillings/new", element: <AnnularDrillingCreate /> },
  { path: "annular-drillings/:id/edit", element: <AnnularDrillingEdit /> },
];

export default annularDrillingRouter;
