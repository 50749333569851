import * as Yup from 'yup';
import create from 'zustand';


export const DataRefYearSchema = Yup.object().shape({
    name: Yup.string().default('').required('Le nom est obligatoire'),
    begin: Yup.date().required('La date de début est obligatoire'),
    end: Yup.date().required('La date de fin est obligatoire'),
});

const initialDataRefYear = DataRefYearSchema.getDefaultFromShape();
export const InitialDataRefYear = () => ({...initialDataRefYear});

const setCurrentNewDataRefYear = set => dataRefYear => {
    set(state => ({...state, currentNewDataRefYear: dataRefYear ?? InitialDataRefYear()}));
}

const setCurrentUpdateDataRefYear = set => dataRefYear => {
    set(state => ({...state, currentUpdateDataRefYear: dataRefYear ?? InitialDataRefYear()}));
}

export const useDataRefYearStore = create(set => ({
    //State
    currentNewDataRefYear: InitialDataRefYear(),
    currentUpdateDataRefYear: InitialDataRefYear(),
    //Reducers
    setCurrentNewDataRefYear: setCurrentNewDataRefYear(set),
    setCurrentUpdateDataRefYear: setCurrentUpdateDataRefYear(set),
}))
