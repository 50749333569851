import React, {useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
// material
import {CircularProgress, Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {RiskManagementToolApplicationDegreeForm} from '../components';

import {RiskManagementToolApplicationDegreeHeaderBreadcrumbs} from '../components';

import {PATH_RISK_PROTECTION} from '../../risk.protection.path';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useRiskManagementToolApplicationDegree} from '../services/risk.management.tool.application.degree.gql';
import {useRiskManagementToolApplicationDegreeStore} from "../services/risk.management.tool.application.degree.store";

// ----------------------------------------------------------------------

export default function RiskManagementToolApplicationDegreeEdit() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {showError} = useMessage();

    const {setCurrentUpdateRiskManagementToolApplicationDegree: setCurrentRiskManagementToolApplicationDegree} = useRiskManagementToolApplicationDegreeStore();

    const {entity: currentRiskManagementToolApplicationDegree, error, loading, errorMessage} = useRiskManagementToolApplicationDegree(id);

    useEffect(() => {
        if (loading) return;
        if (error) {
            showError(errorMessage);
            navigate(PATH_RISK_PROTECTION.riskManagementToolApplicationDegrees);
        }
    }, [loading, error, errorMessage]);

    useEffect(() => {
        setCurrentRiskManagementToolApplicationDegree(currentRiskManagementToolApplicationDegree);
    }, [currentRiskManagementToolApplicationDegree]);

    return (
        <Page title={"Risque et protection en RE: Modification degré d'application des outils de gestion de risques liés à l'eau"}>
            <Container maxWidth={'xl'}>
                <RiskManagementToolApplicationDegreeHeaderBreadcrumbs
                    heading="Modification du degré d'application des outils de gestion de risques liés à l'eau"
                    lastName={`Modification degré d'application des outils de gestion de risques liés à l'eau - ${id}`}

                />

                {loading && <CircularProgress/>}
                {!loading && currentRiskManagementToolApplicationDegree && <RiskManagementToolApplicationDegreeForm isEdit {...{currentRiskManagementToolApplicationDegree, setCurrentRiskManagementToolApplicationDegree}}/>}
            </Container>
        </Page>
    );
}
