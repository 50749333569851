import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const DataRefYearList = Loadable(lazy(() => import('./pages/DataRefYearList')));
const DataRefYearCreate = Loadable(lazy(() => import('./pages/DataRefYearCreate')));
const DataRefYearEdit = Loadable(lazy(() => import('./pages/DataRefYearEdit')));
const DataRefYearDisplay = Loadable(lazy(() => import('./pages/DataRefYearDisplay')));

const dataRefYearRouter = [
    {path: 'data-ref-years', element: <DataRefYearList/>},
    {path: 'data-ref-years/:id', element: <DataRefYearDisplay/>},
    {path: 'data-ref-years/new', element: <DataRefYearCreate/>},
    {path: 'data-ref-years/:id/edit', element: <DataRefYearEdit/>},
];

export default dataRefYearRouter;
