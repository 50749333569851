import React from 'react';
// material
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
// utils
import {PATH_ADMINISTRATIVE_DIVISION} from '../../administrative_division.path';
import {useCountCommunes} from '../services/commune.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function CommuneEntryButton() {
    const {count} = useCountCommunes();
    return (
        <SubModuleEntryButton
            label='Communes'
            value={count}
            url={PATH_ADMINISTRATIVE_DIVISION.communes}
            icon={<ConnectWithoutContactIcon/>}
        />
    );
}
