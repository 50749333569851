import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useNavigate} from 'react-router-dom';
import {Form, FormikProvider, useFormik} from 'formik';
import {Box, Card, Stack} from '@mui/material';
import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {
    FormikAutocomplete, FormikDatePicker,
    FormikTextField,
    FormRowStack,
    SubmitCancelGroupButton
} from '../../../../shared/components/froms/FormComponents';
import {setRefresh} from '../services/legislative.text.gql';
import useLegislativeTextService from '../services/legislative.text.services';
import {useConnectFormState} from "../../../../shared/hooks/useFormikState";
import {LegislativeTextSchema, InitialLegislativeText} from "../services/legislative.text.store";
import {formErrorMessageParser} from "../../../../utils/utils";
import {useSearchLegislativeTextTypes} from "../../legislative_text_type/services/legislative.text.type.gql";
import {LegislativeTextTypeCreateDialogMenuItem} from "../../legislative_text_type/components";
import {useSearchTypeSpheres} from "../../../organ_instrument/type_sphere/services/type.sphere.gql";
import {TypeSphereCreateDialogMenuItem} from "../../../organ_instrument/type_sphere/components";

LegislativeTextForm.propTypes = {
    isEdit: PropTypes.bool,
    currentLegislativeText: PropTypes.object,
    fromDialog: PropTypes.object,
};
export default function LegislativeTextForm({isEdit,
                                                 currentLegislativeText,
                                                 setCurrentLegislativeText,
                                                 fromDialog
                                             }) {
    const navigate = useNavigate();
    const {create, update} = useLegislativeTextService();
    const {showSuccess, showError} = useMessage();

    const initTypeSphereQuery = currentLegislativeText?.typeSphereObj?.name ?? fromDialog?.typeSphere?.name ?? '';
    const [typeSphereQuery, setTypeSphereQuery] = useState(initTypeSphereQuery);
    const {typeSpheres, typeSphereLoading} = useSearchTypeSpheres({typeSphereQuery});


    const initLegislativeTextTypeQuery = currentLegislativeText?.legislativeTextTypeObj?.name ?? fromDialog?.legislativeTextType?.name ?? '';
    const [legislativeTextTypeQuery, setLegislativeTextTypeQuery] = useState(initLegislativeTextTypeQuery);
    const {
        legislativeTextTypes,
        legislativeTextLoading
    } = useSearchLegislativeTextTypes({legislativeTextTypeQuery});

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: LegislativeTextSchema,
        initialValues: InitialLegislativeText(),
        onSubmit: async (legislativeText, {setSubmitting}) => {
            try {
                legislativeText = LegislativeTextSchema.cast(legislativeText);

                let message;
                if (!isEdit) {
                    legislativeText = await create(legislativeText);
                    message = 'Création avec succès';
                } else {
                    legislativeText = await update(currentLegislativeText.id, legislativeText);
                    message = 'Modification avec succès';
                }

                setRefresh(true);
                setCurrentLegislativeText();

                setSubmitting(false);
                showSuccess(message);

                if (fromDialog) {
                    fromDialog.onSave(legislativeText);
                } else {
                    navigate(`${PATH_GIRE_ENV_FAVORABLE.legislativeTexts}/${legislativeText.id}`);
                }
            } catch (error) {
                setSubmitting(false);
                showError(formErrorMessageParser({error, isEdit}));
            }
        }
    });

    const {values, setValues, handleSubmit, isSubmitting, setFieldValue} = formik;

    useConnectFormState({
        formValues: values, setFormValues: setValues,
        stateValues: currentLegislativeText, setStateValues: setCurrentLegislativeText,
    });

    const onCancel = (_) => {
        setCurrentLegislativeText();
        if (fromDialog) {
            fromDialog.onCancel();
        } else {
            navigate(PATH_GIRE_ENV_FAVORABLE.legislativeTexts);
        }
    };

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>

                <Card sx={{p: 3}}>

                    <Stack spacing={3}>

                        <FormRowStack>
                            <FormikTextField label='Nom' name='name' formik={formik}/>
                            <FormikTextField label='Description objectifs' name='descriptionObjectifs' formik={formik}/>
                        </FormRowStack>

                        <FormRowStack>
                            <Box flexGrow={1} display='flex' flexDirection='row'>
                                <FormikAutocomplete name='typeSphere'
                                                    label="Types de sphère d'actions de GIRE"
                                                    options={typeSpheres}
                                                    labelField='name'
                                                    embeddedObjField='typeSphereObj'
                                                    {...{
                                                        typeSphereQuery,
                                                        formik,
                                                        typeSphereLoading,
                                                        setTypeSphereQuery
                                                    }}
                                />
                                <Box sx={{py: 1, ml: 1}}>
                                    <TypeSphereCreateDialogMenuItem btn onSave={(createdTypeSphere) => {
                                        currentLegislativeText.typeSphereObj = createdTypeSphere;
                                        setTypeSphereQuery(createdTypeSphere?.name ?? '');
                                        setFieldValue('typeSphere', createdTypeSphere?.id);
                                    }}/>
                                </Box>
                            </Box>
                            <Box flexGrow={1} display='flex' flexDirection='row'>
                                <FormikAutocomplete name='legislativeTextType'
                                                    label="Types textes de cadre législatif et reglementaire eau"
                                                    options={legislativeTextTypes}
                                                    labelField='name'
                                                    embeddedObjField='legislativeTextTypeObj'
                                                    {...{
                                                        legislativeTextTypeQuery,
                                                        formik,
                                                        legislativeTextLoading,
                                                        setLegislativeTextTypeQuery
                                                    }}
                                />
                                <Box sx={{py: 1, ml: 1}}>
                                    <LegislativeTextTypeCreateDialogMenuItem btn
                                                                              onSave={(createdLegislativeTextType) => {
                                                                                  currentLegislativeText.legislativeTextTypeObj = createdLegislativeTextType;
                                                                                  setLegislativeTextTypeQuery(createdLegislativeTextType?.name ?? '');
                                                                                  setFieldValue('legislativeTextType', createdLegislativeTextType?.id);
                                                                              }}/>
                                </Box>
                            </Box>
                        </FormRowStack>

                        <FormRowStack>
                            <FormikDatePicker label='Date de mise en place' name='dateMizPlace'/>
                            <FormikTextField label='Structure Resp' name='structureResp' formik={formik}/>
                        </FormRowStack>

                        <FormRowStack>
                            <FormikTextField label='Source des données' name='dataSource' formik={formik}/>
                        </FormRowStack>

                        <SubmitCancelGroupButton {...{isEdit, isSubmitting, onCancel}}/>
                    </Stack>

                </Card>

            </Form>
        </FormikProvider>
    );
}
