import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {Form, FormikProvider, useFormik} from 'formik';
import {useMessage} from '../../../../shared/hooks/useMessage';
// material
import {LoadingButton} from '@mui/lab';
import {Card, Stack, Box, Button} from '@mui/material';
// routes
import {setRefresh} from '../services/risk.management.tool.gql';
import useRiskManagementToolService from '../services/risk.management.tool.services';
import {formErrorMessageParser} from '../../../../utils/utils';
import {FormikAutocomplete, FormikTextField} from '../../../../shared/components/froms/FormComponents';
import {PATH_RISK_PROTECTION} from '../../risk.protection.path';
import {InitialRiskManagementTool, RiskManagementToolSchema} from '../services/risk.management.tool.store';
import {useConnectFormState} from '../../../../shared/hooks/useFormikState';

import {useSearchDataRefYears} from "../../../administrative_division/data_ref_year/services/data.ref.year.gql";
import {useSearchRiskManagementToolTypes} from "../../risk_management_tool_type/services/risk.management.tool.type.gql";
import {HealthAreaCreateDialogMenuItem} from "../../health_areas/components";
import {DataRefYearCreateDialogMenuItem} from "../../../administrative_division/data_ref_year/components";
import {RiskManagementToolTypeCreateDialogMenuItem} from "../../risk_management_tool_type/components";

RiskManagementToolForm.propTypes = {
    isEdit: PropTypes.bool,
    currentRiskManagementTool: PropTypes.object,
    fromDialog: PropTypes.object,
};

export default function RiskManagementToolForm({isEdit, currentRiskManagementTool, setCurrentRiskManagementTool, fromDialog}) {
    const navigate = useNavigate();
    const {create, update} = useRiskManagementToolService();
    const {showSuccess, showError} = useMessage();

    const initRiskManagementToolTypeQuery = currentRiskManagementTool?.riskManagementToolTypeObj?.name ?? fromDialog?.riskManagementToolType?.name ?? '';
    const [riskManagementToolTypeQuery, setRiskManagementToolTypeQuery] = useState(initRiskManagementToolTypeQuery);
    const {riskManagementToolTypes, riskManagementToolTypeLoading} = useSearchRiskManagementToolTypes({riskManagementToolTypeQuery});

    const initDataRefYearQuery = currentRiskManagementTool?.dataRefYearObj?.name ?? fromDialog?.dataRefYear?.name ?? '';
    const [dataRefYearQuery, setDataRefYearQuery] = useState(initDataRefYearQuery);
    const {dataRefYears, dataRefYearLoading} = useSearchDataRefYears({dataRefYearQuery});

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: InitialRiskManagementTool(),
        validationSchema: RiskManagementToolSchema,
        onSubmit: async (riskManagementTool, {setSubmitting}) => {
            try {
                riskManagementTool = RiskManagementToolSchema.cast(riskManagementTool);

                let message;
                if (!isEdit) {
                    riskManagementTool = await create(riskManagementTool);
                    message = 'Création avec succès';
                } else {
                    riskManagementTool = await update(currentRiskManagementTool.id, riskManagementTool);
                    message = 'Modification avec succès';
                }

                setRefresh(true);

                setCurrentRiskManagementTool();

                setSubmitting(false);
                showSuccess(message);

                if (fromDialog) {
                    fromDialog.onSave(riskManagementTool);
                } else {
                    navigate(`${PATH_RISK_PROTECTION.riskManagementTools}/${riskManagementTool.id}`);
                }
            } catch (error) {
                setSubmitting(false);
                showError(formErrorMessageParser({error, isEdit}));
            }
        }
    });

    const {values, setValues, handleSubmit, isSubmitting, setFieldValue} = formik;

    useConnectFormState({
        formValues: values, setFormValues: setValues,
        stateValues: currentRiskManagementTool, setStateValues: setCurrentRiskManagementTool,
    });

    const onCancel = (_) => {
        setCurrentRiskManagementTool();
        if (fromDialog) {
            fromDialog.onCancel();
        } else {
            navigate(PATH_RISK_PROTECTION.riskManagementTools);
        }
    }

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete='off' onSubmit={handleSubmit}>

                <Card sx={{p: 3}}>

                    <Stack spacing={3}>

                        <FormikTextField label='Nom' name='name' formik={formik}/>
                        <Box flexGrow={1} display='flex' flexDirection='row'>
                            <FormikAutocomplete
                                name='dataRefYear'
                                label="Année de reférence des données"
                                options={dataRefYears}
                                labelField='name'
                                embeddedObjField='dataRefYearObj'
                                {...{query: dataRefYearQuery, formik, loading: dataRefYearLoading,  setQuery: setDataRefYearQuery}}
                            />
                            <Box sx={{py: 1, ml: 1}}>
                                <DataRefYearCreateDialogMenuItem btn onSave={(createdDataRefYear) => {
                                    currentRiskManagementTool.dataRefYearObj = createdDataRefYear;
                                    setDataRefYearQuery(createdDataRefYear?.name ?? '');
                                    setFieldValue('dataRefYear', createdDataRefYear?.id);
                                }}/>
                            </Box>
                        </Box>

                        <Box flexGrow={1} display='flex' flexDirection='row'>
                            <FormikAutocomplete
                                name='riskManagementToolType'
                                label="Types outils de gestion de risques liées à l'eau"
                                options={riskManagementToolTypes}
                                labelField='name'
                                embeddedObjField='riskManagementToolTypeObj'
                                {...{query: riskManagementToolTypeQuery, formik, loading: riskManagementToolTypeLoading, setQuery: setRiskManagementToolTypeQuery}}
                            />
                            <Box sx={{py: 1, ml: 1}}>
                                <RiskManagementToolTypeCreateDialogMenuItem btn onSave={(createdRiskManagementToolType) => {
                                    currentRiskManagementTool.riskManagementToolTypeObj = createdRiskManagementToolType;
                                    setRiskManagementToolTypeQuery(createdRiskManagementToolType?.name ?? '');
                                    setFieldValue('riskManagementToolType', createdRiskManagementToolType?.id);
                                }}/>
                            </Box>
                        </Box>
                        <FormikTextField label='Source de données' name='dataSource' formik={formik}/>

                        <Box sx={{mt: 3, display: 'flex', justifyContent: 'flex-end'}}>
                            <Button
                                sx={{mr: 2}}
                                size='medium'
                                onClick={onCancel}
                                disabled={isSubmitting}
                            >
                                {'Annuler'}
                            </Button>
                            <LoadingButton type='submit' variant='contained' size='medium' loading={isSubmitting}>
                                {!isEdit ? 'Créer' : 'Modifier'}
                            </LoadingButton>
                        </Box>
                    </Stack>

                </Card>

            </Form>
        </FormikProvider>
    );
}
