import React, {lazy} from 'react';
import Loadable from '../../../../shared/components/Loadable';


const WidgetList = Loadable(lazy(() => import('./pages/WidgetList')));
const WidgetEdit = Loadable(lazy(() => import('./pages/WidgetEdit')));
const WidgetCreate = Loadable(lazy(() => import('./pages/WidgetCreate')));
const WidgetDisplay = Loadable(lazy(() => import('./pages/WidgetDisplay')));

const widgetRouter = [
    {path: 'widgets', element: <WidgetList/>},
    {path: 'widgets/new', element: <WidgetCreate/>},
    {path: 'widgets/:id', element: <WidgetDisplay/>},
    {path: 'widgets/:id/edit', element: <WidgetEdit/>},
];

export default widgetRouter;
