import React from 'react';
import {Power} from '@mui/icons-material';
import {path} from '../../home/sidebar/paths';

//Features
const SANITATION_ACCESS = '/sanitation-access';
const _path = path(SANITATION_ACCESS);
export const PATH_SANITATION_ACCESS = {
    root: SANITATION_ACCESS,
    home: _path('/home'),
    occurrenceAccessRates: _path("/occurrence-access-rates"),
    occurenceEmopResults: _path("/occurence-emop-results"),
};




export const  sanitationAccessMenu = [
    {
        title: 'Acces-Assainissement',
        path: PATH_SANITATION_ACCESS.root,
        icon: <Power/>,
        children: [
            { title: 'Accueil', path: PATH_SANITATION_ACCESS.home },
        ]
    }
];

export const commonItems = [
    { name: "Accueil", href: "/" },
    { name: "Acces-Assainissement", href: PATH_SANITATION_ACCESS.home },
];