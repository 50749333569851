import {useEffect, useState} from 'react';



export function useConnectFormikState({formik, stateValues, setStateValues}) {
    const {values: formValues, setValues: setFormValues} = formik;

    useConnectFormState({
        formValues, setFormValues,
        stateValues, setStateValues
    });
}

export function useConnectFormState({formValues, setFormValues, stateValues, setStateValues}) {
    const [firstInit, setFirstInit] = useState(false);

    // console.log({firstInit});

    useEffect(() => {
        if (firstInit) return;
        setFirstInit(true);
        setFormValues(stateValues);
    }, [stateValues, setFormValues, firstInit]);

    useEffect(() => {
        setStateValues(formValues);
    }, [formValues, setStateValues]);

    return {firstInit, formValues, stateValues}
}
