import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const OccurrenceAchievementList = Loadable(lazy(() => import('./pages/OccurrenceAchievementList')));
const OccurrenceAchievementCreate = Loadable(lazy(() => import('./pages/OccurrenceAchievementCreate')));
const OccurrenceAchievementEdit = Loadable(lazy(() => import('./pages/OccurrenceAchievementEdit')));
const OccurrenceAchievementDisplay = Loadable(lazy(() => import('./pages/OccurrenceAchievementDisplay')));

const occurrenceAchievementRouter = [
    {path: 'occurrence-achievements', element: <OccurrenceAchievementList/>},
    {path: 'occurrence-achievements/:id', element: <OccurrenceAchievementDisplay/>},
    {path: 'occurrence-achievements/new', element: <OccurrenceAchievementCreate/>},
    {path: 'occurrence-achievements/:id/edit', element: <OccurrenceAchievementEdit/>},
];

export default occurrenceAchievementRouter;
