import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const SpecificWaterRequirementList = Loadable(lazy(() => import('./pages/SpecificWaterRequirementList')));
const SpecificWaterRequirementCreate = Loadable(lazy(() => import('./pages/SpecificWaterRequirementCreate')));
const SpecificWaterRequirementEdit = Loadable(lazy(() => import('./pages/SpecificWaterRequirementEdit')));
const SpecificWaterRequirementDisplay = Loadable(lazy(() => import('./pages/SpecificWaterRequirementDisplay')));

const specificWaterRequirementRouter = [
    {path: 'specific-water-requirements', element: <SpecificWaterRequirementList/>},
    {path: 'specific-water-requirements/:id', element: <SpecificWaterRequirementDisplay/>},
    {path: 'specific-water-requirements/new', element: <SpecificWaterRequirementCreate/>},
    {path: 'specific-water-requirements/:id/edit', element: <SpecificWaterRequirementEdit/>},
];

export default specificWaterRequirementRouter;
