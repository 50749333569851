import {useMessage} from './useMessage';


export const useDelete = ({destroyFn, successMessage, errorMessage, onSuccess, onError}) => {
    const {showSuccess, showError} = useMessage();
    const onDelete = (id) => {
        // console.log('id =============================> ', id);
        destroyFn && destroyFn(id)
            .then(resp => {
                showSuccess(successMessage ?? `Suppression realisee avec succès`);
                onSuccess && onSuccess();
                // refetch();
            })
            .catch(error => {
                console.log('error =====================> ', error)
                showError(errorMessage ?? `Erreur lors de la suppression: ${error}`);
                onError && onError();
            });
    }

    return {onDelete, showSuccess, showError}
}
