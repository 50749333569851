import * as Yup from 'yup';
import create from 'zustand';

export const RiskManagementToolApplicationDegreeSchema = Yup.object().shape({
    name: Yup.string().default('').required('Le nom est obligatoire'),
    description: Yup.string().default(''),
    dataSource: Yup.string().default(''),
    notation: Yup.string().default('').required('La notation est obligatoire'),
    notationCriteria: Yup.string().default('').required('Le critère de notation est obligatoire'),
});

const initialRiskManagementToolApplicationDegree = RiskManagementToolApplicationDegreeSchema.getDefaultFromShape();
export const InitialRiskManagementToolApplicationDegree = () => ({...initialRiskManagementToolApplicationDegree});

const setCurrentNewRiskManagementToolApplicationDegree = set => riskManagementToolApplicationDegree => {
    set(state => ({...state, currentNewRiskManagementToolApplicationDegree: riskManagementToolApplicationDegree ?? InitialRiskManagementToolApplicationDegree()}));
}

const setCurrentUpdateRiskManagementToolApplicationDegree = set => riskManagementToolApplicationDegree => {
    set(state => ({...state, currentUpdateRiskManagementToolApplicationDegree: riskManagementToolApplicationDegree ?? InitialRiskManagementToolApplicationDegree()}));
}

export const useRiskManagementToolApplicationDegreeStore = create(set => ({
    //State
    currentNewRiskManagementToolApplicationDegree: InitialRiskManagementToolApplicationDegree(),
    currentUpdateRiskManagementToolApplicationDegree: InitialRiskManagementToolApplicationDegree(),
    //Reducers
    setCurrentNewRiskManagementToolApplicationDegree: setCurrentNewRiskManagementToolApplicationDegree(set),
    setCurrentUpdateRiskManagementToolApplicationDegree: setCurrentUpdateRiskManagementToolApplicationDegree(set),
}))
