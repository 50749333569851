import {gql, useQuery, useLazyQuery} from '@apollo/client';
import {parseCount, parseEntity} from '../../../../utils/gql.utils';
import {useEffect, useState} from 'react';
import {useMessage} from '../../../../shared/hooks/useMessage';

export let needRefresh = false;
export const setRefresh = (value) => needRefresh = value;

export const communeFields = `
    id name circle circleObj {id name} 
    statusCommune statusCommuneObj{id name}
`;

///////////////////// Find By ID /////////////////////
const GET_COMMUNE_BY_ID = gql`
    query CommuneById($id: Int!) {
        communeById(id: $id) {
            error message entity { ${communeFields} }
        }
    }
`;

export const useCommuneById = (id) => {
    id = parseInt(id);
    return useQuery(GET_COMMUNE_BY_ID, {
        fetchPolicy: 'network-only',
        variables: {id},
    });
}

export const useCommune = (id) => {
    const result = useCommuneById(id);
    return parseEntity(result, 'communeById');
}

///////////////////// Search /////////////////////
const SEARCH_COMMUNES = gql`
    query SearchCommunes($query: String, $statusCommune: Int, $circle: Int, $page: Int, $size: Int) {
        searchCommunes(query: $query,statusCommune: $statusCommune, circle: $circle, page: $page, size: $size) {
            error message page {
                content { ${communeFields} }
                page hasNext hasPrevious totalPages, totalElements, numberOfElements,
            }
        }
    }
`;

export const useSearchCommunes = ({query = '', statusCommune, circle, page = 0, size = 20}) => {
    const [communes, setCommunes] = useState([]);
    const [communePage, setCommunePage] = useState(null);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_COMMUNES, {
        variables: {query, statusCommune,  circle, page, size},
    });

    useEffect(() => {
        if(_error) {
            console.log('##### _error: ', _error);
            showError('Erreur lors de l\'operation');
            setCommunePage(null);
            setCommunes([]);
            return;
        }

        if (!searchData) return;

        const {error, message, page} = searchData.searchCommunes;


        if (error) {
            console.log('##### error: ', error);
            showError(message);
            setCommunePage(null);
            setCommunes([]);
        } else {
            setCommunePage(page);
            setCommunes(page.content);
        }


        if (needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({variables: {query,statusCommune,  circle, page, size}});
    }, [query,statusCommune,  circle, page, size]);

    return {
        communes, loading, search, refetch, communePage
    }
}

///////////////////// Count /////////////////////
const COUNT_COMMUNES = gql`
    query CountCommunes {
        countCommunes {
            error message count
        }
    }
`;

export const useCountCommunes = () => {
    const result = useQuery(COUNT_COMMUNES, {
        fetchPolicy: 'network-only'
    });
    return parseCount(result, 'countCommunes');
}
