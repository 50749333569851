import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const StructureList = Loadable(lazy(() => import("./pages/StructureList")));
const StructureCreate = Loadable(lazy(() => import("./pages/StructureCreate")));
const StructureEdit = Loadable(lazy(() => import("./pages/StructureEdit")));
const StructureDisplay = Loadable(
  lazy(() => import("./pages/StructureDisplay"))
);

const structureRouter = [
  { path: "structures", element: <StructureList /> },
  { path: "structures/:id", element: <StructureDisplay /> },
  { path: "structures/new", element: <StructureCreate /> },
  { path: "structures/:id/edit", element: <StructureEdit /> },
];

export default structureRouter;
