import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const OccurrenceEnvironmentalList = Loadable(lazy(() => import('./pages/OccurrenceEnvironmentalList')));
const OccurrenceEnvironmentalCreate = Loadable(lazy(() => import('./pages/OccurrenceEnvironmentalCreate')));
const OccurrenceEnvironmentalEdit = Loadable(lazy(() => import('./pages/OccurrenceEnvironmentalEdit')));
const OccurrenceEnvironmentalDisplay = Loadable(lazy(() => import('./pages/OccurrenceEnvironmentalDisplay')));

const occurrenceEnvironmentalRouter = [
    {path: 'occurrence-environmentals', element: <OccurrenceEnvironmentalList/>},
    {path: 'occurrence-environmentals/:id', element: <OccurrenceEnvironmentalDisplay/>},
    {path: 'occurrence-environmentals/new', element: <OccurrenceEnvironmentalCreate/>},
    {path: 'occurrence-environmentals/:id/edit', element: <OccurrenceEnvironmentalEdit/>},
];

export default occurrenceEnvironmentalRouter;
