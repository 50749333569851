import React from 'react';
// material
import {Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {RiskManagementToolTypeForm} from '../components';

import {RiskManagementToolTypeHeaderBreadcrumbs} from '../components';
import {useRiskManagementToolTypeStore} from "../services/risk.management.tool.type.store";

// ----------------------------------------------------------------------

export default function RiskManagementToolTypeCreate() {
    const {currentNewRiskManagementToolType: currentRiskManagementToolType, setCurrentNewRiskManagementToolType: setCurrentRiskManagementToolType} = useRiskManagementToolTypeStore();
    return (
        <Page title="Risque et protection en RE: Création types outils de gestion de risques liées à l'eau">
            <Container  maxWidth={'xl'}>
                <RiskManagementToolTypeHeaderBreadcrumbs
                    heading="Création de nouveau type outils de gestion de risques liées à l'eau"
                    lastName="nouveau type outils de gestion de risques liées à l'eau"
                />
                <RiskManagementToolTypeForm {...{currentRiskManagementToolType, setCurrentRiskManagementToolType}}/>
            </Container>
        </Page>
    );
}
