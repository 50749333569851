import {useApiHttp} from '../../../../shared/hooks/useHttp';

const useInstrumentPolicyTypeService = () => {
    const {crud} = useApiHttp();

    const {
        create,
        update,
        destroy,
        findById,
        findAll,
    } = crud('instrument-policy-types');

    return {
        create,
        update,
        destroy,
        findById,
        findAll,
    }
}

export default useInstrumentPolicyTypeService;
