import React from "react";
import { LocationOn } from "@mui/icons-material";
import { path } from "../../home/sidebar/paths";

//Features
const ROOTS_ADMINISTRATIVE_DIVISION = "/administrative-division";
const pathAdministrativeDivision = path(ROOTS_ADMINISTRATIVE_DIVISION);
export const PATH_ADMINISTRATIVE_DIVISION = {
  root: ROOTS_ADMINISTRATIVE_DIVISION,
  home: pathAdministrativeDivision("/home"),
  pays: pathAdministrativeDivision("/pays"),
  regions: pathAdministrativeDivision("/regions"),
  circles: pathAdministrativeDivision("/circles"),
  communes: pathAdministrativeDivision("/communes"),
  localities: pathAdministrativeDivision("/localities"),
  localityTypes: pathAdministrativeDivision("/locality-types"),
  village_status: pathAdministrativeDivision("/village-status"),
  environment: pathAdministrativeDivision("/environments"),
  origin_population: pathAdministrativeDivision("/origin-populations"),
  dataRefYears: pathAdministrativeDivision("/data-ref-years"),
  statusCommunes: pathAdministrativeDivision("/status-communes"),
};

export const administrativeDivisionMenu = [
  {
    title: "Découpage administratif",
    path: PATH_ADMINISTRATIVE_DIVISION.root,
    icon: <LocationOn />,
    children: [
      { title: "Accueil", path: PATH_ADMINISTRATIVE_DIVISION.home },
      { title: "Pays", path: PATH_ADMINISTRATIVE_DIVISION.pays },
      { title: "Regions", path: PATH_ADMINISTRATIVE_DIVISION.regions },
      { title: "Cercles", path: PATH_ADMINISTRATIVE_DIVISION.circles },
      { title: "Communes", path: PATH_ADMINISTRATIVE_DIVISION.communes },
      { title: "Localites", path: PATH_ADMINISTRATIVE_DIVISION.localities },
      {
        title: "Types localite",
        path: PATH_ADMINISTRATIVE_DIVISION.localityTypes,
      },
      {
        title: "Status village",
        path: PATH_ADMINISTRATIVE_DIVISION.village_status,
      },
      {
        title: "Milieu",
        path: PATH_ADMINISTRATIVE_DIVISION.environment,
      },
      {
        title: "Origines population",
        path: PATH_ADMINISTRATIVE_DIVISION.origin_population,
      },
      {
        title: "Années reférence données",
        path: PATH_ADMINISTRATIVE_DIVISION.dataRefYears,
      },
      {
        title: "Statuts commune",
        path: PATH_ADMINISTRATIVE_DIVISION.statusCommunes,
      },
    ],
  },
];

export const commonItems = [
  { name: "Accueil", href: "/" },
  { name: "Decoupage administratif", href: PATH_ADMINISTRATIVE_DIVISION.home },
];
