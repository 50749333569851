import React from 'react';
// material
import { Typography } from '@mui/material';
// components
import {LocalityForm} from '../components';
import CreateDialogMenuItem from '../../../../shared/components/CreateDialogMenuItem';
// ----------------------------------------------------------------------

export function LocalityCreateDialogMenuItem({localityType, commune, onClose, onSave, onClick, btn}) {
    const setCurrentLocality = () => {};
    const currentLocality = {
        commune: commune?.id, communeObj: commune,
        localityType: localityType?.id, localityTypeObj: localityType
    };
    return (
        <CreateDialogMenuItem
            btn={btn}
            onClose={onClose}
            onClick={onClick}
            menuText='Ajouter une localite'
            dialogTitle='Création de localite'
            dialogContextText={<>
                Création de nouvelle localite pour
                <Typography variant='h6' component={'span'}> {localityType?.name ?? commune?.name ?? ''}</Typography>
            </>}
            formRender={({onClose}) => {
                return (
                    <LocalityForm
                        {...{currentLocality, setCurrentLocality}}
                        fromDialog={{
                            localityType,
                            commune,
                            onCancel: onClose,
                            onSave: (createdLocality) => {
                                onSave && onSave(createdLocality);
                                onClose && onClose();
                            },
                        }}
                    />
                );
            }}
        />
    );
}
