import React from 'react';
import {PATH_REPORTING} from '../../../reporting.path';
import HeaderBreadcrumbs from "../../../../../shared/components/HeaderBreadcrumbs";

const commonItems = [
    {name: 'Accueil', href: '/'},
    {name: 'Reporting', href: PATH_REPORTING.home},
];

export function ReportingHeaderBreadcrumbs ({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[
            ...commonItems,
            {name: `Reporting`, href: PATH_REPORTING.reporting},
            {name: lastName},
        ]}
    />);
}

export function ReportingListHeaderBreadcrumbs ({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[...commonItems, {name: lastName}]}
    />);
}
