import React, {lazy} from 'react';
import InstitutionalOrganizationalFrameworkStructureOccurrenceList from "./pages/InstitutionalOrganizationalFrameworkStructureOccurrenceList";
import InstitutionalOrganizationalFrameworkStructureOccurrenceDisplay from "./pages/InstitutionalOrganizationalFrameworkStructureOccurrenceDisplay";
import InstitutionalOrganizationalFrameworkStructureOccurrenceCreate from "./pages/InstitutionalOrganizationalFrameworkStructureOccurrenceCreate";
import InstitutionalOrganizationalFrameworkStructureOccurrenceEdit from "./pages/InstitutionalOrganizationalFrameworkStructureOccurrenceEdit";

const institutionalOrganizationalFrameworkStructureOccurrenceRouter = [
    {path: 'institutional-organizational-framework-structure-occurrences', element: <InstitutionalOrganizationalFrameworkStructureOccurrenceList/>},
    {path: 'institutional-organizational-framework-structure-occurrences/:id', element: <InstitutionalOrganizationalFrameworkStructureOccurrenceDisplay/>},
    {path: 'institutional-organizational-framework-structure-occurrences/new', element: <InstitutionalOrganizationalFrameworkStructureOccurrenceCreate/>},
    {path: 'institutional-organizational-framework-structure-occurrences/:id/edit', element: <InstitutionalOrganizationalFrameworkStructureOccurrenceEdit/>},

];
export default institutionalOrganizationalFrameworkStructureOccurrenceRouter;
