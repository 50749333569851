import * as Yup from 'yup';
import create from 'zustand';


export const ParticipationAreaAndTypeSchema = Yup.object().shape({
    name: Yup.string().default('').required('Le nom est obligatoire'),
    description: Yup.string().default(''),
    dataSource: Yup.string().default(''),
});

const initialParticipationAreaAndType = ParticipationAreaAndTypeSchema.getDefaultFromShape();
export const InitialParticipationAreaAndType = () => ({...initialParticipationAreaAndType});

const setCurrentNewParticipationAreaAndType = set => participationAreaAndType => {
    set(state => ({...state, currentNewParticipationAreaAndType: participationAreaAndType ?? InitialParticipationAreaAndType()}));
}

const setCurrentUpdateParticipationAreaAndType = set => participationAreaAndType => {
    set(state => ({...state, currentUpdateParticipationAreaAndType: participationAreaAndType ?? InitialParticipationAreaAndType()}));
}

export const useParticipationAreaAndTypeStore = create(set => ({
    //State
    currentNewParticipationAreaAndType: InitialParticipationAreaAndType(),
    currentUpdateParticipationAreaAndType: InitialParticipationAreaAndType(),
    //Reducers
    setCurrentNewParticipationAreaAndType: setCurrentNewParticipationAreaAndType(set),
    setCurrentUpdateParticipationAreaAndType: setCurrentUpdateParticipationAreaAndType(set),
}))
