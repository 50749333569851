import React, {useState} from 'react';
import {Outlet} from 'react-router-dom';
// material
import {styled} from '@mui/material/styles';
//
import {HomeNavbar, HomeSidebar} from '.';
import {Box} from "@mui/material";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const MainStyle = styled('div')(({theme}) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    background: theme.palette.grey["100"],
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
        paddingTop: APP_BAR_DESKTOP + 24,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}));

// ----------------------------------------------------------------------

export default function AppLayout() {
    const [open, setOpen] = useState(false);

    return (
        <Box display='flex' minHeight='100%' overflow='hidden'>
            <HomeNavbar onOpenSidebar={() => setOpen(true)}/>
            <HomeSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)}/>
            <MainStyle>
                <Outlet/>
            </MainStyle>
        </Box>
    );
}
