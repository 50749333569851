export const entryButtonHover = {scale: 1.03};

export const entryButtonHidden = {x: "100vw", scale: 0.1};

export const entryButtonShow = {
    x: 0,
    scale: 1,
    type: "spring",
    stiffness: 100,
    transition: {duration: 0.5},
};
