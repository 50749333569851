import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const SphereGireList = Loadable(
  lazy(() => import("./pages/SphereGireList"))
);
const SphereGireCreate = Loadable(
  lazy(() => import("./pages/SphereGireCreate"))
);
const SphereGireEdit = Loadable(
  lazy(() => import("./pages/SphereGireEdit"))
);
const SphereGireDisplay = Loadable(
  lazy(() => import("./pages/SphereGireDisplay"))
);

const sphereGireRouter = [
  {
    path: "sphere-gire",
    element: <SphereGireList />,
  },
  {
    path: "sphere-gire/:id",
    element: <SphereGireDisplay />,
  },
  {
    path: "sphere-gire/new",
    element: <SphereGireCreate />,
  },
  {
    path: "sphere-gire/:id/edit",
    element: <SphereGireEdit />,
  },
];

export default sphereGireRouter;
