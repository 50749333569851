import React from 'react';
import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
import {PATH_REPORTING} from '../../../reporting.path';
import {
    Info, Edit, PlayCircle, FileCopyOutlined, Download, Add
} from '@mui/icons-material';
import {useDuplicateReporting, useExportReporting} from './index';
import {ActionsButton} from "../../../../../shared/components/DefaultActionsButton";
import {useRoleChecker} from "../../../../../shared/hooks/useAuth";

ReportingMoreMenu.propTypes = {
    onDelete: PropTypes.func,
    id: PropTypes.number
};

export default function ReportingMoreMenu({onDelete, id, isIconButton, hiddenNew, hiddenDisplay}) {

    const duplicator = useDuplicateReporting();
    const {hasRole, hasNotRole} = useRoleChecker();
    const {byId: exportById} = useExportReporting();

    return (
        <ActionsButton
            isIconButton={isIconButton}
            items={[
                {
                    label: 'Executer',
                    icon: <PlayCircle/>,
                    component: RouterLink,
                    to: `${PATH_REPORTING.reporting}/${id}/execute`
                },
                {
                    label: 'Dupliquer',
                    icon: <FileCopyOutlined/>,
                    onClick: () => duplicator(id).then(console.log)
                },
                {
                    label: 'Exporter',
                    icon: <Download/>,
                    onClick: () => exportById(id).then(console.log)
                },
                {divider: true},
                {
                    icon: <Info/>,
                    label: 'Afficher',
                    hidden: hiddenDisplay || hasNotRole('ROLE_REPORTING_VIEW'),
                    component: RouterLink,
                    to: `${PATH_REPORTING.reporting}/${id}`,
                },
                {
                    icon: <Add/>,
                    label: 'Nouveau',
                    hidden: hiddenNew,
                    component: RouterLink,
                    to: `${PATH_REPORTING.reporting}/new`
                },
                {
                    icon: <Edit/>,
                    label: 'Modifier',
                    component: RouterLink,
                    to: `${PATH_REPORTING.reporting}/${id}/edit`
                },
                {divider: true},
                {
                    isConfirmation: true,
                    onClick: () => onDelete(id),
                    DialogProps: {
                        message: 'Voulez-vous vraiment supprimer ce reporting'
                    },
                },
            ]}
        />
    );
}
