import React, {useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
// material
import {CircularProgress, Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {InstrumentMechanismParticipationOccurrenceForm} from '../components';

import {InstrumentMechanismParticipationOccurrenceHeaderBreadcrumbs} from '../components';

import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useInstrumentMechanismParticipationOccurrence} from '../services/instrument.mechanism.participation.occurrence.gql';
import {useInstrumentMechanismParticipationOccurrenceStore} from "../services/instrument.mechanism.participation.occurrence.store";

export default function InstrumentMechanismParticipationOccurrenceEdit() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {showError} = useMessage();

    const {setCurrentUpdateInstrumentMechanismParticipationOccurrence: setCurrentInstrumentMechanismParticipationOccurrence} = useInstrumentMechanismParticipationOccurrenceStore();

    const {entity: currentInstrumentMechanismParticipationOccurrence, error, loading, errorMessage} = useInstrumentMechanismParticipationOccurrence(id);

    useEffect(() => {
        if (loading) return;
        if (error) {
            showError(errorMessage);
            navigate(PATH_GIRE_ENV_FAVORABLE.instrumentMechanismParticipationOccurrences);
        }
    }, [loading, error, errorMessage]);

    useEffect(() => {
        setCurrentInstrumentMechanismParticipationOccurrence(currentInstrumentMechanismParticipationOccurrence);
    }, [currentInstrumentMechanismParticipationOccurrence]);

    return (
        <Page title={"Gire environment favorable: Modification occurrences d'état de mise en œuvre des instruments et mécanismes de participation à la GIRE"}>
            <Container maxWidth={'xl'}>
                <InstrumentMechanismParticipationOccurrenceHeaderBreadcrumbs
                    heading="Modification du occurrences d'état de mise en œuvre des instruments et mécanismes de participation à la GIRE"
                    lastName={`Modification occurrences d'état de mise en œuvre des instruments et mécanismes de participation à la GIRE - ${id}`}
                />
                {loading && <CircularProgress/>}
                {!loading && currentInstrumentMechanismParticipationOccurrence && <InstrumentMechanismParticipationOccurrenceForm isEdit {...{currentInstrumentMechanismParticipationOccurrence, setCurrentInstrumentMechanismParticipationOccurrence}}/>}
            </Container>
        </Page>
    );
}
