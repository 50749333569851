import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const CircleList = Loadable(lazy(() => import('./pages/CircleList')));
const CircleCreate = Loadable(lazy(() => import('./pages/CircleCreate')));
const CircleEdit = Loadable(lazy(() => import('./pages/CircleEdit')));
const CircleDisplay = Loadable(lazy(() => import('./pages/CircleDisplay')));

const circleRouter = [
    {path: 'circles', element: <CircleList/>},
    {path: 'circles/:id', element: <CircleDisplay/>},
    {path: 'circles/new', element: <CircleCreate/>},
    {path: 'circles/:id/edit', element: <CircleEdit/>},
];

export default circleRouter;
