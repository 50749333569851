import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { PATH_REPORTING } from "../../../reporting.path";
import {
  Info,
  Delete,
  Edit,
  MoreVert,
  PlayCircle,
  FileCopyOutlined,
  Download,
} from "@mui/icons-material";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import { useDuplicateDashboard, useExportDashboard } from "./index";

DashboardMoreMenu.propTypes = {
  onDelete: PropTypes.func,
  id: PropTypes.number,
};

export default function DashboardMoreMenu({ onDelete, id }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const duplicator = useDuplicateDashboard();
  const { byId: exportById } = useExportDashboard();

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVert width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          component={RouterLink}
          to={`${PATH_REPORTING.dashboard}/${id}/execute`}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <PlayCircle />
          </ListItemIcon>
          <ListItemText
            primary="Executer"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        <MenuItem
          sx={{ color: "text.secondary" }}
          onClick={() => {
            duplicator(id);
            setIsOpen(false);
          }}
        >
          <ListItemIcon>
            <FileCopyOutlined />
          </ListItemIcon>
          <ListItemText
            primary="Dupliquer"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        <MenuItem
          sx={{ color: "text.secondary" }}
          onClick={() => {
            exportById(id);
            setIsOpen(false);
          }}
        >
          <ListItemIcon>
            <Download />
          </ListItemIcon>
          <ListItemText
            primary="Exporter"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        <Divider />

        <MenuItem
          component={RouterLink}
          to={`${PATH_REPORTING.dashboard}/${id}`}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Info />
          </ListItemIcon>
          <ListItemText
            primary="Afficher"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        <MenuItem
          component={RouterLink}
          to={`${PATH_REPORTING.dashboard}/${id}/edit`}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText
            primary="Modifier"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        <MenuItem
          onClick={() => {
            onDelete();
            setIsOpen(false);
          }}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText
            primary="Supprimer"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
