import React from 'react';
// components
import {LocalityTypeForm} from '../components';
import CreateDialogMenuItem from '../../../../shared/components/CreateDialogMenuItem';
import {InitialLocalityType} from '../services/locality_type.store';
// ----------------------------------------------------------------------

export function LocalityTypeCreateDialogMenuItem({onClose, onSave, onClick, btn}) {
    const setCurrentLocalityType = () => {};
    const currentLocalityType = InitialLocalityType();
    return (
        <CreateDialogMenuItem
            btn={btn}
            onClose={onClose}
            onClick={onClick}
            menuText='Ajouter un type de localite'
            dialogTitle='Création de type de localite'
            dialogContextText={<> {`Création de nouveau type de localite`} </>}
            formRender={({onClose}) => {
                return (
                    <LocalityTypeForm
                        {...{currentLocalityType, setCurrentLocalityType}}
                        fromDialog={{
                            onCancel: onClose,
                            onSave: (createdLocalityType) => {
                                onSave && onSave(createdLocalityType);
                                onClose && onClose();
                            },
                        }}
                    />
                );
            }}
        />
    );
}
