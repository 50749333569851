import {useApiHttp} from '../../../../shared/hooks/useHttp';

const useInstitutionalOrganizationalFrameworkStructureOccurrenceService = () => {
    const {crud} = useApiHttp();

    const {
        create,
        update,
        destroy,
        findById,
        findAll,
    } = crud('institutional-organizational-framework-structure-occurrences');

    return {
        create,
        update,
        destroy,
        findById,
        findAll
    }
}

export default useInstitutionalOrganizationalFrameworkStructureOccurrenceService;
