import React, {useCallback} from 'react';
import {Clear, FilterList, Search} from "@mui/icons-material";
import {Box, Button, ClickAwayListener, Divider, IconButton, InputBase, Paper, styled, Typography} from "@mui/material";

import {useSearchItem} from "./useSearchItem";
import SearchSection from "./SearchSection";


export const PAPER_WIDTH = {xs: 400, sm: 550, md: 800, lg: 800};

const RootStyle = styled(Paper)(({theme}) => ({
    left: '50%',
    minHeight: 50,
    position: 'absolute',
    zIndex: theme.zIndex.modal,
    transform: 'translateX(-50%)',
    boxShadow: theme.customShadows.z20,
    borderRadius: theme.shape.borderRadiusMd,
    [theme.breakpoints.up('xs')]: {
        top: 10,
        width: PAPER_WIDTH.xs,
    },
    [theme.breakpoints.up('ms')]: {
        width: PAPER_WIDTH.sm,
    },
    [theme.breakpoints.up('md')]: {
        width: PAPER_WIDTH.md,
    },
    [theme.breakpoints.up('lg')]: {
        top: 20,
        width: PAPER_WIDTH.lg,
    },
}));

const StyledSearchWrapper = styled(Box)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    padding: theme.spacing(2, 2, 1, 2),
}))

const StyledSearchInput = styled(InputBase)(({theme}) => ({
    flexGrow: 1,
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
}))

const StyledSectionWrapper = styled(Box)(({theme}) => ({
    maxHeight: '80vh',
    overflow: 'scroll',
    padding: theme.spacing(2),
}))


const StyledBottomWrapper = styled(Box)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.primary.main,
    padding: theme.spacing(1, 2, 2, 2),
}))

function SearchPaper({setOpen, selectedTerms, setSelectedTerms}) {

    const {search, setSearch, filteredSidebarConfig, itemsCount, totalCount} = useSearchItem(selectedTerms);

    const handleSelectItem = useCallback((title) => {
        setSelectedTerms(title);
        setOpen(false);
    }, []);

    const handleClear = useCallback(() => {
        setSearch('');
        setSelectedTerms('');
    }, []);

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <RootStyle>
                <StyledSearchWrapper>
                    <Search/>
                    <StyledSearchInput
                        autoFocus
                        value={search}
                        placeholder={'Recherche: Modules, Entites'}
                        onChange={({target: {value}}) => setSearch(value)}
                    />
                    <IconButton onClick={handleClear}>
                        {search ? <Clear/> : <FilterList/>}
                    </IconButton>
                </StyledSearchWrapper>
                <Divider/>

                <StyledSectionWrapper>
                    {filteredSidebarConfig.map(section => (
                        <SearchSection key={section.subheader} section={section} onSelectItem={handleSelectItem}/>
                    ))}
                </StyledSectionWrapper>

                <Divider/>
                <StyledBottomWrapper>
                    <Typography>{itemsCount} / {totalCount}</Typography>
                    <Button onClick={() => setOpen(false)}>Fermer</Button>
                </StyledBottomWrapper>
            </RootStyle>
        </ClickAwayListener>
    );
}

export default SearchPaper;


