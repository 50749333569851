import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const ActivityTypeConstructionList = Loadable(lazy(() => import('./pages/ActivityTypeConstructionList')));
const ActivityTypeConstructionCreate = Loadable(lazy(() => import('./pages/ActivityTypeConstructionCreate')));
const ActivityTypeConstructionEdit = Loadable(lazy(() => import('./pages/ActivityTypeConstructionEdit')));
const ActivityTypeConstructionDisplay = Loadable(lazy(() => import('./pages/ActivityTypeConstructionDisplay')));

const activityTypeConstructionRouter = [
    {path: 'activity-type-constructions', element: <ActivityTypeConstructionList/>},
    {path: 'activity-type-constructions/:id', element: <ActivityTypeConstructionDisplay/>},
    {path: 'activity-type-constructions/new', element: <ActivityTypeConstructionCreate/>},
    {path: 'activity-type-constructions/:id/edit', element: <ActivityTypeConstructionEdit/>},
];

export default activityTypeConstructionRouter;
