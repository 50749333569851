import {gql, useLazyQuery} from '@apollo/client';
import {useEffect, useState} from 'react';
import {useMessage} from '../../../../../shared/hooks/useMessage';

export let needRefresh = false;
export const setRefresh = (value) => needRefresh = value;

export const reportingGroupingFields = `
    id label description position reporting dateFormat groupingOperation
    columnSource columnSourceObj {id label valueType dateFormat, columnName}
`;

///////////////////// Search /////////////////////
const SEARCH_REPORTING_GROUPINGS_BY_REPORTING = gql`
    query SearchReportingGroupingsByReporting($reporting: Int) {
        searchReportingGroupingsByReporting(reporting: $reporting) {
            error message list { ${reportingGroupingFields} }
        }
    }`;

export const useSearchReportingGroupingsByReporting = ({reporting}) => {
    reporting = reporting && parseInt(reporting);
    const [reportingGroupings, setReportingGroupings] = useState([]);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_REPORTING_GROUPINGS_BY_REPORTING, {
        variables: {reporting},
    });

    useEffect(() => {
        if (_error) {
            showError('Erreur lors de l\'operation');
            setReportingGroupings([]);
            return;
        }

        if (!searchData) return;

        const {error, message, list} = searchData.searchReportingGroupingsByReporting;

        if (error) {
            showError(message);
            setReportingGroupings([]);
        } else {
            setReportingGroupings(list);
        }

        if (needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({variables: {reporting}});
    }, [reporting]);

    return {
        reportingGroupings, loading, search, refetch
    }
}
