import moment from 'moment';

export function formErrorMessageParser({error, isEdit}) {
  return (typeof error === 'string') ? error : !isEdit ? 'Erreur lors de la creation' : 'Erreur lors de la modification';
}

export function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

export function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export function union(a, b) {
  return [...a, ...not(b, a)];
}

export function formatDate(date, separator, locale) {
  if (!date) {
    date = new Date();
  }
  if (separator === undefined) {
    separator = "/";
  }

  if (locale === undefined) {
    locale = false;
  }
  const format = locale
    ? "YYYY" + separator + "MM" + separator + "DD"
    : "DD" + separator + "MM" + separator + "YYYY";
  return moment(date).format(format);
}
