import React from 'react';
// material
import {Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {SicknessTypeForm} from '../components';

import {SicknessTypeHeaderBreadcrumbs} from '../components';
import {useSicknessTypeStore} from "../services/sickness.type.store";

// ----------------------------------------------------------------------

export default function SicknessTypeCreate() {
    const {currentNewSicknessType: currentSicknessType, setCurrentNewSicknessType: setCurrentSicknessType} = useSicknessTypeStore();
    return (
        <Page title="Risque et protection en RE: Création type de maladies liés aux RE">
            <Container  maxWidth={'xl'}>
                <SicknessTypeHeaderBreadcrumbs
                    heading='Création de nouveau type de maladies liés aux RE'
                    lastName='Nouveau type de maladies liés aux RE'
                />
                <SicknessTypeForm {...{currentSicknessType, setCurrentSicknessType}}/>
            </Container>
        </Page>
    );
}
