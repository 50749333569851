import React from 'react';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import {path} from '../../home/sidebar/paths';

//Features
const ORGAN_INSTRUMENT = '/organ-instrument';
const _path = path(ORGAN_INSTRUMENT);
export const PATH_ORGAN_INSTRUMENT = {
    root: ORGAN_INSTRUMENT,
    home: _path('/home'),
    typeOrgans: _path("/type-organs"),
    typeInstruments: _path("/type-instruments"),
    typeSpheres: _path("/type-spheres"),
    entityOrgans: _path("/entity-organs"),
    managementInstruments: _path("/management-instruments"),
    areaCompetenceOrgans: _path("/area-competence-organs"),
    zoneConcerneds: _path("/zone-concerned"),
    stateOccurrences: _path("/state-occurrences"),
    occurrenceCompositions: _path("/occurrence-compositions"),
};


export const organInstrumentMenu = [
    {
        title: 'Organes et instruments de gire',
        path: PATH_ORGAN_INSTRUMENT.root,
        icon: <HomeRepairServiceIcon/>,
        children: [
            {title: 'Accueil', path: PATH_ORGAN_INSTRUMENT.home},
            {title: 'Entités organes', path: PATH_ORGAN_INSTRUMENT.entityOrgans},
            {title: 'Instruments de gestion', path: PATH_ORGAN_INSTRUMENT.managementInstruments},
            {title: 'Domaines compétences des organes', path: PATH_ORGAN_INSTRUMENT.areaCompetenceOrgans},
            {title: 'Zones concernées', path: PATH_ORGAN_INSTRUMENT.zoneConcerneds},
            {title: 'Types des organes', path: PATH_ORGAN_INSTRUMENT.typeOrgans},
            {title: 'Types des instruments', path: PATH_ORGAN_INSTRUMENT.typeInstruments},
            {title: 'Types des sphères d\'actions', path: PATH_ORGAN_INSTRUMENT.typeSpheres},
        ]
    }
];

export const commonItems = [
    {name: "Accueil", href: "/"},
    {name: "Organes et instruments de gire", href: PATH_ORGAN_INSTRUMENT.home},
];

