import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {Form, FormikProvider, useFormik} from 'formik';
import {useMessage} from '../../../../shared/hooks/useMessage';
// material
import {Card, Stack} from '@mui/material';
// routes
import {PATH_ADMINISTRATIVE_DIVISION} from '../../administrative_division.path';
import {
    FormikTextField, SubmitCancelGroupButton
} from '../../../../shared/components/froms/FormComponents';
import {setRefresh} from '../services/locality_type.gql';
import useLocalityTypeService from '../services/locality_type.services';
import {InitialLocalityType, LocalityTypeSchema} from '../services/locality_type.store';
import {formErrorMessageParser} from '../../../../utils/utils';
import {useConnectFormState} from '../../../../shared/hooks/useFormikState';
// ----------------------------------------------------------------------

LocalityTypeForm.propTypes = {
    isEdit: PropTypes.bool,
    currentLocalityType: PropTypes.object,
    fromDialog: PropTypes.object,
};

export default function LocalityTypeForm({isEdit, currentLocalityType, setCurrentLocalityType, fromDialog}) {
    const navigate = useNavigate();
    const {showSuccess, showError} = useMessage();
    const {create, update} = useLocalityTypeService();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: InitialLocalityType(),
        validationSchema: LocalityTypeSchema,
        onSubmit: async (localityType, {setSubmitting}) => {
            try {
                localityType = LocalityTypeSchema.cast(localityType);

                let message;
                if (!isEdit) {
                    localityType = await create(localityType);
                    message = 'Création avec succès';
                } else {
                    localityType = await update(currentLocalityType.id, localityType);
                    message = 'Modification avec succès';
                }

                setRefresh(true);

                setCurrentLocalityType();

                setSubmitting(false);
                showSuccess(message);

                if (fromDialog) {
                    fromDialog.onSave(localityType);
                } else {
                    navigate(`${PATH_ADMINISTRATIVE_DIVISION.localityTypes}/${localityType.id}`);
                }
            } catch (error) {
                setSubmitting(false);
                showError(formErrorMessageParser({error, isEdit}));
            }
        }
    });

    const {values, setValues, handleSubmit, isSubmitting, getFieldProps} = formik;

    useConnectFormState({
        formValues: values, setFormValues: setValues,
        stateValues: currentLocalityType, setStateValues: setCurrentLocalityType,
    });

    const onCancel = (_) => {
        setCurrentLocalityType();
        if (fromDialog) {
            fromDialog.onCancel();
        } else {
            navigate(PATH_ADMINISTRATIVE_DIVISION.localityTypes);
        }
    }

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete='off' onSubmit={handleSubmit}>
                <Card sx={{p: 3}}>
                    <Stack spacing={3}>
                        <FormikTextField label='Libelle' name='name' formik={formik}/>

                        <FormikTextField multiline minRows={2} maxRows={6} label='Description' name='description' formik={formik}/>

                        <SubmitCancelGroupButton {...{isEdit, isSubmitting, onCancel}}/>
                    </Stack>

                </Card>

            </Form>
        </FormikProvider>
    );
}
