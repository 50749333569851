import React from 'react';
// material
import MergeTypeIcon from '@mui/icons-material/MergeType';
// utils
import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {useCountLegislativeTextTypes} from '../services/legislative.text.type.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';

export default function LegislativeTextTypeEntryButton() {
    const {count} = useCountLegislativeTextTypes();
  return (
      <SubModuleEntryButton
          label='Types textes de cadre législatif et reglementaire eau'
          value={count}
          url={PATH_GIRE_ENV_FAVORABLE.legislativeTextTypes}
          icon={<MergeTypeIcon />}
      />
  );
}
