import React from 'react';
// components
import {InstitutionalOrganizationalFrameworkStructureTypeForm} from '../components';
import CreateDialogMenuItem from '../../../../shared/components/CreateDialogMenuItem';
import {useInstitutionalOrganizationalFrameworkStructureTypeStore} from "../services/institutional.organizational.framework.structure.type.store";

export function InstitutionalOrganizationalFrameworkStructureTypeCreateDialogMenuItem({onClose, onSave, btn}) {
    const {currentNewInstitutionalOrganizationalFrameworkStructureType: currentInstitutionalOrganizationalFrameworkStructureType, setCurrentNewInstitutionalOrganizationalFrameworkStructureType: setCurrentInstitutionalOrganizationalFrameworkStructureType} = useInstitutionalOrganizationalFrameworkStructureTypeStore();
    return (
        <CreateDialogMenuItem
            btn={btn}
            onClose={onClose}
            menuText="Ajouter un type de structures de cadre institutionnel et organisationnel eau"
            dialogTitle="Création de type de structures de cadre institutionnel et organisationnel eau"
            dialogContextText={<> {`Création de nouveau type d'instruments de politique et stratégie`} </>}
            formRender={({onClose}) => {
                return (
                    <InstitutionalOrganizationalFrameworkStructureTypeForm
                        {...{currentInstitutionalOrganizationalFrameworkStructureType, setCurrentInstitutionalOrganizationalFrameworkStructureType}}
                        fromDialog={{
                            onCancel: onClose,
                            onSave: (createdInstitutionalOrganizationalFrameworkStructureType) => {
                                onSave && onSave(createdInstitutionalOrganizationalFrameworkStructureType);
                                onClose && onClose();
                            }
                        }}/>
                );
            }}
        />
    );

}
