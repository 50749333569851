import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const OccurrenceCollectionList = Loadable(lazy(() => import('./pages/OccurrenceCollectionList')));
const OccurrenceCollectionCreate = Loadable(lazy(() => import('./pages/OccurrenceCollectionCreate')));
const OccurrenceCollectionEdit = Loadable(lazy(() => import('./pages/OccurrenceCollectionEdit')));
const OccurrenceCollectionDisplay = Loadable(lazy(() => import('./pages/OccurrenceCollectionDisplay')));

const occurrenceCollectionRouter = [
    {path: 'occurrence-collections', element: <OccurrenceCollectionList/>},
    {path: 'occurrence-collections/:id', element: <OccurrenceCollectionDisplay/>},
    {path: 'occurrence-collections/new', element: <OccurrenceCollectionCreate/>},
    {path: 'occurrence-collections/:id/edit', element: <OccurrenceCollectionEdit/>},
];

export default occurrenceCollectionRouter;
