import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const LocalWaterDepartmentList = Loadable(lazy(() => import('./pages/LocalWaterDepartmentList')));
const LocalWaterDepartmentCreate = Loadable(lazy(() => import('./pages/LocalWaterDepartmentCreate')));
const LocalWaterDepartmentEdit = Loadable(lazy(() => import('./pages/LocalWaterDepartmentEdit')));
const LocalWaterDepartmentDisplay = Loadable(lazy(() => import('./pages/LocalWaterDepartmentDisplay')));

const localWaterDepartmentRouter = [
    {path: 'local-water-departments', element: <LocalWaterDepartmentList/>},
    {path: 'local-water-departments/:id', element: <LocalWaterDepartmentDisplay/>},
    {path: 'local-water-departments/new', element: <LocalWaterDepartmentCreate/>},
    {path: 'local-water-departments/:id/edit', element: <LocalWaterDepartmentEdit/>},
];

export default localWaterDepartmentRouter;
