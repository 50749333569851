import * as Yup from 'yup';
import create from 'zustand';


export const HealthAreaSchema = Yup.object().shape({
  name: Yup.string().default('').required('Le nom est obligatoire'),
  waterMass: Yup.string().required('La masse deau est obligatoire'),
  description: Yup.string().default(''),
  dataSource: Yup.string().default(''),
});

const initialHealthArea = HealthAreaSchema.getDefaultFromShape();
export const InitialHealthArea = () => ({...initialHealthArea});

const setCurrentNewHealthArea = set => healthArea => {
  set(state => ({...state, currentNewHealthArea: healthArea ?? InitialHealthArea()}));
}

const setCurrentUpdateHealthArea = set => healthArea => {
  set(state => ({...state, currentUpdateHealthArea: healthArea ?? InitialHealthArea()}));
}

export const useHealthAreaStore = create(set => ({
  //State
  currentNewHealthArea: InitialHealthArea(),
  currentUpdateHealthArea: InitialHealthArea(),
  //Reducers
  setCurrentNewHealthArea: setCurrentNewHealthArea(set),
  setCurrentUpdateHealthArea: setCurrentUpdateHealthArea(set),
}))
