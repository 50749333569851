import React, {forwardRef, useEffect} from 'react';
import {Link as RouterLink} from "react-router-dom";

import {useTheme} from "@mui/material/styles";
import {Refresh, Search} from "@mui/icons-material";
import {
    Box, IconButton, InputAdornment, Link, OutlinedInput, Table,
    TableBody, TableCell, TableContainer, TableRow, Toolbar, Tooltip, Typography
} from "@mui/material";

import {useSearchReporting} from '../services/reporting.gql';
import useReportingService from '../services/reporting.services';
import {useMessage} from '../../../../../shared/hooks/useMessage';
import {usePagination} from '../../../../../shared/components/tables';
import SortableTableHead from "../../../../../shared/components/tables/SortableTableHead";
import MyTablePagination from "../../../../../shared/components/tables/MyTablePagination";
import {PATH_REPORTING} from "../../../reporting.path";
import {ReportingMoreMenu} from './index';

const TABLE_HEAD = [
    {id: 'id', label: 'ID', alignRight: false},
    {id: 'label', label: 'Libelle', alignRight: false},
    {id: 'description', label: 'Description', alignRight: false},
    {id: ''}
];

const ReportingTable = forwardRef(({}, ref) => {

    const theme = useTheme();
    const {showSuccess, showError} = useMessage();
    const {destroy} = useReportingService();

    const {
        page, order, filterName, rowsPerPage, orderBy, handleRequestSort,
        handleChangePage, handleChangeRowsPerPage, handleFilterByName
    } = usePagination();

    const {reporting, refetch, reportingPage} = useSearchReporting({
        query: filterName,
        size: rowsPerPage,
        page
    });

    useEffect(() => {
        if (ref) ref.current = {refetch};
    }, [ref]);

    const handleDelete = (idToDelete) => {
        destroy(idToDelete)
            .then(resp => {
                showSuccess(`Reporting supprime avec succès`);
                refetch();
            })
            .catch(error => {
                showError(`Erreur lors de la suppression du reporting ${error}`);
            });
    }

    return (
        <>
            <Toolbar
                sx={{
                    height: 96,
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: theme.spacing(0, 1, 0, 3),
                }}
            >

                <OutlinedInput
                    sx={{width: 240}}
                    value={filterName}
                    onChange={handleFilterByName}
                    placeholder="Recherche reporting"
                    startAdornment={
                        <InputAdornment position="start">
                            <Box component={Search} sx={{color: 'text.disabled'}}/>
                        </InputAdornment>
                    }
                />

                <Tooltip title='Actualiser'>
                    <IconButton onClick={() => refetch()}>
                        <Refresh/>
                    </IconButton>
                </Tooltip>
            </Toolbar>
            <TableContainer sx={{minWidth: 800}}>
                <Table size="small">
                    <SortableTableHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {reporting && reporting.map((row) => (
                            <Row key={row.id} row={row} onDelete={handleDelete}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <MyTablePagination
                page={reportingPage}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

        </>
    )
});

export default ReportingTable;

function Row({row, onDelete}) {
    const {id, label, description} = row;

    return (
        <TableRow hover sx={{'&:last-child td, &:last-child th': {border: 0}}}>
            <TableCell component='th' scope='row'>
                <Link
                    to={PATH_REPORTING.reporting + '/' + id}
                    component={RouterLink}
                >
                    <Typography variant="body2" noWrap>
                        {id}
                    </Typography>
                </Link>
            </TableCell>
            <TableCell component="th" scope='row'>
                <Link
                    to={PATH_REPORTING.reporting + '/' + id}
                    component={RouterLink}
                >
                    <Typography
                        variant="body2" noWrap>
                        {label}
                    </Typography>
                </Link>
            </TableCell>
            <TableCell component="th" scope='row'>
                <Typography
                    variant="body2" noWrap>
                    {description}
                </Typography>
            </TableCell>
            <TableCell align="right">
                <ReportingMoreMenu id={id} onDelete={onDelete} isIconButton hiddenNew/>
            </TableCell>
        </TableRow>
    );
}
