import * as Yup from 'yup';
import create from 'zustand';

export const LocalityTypeSchema = Yup.object().shape({
    name: Yup.string().default('').required("Le nom est obligatoire"),
    description: Yup.string().default(''),
});

const initialLocalityType = LocalityTypeSchema.getDefaultFromShape();
export const InitialLocalityType = () => ({...initialLocalityType});

const setCurrentNewLocalityType = set => locality => {
    set(state => ({...state, currentNewLocalityType: locality ?? InitialLocalityType()}));
}

const setCurrentUpdateLocalityType = set => locality => {
    set(state => ({...state, currentUpdateLocalityType: locality ?? InitialLocalityType()}));
}

export const useLocalityTypeStore = create(set => ({
    //State
    currentNewLocalityType: InitialLocalityType(),
    currentUpdateLocalityType: InitialLocalityType(),
    //Reducers
    setCurrentNewLocalityType: setCurrentNewLocalityType(set),
    setCurrentUpdateLocalityType: setCurrentUpdateLocalityType(set),
}));
