import * as Yup from "yup";
import create from "zustand";

export const InstrumentPolicySchema = Yup.object().shape({
    name: Yup.string().default("").required("Le libellé est obligatoire"),
    instrumentPolicyType: Yup.number().required("Le type d'instrument de politique et stratégie eau est obligatoire"),
    typeSphere: Yup.number().required("Types de sphère d'actions de GIRE est obligatoire"),
    dataSource: Yup.string(),
    dateMizPlace: Yup.date().required("La date de mise en place est obligatoire"),
    horizonProjection: Yup.string().required("L'horizon projection est obligatoire"),
    structureResp: Yup.string().required("La structure resp est obligatoire"),
    descriptionObjectifs: Yup.string().required("La description objectifs est obligatoire"),
});

const initialInstrumentPolicy =
    InstrumentPolicySchema.getDefaultFromShape();
export const InitialInstrumentPolicy = () => ({
    ...initialInstrumentPolicy,
});

const setCurrentNewInstrumentPolicy =
    (set) => (instrumentPolicy) => {
        set((state) => ({
            ...state,
            currentNewInstrumentPolicy:
                instrumentPolicy ?? InitialInstrumentPolicy(),
        }));
    };

const setCurrentUpdateInstrumentPolicy =
    (set) => (instrumentPolicy) => {
        set((state) => ({
            ...state,
            currentUpdateInstrumentPolicy:
                instrumentPolicy ?? InitialInstrumentPolicy(),
        }));
    };

export const useInstrumentPolicyStore = create((set) => ({
    // State
    currentNewInstrumentPolicy: InitialInstrumentPolicy(),
    currentUpdateInstrumentPolicy:
        InitialInstrumentPolicy(), //Reducers
    setCurrentNewInstrumentPolicy:
        setCurrentNewInstrumentPolicy(set),
    setCurrentUpdateInstrumentPolicy:
        setCurrentUpdateInstrumentPolicy(set),
}));
