import React from 'react';
// material
import MergeTypeIcon from '@mui/icons-material/MergeType';
// utils
import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {useCountInstitutionalOrganizationalFrameworkStructureTypes} from '../services/institutional.organizational.framework.structure.type.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';

export default function InstitutionalOrganizationalFrameworkStructureTypeEntryButton() {
    const {count} = useCountInstitutionalOrganizationalFrameworkStructureTypes();
  return (
      <SubModuleEntryButton
          label='Types de structures de cadre institutionnel et organisationnel eau'
          value={count}
          url={PATH_GIRE_ENV_FAVORABLE.institutionalOrganizationalFrameworkStructureTypes}
          icon={<MergeTypeIcon />}
      />
  );
}
