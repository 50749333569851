import * as Yup from 'yup';
import create from 'zustand';

export const LegislativeTextSchema = Yup.object().shape({
  name: Yup.string().default("").required("Le libellé est obligatoire"),
  legislativeTextType: Yup.number().required("Le type textes de cadre législatif et reglementaire eau est obligatoire"),
  typeSphere: Yup.number().required("Types de sphère d'actions de GIRE est obligatoire"),
  dataSource: Yup.string(),
  dateMizPlace: Yup.date().required("La date de mise en place est obligatoire"),
  structureResp: Yup.string().required("La structure resp est obligatoire"),
  descriptionObjectifs: Yup.string().required("La description objectifs est obligatoire"),
});

const initialLegislativeText = LegislativeTextSchema.getDefaultFromShape();
export const InitialLegislativeText = () => ({...initialLegislativeText});

const setCurrentNewLegislativeText = set => legislativeText => {
  set(state => ({...state, currentNewLegislativeText: legislativeText ?? InitialLegislativeText()}));
}

const setCurrentUpdateLegislativeText = set => legislativeText => {
  set(state => ({...state, currentUpdateLegislativeText: legislativeText ?? InitialLegislativeText()}));
}

export const useLegislativeTextStore = create(set => ({
  //State
  currentNewLegislativeText: InitialLegislativeText(),
  currentUpdateLegislativeText: InitialLegislativeText(),
  //Reducers
  setCurrentNewLegislativeText: setCurrentNewLegislativeText(set),
  setCurrentUpdateLegislativeText: setCurrentUpdateLegislativeText(set),
}))
