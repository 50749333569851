import { useApiHttp } from "../../../../shared/hooks/useHttp";

const useIrrigatedDevelopmentEntityUsagesService = () => {
  const { crud } = useApiHttp();

  const { create, update, destroy, findById, findAll } = crud(
    "irrigated-development-entity-usages"
  );

  return {
    create,
    update,
    destroy,
    findById,
    findAll,
  };
};

export default useIrrigatedDevelopmentEntityUsagesService;
