import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const TypeOrganList = Loadable(lazy(() => import('./pages/TypeOrganList')));
const TypeOrganCreate = Loadable(lazy(() => import('./pages/TypeOrganCreate')));
const TypeOrganEdit = Loadable(lazy(() => import('./pages/TypeOrganEdit')));
const TypeOrganDisplay = Loadable(lazy(() => import('./pages/TypeOrganDisplay')));

const typeOrganRouter = [
    {path: 'type-organs', element: <TypeOrganList/>},
    {path: 'type-organs/:id', element: <TypeOrganDisplay/>},
    {path: 'type-organs/new', element: <TypeOrganCreate/>},
    {path: 'type-organs/:id/edit', element: <TypeOrganEdit/>},
];

export default typeOrganRouter;
