import React from 'react';
// material
import {Typography} from '@mui/material';
// components
import {DataRefYearForm} from '../components';
import CreateDialogMenuItem from '../../../../shared/components/CreateDialogMenuItem';

// ----------------------------------------------------------------------

export function DataRefYearCreateDialogMenuItem({onClose, onSave, onClick, btn}) {
    const setCurrentDataRefYear = () => {};
    const currentDataRefYear = {};
    return (
        <CreateDialogMenuItem
            btn={btn}
            onClose={onClose}
            onClick={onClick}
            menuText='Ajouter une année de reférence des données'
            dialogTitle='Création de année de reférence des données'
            dialogContextText={<>
                Création de nouvelle année de reférence des données
            </>}
            formRender={({onClose}) => {
                return (
                    <DataRefYearForm
                        {...{currentDataRefYear, setCurrentDataRefYear}}
                        fromDialog={{
                            onCancel: onClose,
                            onSave: (createdDataRefYear) => {
                                onSave && onSave(createdDataRefYear);
                                onClose && onClose();
                            },
                        }}
                    />
                );
            }}
        />
    );

}
