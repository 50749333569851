import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const OccurrenceNeedList = Loadable(lazy(() => import('./pages/OccurrenceNeedList')));
const OccurrenceNeedCreate = Loadable(lazy(() => import('./pages/OccurrenceNeedCreate')));
const OccurrenceNeedEdit = Loadable(lazy(() => import('./pages/OccurrenceNeedEdit')));
const OccurrenceNeedDisplay = Loadable(lazy(() => import('./pages/OccurrenceNeedDisplay')));

const occurrenceNeedRouter = [
    {path: 'occurrence-needs', element: <OccurrenceNeedList/>},
    {path: 'occurrence-needs/:id', element: <OccurrenceNeedDisplay/>},
    {path: 'occurrence-needs/new', element: <OccurrenceNeedCreate/>},
    {path: 'occurrence-needs/:id/edit', element: <OccurrenceNeedEdit/>},
];

export default occurrenceNeedRouter;
