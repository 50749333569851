import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {Form, FormikProvider, useFormik} from 'formik';
import {useMessage} from '../../../../shared/hooks/useMessage';
// material
import {LoadingButton} from '@mui/lab';
import {Card, Stack, Box, Button} from '@mui/material';
// routes
import {setRefresh} from '../services/instrument.mechanism.participation.type.gql';
import useInstrumentMechanismParticipationTypeService from '../services/instrument.mechanism.participation.type.services';
import {formErrorMessageParser} from '../../../../utils/utils';
import {FormikTextField} from '../../../../shared/components/froms/FormComponents';
import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {InitialInstrumentMechanismParticipationType, InstrumentMechanismParticipationTypeSchema} from '../services/instrument.mechanism.participation.type.store';
import {useConnectFormState} from '../../../../shared/hooks/useFormikState';

InstrumentMechanismParticipationTypeForm.propTypes = {
    isEdit: PropTypes.bool,
    currentInstrumentMechanismParticipationType: PropTypes.object,
    fromDialog: PropTypes.object,
};

export default function InstrumentMechanismParticipationTypeForm({isEdit, currentInstrumentMechanismParticipationType, setCurrentInstrumentMechanismParticipationType, fromDialog}) {
    const navigate = useNavigate();
    const {create, update} = useInstrumentMechanismParticipationTypeService();
    const {showSuccess, showError} = useMessage();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: InitialInstrumentMechanismParticipationType(),
        validationSchema: InstrumentMechanismParticipationTypeSchema,
        onSubmit: async (instrumentMechanismParticipationType, {setSubmitting}) => {
            try {
                instrumentMechanismParticipationType = InstrumentMechanismParticipationTypeSchema.cast(instrumentMechanismParticipationType);

                let message;
                if (!isEdit) {
                    instrumentMechanismParticipationType = await create(instrumentMechanismParticipationType);
                    message = 'Création avec succès';
                } else {
                    instrumentMechanismParticipationType = await update(currentInstrumentMechanismParticipationType.id, instrumentMechanismParticipationType);
                    message = 'Modification avec succès';
                }

                setRefresh(true);

                setCurrentInstrumentMechanismParticipationType();

                setSubmitting(false);
                showSuccess(message);

                if (fromDialog) {
                    fromDialog.onSave(instrumentMechanismParticipationType);
                } else {
                    navigate(`${PATH_GIRE_ENV_FAVORABLE.instrumentMechanismParticipationTypes}/${instrumentMechanismParticipationType.id}`);
                }
            } catch (error) {
                setSubmitting(false);
                showError(formErrorMessageParser({error, isEdit}));
            }
        }
    });

    const {values, setValues, handleSubmit, isSubmitting} = formik;

    useConnectFormState({
        formValues: values, setFormValues: setValues,
        stateValues: currentInstrumentMechanismParticipationType, setStateValues: setCurrentInstrumentMechanismParticipationType,
    });

    const onCancel = (_) => {
        setCurrentInstrumentMechanismParticipationType();
        if (fromDialog) {
            fromDialog.onCancel();
        } else {
            navigate(PATH_GIRE_ENV_FAVORABLE.instrumentMechanismParticipationTypes);
        }
    }

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete='off' onSubmit={handleSubmit}>

                <Card sx={{p: 3}}>

                    <Stack spacing={3}>

                        <FormikTextField label='Nom' name='name' formik={formik}/>
                        <FormikTextField label='Source de données' name='dataSource' formik={formik}/>
                        <FormikTextField multiline minRows={2} maxRows={6} label='Description' name='description' formik={formik}/>

                        <Box sx={{mt: 3, display: 'flex', justifyContent: 'flex-end'}}>
                            <Button
                                sx={{mr: 2}}
                                size='medium'
                                onClick={onCancel}
                                disabled={isSubmitting}
                            >
                                {'Annuler'}
                            </Button>
                            <LoadingButton type='submit' variant='contained' size='medium' loading={isSubmitting}>
                                {!isEdit ? 'Créer' : 'Modifier'}
                            </LoadingButton>
                        </Box>
                    </Stack>

                </Card>

            </Form>
        </FormikProvider>
    );
}
