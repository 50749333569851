import {gql, useQuery, useLazyQuery} from '@apollo/client';
import {parseCount, parseEntity} from '../../../../utils/gql.utils';
import {useEffect, useState} from 'react';
import {useMessage} from '../../../../shared/hooks/useMessage';

export let needRefresh = false;
export const setRefresh = (value) => needRefresh = value;

export const instrumentPolicyTypeFields = `
    id name description dataSource
`;

///////////////////// Find By ID /////////////////////
const GET_PAYS_BY_ID = gql`
    query InstrumentPolicyTypeById($id: Int!) {
        instrumentPolicyTypeById(id: $id) {
            error message entity { ${instrumentPolicyTypeFields} }
        }
    }
`;

export const useInstrumentPolicyTypeById = (id) => {
    id = parseInt(id);
    return useQuery(GET_PAYS_BY_ID, {
        variables: {id},
        fetchPolicy: 'network-only'
    });
}

export const useInstrumentPolicyType = (id) => {
    const result = useInstrumentPolicyTypeById(id);
    return parseEntity(result, 'instrumentPolicyTypeById');
}

///////////////////// Search /////////////////////
const SEARCH_PAYS = gql`
    query SearchInstrumentPolicyTypes($query: String, $page: Int, $size: Int) {
        searchInstrumentPolicyTypes(query: $query, page: $page, size: $size) {
            error message page {
                content { ${instrumentPolicyTypeFields} }
                page hasNext hasPrevious totalPages totalElements numberOfElements
            }
        }
    }
`;

export const useSearchInstrumentPolicyTypes = ({query = '', page = 0, size = 20}) => {
    const [instrumentPolicyTypes, setInstrumentPolicyTypes] = useState([]);
    const [instrumentPolicyTypePage, setInstrumentPolicyTypePage] = useState(null);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_PAYS, {
        variables: {query, page, size},
    });

    useEffect(() => {
        if(_error) {
            showError('Erreur lors de l\'operation');
            setInstrumentPolicyTypePage(null);
            setInstrumentPolicyTypes([]);
            return;
        }

        if(!searchData) return;

        const {error, message, page} = searchData.searchInstrumentPolicyTypes;

        if (error) {
            showError(message);
            setInstrumentPolicyTypePage(null);
            setInstrumentPolicyTypes([]);
        } else {
            setInstrumentPolicyTypePage(page);
            setInstrumentPolicyTypes(page.content);
        }

        if(needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({ variables: { query, page, size } });
    }, [query, page, size]);

    return {
        instrumentPolicyTypes: instrumentPolicyTypes, loading, search, refetch, instrumentPolicyTypePage
    }
}

///////////////////// Count /////////////////////
const COUNT_PAYS = gql`
    query CountInstrumentPolicyTypes {
        countInstrumentPolicyTypes {
            error message count
        }
    }
`;

export const useCountInstrumentPolicyTypes = () => {
    const result = useQuery(COUNT_PAYS, {
        fetchPolicy: 'network-only'
    });
    return parseCount(result, 'countInstrumentPolicyTypes');
}
