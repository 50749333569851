import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const DewateringTypeList = Loadable(
  lazy(() => import("./pages/DewateringTypeList"))
);
const DewateringTypeCreate = Loadable(
  lazy(() => import("./pages/DewateringTypeCreate"))
);
const DewateringTypeEdit = Loadable(
  lazy(() => import("./pages/DewateringTypeEdit"))
);
const DewateringTypeDisplay = Loadable(
  lazy(() => import("./pages/DewateringTypeDisplay"))
);

const dewateringTypeRouter = [
  { path: "dewatering-types", element: <DewateringTypeList /> },
  { path: "dewatering-types/:id", element: <DewateringTypeDisplay /> },
  { path: "dewatering-types/new", element: <DewateringTypeCreate /> },
  { path: "dewatering-types/:id/edit", element: <DewateringTypeEdit /> },
];

export default dewateringTypeRouter;
