import {gql, useQuery, useLazyQuery} from '@apollo/client';
import {parseCount, parseEntity} from '../../../../utils/gql.utils';
import {useEffect, useState} from 'react';
import {useMessage} from '../../../../shared/hooks/useMessage';

export let needRefresh = false;
export const setRefresh = (value) => needRefresh = value;

export const quantitativeObservationTypeFields = `
    id name description dataSource
`;

///////////////////// Find By ID /////////////////////
const GET_PAYS_BY_ID = gql`
    query QuantitativeObservationTypeById($id: Int!) {
        quantitativeObservationTypeById(id: $id) {
            error message entity { ${quantitativeObservationTypeFields} }
        }
    }
`;

export const useQuantitativeObservationTypeById = (id) => {
    id = parseInt(id);
    return useQuery(GET_PAYS_BY_ID, {
        variables: {id},
        fetchPolicy: 'network-only'
    });
}

export const useQuantitativeObservationType = (id) => {
    const result = useQuantitativeObservationTypeById(id);
    return parseEntity(result, 'quantitativeObservationTypeById');
}

///////////////////// Search /////////////////////
const SEARCH_PAYS = gql`
    query SearchQuantitativeObservationTypes($query: String, $page: Int, $size: Int) {
        searchQuantitativeObservationTypes(query: $query, page: $page, size: $size) {
            error message page {
                content { ${quantitativeObservationTypeFields} }
                page hasNext hasPrevious totalPages totalElements numberOfElements
            }
        }
    }
`;

export const useSearchQuantitativeObservationTypes = ({query = '', page = 0, size = 20}) => {
    const [quantitativeObservationTypes, setQuantitativeObservationTypes] = useState([]);
    const [quantitativeObservationTypePage, setQuantitativeObservationTypePage] = useState(null);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_PAYS, {
        variables: {query, page, size},
    });

    useEffect(() => {
        if(_error) {
            showError('Erreur lors de l\'operation');
            setQuantitativeObservationTypePage(null);
            setQuantitativeObservationTypes([]);
            return;
        }

        if(!searchData) return;

        const {error, message, page} = searchData.searchQuantitativeObservationTypes;

        if (error) {
            showError(message);
            setQuantitativeObservationTypePage(null);
            setQuantitativeObservationTypes([]);
        } else {
            setQuantitativeObservationTypePage(page);
            setQuantitativeObservationTypes(page.content);
        }

        if(needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({ variables: { query, page, size } });
    }, [query, page, size]);

    return {
        quantitativeObservationTypes: quantitativeObservationTypes, loading, search, refetch, quantitativeObservationTypePage
    }
}

///////////////////// Count /////////////////////
const COUNT_PAYS = gql`
    query CountQuantitativeObservationTypes {
        countQuantitativeObservationTypes {
            error message count
        }
    }
`;

export const useCountQuantitativeObservationTypes = () => {
    const result = useQuery(COUNT_PAYS, {
        fetchPolicy: 'network-only'
    });
    return parseCount(result, 'countQuantitativeObservationTypes');
}
