import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {Form, FormikProvider, useFormik} from 'formik';
import {useMessage} from '../../../../shared/hooks/useMessage';
// material
import {LoadingButton} from '@mui/lab';
import {Card, Stack, Box, Button} from '@mui/material';
// routes
import {PATH_ADMINISTRATIVE_DIVISION} from '../../administrative_division.path';
import {
    FormikTextField,
    SubmitCancelGroupButton
} from '../../../../shared/components/froms/FormComponents';
import {setRefresh} from '../services/status.commune.gql';
import useStatusCommuneService from '../services/status.commune.services';
import {InitialStatusCommune, StatusCommuneSchema} from '../services/status.commune.store';
import {formErrorMessageParser} from '../../../../utils/utils';
import {useConnectFormState} from '../../../../shared/hooks/useFormikState';

StatusCommuneForm.propTypes = {
    isEdit: PropTypes.bool,
    currentStatusCommune: PropTypes.object,
    fromDialog: PropTypes.object,
};

export default function StatusCommuneForm({isEdit, currentStatusCommune, setCurrentStatusCommune, fromDialog}) {
    const navigate = useNavigate();
    const {create, update} = useStatusCommuneService();
    const {showSuccess, showError} = useMessage();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: InitialStatusCommune(),
        validationSchema: StatusCommuneSchema,
        onSubmit: async (statusCommune, {setSubmitting}) => {
            try {
                statusCommune = StatusCommuneSchema.cast(statusCommune);
                let message;
                if (!isEdit) {
                    statusCommune = await create(statusCommune);
                    message = 'Création avec succès';
                } else {
                    statusCommune = await update(currentStatusCommune.id, statusCommune);
                    message = 'Modification avec succès';
                }

                setRefresh(true);
                setCurrentStatusCommune();
                setSubmitting(false);

                if (fromDialog) {
                    fromDialog.onSave(statusCommune);
                } else {
                    navigate(`${PATH_ADMINISTRATIVE_DIVISION.statusCommunes}/${statusCommune.id}`);
                }
            } catch (error) {
                setSubmitting(false);
                showError(formErrorMessageParser({error, isEdit}));
            }
        }
    });

    const {values, setValues, handleSubmit, isSubmitting, setFieldValue} = formik;

    useConnectFormState({
        formValues: values, setFormValues: setValues,
        stateValues: currentStatusCommune, setStateValues: setCurrentStatusCommune,
    });

    const onCancel = (_) => {
        setCurrentStatusCommune();
        if (fromDialog) {
            fromDialog.onCancel();
        } else {
            navigate(PATH_ADMINISTRATIVE_DIVISION.statusCommunes);
        }
    }

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete='off' onSubmit={handleSubmit}>

                <Card sx={{p: 3}}>

                    <Stack spacing={3}>

                        <FormikTextField label='Nom' name='name' formik={formik}/>
                        <FormikTextField multiline minRows={2} maxRows={6} label='Description' name='description' formik={formik}/>

                        <SubmitCancelGroupButton {...{isEdit, isSubmitting, onCancel}}/>

                    </Stack>

                </Card>

            </Form>
        </FormikProvider>
    );
}
