import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const OccurrenceAccessRateList = Loadable(lazy(() => import('./pages/OccurrenceAccessRateList')));
const OccurrenceAccessRateCreate = Loadable(lazy(() => import('./pages/OccurrenceAccessRateCreate')));
const OccurrenceAccessRateEdit = Loadable(lazy(() => import('./pages/OccurrenceAccessRateEdit')));
const OccurrenceAccessRateDisplay = Loadable(lazy(() => import('./pages/OccurrenceAccessRateDisplay')));

const occurrenceAccessRateRouter = [
    {path: 'occurrence-access-rates', element: <OccurrenceAccessRateList/>},
    {path: 'occurrence-access-rates/:id', element: <OccurrenceAccessRateDisplay/>},
    {path: 'occurrence-access-rates/new', element: <OccurrenceAccessRateCreate/>},
    {path: 'occurrence-access-rates/:id/edit', element: <OccurrenceAccessRateEdit/>},
];

export default occurrenceAccessRateRouter;
