import React, {lazy} from 'react';
import InstrumentPolicyTypeList from "./pages/InstrumentPolicyTypeList";
import InstrumentPolicyTypeDisplay from "./pages/InstrumentPolicyTypeDisplay";
import InstrumentPolicyTypeCreate from "./pages/InstrumentPolicyTypeCreate";
import InstrumentPolicyTypeEdit from "./pages/InstrumentPolicyTypeEdit";

const instrumentPolicyTypeRouter = [
    {path: 'instrument-policy-types', element: <InstrumentPolicyTypeList/>},
    {path: 'instrument-policy-types/:id', element: <InstrumentPolicyTypeDisplay/>},
    {path: 'instrument-policy-types/new', element: <InstrumentPolicyTypeCreate/>},
    {path: 'instrument-policy-types/:id/edit', element: <InstrumentPolicyTypeEdit/>},

];
export default instrumentPolicyTypeRouter;
