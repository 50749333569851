import React from 'react';
import {path} from '../../home/sidebar/paths';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
//Features
const GIRE_FINANCING = '/gire-financing';
const _path = path(GIRE_FINANCING);
export const PATH_GIRE_FINANCING = {
    root: GIRE_FINANCING,
    home: _path('/home'),
    gire_management_tool: _path('/gire-managment-tools'),
    budget_type: _path('/budget-types'),
    budget_nature: _path('/budget-natures'),
    budget_programs: _path('/budget-programs'),
    gire_organ_entities: _path('/gire-organ-entities'),
    budget_expenditure_area: _path('/budget-expenditure-areas'),
    gire_eha_investment_frameworks: _path('/gire-eha-investment-frameworks'),
    sphere_gire: _path('/sphere-gire'),
    occurrences_of_composition_and_functionality_of_gire_organ_offices: _path('/occurrences-of-composition-and-functionality-of-gire-organ-offices'),
};

export const  gireFinancingMenu = [
    {
        title: 'Gire financement',
        path: PATH_GIRE_FINANCING.root,
        icon: <CurrencyExchangeIcon/>,
        children: [
            { title: 'Accueil', path: PATH_GIRE_FINANCING.home },
            { title: 'Outil GIRE', path: PATH_GIRE_FINANCING.gire_management_tool },
            { title: 'Type de budget', path: PATH_GIRE_FINANCING.budget_type},
            { title: 'Nature de budget', path: PATH_GIRE_FINANCING.budget_nature },
            { title: 'Programme budgétaire', path: PATH_GIRE_FINANCING.budget_programs },
            { title: 'Organes de GIRE', path: PATH_GIRE_FINANCING.gire_organ_entities },
            { title: 'Domaine dépenses budgétaires', path: PATH_GIRE_FINANCING.budget_expenditure_area },
            // { title: 'Cadres d\'investissement', path: PATH_GIRE_FINANCING.gire_eha_investment_frameworks },
            // { title: 'Sphère GIRE', path: PATH_GIRE_FINANCING.sphere_gire },
        ]
    }
];

export const commonItems = [
    {name: 'Accueil', href: '/'},
    {name: 'Gire financement', href: PATH_GIRE_FINANCING.home},
];
