import {createContext, useEffect, useReducer} from 'react';
import PropTypes from 'prop-types';

import Keycloak from 'keycloak-js';
import appConfig from '../configs';

// ----------------------------------------------------------------------

let _kc = null;

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null
};

const handlers = {
    INITIALIZE: (state, action) => {
        const {isAuthenticated, user} = action.payload;
        return {...state, isAuthenticated, isInitialized: true, user};
    },
    LOGIN: (state, action) => {
        const {user} = action.payload;
        return {...state, isAuthenticated: true, user};
    },
    LOGOUT: (state) => ({
        ...state,
        isAuthenticated: false,
        user: null
    })
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
    ...initialState,
    method: 'oauth2',
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    updateToken: (cb) => Promise.resolve(),
});

AuthProvider.propTypes = {
    children: PropTypes.node
};

function AuthProvider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const initialize = async () => {
            try {
                _kc = new Keycloak(appConfig.keycloakConfig);

                await _kc.init({
                    onLoad: 'login-required',
                    // onLoad: 'check-sso',
                    // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
                    // pkceMethod: 'S256',
                });

                const isAuthenticated = _kc.authenticated;

                if (isAuthenticated) {
                    const user = toUser(_kc);

                    dispatch({
                        type: 'INITIALIZE',
                        payload: {isAuthenticated, user}
                    });
                } else {
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {isAuthenticated, user: null}
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: 'INITIALIZE',
                    payload: {isAuthenticated: false, user: null}
                });
            }
        };

        initialize().catch(console.error);
    }, []);

    async function login() {
        await _kc.login();

        const isAuthenticated = _kc.authenticated;

        if (isAuthenticated) {
            const user = toUser(_kc);
            dispatch({type: 'LOGIN', payload: {user}});
        }
    }

    const updateToken = (minValidity) => _kc.updateToken(minValidity);

    const logout = () => {
        _kc.logout();
        dispatch({type: 'LOGOUT'});
    };

    const resetPassword = () => {
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'oauth2',
                login,
                logout,
                updateToken,
                resetPassword
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

const toUser = (_kc) => {
    return {
        getToken: function () {
            return _kc.token;
        },
        getUsername: function () {
            return _kc.tokenParsed?.preferred_username;
        },
        hasRole: function (roles) {
            const _roles = _kc.tokenParsed?.realm_access?.roles ?? [];
            if (typeof roles === 'string') return _roles.includes(roles);
            if (Array.isArray(roles)) return roles.some(role => _roles.includes(role));
            return false;
        },
        getRoles: function () {
            return _kc.tokenParsed?.realm_access?.roles ?? [];
        },
        getName: function () {
            const tokenParsed = _kc.idTokenParsed;
            return tokenParsed?.given_name
                ?? tokenParsed?.preferred_username
                ?? tokenParsed?.email
                ?? tokenParsed?.name;
        },
        getEmail: function () {
            return _kc.idTokenParsed?.email ?? '';
        },
        getFullName: function () {
            return _kc.idTokenParsed?.name ?? '';
        },
        getSubject: function () {
            return _kc.idTokenParsed?.sub ?? '';
        },
    }
}

export {AuthContext, AuthProvider};

