import React from 'react';
// material
import {DonutLarge, DuoRounded, East, Forest} from '@mui/icons-material';
// utils
import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {useCountInstitutionalOrganizationalFrameworkStructures} from '../services/institutional.organizational.framework.structure.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function InstitutionalOrganizationalFrameworkStructureEntryButton() {
    const {count} = useCountInstitutionalOrganizationalFrameworkStructures();
  return (
      <SubModuleEntryButton
          label="Structures de cadre institutionnel et organisationnel"
          value={count}
          url={PATH_GIRE_ENV_FAVORABLE.institutionalOrganizationalFrameworkStructures}
          icon={<Forest />}
      />
  );
}
