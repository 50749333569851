import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const UserList = Loadable(lazy(() => import('./pages/UserList')));
const UserCreate = Loadable(lazy(() => import('./pages/UserCreate')));
const UserEdit = Loadable(lazy(() => import('./pages/UserEdit')));
const UserResetPassword = Loadable(lazy(() => import('./pages/UserResetPassword')));
const UserDisplay = Loadable(lazy(() => import('./pages/UserDisplay')));

const userRouter = [
    {path: 'users', element: <UserList/>},
    {path: 'users/:id', element: <UserDisplay/>},
    {path: 'users/new', element: <UserCreate/>},
    {path: 'users/:id/edit', element: <UserEdit/>},
    {path: 'users/:id/reset-password', element: <UserResetPassword/>},
];

export default userRouter;
