import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const EvaluationOccurrencesList = Loadable(
  lazy(() => import("./pages/EvaluationOccurrencesList"))
);
const EvaluationOccurrencesCreate = Loadable(
  lazy(() => import("./pages/EvaluationOccurrencesCreate"))
);
const EvaluationOccurrencesEdit = Loadable(
  lazy(() => import("./pages/EvaluationOccurrencesEdit"))
);
const EvaluationOccurrencesDisplay = Loadable(
  lazy(() => import("./pages/EvaluationOccurrencesDisplay"))
);

const evaluationOccurrencesRouter = [
  {
    path: "evaluation-occurrences",
    element: <EvaluationOccurrencesList />,
  },
  { 
    path: "evaluation-occurrences/:id",
    element: <EvaluationOccurrencesDisplay />,
  },
  {
    path: "evaluation-occurrences/new",
    element: <EvaluationOccurrencesCreate />,
  },
  {
    path: "evaluation-occurrences/:id/edit",
    element: <EvaluationOccurrencesEdit />,
  },
];

export default evaluationOccurrencesRouter;
