import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Box, Tab, Card, Container, Stack, TextField, CircularProgress} from '@mui/material';
import Page from '../../../../shared/components/Page';
import {SicknessTypeHeaderBreadcrumbs} from '../components';
import {FormRowStack} from '../../../../shared/components/froms/FormComponents';
import {PATH_RISK_PROTECTION} from '../../risk.protection.path';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useSicknessType} from '../services/sickness.type.gql';
import SicknessTypeMoreMenu from '../components/SicknessTypeMoreMenu';
import useSicknessTypeService from "../services/sickness.type.services";
import {useDelete} from "../../../../shared/hooks/useDelete";
// ----------------------------------------------------------------------

export default function SicknessTypeDisplay() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {showError} = useMessage();
    const [tapIndex, setTapIndex] = useState('info');
    const {loading, entity: currentSicknessType, error, errorMessage} = useSicknessType(id);

    const {destroy} = useSicknessTypeService();
    const {onDelete} = useDelete({
        destroyFn: destroy,
        onSuccess: () => navigate(PATH_RISK_PROTECTION.sicknessTypes),
    });

    useEffect(() => {
        if (loading) return;
        if (error) {
            showError(errorMessage);
            navigate(PATH_RISK_PROTECTION.sicknessTypes);
        }
    }, [error, loading, errorMessage]);

    const handleChangeTab = (event, newValue) => setTapIndex(newValue);

    return (
        <Page title={'Risque et protection en RE: Types de maladies liés aux RE'}>
            <Container maxWidth={'xl'}>
                <SicknessTypeHeaderBreadcrumbs
                    heading='Types de maladies liés aux RE'
                    lastName={`${currentSicknessType?.name ?? 'Types de maladies liés aux RE'}`}

                    action={<SicknessTypeMoreMenu id={Number(id)} onDelete={onDelete} hiddenDisplay/>}
                />

                {loading && <CircularProgress/>}
                {!loading && currentSicknessType && (
                    <TabContext value={tapIndex}>
                        <Box sx={{mb: 4, direction: 'row', display: 'flex', justifyContent: 'space-between'}}>
                            <TabList onChange={handleChangeTab}>
                                <Tab disableRipple value='info' label='Information'/>
                                {/*<Tab disableRipple value='regions' label='Regions'/>*/}
                            </TabList>
                        </Box>


                        <Card>
                            <TabPanel value='info'>
                                <Box sx={{p: 0, minHeight: '80%'}}>

                                    <Stack spacing={3}>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label='Nom'
                                                value={currentSicknessType?.name}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label="Source de donnée"
                                                value={currentSicknessType?.dataSource}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                 multiline minRows={2} maxRows={6}  label="Description"
                                                value={currentSicknessType?.description}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>

                                    </Stack>

                                </Box>

                            </TabPanel>

                            {/*<TabPanel value='regions' sx={{p: 0}}>
                                <StatusCommuneTable sicknessType={currentSicknessType.id} currentSicknessType={currentSicknessType}/>
                            </TabPanel>*/}
                        </Card>

                    </TabContext>
                )}
            </Container>
        </Page>
    );
}
