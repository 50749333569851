import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const WaterRightList = Loadable(lazy(() => import('./pages/WaterRightList')));
const WaterRightCreate = Loadable(lazy(() => import('./pages/WaterRightCreate')));
const WaterRightEdit = Loadable(lazy(() => import('./pages/WaterRightEdit')));
const WaterRightDisplay = Loadable(lazy(() => import('./pages/WaterRightDisplay')));

const waterRightRouter = [
    {path: 'water-rights', element: <WaterRightList/>},
    {path: 'water-rights/:id', element: <WaterRightDisplay/>},
    {path: 'water-rights/new', element: <WaterRightCreate/>},
    {path: 'water-rights/:id/edit', element: <WaterRightEdit/>},
];

export default waterRightRouter;
