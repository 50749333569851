import {merge} from 'lodash';
import React, {useEffect, useRef, useState} from 'react';
// material
import {Refresh} from '@mui/icons-material';

import {
    useTheme, useMediaQuery,
    IconButton, Grid, Card, CardHeader, CardContent,
    TableContainer, Typography, Box, Stack,
} from '@mui/material';

// components
import {Scrollbar} from '../../../../../shared/components';
import {BaseOptionChart} from '../../../../../shared/components/charts';
import useDashboardWidgetService from '../services/dashboard.widget.services';
import {WidgetType, WidgetChartType} from "../../dashboard.enum";
import {ReportingResultTable} from "../../../reporting/reporting/pages/ReportingExecutionResult";

import ReactApexChart from 'react-apexcharts';
import {useAutoRefresh} from "../../hooks/useAutoRefresh";

// ----------------------------------------------------------------------

const CARD_HEIGHT = 350;
const CARD_HEADER_HEIGHT = 100;

export default function DashboardWidgetCard({dashboardWidget}) {
    const {execute} = useDashboardWidgetService();

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [dashboardWidgetResult, setDashboardWidgetResult] = useState(null);

    useEffect(() => {
        if (!dashboardWidget) {
            setError(true);
            setErrorMessage(`Aucun widget a afficher`);
            return;
        }
        handleExecute().catch(console.log);
    }, [dashboardWidget]);

    async function handleExecute() {
        try {
            const {id} = dashboardWidget;

            setError(false);
            setLoading(true);

            const {data} = await execute(id);

            setLoading(false);

            setDashboardWidgetResult(data);
        } catch (e) {
            console.log(e);

            setError(true);
            setLoading(false);

            setErrorMessage(typeof e === 'string' ? e : `Erreur lors de l'operation`);
        }
    }

    useAutoRefresh({source: dashboardWidget, handler: handleExecute});

    return (
        <>
            {
                error && (
                    <DashboardWidgetErrorCard
                        title={`Erreur`}
                        onRefresh={handleExecute}
                        message={errorMessage}
                    />
                )
            }
            {
                dashboardWidgetResult && (
                    <DashboardWidgetCardResult
                        onRefresh={handleExecute}
                        dashboardWidgetResult={dashboardWidgetResult}
                    />
                )}
        </>
    );
}

export function DashboardWidgetCardResult({dashboardWidgetResult, onRefresh}) {
    const {status, message} = dashboardWidgetResult;
    const cardDimension = useCardDimension(dashboardWidgetResult);

    if (!status) return <DashboardWidgetErrorCard title="Erreur" message={message}/>;

    const {widget: {type}} = dashboardWidgetResult;
    const enumType = WidgetType.valueOf(type);

    if (enumType?.value) {
        return <DashboardWidgetValueCard {...{onRefresh, dashboardWidgetResult, cardDimension}}/>;
    }
    if (enumType?.chart) {
        return <DashboardWidgetChartCard {...{onRefresh, dashboardWidgetResult, cardDimension}}/>;
    }
    if (enumType?.table) {
        return <DashboardWidgetTableCard {...{onRefresh, dashboardWidgetResult, cardDimension}}/>;
    }

    return (
        <DashboardWidgetErrorCard
            title="Erreur"
            onRefresh={onRefresh}
            message={`Type de widget non pris en charge`}
        />
    );
}

function DashboardWidgetValueCard({dashboardWidgetResult, onRefresh, cardDimension}) {
    const {widget, value, dashboardWidget} = dashboardWidgetResult;
    return (
        <DashboardWidgetGrid {...{dashboardWidgetResult, cardDimension}}>

            <CardHeader
                title={dashboardWidget.widgetTitle}
                subheader={widget.description}
                action={<IconButton onClick={onRefresh}>
                    <Refresh/>
                </IconButton>}
            />

            <CardContent
                sx={{
                    height: cardDimension.currentContentHeight,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography sx={{fontSize: 70, fontWeight: 500}}>
                    {value}
                    <Typography variant="h6" component="span" sx={{color: 'text.disabled'}}>
                        {` ${widget.valueUnit}`}
                    </Typography>
                </Typography>
            </CardContent>

        </DashboardWidgetGrid>
    );
}

function DashboardWidgetTableCard({dashboardWidgetResult, onRefresh, cardDimension}) {
    const {widget, reporting: {columns, lines}, dashboardWidget} = dashboardWidgetResult;

    return (
        <DashboardWidgetGrid {...{dashboardWidgetResult, cardDimension}}>

            <CardHeader
                title={dashboardWidget.widgetTitle}
                subheader={widget.description}
                action={<IconButton onClick={onRefresh}>
                    <Refresh/>
                </IconButton>}
            />

            <CardContent sx={{px: 0}}>
                <Scrollbar>
                    <Stack sx={{height: cardDimension.currentContentHeight - 5}}>
                        <TableContainer>
                            <ReportingResultTable {...{columns, lines}} />
                        </TableContainer>
                    </Stack>
                </Scrollbar>
            </CardContent>

        </DashboardWidgetGrid>
    );
}

function DashboardWidgetChartCard({dashboardWidgetResult, onRefresh, cardDimension}) {
    const {widget: {chartType},} = dashboardWidgetResult;
    const enumChartType = WidgetChartType.valueOf(chartType);

    if (enumChartType?.bar) {
        return <DashboardWidgetChartBarCard {...{onRefresh, dashboardWidgetResult, cardDimension}}/>
    }
    if (enumChartType?.pie) {
        return <DashboardWidgetChartPieCard {...{onRefresh, dashboardWidgetResult, cardDimension}}/>
    }
    if (enumChartType?.line) {
        return <DashboardWidgetChartLineCard {...{onRefresh, dashboardWidgetResult, cardDimension}}/>
    }
    if (enumChartType?.radar) {
        return <DashboardWidgetChartRadarCard {...{onRefresh, dashboardWidgetResult, cardDimension}}/>
    }
    if (enumChartType?.polarArea) {
        return <DashboardWidgetChartPolarAreaCard {...{onRefresh, dashboardWidgetResult, cardDimension}}/>
    }
    if (enumChartType?.percentage) {
        return <DashboardWidgetChartPercentageCard {...{onRefresh, dashboardWidgetResult, cardDimension}}/>
    }

    return (
        <DashboardWidgetErrorCard
            title="Graphique"
            onRefresh={onRefresh}
            message={`Type de Graphique non pris en charge`}
        />
    );
}

function DashboardWidgetChartBarCard({dashboardWidgetResult, onRefresh, cardDimension}) {
    const {widget, reporting, dashboardWidget} = dashboardWidgetResult;

    const {ko, message, categories, dataSeries} = extractData(reporting);

    if (ko) return <DashboardWidgetErrorCard title="Graphique" message={message}/>;

    const chartOptions = merge(BaseOptionChart(), {
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {categories},
        tooltip: {
            y: {
                formatter: (val) => `${val}`
            }
        }
    });

    return (
        <DashboardWidgetGrid {...{dashboardWidgetResult, cardDimension}}>

            <CardHeader
                title={dashboardWidget.widgetTitle}
                subheader={widget.description}
                action={<IconButton onClick={onRefresh}>
                    <Refresh/>
                </IconButton>}
            />

            <ReactApexChart type="bar" series={dataSeries} options={chartOptions} height={cardDimension.currentContentHeight}/>

        </DashboardWidgetGrid>
    );
}

function DashboardWidgetChartPieCard({dashboardWidgetResult, onRefresh, cardDimension}) {
    const {widget, reporting, dashboardWidget} = dashboardWidgetResult;

    const {ko, message, dataSeries: _dataSeries} = extractData(reporting);

    if (ko) return <DashboardWidgetErrorCard title="Graphique" message={message}/>;

    const labels = _dataSeries.map(({name}) => name);
    const dataSeries = _dataSeries.map(({data: [_data]}) => parseFloat(_data));

    const chartOptions = merge(BaseOptionChart(), {
        labels,
        stroke: {colors: [cardDimension.theme.palette.background.paper]},
        legend: {floating: true, horizontalAlign: 'center', position: 'right',},
        dataLabels: {enabled: true, dropShadow: {enabled: false}},
        tooltip: {
            fillSeriesColor: false,
            y: {
                formatter: (seriesName) => `${seriesName}`,
                title: {
                    formatter: (seriesName) => `${seriesName}`
                }
            }
        },
        plotOptions: {
            pie: {donut: {labels: {show: false}}}
        }
    });

    return (
        <DashboardWidgetGrid {...{dashboardWidgetResult, cardDimension}}>

            <CardHeader
                title={dashboardWidget.widgetTitle}
                subheader={widget.description}
                action={<IconButton onClick={onRefresh}>
                    <Refresh/>
                </IconButton>}
            />

            <ReactApexChart type="pie" series={dataSeries} options={chartOptions} height={cardDimension.currentContentHeight}/>

        </DashboardWidgetGrid>
    );
}

function DashboardWidgetChartLineCard({dashboardWidgetResult, onRefresh, cardDimension}) {
    const {widget, reporting, dashboardWidget} = dashboardWidgetResult;

    const {ko, message, categories, dataSeries} = extractData(reporting);

    if (ko) return <DashboardWidgetErrorCard title="Graphique" message={message}/>;

    const chartOptions = merge(BaseOptionChart(), {
        xaxis: {categories}
    });

    return (
        <DashboardWidgetGrid {...{dashboardWidgetResult, cardDimension}}>
            <CardHeader
                title={dashboardWidget.widgetTitle}
                subheader={widget.description}
                action={<IconButton onClick={onRefresh}>
                    <Refresh/>
                </IconButton>}
            />

            <ReactApexChart type="line" series={dataSeries} options={chartOptions} height={cardDimension.currentContentHeight}/>

        </DashboardWidgetGrid>
    );
}

function DashboardWidgetChartRadarCard({dashboardWidgetResult, onRefresh, cardDimension}) {
    const {widget, reporting, dashboardWidget} = dashboardWidgetResult;

    const {ko, message, categories, dataSeries} = extractData(reporting);

    if (ko) return <DashboardWidgetErrorCard title="Graphique" message={message}/>;

    const chartOptions = merge(BaseOptionChart(), {
        xaxis: {categories},
        stroke: {width: 2},
        fill: {opacity: 0.48},
        legend: {floating: true, horizontalAlign: 'center', position: 'right',},
    });

    return (
        <DashboardWidgetGrid {...{dashboardWidgetResult, cardDimension}}>

            <CardHeader
                title={dashboardWidget.widgetTitle}
                subheader={widget.description}
                action={<IconButton onClick={onRefresh}>
                    <Refresh/>
                </IconButton>}
            />

            <ReactApexChart type="radar" series={dataSeries} options={chartOptions} height={cardDimension.currentContentHeight}/>

        </DashboardWidgetGrid>
    );
}

function DashboardWidgetChartPolarAreaCard({dashboardWidgetResult, onRefresh, cardDimension}) {
    const {widget, reporting, dashboardWidget} = dashboardWidgetResult;

    const {ko, message, dataSeries: _dataSeries} = extractData(reporting);

    if (ko) return <DashboardWidgetErrorCard title="Graphique" message={message}/>;

    const labels = _dataSeries.map(({name}) => name);
    const dataSeries = _dataSeries.map(({data: [_data]}) => parseFloat(_data));

    const chartOptions = merge(BaseOptionChart(), {
        labels,
        stroke: {
            colors: [cardDimension.theme.palette.background.paper]
        },
        fill: {opacity: 0.8},
        legend: {
            position: 'right',
            itemMargin: {
                horizontal: 10,
                vertical: 5
            }
        },
        responsive: [
            {
                breakpoint: cardDimension.theme.breakpoints.values.sm,
                options: {
                    legend: {
                        position: 'bottom',
                        horizontalAlign: 'left'
                    }
                }
            }
        ],
    });


    return (
        <DashboardWidgetGrid {...{dashboardWidgetResult, cardDimension}}>

            <CardHeader
                title={dashboardWidget.widgetTitle}
                subheader={widget.description}
                action={<IconButton onClick={onRefresh}>
                    <Refresh/>
                </IconButton>}
            />

            <ReactApexChart type="polarArea" series={dataSeries} options={chartOptions} height={cardDimension.currentContentHeight}/>
        </DashboardWidgetGrid>
    );
}

function DashboardWidgetChartPercentageCard({dashboardWidgetResult, onRefresh, cardDimension}) {
    const {widget, reporting, dashboardWidget} = dashboardWidgetResult;

    const {ko, message, dataSeries: _dataSeries} = extractData(reporting);

    if (ko) return <DashboardWidgetErrorCard title="Graphique" message={message}/>;

    const labels = _dataSeries.map(({name}) => name);
    const dataSeries = _dataSeries.map(({data: [_data]}) => parseFloat(_data));

    const chartOptions = merge(BaseOptionChart(), {
        chart: {sparkline: {enabled: true}},
        grid: {
            padding: {
                top: -9,
                bottom: -9
            }
        },
        legend: {show: false, position: 'right',},
        plotOptions: {
            radialBar: {
                hollow: {size: '64%'},
                track: {margin: 0},
                dataLabels: {
                    name: {show: false},
                    value: {
                        offsetY: 6,
                        fontSize: cardDimension.theme.typography.subtitle2.fontSize
                    }
                }
            }
        }
    });

    return (
        <DashboardWidgetGrid {...{dashboardWidgetResult, cardDimension}}>
            <CardHeader
                title={dashboardWidget.widgetTitle}
                subheader={widget.description}
                action={<IconButton onClick={onRefresh}>
                    <Refresh/>
                </IconButton>}
            />

            <ReactApexChart type='radialBar' series={dataSeries} options={chartOptions} height={cardDimension.currentContentHeight}/>
        </DashboardWidgetGrid>
    );
}

export function DashboardWidgetGrid({dashboardWidgetResult, cardDimension, children}) {
    const {width, height} = cardDimension;

    return (
        <Grid item {...{...width}}>
            <Card sx={{height}}> {children} </Card>
        </Grid>
    )
}

export function DashboardWidgetErrorCard({title, message, onRefresh}) {
    return (
        <Grid item xs={12} md={6}>
            <Card sx={{height: CARD_HEIGHT}}>
                <CardHeader
                    title={title}
                    action={<IconButton onClick={onRefresh}>
                        <Refresh/>
                    </IconButton>}
                />
                <Typography>
                    {message}
                </Typography>
            </Card>
        </Grid>
    );
}

function useCardDimension(dashboardWidgetResult) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const {dashboardWidget} = dashboardWidgetResult ?? {};
    const {gridHxs, gridHmd, gridHlg, gridVxs, gridVmd, gridVlg} = dashboardWidget ?? {};

    const width = {xs: gridHxs ?? 12, md: gridHmd ?? 6, lg: gridHlg ?? 6};

    const height = {xs: gridVxs ?? CARD_HEIGHT, md: gridVmd ?? CARD_HEIGHT, lg: gridVlg ?? CARD_HEIGHT};
    const currentHeight = isMobile ? height.xs : isTablet ? height.md : height.lg;
    const currentContentHeight = Math.max(currentHeight - CARD_HEADER_HEIGHT, 10);

    return {width, height, theme, isMobile, isTablet, isDesktop, currentHeight, currentContentHeight};
}

function extractData(reporting) {
    const {columns, lines} = reporting;
    if (!columns || columns.length === 0) return {
        ko: true,
        message: `Aucune donnees (Categories) a afficher`
    };

    const [categoryCol, ...dataCols] = columns;

    if (!dataCols || dataCols.length === 0) return {
        ko: true,
        message: `Aucune donnees (Series) a afficher`
    };

    const categories = [];
    const dataSeries = dataCols.map(({label}) => ({name: label, data: []}));

    lines.forEach(line => {
        categories.push(line[categoryCol.columnName]);
        dataCols.map(({columnName}, index) => {
            dataSeries[index].data.push(line[columnName]);
        });
    });

    return {
        categories,
        dataSeries
    }
}

