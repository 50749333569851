import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';

import MainLayout from '../../home/AppLayout';
import Loadable from '../../shared/components/Loadable';

import {PATH_SANITATION_ACCESS} from './sanitation.access.path';
import occurrenceAccessRateRouter from "./occurrence_access_rate/occurrence.access.rate.router";
import occurenceEmopResultRouter from "./occurence_emop_result/occurence.emop.result.router";




const SanitationAccessHome = Loadable(lazy(() => import('./SanitationAccessHome')));

const sanitationAccessRouter = [{
    path: PATH_SANITATION_ACCESS.root,
    element: <MainLayout/>,
    children: [
        {path: PATH_SANITATION_ACCESS.root, element: <Navigate to={PATH_SANITATION_ACCESS.home} replace/>},
        {path: 'home', element: <SanitationAccessHome/>},
        ...occurrenceAccessRateRouter,
        ...occurenceEmopResultRouter,
    ]
},];

export default sanitationAccessRouter;
