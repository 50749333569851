import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const HierarchyList = Loadable(lazy(() => import("./pages/HierarchyList")));
const HierarchyCreate = Loadable(lazy(() => import("./pages/HierarchyCreate")));
const HierarchyEdit = Loadable(lazy(() => import("./pages/HierarchyEdit")));
const HierarchyDisplay = Loadable(
  lazy(() => import("./pages/HierarchyDisplay"))
);

const hierarchyRouter = [
  { path: "hierarchies", element: <HierarchyList /> },
  { path: "hierarchies/:id", element: <HierarchyDisplay /> },
  { path: "hierarchies/new", element: <HierarchyCreate /> },
  { path: "hierarchies/:id/edit", element: <HierarchyEdit /> },
];

export default hierarchyRouter;
