import React, {useEffect, useRef, useState} from 'react';
import {useParams, Link as RouterLink, useNavigate} from 'react-router-dom';

// material
import {Grid, CircularProgress, Typography, Box,} from '@mui/material';

// components
import Page from '../../../../../shared/components/Page';
import {DashboardHeaderBreadcrumbs} from '../components';
import {PATH_REPORTING} from '../../../reporting.path';
import {useDashboard} from '../services/dashboard.gql';
import {useMessage} from '../../../../../shared/hooks/useMessage';

import {DashboardWidgetCard} from '../../dashboard_widget/components';
import {useSearchDashboardWidgetsByDashboard} from '../../dashboard_widget/services/dashboard.widget.gql';
import {useMyDashboards} from "../../dashboard_profile/services/dashboard.profile.gql";
import _ from "lodash";

// ----------------------------------------------------------------------

export default function DashboardComponent({currentDashboard, showTitle = true}) {

    const {
        dashboardWidgets, loading,
    } = useSearchDashboardWidgetsByDashboard({dashboard: currentDashboard.id});

    return (
        <>
            {loading && (
                <Box sx={{
                    height: '60vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <CircularProgress/>
                </Box>
            )}
            {!loading && currentDashboard && <>
                {showTitle && <>
                    <Typography variant='h2'>
                        {currentDashboard.title}
                    </Typography>

                    <Typography sx={{
                        fontSize: 22, fontWeight: 300
                    }}>
                        {currentDashboard.description}
                    </Typography>
                </>}

                <Grid container spacing={3} sx={{pt: 2}}>
                    {dashboardWidgets?.map((dashboardWidget, index) => {
                        return <DashboardWidgetCard key={index} dashboardWidget={dashboardWidget}/>
                    })}
                </Grid>
            </>}

        </>
    );
}
