import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const OccurenceEmopResultList = Loadable(lazy(() => import('./pages/OccurenceEmopResultList')));
const OccurenceEmopResultCreate = Loadable(lazy(() => import('./pages/OccurenceEmopResultCreate')));
const OccurenceEmopResultEdit = Loadable(lazy(() => import('./pages/OccurenceEmopResultEdit')));
const OccurenceEmopResultDisplay = Loadable(lazy(() => import('./pages/OccurenceEmopResultDisplay')));

const occurenceEmopResultRouter = [
    {path: 'occurence-emop-results', element: <OccurenceEmopResultList/>},
    {path: 'occurence-emop-results/:id', element: <OccurenceEmopResultDisplay/>},
    {path: 'occurence-emop-results/new', element: <OccurenceEmopResultCreate/>},
    {path: 'occurence-emop-results/:id/edit', element: <OccurenceEmopResultEdit/>},
];

export default occurenceEmopResultRouter;
