import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const WaterMassList = Loadable(lazy(() => import('./pages/WaterMassList')));
const WaterMassCreate = Loadable(lazy(() => import('./pages/WaterMassCreate')));
const WaterMassEdit = Loadable(lazy(() => import('./pages/WaterMassEdit')));
const WaterMassDisplay = Loadable(lazy(() => import('./pages/WaterMassDisplay')));


const waterMassRouter = [
    {path: 'water-masses', element: <WaterMassList/>},
    {path: 'water-masses/:id', element: <WaterMassDisplay/>},
    {path: 'water-masses/new', element: <WaterMassCreate/>},
    {path: 'water-masses/:id/edit', element: <WaterMassEdit/>},
];

export default waterMassRouter;
