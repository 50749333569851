import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const AreaUseWaterList = Loadable(lazy(() => import('./pages/AreaUseWaterList')));
const AreaUseWaterCreate = Loadable(lazy(() => import('./pages/AreaUseWaterCreate')));
const AreaUseWaterEdit = Loadable(lazy(() => import('./pages/AreaUseWaterEdit')));
const AreaUseWaterDisplay = Loadable(lazy(() => import('./pages/AreaUseWaterDisplay')));

const areaUseWaterRouter = [
    {path: 'area-use-waters', element: <AreaUseWaterList/>},
    {path: 'area-use-waters/:id', element: <AreaUseWaterDisplay/>},
    {path: 'area-use-waters/new', element: <AreaUseWaterCreate/>},
    {path: 'area-use-waters/:id/edit', element: <AreaUseWaterEdit/>},
];


export default areaUseWaterRouter;
