import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const CommunicationMethodCategoryList = Loadable(
  lazy(() => import("./pages/CommunicationMethodCategoryList"))
);
const CommunicationMethodCategoryCreate = Loadable(
  lazy(() => import("./pages/CommunicationMethodCategoryCreate"))
);
const CommunicationMethodCategoryEdit = Loadable(
  lazy(() => import("./pages/CommunicationMethodCategoryEdit"))
);
const CommunicationMethodCategoryDisplay = Loadable(
  lazy(() => import("./pages/CommunicationMethodCategoryDisplay"))
);

const communicationMethodCategoryRouter = [
  {
    path: "communication-method-categories",
    element: <CommunicationMethodCategoryList />,
  },
  {
    path: "communication-method-categories/:id",
    element: <CommunicationMethodCategoryDisplay />,
  },
  {
    path: "communication-method-categories/new",
    element: <CommunicationMethodCategoryCreate />,
  },
  {
    path: "communication-method-categories/:id/edit",
    element: <CommunicationMethodCategoryEdit />,
  },
];

export default communicationMethodCategoryRouter;
