import React from 'react';
// components
import {InstrumentPolicyTypeForm} from '../components';
import CreateDialogMenuItem from '../../../../shared/components/CreateDialogMenuItem';
import {useInstrumentPolicyTypeStore} from "../services/instrument.policy.type.store";

export function InstrumentPolicyTypeCreateDialogMenuItem({onClose, onSave, btn}) {

    const {
        currentNewInstrumentPolicyType: currentInstrumentPolicyType,
        setCurrentNewInstrumentPolicyType: setCurrentInstrumentPolicyType}
        = useInstrumentPolicyTypeStore();











    return (
        <CreateDialogMenuItem
            btn={btn}
            onClose={onClose}
            menuText="Ajouter un Type d'instruments de politique et stratégie"
            dialogTitle="Création de type d'instruments de politique et stratégie"
            dialogContextText={<> {`Création de nouveau type d'instruments de politique et stratégie`} </>}
            formRender={({onClose}) => {
                return (
                    <InstrumentPolicyTypeForm
                        {...{currentInstrumentPolicyType, setCurrentInstrumentPolicyType}}
                        fromDialog={{
                            onCancel: onClose,
                            onSave: (createdInstrumentPolicyType) => {
                                onSave && onSave(createdInstrumentPolicyType);
                                onClose && onClose();
                            }
                        }}/>
                );
            }}
        />
    );

}
