import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';
import MainLayout from '../../home/AppLayout';
import Loadable from '../../shared/components/Loadable';
import {PATH_WATER_USAGE_URIGATION} from './water.usage.irrigation.path';
import irrigatedDevelopmentEntitiesRouter from "./irrigated_development_entity_usages/irrigated.development.entity.usages.router";
import oculturalSpeculationEntitiesRouter from "./cultural_speculation_entity_usages/cultural.speculation.entity.usages.router";
import occurrenceAgriculturalProductionRouter from "./occurrence_agricultural_production/occurrence.agricultural.production.router";
import occurenceWaterRequestRouter from "./occurrence_water_request/occurrence.water.request.router";

const DegradationProtectionHome = Loadable(lazy(() => import('./WaterUsageIrrigationHome')));

const waterUsageIrrigationRouter = [{
    path: PATH_WATER_USAGE_URIGATION.root,
    element: <MainLayout/>,
    children: [
        {path: PATH_WATER_USAGE_URIGATION.root, element: <Navigate to={PATH_WATER_USAGE_URIGATION.home} replace/>},
        {path: 'home', element: <DegradationProtectionHome/>},
        ...irrigatedDevelopmentEntitiesRouter,
        ...oculturalSpeculationEntitiesRouter,
        ...occurrenceAgriculturalProductionRouter,
        ...occurenceWaterRequestRouter
    ]
},];

export default waterUsageIrrigationRouter;
