import React from 'react';
// material
import {Typography} from '@mui/material';
// components
import {RegionForm} from '../components';
import CreateDialogMenuItem from '../../../../shared/components/CreateDialogMenuItem';

export function RegionCreateDialogMenuItem({pays, onClose, onSave, onClick, btn}) {
    const setCurrentRegion = () => {};
    const currentRegion = {pays: pays?.id, paysObj: pays};
    return (
        <CreateDialogMenuItem
            btn={btn}
            onClose={onClose}
            onClick={onClick}
            menuText='Ajouter une region'
            dialogTitle='Création de region'
            dialogContextText={<>
                Création de nouvelle region
                {pays && (
                    <>
                        {` pour`} <Typography variant='h6' component={'span'}> {pays.name}</Typography>
                    </>
                )}
            </>}
            formRender={({onClose}) => {
                return (
                    <RegionForm
                        {...{currentRegion, setCurrentRegion}}
                        fromDialog={{
                            pays,
                            onCancel: onClose,
                            onSave: (createdRegion) => {
                                console.log('ON SAVE EXECUTED createdRegion ==> ', createdRegion);
                                onSave && onSave(createdRegion);
                                onClose && onClose();
                            },
                        }}
                    />
                );
            }}
        />
    );

}
