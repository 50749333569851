import React from 'react';
// material
import {Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {RiskManagementToolForm} from '../components';

import {RiskManagementToolHeaderBreadcrumbs} from '../components';
import {useRiskManagementToolStore} from "../services/risk.management.tool.store";

// ----------------------------------------------------------------------

export default function RiskManagementToolCreate() {
    const {currentNewRiskManagementTool: currentRiskManagementTool, setCurrentNewRiskManagementTool: setCurrentRiskManagementTool} = useRiskManagementToolStore();
    return (
        <Page title="Risque et protection en RE: Création d'outils de gestion de risques liés à l'eau">
            <Container  maxWidth={'xl'}>
                <RiskManagementToolHeaderBreadcrumbs
                    heading="Création de nouvel outils de gestion de risques liés à l'eau"
                    lastName="Nouvel outils de gestion de risques liés à l'eau"
                />
                <RiskManagementToolForm {...{currentRiskManagementTool, setCurrentRiskManagementTool}}/>
            </Container>
        </Page>
    );
}
