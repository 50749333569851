import React from 'react';
import {Link as RouterLink} from "react-router-dom";

import {useTheme} from "@mui/material/styles";
import {Refresh, Search} from "@mui/icons-material";
import {
    Box, IconButton, InputAdornment, Link, OutlinedInput, Table,
    TableBody, TableCell, TableContainer, TableRow, Toolbar, Tooltip, Typography
} from "@mui/material";

import {useSearchInstrumentMechanismParticipationTypes} from '../services/instrument.mechanism.participation.type.gql';
import useInstrumentMechanismParticipationTypeService from '../services/instrument.mechanism.participation.type.services';
import {usePagination} from '../../../../shared/components/tables';
import SortableTableHead from "../../../../shared/components/tables/SortableTableHead";
import MyTablePagination from "../../../../shared/components/tables/MyTablePagination";
import {InstrumentMechanismParticipationTypeMoreMenu} from "./index";
import {useDelete} from "../../../../shared/hooks/useDelete";
import {PATH_GIRE_ENV_FAVORABLE} from "../../gire.env.favorable.path";

const TABLE_HEAD = [
    {id: 'id', label: 'ID', alignRight: false},
    {id: 'name', label: 'Nom', alignRight: false},
    {id: 'dataSource', label: 'Source de données', alignRight: false},
    {id: 'description', label: 'Description', alignRight: false},
    {id: ''}
];

export default function InstrumentMechanismParticipationTypeTable() {
    const theme = useTheme();
    const {
        page, order, filterName, rowsPerPage, orderBy, handleRequestSort,
        handleChangePage, handleChangeRowsPerPage, handleFilterByName
    } = usePagination();

    const {instrumentMechanismParticipationTypes, refetch, instrumentMechanismParticipationTypePage} = useSearchInstrumentMechanismParticipationTypes({
        query: filterName,
        size: rowsPerPage,
        page
    });

    const {destroy} = useInstrumentMechanismParticipationTypeService();
    const {onDelete} = useDelete({
        destroyFn: destroy, onSuccess: refetch
    });

    return (
        <>
            <Toolbar
                sx={{
                    height: 96,
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: theme.spacing(0, 1, 0, 3),
                }}
            >

                <OutlinedInput
                    sx={{width: 240}}
                    value={filterName}
                    onChange={handleFilterByName}
                    placeholder="Recherche type d'instruments de politique et stratégie"
                    startAdornment={
                        <InputAdornment position="start">
                            <Box component={Search} sx={{color: 'text.disabled'}}/>
                        </InputAdornment>
                    }
                />

                <Tooltip title='Actualiser'>
                    <IconButton onClick={() => refetch()}>
                        <Refresh/>
                    </IconButton>
                </Tooltip>
            </Toolbar>
            <TableContainer sx={{minWidth: 800}}>
                <Table size="small">
                    <SortableTableHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {instrumentMechanismParticipationTypes.map((row) => (<Row key={row.id} {...{row, onDelete}} />))}
                    </TableBody>
                </Table>
            </TableContainer>

            <MyTablePagination
                page={instrumentMechanismParticipationTypePage}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}

function Row({row, onDelete}) {
    const {id, name, description, dataSource} = row;

    return (
        <TableRow hover sx={{'&:last-child td, &:last-child th': {border: 0}}}>
            <TableCell component='th' scope='row'>
                <Link
                    to={PATH_GIRE_ENV_FAVORABLE.instrumentMechanismParticipationTypes + '/' + id}
                    component={RouterLink}
                >
                    <Typography variant='body2' noWrap>
                        {id}
                    </Typography>
                </Link>
            </TableCell>
            <TableCell component='th' scope='row'>
                <Link
                    to={PATH_GIRE_ENV_FAVORABLE.instrumentMechanismParticipationTypes + '/' + id}
                    component={RouterLink}
                >
                    <Typography
                        variant='body2' noWrap>
                        {name}
                    </Typography>
                </Link>
            </TableCell>
            <TableCell style={{minWidth: 160}}>
                <Typography variant='body2' noWrap> {description} </Typography>
            </TableCell>

            <TableCell style={{minWidth: 160}}>
                <Typography variant='body2' noWrap> {dataSource} </Typography>
            </TableCell>
            <TableCell align='right'>
                <InstrumentMechanismParticipationTypeMoreMenu {...{id, onDelete}} isIconButton hiddenNew/>
            </TableCell>
        </TableRow>
    );
}


