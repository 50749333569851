import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const ProductionOfInformationSupportsList = Loadable(lazy(() => import("./pages/ProductionOfInformationSupportsList")));
const ProductionOfInformationSupportsCreate = Loadable(lazy(() => import("./pages/ProductionOfInformationSupportsCreate")));
const ProductionOfInformationSupportsEdit = Loadable(lazy(() => import("./pages/ProductionOfInformationSupportsEdit")));
const ProductionOfInformationSupportsDisplay = Loadable(lazy(() => import("./pages/ProductionOfInformationSupportsDisplay")));

const productionOfInformationSupportsRouter = [
  { path: "production-of-information-supports", element: <ProductionOfInformationSupportsList /> },
  { path: "production-of-information-supports/:id", element: <ProductionOfInformationSupportsDisplay /> },
  { path: "production-of-information-supports/new", element: <ProductionOfInformationSupportsCreate /> },
  { path: "production-of-information-supports/:id/edit", element: <ProductionOfInformationSupportsEdit /> },
];

export default productionOfInformationSupportsRouter;
