import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const RejectList = Loadable(lazy(() => import("./pages/RejectList")));
const RejectCreate = Loadable(lazy(() => import("./pages/RejectCreate")));
const RejectEdit = Loadable(lazy(() => import("./pages/RejectEdit")));
const RejectDisplay = Loadable(lazy(() => import("./pages/RejectDisplay")));

const rejectRouter = [
  { path: "rejects", element: <RejectList /> },
  { path: "rejects/:id", element: <RejectDisplay /> },
  { path: "rejects/new", element: <RejectCreate /> },
  { path: "rejects/:id/edit", element: <RejectEdit /> },
];

export default rejectRouter;
