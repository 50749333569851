import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const AmenagementProtectionList = Loadable(lazy(() => import("./pages/AmenagementProtectionList")));
const AmenagementProtectionCreate = Loadable(lazy(() => import("./pages/AmenagementProtectionCreate")));
const AmenagementProtectionEdit = Loadable(lazy(() => import("./pages/AmenagementProtectionEdit")));
const AmenagementProtectionDisplay = Loadable(lazy(() => import("./pages/AmenagementProtectionDisplay")));

const amenagementProtectionRouter = [
  { path: "amenagement-protections", element: <AmenagementProtectionList /> },
  { path: "amenagement-protections/:id", element: <AmenagementProtectionDisplay /> },
  { path: "amenagement-protections/new", element: <AmenagementProtectionCreate /> },
  { path: "amenagement-protections/:id/edit", element: <AmenagementProtectionEdit /> },
];

export default amenagementProtectionRouter;
