import React from 'react';
// components
import {LocalWaterDepartmentForm} from '../components';
import CreateDialogMenuItem from '../../../../shared/components/CreateDialogMenuItem';
import {useLocalWaterDepartmentStore} from "../services/local.water.department.store";

// ----------------------------------------------------------------------

export function LocalWaterDepartmentCreateDialogMenuItem({onClose, onSave, btn}) {
    const {
        currentNewLocalWaterDepartment: currentLocalWaterDepartment,
        setCurrentNewLocalWaterDepartment: setCurrentLocalWaterDepartment
    } = useLocalWaterDepartmentStore();
    return (
        <CreateDialogMenuItem
            btn={btn}
            onClose={onClose}
            menuText='Ajouter un service local eau'
            dialogTitle='Création de service local eau'
            dialogContextText={<> {`Création de nouveau service local eau`} </>}
            formRender={({onClose}) => {
                return (
                    <LocalWaterDepartmentForm
                        {...{currentLocalWaterDepartment, setCurrentLocalWaterDepartment}}
                        fromDialog={{
                            onCancel: onClose,
                            onSave: (createdLocalWaterDepartment) => {
                                onSave && onSave(createdLocalWaterDepartment);
                                onClose && onClose();
                            }
                        }}/>
                );
            }}
        />
    );

}
