import React from 'react';
import {PATH_FISHING, commonItems} from '../../module.fishing.path';
import HeaderBreadcrumbs from "../../../../shared/components/HeaderBreadcrumbs";

export function AhpTypeHeaderBreadcrumbs ({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[
            ...commonItems,
            {name: 'Types AHP', href: PATH_FISHING.ahpType},
            {name: lastName},
        ]}
    />);
}

export function AhpTypeListHeaderBreadcrumbs ({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[...commonItems, {name: lastName}]}
    />);
}
