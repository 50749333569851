import React from 'react';
// material
import {Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {AhaTypeForm} from '../components';

import {AhaTypeHeaderBreadcrumbs} from '../components';
import {useAhaTypeStore} from "../services/aha.type.store";

// ----------------------------------------------------------------------

export default function AhaTypeCreate() {
    const {currentNewAhaType: currentAhaType, setCurrentNewAhaType: setCurrentAhaType} = useAhaTypeStore();
    return (
        <Page title="Risque et protection en RE: Création type aménagement hydro-agricole">
            <Container  maxWidth={'xl'}>
                <AhaTypeHeaderBreadcrumbs
                    heading='Création de nouveau type aménagement hydro-agricole'
                    lastName='Nouveau type aménagement hydro-agricole'
                />
                <AhaTypeForm {...{currentAhaType, setCurrentAhaType}}/>
            </Container>
        </Page>
    );
}
