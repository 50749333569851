import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const OccurrenceObservationList = Loadable(lazy(() => import('./pages/OccurrenceObservationList')));
const OccurrenceObservationCreate = Loadable(lazy(() => import('./pages/OccurrenceObservationCreate')));
const OccurrenceObservationEdit = Loadable(lazy(() => import('./pages/OccurrenceObservationEdit')));
const OccurrenceObservationDisplay = Loadable(lazy(() => import('./pages/OccurrenceObservationDisplay')));

const occurrenceObservationRouter = [
    {path: 'occurrence-observations', element: <OccurrenceObservationList/>},
    {path: 'occurrence-observations/:id', element: <OccurrenceObservationDisplay/>},
    {path: 'occurrence-observations/new', element: <OccurrenceObservationCreate/>},
    {path: 'occurrence-observations/:id/edit', element: <OccurrenceObservationEdit/>},
];

export default occurrenceObservationRouter;
