import React from 'react';
// material
import {Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {RiskManagementToolApplicationDegreeForm} from '../components';

import {RiskManagementToolApplicationDegreeHeaderBreadcrumbs} from '../components';
import {useRiskManagementToolApplicationDegreeStore} from "../services/risk.management.tool.application.degree.store";

// ----------------------------------------------------------------------

export default function RiskManagementToolApplicationDegreeCreate() {
    const {currentNewRiskManagementToolApplicationDegree: currentRiskManagementToolApplicationDegree, setCurrentNewRiskManagementToolApplicationDegree: setCurrentRiskManagementToolApplicationDegree} = useRiskManagementToolApplicationDegreeStore();
    return (
        <Page title="Risque et protection en RE: Création degré d'application des outils de gestion de risques liés à l'eau">
            <Container  maxWidth={'xl'}>
                <RiskManagementToolApplicationDegreeHeaderBreadcrumbs
                    heading="Création de nouveau degré d'application des outils de gestion de risques liés à l'eau"
                    lastName="Nouveau degré d'application des outils de gestion de risques liés à l'eau"
                />
                <RiskManagementToolApplicationDegreeForm {...{currentRiskManagementToolApplicationDegree, setCurrentRiskManagementToolApplicationDegree}}/>
            </Container>
        </Page>
    );
}
