import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';

import MainLayout from '../../home/AppLayout';
import Loadable from '../../shared/components/Loadable';

import {PATH_RISK_PROTECTION} from './risk.protection.path';
import riskTypeRouter from "./risk_type/risk.type.router";
import sicknessTypeRouter from "./sickness_type/sickness.type.router";
import amenagementTypeRouter from "./amenagement_type/amenagement.type.router";
import riskManagementToolTypeRouter from "./risk_management_tool_type/risk.management.tool.type.router";
import riskManagementToolApplicationDegreeRouter from "./risk_management_tool_application_degree/risk.management.tool.application.degree.router";
import healthAreaRouter from "./health_areas/healthAreaRouter";
import riskManagementToolRouter from "./risk_management_tool/risk.management.tool.router";
import reliefActionRouter from "./relief_action/reliefActionRouter";
import eventLossOccurrenceRouter from "./event_loss_occurrence/eventLossOccurrenceRouter";
import amenagementProtectionRouter from "./amenagement_protection/amenagementProtectionRouter";
import riskManagementToolsImplementationOccurrenceRouter
    from "./occurrence_risk_management_tools_implementation/riskManagementToolsImplementationOccurrenceRouter";

const RiskProtectionHome = Loadable(lazy(() => import('./RiskProtectionHome')));

const riskProtectionRouter = [{
    path: PATH_RISK_PROTECTION.root,
    element: <MainLayout/>,
    children: [
        {path: PATH_RISK_PROTECTION.root, element: <Navigate to={PATH_RISK_PROTECTION.home} replace/>},
        {path: 'home', element: <RiskProtectionHome/>},
        ...riskTypeRouter,
        ...sicknessTypeRouter,
        ...amenagementTypeRouter,
        ...riskManagementToolTypeRouter,
        ...riskManagementToolApplicationDegreeRouter,
        ...healthAreaRouter,
        ...riskManagementToolRouter,
        ...reliefActionRouter,
        ...eventLossOccurrenceRouter,
        ...riskManagementToolsImplementationOccurrenceRouter,
        ...amenagementProtectionRouter
    ]
},];

export default riskProtectionRouter;
