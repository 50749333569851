import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const OperateurList = Loadable(lazy(() => import("./pages/OperateurList")));
const OperateurCreate = Loadable(lazy(() => import("./pages/OperateurCreate")));
const OperateurEdit = Loadable(lazy(() => import("./pages/OperateurEdit")));
const OperateurDisplay = Loadable(
  lazy(() => import("./pages/OperateurDisplay"))
);

const operateurRouter = [
  { path: "operateurs", element: <OperateurList /> },
  { path: "operateurs/:id", element: <OperateurDisplay /> },
  { path: "operateurs/new", element: <OperateurCreate /> },
  { path: "operateurs/:id/edit", element: <OperateurEdit /> },
];

export default operateurRouter;
