import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const WaterQualityObservationOccurrencesList = Loadable(
  lazy(() => import("./pages/WaterQualityObservationOccurrencesList"))
);
const WaterQualityObservationOccurrencesCreate = Loadable(
  lazy(() => import("./pages/WaterQualityObservationOccurrencesCreate"))
);
const WaterQualityObservationOccurrencesEdit = Loadable(
  lazy(() => import("./pages/WaterQualityObservationOccurrencesEdit"))
);
const WaterQualityObservationOccurrencesDisplay = Loadable(
  lazy(() => import("./pages/WaterQualityObservationOccurrencesDisplay"))
);

const waterQualityObservationOccurrencesRouter = [
  {
    path: "water-quality-observation-occurrences",
    element: <WaterQualityObservationOccurrencesList />,
  },
  {
    path: "water-quality-observation-occurrences/:id",
    element: <WaterQualityObservationOccurrencesDisplay />,
  },
  {
    path: "water-quality-observation-occurrences/new",
    element: <WaterQualityObservationOccurrencesCreate />,
  },
  {
    path: "water-quality-observation-occurrences/:id/edit",
    element: <WaterQualityObservationOccurrencesEdit />,
  },
];

export default waterQualityObservationOccurrencesRouter;
