import {useApiHttp} from '../../../../shared/hooks/useHttp';


const useRegionService = () => {
    const {crud} = useApiHttp();

    const {
        create,
        update,
        destroy,
        findById,
        findAll,
    } = crud('regions');


    return {
        create,
        update,
        destroy,
        findById,
        findAll,
    }
}

export default useRegionService;
