import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const CommuneList = Loadable(lazy(() => import('./pages/CommuneList')));
const CommuneCreate = Loadable(lazy(() => import('./pages/CommuneCreate')));
const CommuneEdit = Loadable(lazy(() => import('./pages/CommuneEdit')));
const CommuneDisplay = Loadable(lazy(() => import('./pages/CommuneDisplay')));

const communeRouter = [
    {path: 'communes', element: <CommuneList/>},
    {path: 'communes/:id', element: <CommuneDisplay/>},
    {path: 'communes/new', element: <CommuneCreate/>},
    {path: 'communes/:id/edit', element: <CommuneEdit/>},
];

export default communeRouter;
