import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const MeaningfulAreasOfDrinkingWaterQualityControlPointsList = Loadable(lazy(() => import("./pages/MeaningfulAreasOfDrinkingWaterQualityControlPointsList")));
const MeaningfulAreasOfDrinkingWaterQualityControlPointsCreate = Loadable(
  lazy(() => import("./pages/MeaningfulAreasOfDrinkingWaterQualityControlPointsCreate"))
);
const MeaningfulAreasOfDrinkingWaterQualityControlPointsEdit = Loadable(lazy(() => import("./pages/MeaningfulAreasOfDrinkingWaterQualityControlPointsEdit")));
const MeaningfulAreasOfDrinkingWaterQualityControlPointsDisplay = Loadable(
  lazy(() => import("./pages/MeaningfulAreasOfDrinkingWaterQualityControlPointsDisplay"))
);

const meaningfulAreasOfDrinkingWaterQualityControlPointsRouter = [
  { path: "meaningful-areas-of-drinking-water-quality-control-points", element: <MeaningfulAreasOfDrinkingWaterQualityControlPointsList /> },
  { path: "meaningful-areas-of-drinking-water-quality-control-points/:id", element: <MeaningfulAreasOfDrinkingWaterQualityControlPointsDisplay /> },
  { path: "meaningful-areas-of-drinking-water-quality-control-points/new", element: <MeaningfulAreasOfDrinkingWaterQualityControlPointsCreate /> },
  { path: "meaningful-areas-of-drinking-water-quality-control-points/:id/edit", element: <MeaningfulAreasOfDrinkingWaterQualityControlPointsEdit /> },
];

export default meaningfulAreasOfDrinkingWaterQualityControlPointsRouter;
