import React from 'react';
// material
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
// utils
import {PATH_WATER_USE} from '../../water.use.path';
import {useCountLocalWaterDepartments} from '../services/local.water.department.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';

// ----------------------------------------------------------------------
export default function LocalWaterDepartmentEntryButton() {
    const {count} = useCountLocalWaterDepartments();
    return (
        <SubModuleEntryButton
            label='Services local eau'
            value={count}
            url={PATH_WATER_USE.localWaterDepartments}
            icon={<HomeRepairServiceIcon/>}
        />
    );
}
