import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Add} from '@mui/icons-material';
import { Card, Button, Container } from '@mui/material';
import {PATH_POTENTIAL_WATER_AVAILABILITY} from '../../potential.water.availability.path';
import {Page} from '../../../../shared/components';
import {QuantitativeObservationTypeTable, QuantitativeObservationTypeListHeaderBreadcrumbs} from '../components';

export default function QuantitativeObservationTypeList() {
    return (
        <Page title="Types d'observation quantitative: Liste des types d'observation quantitative des RE">
            <Container maxWidth={'xl'}>
                <QuantitativeObservationTypeListHeaderBreadcrumbs
                    heading="Types d'observation quantitative"
                    lastName="Liste des type d'observation quantitative"
                    action={
                        <Button
                            variant="contained"
                            component={RouterLink}
                            to={PATH_POTENTIAL_WATER_AVAILABILITY.quantitativeObservationTypes + '/new'}
                            startIcon={<Add/>}>
                            Nouveau
                        </Button>
                    }
                />

                <Card>
                    <QuantitativeObservationTypeTable/>
                </Card>
            </Container>
        </Page>
    );
}
