import React from 'react';
// material
import {Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {InstrumentMechanismParticipationTypeForm} from '../components';

import {InstrumentMechanismParticipationTypeHeaderBreadcrumbs} from '../components';
import {useInstrumentMechanismParticipationTypeStore} from "../services/instrument.mechanism.participation.type.store";

// ----------------------------------------------------------------------

export default function InstrumentMechanismParticipationTypeCreate() {
    const {currentNewInstrumentMechanismParticipationType: currentInstrumentMechanismParticipationType, setCurrentNewInstrumentMechanismParticipationType: setCurrentInstrumentMechanismParticipationType} = useInstrumentMechanismParticipationTypeStore();
    return (
        <Page title="Gire environment favorable: Création type d'instruments et mécanismes de participation à la GIRE">
            <Container  maxWidth={'xl'}>
                <InstrumentMechanismParticipationTypeHeaderBreadcrumbs
                    heading="Création de nouveau type d'instruments et mécanismes de participation à la GIRE"
                    lastName="Nouveau type d'instruments et mécanismes de participation à la GIRE"
                />
                <InstrumentMechanismParticipationTypeForm {...{currentInstrumentMechanismParticipationType, setCurrentInstrumentMechanismParticipationType}}/>
            </Container>
        </Page>
    );
}
