import React from 'react';
import WebhookIcon from '@mui/icons-material/Webhook';
import {path} from '../../home/sidebar/paths';
const WATER_USAGE_URIGATION = '/water-usage-urrigation';
const _path = path(WATER_USAGE_URIGATION);
export const PATH_WATER_USAGE_URIGATION = {
    root: WATER_USAGE_URIGATION,
    home: _path('/home'),
    culturalSpeculationEntityUsages: _path("/cultural-speculation-entity-usages"),
    irrigatedDevelopmentEntityUsages: _path("/irrigated-development-entity-usages"),
    occurrenceAgriculturalProductions: _path("/occurrence-agricultural-productions"),
    occurrenceWaterRequests: _path("/occurrence-water-requests")
};

export const waterUsageIrrigationMenu = [
    {
        title: 'Irrigation Usage Eau',
        path: PATH_WATER_USAGE_URIGATION.root,
        icon: <WebhookIcon/>,
        children: [
            {title: 'Accueil', path: PATH_WATER_USAGE_URIGATION.home},
            {title: 'Entités de spéculations culturales', path: PATH_WATER_USAGE_URIGATION.culturalSpeculationEntityUsages},
            {title: 'Entités d\'Aménagement irrigués', path: PATH_WATER_USAGE_URIGATION.irrigatedDevelopmentEntityUsages},
            // {title: 'Occurrences de productions agricoles', path: PATH_WATER_USAGE_URIGATION.occurrenceAgriculturalProductions},
            // {title: 'Occurrences de la demande en eau', path: PATH_WATER_USAGE_URIGATION.occurrenceWaterRequests},
        ]
    }
];

export const commonItems = [
    {name: "Accueil", href: "/"},
    {name: "Irrigation Usage Eau", href: PATH_WATER_USAGE_URIGATION.home},
];
