import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useNavigate} from 'react-router-dom';
import {Form, FormikProvider, useFormik} from 'formik';
import {Box, Card, Stack} from '@mui/material';
import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {
    FormikAutocomplete, FormikDatePicker,
    FormikTextField,
    FormRowStack,
    SubmitCancelGroupButton
} from '../../../../shared/components/froms/FormComponents';
import {setRefresh} from '../services/institutional.organizational.framework.structure.gql';
import useInstitutionalOrganizationalFrameworkStructureService from '../services/institutional.organizational.framework.structure.services';
import {useConnectFormState} from "../../../../shared/hooks/useFormikState";
import {InstitutionalOrganizationalFrameworkStructureSchema, InitialInstitutionalOrganizationalFrameworkStructure} from "../services/institutional.organizational.framework.structure.store";
import {formErrorMessageParser} from "../../../../utils/utils";
import {useSearchInstitutionalOrganizationalFrameworkStructureTypes} from "../../institutional_organizational_framework_structure_type/services/institutional.organizational.framework.structure.type.gql";
import {InstitutionalOrganizationalFrameworkStructureTypeCreateDialogMenuItem} from "../../institutional_organizational_framework_structure_type/components";
import {useSearchTypeSpheres} from "../../../organ_instrument/type_sphere/services/type.sphere.gql";
import {TypeSphereCreateDialogMenuItem} from "../../../organ_instrument/type_sphere/components";

InstitutionalOrganizationalFrameworkStructureForm.propTypes = {
    isEdit: PropTypes.bool,
    currentInstitutionalOrganizationalFrameworkStructure: PropTypes.object,
    fromDialog: PropTypes.object,
};
export default function InstitutionalOrganizationalFrameworkStructureForm({isEdit,
                                                 currentInstitutionalOrganizationalFrameworkStructure,
                                                 setCurrentInstitutionalOrganizationalFrameworkStructure,
                                                 fromDialog
                                             }) {
    const navigate = useNavigate();
    const {create, update} = useInstitutionalOrganizationalFrameworkStructureService();
    const {showSuccess, showError} = useMessage();

    const initTypeSphereQuery = currentInstitutionalOrganizationalFrameworkStructure?.typeSphereObj?.name ?? fromDialog?.typeSphere?.name ?? '';
    const [typeSphereQuery, setTypeSphereQuery] = useState(initTypeSphereQuery);
    const {typeSpheres, typeSphereLoading} = useSearchTypeSpheres({typeSphereQuery});


    const initInstitutionalOrganizationalFrameworkStructureTypeQuery = currentInstitutionalOrganizationalFrameworkStructure?.institutionalOrganizationalFrameworkStructureTypeObj?.name ?? fromDialog?.institutionalOrganizationalFrameworkStructureType?.name ?? '';
    const [institutionalOrganizationalFrameworkStructureTypeQuery, setInstitutionalOrganizationalFrameworkStructureTypeQuery] = useState(initInstitutionalOrganizationalFrameworkStructureTypeQuery);
    const {
        institutionalOrganizationalFrameworkStructureTypes,
        institutionalOrganizationalFrameworkStructureLoading
    } = useSearchInstitutionalOrganizationalFrameworkStructureTypes({institutionalOrganizationalFrameworkStructureTypeQuery});

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: InstitutionalOrganizationalFrameworkStructureSchema,
        initialValues: InitialInstitutionalOrganizationalFrameworkStructure(),
        onSubmit: async (institutionalOrganizationalFrameworkStructure, {setSubmitting}) => {
            try {
                institutionalOrganizationalFrameworkStructure = InstitutionalOrganizationalFrameworkStructureSchema.cast(institutionalOrganizationalFrameworkStructure);

                let message;
                if (!isEdit) {
                    institutionalOrganizationalFrameworkStructure = await create(institutionalOrganizationalFrameworkStructure);
                    message = 'Création avec succès';
                } else {
                    institutionalOrganizationalFrameworkStructure = await update(currentInstitutionalOrganizationalFrameworkStructure.id, institutionalOrganizationalFrameworkStructure);
                    message = 'Modification avec succès';
                }

                setRefresh(true);
                setCurrentInstitutionalOrganizationalFrameworkStructure();

                setSubmitting(false);
                showSuccess(message);

                if (fromDialog) {
                    fromDialog.onSave(institutionalOrganizationalFrameworkStructure);
                } else {
                    navigate(`${PATH_GIRE_ENV_FAVORABLE.institutionalOrganizationalFrameworkStructures}/${institutionalOrganizationalFrameworkStructure.id}`);
                }
            } catch (error) {
                setSubmitting(false);
                showError(formErrorMessageParser({error, isEdit}));
            }
        }
    });

    const {values, setValues, handleSubmit, isSubmitting, setFieldValue} = formik;

    useConnectFormState({
        formValues: values, setFormValues: setValues,
        stateValues: currentInstitutionalOrganizationalFrameworkStructure, setStateValues: setCurrentInstitutionalOrganizationalFrameworkStructure,
    });

    const onCancel = (_) => {
        setCurrentInstitutionalOrganizationalFrameworkStructure();
        if (fromDialog) {
            fromDialog.onCancel();
        } else {
            navigate(PATH_GIRE_ENV_FAVORABLE.institutionalOrganizationalFrameworkStructures);
        }
    };

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>

                <Card sx={{p: 3}}>

                    <Stack spacing={3}>

                        <FormRowStack>
                            <FormikTextField label='Nom' name='name' formik={formik}/>
                            <FormikTextField label='Description objectifs' name='descriptionObjectifs' formik={formik}/>
                        </FormRowStack>

                        <FormRowStack>
                            <Box flexGrow={1} display='flex' flexDirection='row'>
                                <FormikAutocomplete name='typeSphere'
                                                    label="Types de sphère d'actions de GIRE"
                                                    options={typeSpheres}
                                                    labelField='name'
                                                    embeddedObjField='typeSphereObj'
                                                    {...{
                                                        typeSphereQuery,
                                                        formik,
                                                        typeSphereLoading,
                                                        setTypeSphereQuery
                                                    }}
                                />
                                <Box sx={{py: 1, ml: 1}}>
                                    <TypeSphereCreateDialogMenuItem btn onSave={(createdTypeSphere) => {
                                        currentInstitutionalOrganizationalFrameworkStructure.typeSphereObj = createdTypeSphere;
                                        setTypeSphereQuery(createdTypeSphere?.name ?? '');
                                        setFieldValue('typeSphere', createdTypeSphere?.id);
                                    }}/>
                                </Box>
                            </Box>
                            <Box flexGrow={1} display='flex' flexDirection='row'>
                                <FormikAutocomplete name='institutionalOrganizationalFrameworkStructureType'
                                                    label="Types de structures de cadre institutionnel et organisationnel eau"
                                                    options={institutionalOrganizationalFrameworkStructureTypes}
                                                    labelField='name'
                                                    embeddedObjField='institutionalOrganizationalFrameworkStructureTypeObj'
                                                    {...{
                                                        institutionalOrganizationalFrameworkStructureTypeQuery,
                                                        formik,
                                                        institutionalOrganizationalFrameworkStructureLoading,
                                                        setInstitutionalOrganizationalFrameworkStructureTypeQuery
                                                    }}
                                />
                                <Box sx={{py: 1, ml: 1}}>
                                    <InstitutionalOrganizationalFrameworkStructureTypeCreateDialogMenuItem btn
                                                                              onSave={(createdInstitutionalOrganizationalFrameworkStructureType) => {
                                                                                  currentInstitutionalOrganizationalFrameworkStructure.institutionalOrganizationalFrameworkStructureTypeObj = createdInstitutionalOrganizationalFrameworkStructureType;
                                                                                  setInstitutionalOrganizationalFrameworkStructureTypeQuery(createdInstitutionalOrganizationalFrameworkStructureType?.name ?? '');
                                                                                  setFieldValue('institutionalOrganizationalFrameworkStructureType', createdInstitutionalOrganizationalFrameworkStructureType?.id);
                                                                              }}/>
                                </Box>
                            </Box>
                        </FormRowStack>

                        <FormRowStack>
                            <FormikDatePicker label='Date de mise en place' name='dateMizPlace'/>
                            <FormikTextField label='Structure Resp' name='structureResp' formik={formik}/>
                        </FormRowStack>

                        <FormRowStack>
                            <FormikTextField label='Source des données' name='dataSource' formik={formik}/>
                        </FormRowStack>

                        <SubmitCancelGroupButton {...{isEdit, isSubmitting, onCancel}}/>
                    </Stack>

                </Card>

            </Form>
        </FormikProvider>
    );
}
