import React from 'react';
// material
import MergeTypeIcon from '@mui/icons-material/MergeType';
// utils
import {PATH_FISHING} from '../../module.fishing.path';
import {useCountAhpTypes} from '../services/ahp.type.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';

export default function AhpTypeEntryButton() {
    const {count} = useCountAhpTypes();
  return (
      <SubModuleEntryButton
          label="Type d'entités d'aménagement HP"
          value={count}
          url={PATH_FISHING.ahpType}
          icon={<MergeTypeIcon />}
      />
  );
}
