import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';

// ----------------------------------------------------------------------

const useAuth = () => useContext(AuthContext);

export default useAuth;


export const useRoleChecker = () => {
    const {user: {hasRole}} = useAuth();

    const hasNotRole = (roles) => !hasRole(roles);

    return {hasRole, hasNotRole};
}
