import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const VariationOccurrenceList = Loadable(lazy(() => import('./pages/VariationOccurrenceList')));
const VariationOccurrenceCreate = Loadable(lazy(() => import('./pages/VariationOccurrenceCreate')));
const VariationOccurrenceEdit = Loadable(lazy(() => import('./pages/VariationOccurrenceEdit')));
const VariationOccurrenceDisplay = Loadable(lazy(() => import('./pages/VariationOccurrenceDisplay')));

const variationOccurrenceRouter = [
    {path: 'variation-occurrences', element: <VariationOccurrenceList/>},
    {path: 'variation-occurrences/:id', element: <VariationOccurrenceDisplay/>},
    {path: 'variation-occurrences/new', element: <VariationOccurrenceCreate/>},
    {path: 'variation-occurrences/:id/edit', element: <VariationOccurrenceEdit/>},
];

export default variationOccurrenceRouter;
