import React, {lazy} from 'react';
import InstrumentMechanismParticipationTypeList from "./pages/InstrumentMechanismParticipationTypeList";
import InstrumentMechanismParticipationTypeDisplay from "./pages/InstrumentMechanismParticipationTypeDisplay";
import InstrumentMechanismParticipationTypeCreate from "./pages/InstrumentMechanismParticipationTypeCreate";
import InstrumentMechanismParticipationTypeEdit from "./pages/InstrumentMechanismParticipationTypeEdit";

const instrumentMechanismParticipationTypeRouter = [
    {path: 'instrument-mechanism-participation-types', element: <InstrumentMechanismParticipationTypeList/>},
    {path: 'instrument-mechanism-participation-types/:id', element: <InstrumentMechanismParticipationTypeDisplay/>},
    {path: 'instrument-mechanism-participation-types/new', element: <InstrumentMechanismParticipationTypeCreate/>},
    {path: 'instrument-mechanism-participation-types/:id/edit', element: <InstrumentMechanismParticipationTypeEdit/>},

];
export default instrumentMechanismParticipationTypeRouter;
