import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const DrillingWaterComingList = Loadable(
  lazy(() => import("./pages/DrillingWaterComingList"))
);
const DrillingWaterComingCreate = Loadable(
  lazy(() => import("./pages/DrillingWaterComingCreate"))
);
const DrillingWaterComingEdit = Loadable(
  lazy(() => import("./pages/DrillingWaterComingEdit"))
);
const DrillingWaterComingDisplay = Loadable(
  lazy(() => import("./pages/DrillingWaterComingDisplay"))
);

const drillingWaterComingRouter = [
  { path: "drilling-water-comings", element: <DrillingWaterComingList /> },
  {
    path: "drilling-water-comings/:id",
    element: <DrillingWaterComingDisplay />,
  },
  {
    path: "drilling-water-comings/new",
    element: <DrillingWaterComingCreate />,
  },
  {
    path: "drilling-water-comings/:id/edit",
    element: <DrillingWaterComingEdit />,
  },
];

export default drillingWaterComingRouter;
