import React from 'react';
// material
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
// utils
import {PATH_ADMINISTRATIVE_DIVISION} from '../../administrative_division.path';
import {useCountCircles} from '../services/circle.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function CircleEntryButton() {
    const {count} = useCountCircles();
    return (
        <SubModuleEntryButton
            label='Cercles'
            value={count}
            url={PATH_ADMINISTRATIVE_DIVISION.circles}
            icon={<FlagCircleIcon/>}
        />
    );
}
