import React from 'react';
// material
import {Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {InstrumentMechanismParticipationOccurrenceForm} from '../components';

import {InstrumentMechanismParticipationOccurrenceHeaderBreadcrumbs} from '../components';
import {useInstrumentMechanismParticipationOccurrenceStore} from "../services/instrument.mechanism.participation.occurrence.store";

// ----------------------------------------------------------------------

export default function InstrumentMechanismParticipationOccurrenceCreate() {
    const {currentNewInstrumentMechanismParticipationOccurrence: currentInstrumentMechanismParticipationOccurrence, setCurrentNewInstrumentMechanismParticipationOccurrence: setCurrentInstrumentMechanismParticipationOccurrence} = useInstrumentMechanismParticipationOccurrenceStore();
    return (
        <Page title="Gire environment favorable: Creation occurrences d'état de mise en œuvre des instruments et mécanismes de participation à la GIRE">
            <Container  maxWidth={'xl'}>
                <InstrumentMechanismParticipationOccurrenceHeaderBreadcrumbs
                    heading="Creation de nouvelle occurrences d'état de mise en œuvre des instruments et mécanismes de participation à la GIRE"
                    lastName="Nouvelle occurrence d'état de mise en œuvre des instruments et mécanismes de participation à la GIRE"
                />
                <InstrumentMechanismParticipationOccurrenceForm {...{currentInstrumentMechanismParticipationOccurrence, setCurrentInstrumentMechanismParticipationOccurrence}}/>
            </Container>
        </Page>
    );
}
