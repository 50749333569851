import {useApiHttp} from '../../../../shared/hooks/useHttp';

const useInstrumentMechanismParticipationOccurrenceService = () => {
    const {crud} = useApiHttp();

    const {
        create,
        update,
        destroy,
        findById,
        findAll,
    } = crud('instrument-mechanism-participation-occurrences');

    return {
        create,
        update,
        destroy,
        findById,
        findAll
    }
}

export default useInstrumentMechanismParticipationOccurrenceService;
