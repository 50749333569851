import React from 'react';
// material
import DataObjectIcon from '@mui/icons-material/DataObject';
// utils
import {PATH_ADMINISTRATIVE_DIVISION} from '../../administrative_division.path';
import {useCountDataRefYears} from '../services/data.ref.year.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';

// ----------------------------------------------------------------------

export default function DataRefYearEntryButton() {
    const {count} = useCountDataRefYears();
    return (
        <SubModuleEntryButton
            label='Années de reférence des données'
            value={count}
            url={PATH_ADMINISTRATIVE_DIVISION.dataRefYears}
            icon={<DataObjectIcon/>}
        />
    );
}
