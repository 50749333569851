import * as Yup from 'yup';
import create from 'zustand';


export const RiskManagementToolTypeSchema = Yup.object().shape({
    name: Yup.string().default('').required('Le nom est obligatoire'),
    description: Yup.string().default(''),
    dataSource: Yup.string().default(''),
});

const initialRiskManagementToolType = RiskManagementToolTypeSchema.getDefaultFromShape();
export const InitialRiskManagementToolType = () => ({...initialRiskManagementToolType});

const setCurrentNewRiskManagementToolType = set => riskManagementToolType => {
    set(state => ({...state, currentNewRiskManagementToolType: riskManagementToolType ?? InitialRiskManagementToolType()}));
}

const setCurrentUpdateRiskManagementToolType = set => riskManagementToolType => {
    set(state => ({...state, currentUpdateRiskManagementToolType: riskManagementToolType ?? InitialRiskManagementToolType()}));
}

export const useRiskManagementToolTypeStore = create(set => ({
    //State
    currentNewRiskManagementToolType: InitialRiskManagementToolType(),
    currentUpdateRiskManagementToolType: InitialRiskManagementToolType(),
    //Reducers
    setCurrentNewRiskManagementToolType: setCurrentNewRiskManagementToolType(set),
    setCurrentUpdateRiskManagementToolType: setCurrentUpdateRiskManagementToolType(set),
}))
