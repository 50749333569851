import React, {useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
// material
import {CircularProgress, Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {InstrumentMechanismParticipationTypeForm} from '../components';

import {InstrumentMechanismParticipationTypeHeaderBreadcrumbs} from '../components';

import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useInstrumentMechanismParticipationType} from '../services/instrument.mechanism.participation.type.gql';
import {useInstrumentMechanismParticipationTypeStore} from "../services/instrument.mechanism.participation.type.store";

export default function InstrumentMechanismParticipationTypeEdit() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {showError} = useMessage();

    const {setCurrentUpdateInstrumentMechanismParticipationType: setCurrentInstrumentMechanismParticipationType} = useInstrumentMechanismParticipationTypeStore();

    const {entity: currentInstrumentMechanismParticipationType, error, loading, errorMessage} = useInstrumentMechanismParticipationType(id);

    useEffect(() => {
        if (loading) return;
        if (error) {
            showError(errorMessage);
            navigate(PATH_GIRE_ENV_FAVORABLE.instrumentMechanismParticipationTypes);
        }
    }, [loading, error, errorMessage]);

    useEffect(() => {
        setCurrentInstrumentMechanismParticipationType(currentInstrumentMechanismParticipationType);
    }, [currentInstrumentMechanismParticipationType]);

    return (
        <Page title={"Gire environment favorable: Modification types de structures de cadre institutionnel et organisationnel eau"}>
            <Container maxWidth={'xl'}>
                <InstrumentMechanismParticipationTypeHeaderBreadcrumbs
                    heading="Modification du type de structures de cadre institutionnel et organisationnel eau"
                    lastName={`Modification type de structures de cadre institutionnel et organisationnel eau - ${id}`}

                />

                {loading && <CircularProgress/>}
                {!loading && currentInstrumentMechanismParticipationType && <InstrumentMechanismParticipationTypeForm isEdit {...{currentInstrumentMechanismParticipationType, setCurrentInstrumentMechanismParticipationType}}/>}
            </Container>
        </Page>
    );
}
