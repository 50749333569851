import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';

import MainLayout from '../../home/AppLayout';
import Loadable from '../../shared/components/Loadable';

import {PATH_WATER_MANAGEMENT} from './water.management.path';
import waterManagementTypeRouter from "./water_management_type/water.management.type.router";
import waterManagementEntityRouter from "./water_management/waterManagementEntityRouter";
import operationalManagementDeviceRouter from "./operational_management_device/water.management.type.router";
import strategicWaterManagementAreaEntityRouter from "./strategic_water_management_area/waterManagementEntityRouter";


const WaterUseHome = Loadable(lazy(() => import('./WaterManagementHome')));

const waterManagementRouter = [{
    path: PATH_WATER_MANAGEMENT.root,
    element: <MainLayout/>,
    children: [
        {path: PATH_WATER_MANAGEMENT.root, element: <Navigate to={PATH_WATER_MANAGEMENT.home} replace/>},
        {path: 'home', element: <WaterUseHome/>},

        ...waterManagementTypeRouter,
        ...waterManagementEntityRouter,
        ...operationalManagementDeviceRouter,
        ...strategicWaterManagementAreaEntityRouter,

    ]
},];

export default waterManagementRouter;
