import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const SpeciesTypeAnimalList = Loadable(lazy(() => import('./pages/SpeciesTypeAnimalList')));
const SpeciesTypeAnimalCreate = Loadable(lazy(() => import('./pages/SpeciesTypeAnimalCreate')));
const SpeciesTypeAnimalEdit = Loadable(lazy(() => import('./pages/SpeciesTypeAnimalEdit')));
const SpeciesTypeAnimalDisplay = Loadable(lazy(() => import('./pages/SpeciesTypeAnimalDisplay')));

const speciesTypeAnimalRouter = [
    {path: 'species-type-animals', element: <SpeciesTypeAnimalList/>},
    {path: 'species-type-animals/:id', element: <SpeciesTypeAnimalDisplay/>},
    {path: 'species-type-animals/new', element: <SpeciesTypeAnimalCreate/>},
    {path: 'species-type-animals/:id/edit', element: <SpeciesTypeAnimalEdit/>},
];

export default speciesTypeAnimalRouter;
