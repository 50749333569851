import React, {lazy} from 'react';
import InstrumentMechanismParticipationOccurrenceList from "./pages/InstrumentMechanismParticipationOccurrenceList";
import InstrumentMechanismParticipationOccurrenceDisplay from "./pages/InstrumentMechanismParticipationOccurrenceDisplay";
import InstrumentMechanismParticipationOccurrenceCreate from "./pages/InstrumentMechanismParticipationOccurrenceCreate";
import InstrumentMechanismParticipationOccurrenceEdit from "./pages/InstrumentMechanismParticipationOccurrenceEdit";

const instrumentMechanismParticipationOccurrenceRouter = [
    {path: 'instrument-mechanism-participation-occurrences', element: <InstrumentMechanismParticipationOccurrenceList/>},
    {path: 'instrument-mechanism-participation-occurrences/:id', element: <InstrumentMechanismParticipationOccurrenceDisplay/>},
    {path: 'instrument-mechanism-participation-occurrences/new', element: <InstrumentMechanismParticipationOccurrenceCreate/>},
    {path: 'instrument-mechanism-participation-occurrences/:id/edit', element: <InstrumentMechanismParticipationOccurrenceEdit/>},

];
export default instrumentMechanismParticipationOccurrenceRouter;
