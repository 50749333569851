import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const SpecificNeedList = Loadable(lazy(() => import('./pages/SpecificNeedList')));
const SpecificNeedCreate = Loadable(lazy(() => import('./pages/SpecificNeedCreate')));
const SpecificNeedEdit = Loadable(lazy(() => import('./pages/SpecificNeedEdit')));
const SpecificNeedDisplay = Loadable(lazy(() => import('./pages/SpecificNeedDisplay')));

const specificNeedRouter = [
    {path: 'specific-needs', element: <SpecificNeedList/>},
    {path: 'specific-needs/:id', element: <SpecificNeedDisplay/>},
    {path: 'specific-needs/new', element: <SpecificNeedCreate/>},
    {path: 'specific-needs/:id/edit', element: <SpecificNeedEdit/>},
];

export default specificNeedRouter;
