import React from 'react';
import {Box, Container, Grid} from '@mui/material';
import Page from '../shared/components/Page';
import ModuleWelcome from '../shared/components/ModuleWelcome';
import {Logo} from '../shared/components';
import MyReportingEntryButton from "../features/reporting/reporting/reporting_profile/components/MyReportingEntryButton";
import MyDashboardEntryButton from "../features/reporting/dashboardd/dashboard_profile/components/MyDashboardEntryButton";
import {MyFirstDashboard} from "../features/reporting/dashboardd/dashboard/pages/MyDashboardList";

export default function HomePage() {

    return (
        <Page title='SINEAU'>
        {/*<Page title='SYNERGIE'>*/}
            <Container maxWidth='xl'>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <ModuleWelcome
                            displayName={`SINEAU`}
                            // displayName={`SYNERGIE`}
                            description={`Système national d’information sur l’eau`}
//                             description={`Système d’information sur la collaboration et la Synergie
// entre les Différents Observatoires`}
                            icon={<Logo sx={{width: 150, height: 150}}/>}
                        />
                    </Grid>

                    <MyReportingEntryButton/>

                    <MyDashboardEntryButton/>

                </Grid>

                <Box sx={{mt: 5}}>
                    <MyFirstDashboard/>
                </Box>

            </Container>
        </Page>
    );
}



