import React, {useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
// material
import {CircularProgress, Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {RiskManagementToolForm} from '../components';

import {RiskManagementToolHeaderBreadcrumbs} from '../components';

import {PATH_RISK_PROTECTION} from '../../risk.protection.path';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useRiskManagementTool} from '../services/risk.management.tool.gql';
import {useRiskManagementToolStore} from "../services/risk.management.tool.store";

// ----------------------------------------------------------------------

export default function RiskManagementToolEdit() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {showError} = useMessage();

    const {setCurrentUpdateRiskManagementTool: setCurrentRiskManagementTool} = useRiskManagementToolStore();

    const {entity: currentRiskManagementTool, error, loading, errorMessage} = useRiskManagementTool(id);

    useEffect(() => {
        if (loading) return;
        if (error) {
            showError(errorMessage);
            navigate(PATH_RISK_PROTECTION.riskManagementTools);
        }
    }, [loading, error, errorMessage]);

    useEffect(() => {
        setCurrentRiskManagementTool(currentRiskManagementTool);
    }, [currentRiskManagementTool]);

    return (
        <Page title={'Risque et protection en RE: Modification outils de gestion de risques liés à l\'eau'}>
            <Container maxWidth={'xl'}>
                <RiskManagementToolHeaderBreadcrumbs
                    heading="Modification d'outils de gestion de risques liés à l'eau"
                    lastName={`Modification outils de gestion de risques liés à l'eau - ${id}`}
                />
                {loading && <CircularProgress/>}
                {!loading && currentRiskManagementTool && <RiskManagementToolForm isEdit {...{currentRiskManagementTool, setCurrentRiskManagementTool}}/>}
            </Container>
        </Page>
    );
}
