import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const CulturalSpeculationEntityUsagesList = Loadable(
  lazy(() => import("./pages/CulturalSpeculationEntityUsagesList"))
);
const CulturalSpeculationEntityUsagesCreate = Loadable(
  lazy(() => import("./pages/CulturalSpeculationEntityUsagesCreate"))
);
const CulturalSpeculationEntityUsagesEdit = Loadable(
  lazy(() => import("./pages/CulturalSpeculationEntityUsagesEdit"))
);
const CulturalSpeculationEntityUsagesDisplay = Loadable(
  lazy(() => import("./pages/CulturalSpeculationEntityUsagesDisplay"))
);

const CulturalSpeculationEntityUsagesRouter = [
  {
    path: "cultural-speculation-entity-usages",
    element: <CulturalSpeculationEntityUsagesList />,
  },
  {
    path: "cultural-speculation-entity-usages/:id",
    element: <CulturalSpeculationEntityUsagesDisplay />,
  },
  {
    path: "cultural-speculation-entity-usages/new",
    element: <CulturalSpeculationEntityUsagesCreate />,
  },
  {
    path: "cultural-speculation-entity-usages/:id/edit",
    element: <CulturalSpeculationEntityUsagesEdit />,
  },
];

export default CulturalSpeculationEntityUsagesRouter;
