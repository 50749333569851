import {gql, useQuery, useLazyQuery} from '@apollo/client';
import {parseCount, parseEntity} from '../../../../utils/gql.utils';
import {useEffect, useState} from 'react';
import {useMessage} from '../../../../shared/hooks/useMessage';

export let needRefresh = false;
export const setRefresh = (value) => needRefresh = value;

export const occurrenceAgriculturalProductionFields = `
    id name 
    dataSource
    saisonCulturale
    superfTotIrriguee
    productionAnnee
    rendement
    valeurMarchande
    irrigatedDevelopmentEntityUsages irrigatedDevelopmentEntityUsagesObj{id name}
    culturalSpeculationEntityUsages culturalSpeculationEntityUsagesObj{id name}
    dataRefYear dataRefYearObj{id name}
    localWaterDepartment localWaterDepartmentObj{id name}
`;


///////////////////// Find By ID /////////////////////
const GET_OCCURRENCE_AGRICULTURAL_PRODUCTION_BY_ID = gql`
    query OccurrenceAgriculturalProductionById($id: Int!) {
        occurrenceAgriculturalProductionById(id: $id) {
            error message entity { ${occurrenceAgriculturalProductionFields} }
        }
    }
`;

export const useOccurrenceAgriculturalProductionById = (id) => {
    id = parseInt(id);
    return useQuery(GET_OCCURRENCE_AGRICULTURAL_PRODUCTION_BY_ID, {
        variables: {id},
        fetchPolicy: 'network-only'
    });
}

export const useOccurrenceAgriculturalProduction = (id) => {
    const result = useOccurrenceAgriculturalProductionById(id);
    return parseEntity(result, 'occurrenceAgriculturalProductionById');
}

///////////////////// Search /////////////////////
const SEARCH_OCCURRENCE_AGRICULTURAL_PRODUCTION = gql`
    query SearchOccurrenceAgriculturalProductions($query: String, $irrigatedDevelopmentEntityUsages: Int,$dataRefYear: Int, $culturalSpeculationEntityUsages: Int, $localWaterDepartment: Int, $page: Int, $size: Int) {
        searchOccurrenceAgriculturalProductions(query: $query, irrigatedDevelopmentEntityUsages: $irrigatedDevelopmentEntityUsages, culturalSpeculationEntityUsages: $culturalSpeculationEntityUsages, dataRefYear: $dataRefYear, localWaterDepartment: $localWaterDepartment,  page: $page, size: $size) {
            error message page {
                content { ${occurrenceAgriculturalProductionFields} }
                page hasNext hasPrevious totalPages totalElements numberOfElements
            }
        }
    }
`;

export const useSearchOccurrenceAgriculturalProductions = ({
                                                               query = '',
                                                               irrigatedDevelopmentEntityUsages,
                                                               culturalSpeculationEntityUsages,
                                                               localWaterDepartment,
                                                               dataRefYear,
                                                               page = 0,
                                                               size = 20
                                                           }) => {
    const [occurrenceAgriculturalProductions, setOccurrenceAgriculturalProductions] = useState([]);
    const [occurrenceAgriculturalProductionPage, setOccurrenceAgriculturalProductionPage] = useState(null);

    const {showError} = useMessage();

    const [search, {
        data: searchData,
        refetch,
        loading,
        error: _error
    }] = useLazyQuery(SEARCH_OCCURRENCE_AGRICULTURAL_PRODUCTION, {
        variables: {query, irrigatedDevelopmentEntityUsages, dataRefYear, culturalSpeculationEntityUsages, localWaterDepartment, page, size},
    });

    useEffect(() => {
        if (_error) {
            showError('Erreur lors de l\'operation');
            setOccurrenceAgriculturalProductionPage(null);
            setOccurrenceAgriculturalProductions([]);
            return;
        }

        if (!searchData) return;

        const {error, message, page} = searchData.searchOccurrenceAgriculturalProductions;

        if (error) {
            showError(message);
            setOccurrenceAgriculturalProductionPage(null);
            setOccurrenceAgriculturalProductions([]);
        } else {
            setOccurrenceAgriculturalProductionPage(page);
            setOccurrenceAgriculturalProductions(page.content);
        }

        if (needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({variables: {query, irrigatedDevelopmentEntityUsages, culturalSpeculationEntityUsages, localWaterDepartment, dataRefYear, page, size}});
    }, [query, irrigatedDevelopmentEntityUsages, culturalSpeculationEntityUsages, localWaterDepartment, dataRefYear, page, size]);

    return {
        occurrenceAgriculturalProductions: occurrenceAgriculturalProductions,
        loading,
        search,
        refetch,
        occurrenceAgriculturalProductionPage
    }
}

///////////////////// Count /////////////////////
const COUNT_OCCURRENCE_AGRICULTURAL_PRODUCTION = gql`
    query CountOccurrenceAgriculturalProductions {
        countOccurrenceAgriculturalProductions {
            error message count
        }
    }
`;

export const useCountOccurrenceAgriculturalProductions = () => {
    const result = useQuery(COUNT_OCCURRENCE_AGRICULTURAL_PRODUCTION, {
        fetchPolicy: 'network-only'
    });
    return parseCount(result, 'countOccurrenceAgriculturalProductions');
}
