import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';

import MainLayout from '../../home/AppLayout';
import Loadable from '../../shared/components/Loadable';

import {PATH_PASTORAL} from './pastoral.path';
import typeSpaceRouter from "./type_space/type.space.router";
import speciesTypeAnimalRouter from "./species_type_animal/species.type.animal.router";
import sourceDrinkingWaterRouter from "./source_drinking_water/source.drinking.water.router";
import entityAreaRouter from "./entity_area/entity.area.router";
import occurrenceAnimalProductionRouter from "./occurrence_animal_production/occurrence.animal.production.router";
import occurrenceUsePastoralRouter from "./occurrence_use_pastoral/occurrence.use.pastoral.router";




const PASTORAL_HOME = Loadable(lazy(() => import('./PastoralHome')));

const pastoralRouter = [{
    path: PATH_PASTORAL.root,
    element: <MainLayout/>,
    children: [
        {path: PATH_PASTORAL.root, element: <Navigate to={PATH_PASTORAL.home} replace/>},
        {path: 'home', element: <PASTORAL_HOME/>},

          ...typeSpaceRouter,
          ...speciesTypeAnimalRouter,
          ...sourceDrinkingWaterRouter,
          ...entityAreaRouter,
          ...occurrenceAnimalProductionRouter,
          ...occurrenceUsePastoralRouter,
    ]
},];

export default pastoralRouter;
