import React from "react";
import {Link as RouterLink} from "react-router-dom";

import {useTheme} from "@mui/material/styles";
import {Refresh, Search} from "@mui/icons-material";
import {
    Box,
    IconButton,
    InputAdornment,
    Link,
    OutlinedInput,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Toolbar,
    Tooltip,
    Typography,
} from "@mui/material";

import {useSearchHealthAreas} from "../services/health.area.gql";
import useHealthAreaService from "../services/health.area.services";
import {useMessage} from "../../../../shared/hooks/useMessage";
import {usePagination} from "../../../../shared/components/tables";
import SortableTableHead from "../../../../shared/components/tables/SortableTableHead";
import MyTablePagination from "../../../../shared/components/tables/MyTablePagination";
import {HealthAreaMoreMenu} from "./index";
import AlertDialog, {
    useDeleteDialog,
} from "../../../../shared/components/AlertDialog";
import {PATH_RISK_MANAGEMENT, PATH_RISK_PROTECTION} from "../../risk.protection.path";
import {PATH_WATER_USE} from "../../../water_use/water.use.path";

const TABLE_HEAD = [
    {id: "id", label: "ID", alignRight: false},
    {id: "name", label: "Nom", alignRight: false},
    {id: "waterMassObj", label: "Mass d'eau", alignRight: false},
    {id: "dataSource", label: "Source de donnée", alignRight: false},
    {id: ""},
];

export default function HealthAreaTable() {
    const theme = useTheme();
    const {showSuccess, showError} = useMessage();
    const {destroy} = useHealthAreaService();

    const {
        page,
        order,
        setSelected,
        filterName,
        rowsPerPage,
        orderBy,
        handleRequestSort,
        handleChangePage,
        handleChangeRowsPerPage,
        handleFilterByName,
    } = usePagination();

    const {deleteConfirmOpen, setDeleteConfirmOpen, idToDelete, handleDelete} =
        useDeleteDialog();

    const {healthAreas, refetch, healthAreaPage} = useSearchHealthAreas({
        query: filterName,
        size: rowsPerPage,
        page,
    });

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = healthAreas.map(({id}) => id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleDeleteConfirm = () => {
        console.log("####### handleDelete");
        destroy(idToDelete)
            .then((_) => {
                showSuccess(`Entités aires de santé supprime avec succès`);
                refetch();
            })
            .catch((error) => {
                showError(`Erreur lors de la suppression du  entités aires de santé ${error}`);
            });
    };

    return (
        <>
            <Toolbar
                sx={{
                    height: 96,
                    display: "flex",
                    justifyContent: "space-between",
                    padding: theme.spacing(0, 1, 0, 3),
                }}
            >
                <OutlinedInput
                    sx={{width: 240}}
                    value={filterName}
                    onChange={handleFilterByName}
                    placeholder="Recherche entités aires de santé"
                    startAdornment={
                        <InputAdornment position="start">
                            <Box component={Search} sx={{color: "text.disabled"}}/>
                        </InputAdornment>
                    }
                />

                <Tooltip title="Actualiser">
                    <IconButton onClick={() => refetch()}>
                        <Refresh/>
                    </IconButton>
                </Tooltip>
            </Toolbar>
            <TableContainer sx={{minWidth: 800}}>
                <Table size="small">
                    <SortableTableHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={healthAreas.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                        {healthAreas.map((row) => (
                            <Row key={row.id} row={row} handleDelete={handleDelete}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <MyTablePagination
                page={healthAreaPage}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <AlertDialog
                title={"Suppression"}
                message={"Voulez-vous vraiment supprimer ce type entités aires de santé"}
                open={deleteConfirmOpen}
                onYes={() => {
                    setDeleteConfirmOpen(false);
                    handleDeleteConfirm();
                }}
                onClose={() => setDeleteConfirmOpen(false)}
                onNo={() => setDeleteConfirmOpen(false)}
            />
        </>
    );
}

function Row({row, handleDelete}) {
    const {id, name, waterMassObj, dataSource} = row;

    return (
        <TableRow hover sx={{"&:last-child td, &:last-child th": {border: 0}}}>
            <TableCell component="th" scope="row">
                <Link
                    to={PATH_RISK_PROTECTION.healthAreas + "/" + id}
                    component={RouterLink}
                >
                    <Typography variant="body2" noWrap>
                        {id}
                    </Typography>
                </Link>
            </TableCell>

            <TableCell component="th" scope="row">
                <Link
                    to={PATH_RISK_PROTECTION.healthAreas + "/" + id}
                    component={RouterLink}
                >
                    <Typography variant="body2" noWrap>
                        {name}
                    </Typography>
                </Link>
            </TableCell>
            <TableCell style={{minWidth: 160}}>
                <Link
                    to={PATH_WATER_USE.water_mass + "/" + waterMassObj?.id}
                    component={RouterLink}
                >
                <Typography variant="body2" noWrap>
                    {waterMassObj?.wording}
                </Typography>
                </Link>
            </TableCell>

            <TableCell style={{minWidth: 160}}>
                <Typography variant="body2" noWrap>
                    {dataSource}
                </Typography>
            </TableCell>
            <TableCell align="right">
                <HealthAreaMoreMenu id={id} onDelete={() => handleDelete(id)}/>
            </TableCell>
        </TableRow>
    );
}
