import React from 'react';
import {TablePagination} from '@mui/material';


export default function MyTablePagination(
    {
        page, rowsPerPage, onPageChange, onRowsPerPageChange, rowsPerPageOptions = [5, 10, 20, 50, 100]
    }
) {
    return (
        <TablePagination
            component='div'
            page={page?.page ?? 0}
            rowsPerPage={rowsPerPage}
            count={page?.totalElements ?? 0}
            onPageChange={onPageChange}
            rowsPerPageOptions={rowsPerPageOptions}
            onRowsPerPageChange={onRowsPerPageChange}
            labelDisplayedRows={({ from, to, count }) => {
                return `${from}–${to} sur ${count !== -1 ? count : `plus de ${to}`}`;
            }}
            labelRowsPerPage='Lignes par page: '
        />
    );
}
