import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const ProductionWasteWaterList = Loadable(lazy(() => import('./pages/ProductionWasteWaterList')));
const ProductionWasteWaterCreate = Loadable(lazy(() => import('./pages/ProductionWasteWaterCreate')));
const ProductionWasteWaterEdit = Loadable(lazy(() => import('./pages/ProductionWasteWaterEdit')));
const ProductionWasteWaterDisplay = Loadable(lazy(() => import('./pages/ProductionWasteWaterDisplay')));

const productionWasteWaterRouter = [
    {path: 'production-waste-waters', element: <ProductionWasteWaterList/>},
    {path: 'production-waste-waters/:id', element: <ProductionWasteWaterDisplay/>},
    {path: 'production-waste-waters/new', element: <ProductionWasteWaterCreate/>},
    {path: 'production-waste-waters/:id/edit', element: <ProductionWasteWaterEdit/>},
];

export default productionWasteWaterRouter;
