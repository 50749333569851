import React from 'react';
// components
import {RiskTypeForm} from '../components';
import CreateDialogMenuItem from '../../../../shared/components/CreateDialogMenuItem';
import {useRiskTypeStore} from "../services/risk.type.store";

// ----------------------------------------------------------------------


export function RiskTypeCreateDialogMenuItem({onClose, onSave, btn}) {
    const {currentNewRiskType: currentRiskType, setCurrentNewRiskType: setCurrentRiskType} = useRiskTypeStore();
    return (
        <CreateDialogMenuItem
            btn={btn}
            onClose={onClose}
            menuText='Ajouter un type de risques liés aux RE'
            dialogTitle='Création de type de risques liés aux RE'
            dialogContextText={<> {`Création de nouveau type de risques liés aux RE`} </>}
            formRender={({onClose}) => {
                return (
                    <RiskTypeForm
                        {...{currentRiskType, setCurrentRiskType}}
                        fromDialog={{
                            onCancel: onClose,
                            onSave: (createdRiskType) => {
                                onSave && onSave(createdRiskType);
                                onClose && onClose();
                            }
                        }}/>
                );
            }}
        />
    );

}
