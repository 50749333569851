import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const EventLossOccurrenceList = Loadable(lazy(() => import("./pages/EventLossOccurrenceList")));
const EventLossOccurrenceCreate = Loadable(lazy(() => import("./pages/EventLossOccurrenceCreate")));
const EventLossOccurrenceEdit = Loadable(lazy(() => import("./pages/EventLossOccurrenceEdit")));
const EventLossOccurrenceDisplay = Loadable(lazy(() => import("./pages/EventLossOccurrenceDisplay")));

const eventLossOccurrenceRouter = [
  { path: "event-loss-occurrences", element: <EventLossOccurrenceList /> },
  { path: "event-loss-occurrences/:id", element: <EventLossOccurrenceDisplay /> },
  { path: "event-loss-occurrences/new", element: <EventLossOccurrenceCreate /> },
  { path: "event-loss-occurrences/:id/edit", element: <EventLossOccurrenceEdit /> },
];

export default eventLossOccurrenceRouter;
