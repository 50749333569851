import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const WaterManagementTypeList = Loadable(lazy(() => import("./pages/WaterManagementTypeList")));
const WaterManagementTypeCreate = Loadable(lazy(() => import("./pages/WaterManagementTypeCreate")));
const WaterManagementTypeEdit = Loadable(lazy(() => import("./pages/WaterManagementTypeEdit")));
const WaterManagementTypeDisplay = Loadable(lazy(() => import("./pages/WaterManagementTypeDisplay")));

const waterManagementTypeRouter = [
  { path: "water-management-types", element: <WaterManagementTypeList /> },
  { path: "water-management-types/:id", element: <WaterManagementTypeDisplay /> },
  { path: "water-management-types/new", element: <WaterManagementTypeCreate /> },
  { path: "water-management-types/:id/edit", element: <WaterManagementTypeEdit /> },
];

export default waterManagementTypeRouter;
