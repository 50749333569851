import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const OperationalUnityList = Loadable(lazy(() => import('./pages/OperationalUnityList')));
const OperationalUnityCreate = Loadable(lazy(() => import('./pages/OperationalUnityCreate')));
const OperationalUnityEdit = Loadable(lazy(() => import('./pages/OperationalUnityEdit')));
const OperationalUnityDisplay = Loadable(lazy(() => import('./pages/OperationalUnityDisplay')));

const operationalUnityRouter = [
    {path: 'operational-unities', element: <OperationalUnityList/>},
    {path: 'operational-unities/:id', element: <OperationalUnityDisplay/>},
    {path: 'operational-unities/new', element: <OperationalUnityCreate/>},
    {path: 'operational-unities/:id/edit', element: <OperationalUnityEdit/>},
];

export default operationalUnityRouter;
