import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const OccurrenceResultList = Loadable(lazy(() => import('./pages/OccurrenceResultList')));
const OccurrenceResultCreate = Loadable(lazy(() => import('./pages/OccurrenceResultCreate')));
const OccurrenceResultEdit = Loadable(lazy(() => import('./pages/OccurrenceResultEdit')));
const OccurrenceResultDisplay = Loadable(lazy(() => import('./pages/OccurrenceResultDisplay')));

const occurrenceResultRouter = [
    {path: 'occurrence-results', element: <OccurrenceResultList/>},
    {path: 'occurrence-results/:id', element: <OccurrenceResultDisplay/>},
    {path: 'occurrence-results/new', element: <OccurrenceResultCreate/>},
    {path: 'occurrence-results/:id/edit', element: <OccurrenceResultEdit/>},
];

export default occurrenceResultRouter;
