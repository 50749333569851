import * as Yup from 'yup';
import create from 'zustand';


export const InstrumentMechanismParticipationTypeSchema = Yup.object().shape({
    name: Yup.string().default('').required('Le nom est obligatoire'),
    description: Yup.string().default(''),
    dataSource: Yup.string().default(''),
});

const initialInstrumentMechanismParticipationType = InstrumentMechanismParticipationTypeSchema.getDefaultFromShape();
export const InitialInstrumentMechanismParticipationType = () => ({...initialInstrumentMechanismParticipationType});

const setCurrentNewInstrumentMechanismParticipationType = set => instrumentMechanismParticipationType => {
    set(state => ({...state, currentNewInstrumentMechanismParticipationType: instrumentMechanismParticipationType ?? InitialInstrumentMechanismParticipationType()}));
}

const setCurrentUpdateInstrumentMechanismParticipationType = set => instrumentMechanismParticipationType => {
    set(state => ({...state, currentUpdateInstrumentMechanismParticipationType: instrumentMechanismParticipationType ?? InitialInstrumentMechanismParticipationType()}));
}

export const useInstrumentMechanismParticipationTypeStore = create(set => ({
    //State
    currentNewInstrumentMechanismParticipationType: InitialInstrumentMechanismParticipationType(),
    currentUpdateInstrumentMechanismParticipationType: InitialInstrumentMechanismParticipationType(),
    //Reducers
    setCurrentNewInstrumentMechanismParticipationType: setCurrentNewInstrumentMechanismParticipationType(set),
    setCurrentUpdateInstrumentMechanismParticipationType: setCurrentUpdateInstrumentMechanismParticipationType(set),
}))
