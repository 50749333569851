import {useApiHttp} from '../../../../shared/hooks/useHttp';

const useInstitutionalOrganizationalFrameworkStructureTypeService = () => {
    const {crud} = useApiHttp();

    const {
        create,
        update,
        destroy,
        findById,
        findAll,
    } = crud('institutional-organizational-framework-structure-types');

    return {
        create,
        update,
        destroy,
        findById,
        findAll,
    }
}

export default useInstitutionalOrganizationalFrameworkStructureTypeService;
