import React from "react";
import { path } from "../../home/sidebar/paths";
import { ManageAccountsOutlined } from "@mui/icons-material";
//Features
export const MANAGEMENT_INSTRUMENTS = "/management-instruments";
const _path = path(MANAGEMENT_INSTRUMENTS);
export const PATH_MANAGEMENT_INSTRUMENTS = {
  root: MANAGEMENT_INSTRUMENTS,
  home: _path("/home"),
  evaluation_tools: _path("/evaluations-tools"),
  evaluation_tools_type: _path("/evaluation-tools-type"),
  evaluation_occurrences: _path("/evaluation-occurrences"),
  types_observation_device: _path("/types-observation-device"),
  station_reference_status: _path("/station-reference-status"),
  information_supports_types: _path("/information-supports-types"),
  production_of_information_supports: _path("/production-of-information-supports"),
  information_support_production_occurrences: _path("/information-support-production-occurrences"),
  feature_occurrences_of_observation_devices: _path("/feature-occurrences-of-observation-devices"),
  observation_station_functionality_occurrences: _path("/observation-station-functionality-occurrences"),
  quantitative_observation_and_quality_control_stations: _path("/quantitative-observation-and-quality-control-stations"),
  drinking_water_quality_and_pollution_control_stations: _path("/drinking-water-quality-and-pollution-control-stations"),
};

export const managementInstrumentsMenu = [
  {
    title: "Instrument de gestion",
    path: PATH_MANAGEMENT_INSTRUMENTS.root,
    icon: <ManageAccountsOutlined />,
    children: [
      { title: "Accueil", path: PATH_MANAGEMENT_INSTRUMENTS.home },
      { title: "Outils evaluation", path: PATH_MANAGEMENT_INSTRUMENTS.evaluation_tools },
      { title: "Types outils évaluation", path: PATH_MANAGEMENT_INSTRUMENTS.evaluation_tools_type },
      { title: "Statut référence station", path: PATH_MANAGEMENT_INSTRUMENTS.station_reference_status },
      { title: "Types dispositif observation", path: PATH_MANAGEMENT_INSTRUMENTS.types_observation_device },
      { title: "Types supports Informations", path: PATH_MANAGEMENT_INSTRUMENTS.information_supports_types },
      { title: "Production supports informations", path: PATH_MANAGEMENT_INSTRUMENTS.production_of_information_supports },
      { title: "Stations contrôle de pollution", path: PATH_MANAGEMENT_INSTRUMENTS.drinking_water_quality_and_pollution_control_stations },
      { title: "Stations observation quantitative", path: PATH_MANAGEMENT_INSTRUMENTS.quantitative_observation_and_quality_control_stations },
    ],
  },
];


export const commonItems = [
  { name: "Accueil", href: "/" },
  { name: "Instrument de gestion", href: PATH_MANAGEMENT_INSTRUMENTS.home },
];
