import React, {useRef, useState} from 'react';
import {
    Button,
    Divider,
    IconButton,
    Input,
    InputLabel,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem
} from "@mui/material";
import {Add, ArrowDropDown, Edit, Info, MoreVert} from "@mui/icons-material";
import {ConfirmationMenuItem} from "./AlertDialog";
import {Link as RouterLink} from "react-router-dom";

export default function DefaultActionsButton({label = 'Actions', open, setOpen, children}) {
    const actionMenuRef = useRef(null);

    return (
        <>
            <Button
                ref={actionMenuRef}
                variant="contained"
                endIcon={<ArrowDropDown/>}
                onClick={() => setOpen(true)}
            >
                {label}
            </Button>

            <Menu
                open={open}
                anchorEl={actionMenuRef.current}
                onClose={() => setOpen(false)}
                PaperProps={{sx: {width: 200, maxWidth: '100%'}}}
            >
                {children}
            </Menu>
        </>
    );
}

export function CrudActionsButton(
    {onDelete, id, isIconButton, hiddenNew, hiddenDisplay, hiddenEdit, hiddenDelete, url, deleteMessage}
) {

    return (
        <ActionsButton
            isIconButton={isIconButton}
            items={[
                {icon: <Info/>, label: 'Afficher', hidden: hiddenDisplay, component: RouterLink, to: `${url}/${id}`},
                {icon: <Add/>, label: 'Nouveau', hidden: hiddenNew, component: RouterLink, to: `${url}/new`},
                {icon: <Edit/>, label: 'Modifier', hidden: hiddenEdit, component: RouterLink, to: `${url}/${id}/edit`},
                {divider: true},
                {
                    isConfirmation: true,
                    hidden: hiddenDelete,
                    onClick: () => onDelete(id),
                    DialogProps: {message: deleteMessage},
                },
            ]}
        />
    );
}

export function ActionsButton({label, isIconButton, icon, items}) {
    const actionMenuRef = useRef(null);
    const [open, setOpen] = useState(false);

    if (!items || items.length === 0) return <></>;

    let anchorConfig = {
        anchorOrigin: {vertical: 'top', horizontal: 'right'},
        transformOrigin: {vertical: 'top', horizontal: 'right'}
    }
    if (!isIconButton) {
        anchorConfig = {
            ...anchorConfig,
            anchorOrigin: {vertical: 'bottom', horizontal: 'right'},
        }
    }

    return (
        <>
            {isIconButton && (
                <IconButton
                    ref={actionMenuRef}
                    onClick={() => setOpen(true)}
                >
                    {icon ?? <MoreVert width={20} height={20}/>}
                </IconButton>
            )}
            {!isIconButton && (
                <Button
                    ref={actionMenuRef}
                    variant="contained"
                    endIcon={<ArrowDropDown/>}
                    onClick={() => setOpen(true)}
                >
                    {label ?? 'Actions'}
                </Button>
            )}

            <Menu
                open={open}
                anchorEl={actionMenuRef.current}
                onClose={() => {
                    console.log('On Menu Close ActionsButton');
                    setOpen(false);
                }}
                PaperProps={{sx: {minWidth: 200, maxWidth: 400}}}
                {...{...anchorConfig}}
            >
                {items.map((item, index) => (
                    <ActionMenuItem key={index} item={item} setOpen={setOpen}/>
                ))}
            </Menu>
        </>
    );
}

function ActionMenuItem({item, setOpen}) {
    const fileInputRef = useRef();
    let {
        id, to, sx, icon, label, divider, upload, component, onClick: _onClick,
        isConfirmation, DialogProps, hidden, componentRender
    } = item;

    if (hidden) return <></>;

    if (divider) return <Divider/>;

    if (componentRender) return componentRender({setOpen});

    if (upload) {

        const handleUpload = (e) => {
            try {
                const {files} = e.target;
                if (!_onClick || (files?.length ?? 0) === 0) {
                    setOpen(false);
                    return;
                }

                _onClick(e);

                fileInputRef.current.value = null;

                setOpen(false);
            } catch (e) {
                console.error(e);
            }
        }

        return (
            <InputLabel htmlFor={id}>
                <Input
                    id={id}
                    name={id}
                    type='file'
                    ref={fileInputRef}
                    onChange={handleUpload}
                    style={{display: 'none'}}
                />
                <MenuItem sx={{color: 'text.secondary', ...(sx ?? {})}}>
                    <ListItemIcon> {icon} </ListItemIcon>
                    <ListItemText primary={label} primaryTypographyProps={{variant: 'body2'}}/>
                </MenuItem>
            </InputLabel>
        );
    }

    const onClick = _onClick && ((e) => {
        setOpen(false);
        _onClick(e);
    });

    if (isConfirmation) {
        return (
            <ConfirmationMenuItem
                label={label}
                icon={icon}
                onConfirm={onClick}
                DialogProps={DialogProps}
                onCancel={() => setOpen(false)}
            />
        );
    }

    return (
        <MenuItem
            to={to}
            onClick={onClick}
            component={component}
            sx={{color: 'text.secondary', ...(sx ?? {})}}
        >
            <ListItemIcon> {icon} </ListItemIcon>
            <ListItemText primary={label} primaryTypographyProps={{variant: 'body2'}}/>
        </MenuItem>
    );
}
