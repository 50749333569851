import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const TankNatureList = Loadable(lazy(() => import("./pages/TankNatureList")));
const TankNatureCreate = Loadable(
  lazy(() => import("./pages/TankNatureCreate"))
);
const TankNatureEdit = Loadable(lazy(() => import("./pages/TankNatureEdit")));
const TankNatureDisplay = Loadable(
  lazy(() => import("./pages/TankNatureDisplay"))
);

const tankNatureRouter = [
  { path: "tank-natures", element: <TankNatureList /> },
  { path: "tank-natures/:id", element: <TankNatureDisplay /> },
  { path: "tank-natures/new", element: <TankNatureCreate /> },
  { path: "tank-natures/:id/edit", element: <TankNatureEdit /> },
];

export default tankNatureRouter;
