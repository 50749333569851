import React from 'react';
import {PATH_RISK_PROTECTION, commonItems} from '../../risk.protection.path';
import HeaderBreadcrumbs from "../../../../shared/components/HeaderBreadcrumbs";


export function RiskManagementToolHeaderBreadcrumbs ({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[
            ...commonItems,
            {name: 'Outils de gestion de risques liés à l\'eau', href: PATH_RISK_PROTECTION.riskManagementTools},
            {name: lastName},
        ]}
    />);
}

export function RiskManagementToolListHeaderBreadcrumbs ({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[...commonItems, {name: lastName}]}
    />);
}
