import React, { lazy } from "react";
import { Navigate } from "react-router-dom";

import MainLayout from "../../home/AppLayout";
import Loadable from "../../shared/components/Loadable";

import { PATH_WATER_USE } from "./water.use.path";
import localWaterDepartmentRouter from "./local_water_department/local.water.department.router";
import areaUseWaterRouter from "./area_use_water/area.use.water.router";
import waterUseItemRouter from "./water_use_item/water.use.item.router";
import operationalStructureWaterUseRouter from "./operational_structure_water_use/operational.structure.water.use.router";

import waterSourceRouter from "./water_source/water.source.router";
import impactRouter from "./impact/impact.router";
import rejectRouter from "./reject/reject.router";
import waterUseModeRouter from "./water_use_mode/water.use.mode.router";
import workRouter from "./work/work.router";
import waterTypeRouter from "./water_type/water.type.router";
import waterMassSetRouter from "./water_mass_set/water.mass.set.router";
import waterMassSubsetRouter from "./water_mass_subset/water.mass.subset.router";
import waterMassRouter from "./water_mass/water.mass.router";

const WaterUseHome = Loadable(lazy(() => import("./WaterUseHome")));

const waterUseRouter = [
  {
    path: PATH_WATER_USE.root,
    element: <MainLayout />,
    children: [
      {
        path: PATH_WATER_USE.root,
        element: <Navigate to={PATH_WATER_USE.home} replace />,
      },
      { path: "home", element: <WaterUseHome /> },
      ...waterSourceRouter,
      ...impactRouter,
      ...rejectRouter,
      ...waterUseModeRouter,
      ...workRouter,
      ...waterTypeRouter,
      ...waterMassSetRouter,
      ...waterMassSubsetRouter,
      ...localWaterDepartmentRouter,
      ...areaUseWaterRouter,
      ...waterUseItemRouter,
      ...operationalStructureWaterUseRouter,
      ...waterMassRouter,
    ],
  },
];

export default waterUseRouter;