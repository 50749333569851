import React from 'react';
// material
import {Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {ParticipationAreaAndTypeForm} from '../components';

import {ParticipationAreaAndTypeHeaderBreadcrumbs} from '../components';
import {useParticipationAreaAndTypeStore} from "../services/participation.area.and.type.store";

// ----------------------------------------------------------------------

export default function ParticipationAreaAndTypeCreate() {
    const {currentNewParticipationAreaAndType: currentParticipationAreaAndType, setCurrentNewParticipationAreaAndType: setCurrentParticipationAreaAndType} = useParticipationAreaAndTypeStore();
    return (
        <Page title="Gire environment favorable: Création type et domaine de participation à la GIRE">
            <Container  maxWidth={'xl'}>
                <ParticipationAreaAndTypeHeaderBreadcrumbs
                    heading="Création de nouveau type et domaine de participation à la GIRE"
                    lastName="Nouveau type et domaine de participation à la GIRE"
                />
                <ParticipationAreaAndTypeForm {...{currentParticipationAreaAndType, setCurrentParticipationAreaAndType}}/>
            </Container>
        </Page>
    );
}
