import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const PaysList = Loadable(lazy(() => import('./pages/PaysList')));
const PaysCreate = Loadable(lazy(() => import('./pages/PaysCreate')));
const PaysEdit = Loadable(lazy(() => import('./pages/PaysEdit')));
const PaysDisplay = Loadable(lazy(() => import('./pages/PaysDisplay')));

const paysRouter = [
    {path: 'pays', element: <PaysList/>},
    {path: 'pays/:id', element: <PaysDisplay/>},
    {path: 'pays/new', element: <PaysCreate/>},
    {path: 'pays/:id/edit', element: <PaysEdit/>},
];

export default paysRouter;
