import React from 'react';
import {Link as RouterLink} from "react-router-dom";

import {useTheme} from "@mui/material/styles";
import {Refresh, Search} from "@mui/icons-material";
import {
    Box,
    IconButton,
    InputAdornment, Link,
    OutlinedInput,
    Table,
    TableBody, TableCell,
    TableContainer, TableRow,
    Toolbar,
    Tooltip, Typography
} from '@mui/material';

import {useSearchRegions} from '../services/region.gql';
import useRegionService from '../services/region.services';
import {PATH_ADMINISTRATIVE_DIVISION} from '../../administrative_division.path';
import {RegionCreateDialogMenuItem, RegionMoreMenu} from './index';
import {SortableTableHead, MyTablePagination, usePagination} from "../../../../shared/components/tables";
import {useDelete} from "../../../../shared/hooks/useDelete";
import {sxTableRow, sxTableToolbar} from "../../../../shared/components/tables/components.table.utils";

const TABLE_HEAD = [
    {id: 'id', label: 'ID', alignRight: false},
    {id: 'name', label: 'Nom', alignRight: false},
    {id: 'pays', label: 'Pays', alignRight: false},
    {id: ''}
];

export default function RegionTable({pays, currentPays}) {
    const theme = useTheme();
    const {
        page, order, filterName, rowsPerPage, orderBy, handleRequestSort,
        handleChangePage, handleChangeRowsPerPage, handleFilterByName
    } = usePagination();

    const {regions, refetch, regionsPage} = useSearchRegions({
        query: filterName,
        size: rowsPerPage,
        page, pays
    });

    const {destroy} = useRegionService();
    const {onDelete} = useDelete({
        destroyFn: destroy, onSuccess: refetch
    });

    return (
        <>
            <Toolbar sx={sxTableToolbar}>
                <OutlinedInput
                    sx={{width: 240}}
                    value={filterName}
                    onChange={handleFilterByName}
                    placeholder='Recherche region'
                    startAdornment={
                        <InputAdornment position='start'>
                            <Box component={Search} sx={{color: 'text.disabled'}}/>
                        </InputAdornment>
                    }
                />

                <Box>
                    {currentPays && (
                        <RegionCreateDialogMenuItem
                            btn
                            pays={currentPays}
                            onSave={(createdRegion) => {
                                console.log(`##### createdRegion: `, createdRegion);
                                refetch();
                            }}
                        />
                    )}
                    <Tooltip title='Actualiser'>
                        <IconButton onClick={() => refetch()}>
                            <Refresh/>
                        </IconButton>
                    </Tooltip>
                </Box>
            </Toolbar>
            <TableContainer sx={{minWidth: 800}}>
                <Table size='small'>
                    <SortableTableHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {regions.map((row) => (<Row key={row.id} {...{row, onDelete}}/>))}
                    </TableBody>
                </Table>
            </TableContainer>

            <MyTablePagination
                page={regionsPage}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}

function Row({row, onDelete}) {
    const {id, name, paysObj} = row;

    return (
        <TableRow hover sx={sxTableRow}>
            <TableCell component='th' scope='row'>
                <Link
                    to={PATH_ADMINISTRATIVE_DIVISION.regions + '/' + id}
                    component={RouterLink}>
                    <Typography variant='body2' noWrap>
                        {id}
                    </Typography>
                </Link>
            </TableCell>
            <TableCell component='th' scope='row'>
                <Link
                    to={PATH_ADMINISTRATIVE_DIVISION.regions + '/' + id}
                    component={RouterLink}
                >
                    <Typography variant='body2' noWrap>
                        {name}
                    </Typography>
                </Link>
            </TableCell>
            <TableCell style={{minWidth: 160}}>
                <Link
                    to={PATH_ADMINISTRATIVE_DIVISION.pays + '/' + paysObj?.id}
                    component={RouterLink}
                >
                    <Typography variant='body2' noWrap>
                        {paysObj?.name}
                    </Typography>
                </Link>
            </TableCell>
            <TableCell align="right">
                <RegionMoreMenu id={id} onDelete={onDelete} currentRegion={row} isIconButton hiddenNew/>
            </TableCell>
        </TableRow>
    );
}
