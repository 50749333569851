import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const TypeCommunicationMethodList = Loadable(
  lazy(() => import("./pages/TypeCommunicationMethodList"))
);
const TypeCommunicationMethodCreate = Loadable(
  lazy(() => import("./pages/TypeCommunicationMethodCreate"))
);
const TypeCommunicationMethodEdit = Loadable(
  lazy(() => import("./pages/TypeCommunicationMethodEdit"))
);
const TypeCommunicationMethodDisplay = Loadable(
  lazy(() => import("./pages/TypeCommunicationMethodDisplay"))
);

const typeCommunicationMethodRouter = [
  {
    path: "type-communication-methods",
    element: <TypeCommunicationMethodList />,
  },
  {
    path: "type-communication-methods/:id",
    element: <TypeCommunicationMethodDisplay />,
  },
  {
    path: "type-communication-methods/new",
    element: <TypeCommunicationMethodCreate />,
  },
  {
    path: "type-communication-methods/:id/edit",
    element: <TypeCommunicationMethodEdit />,
  },
];

export default typeCommunicationMethodRouter;
