import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Box, Tab, Card, Container, Stack, TextField, CircularProgress} from '@mui/material';
import Page from '../../../../shared/components/Page';
import {InstitutionalOrganizationalFrameworkStructureTypeHeaderBreadcrumbs} from '../components';
import {FormRowStack} from '../../../../shared/components/froms/FormComponents';
import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useInstitutionalOrganizationalFrameworkStructureType} from '../services/institutional.organizational.framework.structure.type.gql';
import InstitutionalOrganizationalFrameworkStructureTypeMoreMenu from '../components/InstitutionalOrganizationalFrameworkStructureTypeMoreMenu';
import useInstitutionalOrganizationalFrameworkStructureTypeService from "../services/institutional.organizational.framework.structure.type.services";
import {useDelete} from "../../../../shared/hooks/useDelete";

export default function InstitutionalOrganizationalFrameworkStructureTypeDisplay() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {showError} = useMessage();
    const [tapIndex, setTapIndex] = useState('info');
    const {loading, entity: currentInstitutionalOrganizationalFrameworkStructureType, error, errorMessage} = useInstitutionalOrganizationalFrameworkStructureType(id);

    const {destroy} = useInstitutionalOrganizationalFrameworkStructureTypeService();
    const {onDelete} = useDelete({
        destroyFn: destroy,
        onSuccess: () => navigate(PATH_GIRE_ENV_FAVORABLE.institutionalOrganizationalFrameworkStructureTypes),
    });

    useEffect(() => {
        if (loading) return;
        if (error) {
            showError(errorMessage);
            navigate(PATH_GIRE_ENV_FAVORABLE.institutionalOrganizationalFrameworkStructureTypes);
        }
    }, [error, loading, errorMessage]);

    const handleChangeTab = (event, newValue) => setTapIndex(newValue);

    return (
        <Page title={"Gire environment favorable: Types de structures de cadre institutionnel et organisationnel eau"}>
            <Container maxWidth={'xl'}>
                <InstitutionalOrganizationalFrameworkStructureTypeHeaderBreadcrumbs
                    heading="Types de structures de cadre institutionnel et organisationnel eau"
                    lastName={`${currentInstitutionalOrganizationalFrameworkStructureType?.name ?? "Types de structures de cadre institutionnel et organisationnel eau"}`}

                    action={<InstitutionalOrganizationalFrameworkStructureTypeMoreMenu id={Number(id)} onDelete={onDelete} hiddenDisplay/>}
                />

                {loading && <CircularProgress/>}
                {!loading && currentInstitutionalOrganizationalFrameworkStructureType && (
                    <TabContext value={tapIndex}>
                        <Box sx={{mb: 4, direction: 'row', display: 'flex', justifyContent: 'space-between'}}>
                            <TabList onChange={handleChangeTab}>
                                <Tab disableRipple value='info' label='Information'/>
                                {/*<Tab disableRipple value='regions' label='Regions'/>*/}
                            </TabList>
                        </Box>


                        <Card>
                            <TabPanel value='info'>
                                <Box sx={{p: 0, minHeight: '80%'}}>

                                    <Stack spacing={3}>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label='Nom'
                                                value={currentInstitutionalOrganizationalFrameworkStructureType?.name}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label="Source de donnée"
                                                value={currentInstitutionalOrganizationalFrameworkStructureType?.dataSource}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                 multiline minRows={2} maxRows={6}  label="Description"
                                                value={currentInstitutionalOrganizationalFrameworkStructureType?.description}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>

                                    </Stack>

                                </Box>

                            </TabPanel>

                            {/*<TabPanel value='regions' sx={{p: 0}}>
                                <RegionTable institutionalOrganizationalFrameworkStructureType={currentInstitutionalOrganizationalFrameworkStructureType.id} currentInstitutionalOrganizationalFrameworkStructureType={currentInstitutionalOrganizationalFrameworkStructureType}/>
                            </TabPanel>*/}
                        </Card>

                    </TabContext>
                )}
            </Container>
        </Page>
    );
}
