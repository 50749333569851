import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const TypeActorList = Loadable(lazy(() => import("./pages/TypeActorList")));
const TypeActorCreate = Loadable(lazy(() => import("./pages/TypeActorCreate")));
const TypeActorEdit = Loadable(lazy(() => import("./pages/TypeActorEdit")));
const TypeActorDisplay = Loadable(
  lazy(() => import("./pages/TypeActorDisplay"))
);

const typeActorRouter = [
  { path: "type-actors", element: <TypeActorList /> },
  { path: "type-actors/:id", element: <TypeActorDisplay /> },
  { path: "type-actors/new", element: <TypeActorCreate /> },
  { path: "type-actors/:id/edit", element: <TypeActorEdit /> },
];

export default typeActorRouter;
