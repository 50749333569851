import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {Form, FormikProvider, useFormik} from 'formik';
import {useMessage} from '../../../../shared/hooks/useMessage';
// material
import {Card, Stack, Box} from '@mui/material';
// routes
import {PATH_ADMINISTRATIVE_DIVISION} from '../../administrative_division.path';
import {
    FormikAutocomplete,
    FormikTextField, FormRowStack, SubmitCancelGroupButton
} from '../../../../shared/components/froms/FormComponents';
import {setRefresh} from '../services/commune.gql';
import useCommuneService from '../services/commune.services';
import {useSearchCircles} from '../../circle/services/circle.gql';
import {CircleCreateDialogMenuItem} from '../../circle/components';
import {InitialCommune, CommuneSchema} from '../services/commune.store';
import {formErrorMessageParser} from '../../../../utils/utils';
import {useConnectFormState} from '../../../../shared/hooks/useFormikState';
import {useSearchStatusCommunes} from "../../status_commune/services/status.commune.gql";
import {StatusCommuneCreateDialogMenuItem} from "../../status_commune/components";
// ----------------------------------------------------------------------

CommuneForm.propTypes = {
    isEdit: PropTypes.bool,
    currentCommune: PropTypes.object,
    fromDialog: PropTypes.object,
};

export default function CommuneForm({isEdit, currentCommune, setCurrentCommune, fromDialog}) {
    const navigate = useNavigate();
    const {create, update} = useCommuneService();
    const {showSuccess, showError} = useMessage();

    const initCircleQuery = currentCommune?.circleObj?.name ?? fromDialog?.circle?.name ?? '';
    const [circleQuery, setCircleQuery] = useState(initCircleQuery);
    const {circles, circleLoading} = useSearchCircles({circleQuery});

    const initStatusCommuneQuery = currentCommune?.statusCommuneObj?.name ?? fromDialog?.statusCommune?.name ?? '';
    const [statusCommuneQuery, setStatusCommuneQuery] = useState(initStatusCommuneQuery);
    const {statusCommunes, statusCommuneLoading} = useSearchStatusCommunes({statusCommuneQuery});


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: InitialCommune(),
        validationSchema: CommuneSchema,
        onSubmit: async (commune, {setSubmitting}) => {
            try {
                commune = CommuneSchema.cast(commune);

                let message;
                if (!isEdit) {
                    commune = await create(commune);
                    message = 'Création avec succès';
                } else {
                    commune = await update(currentCommune.id, commune);
                    message = 'Modification avec succès';
                }

                setRefresh(true);

                setCurrentCommune();

                setSubmitting(false);
                showSuccess(message);

                if (fromDialog) {
                    fromDialog.onSave(commune);
                } else {
                    navigate(`${PATH_ADMINISTRATIVE_DIVISION.communes}/${commune.id}`);
                }
            } catch (error) {
                setSubmitting(false);
                showError(formErrorMessageParser({error, isEdit}));
            }
        }
    });

    const {values, setValues, handleSubmit, isSubmitting, setFieldValue} = formik;

    useConnectFormState({
        formValues: values, setFormValues: setValues,
        stateValues: currentCommune, setStateValues: setCurrentCommune,
    });

    const onCancel = (_) => {
        setCurrentCommune();
        if (fromDialog) {
            fromDialog.onCancel();
        } else {
            navigate(PATH_ADMINISTRATIVE_DIVISION.communes);
        }
    }

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Card sx={{p: 3}}>
                    <Stack spacing={3}>
                        <FormikTextField label='Nom' name='name' formik={formik}/>
                        <FormRowStack>
                            <FormikAutocomplete
                                name='statusCommune'
                                label="Statut commune"
                                options={statusCommunes}
                                // embeddedObjField='statusCommuneObj'
                                {...{query: statusCommuneQuery, loading: statusCommuneLoading, setQuery: setStatusCommuneQuery}}
                            />
                            <Box sx={{py: 1, ml: 1}}>
                                <StatusCommuneCreateDialogMenuItem btn onSave={(createdStatusCommune) => {
                                    currentCommune.statusCommuneObj = createdStatusCommune;
                                    setStatusCommuneQuery(createdStatusCommune?.name ?? '');
                                    setFieldValue('statusCommune', createdStatusCommune?.id);
                                }}/>
                            </Box>

                            <FormikAutocomplete
                                name='circle'
                                label='Cercle'
                                options={circles}
                                // embeddedObjField='circleObj'
                                {...{query: circleQuery, loading: circleLoading, setQuery: setCircleQuery}}
                            />
                            <Box sx={{py: 1, ml: 1}}>
                                <CircleCreateDialogMenuItem btn onSave={(createdCircle) => {
                                    currentCommune.circleObj = createdCircle;
                                    setCircleQuery(createdCircle?.name ?? '');
                                    setFieldValue('circle', createdCircle?.id);
                                }}/>
                            </Box>
                        </FormRowStack>
                        <SubmitCancelGroupButton {...{isEdit, isSubmitting, onCancel}}/>
                    </Stack>
                </Card>
            </Form>
        </FormikProvider>
    );
}
