import React from 'react';
import {Link as RouterLink} from 'react-router-dom';

import {Refresh, Search} from '@mui/icons-material';
import {
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    Toolbar,
    OutlinedInput,
    InputAdornment,
    Box, Tooltip, Typography, Link
} from '@mui/material';

import {useSearchLocalWaterDepartments} from '../services/local.water.department.gql';
import useLocalWaterDepartmentService from '../services/local.water.department.services';
import {PATH_WATER_USE} from '../../water.use.path';
import {LocalWaterDepartmentMoreMenu} from './index';
import {MyTablePagination, SortableTableHead, usePagination} from '../../../../shared/components/tables';
import {useDelete} from '../../../../shared/hooks/useDelete';
import {sxTableRow, sxTableToolbar} from "../../../../shared/components/tables/components.table.utils";
import {PATH_ADMINISTRATIVE_DIVISION} from "../../../administrative_division/administrative_division.path";

const TABLE_HEAD = [
    {id: 'id', label: 'ID', alignRight: false},
    {id: 'name', label: 'Nom', alignRight: false},
    {id: 'localityObj', label: 'Localité', alignRight: false},
    {id: 'description', label: 'Description', alignRight: false},
    {id: ''}
];

export default function LocalWaterDepartmentTable({localWaterSepartment}) {
    const {
        page, order, filterName, rowsPerPage, orderBy, handleRequestSort,
        handleChangePage, handleChangeRowsPerPage, handleFilterByName
    } = usePagination();

    const {localWaterDepartments, refetch, localWaterDepartmentPage} = useSearchLocalWaterDepartments({
        query: filterName,
        size: rowsPerPage,
        page,localWaterSepartment
    });

    const {destroy} = useLocalWaterDepartmentService();
    const {onDelete} = useDelete({
        destroyFn: destroy, onSuccess: refetch
    });

    return (
        <>
            <Toolbar sx={sxTableToolbar}>
                <OutlinedInput
                    sx={{width: 240}}
                    value={filterName}
                    onChange={handleFilterByName}
                    placeholder='Recherche service local eau'
                    startAdornment={
                        <InputAdornment position='start'>
                            <Box component={Search} sx={{color: 'text.disabled'}}/>
                        </InputAdornment>
                    }
                />

                <Box>
                    <Tooltip title='Actualiser'>
                        <IconButton onClick={() => refetch()}>
                            <Refresh/>
                        </IconButton>
                    </Tooltip>
                </Box>
            </Toolbar>
            <TableContainer sx={{minWidth: 800}}>
                <Table size='small'>
                    <SortableTableHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={localWaterDepartments.length}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {localWaterDepartments.map((row) => (<Row key={row.id} {...{row, onDelete}}/>))}
                    </TableBody>
                </Table>
            </TableContainer>

            <MyTablePagination
                page={localWaterDepartmentPage}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
};

function Row({row, onDelete}) {
    const {id, name, localityObj, description} = row;

    return (
        <TableRow hover sx={sxTableRow}>
            <TableCell component='th' scope='row'>
                <Link
                    to={PATH_WATER_USE.localWaterDepartments + '/' + id}
                    component={RouterLink}
                >
                    <Typography variant='body2' noWrap>
                        {id}
                    </Typography>
                </Link>
            </TableCell>

            <TableCell component='th' scope='row'>
                <Link
                    to={PATH_WATER_USE.localWaterDepartments + '/' + id}
                    component={RouterLink}
                >
                    <Typography variant='body2' noWrap>
                        {name}
                    </Typography>
                </Link>
            </TableCell>
            <TableCell component='th' scope='row'>
                <Link
                    to={PATH_ADMINISTRATIVE_DIVISION.localities + '/' + localityObj?.id}
                    component={RouterLink}
                >
                    <Typography
                        variant='body2' noWrap>
                        {localityObj?.name}
                    </Typography>
                </Link>
            </TableCell>
            <TableCell component='th' scope='row'>
                <Link
                    to={PATH_WATER_USE.localWaterDepartments + '/' + id}
                    component={RouterLink}
                >
                    <Typography variant='body2' noWrap>
                        {description}
                    </Typography>
                </Link>
            </TableCell>
            <TableCell align='right'>
                <LocalWaterDepartmentMoreMenu id={id} onDelete={onDelete} currentLocalWaterDepartment={row} isIconButton hiddenNew/>
            </TableCell>
        </TableRow>
    );
}
