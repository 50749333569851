import React from "react";
// material
import { Typography } from "@mui/material";
// components
import CreateDialogMenuItem from "../../../../shared/components/CreateDialogMenuItem";
import {LegislativeTextForm, LegislativeTextOccurrenceForm} from "./index";
// ----------------------------------------------------------------------

export function LegislativeTextOccurrenceCreateDialogMenuItem({
                                                                   legislativeText,
                                                                   riskManagementToolApplicationDegree,
                                                                   dataRefYear,
                                                                   onClose,
                                                                   onSave,
                                                                   onClick,
                                                                   btn,
                                                               }) {
    const setCurrentLegislativeTextOccurrence = () => {};
    const currentLegislativeTextOccurrence = {
        riskManagementToolApplicationDegree: riskManagementToolApplicationDegree?.id,
        riskManagementToolApplicationDegreeObj: riskManagementToolApplicationDegree,

        dataRefYear: dataRefYear?.id,
        dataRefYearObj: dataRefYear,

        legislativeText: legislativeText?.id,
        legislativeTextObj: legislativeText,
    };

    return (
        <CreateDialogMenuItem
            btn={btn}
            onClose={onClose}
            onClick={onClick}
            menuText="Ajouter une occurrence d'état d'application des textes du cadre cadre législatif et reglementaire eau"
            dialogTitle="Création de l'occurrence d'état d'application des textes du cadre cadre législatif et reglementaire eau"
            dialogContextText={<>{`Creation de nouvelle occurrences d'état d'application des textes du cadre cadre législatif et reglementaire eau`}</>}
            formRender={({ onClose }) => {
                return (
                    <LegislativeTextOccurrenceForm
                        {...{currentLegislativeTextOccurrence, setCurrentLegislativeTextOccurrence,
                        }}
                        fromDialog={{
                            onCancel: onClose,
                            onSave: (createdLegislativeTextOccurrence) => {
                                onSave && onSave(createdLegislativeTextOccurrence);
                                onClose && onClose();
                            },
                        }}
                    />
                );
            }}
        />
    );
}
