import React from 'react';
// material
import BuildIcon from '@mui/icons-material/Build';
// utils
import {PATH_RISK_PROTECTION} from '../../risk.protection.path';
import {useCountRiskManagementTools} from '../services/risk.management.tool.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function RiskManagementToolEntryButton() {
    const {count} = useCountRiskManagementTools();
  return (
      <SubModuleEntryButton
          label="Outils de gestion de risques liés à l'eau"
          value={count}
          url={PATH_RISK_PROTECTION.riskManagementTools}
          icon={<BuildIcon />}
      />
  );
}
