import React, {lazy} from "react";
import {Navigate} from "react-router-dom";

import MainLayout from "../../home/AppLayout";
import Loadable from "../../shared/components/Loadable";

import {PATH_IRRIGATION_NEED_WATER} from "./irrigation.need.water.path";
import bloliqueWaterRequirementRouter from "./blolique_water_requirement/blolique.water.requirement.router";
import culturalSpeculationEntitiesRouter from "./cultural_speculation_entities/cultural.speculation.entities.router";
import hydroAgriculturalDevelopmentEntitiesRouter
    from "./hydro_agricultural_development_entities/hydro.agricultural.development.entities.router";
import irrigatedDevelopmentEntitiesRouter from "./irrigated_development_entities/irrigated.development.entities.router";
import occurrencesOfWaterNeedRouter from "./occurrences_of_water_need/occurrences.of.water.need.router";
import ahaTypeRouter from "./aha_type/aha.type.router";

const IrrigationNeedWaterHome = Loadable(
    lazy(() => import("./IrrigationNeedWaterHome"))
);

const irrigationNeedWaterRouter = [
    {
        path: PATH_IRRIGATION_NEED_WATER.root,
        element: <MainLayout/>,
        children: [
            {
                path: PATH_IRRIGATION_NEED_WATER.root,
                element: <Navigate to={PATH_IRRIGATION_NEED_WATER.home} replace/>,
            },
            {path: "home", element: <IrrigationNeedWaterHome/>},
            ...occurrencesOfWaterNeedRouter,
            ...irrigatedDevelopmentEntitiesRouter,
            ...hydroAgriculturalDevelopmentEntitiesRouter,
            ...culturalSpeculationEntitiesRouter,
            ...bloliqueWaterRequirementRouter,
            ...ahaTypeRouter
        ],
    },
];

export default irrigationNeedWaterRouter;
