import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';

import MainLayout from '../../home/AppLayout';
import Loadable from '../../shared/components/Loadable';

import {PATH_DEGRADATION_PROTECTION} from './degradation.protection.path';
import typeDegradationFactorRouter from "./type_degradation_factor/type.degradation.factor.router";
import typeAggressionFactorRouter from "./type_aggression_factor/type.aggression.factor.router";
import typeDevelopmentActionRouter from "./type_development_action/type.development.action.router";
import occurrenceDegradationFactorRouter from "./occurrence_degradation_factor/occurrence.degradation.factor.router";
import occurrenceAggressionRouter from "./occurrence_aggression/occurrence.aggression.router";
import typeWasteWaterRouter from "./type_waste_water/type.waste.water.router";
import wasteWaterDischargeRouter from "./waste_water_discharge/waste.water.discharge.router";
import occurrenceDevelopmentActionRouter from "./occurrence_development_action/occurrence.development.action.router";
import occurrenceEnvironmentalRouter from "./occurrence_environmental/occurrence.environmental.router";
import productionWasteWaterRouter from "./production_waste_water/production.waste.water.router";




const DegradationProtectionHome = Loadable(lazy(() => import('./DegradationProtectionHome')));

const degradationProtectionRouter = [{
    path: PATH_DEGRADATION_PROTECTION.root,
    element: <MainLayout/>,
    children: [
        {path: PATH_DEGRADATION_PROTECTION.root, element: <Navigate to={PATH_DEGRADATION_PROTECTION.home} replace/>},
        {path: 'home', element: <DegradationProtectionHome/>},

        ...typeDegradationFactorRouter,
        ...typeAggressionFactorRouter,
        ...typeDevelopmentActionRouter,
        ...occurrenceDegradationFactorRouter,
        ...occurrenceAggressionRouter,
        ...typeWasteWaterRouter,
        ...wasteWaterDischargeRouter,
        ...occurrenceDevelopmentActionRouter,
        ...occurrenceEnvironmentalRouter,
        ...productionWasteWaterRouter,

    ]
},];

export default degradationProtectionRouter;
