import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const GireManagementToolList = Loadable(
  lazy(() => import("./pages/GireManagementToolList"))
);
const GireManagementToolCreate = Loadable(
  lazy(() => import("./pages/GireManagementToolCreate"))
);
const GireManagementToolEdit = Loadable(
  lazy(() => import("./pages/GireManagementToolEdit"))
);
const GireManagementToolDisplay = Loadable(
  lazy(() => import("./pages/GireManagementToolDisplay"))
);

const gireManagementToolRouter = [
  {
    path: "gire-managment-tools",
    element: <GireManagementToolList />,
  },
  {
    path: "gire-managment-tools/:id",
    element: <GireManagementToolDisplay />,
  },
  {
    path: "gire-managment-tools/new",
    element: <GireManagementToolCreate />,
  },
  {
    path: "gire-managment-tools/:id/edit",
    element: <GireManagementToolEdit />,
  },
];

export default gireManagementToolRouter;
