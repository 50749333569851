import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';

const OccurrenceAgriculturalProductionList = Loadable(lazy(() => import('./pages/OccurrenceAgriculturalProductionList')));
const OccurrenceAgriculturalProductionCreate = Loadable(lazy(() => import('./pages/OccurrenceAgriculturalProductionCreate')));
const OccurrenceAgriculturalProductionEdit = Loadable(lazy(() => import('./pages/OccurrenceAgriculturalProductionEdit')));
const OccurrenceAgriculturalProductionDisplay = Loadable(lazy(() => import('./pages/OccurrenceAgriculturalProductionDisplay')));

const occurrenceAgriculturalProductionRouter = [
    {path: 'occurrence-agricultural-productions', element: <OccurrenceAgriculturalProductionList/>},
    {path: 'occurrence-agricultural-productions/:id', element: <OccurrenceAgriculturalProductionDisplay/>},
    {path: 'occurrence-agricultural-productions/new', element: <OccurrenceAgriculturalProductionCreate/>},
    {path: 'occurrence-agricultural-productions/:id/edit', element: <OccurrenceAgriculturalProductionEdit/>},
];
export default occurrenceAgriculturalProductionRouter;
