import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const IrrigatedDevelopmentEntitiesList = Loadable(
  lazy(() => import("./pages/IrrigatedDevelopmentEntitiesList"))
);
const IrrigatedDevelopmentEntitiesCreate = Loadable(
  lazy(() => import("./pages/IrrigatedDevelopmentEntitiesCreate"))
);
const IrrigatedDevelopmentEntitiesEdit = Loadable(
  lazy(() => import("./pages/IrrigatedDevelopmentEntitiesEdit"))
);
const IrrigatedDevelopmentEntitiesDisplay = Loadable(
  lazy(() => import("./pages/IrrigatedDevelopmentEntitiesDisplay"))
);

const irrigatedDevelopmentEntitiesRouter = [
  {
    path: "irrigated-development-entities",
    element: <IrrigatedDevelopmentEntitiesList />,
  },
  {
    path: "irrigated-development-entities/:id",
    element: <IrrigatedDevelopmentEntitiesDisplay />,
  },
  {
    path: "irrigated-development-entities/new",
    element: <IrrigatedDevelopmentEntitiesCreate />,
  },
  {
    path: "irrigated-development-entities/:id/edit",
    element: <IrrigatedDevelopmentEntitiesEdit />,
  },
];

export default irrigatedDevelopmentEntitiesRouter;
