import React from "react";
import { HealthAreaForm } from "../components";
import CreateDialogMenuItem from "../../../../shared/components/CreateDialogMenuItem";
import {useHealthAreaStore} from "../services/health.area.store";

export function HealthAreaCreateDialogMenuItem({
  onClose,
  onSave,
  onClick,
  btn,
}) {
  const {
    currentNewHealthArea: currentHealthArea,
    setCurrentNewHealthArea: setCurrentHealthArea,
  } = useHealthAreaStore();
  return (
    <CreateDialogMenuItem
      btn={btn}
      onClose={onClose}
      onClick={onClick}
      menuText="Ajouter une entité aires de santé"
      dialogTitle="Création d'une entité aires de santé"
      dialogContextText={<>Création de nouveau type entité aires de santé</>}
      formRender={({ onClose }) => {
        return (
          <HealthAreaForm
            {...{ currentHealthArea, setCurrentHealthArea }}
            fromDialog={{
              onCancel: onClose,
              onSave: (createdHealthArea) => {
                onSave && onSave(createdHealthArea);
                onClose && onClose();
              },
            }}
          />
        );
      }}
    />
  );
}
