import React, {lazy} from 'react';
import AhpTypeList from "./pages/AhpTypeList";
import AhpTypeDisplay from "./pages/AhpTypeDisplay";
import AhpTypeCreate from "./pages/AhpTypeCreate";
import AhpTypeEdit from "./pages/AhpTypeEdit";

const ahpTypeRouter = [
    {path: 'ahp-types', element: <AhpTypeList/>},
    {path: 'ahp-types/:id', element: <AhpTypeDisplay/>},
    {path: 'ahp-types/new', element: <AhpTypeCreate/>},
    {path: 'ahp-types/:id/edit', element: <AhpTypeEdit/>},
];

export default ahpTypeRouter;
