import React from 'react';
// material
import {Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {LegislativeTextTypeForm} from '../components';

import {LegislativeTextTypeHeaderBreadcrumbs} from '../components';
import {useLegislativeTextTypeStore} from "../services/legislative.text.type.store";

// ----------------------------------------------------------------------

export default function LegislativeTextTypeCreate() {
    const {currentNewLegislativeTextType: currentLegislativeTextType, setCurrentNewLegislativeTextType: setCurrentLegislativeTextType} = useLegislativeTextTypeStore();
    return (
        <Page title="Gire environnement favorable: Création types textes de cadre législatif et reglementaire eau">
            <Container  maxWidth={'xl'}>
                <LegislativeTextTypeHeaderBreadcrumbs
                    heading="Création de nouveau type textes de cadre législatif et reglementaire eau"
                    lastName="nouveau type textes de cadre législatif et reglementaire eau"
                />
                <LegislativeTextTypeForm {...{currentLegislativeTextType, setCurrentLegislativeTextType}}/>
            </Container>
        </Page>
    );
}
