import React from 'react';
import {VpnLockRounded} from '@mui/icons-material';
import {path} from '../../home/sidebar/paths';

const GIRE_ENV_FAVORABLE = '/gire-env-favorable';
const _path = path(GIRE_ENV_FAVORABLE);
export const PATH_GIRE_ENV_FAVORABLE = {
    root: GIRE_ENV_FAVORABLE,
    home: _path('/home'),

    instrumentPolicyTypes: _path("/instrument-policy-types"),
    instrumentPolicies: _path("/instrument-policies"),
    instrumentPolicyOccurrences: _path("/instrument-policy-occurrences"),

    institutionalOrganizationalFrameworkStructureTypes: _path("/institutional-organizational-framework-structure-types"),
    institutionalOrganizationalFrameworkStructures: _path("/institutional-organizational-framework-structures"),
    institutionalOrganizationalFrameworkStructureOccurrences: _path("/institutional-organizational-framework-structure-occurrences"),

    legislativeTextTypes: _path("/legislative-text-types"),
    legislativeTexts: _path("/legislative-texts"),
    legislativeTextOccurrences: _path("/legislative-text-occurrences"),

    participationAreaAndTypes: _path("/participation-area-and-types"),

    instrumentMechanismParticipationTypes: _path("/instrument-mechanism-participation-types"),
    instrumentMechanismParticipations: _path("/instrument-mechanism-participations"),
    instrumentParticipationOccurrences: _path("/instrument-mechanism-participation-occurrences"),

};

export const gireEnvFavorableMenu = [
    {
        title: 'GIRE Environement favorable',
        path: PATH_GIRE_ENV_FAVORABLE.root,
        icon: <VpnLockRounded/>,
        children: [
            {title: 'Accueil', path: PATH_GIRE_ENV_FAVORABLE.home},
            {
                title: 'Types d\'instruments de politique et stratégie',
                path: PATH_GIRE_ENV_FAVORABLE.instrumentPolicyTypes
            },
            {
                title: 'Instruments de politique et stratégie eau',
                path: PATH_GIRE_ENV_FAVORABLE.instrumentPolicies
            },
            {
                title: 'Types de structure de cadre institutionnel',
                path: PATH_GIRE_ENV_FAVORABLE.institutionalOrganizationalFrameworkStructureTypes
            },
            {
                title: 'Structures de cadre institutionnel',
                path: PATH_GIRE_ENV_FAVORABLE.institutionalOrganizationalFrameworkStructures
            },
            {
                title: 'Types et domaine de participation',
                path: PATH_GIRE_ENV_FAVORABLE.participationAreaAndTypes
            },
            {
                title: "Types d'instrument/mécanisme de participation",
                path: PATH_GIRE_ENV_FAVORABLE.instrumentMechanismParticipationTypes
            },
            {
                title: 'Instruments/mécanismes de participation',
                path: PATH_GIRE_ENV_FAVORABLE.instrumentMechanismParticipations
            },
            {
                title: 'Types textes de cadre législatif',
                path: PATH_GIRE_ENV_FAVORABLE.legislativeTextTypes
            },
            {
                title: 'Textes du cadre législatif',
                path: PATH_GIRE_ENV_FAVORABLE.legislativeTexts
            },

            // {
            //     // title: 'Occurrences d\'état de mise en œuvre des instruments de politique et stratégie eau',
            //     title: 'Occurrences d\'état de mise en œuvre des instruments',
            //     path: PATH_GIRE_ENV_FAVORABLE.instrumentPolicyOccurrences
            // },
            // {
            //     // title: 'Occurrences d\'état de dynamisme du cadre institutionnel et organisationnel',
            //     title: 'Occurrences d\'état de dynamisme',
            //     path: PATH_GIRE_ENV_FAVORABLE.institutionalOrganizationalFrameworkStructureOccurrences
            // },
            // {
            //     // title: 'Occurrences d\'état de mise en œuvre des instruments et mécanismes de participation à la GIRE',
            //     title: 'Occurrences d\'état de mise en œuvre',
            //     path: PATH_GIRE_ENV_FAVORABLE.instrumentParticipationOccurrences
            // },
            //
            // {
            //     // title: 'Occurrences d\'état d\'application des textes du cadre cadre législatif et reglementaire eau',
            //     title: 'Occurrences d\'état d\'application',
            //     path: PATH_GIRE_ENV_FAVORABLE.legislativeTextOccurrences
            // },

        ]
    }
];

export const commonItems = [
    {name: "Accueil", href: "/"},
    {name: "Gire Env Favorable", href: PATH_GIRE_ENV_FAVORABLE.home},
];
