import React, {useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
// material
import {CircularProgress, Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {AmenagementTypeForm} from '../components';

import {AmenagementTypeHeaderBreadcrumbs} from '../components';

import {PATH_RISK_PROTECTION} from '../../risk.protection.path';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useAmenagementType} from '../services/amenagement.type.gql';
import {useAmenagementTypeStore} from "../services/amenagement.type.store";

// ----------------------------------------------------------------------

export default function AmenagementTypeEdit() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {showError} = useMessage();

    const {setCurrentUpdateAmenagementType: setCurrentAmenagementType} = useAmenagementTypeStore();

    const {entity: currentAmenagementType, error, loading, errorMessage} = useAmenagementType(id);

    useEffect(() => {
        if (loading) return;
        if (error) {
            showError(errorMessage);
            navigate(PATH_RISK_PROTECTION.amenagementTypes);
        }
    }, [loading, error, errorMessage]);

    useEffect(() => {
        setCurrentAmenagementType(currentAmenagementType);
    }, [currentAmenagementType]);

    return (
        <Page title={'Aménagement et protection en RE: Modification type de aménagement'}>
            <Container maxWidth={'xl'}>
                <AmenagementTypeHeaderBreadcrumbs
                    heading='Modification du type de aménagement'
                    lastName={`Modification type de aménagement - ${id}`}

                />

                {loading && <CircularProgress/>}
                {!loading && currentAmenagementType && <AmenagementTypeForm isEdit {...{currentAmenagementType, setCurrentAmenagementType}}/>}
            </Container>
        </Page>
    );
}
