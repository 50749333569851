import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const DrinkingWaterQualityAndPollutionControlStationsList = Loadable(lazy(() => import("./pages/DrinkingWaterQualityAndPollutionControlStationsList")));
const DrinkingWaterQualityAndPollutionControlStationsCreate = Loadable(lazy(() => import("./pages/DrinkingWaterQualityAndPollutionControlStationsCreate")));
const DrinkingWaterQualityAndPollutionControlStationsEdit = Loadable(lazy(() => import("./pages/DrinkingWaterQualityAndPollutionControlStationsEdit")));
const DrinkingWaterQualityAndPollutionControlStationsDisplay = Loadable(lazy(() => import("./pages/DrinkingWaterQualityAndPollutionControlStationsDisplay")));

const drinkingWaterQualityAndPollutionControlStationsRouter = [
  { path: "drinking-water-quality-and-pollution-control-stations", element: <DrinkingWaterQualityAndPollutionControlStationsList /> },
  { path: "drinking-water-quality-and-pollution-control-stations/:id", element: <DrinkingWaterQualityAndPollutionControlStationsDisplay /> },
  { path: "drinking-water-quality-and-pollution-control-stations/new", element: <DrinkingWaterQualityAndPollutionControlStationsCreate /> },
  { path: "drinking-water-quality-and-pollution-control-stations/:id/edit", element: <DrinkingWaterQualityAndPollutionControlStationsEdit /> },
];

export default drinkingWaterQualityAndPollutionControlStationsRouter;

