import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const WaterMassSubsetList = Loadable(lazy(() => import('./pages/WaterMassSubsetList')));
const WaterMassSubsetCreate = Loadable(lazy(() => import('./pages/WaterMassSubsetCreate')));
const WaterMassSubsetEdit = Loadable(lazy(() => import('./pages/WaterMassSubsetEdit')));
const WaterMassSubsetDisplay = Loadable(lazy(() => import('./pages/WaterMassSubsetDisplay')));


const waterMassSubsetRouter = [
    {path: 'water-mass-subsets', element: <WaterMassSubsetList/>},
    {path: 'water-mass-subsets/:id', element: <WaterMassSubsetDisplay/>},
    {path: 'water-mass-subsets/new', element: <WaterMassSubsetCreate/>},
    {path: 'water-mass-subsets/:id/edit', element: <WaterMassSubsetEdit/>},
];

export default waterMassSubsetRouter;
