import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const AreaCompetenceOrganList = Loadable(lazy(() => import('./pages/AreaCompetenceOrganList')));
const AreaCompetenceOrganCreate = Loadable(lazy(() => import('./pages/AreaCompetenceOrganCreate')));
const AreaCompetenceOrganEdit = Loadable(lazy(() => import('./pages/AreaCompetenceOrganEdit')));
const AreaCompetenceOrganDisplay = Loadable(lazy(() => import('./pages/AreaCompetenceOrganDisplay')));

const areaCompetenceOrganRouter = [
    {path: 'area-competence-organs', element: <AreaCompetenceOrganList/>},
    {path: 'area-competence-organs/:id', element: <AreaCompetenceOrganDisplay/>},
    {path: 'area-competence-organs/new', element: <AreaCompetenceOrganCreate/>},
    {path: 'area-competence-organs/:id/edit', element: <AreaCompetenceOrganEdit/>},
];

export default areaCompetenceOrganRouter;
