import React from 'react';
// material
import {Typography} from '@mui/material';
// components
import {CircleForm} from '../components';
import CreateDialogMenuItem from '../../../../shared/components/CreateDialogMenuItem';

// ----------------------------------------------------------------------

export function CircleCreateDialogMenuItem({region, onClose, onSave, onClick, btn}) {
    const setCurrentCircle = () => {};
    const currentCircle = {region: region?.id, regionObj: region};
    return (
        <CreateDialogMenuItem
            btn={btn}
            onClose={onClose}
            onClick={onClick}
            menuText='Ajouter un cercle'
            dialogTitle='Création de cercle'
            dialogContextText={<>
                Création de nouveau cercle
                {region && <>
                    {` pour`} <Typography variant='h6' component={'span'}> {region.name}</Typography>
                </>}
            </>}
            formRender={({onClose}) => {
                return (
                    <CircleForm
                        {...{currentCircle, setCurrentCircle}}
                        fromDialog={{
                            region,
                            onCancel: onClose,
                            onSave: (createdCircle) => {
                                onSave && onSave(createdCircle);
                                onClose && onClose();
                            },
                        }}
                    />
                );
            }}
        />
    );

}
