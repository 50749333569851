import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';

import MainLayout from '../../home/AppLayout';
import Loadable from '../../shared/components/Loadable';

import {PATH_REPORTING} from './reporting.path';

//Features
import reportingRouter from './reporting/reporting/reporting.router';
import dashboardRouter from './dashboardd/dashboard/dashboard.router';
import widgetRouter from './dashboardd/widget/widget.router';
import testRouter from "./tests/tests.router";


const ReportingHome = Loadable(lazy(() => import('./ReportingHome')));

const reportingModuleRouter = [{
    path: PATH_REPORTING.root,
    element: <MainLayout/>,
    children: [
        {path: PATH_REPORTING.root, element: <Navigate to={PATH_REPORTING.home} replace/>},
        {path: 'home', element: <ReportingHome/>},

        ...reportingRouter,
        ...dashboardRouter,
        ...widgetRouter,
        ...testRouter,
    ]
},];

export default reportingModuleRouter;
