import React from "react";
import HeaderBreadcrumbs from "../../../../shared/components/HeaderBreadcrumbs";
import { PATH_WATER_USAGE_URIGATION } from "../../water.usage.irrigation.path";

const commonItems = [
  { name: "Accueil", href: "/" },
  { name: "Irrigation usage eau", href: PATH_WATER_USAGE_URIGATION.home },
];

export function IrrigatedDevelopmentEntityUsagesHeaderBreadcrumbs({
  heading,
  lastName,
  ...others
}) {
  return (
    <HeaderBreadcrumbs
      {...others}
      heading={heading}
      links={[
        ...commonItems,
        {
          name: "Entités d'Aménagements irrigués",
          href: PATH_WATER_USAGE_URIGATION.irrigatedDevelopmentEntityUsages,
        },
        { name: lastName },
      ]}
    />
  );
}

export function IrrigatedDevelopmentEntityUsagesListHeaderBreadcrumbs({
  heading,
  lastName,
  ...others
}) {
  return (
    <HeaderBreadcrumbs
      {...others}
      heading={heading}
      links={[...commonItems, { name: lastName }]}
    />
  );
}
