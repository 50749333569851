import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Link, Table, TableBody, TableCell, TableContainer, TableRow, Toolbar, Typography} from '@mui/material';
import useLocalityTypeService from '../services/locality_type.services';
import {useSearchLocalityTypes} from '../services/locality_type.gql';
import {MyTablePagination, SortableTableHead, usePagination} from '../../../../shared/components/tables';
import {LocalityTypeMoreMenu} from "./index";
import {PATH_ADMINISTRATIVE_DIVISION} from '../../administrative_division.path';
import {useDelete} from '../../../../shared/hooks/useDelete';
import {
    RefreshIconButton,
    sxTableToolbar, TableCellLink,
    TableSearchField
} from '../../../../shared/components/tables/components.table.utils';

const TABLE_HEAD = [
    { id: "id", label: "ID", alignRight: false },
    { id: "name", label: "Nom", alignRight: false },
    { id: "description", label: "Description", alignRight: false },
    { id: "" },
];

export default function LocalityTypeTable() {
    const {
        page, order, filterName, rowsPerPage, orderBy, handleRequestSort,
        handleChangePage, handleChangeRowsPerPage, handleFilterByName
    } = usePagination();

    const {localityTypes, refetch, localityTypePage} = useSearchLocalityTypes({
        query: filterName,
        size: rowsPerPage,
        page
    });

    const {destroy} = useLocalityTypeService();
    const {onDelete} = useDelete({
        destroyFn: destroy, onSuccess: refetch
    });

    return (
        <>
            <Toolbar sx={sxTableToolbar}>
                <TableSearchField value={filterName} placeholder='Recherche type' onChange={handleFilterByName}/>

                <RefreshIconButton onRefresh={refetch}/>
            </Toolbar>
            <TableContainer sx={{minWidth: 800}}>
                <Table size='small'>
                    <SortableTableHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {localityTypes.map((row) => (<Row key={row.id} {...{row, onDelete}}/>))}
                    </TableBody>
                </Table>
            </TableContainer>

            <MyTablePagination
                page={localityTypePage}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}

function Row({row, onDelete}) {
    const {id, name, description} = row;

    return (
        <TableRow hover sx={{'&:last-child td, &:last-child th': {border: 0}}}>
            <TableCell component='th' scope='row'>
                <TableCellLink to={PATH_ADMINISTRATIVE_DIVISION.localityTypes + '/' + id} value={id}/>
            </TableCell>
            <TableCell component="th" scope='row'>
                <TableCellLink to={PATH_ADMINISTRATIVE_DIVISION.localityTypes + '/' + id} value={name}/>
            </TableCell>
            <TableCell style={{minWidth: 160}}>
                <Typography variant="body2" noWrap>
                    {description}
                </Typography>
            </TableCell>
            <TableCell align="right">
                <LocalityTypeMoreMenu id={id} onDelete={onDelete} isIconButton hiddenNew/>
            </TableCell>
        </TableRow>
    );
}
