import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {useMessage} from '../../../../../shared/hooks/useMessage';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {Form, FormikProvider, useFormik} from 'formik';
// material
import {LoadingButton} from '@mui/lab';
import {
    Card, Stack, TextField, Box, Button
} from '@mui/material';
// routes
import {PATH_REPORTING} from '../../../reporting.path';
import {FormRowStack} from '../../../../../shared/components/froms/FormComponents';
import {setRefresh} from '../services/dashboard.gql';

import useDashboardService from '../services/dashboard.services';
// ----------------------------------------------------------------------

DashboardForm.propTypes = {
    isEdit: PropTypes.bool,
    currentDashboard: PropTypes.object,
    fromDialog: PropTypes.object,
};

export default function DashboardForm({isEdit, currentDashboard, fromDialog}) {
    const navigate = useNavigate();
    const {showSuccess, showError} = useMessage();
    const {create, update} = useDashboardService();

    const DashboardSchema = Yup.object().shape({
        title: Yup.string().required('Le libelle est obligatoire'),
        description: Yup.string(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: DashboardSchema,
        initialValues: {
            title: currentDashboard?.title || '',
            description: currentDashboard?.description || '',
        },
        onSubmit: async (dashboard, {setSubmitting, resetForm, setErrors}) => {
            try {
                dashboard = DashboardSchema.cast(dashboard);

                let message;
                if (!isEdit) {
                    await create(dashboard);
                    message = 'Création avec succès';
                } else {
                    await update(currentDashboard.id, dashboard);
                    message = 'Modification avec succès';
                }

                setRefresh(true);

                resetForm();

                setSubmitting(false);
                showSuccess(message);

                if (fromDialog) {
                    fromDialog.onSave();
                } else {
                    navigate(PATH_REPORTING.dashboard);
                }
            } catch (error) {
                console.error(error);
                setSubmitting(false);
                showError(!isEdit ? 'Erreur lors de la creation' : 'Erreur lors de la modification');
            }
        }
    });

    const onCancel = (_) => {
        if (fromDialog) {
            fromDialog.onCancel();
        } else {
            navigate(PATH_REPORTING.dashboard);
        }
    }

    const {errors, touched, handleSubmit, isSubmitting, getFieldProps} = formik;

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>

                <Card sx={{p: 3}}>

                    <Stack spacing={3}>

                        <FormRowStack>
                            <TextField
                                fullWidth
                                label='Titre'
                                {...getFieldProps('title')}
                                error={Boolean(touched.title && errors.title)}
                                helperText={touched.title && errors.title}
                            />
                        </FormRowStack>

                        <FormRowStack>
                            <TextField
                                fullWidth
                                multiline
                                minRows={2}
                                maxRows={6}
                                label='Description'
                                {...getFieldProps('description')}
                                error={Boolean(touched.description && errors.description)}
                                helperText={touched.description && errors.description}
                            />
                        </FormRowStack>

                        <Box sx={{mt: 3, display: 'flex', justifyContent: 'flex-end'}}>
                            <Button
                                sx={{mr: 2}}
                                size='medium'
                                onClick={onCancel}
                                disabled={isSubmitting}
                            >
                                {'Annuler'}
                            </Button>
                            <LoadingButton type='submit' variant='contained' size='medium' loading={isSubmitting}>
                                {!isEdit ? 'Créer' : 'Modifier'}
                            </LoadingButton>
                        </Box>
                    </Stack>

                </Card>

            </Form>
        </FormikProvider>
    );
}
