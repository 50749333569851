import React, {useEffect, useLayoutEffect, useState} from 'react';
import {LocationOn, LocationOnOutlined, Search} from '@mui/icons-material';
import {
    Box, Button, Dialog, DialogActions, DialogContent, IconButton,
    InputBase, Tooltip, Typography, alpha, useTheme
} from '@mui/material';
import GoogleMapReact from 'google-map-react';
import mapStyles from '../../../theme/mapStyles';
import {Autocomplete} from '@react-google-maps/api';
import GeoPoint from '../../../utils/geopoint';
import './GeoLocationPicker.css';

export const CENTER_COORDINATES = {lat: 12.6392316, lng: -8.0028892}

export default function GeoLocationPicker({location, onSelected, label, isIconButton, icon, title}) {

    const [open, setOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(null);

    useLayoutEffect(() => {
        if (!location) return;

        setSelectedLocation(location);
    }, [location]);


    return (
        <>
            {isIconButton && (
                <Tooltip title={label ?? 'Localisation'}>
                    <IconButton onClick={() => setOpen(true)}>
                        {icon ?? <LocationOn width={20} height={20}/>}
                    </IconButton>
                </Tooltip>
            )}
            {!isIconButton && (
                <Button
                    variant='contained'
                    startIcon={<LocationOn/>}
                    onClick={() => setOpen(true)}
                >
                    {label ?? 'Localisation'}
                </Button>
            )}
            <Dialog
                fullWidth
                maxWidth='md'
                open={open}
                onClose={() => setOpen(false)}
            >
                <Box
                    display='flex'
                    component='div'
                    flexDirection='row'
                    justifyItems='center'
                    justifyContent='space-between'
                    sx={{px: 3, pt: 3}}
                >
                    <Typography variant='h4'> {title ?? 'Geo Localisation'} </Typography>
                    <PlacesAutocomplete onSelected={setSelectedLocation}/>
                </Box>

                <DialogContent>
                    <Box component='div' height={500} width='100%'>
                        <GoogleMapReact
                            defaultZoom={14}
                            bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY}}
                            center={selectedLocation?.toLatLng() ?? CENTER_COORDINATES}
                            defaultCenter={CENTER_COORDINATES}
                            margin={[0, 0, 0, 0]}
                            options={{disableDefaultUI: true, zoomControl: true, styles: mapStyles}}
                            onChange={({center: {lng, lat}}) => {
                                setSelectedLocation(new GeoPoint({lng, lat}));
                            }}
                            onClick={({lat, lng}) => {
                                setSelectedLocation(new GeoPoint({lng, lat}));
                            }}
                        >
                            {selectedLocation && <Box
                                lat={Number(selectedLocation.lat)}
                                lng={Number(selectedLocation.lng)}
                                sx={{
                                    backgroundColor: true,
                                    height: 50,
                                    width: 50,
                                    position: 'absolute',
                                    transform: 'translate(-50%, -50%)',
                                    zIndex: 1,
                                    '&:hover': {zIndex: 2},
                                }}
                                onClick={() => {
                                    // setChildClicked(index);
                                }}
                            >
                                <LocationOnOutlined color='primary' fontSize='large'/>
                            </Box>}

                        </GoogleMapReact>
                    </Box>

                    {selectedLocation && (
                        <Box display='flex' flexDirection='row' justifyContent='end' mt={1}>
                            <Typography variant='body2' color='text.disabled'>
                                {selectedLocation.lat}, {selectedLocation.lng} ({selectedLocation.latDeg}, {selectedLocation.lngDeg})
                            </Typography>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>{'Annuler'}</Button>
                    <Button
                        autoFocus
                        disabled={!selectedLocation}
                        onClick={() => {
                            onSelected && onSelected(selectedLocation);
                            setOpen(false);
                        }}
                    >
                        {'Valider'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export function PlacesAutocomplete({onSelected}) {
    const theme = useTheme();
    const [autocomplete, setAutocomplete] = useState(null);

    const onPlaceChanged = () => {
        if (!onSelected) return;

        const place = autocomplete.getPlace();
        const {location} = place.geometry;
        const lat = location.lat();
        const lng = location.lng();

        onSelected(new GeoPoint({lng, lat}));
    };

    useEffect(() => () => {
        //Remove Autocomplete menu when unmount
        try {
            const els = document.getElementsByClassName('pac-container');
            for (let i = els.length - 1; i >= 0; i--) els[i].remove();
        } catch (e) {
            console.error(e);
        }
    }, []);

    return (
        <Autocomplete
            onLoad={setAutocomplete}
            onPlaceChanged={onPlaceChanged}
            onUnmount={() => {
                console.log(`Unmount Place Autocomplete`);
            }}
        >
            <Box
                display='flex'
                alignItems='center'
                sx={{
                    borderRadius: theme.shape.borderRadius,
                    backgroundColor: alpha(theme.palette.primary.main, 0.1),
                    '&:hover': {backgroundColor: alpha(theme.palette.primary.main, 0.15)},
                    [theme.breakpoints.up('sm')]: {ml: 3, width: 'auto'},
                }}
            >
                <Search sx={{color: 'inherit', ml: 1, fontSize: 23}}/>
                <InputBase
                    placeholder='Search...'
                    sx={{
                        ml: 1,
                        flexGrow: 1,
                        color: 'inherit',
                        transition: theme.transitions.create('width'),
                        [theme.breakpoints.up('md')]: {width: '20ch'},
                        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
                        padding: theme.spacing(1, 1, 1, 0),
                    }}
                />
            </Box>
        </Autocomplete>
    );
}
