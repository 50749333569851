import {useEffect, useState} from "react";
import {filterSidebarConfig} from "./search.utils";

export function useSearchItem(selectedTerms) {

    const [search, setSearch] = useState(selectedTerms);
    const [itemsCount, setItemsCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [filteredSidebarConfig, setFilteredSidebarConfig] = useState([]);

    useEffect(() => {
        const {sidebarConfig: filteredSidebarConfig, count, totalCount} = filterSidebarConfig(search);

        setItemsCount(count);
        setTotalCount(totalCount);
        setFilteredSidebarConfig(filteredSidebarConfig);

    }, [search]);

    return {
        search, setSearch, filteredSidebarConfig, itemsCount, totalCount
    }
}