import React, {lazy} from "react";
import {Navigate} from "react-router-dom";
import MainLayout from "../../home/AppLayout";
import Loadable from "../../shared/components/Loadable";
import {PATH_MANAGEMENT_INSTRUMENTS} from "./management.instruments.path";
import drinkingWaterQualityAndPollutionControlStationsRouter
    from "./drinking_water_quality_and_pollution_control_stations/drinking.water.quality.and.pollution.control.stations.router";
import evaluationOccurrencesRouter from "./evaluation_occurrences/evaluation.occurrences.router";
import featureOccurrencesOfObservationDevicesRouter
    from "./feature_occurrences_of_observation_devices/feature.occurrences.of.observation.devices.router";
import informationSupportProductionOccurrencesRouter
    from "./information_support_production_occurrences/information.support.production.occurrences.router";
import informationSupportsTypesRouter from "./information_supports_types/information.supports.types.router";
import observationStationFunctionalityOccurrencesRouter
    from "./observation_station_functionality_occurrences/observation.station.functionality.occurrences.router";
import productionOfInformationSupportsRouter
    from "./production_of_information_supports/production.of.information.supports.router";
import quantitativeObservationAndQualityControlStationsRouter
    from "./quantitative_observation_and_quality_control_stations/quantitative.observation.and.quality.control.stations.router";
import stationReferenceStatusRouter from "./station_reference_status/station.reference.status.router";
import typesObservationDeviceRouter from "./types_observation_device/types.observation.device.router";
import evaluationToolsRouter from "./evaluation_tools/evaluation.tools.router";
import evaluationToolsTypeRouter from "./evaluation_tools_type/evaluation.tools.type.router";

const ManagementInstrumentsHome = Loadable(lazy(() => import("./ManagementInstrumentsHome")));

const managementInstrumentsRouter = [
    {
        path: PATH_MANAGEMENT_INSTRUMENTS.root,
        element: <MainLayout/>,
        children: [  
            {
                path: PATH_MANAGEMENT_INSTRUMENTS.root,
                element: <Navigate to={PATH_MANAGEMENT_INSTRUMENTS.home} replace/>,
            },
            {path: "home", element: <ManagementInstrumentsHome/>},
            ...evaluationToolsRouter,
            ...evaluationToolsTypeRouter,
            ...evaluationOccurrencesRouter,
            ...typesObservationDeviceRouter,
            ...stationReferenceStatusRouter,
            ...informationSupportsTypesRouter,
            ...productionOfInformationSupportsRouter,
            ...featureOccurrencesOfObservationDevicesRouter,
            ...informationSupportProductionOccurrencesRouter,
            ...observationStationFunctionalityOccurrencesRouter,
            ...drinkingWaterQualityAndPollutionControlStationsRouter,
            ...quantitativeObservationAndQualityControlStationsRouter,
        ],
    },
];

export default managementInstrumentsRouter;
