
import { useContext } from 'react';
import { HttpContext } from '../contexts/HttpContext';

// ----------------------------------------------------------------------

export const useApiHttp = () => {
    const {apiHttp} = useContext(HttpContext);

    return apiHttp;
}

export const usePublicHttp = () => {
    const {publicHttp} = useContext(HttpContext);

    return publicHttp;
}
