
export const parseEntity = (result, key) => {
    const {data, loading, error: _error, refetch} = result;

    if (loading) return {loading}

    if (_error) return {loading, error: true, errorMessage: `Erreur lors de l'operation`}

    const {entity, error, message: errorMessage} = data[key];

    return {entity, error, errorMessage, refetch, loading};

}

export const parseCount = (result, key) => {
    const {data, loading, error: _error} = result;

    if (loading) return {loading}

    if (_error) return {loading, error: true, errorMessage: `Erreur lors de l'operation`}

    const {count, error, message: errorMessage} = data[key];

    return {count, error, errorMessage};

}
