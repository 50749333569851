import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const SaepTypeList = Loadable(lazy(() => import("./pages/SaepTypeList")));
const SaepTypeCreate = Loadable(lazy(() => import("./pages/SaepTypeCreate")));
const SaepTypeEdit = Loadable(lazy(() => import("./pages/SaepTypeEdit")));
const SaepTypeDisplay = Loadable(lazy(() => import("./pages/SaepTypeDisplay")));

const saepTypeRouter = [
  { path: "saep-types", element: <SaepTypeList /> },
  { path: "saep-types/:id", element: <SaepTypeDisplay /> },
  { path: "saep-types/new", element: <SaepTypeCreate /> },
  { path: "saep-types/:id/edit", element: <SaepTypeEdit /> },
];

export default saepTypeRouter;
