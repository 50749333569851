import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const GireOrganEntitiesList = Loadable(
  lazy(() => import("./pages/GireOrganEntitiesList"))
);
const GireOrganEntitiesCreate = Loadable(
  lazy(() => import("./pages/GireOrganEntitiesCreate"))
);
const GireOrganEntitiesEdit = Loadable(
  lazy(() => import("./pages/GireOrganEntitiesEdit"))
);
const GireOrganEntitiesDisplay = Loadable(
  lazy(() => import("./pages/GireOrganEntitiesDisplay"))
);

const gireOrganEntitiesRouter = [
  {
    path: "gire-organ-entities",
    element: <GireOrganEntitiesList />,
  },
  {
    path: "gire-organ-entities/:id",
    element: <GireOrganEntitiesDisplay />,
  },
  {
    path: "gire-organ-entities/new",
    element: <GireOrganEntitiesCreate />,
  },
  {
    path: "gire-organ-entities/:id/edit",
    element: <GireOrganEntitiesEdit />,
  },
];

export default gireOrganEntitiesRouter;
