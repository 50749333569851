import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const TestFormPage = Loadable(lazy(() => import('./pages/TestFormPage')));

const testRouter = [
    {path: 'tests/form', element: <TestFormPage/>},
];

export default testRouter;
