import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const InfrastructureDrillingList = Loadable(
  lazy(() => import("./pages/InfrastructureDrillingList"))
);
const InfrastructureDrillingCreate = Loadable(
  lazy(() => import("./pages/InfrastructureDrillingCreate"))
);
const InfrastructureDrillingEdit = Loadable(
  lazy(() => import("./pages/InfrastructureDrillingEdit"))
);
const InfrastructureDrillingDisplay = Loadable(
  lazy(() => import("./pages/InfrastructureDrillingDisplay"))
);

const infrastructureDrillingRouter = [
  { path: "infrastructure-drillings", element: <InfrastructureDrillingList /> },
  {
    path: "infrastructure-drillings/:id",
    element: <InfrastructureDrillingDisplay />,
  },
  {
    path: "infrastructure-drillings/new",
    element: <InfrastructureDrillingCreate />,
  },
  {
    path: "infrastructure-drillings/:id/edit",
    element: <InfrastructureDrillingEdit />,
  },
];

export default infrastructureDrillingRouter;
