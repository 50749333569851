import React from 'react';
import {Person} from '@mui/icons-material';
import {path} from '../../home/sidebar/paths';

//Features
const ROOTS_ACTORS = '/actors';
const pathActors = path(ROOTS_ACTORS);
export const PATH_ACTORS = {
    root: ROOTS_ACTORS,
    home: pathActors('/home'),
    actor: pathActors('/actors'),
    type_actor: pathActors('/type-actors'),
    hierarchy: pathActors('/hierarchies'),
    structure: pathActors('/structures'),
    responsibility: pathActors('/responsibilities'),
    competency: pathActors('/competencies'),
    activity_type: pathActors('/activity-types'),
    intervention_type: pathActors('/intervention-types'),
    type_communication_method: pathActors('/type-communication-methods'),
    communication_method_category: pathActors('/communication-method-categories'),
    communication_directory: pathActors('/communication-directories'),
};


export const actorsMenu = [
    {
        title: 'acteur',
        path: PATH_ACTORS.root,
        icon: <Person/>,
        children: [
            {title: 'Accueil', path: PATH_ACTORS.home},
            {title: 'acteur', path: PATH_ACTORS.actor},
            {title: 'Type acteur', path: PATH_ACTORS.type_actor},
            {title: 'Structure', path: PATH_ACTORS.structure},
            {title: 'Hierarchie', path: PATH_ACTORS.hierarchy},
            {title: 'Responsabilité', path: PATH_ACTORS.responsibility},
            {title: 'Competence', path: PATH_ACTORS.competency},
            {title: 'Type activité', path: PATH_ACTORS.activity_type},
            {title: 'Type intervention', path: PATH_ACTORS.intervention_type},
            {title: 'Type methode communication', path: PATH_ACTORS.type_communication_method},
            {title: 'Categorie methode communication', path: PATH_ACTORS.communication_method_category},
            {title: 'Repertoire communication', path: PATH_ACTORS.communication_directory},
        ]
    }
];
