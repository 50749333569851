import {gql, useLazyQuery, useQuery} from '@apollo/client';
import {useEffect, useState} from 'react';
import {useMessage} from '../../../../../shared/hooks/useMessage';
import {parseCount} from "../../../../../utils/gql.utils";

export let needRefresh = false;
export const setRefresh = (value) => needRefresh = value;

export const dashboardProfileFields = `
    id dashboard dashboardObj{id title}
    profileUid profileObj{id name}
`;

///////////////////// Search /////////////////////
const SEARCH_DASHBOARD_PROFILES = gql`
    query SearchDashboardProfiles($dashboard: Int, $profileUid: String) {
        searchDashboardProfiles(dashboard: $dashboard, profileUid: $profileUid) {
            error message list { ${dashboardProfileFields} }
        }
    }`;

export const useSearchDashboardProfiles = ({dashboard, profileUid}) => {
    dashboard = dashboard && parseInt(dashboard);

    const [dashboardProfiles, setDashboardProfiles] = useState([]);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_DASHBOARD_PROFILES, {
        variables: {dashboard, profileUid},
    });

    useEffect(() => {
        if (_error) {
            showError('Erreur lors de l\'operation');
            setDashboardProfiles([]);
            return;
        }

        if (!searchData) return;

        const {error, message, list} = searchData.searchDashboardProfiles;

        if (error) {
            showError(message);
            setDashboardProfiles([]);
        } else {
            setDashboardProfiles(list);
        }

        if (needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({variables: {dashboard, profileUid}});
    }, [dashboard, profileUid]);

    return {
        dashboardProfiles, loading, search, refetch
    }
}

///////////////////// Search My Dashboard /////////////////////
const MY_DASHBOARDS = gql`
    query MyDashboards {
        myDashboards {
            error message list { ${dashboardProfileFields} }
        }
    }`;

export const useMyDashboards = () => {
    const [myDashboards, setMyDashboards] = useState([]);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(MY_DASHBOARDS);

    useEffect(() => {
        if (_error) {
            showError('Erreur lors de l\'operation');
            setMyDashboards([]);
            return;
        }

        if (!searchData) return;

        const {error, message, list} = searchData.myDashboards;

        if (error) {
            showError(message);
            setMyDashboards([]);
        } else {
            setMyDashboards(list);
        }

        if (needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search();
    }, []);

    return {
        myDashboards, loading, search, refetch
    }
}

///////////////////// Count /////////////////////
const COUNT_MY_DASHBOARDS = gql`
    query CountMyDashboards {
        countMyDashboards {
            error message count
        }
    }
`;

export const useCountMyDashboards = () => {
    const result = useQuery(COUNT_MY_DASHBOARDS, {
        fetchPolicy: 'network-only'
    });
    return parseCount(result, 'countMyDashboards');
}

