import React from 'react';
// material
import {Dashboard} from '@mui/icons-material';
// utils
import {PATH_REPORTING} from '../../../reporting.path';
import {useCountDashboards} from '../services/dashboard.gql';
import SubModuleEntryButton from '../../../../../shared/components/SubModuleEntryButton';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function DashboardEntryButton() {
    const {count} = useCountDashboards();
    return (
        <SubModuleEntryButton
            label={`Tableau de bord`}
            value={count}
            url={PATH_REPORTING.dashboard}
            icon={<Dashboard/>}
        />
    );
}
