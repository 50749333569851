import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const TypeVegetationList = Loadable(lazy(() => import('./pages/TypeVegetationList')));
const TypeVegetationCreate = Loadable(lazy(() => import('./pages/TypeVegetationCreate')));
const TypeVegetationEdit = Loadable(lazy(() => import('./pages/TypeVegetationEdit')));
const TypeVegetationDisplay = Loadable(lazy(() => import('./pages/TypeVegetationDisplay')));

const typeVegetationRouter = [
    {path: 'type-vegetations', element: <TypeVegetationList/>},
    {path: 'type-vegetations/:id', element: <TypeVegetationDisplay/>},
    {path: 'type-vegetations/new', element: <TypeVegetationCreate/>},
    {path: 'type-vegetations/:id/edit', element: <TypeVegetationEdit/>},
];

export default typeVegetationRouter;
