import {gql, useLazyQuery, useQuery} from '@apollo/client';
import {parseCount, parseEntity} from "../../../../utils/gql.utils";
import {useEffect, useState} from "react";
import {useMessage} from "../../../../shared/hooks/useMessage";

export let needRefresh = false;
export const setRefresh = (value) => needRefresh = value;

export const localWaterDepartmentFields = `
    id 
    name 
    description
    locality localityObj{id name}
`;

///////////////////// Find By ID /////////////////////
const GET_LOCAL_WATER_DEPARTMENT_BY_ID = gql`
    query LocalWaterDepartmentById($id: Int!) {
        localWaterDepartmentById(id: $id) {
            error message entity { ${localWaterDepartmentFields} }
        }
    }
`;

export const useLocalWaterDepartmentById = (id) => {
    id = parseInt(id);
    return useQuery(GET_LOCAL_WATER_DEPARTMENT_BY_ID, {
        variables: {id},
    });
}

export const useLocalWaterDepartment = (id) => {
    const result = useLocalWaterDepartmentById(id);
    return parseEntity(result, 'localWaterDepartmentById');
}

///////////////////// Search /////////////////////
const SEARCH_LOCAL_WATER_DEPARTMENTS = gql`
    query SearchLocalWaterDepartments($query: String, $locality: Int, $page: Int, $size: Int) {
        searchLocalWaterDepartments(query: $query, locality: $locality, page: $page, size: $size) {
            error message page {
                content { ${localWaterDepartmentFields} }
                page hasNext hasPrevious totalPages, totalElements, numberOfElements,
            }
        }
    }
`;

export const useSearchLocalWaterDepartments = ({query = '',locality, page = 0, size = 20}) => {
    const [localWaterDepartments, setLocalWaterDepartments] = useState([]);
    const [localWaterDepartmentPage, setLocalWaterDepartmentPage] = useState(null);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_LOCAL_WATER_DEPARTMENTS, {
        variables: {query, locality,page, size},
    });

    useEffect(() => {
        if (_error) {
            showError('Erreur lors de l\'operation');
            setLocalWaterDepartmentPage(null);
            setLocalWaterDepartments([]);
            return;
        }

        if (!searchData) return;

        const {error, message, page} = searchData.searchLocalWaterDepartments;


        if (error) {
            showError(message);
            setLocalWaterDepartmentPage(null);
            setLocalWaterDepartments([]);
        } else {
            setLocalWaterDepartmentPage(page);
            setLocalWaterDepartments(page.content);
        }


        if (needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({variables: {query,locality, page, size}});
    }, [query, locality,page, size]);

    return {
        localWaterDepartments, loading, search, refetch, localWaterDepartmentPage
    }
}

///////////////////// Count /////////////////////
const COUNT_LOCAL_WATER_DEPARTMENTS = gql`
    query CountLocalWaterDepartments {
        countLocalWaterDepartments {
            error message count
        }
    }
`;

export const useCountLocalWaterDepartments = () => {
    const result = useQuery(COUNT_LOCAL_WATER_DEPARTMENTS, {
        fetchPolicy: 'network-only'
    });
    return parseCount(result, 'countLocalWaterDepartments');
}
