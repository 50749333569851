import * as Yup from 'yup';
import create from 'zustand';


export const SicknessTypeSchema = Yup.object().shape({
    name: Yup.string().default('').required('Le nom est obligatoire'),
    description: Yup.string().default(''),
    dataSource: Yup.string().default(''),
});

const initialSicknessType = SicknessTypeSchema.getDefaultFromShape();
export const InitialSicknessType = () => ({...initialSicknessType});

const setCurrentNewSicknessType = set => sicknessType => {
    set(state => ({...state, currentNewSicknessType: sicknessType ?? InitialSicknessType()}));
}

const setCurrentUpdateSicknessType = set => sicknessType => {
    set(state => ({...state, currentUpdateSicknessType: sicknessType ?? InitialSicknessType()}));
}

export const useSicknessTypeStore = create(set => ({
    //State
    currentNewSicknessType: InitialSicknessType(),
    currentUpdateSicknessType: InitialSicknessType(),
    //Reducers
    setCurrentNewSicknessType: setCurrentNewSicknessType(set),
    setCurrentUpdateSicknessType: setCurrentUpdateSicknessType(set),
}))
