import React from "react";
// material
import { Typography } from "@mui/material";
// components
import CreateDialogMenuItem from "../../../../shared/components/CreateDialogMenuItem";
import {InstrumentPolicyForm, InstrumentPolicyOccurrenceForm} from "./index";
// ----------------------------------------------------------------------

export function InstrumentPolicyOccurrenceCreateDialogMenuItem({
                                                                   instrumentPolicy,
                                                         riskManagementToolApplicationDegree,
                                                                   dataRefYear,
                                                         onClose,
                                                         onSave,
                                                         onClick,
                                                         btn,
                                                     }) {
    const setCurrentInstrumentPolicyOccurrence = () => {};
    const currentInstrumentPolicyOccurrence = {
        riskManagementToolApplicationDegree: riskManagementToolApplicationDegree?.id,
        riskManagementToolApplicationDegreeObj: riskManagementToolApplicationDegree,

        dataRefYear: dataRefYear?.id,
        dataRefYearObj: dataRefYear,

        instrumentPolicy: instrumentPolicy?.id,
        instrumentPolicyObj: instrumentPolicy,
    };

    return (
        <CreateDialogMenuItem
            btn={btn}
            onClose={onClose}
            onClick={onClick}
            menuText="Ajouter une occurence instruments de politique et stratégie eau"
            dialogTitle="Création de l'instrument de politique et stratégie eau"
            dialogContextText={<>{`Creation de nouvelle occurrence instruments de politique et stratégie eau`}</>}
            formRender={({ onClose }) => {
                return (
                    <InstrumentPolicyOccurrenceForm
                        {...{currentInstrumentPolicyOccurrence, setCurrentInstrumentPolicyOccurrence,
                        }}
                        fromDialog={{
                            onCancel: onClose,
                            onSave: (createdInstrumentPolicyOccurrence) => {
                                onSave && onSave(createdInstrumentPolicyOccurrence);
                                onClose && onClose();
                            },
                        }}
                    />
                );
            }}
        />
    );
}
