import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';

import MainLayout from '../../home/AppLayout';
import Loadable from '../../shared/components/Loadable';

import {PATH_ENVIRONMENT} from './environment.path';
import typeVegetationRouter from "./type_vegetation/type.vegetation.router";
import specificNeedRouter from "./specific_need/specific.need.router";
import occurrenceRangeRouter from "./occurrence_range/occurrence.range.router";
import plantOccurrenceRouter from "./plant_occurrence/plant.occurrence.router";
import occurrenceObservationRouter from "./occurrence_observation/occurrence.observation.router";




const EnvironmentHome = Loadable(lazy(() => import('./EnvironmentHome')));

const environmentRouter = [{
    path: PATH_ENVIRONMENT.root,
    element: <MainLayout/>,
    children: [
        {path: PATH_ENVIRONMENT.root, element: <Navigate to={PATH_ENVIRONMENT.home} replace/>},
        {path: 'home', element: <EnvironmentHome/>},

          ...typeVegetationRouter,
          ...specificNeedRouter,
          ...occurrenceRangeRouter,
          ...plantOccurrenceRouter,
          ...occurrenceObservationRouter,
    ]
},];

export default environmentRouter;
