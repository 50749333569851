import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';

import {PATH_APP} from '../../home/sidebar/paths';
import AppLayout from '../../home/AppLayout';
import Loadable from '../../shared/components/Loadable';


const HomePage = Loadable(lazy(() => import('../../home/HomePage')));
const TestGraphQL = Loadable(lazy(() => import('./TestGraphQL')));

//Features
const testGraphqlRouter = [
    {
        path: PATH_APP.root,
        element: <AppLayout/>,
        children: [
            {path: PATH_APP.root, element: <Navigate to={PATH_APP.home} replace/>},
            {path: 'home', element: <HomePage/>},
            {path: 'test-graphql', element: <TestGraphQL/>},
        ]
    },
];

export default testGraphqlRouter;
