import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const StateOccurrenceList = Loadable(lazy(() => import('./pages/StateOccurrenceList')));
const StateOccurrenceCreate = Loadable(lazy(() => import('./pages/StateOccurrenceCreate')));
const StateOccurrenceEdit = Loadable(lazy(() => import('./pages/StateOccurrenceEdit')));
const StateOccurrenceDisplay = Loadable(lazy(() => import('./pages/StateOccurrenceDisplay')));

const stateOccurrenceRouter = [
    {path: 'state-occurrences', element: <StateOccurrenceList/>},
    {path: 'state-occurrences/:id', element: <StateOccurrenceDisplay/>},
    {path: 'state-occurrences/new', element: <StateOccurrenceCreate/>},
    {path: 'state-occurrences/:id/edit', element: <StateOccurrenceEdit/>},
];

export default stateOccurrenceRouter;
