import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const EvaluationToolsList = Loadable(lazy(() => import("./pages/EvaluationToolsList")));
const EvaluationToolsCreate = Loadable(lazy(() => import("./pages/EvaluationToolsCreate")));
const EvaluationToolsEdit = Loadable(lazy(() => import("./pages/EvaluationToolsEdit")));
const EvaluationToolsDisplay = Loadable(lazy(() => import("./pages/EvaluationToolsDisplay")));

const evaluationToolsRouter = [
  { path: "evaluations-tools", element: <EvaluationToolsList /> },
  { path: "evaluations-tools/:id", element: <EvaluationToolsDisplay /> },
  { path: "evaluations-tools/new", element: <EvaluationToolsCreate /> },
  { path: "evaluations-tools/:id/edit", element: <EvaluationToolsEdit /> },
];

export default evaluationToolsRouter;
