import React, {useState} from 'react';
import {Add, Edit, Info} from '@mui/icons-material';
import {
    Dialog, IconButton, ListItemIcon, ListItemText, MenuItem, Tooltip
} from '@mui/material';

import {DashboardWidgetForm} from "./index";


export function AddDashboardWidgetDialog({dashboard, onAdd, onCancel}) {
    dashboard = parseInt(dashboard);

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <Tooltip title='Ajouter un widget'>
                    <Add/>
                </Tooltip>
            </IconButton>

            <Dialog
                fullWidth
                maxWidth='md'
                open={open} onClose={handleClose}
            >
                <DashboardWidgetForm
                    dashboard={dashboard}
                    fromDialog={{
                        onSave: () => {
                            onAdd();
                            handleClose();
                        },
                        onCancel: () => {
                            onCancel()
                            handleClose();
                        },
                    }}
                />
            </Dialog>

        </>
    );
}

export function EditDashboardWidgetDialog({dashboard, dashboardWidget, onUpdate, onCancel}) {
    dashboard = parseInt(dashboard);

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
            <MenuItem
                onClick={() => setOpen(true)}
                sx={{color: 'text.secondary'}}
            >
                <ListItemIcon>
                    <Edit/>
                </ListItemIcon>
                <ListItemText primary='Modifier' primaryTypographyProps={{variant: 'body2'}}/>
            </MenuItem>


            <Dialog
                fullWidth
                maxWidth='md'
                open={open}
                onClose={() => {
                    onCancel();
                    handleClose();
                }}
            >
                <DashboardWidgetForm
                    isEdit
                    dashboard={dashboard}
                    currentDashboardWidget={dashboardWidget}
                    fromDialog={{
                        onSave: () => {
                            onUpdate();
                            handleClose();
                        },
                        onCancel: () => {
                            onCancel();
                            handleClose();
                        },
                    }}
                />
            </Dialog>

        </>
    );
}

export function DisplayDashboardWidgetDialog({dashboard, dashboardWidget, onClose}) {
    dashboard = parseInt(dashboard);

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
            <MenuItem
                onClick={() => setOpen(true)}
                sx={{color: 'text.secondary'}}
            >
                <ListItemIcon>
                    <Info/>
                </ListItemIcon>
                <ListItemText primary='Afficher' primaryTypographyProps={{variant: 'body2'}}/>
            </MenuItem>


            <Dialog
                fullWidth
                maxWidth='md'
                open={open}
                onClose={() => {
                    onClose();
                    handleClose();
                }}
            >
                <DashboardWidgetForm
                    isDisplay
                    dashboard={dashboard}
                    currentDashboardWidget={dashboardWidget}
                    fromDialog={{
                        onSave: () => {
                            onClose();
                            handleClose();
                        },
                        onCancel: () => {
                            onClose();
                            handleClose();
                        },
                    }}
                />
            </Dialog>

        </>
    );
}
