import * as Yup from 'yup';
import create from 'zustand';


export const RiskTypeSchema = Yup.object().shape({
    name: Yup.string().default('').required('Le nom est obligatoire'),
    description: Yup.string().default(''),
    dataSource: Yup.string().default(''),
});

const initialRiskType = RiskTypeSchema.getDefaultFromShape();
export const InitialRiskType = () => ({...initialRiskType});

const setCurrentNewRiskType = set => riskType => {
    set(state => ({...state, currentNewRiskType: riskType ?? InitialRiskType()}));
}

const setCurrentUpdateRiskType = set => riskType => {
    set(state => ({...state, currentUpdateRiskType: riskType ?? InitialRiskType()}));
}

export const useRiskTypeStore = create(set => ({
    //State
    currentNewRiskType: InitialRiskType(),
    currentUpdateRiskType: InitialRiskType(),
    //Reducers
    setCurrentNewRiskType: setCurrentNewRiskType(set),
    setCurrentUpdateRiskType: setCurrentUpdateRiskType(set),
}))
