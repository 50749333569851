import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Box, Tab, Card, Container, Stack, TextField, CircularProgress} from '@mui/material';
import Page from '../../../../shared/components/Page';
import {InstrumentPolicyOccurrenceHeaderBreadcrumbs} from '../components';
import {FormRowStack} from '../../../../shared/components/froms/FormComponents';
import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useInstrumentPolicyOccurrence} from '../services/instrument.policy.occurrence.gql';
import InstrumentPolicyOccurrenceMoreMenu from '../components/InstrumentPolicyOccurrenceMoreMenu';
import useInstrumentPolicyOccurrenceService from "../services/instrument.policy.occurrence.services";
import {useDelete} from "../../../../shared/hooks/useDelete";

export default function InstrumentPolicyOccurrenceDisplay() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {showError} = useMessage();
    const [tapIndex, setTapIndex] = useState('info');
    const {loading, entity: currentInstrumentPolicyOccurrence, error, errorMessage} = useInstrumentPolicyOccurrence(id);

    const {destroy} = useInstrumentPolicyOccurrenceService();
    const {onDelete} = useDelete({
        destroyFn: destroy,
        onSuccess: () => navigate(PATH_GIRE_ENV_FAVORABLE.instrumentPolicyOccurrences),
    });

    useEffect(() => {
        if (loading) return;
        if (error) {
            showError(errorMessage);
            navigate(PATH_GIRE_ENV_FAVORABLE.instrumentPolicyOccurrences);
        }
    }, [error, loading, errorMessage]);

    const handleChangeTab = (event, newValue) => setTapIndex(newValue);

    return (
        <Page
            title={"Gire environment favorable: occurrences d'état de mise en œuvre des instruments de politique et stratégie eau"}>
            <Container maxWidth={'xl'}>
                <InstrumentPolicyOccurrenceHeaderBreadcrumbs
                    heading="occurrences d'état de mise en œuvre des instruments de politique et stratégie eau"
                    lastName={`${currentInstrumentPolicyOccurrence?.name ?? "occurrences d'état de mise en œuvre des instruments de politique et stratégie eau"}`}

                    action={<InstrumentPolicyOccurrenceMoreMenu id={Number(id)} onDelete={onDelete} hiddenDisplay/>}
                />

                {loading && <CircularProgress/>}
                {!loading && currentInstrumentPolicyOccurrence && (
                    <TabContext value={tapIndex}>
                        <Box sx={{mb: 4, direction: 'row', display: 'flex', justifyContent: 'space-between'}}>
                            <TabList onChange={handleChangeTab}>
                                <Tab disableRipple value='info' label='Information'/>
                                {/*<Tab disableRipple value='regions' label='Regions'/>*/}
                            </TabList>
                        </Box>


                        <Card>
                            <TabPanel value='info'>
                                <Box sx={{p: 0, minHeight: '80%'}}>

                                    <Stack spacing={3}>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label='Nom'
                                                value={currentInstrumentPolicyOccurrence?.name}
                                                InputProps={{readOnly: true}}
                                            />
                                            <TextField
                                                fullWidth
                                                label="Dégrée d'application"
                                                value={currentInstrumentPolicyOccurrence?.riskManagementToolApplicationDegreeObj?.name}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label="Année de reference de donnée"
                                                value={currentInstrumentPolicyOccurrence?.dataRefYearObj?.name}
                                                InputProps={{readOnly: true}}
                                            />
                                            <TextField
                                                fullWidth
                                                label="Instruments de politique et stratégie eau"
                                                value={currentInstrumentPolicyOccurrence?.instrumentPolicyObj?.name}
                                                InputProps={{readOnly: true}}
                                            />

                                        </FormRowStack>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label="Justification"
                                                value={currentInstrumentPolicyOccurrence?.justification}
                                                InputProps={{readOnly: true}}
                                            />

                                            <TextField
                                                fullWidth
                                                label="Source de donnée"
                                                value={currentInstrumentPolicyOccurrence?.dataSource}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>

                                    </Stack>

                                </Box>

                            </TabPanel>

                            {/*<TabPanel value='regions' sx={{p: 0}}>
                                <RegionTable instrumentPolicyOccurrence={currentInstrumentPolicyOccurrence.id} currentInstrumentPolicyOccurrence={currentInstrumentPolicyOccurrence}/>
                            </TabPanel>*/}
                        </Card>

                    </TabContext>
                )}
            </Container>
        </Page>
    );
}
