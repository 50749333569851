import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const ResponsibilityList = Loadable(
  lazy(() => import("./pages/ResponsibilityList"))
);
const ResponsibilityCreate = Loadable(
  lazy(() => import("./pages/ResponsibilityCreate"))
);
const ResponsibilityEdit = Loadable(
  lazy(() => import("./pages/ResponsibilityEdit"))
);
const ResponsibilityDisplay = Loadable(
  lazy(() => import("./pages/ResponsibilityDisplay"))
);

const responsibilityRouter = [
  { path: "responsibilities", element: <ResponsibilityList /> },
  { path: "responsibilities/:id", element: <ResponsibilityDisplay /> },
  { path: "responsibilities/new", element: <ResponsibilityCreate /> },
  { path: "responsibilities/:id/edit", element: <ResponsibilityEdit /> },
];

export default responsibilityRouter;
