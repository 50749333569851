import React from 'react';
import {Navigate} from 'react-router-dom';
import LogoOnlyLayout from '../../home/LogoOnlyLayout';
import Page500 from '../../shared/pages/Page500';
import {NotFound} from '../../shared/pages';
import {PATH_PAGE} from '../../home/sidebar/paths';


const othersPagesRouter = [
    {
        path: '*',
        element: <LogoOnlyLayout/>,
        children: [
            {path: '500', element: <Page500/>},
            {path: '404', element: <NotFound/>},
            {path: '*', element: <Navigate to={PATH_PAGE.page404} replace/>}
        ]
    },

    {path: '*', element: <Navigate to={PATH_PAGE.page404} replace/>}
];

export default othersPagesRouter;
