import PropTypes from 'prop-types';
import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {PATH_WATER_USE} from '../../water.use.path';
import {Info, Edit, Add} from '@mui/icons-material';
import {ActionsButton} from '../../../../shared/components/DefaultActionsButton';

LocalWaterDepartmentMoreMenu.propTypes = {
    onDelete: PropTypes.func,
    id: PropTypes.number
};

export default function LocalWaterDepartmentMoreMenu({ onDelete, id, isIconButton, hiddenNew, hiddenDisplay }) {
    // const {hasRole, hasNotRole} = useRoleChecker();

    return (
        <ActionsButton
            isIconButton={isIconButton}
            items={[
                {
                    icon: <Info/>,
                    label: 'Afficher',
                    hidden: hiddenDisplay, //|| hasNotRole('ROLE_REGION_VIEW'),
                    component: RouterLink,
                    to: `${PATH_WATER_USE.localWaterDepartments}/${id}`,
                },
                {
                    icon: <Add/>,
                    label: 'Nouveau',
                    hidden: hiddenNew,
                    component: RouterLink,
                    to: `${PATH_WATER_USE.localWaterDepartments}/new`
                },
                {
                    icon: <Edit/>,
                    label: 'Modifier',
                    component: RouterLink,
                    to: `${PATH_WATER_USE.localWaterDepartments}/${id}/edit`
                },
                {divider: true},
                {
                    isConfirmation: true,
                    onClick: () => onDelete(id),
                    DialogProps: {
                        message: 'Voulez-vous vraiment supprimer ce service local eau'
                    },
                },
            ]}
        />
    );
}
