import React from "react";
import {Home} from "@mui/icons-material";
import {PATH_APP} from "./paths";

//Modules
import {adminMenu} from "../../features/admin/admin.path";
import {administrativeDivisionMenu} from "../../features/administrative_division/administrative_division.path";
import {reportingMenu} from "../../features/reporting/reporting.path";

import {waterUseMenu} from "../../features/water_use/water.use.path";
import {waterManagementMenu} from "../../features/water_management/water.management.path";
import {degradationProtectionMenu} from "../../features/degradation_protection/degradation.protection.path";
import {constructionMenu} from "../../features/construction/construction.path";
import {energyMenu} from "../../features/energy/energy.path";
import {waterQualityMenu} from "../../features/water_quality/water.quality.path";
import {riskProtectionMenu} from "../../features/risk_protection/risk.protection.path";
import {irrigationNeedWaterMenu} from "../../features/irrigation_need_water/irrigation.need.water.path";
import {waterUsageIrrigationMenu,} from "../../features/water_usage_urrigation/water.usage.irrigation.path";
import {fishingMenu} from "../../features/module_fishing/module.fishing.path";
import {environmentMenu} from "../../features/environment/environment.path";
import {gireFinancingMenu} from "../../features/gire_financing/gire.financing.path";
import {pastoralMenu} from "../../features/pastoral/pastoral.path";
import {domesticMenu} from "../../features/domestic/domestic.path";

// ----------------------------------------------------------------------
import {
    potentialWaterAvailabilityMenu
} from "../../features/potential_water_availability/potential.water.availability.path";
import {managementInstrumentsMenu} from "../../features/management_instruments/management.instruments.path";
import {organInstrumentMenu} from "../../features/organ_instrument/organ.instrument.path";
import {gireEnvFavorableMenu} from "../../features/gire_env_favorable/gire.env.favorable.path";

const sidebarConfig = [
    {
        subheader: "acceuil",
        items: [
            {
                title: "tableau de bord",
                path: PATH_APP.home,
                icon: <Home/>,
            },
        ],
    },
    {
        subheader: "MODULES",
        items: [
            ...waterUseMenu,
            ...waterManagementMenu,
            ...degradationProtectionMenu,
            ...waterQualityMenu,
            ...riskProtectionMenu,
            ...waterUsageIrrigationMenu,
            ...constructionMenu,
            ...irrigationNeedWaterMenu,
            ...energyMenu,
            ...fishingMenu,
            ...environmentMenu,
            ...pastoralMenu,
            ...domesticMenu,
            ...gireFinancingMenu,
            ...potentialWaterAvailabilityMenu,
            ...organInstrumentMenu,
            ...managementInstrumentsMenu,
            ...gireEnvFavorableMenu,
        ],
    },
    // {
    //   subheader: "MODULES EN DEV",
    //   items: fakeMenus(),
    // },
    {
        subheader: "CONFIGURATION",
        items: [...adminMenu, ...administrativeDivisionMenu, ...reportingMenu],
    },
];

export default sidebarConfig;
