import React from "react";
// material
import {} from "@mui/icons-material";
// utils
import SubModuleEntryButton from "../../../../shared/components/SubModuleEntryButton";
import { PATH_WATER_USAGE_URIGATION } from "../../water.usage.irrigation.path";
import AgricultureIcon from '@mui/icons-material/Agriculture';
import { useCountCulturalSpeculationEntityUsages } from "../services/cultural.speculation.entity.usages.gql";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function CulturalSpeculationEntityUsagesEntryButton() {
  const { count } = useCountCulturalSpeculationEntityUsages();
  return (
    <SubModuleEntryButton
      label={
        count > 1
          ? "Entités de spéculations culturales"
          : "Entité de spéculations culturales"
      }
      value={count}
      url={PATH_WATER_USAGE_URIGATION.culturalSpeculationEntityUsages}
      icon={<AgricultureIcon />}
    />
  );
}
