import React from 'react';

import {StatusCommuneForm} from '../components';
import CreateDialogMenuItem from '../../../../shared/components/CreateDialogMenuItem';

export function StatusCommuneCreateDialogMenuItem({onClose, onSave, onClick, btn}) {
    const setCurrentStatusCommune = () => {};
    const currentStatusCommune = {};
    return (
        <CreateDialogMenuItem
            btn={btn}
            onClose={onClose}
            onClick={onClick}
            menuText='Ajouter un statut de la commune'
            dialogTitle='Création de statut de la commune'
            dialogContextText={<>
                Création de nouveau statut de la commune

            </>}
            formRender={({onClose}) => {
                return (
                    <StatusCommuneForm
                        {...{currentStatusCommune, setCurrentStatusCommune}}
                        fromDialog={{
                            onCancel: onClose,
                            onSave: (createdStatusCommune) => {
                                console.log('ON SAVE EXECUTED createdStatusCommune ==> ', createdStatusCommune);
                                onSave && onSave(createdStatusCommune);
                                onClose && onClose();
                            },
                        }}
                    />
                );
            }}
        />
    );

}
