import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const WaterSourceList = Loadable(lazy(() => import("./pages/WaterSourceList")));
const WaterSourceCreate = Loadable(
  lazy(() => import("./pages/WaterSourceCreate"))
);
const WaterSourceEdit = Loadable(lazy(() => import("./pages/WaterSourceEdit")));
const WaterSourceDisplay = Loadable(
  lazy(() => import("./pages/WaterSourceDisplay"))
);

const waterSourceRouter = [
  { path: "waters-sources", element: <WaterSourceList /> },
  { path: "waters-sources/:id", element: <WaterSourceDisplay /> },
  { path: "waters-sources/new", element: <WaterSourceCreate /> },
  { path: "waters-sources/:id/edit", element: <WaterSourceEdit /> },
];

export default waterSourceRouter;
