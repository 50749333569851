import React, {Fragment, useState} from 'react';

import {FilterList, Search} from '@mui/icons-material';
import {Box, alpha, Typography, styled} from '@mui/material';

import SearchPaper, {PAPER_WIDTH} from './SearchPaper';
import Spacer from '../../../shared/components/Spacer';
import {scaleObject} from './search.utils';

const FIELD_WIDTH = scaleObject(PAPER_WIDTH, .7);

const RootStyle = styled(Box)(({theme}) => ({
    display: 'flex',
    cursor: 'pointer',
    border: '1px solid',
    alignItems: 'center',
    boxSizing: 'border-box',
    boxShadow: theme.shadows[1],
    color: theme.palette.primary.main,
    padding: theme.spacing(0, 2),
    borderRadius: theme.spacing(theme.shape.borderRadius),
    background: alpha(theme.palette.common.white, .7),
    borderColor: alpha(theme.palette.common.white, .9),
    transition: theme.transitions.create('all'),
    [theme.breakpoints.up('xs')]: {
        minHeight: 40,
        width: FIELD_WIDTH.xs,
    },
    [theme.breakpoints.up('ms')]: {
        minHeight: 45,
        width: FIELD_WIDTH.sm,
    },
    [theme.breakpoints.up('md')]: {
        minHeight: 45,
        width: FIELD_WIDTH.md,
    },
    [theme.breakpoints.up('lg')]: {
        minHeight: 50,
        width: FIELD_WIDTH.lg,
    },
    '&:hover': {
        boxShadow: theme.shadows[5],
        background: theme.palette.common.white,
    },
}));

function SearchField() {
    const [open, setOpen] = useState(false);
    const [selectedTerms, setSelectedTerms] = useState('');

    return (
        <Fragment>
            <RootStyle onClick={() => setOpen(true)}>
                <Search/>
                <Box sx={{mx: 1}}>
                    <Typography fontWeight={600} component={'span'}>{`Recherche `}</Typography>
                    <Typography display={{xs: 'none', md: 'inline-block'}} component={'span'}>
                        {selectedTerms || 'Modules, Entites'}
                    </Typography>
                </Box>
                <Spacer/>
                <FilterList/>
            </RootStyle>

            {open && (
                <SearchPaper setOpen={setOpen} selectedTerms={selectedTerms} setSelectedTerms={setSelectedTerms}/>
            )}
        </Fragment>
    );
}

export default SearchField;
