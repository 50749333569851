import React from 'react';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import {path} from '../../home/sidebar/paths';

//Features
const ENVIRONMENT = '/environments';
const _path = path(ENVIRONMENT);
export const PATH_ENVIRONMENT = {
    root: ENVIRONMENT,
    home: _path('/home'),
    typeVegetations: _path("/type-vegetations"),
    specificNeeds: _path("/specific-needs"),
    occurrenceRanges: _path("/occurrence-ranges"),
    plantOccurrences: _path("/plant-occurrences"),
    occurrenceObservations: _path("/occurrence-observations"),

};


export const environmentMenu = [
    {
        title: 'Environnement',
        path: PATH_ENVIRONMENT.root,
        icon: <CropOriginalIcon/>,
        children: [
            {title: 'Accueil', path: PATH_ENVIRONMENT.home},
            {title: 'Besoins specifiques en eau', path: PATH_ENVIRONMENT.specificNeeds},
            {title: 'Types de couverts végétal', path: PATH_ENVIRONMENT.typeVegetations},
        ]
    }
];

export const commonItems = [
    {name: "Accueil", href: "/"},
    {name: "Environnement", href: PATH_ENVIRONMENT.home},
];