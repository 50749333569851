import React from 'react';
import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
import {Info, Edit, Add} from '@mui/icons-material';
import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';

import {ActionsButton} from '../../../../shared/components/DefaultActionsButton';

InstrumentMechanismParticipationTypeMoreMenu.propTypes = {
    id: PropTypes.number,
    onDelete: PropTypes.func,
};

export default function InstrumentMechanismParticipationTypeMoreMenu({onDelete, id, isIconButton, hiddenNew, hiddenDisplay}) {
    // const {hasRole, hasNotRole} = useRoleChecker();

    return (
        <ActionsButton
            isIconButton={isIconButton}
            items={[
                {
                    icon: <Info/>,
                    label: 'Afficher',
                    hidden: hiddenDisplay, //|| hasNotRole('ROLE_PAYS_VIEW'),
                    component: RouterLink,
                    to: `${PATH_GIRE_ENV_FAVORABLE.instrumentMechanismParticipationTypes}/${id}`,
                },
                {
                    icon: <Add/>,
                    label: 'Nouveau',
                    hidden: hiddenNew,
                    component: RouterLink,
                    to: `${PATH_GIRE_ENV_FAVORABLE.instrumentMechanismParticipationTypes}/new`
                },
                {
                    icon: <Edit/>,
                    label: 'Modifier',
                    component: RouterLink,
                    to: `${PATH_GIRE_ENV_FAVORABLE.instrumentMechanismParticipationTypes}/${id}/edit`
                },
                {divider: true},
                {
                    isConfirmation: true,
                    onClick: () => onDelete(id),
                    DialogProps: {
                        message: "Voulez-vous vraiment supprimer ce type d'instruments et mécanismes de participation à la GIRE"
                    },
                },
            ]}
        />
    );
}
