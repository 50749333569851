import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {PATH_RISK_PROTECTION} from '../../risk.protection.path';
import {Info, Delete, Edit, MoreVert} from '@mui/icons-material';
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material';

RiskManagementToolsImplementationOccurrenceMoreMenu.propTypes = {
    onDelete: PropTypes.func,
    id: PropTypes.number
};

export default function RiskManagementToolsImplementationOccurrenceMoreMenu({ onDelete, id }) {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    // const {hasRole} = useRoleChecker();

    return (
        <>
            <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                <MoreVert width={20} height={20} />
            </IconButton>

            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: { width: 200, maxWidth: '100%' }
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuItem
                    component={RouterLink}
                    to={`${PATH_RISK_PROTECTION.riskManagementToolsImplementationOccurrences}/${id}`}
                    sx={{ color: 'text.secondary' }}
                >
                    <ListItemIcon>
                        <Info />
                    </ListItemIcon>
                    <ListItemText primary="Afficher" primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>

                <MenuItem
                    component={RouterLink}
                    to={`${PATH_RISK_PROTECTION.riskManagementToolsImplementationOccurrences}/${id}/edit`}
                    sx={{color: 'text.secondary'}}
                >
                    <ListItemIcon>
                        <Edit/>
                    </ListItemIcon>
                    <ListItemText primary="Modifier" primaryTypographyProps={{variant: 'body2'}}/>
                </MenuItem>

                  <MenuItem onClick={() => {
                    console.log('###### OnClick onDelete: ', onDelete);
                    onDelete();
                    setIsOpen(false);
                }} sx={{ color: 'text.secondary' }}>
                    <ListItemIcon>
                        <Delete />
                    </ListItemIcon>
                    <ListItemText primary="Supprimer" primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>

            </Menu>
        </>
    );
}
