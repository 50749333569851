import React from 'react';
// material
import MergeTypeIcon from '@mui/icons-material/MergeType';
// utils
import {PATH_GIRE_ENV_FAVORABLE} from '../../gire.env.favorable.path';
import {useCountParticipationAreaAndTypes} from '../services/participation.area.and.type.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';

export default function ParticipationAreaAndTypeEntryButton() {
    const {count} = useCountParticipationAreaAndTypes();
  return (
      <SubModuleEntryButton
          label='Types et domaine de participation à la GIRE'
          value={count}
          url={PATH_GIRE_ENV_FAVORABLE.participationAreaAndTypes}
          icon={<MergeTypeIcon />}
      />
  );
}
