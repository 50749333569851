import {useApiHttp} from '../../../../shared/hooks/useHttp';


const useLocalityService = () => {
    const {crud} = useApiHttp();

    const {
        create,
        update,
        destroy,
        findById,
        findAll,
    } = crud('localities');



    return {
        create,
        update,
        destroy,
        findById,
        findAll,
    }
}

export default useLocalityService;
