import React from "react";
import { PATH_WATER_USE, commonItems } from "../../water.use.path";
import HeaderBreadcrumbs from "../../../../shared/components/HeaderBreadcrumbs";

export function LocalWaterDepartmentHeaderBreadcrumbs({
  heading,
  lastName,
  ...others
}) {
  return (
    <HeaderBreadcrumbs
      {...others}
      heading={heading}
      links={[
        ...commonItems,
        {
          name: "Service local eau",
          href: PATH_WATER_USE.localWaterDepartments,
        },
        { name: lastName },
      ]}
    />
  );
}

export function LocalWaterDepartmentListHeaderBreadcrumbs({
  heading,
  lastName,
  ...others
}) {
  return (
    <HeaderBreadcrumbs
      {...others}
      heading={heading}
      links={[...commonItems, { name: lastName }]}
    />
  );
}
