import React from 'react';
// material
import AutorenewIcon from '@mui/icons-material/Autorenew';
// utils
import {PATH_ADMINISTRATIVE_DIVISION} from '../../administrative_division.path';
import {useCountStatusCommunes} from '../services/status.commune.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';

// ----------------------------------------------------------------------

export default function StatusCommuneEntryButton() {
    const {count} = useCountStatusCommunes();
  return (
      <SubModuleEntryButton
          label='Statut de la commune'
          value={count}
          url={PATH_ADMINISTRATIVE_DIVISION.statusCommunes}
          icon={<AutorenewIcon />}
      />
  );
}
