import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const OccurrenceAnimalProductionList = Loadable(lazy(() => import('./pages/OccurrenceAnimalProductionList')));
const OccurrenceAnimalProductionCreate = Loadable(lazy(() => import('./pages/OccurrenceAnimalProductionCreate')));
const OccurrenceAnimalProductionEdit = Loadable(lazy(() => import('./pages/OccurrenceAnimalProductionEdit')));
const OccurrenceAnimalProductionDisplay = Loadable(lazy(() => import('./pages/OccurrenceAnimalProductionDisplay')));

const occurrenceAnimalProductionRouter = [
    {path: 'occurrence-animal-productions', element: <OccurrenceAnimalProductionList/>},
    {path: 'occurrence-animal-productions/:id', element: <OccurrenceAnimalProductionDisplay/>},
    {path: 'occurrence-animal-productions/new', element: <OccurrenceAnimalProductionCreate/>},
    {path: 'occurrence-animal-productions/:id/edit', element: <OccurrenceAnimalProductionEdit/>},
];

export default occurrenceAnimalProductionRouter;
