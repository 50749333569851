import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Box, Card, CircularProgress, Container, Stack, Tab, TextField} from '@mui/material';
import Page from '../../../../shared/components/Page';
import {RiskManagementToolApplicationDegreeHeaderBreadcrumbs} from '../components';
import {FormRowStack} from '../../../../shared/components/froms/FormComponents';
import {PATH_RISK_PROTECTION} from '../../risk.protection.path';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useRiskManagementToolApplicationDegree} from '../services/risk.management.tool.application.degree.gql';
import RiskManagementToolApplicationDegreeMoreMenu from '../components/RiskManagementToolApplicationDegreeMoreMenu';
import useRiskManagementToolApplicationDegreeService
    from "../services/risk.management.tool.application.degree.services";
import {useDelete} from "../../../../shared/hooks/useDelete";

export default function RiskManagementToolApplicationDegreeDisplay() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {showError} = useMessage();
    const [tapIndex, setTapIndex] = useState('info');
    const {
        loading,
        entity: currentRiskManagementToolApplicationDegree,
        error,
        errorMessage
    } = useRiskManagementToolApplicationDegree(id);

    const {destroy} = useRiskManagementToolApplicationDegreeService();
    const {onDelete} = useDelete({
        destroyFn: destroy,
        onSuccess: () => navigate(PATH_RISK_PROTECTION.riskManagementToolApplicationDegrees),
    });

    useEffect(() => {
        if (loading) return;
        if (error) {
            showError(errorMessage);
            navigate(PATH_RISK_PROTECTION.riskManagementToolApplicationDegrees);
        }
    }, [error, loading, errorMessage]);

    const handleChangeTab = (event, newValue) => setTapIndex(newValue);

    return (
        <Page title={"Risque et protection en RE: Degré d'application des outils de gestion de risques liés à l'eau"}>
            <Container maxWidth={'xl'}>
                <RiskManagementToolApplicationDegreeHeaderBreadcrumbs
                    heading= "Degré d'application des outils de gestion de risques liés à l'eau"
                    lastName={`${currentRiskManagementToolApplicationDegree?.name ??  "Degré d'application des outils de gestion de risques liés à l'eau"}`}
                    action={<RiskManagementToolApplicationDegreeMoreMenu id={Number(id)} onDelete={onDelete} hiddenDisplay/>}
                />

                {loading && <CircularProgress/>}
                {!loading && currentRiskManagementToolApplicationDegree && (
                    <TabContext value={tapIndex}>
                        <Box sx={{mb: 4, direction: 'row', display: 'flex', justifyContent: 'space-between'}}>
                            <TabList onChange={handleChangeTab}>
                                <Tab disableRipple value='info' label='Information'/>
                                {/*<Tab disableRipple value='regions' label='Regions'/>*/}
                            </TabList>
                        </Box>


                        <Card>
                            <TabPanel value='info'>
                                <Box sx={{p: 0, minHeight: '80%'}}>

                                    <Stack spacing={3}>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label='Nom'
                                                value={currentRiskManagementToolApplicationDegree?.name}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>
                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label="Critère de notation"
                                                value={currentRiskManagementToolApplicationDegree?.notationCriteria}
                                                InputProps={{readOnly: true}}
                                            />
                                            <TextField
                                                fullWidth
                                                label="Notation"
                                                value={currentRiskManagementToolApplicationDegree?.notation}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>
                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label="Source de donnée"
                                                value={currentRiskManagementToolApplicationDegree?.dataSource}
                                                InputProps={{readOnly: true}}
                                            />
                                            <TextField
                                                fullWidth
                                                 multiline minRows={2} maxRows={6}  label="Description"
                                                value={currentRiskManagementToolApplicationDegree?.description}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>
                                    </Stack>
                                </Box>

                            </TabPanel>

                            {/*<TabPanel value='regions' sx={{p: 0}}>
                                <StatusCommuneTable riskManagementToolApplicationDegree={currentRiskManagementToolApplicationDegree.id} currentRiskManagementToolApplicationDegree={currentRiskManagementToolApplicationDegree}/>
                            </TabPanel>*/}
                        </Card>

                    </TabContext>
                )}
            </Container>
        </Page>
    );
}
