import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Box, Tab, Card, Container, Stack, TextField, CircularProgress} from '@mui/material';
import Page from '../../../../shared/components/Page';
import {RiskManagementToolHeaderBreadcrumbs} from '../components';
import {FormRowStack} from '../../../../shared/components/froms/FormComponents';
import {PATH_RISK_PROTECTION} from '../../risk.protection.path';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useRiskManagementTool} from '../services/risk.management.tool.gql';
import RiskManagementToolMoreMenu from '../components/RiskManagementToolMoreMenu';
import useRiskManagementToolService from "../services/risk.management.tool.services";
import {useDelete} from "../../../../shared/hooks/useDelete";
import {RiskManagementToolsImplementationOccurrenceTable} from "../../occurrence_risk_management_tools_implementation/components";

export default function RiskManagementToolDisplay() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {showError} = useMessage();
    const [tapIndex, setTapIndex] = useState('info');
    const {loading, entity: currentRiskManagementTool, error, errorMessage} = useRiskManagementTool(id);

    const {destroy} = useRiskManagementToolService();
    const {onDelete} = useDelete({
        destroyFn: destroy,
        onSuccess: () => navigate(PATH_RISK_PROTECTION.riskManagementTools),
    });

    useEffect(() => {
        if (loading) return;
        if (error) {
            showError(errorMessage);
            navigate(PATH_RISK_PROTECTION.riskManagementTools);
        }
    }, [error, loading, errorMessage]);

    const handleChangeTab = (event, newValue) => setTapIndex(newValue);

    return (
        <Page title={"Risque et protection en RE: Outils de gestion de risques liés à l'eau"}>
            <Container maxWidth={'xl'}>
                <RiskManagementToolHeaderBreadcrumbs
                    heading="Outils de gestion de risques liés à l'eau"
                    lastName={`${currentRiskManagementTool?.name ?? "Outils de gestion de risques liés à l'eau"}`}
                    action={<RiskManagementToolMoreMenu id={Number(id)} onDelete={onDelete} hiddenDisplay/>}
                />

                {loading && <CircularProgress/>}
                {!loading && currentRiskManagementTool && (
                    <TabContext value={tapIndex}>
                        <Box sx={{mb: 4, direction: 'row', display: 'flex', justifyContent: 'space-between'}}>
                            <TabList onChange={handleChangeTab}>
                                <Tab disableRipple value="info" label="Information"/>
                                <Tab disableRipple value="occurrence" label="Occurrences de mise en œuvre des outils de gestion de risques liés à l'eau"/>
                            </TabList>
                        </Box>

                        <Card>
                            <TabPanel value='info'>
                                <Box sx={{p: 0, minHeight: '80%'}}>

                                    <Stack spacing={3}>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label='Nom'
                                                value={currentRiskManagementTool?.name}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label="Année de mise à jour"
                                                value={currentRiskManagementTool?.dataRefYearObj?.name}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label="Année de mise à jour"
                                                value={currentRiskManagementTool?.riskManagementToolTypeObj?.name}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>

                                        <FormRowStack>
                                            <TextField
                                                fullWidth
                                                label="Source de données"
                                                value={currentRiskManagementTool?.dataSource}
                                                InputProps={{readOnly: true}}
                                            />
                                        </FormRowStack>
                                    </Stack>

                                </Box>

                            </TabPanel>

                            <TabPanel value='occurrence' sx={{p: 0}}>
                                <RiskManagementToolsImplementationOccurrenceTable riskManagementTool={currentRiskManagementTool.id} currentRiskManagementTool={currentRiskManagementTool}/>
                            </TabPanel>

                        </Card>

                    </TabContext>
                )}
            </Container>
        </Page>
    );
}
