import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const ZoneConcernedList = Loadable(lazy(() => import('./pages/ZoneConcernedList')));
const ZoneConcernedCreate = Loadable(lazy(() => import('./pages/ZoneConcernedCreate')));
const ZoneConcernedEdit = Loadable(lazy(() => import('./pages/ZoneConcernedEdit')));
const ZoneConcernedDisplay = Loadable(lazy(() => import('./pages/ZoneConcernedDisplay')));

const zoneConcernedRouter = [
    {path: 'zone-concerned', element: <ZoneConcernedList/>},
    {path: 'zone-concerned/:id', element: <ZoneConcernedDisplay/>},
    {path: 'zone-concerned/new', element: <ZoneConcernedCreate/>},
    {path: 'zone-concerned/:id/edit', element: <ZoneConcernedEdit/>},
];

export default zoneConcernedRouter;
