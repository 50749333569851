import {useEffect, useState} from 'react';
import {gql, useQuery, useLazyQuery} from '@apollo/client';
import {parseCount, parseEntity} from '../../../../utils/gql.utils';
import {useMessage} from '../../../../shared/hooks/useMessage';

export let needRefresh = false;
export const setRefresh = (value) => needRefresh = value;

export const localityTypeFields = `
    id name description
`;

///////////////////// Find By ID /////////////////////
const GET_LOCALITY_TYPE_BY_ID = gql`
    query LocalityTypeById($id: Int!) {
        localityTypeById(id: $id) {
            error message entity { ${localityTypeFields} }
        }
    }
`;

export const useLocalityTypeById = (id) => {
    id = parseInt(id);
    return useQuery(GET_LOCALITY_TYPE_BY_ID, {
        variables: {id},
        fetchPolicy: 'network-only'
    });
}

export const useLocalityType = (id) => {
    const result = useLocalityTypeById(id);
    return parseEntity(result, 'localityTypeById');
}

///////////////////// Search /////////////////////
const SEARCH_LOCALITY_TYPES = gql`
    query SearchLocalityTypes($query: String, $page: Int, $size: Int) {
        searchLocalityTypes(query: $query, page: $page, size: $size) {
            error message page {
                content { ${localityTypeFields} }
                page hasNext hasPrevious totalPages totalElements numberOfElements
            }
        }
    }
`;

export const useSearchLocalityTypes = ({query = '', page = 0, size = 20}) => {
    const [localityTypes, setLocalityTypes] = useState([]);
    const [localityTypePage, setLocalityTypePage] = useState(null);
    const {showError} = useMessage();
    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_LOCALITY_TYPES, {
        variables: {query, page, size},
    });

    useEffect(() => {
        if(_error) {
            showError('Erreur lors de l\'operation');
            setLocalityTypePage(null);
            setLocalityTypes([]);
            return;
        }

        if(!searchData) return;

        const {error, message, page} = searchData.searchLocalityTypes;

        if (error) {
            showError(message);
            setLocalityTypePage(null);
            setLocalityTypes([]);
        } else {
            setLocalityTypePage(page);
            setLocalityTypes(page.content);
        }

        if(needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({ variables: { query, page, size } });
    }, [query, page, size]);

    return {
        localityTypes, loading, search, refetch, localityTypePage
    }
}

///////////////////// Count /////////////////////
const COUNT_LOCALITY_TYPES = gql`
    query CountLocalityTypes {
        countLocalityTypes {
            error message count
        }
    }
`;

export const useCountLocalityTypes = () => {
    const result = useQuery(COUNT_LOCALITY_TYPES, {
        fetchPolicy: 'network-only'
    });
    return parseCount(result, 'countLocalityTypes');
}
