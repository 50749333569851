import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const LocalityTypeList = Loadable(lazy(() => import('./pages/LocalityTypeList')));
const LocalityTypeCreate = Loadable(lazy(() => import('./pages/LocalityTypeCreate')));
const LocalityTypeEdit = Loadable(lazy(() => import('./pages/LocalityTypeEdit')));
const LocalityTypeDisplay = Loadable(lazy(() => import('./pages/LocalityTypeDisplay')));

const locality_typeRouter = [
    {path: 'locality-types', element: <LocalityTypeList/>},
    {path: 'locality-types/:id', element: <LocalityTypeDisplay/>},
    {path: 'locality-types/new', element: <LocalityTypeCreate/>},
    {path: 'locality-types/:id/edit', element: <LocalityTypeEdit/>},
];

export default locality_typeRouter;
