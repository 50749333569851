import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const DrillingResultList = Loadable(
  lazy(() => import("./pages/DrillingResultList"))
);
const DrillingResultCreate = Loadable(
  lazy(() => import("./pages/DrillingResultCreate"))
);
const DrillingResultEdit = Loadable(
  lazy(() => import("./pages/DrillingResultEdit"))
);
const DrillingResultDisplay = Loadable(
  lazy(() => import("./pages/DrillingResultDisplay"))
);

const drillingResultRouter = [
  { path: "drilling-results", element: <DrillingResultList /> },
  { path: "drilling-results/:id", element: <DrillingResultDisplay /> },
  { path: "drilling-results/new", element: <DrillingResultCreate /> },
  { path: "drilling-results/:id/edit", element: <DrillingResultEdit /> },
];

export default drillingResultRouter;
