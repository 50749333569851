import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const SolicitedWaterMassList = Loadable(lazy(() => import('./pages/SolicitedWaterMassList')));
const SolicitedWaterMassCreate = Loadable(lazy(() => import('./pages/SolicitedWaterMassCreate')));
const SolicitedWaterMassEdit = Loadable(lazy(() => import('./pages/SolicitedWaterMassEdit')));
const SolicitedWaterMassDisplay = Loadable(lazy(() => import('./pages/SolicitedWaterMassDisplay')));

const solicitedWaterMassRouter = [
    {path: 'solicited-water-mass', element: <SolicitedWaterMassList/>},
    {path: 'solicited-water-mass/:id', element: <SolicitedWaterMassDisplay/>},
    {path: 'solicited-water-mass/new', element: <SolicitedWaterMassCreate/>},
    {path: 'solicited-water-mass/:id/edit', element: <SolicitedWaterMassEdit/>},
];

export default solicitedWaterMassRouter;
