import React from 'react';
import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
import {Add, Edit, Info} from '@mui/icons-material';
import {PATH_ORGAN_INSTRUMENT} from "../../organ.instrument.path";

import {ActionsButton} from '../../../../shared/components/DefaultActionsButton';

TypeSphereMoreMenu.propTypes = {
    id: PropTypes.number,
    onDelete: PropTypes.func,
};

export default function TypeSphereMoreMenu({onDelete, id, isIconButton, hiddenNew, hiddenDisplay}) {
    // const {hasRole, hasNotRole} = useRoleChecker();

    return (
        <ActionsButton
            isIconButton={isIconButton}
            items={[
                {
                    icon: <Info/>,
                    label: 'Afficher',
                    hidden: hiddenDisplay, //|| hasNotRole('ROLE_PAYS_VIEW'),
                    component: RouterLink,
                    to: `${PATH_ORGAN_INSTRUMENT.typeSpheres}/${id}`,
                },
                {
                    icon: <Add/>,
                    label: 'Nouveau',
                    hidden: hiddenNew,
                    component: RouterLink,
                    to: `${PATH_ORGAN_INSTRUMENT.typeSpheres}/new`
                },
                {
                    icon: <Edit/>,
                    label: 'Modifier',
                    component: RouterLink,
                    to: `${PATH_ORGAN_INSTRUMENT.typeSpheres}/${id}/edit`
                },
                {divider: true},
                {
                    isConfirmation: true,
                    onClick: () => onDelete(id),
                    DialogProps: {
                        message: "Voulez-vous vraiment supprimer ce type de sphère d'actions"
                    },
                },
            ]}
        />
    );
}
