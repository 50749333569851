import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const EnergyProductionList = Loadable(lazy(() => import('./pages/EnergyProductionList')));
const EnergyProductionCreate = Loadable(lazy(() => import('./pages/EnergyProductionCreate')));
const EnergyProductionEdit = Loadable(lazy(() => import('./pages/EnergyProductionEdit')));
const EnergyProductionDisplay = Loadable(lazy(() => import('./pages/EnergyProductionDisplay')));

const energyProductionRouter = [
    {path: 'energy-productions', element: <EnergyProductionList/>},
    {path: 'energy-productions/:id', element: <EnergyProductionDisplay/>},
    {path: 'energy-productions/new', element: <EnergyProductionCreate/>},
    {path: 'energy-productions/:id/edit', element: <EnergyProductionEdit/>},
];

export default energyProductionRouter;
