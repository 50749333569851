import React, { lazy } from "react";
import { Navigate } from "react-router-dom";

import MainLayout from "../../home/AppLayout";
import Loadable from "../../shared/components/Loadable";

import { PATH_ADMIN } from "./admin.path";

//Features
import groupRouter from "./group/group.router";
import userRouter from "./user/user.router";

const AdminHome = Loadable(lazy(() => import("./AdminHome")));

const adminRouter = [
  {
    path: PATH_ADMIN.root,
    element: <MainLayout />,
    children: [
      {
        path: PATH_ADMIN.root,
        element: <Navigate to={PATH_ADMIN.home} replace />,
      },
      { path: "home", element: <AdminHome /> },

      ...groupRouter,
      ...userRouter,
    ],
  },
];

export default adminRouter;
