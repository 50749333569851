import {gql, useLazyQuery, useQuery} from '@apollo/client';
import {parseCount, parseEntity} from "../../../../utils/gql.utils";
import {useEffect, useState} from "react";
import {useMessage} from "../../../../shared/hooks/useMessage";

export let needRefresh = false;
export const setRefresh = (value) => needRefresh = value;

export const statusCommuneFields = `
    id name description
`;

///////////////////// Find By ID /////////////////////
const GET_REGION_BY_ID = gql`
    query StatusCommuneById($id: Int!) {
        statusCommuneById(id: $id) {
            error message entity { ${statusCommuneFields} }
        }
    }
`;

export const useStatusCommuneById = (id) => {
    id = parseInt(id);
    return useQuery(GET_REGION_BY_ID, {
        variables: {id},
        fetchPolicy: 'network-only'
    });
}

export const useStatusCommune = (id) => {
    const result = useStatusCommuneById(id);
    return parseEntity(result, 'statusCommuneById');
}

///////////////////// Search /////////////////////
const SEARCH_REGIONS = gql`
    query SearchStatusCommunes($query: String, $page: Int, $size: Int) {
        searchStatusCommunes(query: $query, page: $page, size: $size) {
            error message page {
                content { ${statusCommuneFields} }
                page hasNext hasPrevious totalPages, totalElements, numberOfElements,
            }
        }
    }
`;

export const useSearchStatusCommunes = ({query = '', page = 0, size = 20}) => {
    const [statusCommunes, setStatusCommunes] = useState([]);
    const [statusCommunesPage, setStatusCommunesPage] = useState(null);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_REGIONS, {
        variables: {query, page, size},
    });

    useEffect(() => {
        if (_error) {
            showError('Erreur lors de l\'operation');
            setStatusCommunesPage(null);
            setStatusCommunes([]);
            return;
        }

        if (!searchData) return;

        const {error, message, page} = searchData.searchStatusCommunes;


        if (error) {
            showError(message);
            setStatusCommunesPage(null);
            setStatusCommunes([]);
        } else {
            setStatusCommunesPage(page);
            setStatusCommunes(page.content);
        }


        if (needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({variables: {query, page, size}});
    }, [query, page, size]);

    return {
        statusCommunes, loading, search, refetch, statusCommunesPage
    }
}

///////////////////// Count /////////////////////
const COUNT_REGIONS = gql`
    query CountStatusCommunes {
        countStatusCommunes {
            error message count
        }
    }
`;

export const useCountStatusCommunes = () => {
    const result = useQuery(COUNT_REGIONS, {
        fetchPolicy: 'network-only'
    });
    return parseCount(result, 'countStatusCommunes');
}
