import React, {useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
// material
import {CircularProgress, Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {SicknessTypeForm} from '../components';

import {SicknessTypeHeaderBreadcrumbs} from '../components';

import {PATH_RISK_PROTECTION} from '../../risk.protection.path';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useSicknessType} from '../services/sickness.type.gql';
import {useSicknessTypeStore} from "../services/sickness.type.store";

export default function SicknessTypeEdit() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {showError} = useMessage();

    const {setCurrentUpdateSicknessType: setCurrentSicknessType} = useSicknessTypeStore();

    const {entity: currentSicknessType, error, loading, errorMessage} = useSicknessType(id);

    useEffect(() => {
        if (loading) return;
        if (error) {
            showError(errorMessage);
            navigate(PATH_RISK_PROTECTION.sicknessTypes);
        }
    }, [loading, error, errorMessage]);

    useEffect(() => {
        setCurrentSicknessType(currentSicknessType);
    }, [currentSicknessType]);

    return (
        <Page title={'Risque et protection en RE: Modification type de maladies liés aux RE'}>
            <Container maxWidth={'xl'}>
                <SicknessTypeHeaderBreadcrumbs
                    heading='Modification du type de maladies liés aux RE'
                    lastName={`Modification du type de maladies liés aux RE - ${id}`}

                />

                {loading && <CircularProgress/>}
                {!loading && currentSicknessType && <SicknessTypeForm isEdit {...{currentSicknessType, setCurrentSicknessType}}/>}
            </Container>
        </Page>
    );
}
