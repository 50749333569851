import React from 'react';
// material
import {Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {QuantitativeObservationTypeForm} from '../components';

import {QuantitativeObservationTypeHeaderBreadcrumbs} from '../components';
import {useQuantitativeObservationTypeStore} from "../services/quantitative.observation.type.store";

// ----------------------------------------------------------------------

export default function QuantitativeObservationTypeCreate() {
    const {currentNewQuantitativeObservationType: currentQuantitativeObservationType, setCurrentNewQuantitativeObservationType: setCurrentQuantitativeObservationType} = useQuantitativeObservationTypeStore();
    return (
        <Page title="Disponibilite potentielle en eau: Création type d'observation quantitative">
            <Container  maxWidth={'xl'}>
                <QuantitativeObservationTypeHeaderBreadcrumbs
                    heading="Création de nouveau type d'observation quantitative"
                    lastName="Nouveau type d'observation quantitative"
                />
                <QuantitativeObservationTypeForm {...{currentQuantitativeObservationType, setCurrentQuantitativeObservationType}}/>
            </Container>
        </Page>
    );
}
