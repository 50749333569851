import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const WaterQualityControlParametersList = Loadable(
  lazy(() => import("./pages/WaterQualityControlParametersList"))
);
const WaterQualityControlParametersCreate = Loadable(
  lazy(() => import("./pages/WaterQualityControlParametersCreate"))
);
const WaterQualityControlParametersEdit = Loadable(
  lazy(() => import("./pages/WaterQualityControlParametersEdit"))
);
const WaterQualityControlParametersDisplay = Loadable(
  lazy(() => import("./pages/WaterQualityControlParametersDisplay"))
);

const waterQualityControlParametersRouter = [
  {
    path: "water-quality-control-parameters",
    element: <WaterQualityControlParametersList />,
  },
  {
    path: "water-quality-control-parameters/:id",
    element: <WaterQualityControlParametersDisplay />,
  },
  {
    path: "water-quality-control-parameters/new",
    element: <WaterQualityControlParametersCreate />,
  },
  {
    path: "water-quality-control-parameters/:id/edit",
    element: <WaterQualityControlParametersEdit />,
  },
];

export default waterQualityControlParametersRouter;
