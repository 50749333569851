import { useState, useEffect } from 'react';

// ----------------------------------------------------------------------

const DAY_HOURS = 24;
const HOUR_MINUTE = 60;
const MINUTE_SECOND = 60;
const SECOND_MILLISECOND = 1000;
const MINUTE_MILLISECOND = MINUTE_SECOND * SECOND_MILLISECOND;
const HOUR_MILLISECOND = HOUR_MINUTE * MINUTE_MILLISECOND;
const DAY_MILLISECOND = DAY_HOURS * HOUR_MILLISECOND;

export default function useCountdown(date) {
  const [countdown, setCountdown] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00'
  });

  useEffect(() => {
    const interval = setInterval(() => setNewTime(), 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setNewTime = () => {
    const startTime = date;
    const endTime = new Date();
    const distanceToNow = startTime - endTime;

    const getDays = Math.floor(distanceToNow / DAY_MILLISECOND);
    const getHours = `0${Math.floor((distanceToNow % DAY_MILLISECOND) / HOUR_MILLISECOND)}`.slice(-2);
    const getMinutes = `0${Math.floor((distanceToNow % HOUR_MILLISECOND) / MINUTE_MILLISECOND)}`.slice(-2);
    const getSeconds = `0${Math.floor((distanceToNow % MINUTE_MILLISECOND) / SECOND_MILLISECOND)}`.slice(-2);

    setCountdown({
      days: getDays || '000',
      hours: getHours || '000',
      minutes: getMinutes || '000',
      seconds: getSeconds || '000'
    });
  };

  return countdown;
}

// Usage
// const countdown = useCountdown(new Date('07/07/2022 21:30'));
