import useDashboardService from '../services/dashboard.services';
import {useNavigate} from 'react-router-dom';
import {useMessage} from '../../../../../shared/hooks/useMessage';
import {PATH_REPORTING} from '../../../reporting.path';
import {useImportExport} from '../../../../../shared/hooks/useInportExport';
import {setRefresh} from '../services/dashboard.gql';

export function useDuplicateDashboard() {
    const navigate = useNavigate();
    const {duplicate} = useDashboardService();
    const {showSuccess, showError} = useMessage();

    return async (id) => {
        try{
            const {data: dashboardClone} = await duplicate(id);
            showSuccess(`Duplication realisee avec succès`);

            navigate(`${PATH_REPORTING.dashboard}/${dashboardClone.id}`);
        } catch (e) {
            const message = `Erreur lors de la duplication du tableau de bord`;

            showError(message);
            console.log(message, e);
        }
    }
}

export function useExportDashboard() {
    const {exportToJson} = useImportExport();
    const {exportById, exportAll, exportAllWithDependencies} = useDashboardService();
    const {showError} = useMessage();

    const byId = async (id) => {
        try {
            const {data} = await exportById(id);
            await exportToJson(data, `dashboard-${id}.json`);
        } catch (e) {
            console.error(e);
            showError(`Erreur lors de l'export`);
        }
    }

    const all = async () => {
        try {
            const {data} = await exportAll();
            await exportToJson(data, `dashboards.json`);
        } catch (e) {
            console.error(e);
            showError(`Erreur lors de l'export`);
        }
    }

    const allWithDependencies = async () => {
        try {
            const {data} = await exportAllWithDependencies();
            await exportToJson(data, `dashboard-with-deps.json`);
        } catch (e) {
            console.error(e);
            showError(`Erreur lors de l'export`);
        }
    }

    return { byId, all, allWithDependencies }
}

export function useImportDashboard() {
    const navigate = useNavigate();
    const {importFromJson} = useImportExport();
    const {importOne, importAll, importAllWithDependencies} = useDashboardService();
    const {showError, showSuccess} = useMessage();

    const one = async (files) => {
        try {
            const file = files[0];
            const data = await importFromJson(file);
            if (!data) return;

            const {dashboard} = data;
            if (!dashboard) {
                showError(`Impossible d'importer, le fichier ne contient pas d'object tableau de bord`);
                return;
            }

            const {id} = dashboard;
            if (!id) {
                showError(`Impossible d'importer, l'object tableau de bord dans le fichier n'a pas d'id`);
                return;
            }

            const {data: createdDashboard} = await importOne(data);

            showSuccess(`Importation realisee avec succès`);

            setRefresh(true);

            navigate(`${PATH_REPORTING.dashboard}/${id}`);
        } catch (e) {
            console.error(e);
            showError(`Erreur lors de l'import`);
        }
    }

    const all = async (files) => {
        try {
            const file = files[0];
            const data = await importFromJson(file);
            if (!data) return;

            await importAll(data);
            showSuccess(`Importation realisee avec succès`);
        } catch (e) {
            console.error(e);
            showError(`Erreur lors de l'import`);
        }
    }

    const allWithDependencies = async (files) => {
        try {
            const file = files[0];
            const data = await importFromJson(file);
            if (!data) return;

            await importAllWithDependencies(data);
            showSuccess(`Importation realisee avec succès`);
        } catch (e) {
            console.error(e);
            showError(`Erreur lors de l'import`);
        }
    }

    return {one, all, allWithDependencies}
}
