import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const EnergyList = Loadable(lazy(() => import("./pages/EnergyList")));
const EnergyCreate = Loadable(lazy(() => import("./pages/EnergyCreate")));
const EnergyEdit = Loadable(lazy(() => import("./pages/EnergyEdit")));
const EnergyDisplay = Loadable(lazy(() => import("./pages/EnergyDisplay")));

const energyRouter = [
  { path: "energies", element: <EnergyList /> },
  { path: "energies/:id", element: <EnergyDisplay /> },
  { path: "energies/new", element: <EnergyCreate /> },
  { path: "energies/:id/edit", element: <EnergyEdit /> },
];

export default energyRouter;
