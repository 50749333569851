import * as Yup from "yup";
import create from "zustand";

export const IrrigatedDevelopmentEntityUsagesSchema = Yup.object().shape({
    name: Yup.string().default("").required("Le libellé est obligatoire"),
    code: Yup.number().required("Le code est obligatoire"),
    cultureType: Yup.string().required("Le type de culture est obligatoire"),
    ahaType: Yup.number().required("Le type AHA est obligatoire"),
    dataRefYear: Yup.number().required("L'année de mise à jour est obligatoire"),
    culturalSeason: Yup.string().required("La saison culturelle est obligatoire"),
    averageProductionCost: Yup.string().required("Le cout moyen de la production est obligatoire"),
    dataSource: Yup.string(),
});

const initialIrrigatedDevelopmentEntityUsages =
    IrrigatedDevelopmentEntityUsagesSchema.getDefaultFromShape();
export const InitialIrrigatedDevelopmentEntityUsages = () => ({
    ...initialIrrigatedDevelopmentEntityUsages,
});

const setCurrentNewIrrigatedDevelopmentEntityUsages =
    (set) => (irrigatedDevelopmentEntityUsages) => {
        set((state) => ({
            ...state,
            currentNewIrrigatedDevelopmentEntityUsages:
                irrigatedDevelopmentEntityUsages ?? InitialIrrigatedDevelopmentEntityUsages(),
        }));
    };

const setCurrentUpdateIrrigatedDevelopmentEntityUsages =
    (set) => (irrigatedDevelopmentEntityUsages) => {
        set((state) => ({
            ...state,
            currentUpdateIrrigatedDevelopmentEntityUsages:
                irrigatedDevelopmentEntityUsages ?? InitialIrrigatedDevelopmentEntityUsages(),
        }));
    };

export const useIrrigatedDevelopmentEntityUsagesStore = create((set) => ({
    //State
    currentNewIrrigatedDevelopmentEntityUsages: InitialIrrigatedDevelopmentEntityUsages(),
    currentUpdateIrrigatedDevelopmentEntityUsages:
        InitialIrrigatedDevelopmentEntityUsages(), //Reducers
    setCurrentNewIrrigatedDevelopmentEntityUsages:
        setCurrentNewIrrigatedDevelopmentEntityUsages(set),
    setCurrentUpdateIrrigatedDevelopmentEntityUsages:
        setCurrentUpdateIrrigatedDevelopmentEntityUsages(set),
}));
