import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const QuantitativeObservationStationList = Loadable(lazy(() => import("./pages/QuantitativeObservationStationList")));
const QuantitativeObservationStationCreate = Loadable(lazy(() => import("./pages/QuantitativeObservationStationCreate")));
const QuantitativeObservationStationEdit = Loadable(lazy(() => import("./pages/QuantitativeObservationStationEdit")));
const QuantitativeObservationStationDisplay = Loadable(lazy(() => import("./pages/QuantitativeObservationStationDisplay")));

const quantitativeObservationStationRouter = [
  { path: "quantitative-observation-stations", element: <QuantitativeObservationStationList /> },
  { path: "quantitative-observation-stations/:id", element: <QuantitativeObservationStationDisplay /> },
  { path: "quantitative-observation-stations/new", element: <QuantitativeObservationStationCreate /> },
  { path: "quantitative-observation-stations/:id/edit", element: <QuantitativeObservationStationEdit /> },
];

export default quantitativeObservationStationRouter;
