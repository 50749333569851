import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const OperationalStructureWaterUseList = Loadable(lazy(() => import("./pages/OperationalStructureWaterUseList")));
const OperationalStructureWaterUseCreate = Loadable(lazy(() => import("./pages/OperationalStructureWaterUseCreate")));
const OperationalStructureWaterUseEdit = Loadable(lazy(() => import("./pages/OperationalStructureWaterUseEdit")));
const OperationalStructureWaterUseDisplay = Loadable(lazy(() => import("./pages/OperationalStructureWaterUseDisplay")));

const operationalStructureWaterUseRouter = [
  { path: "operational-structure-water-uses", element: <OperationalStructureWaterUseList /> },
  { path: "operational-structure-water-uses/:id", element: <OperationalStructureWaterUseDisplay /> },
  { path: "operational-structure-water-uses/new", element: <OperationalStructureWaterUseCreate /> },
  { path: "operational-structure-water-uses/:id/edit", element: <OperationalStructureWaterUseEdit /> },
];

export default operationalStructureWaterUseRouter;
