import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const TypeDevelopmentActionList = Loadable(lazy(() => import('./pages/TypeDevelopmentActionList')));
const TypeDevelopmentActionCreate = Loadable(lazy(() => import('./pages/TypeDevelopmentActionCreate')));
const TypeDevelopmentActionEdit = Loadable(lazy(() => import('./pages/TypeDevelopmentActionEdit')));
const TypeDevelopmentActionDisplay = Loadable(lazy(() => import('./pages/TypeDevelopmentActionDisplay')));

const typeDevelopmentActionRouter = [
    {path: 'type-development-actions', element: <TypeDevelopmentActionList/>},
    {path: 'type-development-actions/:id', element: <TypeDevelopmentActionDisplay/>},
    {path: 'type-development-actions/new', element: <TypeDevelopmentActionCreate/>},
    {path: 'type-development-actions/:id/edit', element: <TypeDevelopmentActionEdit/>},
];

export default typeDevelopmentActionRouter;
