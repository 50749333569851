import {Box, Link, styled, Typography} from "@mui/material";
import {APPBAR_DESKTOP, APPBAR_MOBILE} from "../navbar/HomeNavbar";
import {Link as RouterLink} from "react-router-dom";
import {Logo} from "../../shared/components";
import NavSection from "./NavSection";
import sidebarConfig from "./SidebarConfig";
import React from "react";


const StyleSideRoot = styled(Box)(({theme}) => ({
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
}))

const StyleSideHeader = styled(Box)(({theme}) => ({
    top: 0,
    zIndex: 1,
    display: 'flex',
    position: 'sticky',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    borderBottom: '1px solid',
    borderColor: theme.palette.grey["200"],
    backgroundColor: theme.palette.background.paper,
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: APPBAR_DESKTOP,
    }
}))

const StyleSideFooter = styled(Box)(({theme}) => ({
    bottom: 0,
    position: 'sticky',
    textAlign: 'center',
    borderTop: '1px solid',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    borderColor: theme.palette.grey["200"],
    backgroundColor: theme.palette.background.paper,
}))

export default function SideBarContent() {
    return (
        <StyleSideRoot>
            <StyleSideHeader>
                <RouterLink to='/'>
                    <Logo sx={{width: 60, height: 60}}/>
                </RouterLink>
            </StyleSideHeader>

            <NavSection sx={{flexGrow: 1, overflowY: 'auto'}} navConfig={sidebarConfig}/>

            <StyleSideFooter>
                <Typography gutterBottom variant="body2" color={'text.secondary'}>
                    Réalisé par
                </Typography>

                <Link underline='hover' href='https://loga-engineering.com' target='_blank'>
                    <Typography gutterBottom variant='subtitle1'>
                        Log@ Engineering
                    </Typography>
                </Link>
            </StyleSideFooter>
        </StyleSideRoot>
    )
}
