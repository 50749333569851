import React, {useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
// material
import {CircularProgress, Container} from '@mui/material';
// components
import Page from '../../../../shared/components/Page';
import {RiskManagementToolTypeForm} from '../components';

import {RiskManagementToolTypeHeaderBreadcrumbs} from '../components';

import {PATH_RISK_PROTECTION} from '../../risk.protection.path';
import {useMessage} from '../../../../shared/hooks/useMessage';
import {useRiskManagementToolType} from '../services/risk.management.tool.type.gql';
import {useRiskManagementToolTypeStore} from "../services/risk.management.tool.type.store";

// ----------------------------------------------------------------------

export default function RiskManagementToolTypeEdit() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {showError} = useMessage();

    const {setCurrentUpdateRiskManagementToolType: setCurrentRiskManagementToolType} = useRiskManagementToolTypeStore();

    const {entity: currentRiskManagementToolType, error, loading, errorMessage} = useRiskManagementToolType(id);

    useEffect(() => {
        if (loading) return;
        if (error) {
            showError(errorMessage);
            navigate(PATH_RISK_PROTECTION.riskManagementToolTypes);
        }
    }, [loading, error, errorMessage]);

    useEffect(() => {
        setCurrentRiskManagementToolType(currentRiskManagementToolType);
    }, [currentRiskManagementToolType]);

    return (
        <Page title={"Risque et protection en RE: Modification types outils de gestion de risques liées à l'eau"}>
            <Container maxWidth={'xl'}>
                <RiskManagementToolTypeHeaderBreadcrumbs
                    heading="Modification du typeoutils de gestion de risques liées à l'eau"
                    lastName={`Modification types outils de gestion de risques liées à l'eau - ${id}`}
                />

                {loading && <CircularProgress/>}
                {!loading && currentRiskManagementToolType && <RiskManagementToolTypeForm isEdit {...{currentRiskManagementToolType, setCurrentRiskManagementToolType}}/>}
            </Container>
        </Page>
    );
}
