import React from 'react';
import {PATH_GIRE_ENV_FAVORABLE, commonItems} from '../../gire.env.favorable.path';
import HeaderBreadcrumbs from "../../../../shared/components/HeaderBreadcrumbs";

export function LegislativeTextTypeHeaderBreadcrumbs ({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[
            ...commonItems,
            {name: 'Types textes de cadre législatif et reglementaire eau', href: PATH_GIRE_ENV_FAVORABLE.legislativeTextTypes},
            {name: lastName},
        ]}
    />);
}

export function LegislativeTextTypeListHeaderBreadcrumbs ({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[...commonItems, {name: lastName}]}
    />);
}
