import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const RegionList = Loadable(lazy(() => import('./pages/RegionList')));
const RegionCreate = Loadable(lazy(() => import('./pages/RegionCreate')));
const RegionEdit = Loadable(lazy(() => import('./pages/RegionEdit')));
const RegionDisplay = Loadable(lazy(() => import('./pages/RegionDisplay')));

const regionRouter = [
    {path: 'regions', element: <RegionList/>},
    {path: 'regions/:id', element: <RegionDisplay/>},
    {path: 'regions/new', element: <RegionCreate/>},
    {path: 'regions/:id/edit', element: <RegionEdit/>},
];

export default regionRouter;
