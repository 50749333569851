import React from 'react';
import {Grass, Power} from '@mui/icons-material';
import {path} from '../../home/sidebar/paths';

//Features
const PASTORAL = '/pastorals';
const _path = path(PASTORAL);
export const PATH_PASTORAL = {
    root: PASTORAL,
    home: _path('/home'),
    typeSpaces: _path("/type-spaces"),
    speciesTypeAnimals: _path("/species-type-animals"),
    sourceDrinkingWaters: _path("/source-drinking-waters"),
    entityAreas: _path("/entity-areas"),
    occurrenceAnimalProductions: _path("/occurrence-animal-productions"),
    occurrenceUsePastorals: _path('/occurrence-use-pastorals'),
};




export const  pastoralMenu = [
    {
        title: 'AEP-Pastorale',
        path: PATH_PASTORAL.root,
        icon: <Grass/>,
        children: [
            { title: 'Accueil', path: PATH_PASTORAL.home },
            { title: 'Entités de sources d\'eau', path: PATH_PASTORAL.sourceDrinkingWaters },
            { title: 'Entités aire de paturage', path: PATH_PASTORAL.entityAreas },
            { title: 'Types d\'espace', path: PATH_PASTORAL.typeSpaces },
            { title: 'Types d\'espèce animal', path: PATH_PASTORAL.speciesTypeAnimals },

        ]
    }
];

export const commonItems = [
    { name: "Accueil", href: "/" },
    { name: "AEP-Pastorale", href: PATH_PASTORAL.home },
];