import {gql, useLazyQuery} from '@apollo/client';
import {useEffect, useState} from 'react';
import {useMessage} from '../../../../../shared/hooks/useMessage';

export let needRefresh = false;
export const setRefresh = (value) => needRefresh = value;

export const reportingColumnFields = `
    id label description position valueType reporting dateFormat columnName
`;

///////////////////// Search /////////////////////
const SEARCH_REPORTING_COLUMNS_BY_REPORTING = gql`
    query SearchReportingColumnsByReporting($reporting: Int, $query: String) {
        searchReportingColumnsByReporting(reporting: $reporting, query: $query) {
            error message list { ${reportingColumnFields} }
        }
    }`;

export const useSearchReportingColumnsByReporting = ({reporting, query}) => {
    reporting = reporting && parseInt(reporting);
    const [reportingColumns, setReportingColumns] = useState([]);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_REPORTING_COLUMNS_BY_REPORTING, {
        variables: {reporting, query},
    });

    useEffect(() => {
        if (_error) {
            showError('Erreur lors de l\'operation');
            setReportingColumns([]);
            return;
        }

        if (!searchData) return;

        const {error, message, list} = searchData.searchReportingColumnsByReporting;

        if (error) {
            showError(message);
            setReportingColumns([]);
        } else {
            setReportingColumns(list);
        }

        if (needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({variables: {reporting}});
    }, [reporting]);

    return {
        reportingColumns, loading, search, refetch
    }
}
