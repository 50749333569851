import React, {lazy} from 'react';
import Loadable from '../../../../shared/components/Loadable';


const GroupList = Loadable(lazy(() => import('./pages/ReportingList')));
const GroupCreate = Loadable(lazy(() => import('./pages/ReportingCreate')));
const GroupEdit = Loadable(lazy(() => import('./pages/ReportingEdit')));
const GroupDisplay = Loadable(lazy(() => import('./pages/ReportingDisplay')));
const ReportingExecutionResult = Loadable(lazy(() => import('./pages/ReportingExecutionResult')));
const MyReportingList = Loadable(lazy(() => import('./pages/MyReportingList')));

const reportingRouter = [
    {path: 'reporting', element: <GroupList/>},
    {path: 'reporting/:id', element: <GroupDisplay/>},
    {path: 'reporting/new', element: <GroupCreate/>},
    {path: 'reporting/:id/edit', element: <GroupEdit/>},
    {path: 'reporting/:id/execute', element: <ReportingExecutionResult/>},
    {path: 'reporting/my-reporting', element: <MyReportingList/>},
];

export default reportingRouter;
