import {gql, useLazyQuery, useQuery} from '@apollo/client';
import {useEffect, useState} from 'react';
import {useMessage} from '../../../../../shared/hooks/useMessage';
import {parseCount} from "../../../../../utils/gql.utils";

export let needRefresh = false;
export const setRefresh = (value) => needRefresh = value;

export const reportingProfileFields = `
    id reporting reportingObj{id label}
    profileUid profileObj{id name}
`;

///////////////////// Search /////////////////////
const SEARCH_REPORTING_PROFILES = gql`
    query SearchReportingProfiles($reporting: Int, $profileUid: String) {
        searchReportingProfiles(reporting: $reporting, profileUid: $profileUid) {
            error message list { ${reportingProfileFields} }
        }
    }`;

export const useSearchReportingProfiles = ({reporting, profileUid}) => {
    reporting = reporting && parseInt(reporting);

    const [reportingProfiles, setReportingProfiles] = useState([]);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(SEARCH_REPORTING_PROFILES, {
        variables: {reporting, profileUid},
    });

    useEffect(() => {
        if (_error) {
            showError('Erreur lors de l\'operation');
            setReportingProfiles([]);
            return;
        }

        if (!searchData) return;

        const {error, message, list} = searchData.searchReportingProfiles;

        if (error) {
            showError(message);
            setReportingProfiles([]);
        } else {
            setReportingProfiles(list);
        }

        if (needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search({variables: {reporting, profileUid}});
    }, [reporting, profileUid]);

    return {
        reportingProfiles, loading, search, refetch
    }
}

///////////////////// Search My Reporting /////////////////////
const MY_REPORTING = gql`
    query MyReporting {
        myReporting {
            error message list { ${reportingProfileFields} }
        }
    }`;

export const useMyReporting = () => {
    const [myReporting, setMyReporting] = useState([]);

    const {showError} = useMessage();

    const [search, {data: searchData, refetch, loading, error: _error}] = useLazyQuery(MY_REPORTING);

    useEffect(() => {
        if (_error) {
            showError('Erreur lors de l\'operation');
            setMyReporting([]);
            return;
        }

        if (!searchData) return;

        const {error, message, list} = searchData.myReporting;

        if (error) {
            showError(message);
            setMyReporting([]);
        } else {
            setMyReporting(list);
        }

        if (needRefresh) {
            refetch();
            setRefresh(false);
        }

    }, [_error, searchData, needRefresh]);

    useEffect(() => {
        search();
    }, []);

    return {
        myReporting, loading, search, refetch
    }
}

///////////////////// Count /////////////////////
const COUNT_MY_REPORTING = gql`
    query CountMyReporting {
        countMyReporting {
            error message count
        }
    }
`;

export const useCountMyReporting = () => {
    const result = useQuery(COUNT_MY_REPORTING, {
        fetchPolicy: 'network-only'
    });
    return parseCount(result, 'countMyReporting');
}

