import React from 'react';
// material
import MergeTypeIcon from "@mui/icons-material/MergeType";
// utils
import {PATH_RISK_PROTECTION} from '../../risk.protection.path';
import {useCountRiskManagementToolTypes} from '../services/risk.management.tool.type.gql';
import SubModuleEntryButton from '../../../../shared/components/SubModuleEntryButton';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function RiskManagementToolTypeEntryButton() {
    const {count} = useCountRiskManagementToolTypes();
  return (
      <SubModuleEntryButton
          label="Types outils de gestion de risques liées à l'eau"
          value={count}
          url={PATH_RISK_PROTECTION.riskManagementToolTypes}
          icon={<MergeTypeIcon />}
      />
  );
}
