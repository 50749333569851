import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {Form, FormikProvider, useFormik} from 'formik';
import {useMessage} from '../../../../shared/hooks/useMessage';
// material
import {Autocomplete, Box, Card, CircularProgress, Stack, TextField} from '@mui/material';
// routes
import {PATH_WATER_USE} from '../../water.use.path';
import {
    FormikTextField,
    FormRowStack,
    SubmitCancelGroupButton
} from '../../../../shared/components/froms/FormComponents';
import {setRefresh} from '../services/local.water.department.gql';
import useLocalWaterDepartmentService from '../services/local.water.department.services';
import {InitialLocalWaterDepartment, LocalWaterDepartmentSchema} from '../services/local.water.department.store';
import {formErrorMessageParser} from '../../../../utils/utils';
import {useConnectFormState} from '../../../../shared/hooks/useFormikState';
import {useSearchLocalities} from "../../../administrative_division/locality/services/locality.gql";
import {LocalityCreateDialogMenuItem} from "../../../administrative_division/locality/components";
// ----------------------------------------------------------------------

LocalWaterDepartmentForm.propTypes = {
    isEdit: PropTypes.bool,
    currentLocalWaterDepartment: PropTypes.object,
    fromDialog: PropTypes.object,
};

export default function LocalWaterDepartmentForm(
    {
        isEdit,
        currentLocalWaterDepartment,
        setCurrentLocalWaterDepartment,
        fromDialog
    }
) {
    const navigate = useNavigate();
    const {create, update} = useLocalWaterDepartmentService();
    const {showSuccess, showError} = useMessage();

    const initQueryLocality = currentLocalWaterDepartment?.localityObj?.name ?? fromDialog?.locality?.name ?? '';
    const [localityQuery, setLocalityQuery] = useState(initQueryLocality);
    const {localities, localityLoading} = useSearchLocalities({localityQuery});


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: InitialLocalWaterDepartment(),
        validationSchema: LocalWaterDepartmentSchema,
        onSubmit: async (localWaterDepartment, {setSubmitting}) => {
            try {
                localWaterDepartment = LocalWaterDepartmentSchema.cast(localWaterDepartment);

                let message;
                if (!isEdit) {
                    localWaterDepartment = await create(localWaterDepartment);
                    message = 'Création avec succès';
                } else {
                    localWaterDepartment = await update(currentLocalWaterDepartment.id, localWaterDepartment);
                    message = 'Modification avec succès';
                }

                setRefresh(true);

                setCurrentLocalWaterDepartment();

                setSubmitting(false);
                showSuccess(message);

                if (fromDialog) {
                    fromDialog.onSave(localWaterDepartment);
                } else {
                    navigate(`${PATH_WATER_USE.localWaterDepartments}/${localWaterDepartment.id}`);
                }
            } catch (error) {
                setSubmitting(false);
                showError(formErrorMessageParser({error, isEdit}));
            }
        }
    });

    const {values, errors, touched, setValues, handleSubmit, isSubmitting, setFieldValue} = formik;

    useConnectFormState({
        formValues: values, setFormValues: setValues,
        stateValues: currentLocalWaterDepartment, setStateValues: setCurrentLocalWaterDepartment,
    });

    const onCancel = (_) => {
        setCurrentLocalWaterDepartment();
        if (fromDialog) {
            fromDialog.onCancel();
        } else {
            navigate(PATH_WATER_USE.localWaterDepartments);
        }
    }

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete='off' onSubmit={handleSubmit}>
                <Card sx={{p: 3}}>
                    <Stack spacing={3}>
                        <FormRowStack>
                            <FormikTextField label='Nom' name='name' formik={formik}/>
                            <Autocomplete
                                fullWidth
                                name="locality"
                                autoHighlight
                                options={localities}
                                loading={localityLoading}
                                inputValue={localityQuery}
                                value={{id: values.locality}}
                                loadingText={<CircularProgress/>}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value?.id
                                }
                                onChange={(event, selectedLocality) => {
                                    setFieldValue("locality", selectedLocality?.id);
                                    setLocalityQuery(selectedLocality?.name ?? "");
                                }}
                                getOptionLabel={(option) => {
                                    const _option = localities.find((op) => op.id === option.id);
                                    return _option?.name ?? "";
                                }}
                                renderInput={(params) => {
                                    return (
                                        <TextField
                                            {...params}
                                            label="Localite"
                                            onChange={(e) => {
                                                setLocalityQuery(e.target.value);
                                            }}
                                            error={Boolean(touched.locality && errors.locality)}
                                            helperText={touched.locality && errors.locality}
                                        />
                                    );
                                }}
                            />
                            {/*<FormikAutocomplete*/}
                            {/*    name='locality'*/}
                            {/*    label="Localité"*/}
                            {/*    options={localities}*/}
                            {/*    embeddedObjField='localityObj'*/}
                            {/*    {...{*/}
                            {/*        query: localityQuery, formik, loading: localityLoading,*/}
                            {/*        setQuery: setLocalityQuery*/}
                            {/*    }}*/}
                            {/*/>*/}
                            <Box sx={{py: 1, ml: 1}}>
                                <LocalityCreateDialogMenuItem btn onSave={(createdLocality) => {
                                    currentLocalWaterDepartment.localityObj = createdLocality;
                                    setLocalityQuery(createdLocality?.name ?? '');
                                    setFieldValue('locality', createdLocality?.id);
                                }}/>
                            </Box>
                        </FormRowStack>
                        <FormikTextField multiline minRows={2} maxRows={6}  label='Description' name='description' formik={formik}/>

                        <SubmitCancelGroupButton {...{isEdit, isSubmitting, onCancel}}/>

                    </Stack>

                </Card>

            </Form>
        </FormikProvider>
    );
}
