import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const AmenagementEntitiesList = Loadable(
  lazy(() => import("./pages/AmenagementEntityList"))
);
const AmenagementEntitiesCreate = Loadable(
  lazy(() => import("./pages/AmenagementEntityCreate"))
);
const AmenagementEntitiesEdit = Loadable(
  lazy(() => import("./pages/AmenagementEntityEdit"))
);
const AmenagementEntitiesDisplay = Loadable(
  lazy(() => import("./pages/AmenagementEntityDisplay"))
);

const AmenagementEntityRouter = [
  {
    path: "amenagement-entities",
    element: <AmenagementEntitiesList />,
  },
  {
    path: "amenagement-entities/:id",
    element: <AmenagementEntitiesDisplay />,
  },
  {
    path: "amenagement-entities/new",
    element: <AmenagementEntitiesCreate />,
  },
  {
    path: "amenagement-entities/:id/edit",
    element: <AmenagementEntitiesEdit />,
  },
];

export default AmenagementEntityRouter;
