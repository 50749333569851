import React, {lazy} from 'react';
import RiskTypeList from "./pages/RiskTypeList";
import RiskTypeDisplay from "./pages/RiskTypeDisplay";
import RiskTypeCreate from "./pages/RiskTypeCreate";
import RiskTypeEdit from "./pages/RiskTypeEdit";

const riskTypeRouter = [
    {path: 'risk-types', element: <RiskTypeList/>},
    {path: 'risk-types/:id', element: <RiskTypeDisplay/>},
    {path: 'risk-types/new', element: <RiskTypeCreate/>},
    {path: 'risk-types/:id/edit', element: <RiskTypeEdit/>},
];

export default riskTypeRouter;
