import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
// material

import {
    Card,
    Container,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    TableCell,
    TableHead,
    Link,
    Typography,
    Toolbar,
    Tooltip,
    IconButton,
    Box,
    CircularProgress,
    Select,
    FormControl,
    InputLabel, MenuItem
} from '@mui/material';
// routes
import {PATH_REPORTING} from '../../../reporting.path';
// components
import {Page} from '../../../../../shared/components';
import HeaderBreadcrumbs from '../../../../../shared/components/HeaderBreadcrumbs';
import {useMyDashboards} from '../../dashboard_profile/services/dashboard.profile.gql';
import {Refresh} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import DashboardComponent from "../components/DashboardComponent";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    {id: 'id', label: 'ID', alignRight: false},
    {id: 'title', label: 'Titre', alignRight: false},
    {id: 'description', label: 'Description', alignRight: false},
];


export default function MyDashboardList() {

    const theme = useTheme();

    const {myDashboards, refetch, loading} = useMyDashboards();

    const dashboards = _.uniqBy(myDashboards.map(({dashboardObj}) => dashboardObj), 'id');

    return (
        <Page title={`Reporting: Mes Tableaux de bord`}>
            <Container maxWidth={'xl'}>
                <HeaderBreadcrumbs
                    heading={`Tableau de bord`}
                    links={[{name: 'Mes Tableaux de bord'}]}
                />

                <Card>

                    <Toolbar
                        sx={{
                            height: 96,
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: theme.spacing(0, 1, 0, 3),
                        }}
                    >
                        <Typography>
                            Mes Tableaux de bord
                        </Typography>

                        <Tooltip title='Actualiser'>
                            <IconButton onClick={() => refetch()}>
                                <Refresh/>
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                    <TableContainer sx={{minWidth: 800}}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    {TABLE_HEAD.map((headCell) => (
                                        <TableCell
                                            key={headCell.id}
                                            align={headCell.alignRight ? 'right' : 'left'}
                                        >
                                            {headCell.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dashboards && dashboards.map((row) => (<Row key={row.id} row={row}/>))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Container>
        </Page>
    );
}

function Row({row}) {
    const {id, title, description} = row;

    return (
        <TableRow hover sx={{'&:last-child td, &:last-child th': {border: 0}}}>
            <TableCell component='th' scope='row'>
                <Link
                    to={PATH_REPORTING.dashboard + '/' + id + '/execute'}
                    component={RouterLink}
                >
                    <Typography variant="body2" noWrap>
                        {id}
                    </Typography>
                </Link>
            </TableCell>
            <TableCell component="th" scope='row'>
                <Link
                    to={PATH_REPORTING.dashboard + '/' + id + '/execute'}
                    component={RouterLink}
                >
                    <Typography
                        variant="body2" noWrap>
                        {title}
                    </Typography>
                </Link>
            </TableCell>
            <TableCell component="th" scope='row'>
                <Typography
                    variant="body2" noWrap>
                    {description}
                </Typography>
            </TableCell>
        </TableRow>
    );
}

export function MyFirstDashboard() {
    const {myDashboards, refetch, loading} = useMyDashboards();
    const [dashboards, setDashboards] = useState([]);
    const [currentDashboard, setCurrentDashboard] = useState(null);
    const [showTitle] = useState(false);

    useEffect(() => {
        if (!myDashboards) return;

        const dashboards = _.uniqBy(myDashboards.map(({dashboardObj}) => dashboardObj), 'id');
        setDashboards(dashboards);
        if (dashboards.length === 0) {
            setCurrentDashboard(null);
            return;
        }

        const currentDashboard = dashboards[0];
        setCurrentDashboard(currentDashboard);

    }, [myDashboards]);

    return (
        <>
            {loading && (
                <Box sx={{
                    height: '60vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <CircularProgress/>
                </Box>
            )}
            {
                currentDashboard && <>
                    {
                        dashboards.length > 1 && <FormControl sx={{
                            width: {
                                xs: '100%',
                                md: '50%',
                                lg: '25%',
                            }
                        }}>
                            <InputLabel id='id-label-dashboard'>Tableau de bord</InputLabel>
                            <Select
                                id='id-dashboard'
                                label='Tableau de bord'
                                value={currentDashboard}
                                labelId='id-label-dashboard'
                                isOptionEqualToValue={(option, value) => option.id === value?.id}
                                onChange={(event) => {
                                    // const _id = parseInt(event.target.value);
                                    // const _currentDashboard = _.find(myDashboards, (({id}) => _id === id));
                                    // setCurrentDashboard(_currentDashboard);
                                    setCurrentDashboard(event.target.value);
                                }}
                            >
                                {dashboards.map(dashboard => {
                                    console.log(dashboard);
                                    return (
                                        <MenuItem key={dashboard.id} value={dashboard}>{dashboard.title}</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    }
                    <DashboardComponent {...{currentDashboard, showTitle}}/>
                </>
            }
        </>
    );
}