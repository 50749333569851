import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const LithologyInfrastructureList = Loadable(
  lazy(() => import("./pages/LithologyInfrastructureList"))
);
const LithologyInfrastructureCreate = Loadable(
  lazy(() => import("./pages/LithologyInfrastructureCreate"))
);
const LithologyInfrastructureEdit = Loadable(
  lazy(() => import("./pages/LithologyInfrastructureEdit"))
);
const LithologyInfrastructureDisplay = Loadable(
  lazy(() => import("./pages/LithologyInfrastructureDisplay"))
);

const lithologyInfrastructureRouter = [
  {
    path: "lithology-infrastructures",
    element: <LithologyInfrastructureList />,
  },
  {
    path: "lithology-infrastructures/:id",
    element: <LithologyInfrastructureDisplay />,
  },
  {
    path: "lithology-infrastructures/new",
    element: <LithologyInfrastructureCreate />,
  },
  {
    path: "lithology-infrastructures/:id/edit",
    element: <LithologyInfrastructureEdit />,
  },
];

export default lithologyInfrastructureRouter;
