import React, {lazy} from 'react';
import ParticipationAreaAndTypeList from "./pages/ParticipationAreaAndTypeList";
import ParticipationAreaAndTypeDisplay from "./pages/ParticipationAreaAndTypeDisplay";
import ParticipationAreaAndTypeCreate from "./pages/ParticipationAreaAndTypeCreate";
import ParticipationAreaAndTypeEdit from "./pages/ParticipationAreaAndTypeEdit";

const participationAreaAndTypeRouter = [
    {path: 'participation-area-and-types', element: <ParticipationAreaAndTypeList/>},
    {path: 'participation-area-and-types/:id', element: <ParticipationAreaAndTypeDisplay/>},
    {path: 'participation-area-and-types/new', element: <ParticipationAreaAndTypeCreate/>},
    {path: 'participation-area-and-types/:id/edit', element: <ParticipationAreaAndTypeEdit/>},

];
export default participationAreaAndTypeRouter;
