import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const LithologyList = Loadable(lazy(() => import("./pages/LithologyList")));
const LithologyCreate = Loadable(lazy(() => import("./pages/LithologyCreate")));
const LithologyEdit = Loadable(lazy(() => import("./pages/LithologyEdit")));
const LithologyDisplay = Loadable(
  lazy(() => import("./pages/LithologyDisplay"))
);

const lithologyRouter = [
  { path: "Lithologies", element: <LithologyList /> },
  { path: "Lithologies/:id", element: <LithologyDisplay /> },
  { path: "Lithologies/new", element: <LithologyCreate /> },
  { path: "Lithologies/:id/edit", element: <LithologyEdit /> },
];

export default lithologyRouter;
