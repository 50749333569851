import React from 'react';
import {PATH_ADMINISTRATIVE_DIVISION, commonItems} from '../../administrative_division.path';
import HeaderBreadcrumbs from "../../../../shared/components/HeaderBreadcrumbs";

export function StatusCommuneHeaderBreadcrumbs ({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[
            ...commonItems,
            {name: 'Statut de la commune', href: PATH_ADMINISTRATIVE_DIVISION.statusCommunes},
            {name: lastName},
        ]}
    />);
}

export function StatusCommuneListHeaderBreadcrumbs ({heading, lastName, ...others}) {
    return (<HeaderBreadcrumbs
        {...others}
        heading={heading}
        links={[...commonItems, {name: lastName}]}
    />);
}
