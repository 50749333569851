import React from 'react';
// components
import {AhpTypeForm} from '../components';
import CreateDialogMenuItem from '../../../../shared/components/CreateDialogMenuItem';
import {useAhpTypeStore} from "../services/ahp.type.store";

export function AhpTypeCreateDialogMenuItem({onClose, onSave, btn}) {
    const {currentNewAhpType: currentAhpType, setCurrentNewAhpType: setCurrentAhpType} = useAhpTypeStore();
    return (
        <CreateDialogMenuItem
            btn={btn}
            onClose={onClose}
            menuText='Ajouter un type AHP'
            dialogTitle='Création de type AHP'
            dialogContextText={<> {`Création de nouveau type AHP`} </>}
            formRender={({onClose}) => {
                return (
                    <AhpTypeForm
                        {...{currentAhpType, setCurrentAhpType}}
                        fromDialog={{
                            onCancel: onClose,
                            onSave: (createdAhpType) => {
                                onSave && onSave(createdAhpType);
                                onClose && onClose();
                            }
                        }}/>
                );
            }}
        />
    );

}
