import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const ExploitantTypeList = Loadable(
  lazy(() => import("./pages/ExploitantTypeList"))
);
const ExploitantTypeCreate = Loadable(
  lazy(() => import("./pages/ExploitantTypeCreate"))
);
const ExploitantTypeEdit = Loadable(
  lazy(() => import("./pages/ExploitantTypeEdit"))
);
const ExploitantTypeDisplay = Loadable(
  lazy(() => import("./pages/ExploitantTypeDisplay"))
);

const exploitantTypeRouter = [
  { path: "exploitant-types", element: <ExploitantTypeList /> },
  { path: "exploitant-types/:id", element: <ExploitantTypeDisplay /> },
  { path: "exploitant-types/new", element: <ExploitantTypeCreate /> },
  { path: "exploitant-types/:id/edit", element: <ExploitantTypeEdit /> },
];

export default exploitantTypeRouter;
