import * as Yup from 'yup';
import create from 'zustand';

export const InstitutionalOrganizationalFrameworkStructureSchema = Yup.object().shape({
  name: Yup.string().default("").required("Le libellé est obligatoire"),
  institutionalOrganizationalFrameworkStructureType: Yup.number().required("Le type de structures de cadre institutionnel et organisationnel eau est obligatoire"),
  typeSphere: Yup.number().required("Types de sphère d'actions de GIRE est obligatoire"),
  dataSource: Yup.string(),
  dateMizPlace: Yup.date().required("La date de mise en place est obligatoire"),
  structureResp: Yup.string().required("La structure resp est obligatoire"),
  descriptionObjectifs: Yup.string().required("La description objectifs est obligatoire"),
});

const initialInstitutionalOrganizationalFrameworkStructure = InstitutionalOrganizationalFrameworkStructureSchema.getDefaultFromShape();
export const InitialInstitutionalOrganizationalFrameworkStructure = () => ({...initialInstitutionalOrganizationalFrameworkStructure});

const setCurrentNewInstitutionalOrganizationalFrameworkStructure = set => institutionalOrganizationalFrameworkStructure => {
  set(state => ({...state, currentNewInstitutionalOrganizationalFrameworkStructure: institutionalOrganizationalFrameworkStructure ?? InitialInstitutionalOrganizationalFrameworkStructure()}));
}

const setCurrentUpdateInstitutionalOrganizationalFrameworkStructure = set => institutionalOrganizationalFrameworkStructure => {
  set(state => ({...state, currentUpdateInstitutionalOrganizationalFrameworkStructure: institutionalOrganizationalFrameworkStructure ?? InitialInstitutionalOrganizationalFrameworkStructure()}));
}

export const useInstitutionalOrganizationalFrameworkStructureStore = create(set => ({
  //State
  currentNewInstitutionalOrganizationalFrameworkStructure: InitialInstitutionalOrganizationalFrameworkStructure(),
  currentUpdateInstitutionalOrganizationalFrameworkStructure: InitialInstitutionalOrganizationalFrameworkStructure(),
  //Reducers
  setCurrentNewInstitutionalOrganizationalFrameworkStructure: setCurrentNewInstitutionalOrganizationalFrameworkStructure(set),
  setCurrentUpdateInstitutionalOrganizationalFrameworkStructure: setCurrentUpdateInstitutionalOrganizationalFrameworkStructure(set),
}))
