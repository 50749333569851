import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const OccurrenceAggressionList = Loadable(lazy(() => import('./pages/OccurrenceAggressionList')));
const OccurrenceAggressionCreate = Loadable(lazy(() => import('./pages/OccurrenceAggressionCreate')));
const OccurrenceAggressionEdit = Loadable(lazy(() => import('./pages/OccurrenceAggressionEdit')));
const OccurrenceAggressionDisplay = Loadable(lazy(() => import('./pages/OccurrenceAggressionDisplay')));

const occurrenceAggressionRouter = [
    {path: 'occurrence-aggressions', element: <OccurrenceAggressionList/>},
    {path: 'occurrence-aggressions/:id', element: <OccurrenceAggressionDisplay/>},
    {path: 'occurrence-aggressions/new', element: <OccurrenceAggressionCreate/>},
    {path: 'occurrence-aggressions/:id/edit', element: <OccurrenceAggressionEdit/>},
];

export default occurrenceAggressionRouter;
