import React from 'react';
// components
import {RiskManagementToolApplicationDegreeForm} from '../components';
import CreateDialogMenuItem from '../../../../shared/components/CreateDialogMenuItem';
import {useRiskManagementToolApplicationDegreeStore} from "../services/risk.management.tool.application.degree.store";

// ----------------------------------------------------------------------


export function RiskManagementToolApplicationDegreeCreateDialogMenuItem({onClose, onSave, btn}) {
    const {currentNewRiskManagementToolApplicationDegree: currentRiskManagementToolApplicationDegree, setCurrentNewRiskManagementToolApplicationDegree: setCurrentRiskManagementToolApplicationDegree} = useRiskManagementToolApplicationDegreeStore();
    return (
        <CreateDialogMenuItem
            btn={btn}
            onClose={onClose}
            menuText="Ajouter un degré d'application des outils de gestion de risques liés à l'eau"
            dialogTitle="Création de degré d'application des outils de gestion de risques liés à l'eau"
            dialogContextText={<> {`Création de nouveau degré d'application des outils de gestion de risques liés à l'eau`} </>}
            formRender={({onClose}) => {
                return (
                    <RiskManagementToolApplicationDegreeForm
                        {...{currentRiskManagementToolApplicationDegree, setCurrentRiskManagementToolApplicationDegree}}
                        fromDialog={{
                            onCancel: onClose,
                            onSave: (createdRiskManagementToolApplicationDegree) => {
                                onSave && onSave(createdRiskManagementToolApplicationDegree);
                                onClose && onClose();
                            }
                        }}/>
                );
            }}
        />
    );

}
