import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const VillageStatusList = Loadable(
  lazy(() => import("./pages/VillageStatusList"))
);
const VillageStatusCreate = Loadable(
  lazy(() => import("./pages/VillageStatusCreate"))
);
const VillageStatusEdit = Loadable(
  lazy(() => import("./pages/VillageStatusEdit"))
);
const VillageStatusDisplay = Loadable(
  lazy(() => import("./pages/VillageStatusDisplay"))
);

const villageStatusRouter = [
  { path: "village-status", element: <VillageStatusList /> },
  { path: "village-status/:id", element: <VillageStatusDisplay /> },
  { path: "village-status/new", element: <VillageStatusCreate /> },
  { path: "village-status/:id/edit", element: <VillageStatusEdit /> },
];

export default villageStatusRouter;
