import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { parseCount, parseEntity } from "../../../../utils/gql.utils";
import { useEffect, useState } from "react";
import { useMessage } from "../../../../shared/hooks/useMessage";

export let needRefresh = false;
export const setRefresh = (value) => (needRefresh = value);

export const waterMassSetFields = `
    id wording description
`;

///////////////////// Find By ID /////////////////////
const GET_BASIN_BY_ID = gql`
    query WaterMassSetById($id: Int!) {
        waterMassSetById(id: $id) {
            error message entity { ${waterMassSetFields} }
        }
    }
`;

export const useWaterMassSetById = (id) => {
  id = parseInt(id);
  return useQuery(GET_BASIN_BY_ID, {
    variables: { id },
    fetchPolicy: "network-only",
  });
};

export const useWaterMassSet = (id) => {
  const result = useWaterMassSetById(id);
  return parseEntity(result, "waterMassSetById");
};

///////////////////// Search /////////////////////
const SEARCH_BASINS = gql`
    query SearchWaterMassSets($query: String, $page: Int, $size: Int) {
        searchWaterMassSets(query: $query, page: $page, size: $size) {
            error message page {
                content { ${waterMassSetFields} }
                page hasNext hasPrevious totalPages totalElements numberOfElements
            }
        }
    }
`;

export const useSearchWaterMassSets = ({ query = "", page = 0, size = 20 }) => {
  const [waterMassSets, setWaterMassSets] = useState([]);
  const [waterMassSetPage, setWaterMassSetPage] = useState(null);

  const { showError } = useMessage();

  const [search, { data: searchData, refetch, loading, error: _error }] =
    useLazyQuery(SEARCH_BASINS, {
      variables: { query, page, size },
    });

  useEffect(() => {
    if (_error) {
      showError("Erreur lors de l'operation");
      setWaterMassSetPage(null);
      setWaterMassSets([]);
      return;
    }

    if (!searchData) return;

    const { error, message, page } = searchData.searchWaterMassSets;

    if (error) {
      showError(message);
      setWaterMassSetPage(null);
      setWaterMassSets([]);
    } else {
      setWaterMassSetPage(page);
      setWaterMassSets(page.content);
    }

    if (needRefresh) {
      refetch();
      setRefresh(false);
    }
  }, [_error, searchData, needRefresh]);

  useEffect(() => {
    search({ variables: { query, page, size } });
  }, [query, page, size]);

  return {
    waterMassSets,
    loading,
    search,
    refetch,
    waterMassSetPage,
  };
};

///////////////////// Count /////////////////////
const COUNT_BASINS = gql`
  query CountWaterMassSets {
    countWaterMassSets {
      error
      message
      count
    }
  }
`;

export const useCountWaterMassSets = () => {
  const result = useQuery(COUNT_BASINS, {
    fetchPolicy: "network-only",
  });
  return parseCount(result, "countWaterMassSets");
};
