import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {Form, FormikProvider, useFormik} from 'formik';
import {useMessage} from '../../../../shared/hooks/useMessage';
// material
import {LoadingButton} from '@mui/lab';
import {Card, Stack, Box, Button} from '@mui/material';
// routes
import {PATH_ADMINISTRATIVE_DIVISION} from '../../administrative_division.path';
import {
    FormikAutocomplete,
    FormikTextField,
    SubmitCancelGroupButton
} from '../../../../shared/components/froms/FormComponents';
import {setRefresh} from '../services/region.gql';
import useRegionService from '../services/region.services';
import {useSearchPays} from '../../pays/services/pays.gql';
import {PaysCreateDialogMenuItem} from '../../pays/components';
import {InitialRegion, RegionSchema} from '../services/region.store';
import {formErrorMessageParser} from '../../../../utils/utils';
import {useConnectFormState} from '../../../../shared/hooks/useFormikState';

RegionForm.propTypes = {
    isEdit: PropTypes.bool,
    currentRegion: PropTypes.object,
    fromDialog: PropTypes.object,
};

export default function RegionForm({isEdit, currentRegion, setCurrentRegion, fromDialog}) {
    const navigate = useNavigate();
    const {create, update} = useRegionService();
    const {showSuccess, showError} = useMessage();

    const initQuery = currentRegion?.paysObj?.name ?? fromDialog?.pays?.name ?? '';
    const [query, setQuery] = useState(initQuery);
    const {pays, loading} = useSearchPays({query});

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: InitialRegion(),
        validationSchema: RegionSchema,
        onSubmit: async (region, {setSubmitting}) => {
            try {
                region = RegionSchema.cast(region);

                let message;
                if (!isEdit) {
                    region = await create(region);
                    message = 'Création avec succès';
                } else {
                    region = await update(currentRegion.id, region);
                    message = 'Modification avec succès';
                }

                setRefresh(true);
                setCurrentRegion();
                setSubmitting(false);
                // showSuccess(message);

                console.log('fromDialog ==> ', fromDialog);
                if (fromDialog) {
                    fromDialog.onSave(region);
                } else {
                    navigate(`${PATH_ADMINISTRATIVE_DIVISION.regions}/${region.id}`);
                }
            } catch (error) {
                setSubmitting(false);
                showError(formErrorMessageParser({error, isEdit}));
            }
        }
    });

    const {values, setValues, handleSubmit, isSubmitting, setFieldValue} = formik;

    useConnectFormState({
        formValues: values, setFormValues: setValues,
        stateValues: currentRegion, setStateValues: setCurrentRegion,
    });

    const onCancel = (_) => {
        setCurrentRegion();
        if (fromDialog) {
            fromDialog.onCancel();
        } else {
            navigate(PATH_ADMINISTRATIVE_DIVISION.regions);
        }
    }

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete='off' onSubmit={handleSubmit}>

                <Card sx={{p: 3}}>

                    <Stack spacing={3}>

                        <FormikTextField label='Nom' name='name' formik={formik}/>

                        <Box flexGrow={1} display='flex' flexDirection='row'>
                            <FormikAutocomplete
                                name='pays'
                                label='Pays'
                                options={pays}
                                embeddedObjField='paysObj'
                                {...{query, formik, loading, setQuery}}
                            />

                            <Box sx={{py: 1, ml: 1}}>
                                <PaysCreateDialogMenuItem btn onSave={(createdPays) => {
                                    currentRegion.paysObj = createdPays;
                                    setQuery(createdPays?.name ?? '');
                                    setFieldValue('pays', createdPays?.id);
                                }}/>
                            </Box>
                        </Box>

                        <SubmitCancelGroupButton {...{isEdit, isSubmitting, onCancel}}/>

                    </Stack>

                </Card>

            </Form>
        </FormikProvider>
    );
}
