import React from "react";
import { path } from "../../home/sidebar/paths";
import InvertColorsIcon from '@mui/icons-material/InvertColors';
 
//Features
export const WATER_QUALITY = "/water-qualities";
const _path = path(WATER_QUALITY);
export const PATH_WATER_QUALITY = {
  root: WATER_QUALITY,
  home: _path("/home"),
  water_quality_observation_types: _path("/water-quality-observation-types"),
  water_quality_control_parameters: _path("/water-quality-control-parameters"),
  water_quality_observation_stations: _path(
    "/water-quality-observation-stations"
  ),
  points_for_monitoring_the_quality_of_the_water_distributed_and_pollution_control: _path(
    "/points-for-monitoring-the-quality-of-the-water-distributed-and-pollution-control"
  ),
  meaningful_areas_of_drinking_water_quality_control_points: _path(
    "/meaningful-areas-of-drinking-water-quality-control-points"
  ),
  water_quality_observation_occurrences: _path(
    "/water-quality-observation-occurrences"
  ),
};

export const waterQualityMenu = [
  {
    title: "Qualité eau",
    path: PATH_WATER_QUALITY.root,
    icon: <InvertColorsIcon />,
    children: [
      { title: "Accueil", path: PATH_WATER_QUALITY.home },
      {
        title: "Types d'observation",
        path: PATH_WATER_QUALITY.water_quality_observation_types,
      },
      {
        title: "Points de suivi qualite eau ",
        path: PATH_WATER_QUALITY.points_for_monitoring_the_quality_of_the_water_distributed_and_pollution_control,
      },
      {
        title: "Zones de signification",
        path: PATH_WATER_QUALITY.meaningful_areas_of_drinking_water_quality_control_points,
      },
      {
        title: "Paramètres de contrôle",
        path: PATH_WATER_QUALITY.water_quality_control_parameters,
      },
      {
        title: "Stations d'observation",
        path: PATH_WATER_QUALITY.water_quality_observation_stations,
      },
      // {
      //   title: "Occurences d'observation",
      //   path: PATH_WATER_QUALITY.water_quality_observation_occurrences,
      // },
    ],
  },
];

export const commonItems = [
  { name: "Accueil", href: "/" },
  { name: "Qualité Eau", href: PATH_WATER_QUALITY.home },
];
