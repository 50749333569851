import React, {lazy} from 'react';
import Loadable from '../../../shared/components/Loadable';


const OccurrenceUsePastoralList = Loadable(lazy(() => import('./pages/OccurrenceUsePastoralList')));
const OccurrenceUsePastoralCreate = Loadable(lazy(() => import('./pages/OccurrenceUsePastoralCreate')));
const OccurrenceUsePastoralEdit = Loadable(lazy(() => import('./pages/OccurrenceUsePastoralEdit')));
const OccurrenceUsePastoralDisplay = Loadable(lazy(() => import('./pages/OccurrenceUsePastoralDisplay')));

const occurrenceUsePastoralRouter = [
    {path: 'occurrence-use-pastorals', element: <OccurrenceUsePastoralList/>},
    {path: 'occurrence-use-pastorals/:id', element: <OccurrenceUsePastoralDisplay/>},
    {path: 'occurrence-use-pastorals/new', element: <OccurrenceUsePastoralCreate/>},
    {path: 'occurrence-use-pastorals/:id/edit', element: <OccurrenceUsePastoralEdit/>},
];

export default occurrenceUsePastoralRouter;
