import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {Form, FormikProvider, useFormik} from 'formik';
import {useMessage} from '../../../../shared/hooks/useMessage';
// material
import {LoadingButton} from '@mui/lab';
import {Box, Button, Card, Stack} from '@mui/material';
// routes
import {setRefresh} from '../services/occurrence.agricultural.production.gql';
import useOccurrenceAgriculturalProductionService from '../services/occurrence.agricultural.production.services';
import {formErrorMessageParser} from '../../../../utils/utils';
import {FormikAutocomplete, FormikTextField, FormRowStack} from '../../../../shared/components/froms/FormComponents';
import {PATH_WATER_USAGE_URIGATION} from "../../water.usage.irrigation.path";
import {
    InitialOccurrenceAgriculturalProduction,
    OccurrenceAgriculturalProductionSchema
} from '../services/occurrence.agricultural.production.store';
import {useConnectFormState} from '../../../../shared/hooks/useFormikState';
import {DataRefYearCreateDialogMenuItem} from "../../../administrative_division/data_ref_year/components";
import {useSearchDataRefYears} from "../../../administrative_division/data_ref_year/services/data.ref.year.gql";
import {useSearchIrrigatedDevelopmentEntityUsages} from "../../irrigated_development_entity_usages/services/irrigated.development.entity.usages.gql";
import {IrrigatedDevelopmentEntityUsagesCreateDialogMenuItem} from "../../irrigated_development_entity_usages/components";
import {useSearchCulturalSpeculationEntityUsages} from "../../cultural_speculation_entity_usages/services/cultural.speculation.entity.usages.gql";
import {CulturalSpeculationEntityUsagesCreateDialogMenuItem} from "../../cultural_speculation_entity_usages/components/CulturalSpeculationEntityUsagesDialog";
import {useSearchLocalWaterDepartments} from "../../../water_use/local_water_department/services/local.water.department.gql";
import {LocalWaterDepartmentCreateDialogMenuItem} from "../../../water_use/local_water_department/components";

OccurrenceAgriculturalProductionForm.propTypes = {
    isEdit: PropTypes.bool,
    currentOccurrenceAgriculturalProduction: PropTypes.object,
    fromDialog: PropTypes.object,
};

export default function OccurrenceAgriculturalProductionForm({
                                                                 isEdit,
                                                                 currentOccurrenceAgriculturalProduction,
                                                                 setCurrentOccurrenceAgriculturalProduction,
                                                                 fromDialog
                                                             }) {
    const navigate = useNavigate();
    const {create, update} = useOccurrenceAgriculturalProductionService();
    const {showSuccess, showError} = useMessage();

    const initQueryIrrigatedDevelopmentEntityUsages = currentOccurrenceAgriculturalProduction?.irrigatedDevelopmentEntityUsagesObj?.name ?? fromDialog?.irrigatedDevelopmentEntityUsages?.name ?? '';
    const [irrigatedDevelopmentEntityUsagesQuery, setIrrigatedDevelopmentEntityUsagesQuery] = useState(initQueryIrrigatedDevelopmentEntityUsages);
    const {irrigatedDevelopmentEntityUsages, irrigatedDevelopmentEntityUsagesLoading} = useSearchIrrigatedDevelopmentEntityUsages({irrigatedDevelopmentEntityUsagesQuery});

    const initQueryCulturalSpeculationEntityUsages = currentOccurrenceAgriculturalProduction?.culturalSpeculationEntityUsagesObj?.name ?? fromDialog?.culturalSpeculationEntityUsages?.name ?? '';
    const [culturalSpeculationEntityUsagesQuery, setCulturalSpeculationEntityUsagesQuery] = useState(initQueryCulturalSpeculationEntityUsages);
    const {culturalSpeculationEntityUsages, culturalSpeculationEntityUsagesLoading} = useSearchCulturalSpeculationEntityUsages({culturalSpeculationEntityUsagesQuery});

    const initQueryDataRefYear = currentOccurrenceAgriculturalProduction?.dataRefYearObj?.name ?? fromDialog?.dataRefYear?.name ?? '';
    const [dataRefYearQuery, setDataRefYearQuery] = useState(initQueryDataRefYear);
    const {dataRefYears, dataRefYearLoading} = useSearchDataRefYears({dataRefYearQuery});

    const initQueryLocalWaterDepartment = currentOccurrenceAgriculturalProduction?.localWaterDepartmentObj?.name ?? fromDialog?.localWaterDepartment?.name ?? '';
    const [localWaterDepartmentQuery, setLocalWaterDepartmentQuery] = useState(initQueryLocalWaterDepartment);
    const {localWaterDepartments, localWaterDepartmentLoading} = useSearchLocalWaterDepartments({localWaterDepartmentQuery});

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: InitialOccurrenceAgriculturalProduction(),
        validationSchema: OccurrenceAgriculturalProductionSchema,
        onSubmit: async (occurrenceAgriculturalProduction, {setSubmitting}) => {
            try {
                occurrenceAgriculturalProduction = OccurrenceAgriculturalProductionSchema.cast(occurrenceAgriculturalProduction);

                let message;
                if (!isEdit) {
                    occurrenceAgriculturalProduction = await create(occurrenceAgriculturalProduction);
                    message = 'Création avec succès';
                } else {
                    occurrenceAgriculturalProduction = await update(currentOccurrenceAgriculturalProduction.id, occurrenceAgriculturalProduction);
                    message = 'Modification avec succès';
                }

                setRefresh(true);

                setCurrentOccurrenceAgriculturalProduction();

                setSubmitting(false);
                showSuccess(message);

                if (fromDialog) {
                    fromDialog.onSave(occurrenceAgriculturalProduction);
                } else {
                    navigate(`${PATH_WATER_USAGE_URIGATION.occurrenceAgriculturalProductions}/${occurrenceAgriculturalProduction.id}`);
                }
            } catch (error) {
                setSubmitting(false);
                showError(formErrorMessageParser({error, isEdit}));
            }
        }
    });

    const {values, setValues, handleSubmit, isSubmitting, setFieldValue} = formik;

    useConnectFormState({
        formValues: values,
        setFormValues: setValues,
        stateValues: currentOccurrenceAgriculturalProduction,
        setStateValues: setCurrentOccurrenceAgriculturalProduction,
    });

    const onCancel = (_) => {
        setCurrentOccurrenceAgriculturalProduction();
        if (fromDialog) {
            fromDialog.onCancel();
        } else {
            navigate(PATH_WATER_USAGE_URIGATION.occurrenceAgriculturalProductions);
        }
    }

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete='off' onSubmit={handleSubmit}>

                <Card sx={{p: 3}}>

                    <Stack spacing={3}>

                        <FormRowStack>
                            <FormikTextField label='Nom' name='name' formik={formik}/>
                        </FormRowStack>

                        <FormRowStack>
                            <Box flexGrow={1} display='flex' flexDirection='row'>
                                <FormikAutocomplete
                                    name='dataRefYear'
                                    label="Année de reference"
                                    options={dataRefYears}
                                    embeddedObjField='dataRefYearObj'
                                    {...{
                                        query: dataRefYearQuery,
                                        formik,
                                        loading: dataRefYearLoading,
                                        setQuery: setDataRefYearQuery
                                    }}
                                />
                                <Box sx={{py: 1, ml: 1}}>
                                    <DataRefYearCreateDialogMenuItem btn
                                                                     onSave={(createdDataRefYear) => {
                                                                         currentOccurrenceAgriculturalProduction.dataRefYearObj = createdDataRefYear;
                                                                         setDataRefYearQuery(createdDataRefYear?.name ?? '');
                                                                         setFieldValue('dataRefYear', createdDataRefYear?.id);
                                                                     }}/>
                                </Box>
                            </Box>
                            <Box flexGrow={1} display='flex' flexDirection='row'>
                                <FormikAutocomplete
                                    name='localWaterDepartment'
                                    label="Service Locale Eau"
                                    options={localWaterDepartments}
                                    embeddedObjField='localWaterDepartmentObj'
                                    {...{
                                        query: localWaterDepartmentQuery,
                                        formik,
                                        loading: localWaterDepartmentLoading,
                                        setQuery: setLocalWaterDepartmentQuery
                                    }}
                                />
                                <Box sx={{py: 1, ml: 1}}>
                                    <LocalWaterDepartmentCreateDialogMenuItem btn
                                                                     onSave={(createdLocalWaterDepartment) => {
                                                                         currentOccurrenceAgriculturalProduction.localWaterDepartmentObj = createdLocalWaterDepartment;
                                                                         setLocalWaterDepartmentQuery(createdLocalWaterDepartment?.name ?? '');
                                                                         setFieldValue('localWaterDepartment', createdLocalWaterDepartment?.id);
                                                                     }}/>
                                </Box>
                            </Box>
                        </FormRowStack>

                        <FormRowStack>
                            <Box flexGrow={1} display='flex' flexDirection='row'>
                                <FormikAutocomplete
                                    name='irrigatedDevelopmentEntityUsages'
                                    label="Entités d'Aménagement irrigués"
                                    options={irrigatedDevelopmentEntityUsages}
                                    embeddedObjField='irrigatedDevelopmentEntityUsagesObj'
                                    {...{
                                        query: irrigatedDevelopmentEntityUsagesQuery,
                                        formik,
                                        loading: irrigatedDevelopmentEntityUsagesLoading,
                                        setQuery: setIrrigatedDevelopmentEntityUsagesQuery
                                    }}
                                />
                                <Box sx={{py: 1, ml: 1}}>
                                    <IrrigatedDevelopmentEntityUsagesCreateDialogMenuItem btn
                                                                                          onSave={(createdIrrigatedDevelopmentEntityUsages) => {
                                                                                              currentOccurrenceAgriculturalProduction.irrigatedDevelopmentEntityUsagesObj = createdIrrigatedDevelopmentEntityUsages;
                                                                                              setIrrigatedDevelopmentEntityUsagesQuery(createdIrrigatedDevelopmentEntityUsages?.name ?? '');
                                                                                              setFieldValue('irrigatedDevelopmentEntityUsages', createdIrrigatedDevelopmentEntityUsages?.id);
                                                                                          }}/>
                                </Box>
                            </Box>


                            <Box flexGrow={1} display='flex' flexDirection='row'>
                                <FormikAutocomplete
                                    name='culturalSpeculationEntityUsages'
                                    label="Entités de spéculations culturales"
                                    options={culturalSpeculationEntityUsages}
                                    embeddedObjField='culturalSpeculationEntityUsagesObj'
                                    {...{
                                        query: culturalSpeculationEntityUsagesQuery,
                                        formik,
                                        loading: culturalSpeculationEntityUsagesLoading,
                                        setQuery: setCulturalSpeculationEntityUsagesQuery
                                    }}
                                />
                                <Box sx={{py: 1, ml: 1}}>
                                    <CulturalSpeculationEntityUsagesCreateDialogMenuItem btn
                                                                                         onSave={(createdCulturalSpeculationEntityUsages) => {
                                                                                             currentOccurrenceAgriculturalProduction.culturalSpeculationEntityUsagesObj = createdCulturalSpeculationEntityUsages;
                                                                                             setCulturalSpeculationEntityUsagesQuery(createdCulturalSpeculationEntityUsages?.name ?? '');
                                                                                             setFieldValue('culturalSpeculationEntityUsages', createdCulturalSpeculationEntityUsages?.id);
                                                                                         }}/>
                                </Box>
                            </Box>
                        </FormRowStack>

                        <FormRowStack>
                            <FormikTextField label='Saison culturelle' name='saisonCulturale' formik={formik}/>
                            <FormikTextField label='Superficie totale irriguée' name='superfTotIrriguee' formik={formik}/>
                        </FormRowStack>
                        <FormRowStack>
                            <FormikTextField label='Production annuelle' name='productionAnnee' formik={formik}/>
                            <FormikTextField label='Rendement' name='rendement' formik={formik}/>
                        </FormRowStack>
                        <FormRowStack>
                            <FormikTextField label='Valeur marchande' name='valeurMarchande' formik={formik}/>
                            <FormikTextField label='Source de données' name='dataSource' formik={formik}/>
                        </FormRowStack>

                        <Box sx={{mt: 3, display: 'flex', justifyContent: 'flex-end'}}>
                            <Button
                                sx={{mr: 2}}
                                size='medium'
                                onClick={onCancel}
                                disabled={isSubmitting}
                            >
                                {'Annuler'}
                            </Button>
                            <LoadingButton type='submit' variant='contained' size='medium' loading={isSubmitting}>
                                {!isEdit ? 'Créer' : 'Modifier'}
                            </LoadingButton>
                        </Box>
                    </Stack>

                </Card>

            </Form>
        </FormikProvider>
    );
}
