import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';

import MainLayout from '../../home/AppLayout';
import Loadable from '../../shared/components/Loadable';

import {PATH_DOMESTIC} from './domestic.path';
import waterRightRouter from "./water_right/water.right.router";
import operationalUnityRouter from "./operational_unity/operational.unity.router";
import operationalEntityRouter from "./operational_entity/operational.entity.router";
import businessUnitAreaRouter from "./business_unit_area/business.unit.area.router";
import specificWaterRequirementRouter from "./specific_water_requirement/specific.water.requirement.router";
import solicitedWaterMassRouter from "./solicited_water_mass/solicited.water.mass.router";
import occurrenceRateRouter from "./occurrence_rate/occurrence.rate.router";
import occurrenceResultRouter from "./occurrence_result/occurrence.result.router";
import occurrenceCollectionRouter from "./occurrence_collection/occurrence.collection.router";
import occurrenceNeedRouter from "./occurrence_need/occurrence.need.router";




const DOMESTIC_HOME = Loadable(lazy(() => import('./DomesticHome')));

const domesticRouter = [{
    path: PATH_DOMESTIC.root,
    element: <MainLayout/>,
    children: [
        {path: PATH_DOMESTIC.root, element: <Navigate to={PATH_DOMESTIC.home} replace/>},
        {path: 'home', element: <DOMESTIC_HOME/>},

          ...waterRightRouter,
          ...operationalEntityRouter,
          ...businessUnitAreaRouter,
          ...specificWaterRequirementRouter,
          ...solicitedWaterMassRouter,
          ...occurrenceRateRouter,
          ...occurrenceResultRouter,
          ...occurrenceCollectionRouter,
          ...occurrenceNeedRouter,
    ]
},];

export default domesticRouter;
