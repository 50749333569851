import React, { lazy } from "react";
import Loadable from "../../../shared/components/Loadable";

const IrrigatedDevelopmentEntityUsagesList = Loadable(
  lazy(() => import("./pages/IrrigatedDevelopmentEntityUsagesList"))
);
const IrrigatedDevelopmentEntityUsagesCreate = Loadable(
  lazy(() => import("./pages/IrrigatedDevelopmentEntityUsagesCreate"))
);
const IrrigatedDevelopmentEntityUsagesEdit = Loadable(
  lazy(() => import("./pages/IrrigatedDevelopmentEntityUsagesEdit"))
);
const IrrigatedDevelopmentEntityUsagesDisplay = Loadable(
  lazy(() => import("./pages/IrrigatedDevelopmentEntityUsagesDisplay"))
);

const irrigatedDevelopmentEntityUsagesRouter = [
  {
    path: "irrigated-development-entity-usages",
    element: <IrrigatedDevelopmentEntityUsagesList />,
  },
  {
    path: "irrigated-development-entity-usages/:id",
    element: <IrrigatedDevelopmentEntityUsagesDisplay />,
  },
  {
    path: "irrigated-development-entity-usages/new",
    element: <IrrigatedDevelopmentEntityUsagesCreate />,
  },
  {
    path: "irrigated-development-entity-usages/:id/edit",
    element: <IrrigatedDevelopmentEntityUsagesEdit />,
  },
];

export default irrigatedDevelopmentEntityUsagesRouter;
