import {useSnackbar} from 'notistack';

export const useMessage = () => {
    const {enqueueSnackbar} = useSnackbar();

    const _message = variant => message => enqueueSnackbar(message, {variant});

    const showSuccess = _message('success');

    const showInfo = _message('info');

    const showError = _message('error');

    const showWarning = _message('warning');

    return {
        showInfo,
        showError,
        showSuccess,
        showWarning,
    }
}
